import {
  DraftField,
  DraftFieldProps,
  draftInputInternalClasses,
} from '@allganize/draft-input';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { UserFeedbackImproverFormValues } from './use-user-feedback-improver-form';

interface SuggestedAnswerFieldProps
  extends Pick<DraftFieldProps, 'autoFocus'> {}

export const SuggestedAnswerField: FunctionComponent<
  SuggestedAnswerFieldProps
> = props => {
  const { autoFocus } = props;
  const { control, trigger } = useFormContext<UserFeedbackImproverFormValues>();
  const {
    field: { ref: fieldRef, ...field },
    fieldState,
  } = useController({
    control,
    name: 'suggestedAnswer',
  });

  return (
    <DraftField
      css={css`
        .${draftInputInternalClasses.content} {
          min-height: 96px;
        }
      `}
      {...field}
      onBlur={() => {
        field.onBlur();

        if (fieldState.isTouched || fieldState.isDirty) {
          trigger('feedbackSelection');
        }
      }}
      onChange={value => {
        field.onChange(value);

        if (fieldState.isTouched) {
          trigger('feedbackSelection');
        }
      }}
      inputRef={fieldRef}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      fullWidth
      autoFocus={autoFocus}
      label={
        <FormattedMessage
          id="user-feedback-improver-form.suggested-answer.placeholder"
          defaultMessage="If you have a better answer, please leave a suggestion."
          description="User Negative Feedback suggestedAns label"
        />
      }
    />
  );
};
