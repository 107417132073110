import { LoadingButton } from '@allganize/ui-button';
import { FormHelperText } from '@allganize/ui-form';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { AgentField } from './agent-field';
import { AttachmentsField } from './attachments-field';
import { MessageField } from './message-field';
import {
  useContactAgentForm,
  UseContactAgentFormOptions,
} from './use-contact-agent-form';

export interface ContactAgentFormProps
  extends Pick<
    UseContactAgentFormOptions,
    'defaultValues' | 'disabled' | 'validation' | 'onSubmit' | 'onTyping'
  > {}

export const ContactAgentForm: FunctionComponent<
  ContactAgentFormProps
> = props => {
  const { defaultValues, disabled, onSubmit, onTyping, validation } = props;

  const { form, formRef, submit } = useContactAgentForm({
    defaultValues,
    disabled,
    onSubmit,
    onTyping,
    validation,
  });

  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = form;

  return (
    <form
      css={css`
        margin: 0;
      `}
      onSubmit={handleSubmit(submit)}
      ref={formRef}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <FormProvider {...form}>
          <AgentField />

          <MessageField />

          <AttachmentsField />
        </FormProvider>
      </div>

      {errors.root?.message && (
        <FormHelperText error>{errors.root?.message}</FormHelperText>
      )}

      {!disabled && (
        <LoadingButton
          css={css`
            margin-top: 16px;
          `}
          type="submit"
          fullWidth
          color="primary"
          size="large"
          variant="filled"
          disabled={!isValid}
          loading={isSubmitting}
        >
          <FormattedMessage
            id="actions.submit"
            defaultMessage="Submit"
            description="Submit button text"
          />
        </LoadingButton>
      )}
    </form>
  );
};
