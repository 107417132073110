import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import useSlot from '@mui/material/utils/useSlot';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';
import { PanelItem } from '../panel-item/panel-item';
import { LocationButton, LocationButtonProps } from './location-button';

export const Location_KnowledgeBaseNodeFragment = gql(`
  fragment Location_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    ownership

    parent {
      id
      name
      ownership
    }
  }
`);

export interface LocationSlots {
  /**
   * @default LocationButton
   */
  locationButton?: React.ElementType;
}

export interface LocationSlotProps {
  locationButton: SlotProps<React.ElementType<LocationButtonProps>, {}, {}>;
}

export type LocationSlotsAndSlotProps = CreateSlotsAndSlotProps<
  LocationSlots,
  LocationSlotProps
>;

export interface LocationProps extends LocationSlotsAndSlotProps {
  node: FragmentOf<typeof Location_KnowledgeBaseNodeFragment>;
}

export const Location: FunctionComponent<LocationProps> = props => {
  const { node: nodeProp, slotProps = {}, slots = {} } = props;
  const node = readFragment(Location_KnowledgeBaseNodeFragment, nodeProp);

  const [LocationButtonSlot, locationButtonProps] = useSlot('locationButton', {
    elementType: LocationButton,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });

  if (node.ownership === gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL')) {
    return null;
  }

  return (
    <PanelItem
      label={
        <FormattedMessage
          id="knowledge-base-table.location"
          defaultMessage="Location"
          description="Knowledge base table location column text"
        />
      }
    >
      <div>
        <LocationButtonSlot node={node} {...locationButtonProps} />
      </div>
    </PanelItem>
  );
};
