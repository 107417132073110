import {
  KnowledgeBaseFileIcon,
  getKnowledgeBaseTypeIconVariant,
} from '@allganize/knowledge-base-icons';
import { IcFolder } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { Toolbar } from '@mui/material';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

export const SidePanelHeader_KnowledgeBaseNodeFragment = gql(`
  fragment SidePanelHeader_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    name
    ... on KnowledgeBaseNodeFile {
      type
    }
  }
`);

export interface SidePanelHeaderProps {
  data: FragmentOf<typeof SidePanelHeader_KnowledgeBaseNodeFragment> | null;
  moreActions?: React.ReactNode;
}

export const SidePanelHeader: FunctionComponent<
  SidePanelHeaderProps
> = props => {
  const { data: dataProp, moreActions } = props;
  const theme = useTheme();
  const data = readFragment(
    SidePanelHeader_KnowledgeBaseNodeFragment,
    dataProp,
  );
  const variant =
    data?.__typename === 'KnowledgeBaseNodeFile'
      ? getKnowledgeBaseTypeIconVariant({
          type: data.type,
          fileName: data.name,
        })
      : undefined;

  return (
    <Toolbar
      css={css`
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: ${theme.palette.unstable_background.white};
        padding: 16px;
        align-items: flex-start;
        gap: 16px;

        ${theme.breakpoints.up('xs')} {
          padding-left: 16px;
          padding-right: 16px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          gap: 8px;
          padding: 4px 0;
          flex-grow: 1;
        `}
      >
        {variant ? (
          <KnowledgeBaseFileIcon fontSize="large" variant={variant} />
        ) : (
          <IcFolder fontSize="large" />
        )}

        <Text
          css={css`
            flex-grow: 1;
            word-break: break-all;
          `}
          variant="subtitle16"
        >
          {data?.name}
        </Text>
      </div>

      {moreActions}
    </Toolbar>
  );
};
