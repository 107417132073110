import { useEventCallback } from '@allganize/hooks';
import {
  convertEdgesToNodes,
  ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment,
  Metadata,
} from '@allganize/knowledge-base-tree-view';
import { ApolloQueryResult } from '@apollo/client/core';
import { QueryResult, useQuery } from '@apollo/client/react';
import { uniqBy } from 'lodash-es';
import { useMemo } from 'react';
import { INode, NodeId } from 'react-accessible-treeview';
import { gql, ResultOf, Scalars, VariablesOf } from '../gql';

export const MoveNodeTreeQuery = gql(
  `
  query MoveNodeTreeQuery(
    $project: ProjectWhereUniqueInput!
    $filter: KnowledgeBaseNodeFilter
    $order: KnowledgeBaseNodeOrder
    $first: Int
  ) {
    knowledgeBaseNodes(
      project: $project
      filter: $filter
      order: $order
      first: $first
    ) {
      edges {
        node {
          id

          accessAgents {
            agent {
              id
              isMe
            }
            accessType
          }
        }

        ...ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment
      }
    }
  }
`,
  [ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment],
);

export interface UseMoveNodeTreeQueryOptions {
  projectId: Scalars<'ID'>;
  ownership?: Scalars<'KnowledgeBaseNodeOwnership'>;
  parent?: NodeId[];
}

interface UseMoveNodeTreeQueryResult
  extends QueryResult<
    ResultOf<typeof MoveNodeTreeQuery>,
    VariablesOf<typeof MoveNodeTreeQuery>
  > {
  nodes?: INode<
    Metadata & {
      isEditable: boolean;
    }
  >[];
  loadNextData(
    newFilter?: Scalars<'KnowledgeBaseNodeFilter'>,
  ): Promise<ApolloQueryResult<ResultOf<typeof MoveNodeTreeQuery>>>;
  refresh(): Promise<void>;
}

export const useMoveNodeTreeQuery = ({
  ownership = gql.scalar('KnowledgeBaseNodeOwnership', 'SHARED'),
  projectId,
  parent = [],
}: UseMoveNodeTreeQueryOptions): UseMoveNodeTreeQueryResult => {
  const result = useQuery(MoveNodeTreeQuery, {
    variables: {
      project: { id: projectId },
      filter: {
        parent: [null, ...parent],
        ownership: [ownership],
        type: [gql.scalar('KnowledgeBaseNodeType', 'FOLDER')],
      },
      order: gql.scalar('KnowledgeBaseNodeOrder', 'NAME_ASC'),
      first: -1,
    },
    fetchPolicy: 'network-only',
  });

  const { data, fetchMore, refetch } = result;

  const nodes = useMemo(
    () =>
      convertEdgesToNodes(data?.knowledgeBaseNodes?.edges, edge => {
        return {
          isEditable: !!edge.node.accessAgents?.some(
            w =>
              w.accessType === gql.scalar('ObjectAccessType', 'EDITOR') &&
              w.agent?.isMe,
          ),
        };
      }),
    [data],
  );

  const loadNextData = useEventCallback(
    async (newFilter?: Scalars<'KnowledgeBaseNodeFilter'>) => {
      return fetchMore({
        variables: {
          project: { id: projectId },
          filter: {
            type: [gql.scalar('KnowledgeBaseNodeType', 'FOLDER')],
            ownership: [ownership],
            ...newFilter,
          },
          order: gql.scalar('KnowledgeBaseNodeOrder', 'NAME_ASC'),
          first: -1,
        },
        updateQuery(prev, { fetchMoreResult }) {
          if (!fetchMoreResult.knowledgeBaseNodes?.edges.length) {
            return prev;
          }

          return {
            ...prev,
            ...fetchMoreResult,
            knowledgeBaseNodes: {
              ...prev.knowledgeBaseNodes,
              ...fetchMoreResult.knowledgeBaseNodes,
              edges: uniqBy(
                [
                  ...fetchMoreResult.knowledgeBaseNodes.edges,
                  ...(prev.knowledgeBaseNodes?.edges ?? []),
                ],
                edge => edge.node.id,
              ),
            },
          };
        },
      });
    },
  );

  const refresh = useEventCallback(async () => {
    const ids = data?.knowledgeBaseNodes?.edges.map(edge => edge.node.id) ?? [];

    if (ids.length === 0) {
      await refetch();
      return;
    }

    await fetchMore({
      variables: {
        project: { id: projectId },
        filter: {
          type: [gql.scalar('KnowledgeBaseNodeType', 'FOLDER')],
          ownership: [ownership],
          parent: [null, ...ids],
        },
        order: gql.scalar('KnowledgeBaseNodeOrder', 'NAME_ASC'),
        first: -1,
      },
      updateQuery(prev, { fetchMoreResult }) {
        return fetchMoreResult;
      },
    });
  });

  return {
    ...result,
    nodes,
    loadNextData,
    refresh,
  };
};
