import { EditorState, isDraftInputEmpty } from '@allganize/draft-input';
import { useWindow } from '@allganize/hooks';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import { isValid } from 'date-fns';
import { filesize } from 'filesize';
import { useEffect, useMemo } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';
import { gql, ResultOf, Scalars } from '../gql';
import { formatDate } from './use-form-value-form.utils';

export const FormValueBase_VariableFragment = gql(`
  fragment FormValueBase_VariableFragment on Variable {
    id
    validationCustom
    validationFailedMessage
  }
`);

export interface FormValueBase {
  readonly label: string;
  readonly required: boolean;
  readonly variable: ResultOf<typeof FormValueBase_VariableFragment>;
}

export const FormValueOption_MediaFragment = gql(`
  fragment FormValueOption_MediaFragment on Media {
    id
    url
    metaInfo
    width
    height
    mediaType
  }
`);

export interface FormValueOption {
  readonly value: number;
  readonly label: string;
  readonly media?: ResultOf<typeof FormValueOption_MediaFragment> & {
    isOptionType: true;
  };
}

export interface TextFormValue extends FormValueBase {
  readonly type: 'text';
  value: EditorState;
}

export interface NumberFormValue extends FormValueBase {
  readonly type: 'number';
  value: number | null;
}

export interface DateFormValue extends FormValueBase {
  readonly type: 'date';
  value: Date | null;
}

export const FileFormValue_FileFragment = gql(`
  fragment FileFormValue_FileFragment on File {
    id
    metaInfo
    url
  }
`);

export interface FileFormValue extends FormValueBase {
  readonly type: 'file';
  value: File | ResultOf<typeof FileFormValue_FileFragment> | null;
}

export interface SingleSelectFormValue extends FormValueBase {
  readonly type: 'single-select';
  readonly options: FormValueOption[];
  value: FormValueOption | null;
}

export interface MultiSelectFormValue extends FormValueBase {
  readonly type: 'multi-select';
  readonly options: FormValueOption[];
  value: FormValueOption[];
}

export interface CheckboxFormValue extends FormValueBase {
  readonly type: 'checkbox';
  readonly options: FormValueOption[];
  value: number[];
}

export interface RadioFormValue extends FormValueBase {
  readonly type: 'radio';
  readonly options: FormValueOption[];
  value: number | null;
}

export interface SwitchFormValue extends FormValueBase {
  readonly type: 'switch';
  value: boolean;
}

export type FormValue =
  | TextFormValue
  | NumberFormValue
  | DateFormValue
  | FileFormValue
  | SingleSelectFormValue
  | MultiSelectFormValue
  | CheckboxFormValue
  | RadioFormValue
  | SwitchFormValue;

export interface AgentSelectOption {
  label: string;
  value: Scalars<'ID'>;
  avatar?: string;
}

export interface FormValueFormValues {
  formValues: FormValue[];
  targetAgents: AgentSelectOption[];
}

const defaultFormValueFormValues: FormValueFormValues = {
  formValues: [],
  targetAgents: [],
};

type FormValueFormErrorField = FieldPath<FormValueFormValues> | 'root';
const contactAgentFormErrorFields: FormValueFormErrorField[] = [
  'formValues',
  'targetAgents',
  'root',
];

const isFormValueFormErrorField = (
  field: any,
): field is FormValueFormErrorField =>
  contactAgentFormErrorFields.includes(field);

const errorMessages = defineMessages({
  fieldRequired: {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
  fieldInvalidOption: {
    id: 'form.errors.invalid-option',
    defaultMessage: 'Invalid option',
    description: 'Field invalid option error message',
  },
  fieldInvalidFormat: {
    id: 'form.errors.invalid-format',
    defaultMessage: 'Invalid format',
    description: 'Field invalid format error message',
  },
  maxFileSize: {
    id: 'form.file.errors.max',
    defaultMessage: 'File too large (Max. {size})',
    description: 'File too large error message',
  },
});

interface FormValueFormValidationOptions {
  formValues?: {
    file: {
      maxSize: {
        default: number;
        image: number;
      };
    };
  };
  intl: IntlShape;
  window?: typeof globalThis;
}

const createValidationSchema = ({
  intl,
  formValues = { file: { maxSize: { default: 0, image: 0 } } },
  window: win,
}: FormValueFormValidationOptions) => {
  const FileKlass = win?.File ?? File;

  const formValueBaseSchema = z.object({
    label: z.string(),
    required: z.boolean(),
    variable: z.object({
      id: z.union([z.string(), z.number()]),
      validationCustom: z.string().nullable(),
      validationFailedMessage: z.string().nullable(),
    }),
  }) satisfies z.ZodSchema<FormValueBase>;

  const formValueOptionSchema = z.object(
    {
      value: z.number(),
      label: z.string(),
      media: z
        .object({
          id: z.union([z.string(), z.number()]),
          url: z.string(),
          metaInfo: z.string().nullable(),
          width: z.number().nullable(),
          height: z.number().nullable(),
          mediaType: z.string().nullable(),
          isOptionType: z.literal(true),
        })
        .optional(),
    },
    {
      invalid_type_error: intl.formatMessage(errorMessages.fieldInvalidFormat),
    },
  ) satisfies z.ZodSchema<FormValueOption>;

  const textFormValueSchema = formValueBaseSchema.extend({
    type: z.literal('text'),
    value: z.instanceof(EditorState),
  }) satisfies z.ZodSchema<TextFormValue>;

  const numberFormValueSchema = formValueBaseSchema.extend({
    type: z.literal('number'),
    value: z
      .number({
        invalid_type_error: intl.formatMessage(
          errorMessages.fieldInvalidFormat,
        ),
      })
      .nullable(),
  }) satisfies z.ZodSchema<NumberFormValue>;

  const dateFormValueSchema = formValueBaseSchema.extend({
    type: z.literal('date'),
    value: z
      .date({
        // https://github.com/colinhacks/zod/issues/1526
        errorMap(issue, { defaultError }) {
          if (issue.code !== 'invalid_date') {
            return { message: defaultError };
          }

          return {
            message: intl.formatMessage(errorMessages.fieldInvalidFormat),
          };
        },
      })
      .nullable()
      .refine(value => {
        if (value === null) {
          return true;
        }

        return isValid(value);
      }, intl.formatMessage(errorMessages.fieldInvalidFormat)),
  }) satisfies z.ZodSchema<DateFormValue>;

  const fileFormValueSchema = formValueBaseSchema.extend({
    type: z.literal('file'),
    value: z
      .union([
        z.instanceof(File),
        z.instanceof(FileKlass),
        z
          .object({
            id: z.union([z.string(), z.number()]),
            metaInfo: z.string().nullable(),
            url: z.string(),
          })
          .passthrough(),
      ])
      .nullable(),
  }) satisfies z.ZodSchema<FileFormValue>;

  const singleSelectFormValueSchema = formValueBaseSchema.extend({
    type: z.literal('single-select'),
    options: z.array(formValueOptionSchema),
    value: formValueOptionSchema.nullable(),
  }) satisfies z.ZodSchema<SingleSelectFormValue>;

  const multiSelectFormValueSchema = formValueBaseSchema.extend({
    type: z.literal('multi-select'),
    options: z.array(formValueOptionSchema),
    value: z.array(formValueOptionSchema),
  }) satisfies z.ZodSchema<MultiSelectFormValue>;

  const checkboxFormValueSchema = formValueBaseSchema.extend({
    type: z.literal('checkbox'),
    options: z.array(formValueOptionSchema),
    value: z.array(
      z.number({
        invalid_type_error: intl.formatMessage(
          errorMessages.fieldInvalidFormat,
        ),
      }),
    ),
  }) satisfies z.ZodSchema<CheckboxFormValue>;

  const radioFormValueSchema = formValueBaseSchema.extend({
    type: z.literal('radio'),
    options: z.array(formValueOptionSchema),
    value: z
      .number({
        invalid_type_error: intl.formatMessage(
          errorMessages.fieldInvalidFormat,
        ),
      })
      .nullable(),
  }) satisfies z.ZodSchema<RadioFormValue>;

  const switchFormValueSchema = formValueBaseSchema.extend({
    type: z.literal('switch'),
    value: z.boolean({
      invalid_type_error: intl.formatMessage(errorMessages.fieldInvalidFormat),
    }),
  }) satisfies z.ZodSchema<SwitchFormValue>;

  const agentSelectOptionSchema = z.object({
    label: z.string(),
    value: z.union([z.string(), z.number()]),
    avatar: z.string().optional(),
  }) satisfies z.ZodSchema<AgentSelectOption>;

  return z.object({
    formValues: z.array(
      z
        .discriminatedUnion('type', [
          textFormValueSchema,
          numberFormValueSchema,
          dateFormValueSchema,
          fileFormValueSchema,
          singleSelectFormValueSchema,
          multiSelectFormValueSchema,
          checkboxFormValueSchema,
          radioFormValueSchema,
          switchFormValueSchema,
        ])
        .refine(
          value => {
            if (!value.required) {
              return true;
            }

            switch (value.type) {
              case 'number':
                return value.value !== null && !Number.isNaN(value.value);
              case 'text':
                return !isDraftInputEmpty(value.value);
              case 'date':
              case 'radio':
              case 'file':
              case 'single-select':
                return value.value !== null;
              case 'checkbox':
              case 'multi-select':
                return value.value.length > 0;
            }

            return true;
          },
          {
            path: ['value'],
            message: intl.formatMessage(errorMessages.fieldRequired),
          },
        )
        .refine(
          value => {
            switch (value.type) {
              case 'radio':
                if (value.value === null) {
                  return true;
                }

                return value.options.some(
                  option => option.value === value.value,
                );
              case 'single-select':
                if (value.value === null) {
                  return true;
                }

                return value.options.some(
                  option => option.value === value.value?.value,
                );
              case 'checkbox':
                if (value.value.length === 0) {
                  return true;
                }

                return value.value.every(option =>
                  value.options.some(opt => opt.value === option),
                );
              case 'multi-select':
                if (value.value.length === 0) {
                  return true;
                }

                return value.value.every(option =>
                  value.options.some(opt => opt.value === option.value),
                );
            }

            return true;
          },
          {
            message: intl.formatMessage(errorMessages.fieldInvalidOption),
            path: ['value'],
          },
        )
        .refine(
          value => {
            if (value.type !== 'file') {
              return true;
            }

            if (
              !(value.value instanceof File || value.value instanceof FileKlass)
            ) {
              return true;
            }

            if (value.value.type.startsWith('image/')) {
              return value.value.size <= formValues.file.maxSize.image;
            }

            return value.value.size <= formValues.file.maxSize.default;
          },
          value => {
            const isImageFile =
              (value.value instanceof File ||
                value.value instanceof FileKlass) &&
              value.value.type.startsWith('image/');

            return {
              message: isImageFile
                ? intl.formatMessage(errorMessages.maxFileSize, {
                    size: filesize(formValues.file.maxSize.image, {
                      output: 'string',
                      locale: intl.locale,
                      localeOptions: intl.formats.date,
                    }),
                  })
                : intl.formatMessage(errorMessages.maxFileSize, {
                    size: filesize(formValues.file.maxSize.default, {
                      output: 'string',
                      locale: intl.locale,
                      localeOptions: intl.formats.date,
                    }),
                  }),
              path: ['value'],
            };
          },
        )
        .refine(
          value => {
            if (value.type !== 'text') {
              return true;
            }

            if (!value.variable.validationCustom) {
              return true;
            }

            const regexp = new RegExp(value.variable.validationCustom);
            const plainText = value.value
              .getCurrentContent()
              .getPlainText('\n');
            return regexp.test(plainText);
          },
          value => {
            return {
              message:
                value.variable.validationFailedMessage ||
                intl.formatMessage(errorMessages.fieldInvalidFormat),
              path: ['value'],
            };
          },
        ),
    ),
    targetAgents: z.array(agentSelectOptionSchema),
  }) satisfies z.ZodSchema<FormValueFormValues>;
};

export interface UseFormValueFormOptions {
  defaultValues?: Partial<FormValueFormValues>;
  disabled?: boolean;
  onSubmit?(values: Scalars<'SendFormInput'>): Promise<void>;
  onTyping?(isTyping: boolean): void;
  targetAgents?: { disabled?: boolean };
  validation?: Pick<FormValueFormValidationOptions, 'formValues'>;
}

export const useFormValueForm = ({
  defaultValues,
  disabled,
  onSubmit,
  onTyping,
  targetAgents,
  validation,
}: UseFormValueFormOptions) => {
  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();
  const { ref: formRef, window: win } = useWindow();
  const validationSchema = useMemo(
    () =>
      zodResolver(
        createValidationSchema({
          intl,
          window: win ?? undefined,
          ...validation,
        }),
      ),
    [intl, validation, win],
  );

  const form = useForm<FormValueFormValues>({
    mode: 'all',
    defaultValues: {
      ...defaultFormValueFormValues,
      ...defaultValues,
    },
    disabled,
    resolver: validationSchema,
  });

  const { setError, watch } = form;

  const submit = async (values: FormValueFormValues) => {
    try {
      const FileKlass = win?.File ?? File;

      await onSubmit?.({
        formValues: values.formValues.map(formValue => {
          const value: Scalars<'FormValueInput'> = {
            variable: formValue.variable.id,
          };

          switch (formValue.type) {
            case 'switch':
              value.value = formValue.value ? 'true' : 'false';
              break;
            case 'checkbox':
              value.choices = formValue.value;
              break;
            case 'date':
              if (formValue.value !== null) {
                value.value = formatDate(formValue.value);
              }

              break;
            case 'file':
              if (
                formValue.value instanceof FileKlass ||
                formValue.value instanceof File
              ) {
                value.file = {
                  file: formValue.value,
                  metaInfo: JSON.stringify({
                    lastModified: formValue.value.lastModified,
                    lastModifiedDate: (formValue.value as any).lastModifiedDate,
                    name: formValue.value.name,
                    size: formValue.value.size,
                    type: formValue.value.type,
                  }),
                };
              } else if (formValue.value !== null) {
                value.file = { id: formValue.value.id };
              }

              break;
            case 'multi-select':
              value.choices = formValue.value.map(opt => opt.value);
              break;
            case 'number':
              if (formValue.value !== null) {
                value.value = formValue.value.toString();
              }

              break;
            case 'radio':
              if (formValue.value !== null) {
                value.choices = [formValue.value];
              }

              break;
            case 'single-select':
              if (formValue.value !== null) {
                value.choices = [formValue.value.value];
              }

              break;
            case 'text':
              value.value = formValue.value
                .getCurrentContent()
                .getPlainText('\n');
              break;
          }

          return value;
        }),
        targetAgents: targetAgents?.disabled
          ? null
          : values.targetAgents.map(option => option.value),
      });
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const errorGroups = err.groupErrorsByField({
          intl,
          isErrorField: isFormValueFormErrorField,
        });

        // Set the errors in the form using setError
        Object.entries(errorGroups).forEach(([field, errors]) => {
          if (!errors) {
            return;
          }

          setError(field as FormValueFormErrorField, {
            message: errors[0]?.message,
          });
        });

        return;
      }

      if (err instanceof Error) {
        setError('root', {
          message: formatErrorMessage(err),
        });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: formatErrorMessage(),
      });
    }
  };

  useEffect(() => {
    if (disabled) {
      return;
    }

    const subscription = watch(values => {
      const hasTargetAgents = !!values.targetAgents?.length;

      const hasFormValues =
        values.formValues?.some(field => {
          if (!field) {
            return false;
          }

          switch (field.type) {
            case 'text':
              return field.value instanceof EditorState
                ? !isDraftInputEmpty(field.value)
                : false;
            case 'number':
            case 'date':
            case 'file':
            case 'single-select':
            case 'radio':
              return field.value !== null && typeof field.value !== 'undefined';
            case 'multi-select':
            case 'checkbox':
              return !!field.value?.length;
            case 'switch':
              return typeof field.value !== 'undefined';
          }

          return false;
        }) ?? false;

      onTyping?.(hasTargetAgents || hasFormValues);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [disabled, onTyping, watch]);

  return { form, formRef, submit };
};
