import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';
import { PanelItem } from '../panel-item/panel-item';
import { PanelUser, PanelUser_AgentFragment } from './panel-user';

export const CreatedBy_KnowledgeBaseNodeFragment = gql(
  `
  fragment CreatedBy_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    createdAt

    createdBy {
      ...PanelUser_AgentFragment
    }
  }
`,
  [PanelUser_AgentFragment],
);

interface CreatedByProps {
  node: FragmentOf<typeof CreatedBy_KnowledgeBaseNodeFragment>;
}

export const CreatedBy: FunctionComponent<CreatedByProps> = props => {
  const { node: nodeProp } = props;
  const node = readFragment(CreatedBy_KnowledgeBaseNodeFragment, nodeProp);

  if (!node.createdBy) {
    return null;
  }

  return (
    <PanelItem
      label={
        <FormattedMessage
          id="knowledge-base-side-panel.created-by"
          defaultMessage="Created by"
          description="Knowledge base side panel created by label text"
        />
      }
    >
      <PanelUser agent={node.createdBy} timestamp={node.createdAt} />
    </PanelItem>
  );
};
