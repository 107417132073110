import { OptionBoxTemplate } from '@allganize/data-access-alli-enums';
import { useEventCallback } from '@allganize/hooks';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';
import { Scalars } from '../gql';

export interface FaqCarouselOption {
  readonly optionBoxTemplate: Scalars<'OptionBoxTemplate'> | null;
  readonly reusable: boolean;
  value: number;
  question: string | null;
  answer: string | null;
}

export interface FaqCarouselFormValues {
  selected: FaqCarouselOption | null;
}

const defaultFaqCarouselFormValues: FaqCarouselFormValues = {
  selected: null,
};

type FaqCarouselFormErrorField = FieldPath<FaqCarouselFormValues> | 'root';
const faqCarouselFormErrorFields: FaqCarouselFormErrorField[] = [
  'selected',
  'root',
];

const isFaqCarouselFormErrorField = (
  field: any,
): field is FaqCarouselFormErrorField =>
  faqCarouselFormErrorFields.includes(field);

const errorMessages = defineMessages({
  'faqCarouselOption-required': {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
});

const createValidationSchema = (intl: IntlShape) => {
  const faqCarouselOptionSchema = z.object(
    {
      optionBoxTemplate: z.nativeEnum(OptionBoxTemplate).nullable(),
      reusable: z.boolean(),
      value: z.number(),
      question: z.string().nullable(),
      answer: z.string().nullable(),
    },
    {
      invalid_type_error: intl.formatMessage(
        errorMessages['faqCarouselOption-required'],
      ),
      required_error: intl.formatMessage(
        errorMessages['faqCarouselOption-required'],
      ),
    },
  ) satisfies z.ZodSchema<FaqCarouselOption>;

  return z.object({
    selected: faqCarouselOptionSchema.nullable(),
  }) satisfies z.ZodSchema<FaqCarouselFormValues>;
};

export interface UseFaqCarouselFormOptions {
  disabled?: boolean;
  onSubmit?(values: FaqCarouselFormValues): Promise<void>;
}

export const useFaqCarouselForm = ({
  disabled,
  onSubmit,
}: UseFaqCarouselFormOptions) => {
  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();
  const validationSchema = useMemo(
    () => zodResolver(createValidationSchema(intl)),
    [intl],
  );

  const form = useForm<FaqCarouselFormValues>({
    mode: 'all',
    defaultValues: defaultFaqCarouselFormValues,
    disabled,
    resolver: validationSchema,
  });

  const { reset, setError } = form;

  const submit = useEventCallback(async (values: FaqCarouselFormValues) => {
    try {
      await onSubmit?.(values);
      reset();
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const errorGroups = err.groupErrorsByField({
          intl,
          isErrorField: isFaqCarouselFormErrorField,
        });

        // Set the errors in the form using setError
        Object.entries(errorGroups).forEach(([field, errors]) => {
          if (!errors) {
            return;
          }

          setError(field as FaqCarouselFormErrorField, {
            message: errors[0]?.message,
          });
        });

        return;
      }

      if (err instanceof Error) {
        setError('root', {
          message: formatErrorMessage(err),
        });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: formatErrorMessage(),
      });
    }
  });

  return { form, submit };
};
