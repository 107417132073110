import { InputField } from '@allganize/ui-input';
import { Control, useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface NameFieldProps<T extends { name: string }> {
  control: Control<T>;
  disabled?: boolean;
}

export const NameField = <T extends { name: string }>(
  props: NameFieldProps<T>,
) => {
  const { control, disabled } = props;
  const {
    field: { ref: fieldRef, ...field },
    fieldState,
    // @ts-expect-error generic type is not inferred correctly
  } = useController<T, 'name'>({
    control,
    name: 'name',
    disabled,
  });
  const split = field.value.split('.');
  const extname = split.length > 1 ? split[split.length - 1] : '';
  const filename =
    split.length > 1 ? split.slice(0, -1).join('.') : field.value;

  const handleChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    field.onChange([ev.target.value, extname].join('.'));
  };

  return (
    <InputField
      {...field}
      value={filename}
      onChange={handleChange}
      inputRef={fieldRef}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      required
      fullWidth
      label={
        <FormattedMessage
          id="FAQ.Documents.NewFile.Name.Label"
          defaultMessage="Name"
          description="FAQ documents New File Name label text"
        />
      }
    />
  );
};
