import { formControlState } from '@allganize/ui-form';
import { ListItemText } from '@allganize/ui-list';
import { useTheme } from '@allganize/ui-theme';
import { useFormControl } from '@mui/material/FormControl';
import clsx from 'clsx';
import { lighten } from 'polished';
import { FormatOptionLabelMeta, GroupBase, Props } from 'react-select';
import { components as defaultComponents, selectClasses } from '../components';
import { HighlightedText } from '../components/highlighted-text';
import { createFilter } from '../filters';
import { SelectProps } from './select-type-map';

const defaultFilterOption = createFilter();

export const useSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
  AdditionalProps = {},
>(
  props: SelectProps<Option, IsMulti, Group> & AdditionalProps,
): Props<Option, IsMulti, Group> & AdditionalProps => {
  const {
    classes,
    color: colorProp,
    components,
    disabled,
    error,
    filterOption = defaultFilterOption,
    getOptionDescription,
    ...other
  } = props;
  const theme = useTheme();
  const muiFormControl = useFormControl();
  const fcs = formControlState({
    props,
    muiFormControl,
    states: ['required', 'error', 'color', 'disabled'],
  });
  const color = fcs.color || 'primary';

  const formatOptionLabel = (
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>,
  ) => {
    const label =
      props.getOptionLabel?.(data) ||
      ((data as { label: unknown }).label as string);

    if (formatOptionLabelMeta.context === 'menu') {
      const description =
        getOptionDescription?.(data) ||
        ((data as { description: unknown }).description as React.ReactNode);

      return (
        <ListItemText
          primary={
            <HighlightedText
              text={label}
              query={formatOptionLabelMeta.inputValue}
              options={{ insideWords: true, findAllOccurrences: true }}
            />
          }
          secondary={
            typeof description === 'string' ? (
              <HighlightedText
                text={description}
                query={formatOptionLabelMeta.inputValue}
                options={{ insideWords: true, findAllOccurrences: true }}
              />
            ) : (
              description
            )
          }
        />
      );
    }

    return label;
  };

  // @ts-expect-error additional props
  return {
    filterOption,
    formatOptionLabel,
    unstyled: true,
    menuPlacement: 'auto',
    ...other,
    isRtl: theme.direction === 'rtl',
    isDisabled: fcs.disabled,
    required: fcs.required,
    classNames: {
      clearIndicator: () => clsx(classes?.clearIndicator),
      container: ({ isDisabled, isRtl }) =>
        clsx(classes?.container, {
          [classes?.disabled ?? '']: isDisabled,
          [classes?.rtl ?? '']: isRtl,
        }),
      control: ({ isDisabled, isFocused, menuIsOpen }) =>
        clsx({ [selectClasses.error]: fcs.error }, classes?.control, {
          [classes?.disabled ?? '']: isDisabled,
          [classes?.focused ?? '']: muiFormControl?.focused || isFocused,
          [classes?.controlOpen ?? '']: menuIsOpen,
          [classes?.error ?? '']: fcs.error,
        }),
      dropdownIndicator: () => clsx(classes?.dropdownIndicator),
      group: () => clsx(classes?.group),
      groupHeading: () => clsx(classes?.groupHeading),
      indicatorsContainer: () => clsx(classes?.indicatorsContainer),
      input: ({ isHidden }) =>
        clsx(classes?.input, { [classes?.inputHidden ?? '']: isHidden }),
      loadingIndicator: () => clsx(classes?.loadingIndicator),
      loadingMessage: () => clsx(classes?.loadingMessage),
      menu: ({ placement }) =>
        clsx(classes?.menu, {
          [classes?.menuPlacementTop ?? '']: placement === 'top',
          [classes?.menuPlacementBottom ?? '']: placement === 'bottom',
        }),
      menuList: () => clsx(classes?.menuList),
      menuPortal: () => clsx(classes?.menuPortal),
      multiValue: () => clsx(classes?.multiValue),
      multiValueLabel: () => clsx(classes?.multiValueLabel),
      multiValueRemove: () => clsx(classes?.multiValueRemove),
      noOptionsMessage: () => clsx(classes?.noOptionsMessage),
      option: ({ isDisabled, isFocused, isSelected }) =>
        clsx(classes?.option, {
          [classes?.focused ?? '']: isFocused,
          [classes?.selected ?? '']: isSelected,
          [classes?.disabled ?? '']: isDisabled,
        }),
      placeholder: () => clsx(classes?.placeholder),
      singleValue: () => clsx(classes?.singleValue),
      valueContainer: ({ hasValue, isDisabled, isMulti }) =>
        clsx(classes?.valueContainer, {
          [classes?.disabled ?? '']: isDisabled,
          [classes?.multiple ?? '']: isMulti,
          [classes?.empty ?? '']: !hasValue,
        }),
    },
    components: {
      ...defaultComponents,
      ...components,
      IndicatorSeparator: null,
    },
    theme: {
      borderRadius: theme.radius.sm,
      spacing: {
        baseUnit: 4,
        controlHeight: 36,
        menuGutter: 8,
      },
      colors: {
        primary: theme.palette[color].main,
        primary25: theme.palette[color].light,
        primary50: theme.palette[color].main,
        primary75: theme.palette[color].dark,
        danger: theme.palette.error.main,
        dangerLight: lighten(0.3, theme.palette.error.main),
        neutral0: theme.palette.common.white,
        neutral5: theme.palette.grayAlpha[50],
        neutral10: theme.palette.grayAlpha[100],
        neutral20: theme.palette.grayAlpha[200],
        neutral30: theme.palette.grayAlpha[300],
        neutral40: theme.palette.grayAlpha[400],
        neutral50: theme.palette.grayAlpha[500],
        neutral60: theme.palette.grayAlpha[600],
        neutral70: theme.palette.grayAlpha[700],
        neutral80: theme.palette.grayAlpha[800],
        neutral90: theme.palette.grayAlpha[900],
      },
    },
  };
};
