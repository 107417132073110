import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { FunctionComponent } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import {
  AlliGraphQLError,
  AlliGraphQLErrorFragment,
} from '../alli-graphql-error/alli-graphql-error';
import { isNetworkError } from '../is-network-error/is-network-error';

export interface FormattedErrorMessageProps
  extends Omit<
    React.ComponentProps<typeof FormattedMessage>,
    keyof MessageDescriptor | 'values'
  > {
  error?: Error | AlliGraphQLErrorFragment;
}

export const FormattedErrorMessage: FunctionComponent<
  FormattedErrorMessageProps
> = props => {
  const { error, ...other } = props;

  if (!error) {
    return (
      <FormattedMessage
        {...other}
        {...AlliGraphQLError.getMallyErrorMessageDescriptor({
          __typename: 'Error',
          key: UserQueryTypes.MallyError.UNKNOWN,
          message: null,
          info: null,
          field: null,
        })}
      />
    );
  }

  if (error instanceof Error) {
    if (isNetworkError(error)) {
      return (
        <FormattedMessage
          {...other}
          id="Errors.network"
          defaultMessage="A network error occurred. Please try again."
          description="Network error message"
        />
      );
    }

    return (
      <FormattedMessage
        {...other}
        id={`MallyError.${error.message}`}
        defaultMessage={error.message}
        description={`Error message for ${error.message}`}
      />
    );
  }

  return (
    <FormattedMessage
      {...other}
      {...AlliGraphQLError.getMallyErrorMessageDescriptor(error)}
    />
  );
};
