export const formatUserName = (
  user: {
    firstName: string | null;
    lastName: string | null;
  },
  locale: 'en' | 'ja' | 'ko' | (string & {}) = 'en',
): string => {
  let name = (user.firstName || '').trim();

  if (user.lastName && user.lastName.trim()) {
    if (locale === 'ja') {
      name = `${user.lastName} ${name}`;
    } else if (locale === 'ko') {
      name = `${user.lastName}${name}`;
    } else {
      name = `${name} ${user.lastName}`;
    }
  }

  return name;
};
