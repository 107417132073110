import { ChatFormValues } from '@allganize/alli-chat-form';
import { SidePanelContext } from '@allganize/alli-works-side-panel';
import { IconButton } from '@allganize/ui-button';
import { IcAppMarket, IcDocuments, IcUpload } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { css, useTheme } from '@emotion/react';
import { useContext, useEffect } from 'react';
import { Control, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

export const DocumentListSidePanelButton = ({
  control,
}: {
  control: Control<ChatFormValues>;
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const { selectedKnowledgeBaseIds, openDocumentListTab } =
    useContext(SidePanelContext);
  const selectedKnowledgeBaseCount = selectedKnowledgeBaseIds.length;

  const { field: knowledgeBaseNodeIdsField } = useController({
    control,
    name: 'knowledgeBaseNodeIds',
  });

  useEffect(() => {
    knowledgeBaseNodeIdsField.onChange(selectedKnowledgeBaseIds);
  }, [selectedKnowledgeBaseIds]);

  return (
    <IconButton
      shape="rounded"
      onClick={() => openDocumentListTab()}
      variant={selectedKnowledgeBaseIds.length ? 'outlined' : 'ghost'}
      css={css`
        position: relative;
      `}
    >
      <IcDocuments />
      {selectedKnowledgeBaseCount ? (
        <div
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
            background-color: ${theme.palette.unstable_background.information
              .default};
            color: ${theme.palette.foreground.inverse};
            border-radius: ${theme.radius.round}px;
          `}
        >
          <Text
            variant="body12"
            aria-label={intl.formatMessage(
              {
                id: 'document-list-side-panel-button__selected-knowledge-base-count-badge.aria-label',
                defaultMessage: '{count} knowledge bases are selected',
                description:
                  'document list side panel button selected knowledge bases count badge aria label',
              },
              { count: selectedKnowledgeBaseCount },
            )}
            css={css`
              height: 20px;
              min-width: 20px;
              padding: 0 6px;
              text-align: center;
            `}
          >
            {selectedKnowledgeBaseCount}
          </Text>
        </div>
      ) : null}
    </IconButton>
  );
};

export const UploadDocumentButton = () => {
  const { uploadDocument } = useContext(SidePanelContext);
  return (
    <IconButton shape="rounded" onClick={uploadDocument}>
      <IcUpload />
    </IconButton>
  );
};

export const AppListSidePanelButton = () => {
  const { openAppListTab } = useContext(SidePanelContext);
  return (
    <IconButton shape="rounded" onClick={openAppListTab}>
      <IcAppMarket />
    </IconButton>
  );
};
