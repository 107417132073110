import { Avatar, AvatarProps } from '@allganize/ui-avatar';
import { IcPeople } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';

export interface AccessControlAvatarProps extends Pick<AvatarProps, 'src'> {
  variant?: 'group' | 'user';
  name?: string | null;
}

export const AccessControlAvatar = forwardRef<
  HTMLDivElement,
  AccessControlAvatarProps
>(({ variant = 'user', name, src }, ref) => {
  const theme = useTheme();

  if (variant === 'group') {
    return (
      <div
        css={css`
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: ${theme.palette.foreground.helper};
          width: 32px;
          height: 32px;
          font-size: 24px;
        `}
        ref={ref}
      >
        <IcPeople fontSize="inherit" />
      </div>
    );
  }

  return (
    <Avatar
      variant="circular"
      ref={ref}
      src={src}
      children={src ? undefined : name?.[0]}
    />
  );
});
