import { Alert, alertClasses } from '@allganize/ui-alert';
import { IcInfo } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccessControlContainer } from '../access-control-container/access-control-container';
import { AccessControlSelectProps } from '../access-control-select/access-control-select-type-map';
import { AccessControlSwitch } from '../access-control-switch/access-control-switch';
import { AccessControlAgentField } from './access-control-agent-field';
import {
  AccessControlFieldBase,
  AccessControlFieldBaseProps,
} from './access-control-field-base';
import {
  AccessControlFieldWithTab,
  AccessControlFieldWithTabProps,
} from './access-control-field-with-tab';
import { AccessControlUserField } from './access-control-user-field';
import { AccessControlFieldPropBase } from './access-control-field-types';

export type AccessControlFieldProps = AccessControlFieldBaseProps &
  AccessControlFieldPropBase &
  Pick<AccessControlSelectProps, 'onAgentOptionsLoad'> &
  Pick<AccessControlFieldWithTabProps, 'overwriteFolderAccess'>;

export const AccessControlField = forwardRef<
  HTMLFieldSetElement,
  AccessControlFieldProps
>(
  (
    {
      accessAgents,
      accessUserFilters,
      disabled,
      overwriteFolderAccess,
      ...others
    },
    ref,
  ) => {
    const theme = useTheme();

    if (accessAgents && accessUserFilters) {
      return (
        <AccessControlFieldWithTab
          accessAgents={accessAgents}
          accessUserFilters={accessUserFilters}
          disabled={disabled}
          {...others}
          overwriteFolderAccess={overwriteFolderAccess}
          ref={ref}
        />
      );
    }

    const {
      viewAccessToAgent,
      viewAccessToUser,
      label,
      projectId,
      SelectProps,
      ...other
    } = others;
    const showAgentAlert = viewAccessToAgent?.value;
    const showUserAlert = viewAccessToUser?.value;

    return (
      <AccessControlFieldBase
        label={
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr auto;
              width: 100%;
            `}
          >
            {label ?? (
              <FormattedMessage
                id="access-control.field.label"
                defaultMessage="Who has access"
                description="Access control field label"
              />
            )}
            {viewAccessToAgent && (
              <AccessControlSwitch
                {...viewAccessToAgent}
                label={
                  <FormattedMessage
                    id="access-control.field.viewAccessToAgent.label"
                    defaultMessage="Any agents in this project can view"
                    description="View access to agent label"
                  />
                }
                disabled={disabled}
                onChange={(_, value) => viewAccessToAgent.onChange(value)}
              />
            )}
            {viewAccessToUser && (
              <AccessControlSwitch
                {...viewAccessToUser}
                label={
                  <FormattedMessage
                    id="access-control.field.viewAccessToUser.label"
                    defaultMessage="Any customers(SDK/API) can view"
                    description="View access to user label"
                  />
                }
                disabled={disabled}
                onChange={(_, value) => viewAccessToUser.onChange(value)}
              />
            )}
          </div>
        }
        {...other}
        ref={ref}
      >
        {(showAgentAlert || showUserAlert) && (
          <div
            css={css`
              padding: 0 0 12px 0;

              /* TODO: remove these styles after update ui component */
              .${alertClasses.root} {
                border: 1px solid ${theme.palette.border.information};
                background-color: ${theme.palette.unstable_background
                  .information.subtle};
                color: ${theme.palette.foreground.default};
                padding: 0 12px;
              }

              .${alertClasses.icon} {
                color: ${theme.palette.foreground.information};
                margin-right: 4px;
              }
            `}
          >
            {showAgentAlert && (
              <Alert color="info" icon={<IcInfo />}>
                <FormattedMessage
                  id="access-control.field.viewAccessToAgent.alert"
                  defaultMessage="The above option allows all agents, including those not listed below, to view."
                  description="View access to user alert message"
                />
              </Alert>
            )}
            {showUserAlert && (
              <Alert color="info" icon={<IcInfo />}>
                <FormattedMessage
                  id="access-control.field.viewAccessToUser.alert"
                  defaultMessage="The above option allows all customers, including those not listed below, to view."
                  description="View access to user alert message"
                />
              </Alert>
            )}
          </div>
        )}
        {accessUserFilters && (
          <AccessControlContainer>
            <AccessControlUserField
              projectId={projectId}
              SelectProps={SelectProps as any}
              disabled={disabled}
              {...accessUserFilters}
            />
          </AccessControlContainer>
        )}
        {accessAgents && (
          <AccessControlContainer>
            <AccessControlAgentField
              projectId={projectId}
              SelectProps={SelectProps as any}
              disabled={disabled}
              {...accessAgents}
            />
          </AccessControlContainer>
        )}
      </AccessControlFieldBase>
    );
  },
);
