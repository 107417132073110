import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import useSlot from '@mui/material/utils/useSlot';
import { FunctionComponent } from 'react';
import {
  AccessControlList,
  AccessControlList_KnowledgeBaseNodeFragment,
  AccessControlListProps,
} from '../access-control-list/access-control-list';
import {
  FileSize,
  FileSize_KnowledgeBaseNodeFragment,
} from '../file-size/file-size';
import {
  FileStatus,
  FileStatus_KnowledgeBaseNodeFragment,
  FileStatusProps,
} from '../file-status/file-status';
import { FragmentOf, gql, readFragment } from '../gql';
import {
  Hashtags,
  Hashtags_KnowledgeBaseNodeFragment,
  Hashtags_ProjectFragment,
  HashtagsProps,
} from '../hashtags/hashtags';
import {
  Location,
  Location_KnowledgeBaseNodeFragment,
  LocationSlotProps,
  LocationSlots,
} from '../location/location';
import { PanelItemGroup } from '../panel-item/panel-item-group';
import {
  CreatedBy,
  CreatedBy_KnowledgeBaseNodeFragment,
} from '../panel-user/created-by';
import {
  UpdatedBy,
  UpdatedBy_KnowledgeBaseNodeFragment,
} from '../panel-user/updated-by';
import {
  ProcessState,
  ProcessState_KnowledgeBaseNodeFragment,
} from '../process-state/process-state';
import {
  QnaGeneration,
  QnaGeneration_KnowledgeBaseNodeFragment,
  QnaGenerationProps,
} from '../qna-generation/qna-generation';
import {
  SidePanelActions,
  SidePanelActions_KnowledgeBaseNodeFragment,
  SidePanelActionsProps,
} from '../side-panel-actions/side-panel-actions';
import { Thumbnail, ThumbnailProps } from '../thumbnail/thumbnail';

export const FileSidePanel_KnowledgeBaseNodeFragment = gql(
  `
  fragment FileSidePanel_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id

    ...FileStatus_KnowledgeBaseNodeFragment
    ...Hashtags_KnowledgeBaseNodeFragment
    ...ProcessState_KnowledgeBaseNodeFragment
    ...FileSize_KnowledgeBaseNodeFragment
    ...CreatedBy_KnowledgeBaseNodeFragment
    ...UpdatedBy_KnowledgeBaseNodeFragment
    ...AccessControlList_KnowledgeBaseNodeFragment
    ...SidePanelActions_KnowledgeBaseNodeFragment
    ...QnaGeneration_KnowledgeBaseNodeFragment
    ...Location_KnowledgeBaseNodeFragment
  }
`,
  [
    FileStatus_KnowledgeBaseNodeFragment,
    Hashtags_KnowledgeBaseNodeFragment,
    ProcessState_KnowledgeBaseNodeFragment,
    FileSize_KnowledgeBaseNodeFragment,
    CreatedBy_KnowledgeBaseNodeFragment,
    UpdatedBy_KnowledgeBaseNodeFragment,
    AccessControlList_KnowledgeBaseNodeFragment,
    SidePanelActions_KnowledgeBaseNodeFragment,
    QnaGeneration_KnowledgeBaseNodeFragment,
    Location_KnowledgeBaseNodeFragment,
  ],
);

export const FileSidePanel_ProjectFragment = gql(
  `
  fragment FileSidePanel_ProjectFragment on Project {
    id
    ...Hashtags_ProjectFragment
  }
`,
  [Hashtags_ProjectFragment],
);

export interface FileSidePanelSlots extends LocationSlots {
  /**
   * @default AccessControlList
   */
  accessControlList?: React.ElementType;
  /**
   * @default SidePanelActions
   */
  actions?: React.ElementType;
  /**
   * @default FileStatus
   */
  fileStatus?: React.ElementType;
  /**
   * @default Hashtags
   */
  hashtags?: React.ElementType;
  /**
   * @default QnaGeneration
   */
  qnaGeneration?: React.ElementType;
  /**
   * @default Thumbnail
   */
  thumbnail?: React.ElementType;
}

export interface FileSidePanelSlotProps extends LocationSlotProps {
  accessControlList: SlotProps<
    React.ElementType<AccessControlListProps>,
    {},
    {}
  >;
  actions: SlotProps<React.ElementType<SidePanelActionsProps>, {}, {}>;
  fileStatus: SlotProps<React.ElementType<FileStatusProps>, {}, {}>;
  hashtags: SlotProps<React.ElementType<HashtagsProps>, {}, {}>;
  qnaGeneration: SlotProps<React.ElementType<QnaGenerationProps>, {}, {}>;
  thumbnail: SlotProps<React.ElementType<ThumbnailProps>, {}, {}>;
}

export type FileSidePanelSlotsAndSlotProps = CreateSlotsAndSlotProps<
  FileSidePanelSlots,
  FileSidePanelSlotProps
>;

export interface FileSidePanelProps
  extends Pick<HashtagsProps, 'onHashtagClick'>,
    FileSidePanelSlotsAndSlotProps {
  data: FragmentOf<typeof FileSidePanel_KnowledgeBaseNodeFragment>;
  project: FragmentOf<typeof FileSidePanel_ProjectFragment>;
}

export const FileSidePanel: FunctionComponent<FileSidePanelProps> = props => {
  const {
    data: dataProp,
    project: projectProp,
    slotProps = {},
    slots = {},
  } = props;
  const data = readFragment(FileSidePanel_KnowledgeBaseNodeFragment, dataProp);
  const project = readFragment(FileSidePanel_ProjectFragment, projectProp);

  const [AccessControlListSlot, accessControlListSlotProps] = useSlot(
    'accessControlList',
    {
      elementType: AccessControlList,
      // @ts-expect-error internal prop
      externalForwardedProps: { slots, slotProps },
      ownerState: {},
      className: undefined,
    },
  );
  const [ActionsSlot, actionsSlotProps] = useSlot('actions', {
    elementType: SidePanelActions,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });
  const [FileStatusSlot, fileStatusSlotProps] = useSlot('fileStatus', {
    elementType: FileStatus,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });
  const [HashtagsSlot, hashtagsSlotProps] = useSlot('hashtags', {
    elementType: Hashtags,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });
  const [QnaGenerationSlot, qnaGenerationSlotProps] = useSlot('qnaGeneration', {
    elementType: QnaGeneration,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });
  const [ThumbnailSlot, thumbnailSlotProps] = useSlot('thumbnail', {
    elementType: Thumbnail,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });

  return (
    <>
      <ThumbnailSlot
        projectId={project.id}
        knowledgeBaseId={data.id}
        {...thumbnailSlotProps}
      />

      <PanelItemGroup>
        <FileStatusSlot
          projectId={project.id}
          data={data}
          {...fileStatusSlotProps}
        />

        <HashtagsSlot node={data} project={project} {...hashtagsSlotProps} />
      </PanelItemGroup>

      <AccessControlListSlot node={data} {...accessControlListSlotProps} />

      <PanelItemGroup>
        <ProcessState node={data} />

        <CreatedBy node={data} />

        <UpdatedBy node={data} />

        <FileSize node={data} />

        <Location node={data} slotProps={slotProps} slots={slots} />
      </PanelItemGroup>

      {slotProps.qnaGeneration && (
        <QnaGenerationSlot
          key={data.id}
          node={data}
          projectId={project.id}
          {...qnaGenerationSlotProps}
        />
      )}

      <ActionsSlot node={data} {...actionsSlotProps} />
    </>
  );
};
