import {
  BaseDateTimePickerProps as MuiBaseDateTimePickerProps,
  useDateTimePickerDefaultizedProps as useMuiDateTimePickerDefaultizedProps,
} from '@mui/x-date-pickers/DateTimePicker/shared';
import {
  DateCalendarSlotsComponent,
  DateCalendarSlotsComponentsProps,
} from '../date-calendar';
import {
  DateTimePickerTabs,
  DateTimePickerTabsProps,
  ExportedDateTimePickerTabsProps,
} from '../date-time-picker-tabs';
import {
  DateTimePickerToolbar,
  DateTimePickerToolbarProps,
  ExportedDateTimePickerToolbarProps,
} from '../date-time-picker-toolbar';
import { DateViewRendererProps } from '../date-view-renderers';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import {
  BaseClockProps,
  BaseDateValidationProps,
  BaseTimeValidationProps,
  DateOrTimeViewWithMeridiem,
  DefaultizedProps,
  TimeViewWithMeridiem,
} from '../internals/models';
import { UncapitalizeObjectKeys } from '../internals/utils/slots-migration';
import { LocalizedComponent } from '../locales/utils/pickers-locale-text-api';
import {
  TimeClockSlotsComponent,
  TimeClockSlotsComponentsProps,
} from '../time-clock';
import { TimeViewRendererProps } from '../time-view-renderers';

export interface BaseDateTimePickerSlotsComponent
  extends DateCalendarSlotsComponent,
    TimeClockSlotsComponent {
  /**
   * Tabs enabling toggling between date and time pickers.
   * @default DateTimePickerTabs
   */
  Tabs?: React.ElementType<DateTimePickerTabsProps>;
  /**
   * Custom component for the toolbar rendered above the views.
   * @default DateTimePickerToolbar
   */
  Toolbar?: React.JSXElementConstructor<DateTimePickerToolbarProps>;
}

export interface BaseDateTimePickerSlotsComponentsProps
  extends DateCalendarSlotsComponentsProps,
    TimeClockSlotsComponentsProps {
  /**
   * Props passed down to the tabs component.
   */
  tabs?: ExportedDateTimePickerTabsProps;
  /**
   * Props passed down to the toolbar component.
   */
  toolbar?: ExportedDateTimePickerToolbarProps;
}

export interface BaseDateTimePickerProps<
  TView extends DateOrTimeViewWithMeridiem,
> extends Omit<
    MuiBaseDateTimePickerProps<Date, TView>,
    | 'components'
    | 'componentsProps'
    | 'shouldDisableClock'
    | 'slots'
    | 'slotProps'
    | 'sx'
    | 'viewRenderers'
  > {
  /**
   * Overridable component slots.
   * @default {}
   */
  slots?: UncapitalizeObjectKeys<BaseDateTimePickerSlotsComponent>;
  /**
   * The props used for each component slot.
   * @default {}
   */
  slotProps?: BaseDateTimePickerSlotsComponentsProps;
  /**
   * Define custom view renderers for each section.
   * If `null`, the section will only have field editing.
   * If `undefined`, internally defined view will be the used.
   */
  viewRenderers?: Partial<
    PickerViewRendererLookup<
      Date | null,
      TView,
      DateViewRendererProps<TView> &
        TimeViewRendererProps<
          TimeViewWithMeridiem,
          BaseClockProps<TimeViewWithMeridiem>
        >,
      {}
    >
  >;
}

type UseDateTimePickerDefaultizedProps<
  TView extends DateOrTimeViewWithMeridiem,
  Props extends BaseDateTimePickerProps<TView>,
> = LocalizedComponent<
  DefaultizedProps<
    Props,
    | 'views'
    | 'openTo'
    | 'orientation'
    | 'ampm'
    | keyof BaseDateValidationProps<Date>
    | keyof BaseTimeValidationProps
  >
>;

export const useDateTimePickerDefaultizedProps = <
  TView extends DateOrTimeViewWithMeridiem,
  Props extends BaseDateTimePickerProps<TView>,
>(
  props: Props,
  name: string,
): UseDateTimePickerDefaultizedProps<TView, Props> =>
  // @ts-expect-error internal component
  useMuiDateTimePickerDefaultizedProps<Date, TView, Props>(
    {
      ...props,
      slots: {
        toolbar: DateTimePickerToolbar,
        tabs: DateTimePickerTabs,
        ...props.slots,
      },
    },
    name,
  );
