import { useId } from '@allganize/hooks';
import { IcArrowNavigateNext } from '@allganize/ui-icons';
import {
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemCheckbox,
  ListItemSecondaryText,
  ListItemText,
  listItemTextClasses,
} from '@allganize/ui-list';
import { Radio } from '@allganize/ui-radio';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { gql } from '../gql';
import { FaqCarouselOption } from './use-faq-carousel-form';

interface FaqCarouselOptionListItemProps
  extends Pick<ListItemButtonProps, 'onBlur' | 'onClick'> {
  checked?: boolean;
  disabled?: boolean;
  option: FaqCarouselOption;
}

export const FaqCarouselOptionListItem: FunctionComponent<
  FaqCarouselOptionListItemProps
> = props => {
  const { checked, disabled, onBlur, onClick, option } = props;
  const theme = useTheme();
  const reactId = useId();
  const labelId = `faq-carousel-option-list-item-${reactId}-${option.value}`;

  return (
    <ListItem
      css={[
        css`
          display: inline-flex;
          flex-direction: column;
          align-items: stretch;
          border-radius: ${theme.radius.md}px;
          border: 1px solid ${theme.palette.border.divider.default};
          background-color: ${theme.palette.unstable_background.white};
          overflow: hidden;
          width: auto;
          max-width: 480px;
        `,
        disabled &&
          css`
            border-color: ${theme.palette.border.disabled};
          `,
      ]}
      component="div"
      disablePadding
    >
      <ListItemButton
        css={css`
          padding: 7px 15px;
          align-items: flex-start;
        `}
        disabled={disabled}
        onBlur={onBlur}
        onClick={onClick}
        role={undefined}
      >
        <ListItemCheckbox
          css={css`
            display: none;
          `}
        >
          <Radio
            checked={checked}
            edge="start"
            inputProps={{
              'aria-labelledby': labelId,
            }}
            tabIndex={-1}
          />
        </ListItemCheckbox>

        <ListItemText
          css={css`
            .${listItemTextClasses.primary}
              + .${listItemTextClasses.secondary} {
              margin-top: 8px;
            }

            &:not(.${listItemTextClasses.disabled})
              .${listItemTextClasses.secondary} {
              color: ${theme.palette.foreground.default};
            }
          `}
          id={labelId}
          primary={option.question}
          primaryTextProps={{ variant: 'title14' }}
          secondary={option.answer}
          secondaryTextProps={{ variant: 'body14' }}
        />

        {option.optionBoxTemplate ===
          gql.scalar('OptionBoxTemplate', 'NEXT_PAGE') && (
          <ListItemSecondaryText
            css={css`
              margin-left: 4px;
              padding: 4px 0;
            `}
          >
            <IcArrowNavigateNext />
          </ListItemSecondaryText>
        )}
      </ListItemButton>
    </ListItem>
  );
};
