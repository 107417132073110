import { gql, ResultOf } from '../gql';
import { KnowledgeBaseFileIconVariant } from '../knowledge-base-file-icon';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const KnowledgeBaseTypenameIconVariant_KnowledgeBaseFragment = gql(`
  fragment KnowledgeBaseTypenameIconVariant_KnowledgeBaseFragment on KnowledgeBase {
    __typename
  }
`);

interface KnowledgeBaseTypenameIconVariantOptions {
  typename: ResultOf<
    typeof KnowledgeBaseTypenameIconVariant_KnowledgeBaseFragment
  >['__typename'];
  fileName?: string;
}

export const getKnowledgeBaseTypenameIconVariant = ({
  typename,
  fileName,
}: KnowledgeBaseTypenameIconVariantOptions): Exclude<
  KnowledgeBaseFileIconVariant,
  'fallback'
> | null => {
  switch (typename) {
    case 'CSVKnowledgeBase':
      return 'csv';
    case 'HWPKnowledgeBase':
      if (fileName?.toLowerCase()?.endsWith('.hwpx')) {
        return 'hwpx';
      }

      return 'hwp';
    case 'ImageKnowledgeBase':
      if (fileName?.toLowerCase()?.endsWith('.png')) {
        return 'png';
      }

      if (fileName?.toLowerCase()?.endsWith('.jpeg')) {
        return 'jpeg';
      }

      return 'jpg';
    case 'MSExcelKnowledgeBase':
      if (fileName?.toLowerCase()?.endsWith('.xlsx')) {
        return 'xlsx';
      }

      if (fileName?.toLowerCase()?.endsWith('.xlsm')) {
        return 'xlsm';
      }

      return 'xls';
    case 'MSPPTKnowledgeBase':
      if (fileName?.toLowerCase()?.endsWith('.pptx')) {
        return 'pptx';
      }

      return 'ppt';
    case 'MSDocsKnowledgeBase':
    case 'OldMSDocsKnowledgeBase':
      if (fileName?.toLowerCase()?.endsWith('.docx')) {
        return 'docx';
      }

      return 'doc';
    case 'PDFKnowledgeBase':
      return 'pdf';
    case 'TextKnowledgeBase':
      return 'txt';
  }

  return null;
};
