import { atom, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { Scalars } from '../gql';
import { UseCreateFolderFormOptions } from './use-create-folder-form';

interface CreateFolderState
  extends Omit<UseCreateFolderFormOptions, 'defaultValues'> {
  open: boolean;
}

export const createFolderAtom = atomFamily(
  (projectId: Scalars<'ID'>) =>
    atom<CreateFolderState>({
      open: false,
      parentId: null,
      projectId,
    }),
  (a, b) => a === b,
);

export const useCreateFolder = (projectId: Scalars<'ID'>) => {
  return useSetAtom(createFolderAtom(projectId));
};
