import { useCombinedRef, useId } from '@allganize/hooks';
import { Fade } from '@allganize/ui-transition';
import { useSlotProps } from '@mui/base/utils';
import { useRef } from 'react';
import { LocalizationProvider } from '../../../localization-provider';
import { BaseSingleInputFieldProps, FieldSection } from '../../../models';
import { PickersLayout } from '../../../pickers-layout';
import {
  PickersModalDialog,
  PickersModalDialogRoot,
} from '../../../pickers-modal-dialog';
import { DateOrTimeViewWithMeridiem } from '../../models';
import { onSpaceOrEnter } from '../../utils/utils';
import { usePicker } from '../use-picker';
import { useUtils } from '../use-utils';
import { InferError } from '../use-validation';
import {
  UseMobilePickerParams,
  UseMobilePickerProps,
} from './use-mobile-picker.types';

export const useMobilePicker = <
  TView extends DateOrTimeViewWithMeridiem,
  TExternalProps extends UseMobilePickerProps<TView, any, TExternalProps>,
>({
  props,
  getOpenDialogAriaText,
  ...pickerParams
}: UseMobilePickerParams<TView, TExternalProps>) => {
  const {
    slots: innerSlots,
    slotProps: innerSlotProps,
    className,
    format,
    formatDensity,
    timezone,
    name,
    label,
    inputRef,
    readOnly,
    disabled,
    localeText,
  } = props;

  const slots = {
    dialog: PickersModalDialogRoot,
    mobileTransition: Fade,
    ...innerSlots,
  };

  const utils = useUtils<Date>();
  const internalInputRef = useRef<HTMLInputElement>(null);
  const labelId = useId();
  const isToolbarHidden = innerSlotProps?.toolbar?.hidden ?? false;

  const {
    open,
    actions,
    layoutProps,
    renderCurrentView,
    fieldProps: pickerFieldProps,
  } = usePicker<Date | null, Date, TView, FieldSection, TExternalProps, {}>({
    ...pickerParams,
    props,
    inputRef: internalInputRef,
    autoFocusView: true,
    additionalViewProps: {},
    wrapperVariant: 'mobile',
  });

  const Field = slots.field;
  const fieldProps: Omit<
    BaseSingleInputFieldProps<
      Date | null,
      Date,
      FieldSection,
      InferError<TExternalProps>
    >,
    'referenceDate' | 'defaultValue'
  > = useSlotProps({
    elementType: Field,
    // @ts-expect-error internal prop
    externalSlotProps: innerSlotProps?.field,
    additionalProps: {
      ...pickerFieldProps,
      ...(isToolbarHidden && { id: labelId }),
      ...(!(disabled || readOnly) && {
        onClick: actions.onOpen,
        onKeyDown: onSpaceOrEnter(actions.onOpen),
      }),
      readOnly: readOnly ?? true,
      disabled,
      className,
      format,
      formatDensity,
      timezone,
      label,
      name,
    },
    ownerState: props,
  });

  // TODO: Move to `useSlotProps` when https://github.com/mui/material-ui/pull/35088 will be merged
  fieldProps.inputProps = {
    ...fieldProps.inputProps,
    'aria-label': getOpenDialogAriaText(pickerFieldProps.value, utils),
  };

  const slotsForField: BaseSingleInputFieldProps<
    Date | null,
    Date,
    FieldSection,
    unknown
  >['slots'] = {
    inputField: slots.inputField,
    ...fieldProps.slots,
  };

  const Layout = slots.layout ?? PickersLayout;

  const handleInputRef = useCombinedRef(
    internalInputRef,
    fieldProps.inputRef ?? null,
    inputRef ?? null,
  );

  let labelledById: string | undefined = labelId;
  if (isToolbarHidden) {
    if (label) {
      labelledById = `${labelId}-label`;
    } else {
      labelledById = undefined;
    }
  }
  const slotProps = {
    ...innerSlotProps,
    toolbar: {
      ...innerSlotProps?.toolbar,
      titleId: labelId,
    },
    mobilePaper: {
      'aria-labelledby': labelledById,
      ...innerSlotProps?.mobilePaper,
    },
  };

  const renderPicker = () => (
    <LocalizationProvider localeText={localeText}>
      <Field
        {...fieldProps}
        slots={slotsForField}
        slotProps={slotProps}
        inputRef={handleInputRef}
      />
      <PickersModalDialog
        {...actions}
        open={open}
        // @ts-expect-error internal prop
        slots={slots}
        slotProps={slotProps}
      >
        <Layout
          {...layoutProps}
          {...slotProps?.layout}
          slots={slots}
          slotProps={slotProps}
        >
          {renderCurrentView()}
        </Layout>
      </PickersModalDialog>
    </LocalizationProvider>
  );

  return { renderPicker };
};
