import { OverridableComponent } from '@allganize/types';
import {
  IcAlarm,
  IcAutoGraph,
  IcBusiness,
  IcCampaign,
  IcDescription,
  IcFavorite,
  IcFlag,
  IcGavel,
  IcLaptopMac,
  IcPerson,
  IcSecurity,
  IcSource,
  IcSteppers,
} from '@allganize/ui-icons';
import { forwardRef } from 'react';
import { IcAccount } from '../icons/ic-account';
import { IcAddLocation } from '../icons/ic-add-location';
import { IcAutoCreate } from '../icons/ic-auto-create';
import { IcBadge } from '../icons/ic-badge';
import { IcBus } from '../icons/ic-bus';
import { IcFileDownloadDone } from '../icons/ic-file-download-done';
import { IcFlightTakeoff } from '../icons/ic-flight-takeoff';
import { IcGroup } from '../icons/ic-group';
import { IcHealthAndSafety } from '../icons/ic-health-and-safety';
import { IcHelp } from '../icons/ic-help';
import { IcHomeWork } from '../icons/ic-home-work';
import { IcHub } from '../icons/ic-hub';
import { IcLocalLibrary } from '../icons/ic-local-library';
import { IcLocalMall } from '../icons/ic-local-mall';
import { IcNotification } from '../icons/ic-notification';
import { IcPalette } from '../icons/ic-palette';
import { IcRocketLaunch } from '../icons/ic-rocket-launch';
import { IcShoppingCart } from '../icons/ic-shopping-cart';
import { IcSignpost } from '../icons/ic-signpost';
import { IcTag } from '../icons/ic-tag';
import { IcVerified } from '../icons/ic-verified';
import { IcWysiwyg } from '../icons/ic-wysiwyg';
import { IcYard } from '../icons/ic-yard';
import { LLMAppCategoryIconTypeMap } from './llm-app-category-icon.types';

// @ts-expect-error overridable component
export const LLMAppCategoryIcon: OverridableComponent<LLMAppCategoryIconTypeMap> =
  forwardRef((props, ref) => {
    const { variant, ...other } = props;

    switch (variant) {
      case 'ic-add-location':
        return <IcAddLocation {...other} ref={ref} />;
      case 'ic-alarm':
        return <IcAlarm {...other} ref={ref} />;
      case 'ic-auto-create':
        return <IcAutoCreate {...other} ref={ref} />;
      case 'ic-auto-graph':
        return <IcAutoGraph {...other} ref={ref} />;
      case 'ic-badge':
        return <IcBadge {...other} ref={ref} />;
      case 'ic-business':
        return <IcBusiness {...other} ref={ref} />;
      case 'ic-campaign':
        return <IcCampaign {...other} ref={ref} />;
      case 'ic-palette':
        return <IcPalette {...other} ref={ref} />;
      case 'ic-bus':
        return <IcBus {...other} ref={ref} />;
      case 'ic-description':
        return <IcDescription {...other} ref={ref} />;
      case 'ic-file-download-done':
        return <IcFileDownloadDone {...other} ref={ref} />;
      case 'ic-flag':
        return <IcFlag {...other} ref={ref} />;
      case 'ic-flight-takeoff':
        return <IcFlightTakeoff {...other} ref={ref} />;
      case 'ic-gavel':
        return <IcGavel {...other} ref={ref} />;
      case 'ic-group':
        return <IcGroup {...other} ref={ref} />;
      case 'ic-health-and-safety':
        return <IcHealthAndSafety {...other} ref={ref} />;
      case 'ic-help':
        return <IcHelp {...other} ref={ref} />;
      case 'ic-home-work':
        return <IcHomeWork {...other} ref={ref} />;
      case 'ic-hub':
        return <IcHub {...other} ref={ref} />;
      case 'ic-laptop-mac':
        return <IcLaptopMac {...other} ref={ref} />;
      case 'ic-local-library':
        return <IcLocalLibrary {...other} ref={ref} />;
      case 'ic-local-mall':
        return <IcLocalMall {...other} ref={ref} />;
      case 'ic-tag':
        return <IcTag {...other} ref={ref} />;
      case 'ic-account':
        return <IcAccount {...other} ref={ref} />;
      case 'ic-notification':
        return <IcNotification {...other} ref={ref} />;
      case 'ic-person':
        return <IcPerson {...other} ref={ref} />;
      case 'ic-security':
        return <IcSecurity {...other} ref={ref} />;
      case 'ic-rocket-launch':
        return <IcRocketLaunch {...other} ref={ref} />;
      case 'ic-shopping-cart':
        return <IcShoppingCart {...other} ref={ref} />;
      case 'ic-signpost':
        return <IcSignpost {...other} ref={ref} />;
      case 'ic-source':
        return <IcSource {...other} ref={ref} />;
      case 'ic-steppers':
        return <IcSteppers {...other} ref={ref} />;
      case 'ic-verified':
        return <IcVerified {...other} ref={ref} />;
      case 'ic-wysiwyg':
        return <IcWysiwyg {...other} ref={ref} />;
      case 'ic-yard':
        return <IcYard {...other} ref={ref} />;
      case 'ic-favorite':
        return <IcFavorite {...other} ref={ref} />;
      default:
        break;
    }

    return null;
  });
