import { RichText, useRichText } from '@allganize/alli-chat-content';
import {
  DocumentEntities_KnowledgeBasePreviewFragment,
  DraftDocumentList,
  useDocumentEntities,
} from '@allganize/alli-chat-document';
import {
  UserFeedback,
  UserFeedback_ChatMRCInfoFragment,
  UserFeedbackProps,
  UserFeedbackSlotProps,
  UserFeedbackSlots,
} from '@allganize/alli-chat-feedback';
import { isDraftInputEmpty } from '@allganize/draft-input';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import useSlot from '@mui/material/utils/useSlot';
import { forwardRef } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

export const ChatMrcInfoListItem_ChatMRCInfoFragment = gql(
  `
  fragment ChatMrcInfoListItem_ChatMRCInfoFragment on ChatMRCInfo {
    id
    answer
    knowledgeBasePreview {
      ...DocumentEntities_KnowledgeBasePreviewFragment
    }

    ...UserFeedback_ChatMRCInfoFragment
  }
`,
  [
    DocumentEntities_KnowledgeBasePreviewFragment,
    UserFeedback_ChatMRCInfoFragment,
  ],
);

export interface ChatMrcInfoListItemSlots
  extends Pick<UserFeedbackSlots, 'dialog'> {
  /**
   * @default UserFeedback
   */
  userFeedback?: React.ElementType;
}

export interface ChatMrcInfoListItemSlotProps
  extends Pick<UserFeedbackSlotProps, 'dialog'> {
  userFeedback: SlotProps<React.ElementType<UserFeedbackProps>, {}, {}>;
}

export type ChatMrcInfoListItemSlotsAndSlotProps = CreateSlotsAndSlotProps<
  ChatMrcInfoListItemSlots,
  ChatMrcInfoListItemSlotProps
>;

export interface ChatMrcInfoListItemProps
  extends React.HTMLAttributes<HTMLDivElement>,
    ChatMrcInfoListItemSlotsAndSlotProps {
  /**
   * The data of the chat MRC info.
   */
  mrcInfo: FragmentOf<typeof ChatMrcInfoListItem_ChatMRCInfoFragment>;
}

export const ChatMrcInfoListItem = forwardRef<
  HTMLDivElement,
  ChatMrcInfoListItemProps
>((props, ref) => {
  const {
    children,
    mrcInfo: mrcInfoProp,
    slotProps = {},
    slots = {},
    ...other
  } = props;
  const mrcInfo = readFragment(
    ChatMrcInfoListItem_ChatMRCInfoFragment,
    mrcInfoProp,
  );
  const theme = useTheme();
  const { value: answer, onChange: onAnswerChange } = useRichText({
    value: mrcInfo.answer,
  });
  const { documentEntities } = useDocumentEntities({
    value: answer,
    knowledgeBasePreview: mrcInfo.knowledgeBasePreview,
    knowledgeBasePreviewKeyPrefix: mrcInfo.id,
  });
  const hasMessage = !isDraftInputEmpty(answer);
  const hasDocumentEntities = documentEntities.length > 0;

  const [UserFeedbackSlot, feedbackSlotProps] = useSlot('userFeedback', {
    elementType: UserFeedback,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });

  return (
    <div
      data-testid="chat-mrc-info"
      css={css`
        width: 292px;
        background-color: ${theme.palette.unstable_background.white};
        border-radius: ${theme.radius.sm}px;
        border: 1px solid ${theme.palette.border.divider.default};
        padding: 11px 15px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
      `}
      {...other}
      ref={ref}
    >
      <div
        css={css`
          flex-grow: 1;
        `}
      >
        <RichText value={answer} onChange={onAnswerChange} />

        {hasDocumentEntities && (
          <DraftDocumentList
            css={[
              !hasMessage &&
                css`
                  margin-top: -2px;
                `,
            ]}
            data={documentEntities}
          />
        )}
      </div>

      <UserFeedbackSlot
        css={css`
          margin-top: 8px;
        `}
        data={mrcInfo}
        slotProps={slotProps}
        slots={slots}
        {...feedbackSlotProps}
      />
    </div>
  );
});
