import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { SidePanelContext } from './side-panel-context';
import {
  AppPreviewMetadata,
  DocumentListMetadata,
  DocumentPreviewMetadata,
  isDocumentListTab,
  TabNavigatorContext,
  TabNavigatorContextValue,
  useTabNavigator,
} from './use-tab-navigator';
import { gql } from '../gql';

interface SidePanelProviderProps {
  children?: ReactNode;
}

export const SidePanelProvider = ({ children }: SidePanelProviderProps) => {
  const intl = useIntl();
  const location = useLocation();
  const tabNavigator = useTabNavigator();

  const [open, setOpen] = useState(false);
  const openSidePanel = () => setOpen(true);
  const closeSidePanel = () => setOpen(false);

  const openTab: TabNavigatorContextValue['openTab'] = tab => {
    tabNavigator.openTab(tab);
    openSidePanel();
  };
  const openAppListTab = () => {
    openTab({
      type: 'APP_LIST',
      label: intl.formatMessage({
        id: 'tab-navigator.app-list-tab.title',
        defaultMessage: 'Apps',
        description: 'tab navigator app list tab title',
      }),
    });
  };
  const openAppPreviewTab = (metadata: AppPreviewMetadata) => {
    openTab({
      type: 'APP_PREVIEW',
      label: intl.formatMessage({
        id: 'tab-navigator.app-preview-tab.title',
        defaultMessage: 'App',
        description: 'tab navigator app preview tab title',
      }),
      metadata,
    });
  };
  const openDocumentListTab = (metadata: DocumentListMetadata) => {
    openTab({
      type: 'DOCUMENT_LIST',
      label: intl.formatMessage({
        id: 'tab-navigator.document-list-tab.title',
        defaultMessage: 'Documents',
        description: 'tab navigator document list tab title',
      }),
      metadata,
    });
  };
  const openDocumentPreviewTab = (metadata: DocumentPreviewMetadata) => {
    openTab({
      type: 'DOCUMENT_PREVIEW',
      label: intl.formatMessage({
        id: 'tab-navigator.document-preview-tab.title',
        defaultMessage: 'Document',
        description: 'tab navigator document preview tab title',
      }),
      metadata,
    });
  };

  const uploadDocument = () => {
    openDocumentListTab({
      tab: gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL'),
      openUpload: true,
    });
  };

  const selectedKnowledgeBaseIds = useMemo(() => {
    const documentListTab = tabNavigator.tabs.find(tab =>
      isDocumentListTab(tab),
    );
    return documentListTab?.metadata?.selectedKnowledgeBaseIds ?? [];
  }, [tabNavigator.tabs]);

  useEffect(() => {
    closeSidePanel();
    tabNavigator.closeAllTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (tabNavigator.tabs.length === 0) {
      closeSidePanel();
    }
  }, [tabNavigator.tabs]);

  return (
    <SidePanelContext.Provider
      value={{
        open,
        activeTab: tabNavigator.activeTab,
        selectedKnowledgeBaseIds,
        openAppListTab,
        openAppPreviewTab,
        openDocumentListTab,
        openDocumentPreviewTab,
        uploadDocument,
        closeSidePanel,
      }}
    >
      <TabNavigatorContext.Provider value={{ ...tabNavigator, openTab }}>
        {children}
      </TabNavigatorContext.Provider>
    </SidePanelContext.Provider>
  );
};
