import { css } from '@emotion/react';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { forwardRef } from 'react';
import { AlertTitleProps } from './alert-title-type-map';
import clsx from 'clsx';
import { alertTitleClasses } from './alert-title-classes';

export const AlertTitle = forwardRef<HTMLDivElement, AlertTitleProps>(
  (props, ref) => {
    const { classes, className, ...other } = props;
    const theme = useTheme();

    return (
      <Text
        data-testid="alert-title"
        css={css`
          font-weight: ${theme.typography.fontWeightMedium};
          margin-top: -2px;
        `}
        gutterBottom
        component="div"
        {...other}
        ref={ref}
        className={clsx(alertTitleClasses.root, classes?.root, className)}
      />
    );
  },
);
