import { Truncate } from '@allganize/truncate';
import { Avatar } from '@allganize/ui-avatar';
import { ButtonBase, buttonBaseClasses } from '@allganize/ui-button';
import { IcArrowExpandMore, IcLogout } from '@allganize/ui-icons';
import { ListItemIcon, ListItemText } from '@allganize/ui-list';
import { Menu, MenuItem } from '@allganize/ui-menu';
import { Text } from '@allganize/ui-text';
import { css, useTheme } from '@emotion/react';
import { FunctionComponent, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCurrentUser } from '../../auth/use-current-user';
import { useAuth } from '../../hooks/use-auth';

export const UserMenu: FunctionComponent = () => {
  const theme = useTheme();
  const { me } = useCurrentUser();
  const auth = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);
  const canOpenMenu = auth.isEnabled && auth.isAuthenticated;

  const handleClick = () => {
    setMenuOpen(prev => !prev);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  if (!me) {
    return null;
  }

  return (
    <>
      <ButtonBase
        css={css`
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: left;
          padding: 8px 8px 8px 16px;
          gap: 8px;
          width: 100%;
          border-top: 1px solid ${theme.palette.border.divider.subtle};
          border-bottom: 1px solid ${theme.palette.border.divider.subtle};

          &:hover,
          &.${buttonBaseClasses.focusVisible} {
            background-color: ${theme.palette.backgroundInteractive
              .grayGhostAlpha.hover};
          }
        `}
        disabled={!canOpenMenu}
        ref={menuTriggerRef}
        onClick={handleClick}
      >
        <Avatar variant="circular" src={me.avatar ?? undefined} alt={me.name}>
          {me.name[0]}
        </Avatar>

        <Text
          css={css`
            word-break: break-all;
            color: ${theme.palette.foreground.secondary};
            flex-grow: 1;
          `}
          variant="body12"
          component="div"
        >
          <Truncate clamp={1}>{me.name}</Truncate>
        </Text>

        {canOpenMenu && (
          <IcArrowExpandMore
            css={css`
              color: ${theme.palette.foreground.helper};
            `}
            fontSize="small"
          />
        )}
      </ButtonBase>

      {canOpenMenu && (
        <Menu
          open={menuOpen}
          anchorEl={menuTriggerRef.current}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <MenuItem
            onClick={() => {
              auth.signOut();
            }}
          >
            <ListItemIcon>
              <IcLogout />
            </ListItemIcon>

            <ListItemText
              primary={
                <FormattedMessage
                  id="sign-out"
                  defaultMessage="Sign out"
                  description="Sign out text"
                />
              }
            />
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
