import { inputAdornmentClasses, inputClasses } from '@allganize/ui-input';
import { css } from '@emotion/react';

export const inlineStyle = css`
  .${inputClasses.root} {
    min-height: 52px;
    padding: 8px 16px 8px 12px;
    align-self: end;
  }
`;

export const blockStyle = css`
  .${inputClasses.root} {
    padding: 16px 8px 60px 12px;
  }

  .${inputClasses.root} > .${inputAdornmentClasses.positionStart} {
    position: absolute;
    left: 8px;
    bottom: 8px;
  }

  .${inputClasses.root} > .${inputAdornmentClasses.positionEnd} {
    position: absolute;
    right: 16px;
    bottom: 8px;
  }

  .${inputAdornmentClasses.root} > .notranslate {
    margin: 0;
  }
`;
