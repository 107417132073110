import {
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemProps,
  ListItemText,
  listItemTextClasses,
  ListItemTextProps,
} from '@allganize/ui-list';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';

interface CreatedLlmAppOptionItemProps
  extends Pick<ListItemProps, 'className'>,
    Pick<ListItemTextProps, 'primary' | 'secondary'> {
  disabled?: boolean;
  onClick?(): void;
}

export const CreatedLlmAppOptionItem: ((
  props: { href: string } & Pick<ListItemButtonProps<'a'>, 'target'> &
    CreatedLlmAppOptionItemProps,
) => JSX.Element) &
  ((props: CreatedLlmAppOptionItemProps) => JSX.Element) = props => {
  const { className, disabled, onClick, primary, secondary, ...other } = props;
  const theme = useTheme();

  return (
    <ListItem
      css={[
        css`
          display: inline-flex;
          flex-direction: column;
          align-items: stretch;
          border-radius: ${theme.radius.md}px;
          border: 1px solid ${theme.palette.border.divider.default};
          background-color: ${theme.palette.unstable_background.white};
          overflow: hidden;
          width: auto;
          max-width: 480px;
        `,
        disabled &&
          css`
            border-color: ${theme.palette.border.disabled};
          `,
      ]}
      component="div"
      disablePadding
      className={className}
    >
      <ListItemButton
        css={css`
          padding: 7px 15px;
          align-items: flex-start;
        `}
        disabled={disabled}
        onClick={onClick}
        {...other}
      >
        <ListItemText
          css={css`
            .${listItemTextClasses.primary}
              + .${listItemTextClasses.secondary} {
              margin-top: 8px;
            }

            &:not(.${listItemTextClasses.disabled})
              .${listItemTextClasses.secondary} {
              color: ${theme.palette.foreground.default};
            }
          `}
          primary={primary}
          primaryTextProps={{ variant: 'title14' }}
          secondary={secondary}
          secondaryTextProps={{ variant: 'body14' }}
        />
      </ListItemButton>
    </ListItem>
  );
};
