import {
  ExtendIconButton,
  ExtendIconButtonTypeMap,
  IconButton,
} from '@allganize/ui-button';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { PickersArrowSwitcherButtonSlotOwnerState } from './pickers-arrow-switcher-type-map';

type PickersArrowSwitcherButtonTypeMap<
  P = {},
  D extends React.ElementType = 'button',
> = ExtendIconButtonTypeMap<{
  props: P & {
    ownerState: PickersArrowSwitcherButtonSlotOwnerState;
  };
  defaultComponent: D;
}>;

// @ts-expect-error overridable component
export const PickersArrowSwitcherButton: ExtendIconButton<PickersArrowSwitcherButtonTypeMap> =
  forwardRef((props, ref) => {
    const { ownerState, ...other } = props;

    return (
      <IconButton
        css={[
          ownerState.hidden &&
            css`
              visibility: hidden;
            `,
        ]}
        {...other}
        ref={ref}
      />
    );
  });
