import { useImageLoaded } from '@allganize/hooks';
import { ButtonBase, buttonBaseClasses } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcImageNotSupported } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { DraftImageClasses, draftImageClasses } from './draft-image-classes';

interface DraftImageBaseProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Pick<
      React.ImgHTMLAttributes<HTMLImageElement>,
      'alt' | 'crossOrigin' | 'referrerPolicy' | 'src' | 'srcSet'
    > {
  classes?: Partial<DraftImageClasses>;
  height?: number;
  imageRef?: React.Ref<HTMLImageElement>;
  preserveRatio?: boolean;
  width?: number;
}

export const DraftImageBase = forwardRef<HTMLDivElement, DraftImageBaseProps>(
  (props, ref) => {
    const {
      alt,
      classes,
      crossOrigin,
      height,
      imageRef,
      preserveRatio = false,
      referrerPolicy,
      src,
      srcSet,
      width,
      ...other
    } = props;
    const theme = useTheme();
    const [loaded] = useImageLoaded({
      crossOrigin,
      referrerPolicy,
      src,
      srcSet,
    });
    // 16:9 ratio by default
    let ratio = '56.25%';

    if (preserveRatio && width && height) {
      ratio = `${(height / width) * 100}%`;
    }

    const handleClick = () => {
      if (!src) {
        return;
      }

      window.open(
        src,
        `draft-image-${src}`,
        [
          'titlebar=no',
          'toolbar=no',
          'status=no',
          'menubar=no',
          'location=no',
          width ? `width=${width}` : null,
          height ? `height=${height}` : null,
        ]
          .filter(Boolean)
          .join(','),
      );
    };

    return (
      <div
        data-testid="draft-image-base"
        css={css`
          position: relative;
          width: 100%;
          border: 1px solid ${theme.palette.divider};
          border-radius: ${theme.radius.xs}px;
          overflow: hidden;
          background-color: ${theme.palette.grey[200]};
          min-width: 232px;
          max-width: 480px;

          &::before {
            content: '';
            display: block;
            padding-bottom: ${ratio};
          }
        `}
        {...other}
        ref={ref}
        className={clsx(draftImageClasses.root, classes?.root, other.className)}
      >
        <ButtonBase
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &::after {
              display: block;
              position: absolute;
              content: '';
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background-color: transparent;
              pointer-events: none;
              transition: ${theme.transitions.create('background-color', {
                duration: theme.transitions.duration.short,
              })};
            }

            &:hover,
            &.${buttonBaseClasses.focusVisible} {
              &::after {
                background-color: ${theme.palette.grayAlpha[300]};
              }
            }

            &:active {
              &::after {
                background-color: ${theme.palette.grayAlpha[500]};
              }
            }

            &.${buttonBaseClasses.disabled} {
              &::after {
                background-color: transparent;
              }
            }
          `}
          disabled={loaded !== 'loaded'}
          className={clsx(draftImageClasses.button, classes?.button)}
          onClick={handleClick}
        >
          {loaded === 'loaded' && (
            <img
              css={css`
                display: block;
                width: 100%;
                height: 100%;
                user-select: none;
                object-fit: cover;
                word-break: break-all;
                background-color: ${theme.palette.common.white};
              `}
              src={src}
              srcSet={srcSet}
              alt={alt}
              className={clsx(draftImageClasses.image, classes?.image)}
              ref={imageRef}
            />
          )}

          {loaded === 'error' && (
            <span
              css={css`
                color: ${theme.palette.text.secondary};
                font-size: 36px;
              `}
            >
              <IcImageNotSupported color="inherit" fontSize="inherit" />
            </span>
          )}

          {!loaded && <CircularProgress color="primary" />}
        </ButtonBase>
      </div>
    );
  },
);
