import { toast } from '@allganize/ui-toast';
import { FormattedErrorMessage } from '@allganize/utils-graphql';
import { useMutation } from '@apollo/client/react';
import { gql } from '../gql';

const RunKnowledgeBaseAutoHashtagMutation = gql(`
  mutation RunKnowledgeBaseAutoHashtagMutation(
    $where: ProjectWhereUniqueInput!
    $ids: [ID]!
  ) {
    runKnowledgeBaseAutoHashtag(ids: $ids, where: $where) {
      updatedNode {
        id

        ...on KnowledgeBaseNodeFile {
          hashtags

          autoHashtags {
            id
            state

            autoHashtag {
              id
              hashtag
            }
          }
        }
      }

      errors {
        __typename
        key
        message
        field
        info
      }
    }
  }
`);

export const useRunKnowledgeBaseAutoHashtag = () => {
  return useMutation(RunKnowledgeBaseAutoHashtagMutation, {
    onCompleted(result) {
      if (result.runKnowledgeBaseAutoHashtag?.updatedNode) {
        return;
      }

      if (result.runKnowledgeBaseAutoHashtag?.errors?.[0]) {
        toast.error(
          <FormattedErrorMessage
            error={result.runKnowledgeBaseAutoHashtag.errors[0]}
          />,
        );

        return;
      }

      toast.error(<FormattedErrorMessage />);
    },
    onError(err) {
      toast.error(<FormattedErrorMessage error={err} />);
    },
  });
};
