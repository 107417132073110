import {
  KnowledgeBaseNode,
  KnowledgeBaseTreeViewProps,
  ROOT_NODE_ID,
} from '@allganize/knowledge-base-tree-view';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { KnowledgeBaseManageLink } from './knowledge-base-manage-link';
import { gql } from '../gql';

export interface KnowledgeBaseListViewProps
  extends Pick<KnowledgeBaseTreeViewProps, 'data' | 'onSelectedNodeChange'> {
  selectedIds?: (string | number)[];
  showOwnershipIcon?: boolean;
}

export const KnowledgeBaseListView: FunctionComponent<
  KnowledgeBaseListViewProps
> = props => {
  const {
    data,
    selectedIds = [],
    showOwnershipIcon,
    onSelectedNodeChange,
  } = props;
  const intl = useIntl();
  return (
    <ul
      css={css`
        margin: 0;
        padding: 0;
        list-style: none;
      `}
    >
      {data.map(node => {
        if (node.id === ROOT_NODE_ID) {
          return null;
        }
        const isSelected = selectedIds.includes(node.id);
        const handleClick = () =>
          onSelectedNodeChange?.(
            {
              id: node.id,
              name: node.name,
              ownership: node.metadata?.ownership ?? null,
            },
            !isSelected,
          );
        const hasErrorState =
          node.metadata?.processState ===
            gql.scalar('ProcessStateEnum', 'RETRYING') ||
          node.metadata?.processState ===
            gql.scalar('ProcessStateEnum', 'PARSING_FAIL');

        if (
          hasErrorState &&
          node.metadata?.ownership ===
            gql.scalar('KnowledgeBaseNodeOwnership', 'SHARED')
        ) {
          return null;
        }

        return (
          <li key={node.id}>
            <Tooltip
              title={
                hasErrorState
                  ? intl.formatMessage({
                      id: 'knowledge-base-list.error-state-node.tooltip.title',
                      defaultMessage:
                        "This document couldn't be processed successfully. Please delete it and try uploading the same file again to attempt processing.",
                      description:
                        'knowledge base list error state node tooltip title',
                    })
                  : ''
              }
              placement="top-start"
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              }}
            >
              <span
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 4px;
                `}
              >
                <KnowledgeBaseNode
                  fullWidth
                  name={node.name}
                  selected={isSelected}
                  metadata={node.metadata}
                  onClick={handleClick}
                  showOwnershipIcon={showOwnershipIcon}
                />

                {hasErrorState && (
                  <KnowledgeBaseManageLink
                    knowledgeBaseNodeId={node.id}
                    css={css`
                      flex-shrink: 0;
                    `}
                  />
                )}
              </span>
            </Tooltip>
          </li>
        );
      })}
    </ul>
  );
};
