import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent, HTMLAttributes } from 'react';

export const SidePanelHeader: FunctionComponent<
  HTMLAttributes<HTMLDivElement>
> = ({ children, ...others }) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${theme.palette.unstable_background.graySubtle
          .default};

        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: ${theme.palette.border.divider.subtle};
        }
      `}
      {...others}
    >
      {children}
    </div>
  );
};
