import { noop } from 'lodash-es';
import { createContext } from 'react';
import { gql, ResultOf, Scalars } from '../gql';

export const OutputData_SingleActionAppDocumentMapReduceResultFragment = gql(`
  fragment OutputData_SingleActionAppDocumentMapReduceResultFragment on SingleActionAppDocumentMapReduceResult {
    id
    output
    dynamicInputsItemName
  }
`);

interface OutputData {
  id: Scalars<'ID'>;
  output: string;
  outputOriginal: string;
  documentResults: ResultOf<
    typeof OutputData_SingleActionAppDocumentMapReduceResultFragment
  >[];
}

export interface DocumentInput {
  knowledgeBaseId: Scalars<'ID'>;
  singleActionInputId: Scalars<'ID'>;
}

export interface GenerateInput {
  userInput: string;
  singleActionAppSelectedDocumentInputInfo: DocumentInput[];
}

interface Output {
  data: OutputData;
  called: boolean;
  loading: boolean;
  updating: boolean;
  updated: boolean;
  update: (updatedOutput: string) => void;
  revert: () => void;
  onCopy: () => void;
  stopGenerate: () => void;
  startGenerate: (input: GenerateInput) => void;
  setGenerateDisabled: (disabled: boolean) => void;
  generateDisabled: boolean;
}

export const OutputContext = createContext<Output>({
  data: {
    id: '',
    output: '',
    outputOriginal: '',
    documentResults: [],
  },
  called: false,
  loading: false,
  updating: false,
  updated: false,
  update: noop,
  revert: noop,
  onCopy: noop,
  stopGenerate: noop,
  startGenerate: noop,
  setGenerateDisabled: noop,
  generateDisabled: false,
});
