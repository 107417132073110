import { Skeleton } from '@allganize/ui-skeleton';
import { forwardRef } from 'react';
import { AlliChatRow } from '../alli-chat-row';
import { AlliChatRowItem } from '../alli-chat-row-item';
import { AlliChatRowSkeletonProps } from './alli-chat-row-skeleton.types';

export const AlliChatRowSkeleton = forwardRef<
  HTMLDivElement,
  AlliChatRowSkeletonProps
>((props, ref) => {
  const { className, rows = 3 } = props;

  return (
    <AlliChatRow className={className} ref={ref}>
      <AlliChatRowItem
        avatar={<Skeleton variant="circular" width={32} height={32} />}
      >
        {Array.from({ length: rows }).map((_, index) => {
          return (
            <Skeleton
              key={index}
              variant="text"
              textVariant="body14"
              width={index < rows - 1 ? '100%' : '67%'}
            />
          );
        })}
      </AlliChatRowItem>
    </AlliChatRow>
  );
});
