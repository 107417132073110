import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { Scalars } from '../gql';

interface DocumentPreviewState {
  nodeId: Scalars<'ID'> | null;
}

export const documentPreviewAtom = atomFamily(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (projectId: Scalars<'ID'>) =>
    atom<DocumentPreviewState>({
      nodeId: null,
    }),
  (a, b) => a === b,
);
