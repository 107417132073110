import { atom } from 'jotai';
import { ConfirmDialogProps } from './confirm-dialog/confirm-dialog.types';

export interface ConfirmOptions
  extends Omit<ConfirmDialogProps, 'ref' | 'open'> {
  key?: string;
}

export interface ConfirmDialogState {
  key: string;
  options: ConfirmOptions;
}

export const confirmAtom = atom<ConfirmDialogState[]>([]);
