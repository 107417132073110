import { useMediaQuery } from '@allganize/hooks';
import { Button, LoadingButton } from '@allganize/ui-button';
import {
  Dialog,
  DialogActions,
  DialogCloseButton,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@allganize/ui-dialog';
import { FormHelperText } from '@allganize/ui-form';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AccessControlListField,
  AccessControlListFieldProps,
} from '../file-form-fields/access-control-list-field';
import { HashtagField } from '../file-form-fields/hashtags-field';
import { NameField } from '../file-form-fields/name-field';
import {
  EditFileFormValues,
  useEditFileForm,
  UseEditFileFormOptions,
} from './use-edit-file-form';

export interface EditFileFormProps
  extends UseEditFileFormOptions,
    Pick<DialogProps, 'open' | 'onClose'>,
    Pick<AccessControlListFieldProps, 'enableOverwriteFolderAccess'> {}

export const EditFileForm: FunctionComponent<EditFileFormProps> = props => {
  const { open, onClose, enableOverwriteFolderAccess, ...other } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { form, submit } = useEditFileForm(other);
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    reset,
  } = form;

  const handleClose = (
    event: {},
    reason?: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick',
  ) => {
    onClose?.(event, reason || 'closeButtonClick');
    reset();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(submit)}>
        <DialogTitle padding="close-button">
          <FormattedMessage
            id="FAQ.Documents.EditFile.Title"
            defaultMessage="Edit File"
            description="FAQ document Edit File form title text"
          />

          <DialogCloseButton disabled={isSubmitting} />
        </DialogTitle>

        <DialogContent>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 24px;
            `}
          >
            <NameField<EditFileFormValues> control={control} />

            <HashtagField
              form={form}
              variables={{ where: { id: other.projectId } }}
            />

            <AccessControlListField
              control={control}
              projectId={other.projectId}
              enableOverwriteFolderAccess={enableOverwriteFolderAccess}
            />
          </div>

          {errors.root?.message && (
            <FormHelperText error>{errors.root.message}</FormHelperText>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            disabled={isSubmitting}
            onClick={handleClose}
          >
            <FormattedMessage
              id="FAQ.Documents.EditFile.CancelButton"
              defaultMessage="Cancel"
              description="FAQ document Edit File form cancel button text"
            />
          </Button>

          <LoadingButton
            variant="filled"
            size="large"
            color="primary"
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
          >
            <FormattedMessage
              id="FAQ.Documents.EditFile.SubmitButton"
              defaultMessage="Submit"
              description="FAQ document Edit File form submit button text"
            />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
