import { Text } from '@allganize/ui-text';
import { FunctionComponent } from 'react';
import { FormattedDate, useIntl, FormattedTime } from 'react-intl';
import { FragmentOf, gql, readFragment, Scalars } from '../gql';
import { formatUserName } from '../utils/format-user-name';

export const PanelUser_AgentFragment = gql(`
  fragment PanelUser_AgentFragment on Agent {
    id
    firstName
    lastName
  }
`);

export interface PanelUserProps {
  agent: FragmentOf<typeof PanelUser_AgentFragment>;
  timestamp?: Scalars<'Float'>;
}

export const PanelUser: FunctionComponent<PanelUserProps> = props => {
  const { agent: agentProp, timestamp } = props;
  const intl = useIntl();
  const agent = readFragment(PanelUser_AgentFragment, agentProp);
  const name = formatUserName(agent, intl.locale);

  return (
    <>
      <Text variant="body14">{name}</Text>

      {timestamp && (
        <Text variant="body14">
          <FormattedDate value={timestamp} />{' '}
          <FormattedTime value={timestamp} />
        </Text>
      )}
    </>
  );
};
