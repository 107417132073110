import { MarkdownText } from '@allganize/alli-chat-content';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { Divider } from '@allganize/ui-divider';
import { IcCheckCircle } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent, Suspense } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

export const IntermediateStep_IntermediateStepFragment = gql(`
  fragment IntermediateStep_IntermediateStepFragment on IntermediateStep {
    id
    markdownMessage
    statusMessage
    completed
  }
`);

interface IntermediateStepProps {
  step: FragmentOf<typeof IntermediateStep_IntermediateStepFragment>;
}

export const IntermediateStep: FunctionComponent<
  IntermediateStepProps
> = props => {
  const { step: stepProp } = props;
  const step = readFragment(
    IntermediateStep_IntermediateStepFragment,
    stepProp,
  );

  if (!step.markdownMessage && !step.statusMessage) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 16px;
      `}
    >
      {step.statusMessage && (
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            gap: 8px;
          `}
        >
          {step.completed ? (
            <IcCheckCircle color="primary" />
          ) : (
            <CircularProgress size="sm" />
          )}

          <Text variant="body14">{step.statusMessage}</Text>
        </div>
      )}

      {step.markdownMessage && (
        <Suspense fallback={null}>
          <div
            css={css`
              display: flex;
              align-items: stretch;
              gap: 8px;
            `}
          >
            <div
              css={css`
                width: 20px;
                padding: 0 9.5px;
              `}
            >
              <Divider orientation="vertical" />
            </div>

            <MarkdownText>{step.markdownMessage}</MarkdownText>
          </div>
        </Suspense>
      )}
    </div>
  );
};
