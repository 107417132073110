import { CircularProgress } from '@allganize/ui-circular-progress';
import { components, Select, SelectRef } from '@allganize/ui-select';
import { toast } from '@allganize/ui-toast';
import { useLazyQuery } from '@apollo/client/react';
import { forwardRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { GroupBase } from 'react-select';
import { useAsyncPaginate, wrapMenuList } from 'react-select-async-paginate';
import { AccessControlSelectOptionComponent } from '../access-control-select/access-control-select-option';
import {
  AccessControlSelectOption,
  AccessControlSelectProps,
  LoadOptionType,
} from '../access-control-select/access-control-select-type-map';
import { gql, Scalars } from '../gql';

export interface AccessControlSelectAgentOption
  extends AccessControlSelectOption {
  accessType: Scalars<'ObjectAccessType'>;
  isMe?: boolean;
  isAdmin?: boolean;
}

export type AccessControlAgentSelectProps =
  AccessControlSelectProps<AccessControlSelectAgentOption>;

export const AccessControlAgentSelect_ProjectAgentsQuery = gql(
  `
  query AccessControlAgentSelect_ProjectAgentsQuery(
    $where: ProjectWhereUniqueInput!
    $filter: ProjectAgentFilter
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    project(where: $where) {
      id
      agents(
        filter: $filter
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            id
            displayName
            email
            isMe
            name

            avatar {
              id
              url
            }
          }

          permissionGroup {
            id
            isAdmin
          }
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`,
);

export const AccessControlAgentSelect = forwardRef<
  SelectRef<AccessControlSelectAgentOption, true>,
  AccessControlSelectProps<AccessControlSelectAgentOption>
>(({ projectId, onAgentOptionsLoad, ...selectProps }, ref) => {
  const intl = useIntl();
  const [fetchAgents] = useLazyQuery(
    AccessControlAgentSelect_ProjectAgentsQuery,
    {
      variables: {
        where: {
          id: projectId,
        },
        first: 20,
      },
    },
  );

  const loadOptions: LoadOptionType<AccessControlSelectAgentOption> =
    useCallback(
      async (inputValue, prevOptions, additional) => {
        try {
          const { data } = await fetchAgents({
            variables: {
              where: {
                id: projectId,
              },
              filter: {
                searchTerm: inputValue,
              },
              after: additional?.endCursor,
            },
          });
          if (!data?.project?.agents?.edges) {
            throw new Error('No agents found');
          }

          const allOptions = data.project.agents.edges.reduce<
            AccessControlSelectAgentOption[]
          >((prev, curr) => {
            if (!curr?.node) {
              return prev;
            }
            const isMe = curr.node.isMe ?? false;
            const isAdmin = curr.permissionGroup?.isAdmin ?? false;

            prev.push({
              label: curr.node.displayName || curr.node.name,
              value: curr.node.id,
              description: curr.node.email,
              avatar: {
                src: curr.node.avatar?.url,
                name: curr.node.displayName,
              },
              accessType:
                isMe || isAdmin
                  ? gql.scalar('ObjectAccessType', 'EDITOR')
                  : gql.scalar('ObjectAccessType', 'READER'),
              isMe,
              isAdmin,
            });
            return prev;
          }, []);

          onAgentOptionsLoad?.(allOptions);

          const result = allOptions.filter(
            option => !option.isMe && !option.isAdmin,
          );

          return {
            options: result,
            hasMore: data.project.agents.pageInfo.hasNextPage,
          };
        } catch {
          toast.error(
            intl.formatMessage({
              id: 'access-control.select.load.error',
              defaultMessage: 'Failed to load the list. Please try again.',
              description: 'Error message when failed to load list',
            }),
          );
        }
        return {
          options: [],
          hasMore: false,
        };
      },
      [fetchAgents, projectId, intl, onAgentOptionsLoad],
    );

  const asyncPaginateProps = useAsyncPaginate({
    loadOptions,
    debounceTimeout: 100,
  });

  return (
    <Select<
      AccessControlSelectAgentOption,
      true,
      GroupBase<AccessControlSelectAgentOption>
    >
      maxMenuHeight={244}
      hideSelectedOptions
      loadingMessage={() => <CircularProgress />}
      {...selectProps}
      {...asyncPaginateProps}
      isMulti
      backspaceRemovesValue={false}
      ref={ref}
      components={{
        ...selectProps.components,
        MenuList: wrapMenuList(components.MenuList as any),
        Option: AccessControlSelectOptionComponent,
      }}
    />
  );
});
