import { OutlinedChip } from '@allganize/ui-chip';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcArrowDropDown, IcCheck, IcClose } from '@allganize/ui-icons';
import { ListItemIcon, ListItemText } from '@allganize/ui-list';
import { Menu, MenuItem } from '@allganize/ui-menu';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { gql, ResultOf, Scalars } from '../gql';

export const AutoHashtagChip_KnowledgeBaseAutoHashtagFragment = gql(`
  fragment AutoHashtagChip_KnowledgeBaseAutoHashtagFragment on KnowledgeBaseAutoHashtag {
    id

    autoHashtag {
      id
      hashtag
    }
  }
`);

export const AutoHashtagChip_FAQAutoHashtagFragment = gql(`
  fragment AutoHashtagChip_FAQAutoHashtagFragment on FAQAutoHashtag {
    id

    autoHashtag {
      id
      hashtag
    }
  }
`);

export interface AutoHashtagChipProps<
  T extends
    | ResultOf<typeof AutoHashtagChip_KnowledgeBaseAutoHashtagFragment>
    | ResultOf<typeof AutoHashtagChip_FAQAutoHashtagFragment>,
> {
  autoHashtag: T;
  onClick?(autoHashtag: T): void;
  onStateChange?(
    autoHashtag: T,
    state: Scalars<'AutoHashtagState'>,
  ): Promise<void>;
}

export const AutoHashtagChip = <
  T extends
    | ResultOf<typeof AutoHashtagChip_KnowledgeBaseAutoHashtagFragment>
    | ResultOf<typeof AutoHashtagChip_FAQAutoHashtagFragment>,
>(
  props: AutoHashtagChipProps<T>,
) => {
  const { autoHashtag, onClick, onStateChange } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClick = () => {
    onClick?.(autoHashtag);
  };

  const handleApprove = async () => {
    setApproving(true);

    try {
      await onStateChange?.(
        autoHashtag,
        gql.scalar('AutoHashtagState', 'APPROVED'),
      );
    } finally {
      setApproving(false);
      closeMenu();
    }
  };

  const handleReject = async () => {
    setRejecting(true);

    try {
      await onStateChange?.(
        autoHashtag,
        gql.scalar('AutoHashtagState', 'DECLINED'),
      );
    } finally {
      setRejecting(false);
      closeMenu();
    }
  };

  return (
    <>
      <OutlinedChip
        aria-controls={`auto-hashtag-${autoHashtag.id}`}
        aria-haspopup="true"
        onClick={onClick ? handleClick : undefined}
        ref={rootRef}
        label={`#${autoHashtag.autoHashtag.hashtag}`}
        deleteIcon={<IcArrowDropDown />}
        onDelete={openMenu}
      />

      <Menu
        id={`auto-hashtag-${autoHashtag.id}`}
        anchorEl={rootRef.current}
        open={menuOpen}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItem disabled={approving} onClick={handleApprove}>
          <ListItemIcon>
            {approving ? <CircularProgress size="sm" /> : <IcCheck />}
          </ListItemIcon>

          <ListItemText
            primary={
              <FormattedMessage
                id="FAQ.Hashtags.AutoHashtagReview.AutoHashtagItem.ApproveButton"
                defaultMessage="Approve"
                description="FAQ hashtags review approve button text"
              />
            }
          />
        </MenuItem>

        <MenuItem disabled={rejecting} onClick={handleReject}>
          <ListItemIcon>
            {rejecting ? <CircularProgress size="sm" /> : <IcClose />}
          </ListItemIcon>

          <ListItemText
            primary={
              <FormattedMessage
                id="FAQ.Hashtags.AutoHashtagReview.AutoHashtagItem.RejectButton"
                defaultMessage="Reject"
                description="FAQ hashtags review reject button text"
              />
            }
          />
        </MenuItem>
      </Menu>
    </>
  );
};
