import { PdfPageStyles } from '@allganize/alli-document';
import { CssBaseline } from '@allganize/ui-theme';
import { css, Global } from '@emotion/react';
import { Fragment } from 'react';

export const GlobalStyles = () => {
  return (
    <Fragment>
      <CssBaseline />
      <PdfPageStyles />

      <Global
        styles={css`
          html,
          body,
          #root {
            height: 100%;
          }

          a {
            color: inherit;
          }
        `}
      />
    </Fragment>
  );
};
