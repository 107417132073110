import { ChatFormInputAdornment } from '@allganize/alli-sdk-ui';
import { useCombinedRef, useEventCallback } from '@allganize/hooks';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import {
  BaseSingleInputFieldProps,
  DatePicker,
  DatePickerProps,
  DateValidationError,
  FieldSection,
  PickersModalDialogRoot,
  UseDateFieldProps,
} from '@allganize/ui-date-picker';
import { DialogProps } from '@allganize/ui-dialog';
import { IcCalendar } from '@allganize/ui-icons';
import React, { forwardRef, useEffect, useState } from 'react';
import { Control, FormState, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { dateFormat } from '../utils/chat-form';
import { ChatFormMessageField } from './chat-form-message-field';
import { ChatFormValues } from '../types/types';

const DatePickerDialog = forwardRef<HTMLDivElement, DialogProps>(
  (props, ref) => {
    return <PickersModalDialogRoot {...props} ref={ref} />;
  },
);

interface ButtonDateFieldProps
  extends UseDateFieldProps<Date>,
    BaseSingleInputFieldProps<
      Date | null,
      Date,
      FieldSection,
      DateValidationError
    >,
    Pick<IconButtonProps, 'edge' | 'onClick'> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ButtonDateField = forwardRef<HTMLButtonElement, ButtonDateFieldProps>(
  (props, ref) => {
    const {
      disabled,
      edge,
      id,
      InputProps: { ref: InputPropsRef } = {},
      inputProps: { 'aria-label': ariaLabel } = {},
      onClick,
    } = props;
    const combinedRef = useCombinedRef<HTMLButtonElement>(
      InputPropsRef ?? null,
      ref,
    );

    return (
      <IconButton
        shape="rounded"
        id={id}
        edge={edge}
        disabled={disabled}
        aria-label={ariaLabel}
        onClick={onClick}
        ref={combinedRef}
      >
        <IcCalendar />
      </IconButton>
    );
  },
);

interface ButtonDatePickerProps extends Omit<DatePickerProps, 'value'> {
  control: Control<ChatFormValues>;
  edge?: IconButtonProps['edge'];
}

export const ChatFormDatePickerButton = forwardRef<
  HTMLDivElement,
  ButtonDatePickerProps
>((props, ref) => {
  const {
    control,
    onClose,
    onOpen,
    open: openProp,
    slots,
    slotProps,
    edge,
    inputRef: inputRefProp,
    onChange,
    ...other
  } = props;
  const intl = useIntl();
  const [open, setOpen] = useState(openProp ?? false);
  const { field } = useController({ control, name: 'date' });
  const inputRef = useCombinedRef(field.ref, inputRefProp ?? null);
  const handleChange = useEventCallback<
    NonNullable<DatePickerProps['onChange']>
  >((value, context) => {
    field.onChange(value);
    onChange?.(value, context);
  });

  useEffect(() => {
    if (typeof openProp === 'undefined') {
      return;
    }

    setOpen(openProp);
  }, [openProp]);

  const toggle = () => {
    setOpen(prev => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleOpen = () => {
    setOpen(true);
    onOpen?.();
  };

  return (
    <DatePicker
      open={open}
      {...other}
      ref={ref}
      value={field.value}
      inputRef={inputRef}
      onChange={handleChange}
      onClose={handleClose}
      onOpen={handleOpen}
      format={dateFormat}
      slots={{
        ...slots,
        // @ts-expect-error internal prop
        dialog: DatePickerDialog,
        // @ts-expect-error internal component
        field: ButtonDateField,
      }}
      slotProps={{
        ...slotProps,
        // @ts-expect-error internal component
        field(ownerState) {
          const fieldProps =
            typeof slotProps?.field === 'function'
              ? slotProps.field(ownerState)
              : slotProps?.field;

          return {
            onClick(ev: React.MouseEvent<HTMLButtonElement>) {
              toggle();
              // @ts-expect-error internal component
              fieldProps?.onClick?.(ev);
            },
            edge,
            'aria-label': intl.formatMessage({
              id: 'chat-form.date-picker-button.aria-label',
              defaultMessage: 'Choose date',
              description: 'Date picker button aria-label',
            }),
            ...fieldProps,
          };
        },
      }}
    />
  );
});

interface FieldDatePickerProps extends Omit<DatePickerProps, 'value'> {
  control: Control<ChatFormValues>;
  formState: FormState<ChatFormValues>;
  onClear?: () => void;
  onClearError?: () => void;
}
export const ChatFormDatePickerField = forwardRef<
  HTMLDivElement,
  FieldDatePickerProps
>(
  (
    {
      control,
      formState,
      disabled,
      onClear,
      onClearError,
      onChange,
      slots,
      slotProps,
      ...others
    },
    ref,
  ) => {
    const intl = useIntl();
    const { field } = useController({ control, name: 'date' });
    const handleChange = useEventCallback<
      NonNullable<DatePickerProps['onChange']>
    >((value, context) => {
      field.onChange(value);
      onChange?.(value, context);
    });

    return (
      <DatePicker
        {...others}
        ref={ref}
        format={dateFormat}
        readOnly={disabled}
        disabled={disabled}
        onChange={handleChange}
        value={field.value}
        slots={{
          ...slots,
          // @ts-expect-error internal prop
          dialog: DatePickerDialog,
          // @ts-expect-error internal prop
          inputField: ChatFormMessageField,
          inputAdornment: ChatFormInputAdornment,
        }}
        slotProps={{
          ...slotProps,
          field: {
            clearable: true,
            onClear,
            ...slotProps?.field,
          },
          clearIcon: {
            fontSize: 'medium',
            ...slotProps?.clearIcon,
          },
          inputAdornment: {
            position: 'start',
            ...slotProps?.inputAdornment,
          },
          openPickerButton: {
            size: 'medium',
            ...slotProps?.openPickerButton,
          },
          inputField: {
            ...slotProps?.inputField,
            inputRef: field.ref,
            name: field.name,
            onBlur: field.onBlur,
            // @ts-expect-error internal prop
            control,
            onClearError,
            formState,
            inputProps: {
              'aria-label': intl.formatMessage({
                id: 'chat-form.date-picker-field.aria-label',
                defaultMessage:
                  'Please enter the date in the YYYY-MM-DD format, using only numbers.',
                description: 'Chat form date picker input aria label',
              }),
            },
          },
        }}
      />
    );
  },
);
