import { Tab, Tabs } from '@allganize/ui-tab';
import { FunctionComponent } from 'react';
import { useDocumentsTab } from '../hooks/use-documents-tab';

export const DocumentsHeaderTabs: FunctionComponent = () => {
  const { tab, setTab, options } = useDocumentsTab();

  const handleChange = (event: React.SyntheticEvent, value: any) => {
    setTab(value);
  };

  return (
    <Tabs value={tab?.value} variant="filled" onChange={handleChange}>
      {options.map(option => {
        return (
          <Tab
            key={option.value}
            icon={option.icon}
            value={option.value}
            label={option.label}
          />
        );
      })}
    </Tabs>
  );
};
