import { useAlliClient } from '@allganize/alli-sdk/alli-client/use-alli-client';
import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { useEventCallback } from '@allganize/hooks';
import { Button } from '@allganize/ui-button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@allganize/ui-dialog';
import { Text } from '@allganize/ui-text';
import { toast } from '@allganize/ui-toast';
import { AlliGraphQLError } from '@allganize/utils-graphql';
import { useMutation } from '@apollo/client/react';
import styled from '@emotion/styled';
import { compact } from 'lodash-es';
import { MouseEvent, forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { HideConversationMutationDocument } from '../../graphql/mutations/hide-conversation-mutation';
import { mallyErrors } from '../../i18n/mally-errors';

const StyledDialogContent = styled(DialogContent)`
  min-width: 520px;
`;

interface DeleteConversationModalProps extends DialogProps {
  conversationId: string | number;
  onConfirm?: () => void;
}

export const DeleteConversationModal = forwardRef<
  HTMLDivElement,
  DeleteConversationModalProps
>(({ conversationId: conversationIdProps, onConfirm, ...others }, ref) => {
  const intl = useIntl();
  const client = useAlliClient();
  const navigate = useNavigate();
  const { conversationId } = useParams<{ conversationId: string }>();
  const [hideConversation] = useMutation(HideConversationMutationDocument);

  const handleClickClose = useEventCallback((e: MouseEvent) => {
    others.onClose?.(e, 'closeButtonClick');
  });
  const handleClickConfirm = useEventCallback(async (e: MouseEvent) => {
    onConfirm?.();
    try {
      // end conversation before hide
      const leaveConversation = await client.endConversation({
        variables: {
          where: { id: conversationIdProps },
        },
      });

      if (!leaveConversation?.data) {
        throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
      }

      if (
        leaveConversation.data.endConversation?.errors?.length &&
        // ignore INVALID_STATE error (already ended conversation)
        leaveConversation.data.endConversation.errors[0]?.key !==
          UserQueryTypes.MallyError.INVALID_STATE
      ) {
        throw new AlliGraphQLError(
          leaveConversation.data.endConversation.errors,
        );
      }

      // if current conversation is ended, navigate to the conversation list
      if (conversationId === conversationIdProps) {
        navigate({
          pathname: '/',
        });
      }

      // start hide conversation
      const result = await hideConversation({
        variables: {
          ids: [conversationIdProps],
        },
      });

      if (!result.data) {
        throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
      }

      if (result.data.hideConversations?.errors?.length) {
        throw new AlliGraphQLError(result.data.hideConversations.errors);
      }

      client.apolloClient?.refetchQueries({
        include: ['ConversationsQuery'],
      });
      others.onClose?.(e, 'closeButtonClick');
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const error = compact(err.errors)[0];

        if (error) {
          toast.error(
            intl.formatMessage(
              AlliGraphQLError.getMallyErrorMessageDescriptor(error),
            ),
          );
        }
      }

      if (err instanceof Error) {
        toast.error(err.message);
      }

      if (typeof err === 'string') {
        toast.error(err);
      }
    }
  });

  return (
    <Dialog {...others} ref={ref}>
      <DialogTitle>
        <Text variant="title16">
          <FormattedMessage
            id="AppMarket.Modal.DeleteConversationModal.Title"
            defaultMessage="Would you like to delete this conversation?"
            description="app market delete conversation modal title"
          />
        </Text>
      </DialogTitle>
      <StyledDialogContent>
        <Text variant="body14">
          <FormattedMessage
            id="AppMarket.Modal.DeleteConversationModal.Description"
            defaultMessage="Deleting conversation is irreversible. Please confirm if you want to continue."
            description="app market delete conversation modal description"
          />
        </Text>
      </StyledDialogContent>
      <DialogActions>
        <Button size="large" variant="ghost" onClick={handleClickClose}>
          <FormattedMessage
            id="AppMarket.Modal.DeleteConversationModal.Cancel"
            defaultMessage="Cancel"
            description="app market delete conversation modal cancel button"
          />
        </Button>
        <Button
          size="large"
          variant="filled"
          color="error"
          onClick={handleClickConfirm}
        >
          <FormattedMessage
            id="AppMarket.Modal.DeleteConversationModal.Confirm"
            defaultMessage="Delete"
            description="app market delete conversation modal confirm button"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
});
