import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { IcClose } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { TabBase, TabNavigatorContextValue } from './tab-navigator-context';

export const TabNavigator = <T extends TabBase>(
  props: TabNavigatorContextValue<T>,
) => {
  const theme = useTheme();
  const { tabs, activeTab, openTab, closeTab } = props;

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        height: 40px;
        overflow: auto;
        z-index: 1;
      `}
    >
      {tabs.map(tab => {
        const active = activeTab?.id === tab.id;
        return (
          <div
            key={tab.id}
            onClick={() => openTab(tab)}
            css={css`
              display: flex;
              align-items: center;
              gap: 4px;
              padding-left: 16px;
              padding-right: 8px;
              background-color: ${active
                ? theme.palette.unstable_background.default
                : 'transparent'};
              border-right: 1px solid ${theme.palette.border.divider.subtle};
              cursor: pointer;
            `}
          >
            <Truncate clamp={1}>
              <Text variant="label14">{tab.label}</Text>
            </Truncate>
            <IconButton
              css={css`
                visibility: ${active ? 'visible' : 'hidden'};
              `}
              onClick={e => {
                e.stopPropagation();
                closeTab(tab.id);
              }}
            >
              <IcClose />
            </IconButton>
          </div>
        );
      })}
    </div>
  );
};
