import { useId } from '@allganize/hooks';
import { FileDropzone } from '@allganize/ui-file-input';
import { FormControl, FormHelperText } from '@allganize/ui-form';
import { IcUpload } from '@allganize/ui-icons';
import { InputLabel } from '@allganize/ui-input';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import { AttachmentList } from './attachment-list';
import { ContactAgentFormValues } from './use-contact-agent-form';

export const AttachmentsField: FunctionComponent = () => {
  const { control } = useFormContext<ContactAgentFormValues>();
  const { field, fieldState } = useController({
    control,
    name: 'attachments',
  });
  const fieldArray = useFieldArray({ control, name: 'attachments' });
  const { append } = fieldArray;
  const reactId = useId();
  const id = `attachments-field-${reactId}`;
  const helperTextId = id ? `${id}-helper-text` : undefined;
  const inputLabelId = id ? `${id}-label` : undefined;

  return (
    <FormControl fullWidth error={fieldState.invalid} disabled={field.disabled}>
      <InputLabel htmlFor={id} id={inputLabelId}>
        <FormattedMessage
          id="attachment"
          defaultMessage="{count, plural, one {Attachment} other {Attachments}}"
          description="contact agent by email form attachments field label"
          values={{ count: 0 }}
        />
      </InputLabel>

      <FileDropzone
        disabled={field.disabled}
        inputProps={{
          name: field.name,
          'aria-describedby': helperTextId,
        }}
        inputRef={field.ref}
        onDropAccepted={files => {
          append(files.map(file => ({ id: v4(), file })));
        }}
        onBlur={field.onBlur}
        css={css`
          padding: 12px;
          display: flex;
          align-items: center;
          flex-direction: column;
          text-align: center;
        `}
        dragOverlay={
          <div
            css={css`
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              text-align: center;
            `}
          >
            <IcUpload
              css={css`
                margin-bottom: 4px;
              `}
            />

            <Text variant="body14">
              <FormattedMessage
                id="form.file.placeholder.drag"
                defaultMessage="Drop file here"
                description="File input placeholder dragging state"
              />
            </Text>
          </div>
        }
      >
        <IcUpload
          css={css`
            margin-bottom: 4px;
          `}
        />

        <Text variant="body14">
          <FormattedMessage
            id="form.file.placeholder"
            defaultMessage="Click or drag & drop{br}a file to upload"
            description="File input placeholder"
            values={{
              br: <br />,
            }}
          />
        </Text>
      </FileDropzone>

      {fieldState.error?.message && (
        <FormHelperText id={helperTextId} error>
          {fieldState.error.message}
        </FormHelperText>
      )}

      <AttachmentList
        css={css`
          margin-top: 8px;
        `}
        disabled={field.disabled}
        fieldArray={fieldArray}
      />
    </FormControl>
  );
};
