import { SearchField } from '@allganize/ui-search';
import { css } from '@emotion/react';
import { FunctionComponent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface DocumentsPickerSearchFormValues {
  query: string;
}

interface DocumentsPickerSearchFormProps {
  searchTerm?: string;
  onSubmit: (searchTerm: string) => void;
}

export const DocumentsPickerSearchForm: FunctionComponent<
  DocumentsPickerSearchFormProps
> = ({ searchTerm, onSubmit }) => {
  const { control, handleSubmit, watch } =
    useForm<DocumentsPickerSearchFormValues>({
      defaultValues: {
        query: searchTerm ?? '',
      },
      mode: 'onSubmit',
    });

  const query = watch('query');

  useEffect(() => {
    if (!query) {
      onSubmit?.(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const submit = (values: DocumentsPickerSearchFormValues) => {
    onSubmit(values.query);
  };

  return (
    <form
      css={css`
        margin: 0;
        padding: 12px;
        height: 64px;
      `}
      onSubmit={handleSubmit(submit)}
    >
      <Controller
        control={control}
        name="query"
        render={({ field: { ref: fieldRef, ...field } }) => {
          return (
            <SearchField
              {...field}
              inputRef={fieldRef}
              label={null}
              fullWidth
            />
          );
        }}
      />
    </form>
  );
};
