import { useEventCallback } from '@allganize/hooks';
import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { confirm } from './confirm';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog';
import {
  ConfirmDialogState,
  ConfirmOptions,
  confirmAtom,
} from './confirm-states';
import { store } from './confirm-store';

export const ConfirmContainer = memo<ConfirmOptions>(props => {
  const dialogs = useAtomValue(confirmAtom, { store });
  const dialog: ConfirmDialogState | null = dialogs[0] || null;

  const handleClose = useEventCallback<NonNullable<ConfirmOptions['onClose']>>(
    (ev, reason) => {
      if (!dialog) {
        return;
      }

      dialog.options.onClose?.(ev, reason);
      confirm.cancel(dialog.key);
    },
  );

  if (!dialog) {
    return null;
  }

  const { key, ...options } = dialog.options;

  return <ConfirmDialog {...props} {...options} open onClose={handleClose} />;
});
