import { createIllustration } from '../create-illustration';
import documents from './documents.png';
import documents2x from './documents@2x.png';
import documents3x from './documents@3x.png';

export const Documents = createIllustration({
  src: documents,
  srcSet: [documents, documents2x, documents3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 64,
});
