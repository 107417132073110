import { AccessControlField } from '@allganize/access-control';
import { Checkbox } from '@allganize/ui-checkbox';
import { FormControlLabel } from '@allganize/ui-form';
import { css } from '@emotion/react';
import { Control, useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { EditFileFormValues } from '../edit-file/use-edit-file-form';
import { Scalars } from '../gql';

export interface AccessControlListFieldProps {
  control: Control<EditFileFormValues>;
  projectId: Scalars<'ID'>;
  className?: string;
  enableOverwriteFolderAccess?: boolean;
}

export const AccessControlListField = (props: AccessControlListFieldProps) => {
  const { control, enableOverwriteFolderAccess, ...others } = props;
  const { field: viewAccessToUserField } = useController({
    control,
    name: 'accessControl.viewAccessToUser',
  });
  const { field: viewAccessToAgentField } = useController({
    control,
    name: 'accessControl.viewAccessToAgent',
  });
  const { field: accessAgentsField } = useController({
    control,
    name: 'accessControl.accessAgents',
  });
  const { field: accessUserFiltersField } = useController({
    control,
    name: 'accessControl.accessUserFilters',
  });
  const { field: isOverwriteFolderAccessField } = useController({
    control,
    name: 'accessControl.isOverwriteFolderAccess',
  });

  return (
    <AccessControlField
      {...others}
      label={
        <FormattedMessage
          id="FAQ.Documents.NewFolder.WhoHasAccess.Label"
          defaultMessage="Who has access"
          description="FAQ documents WhoHasAccess label text"
        />
      }
      viewAccessToUser={{
        onChange: viewAccessToUserField.onChange,
        value: viewAccessToUserField.value,
      }}
      viewAccessToAgent={{
        onChange: viewAccessToAgentField.onChange,
        value: viewAccessToAgentField.value,
      }}
      accessAgents={{
        onChange: accessAgentsField.onChange,
        value: accessAgentsField.value,
      }}
      accessUserFilters={{
        onChange: accessUserFiltersField.onChange,
        value: accessUserFiltersField.value,
      }}
      SelectProps={{
        menuPortalTarget: document.body,
        menuShouldBlockScroll: true,
      }}
      disabled={
        enableOverwriteFolderAccess && isOverwriteFolderAccessField.value
      }
      overwriteFolderAccess={
        enableOverwriteFolderAccess && (
          <FormControlLabel
            {...isOverwriteFolderAccessField}
            label={
              <FormattedMessage
                id="access-control.overwrite.label"
                defaultMessage="Overwrite folder permissions"
                description="Access control overwrite label"
              />
            }
            control={<Checkbox />}
            checked={isOverwriteFolderAccessField.value}
            css={css`
              margin-left: 0;
              margin-bottom: 4px;
            `}
          />
        )
      }
    />
  );
};
