import { ChatStack } from '@allganize/alli-chat-base';
import {
  ContactAgent,
  ContactAgentProps,
} from '@allganize/alli-chat-contact-agent';
import {
  FormValue,
  FormValue_ChatFragment,
  FormValueProps,
} from '@allganize/alli-chat-form-value';
import {
  ChatMrcInfo,
  ChatMrcInfo_ChatFragment,
  ChatMrcInfoSlotProps,
  ChatMrcInfoSlots,
} from '@allganize/alli-chat-mrc-info';
import {
  ChatOptionInfo,
  ChatOptionInfo_ChatFragment,
  ChatOptionInfoProps,
} from '@allganize/alli-chat-option-info';
import { Avatar } from '@allganize/ui-avatar';
import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import useSlot from '@mui/material/utils/useSlot';
import { FunctionComponent, useContext } from 'react';
import { gql, ResultOf } from '../gql';
import { DatePickerDialog } from '../modal/date-picker-dialog';
import { Dialog } from '../modal/dialog';
import { TypingContext } from '../typing-context/typing-context';
import {
  AgentChatMessage,
  AgentChatMessage_AgentChatFragment,
} from './agent-chat-message';
import { AlliChatFile, AlliChatFile_ChatFragment } from './alli-chat-file';
import { AlliChatMedia, AlliChatMedia_ChatFragment } from './alli-chat-media';
import {
  CarouselChatMessage,
  CarouselChatMessage_CarouselChatFragment,
} from './carousel-chat-message';
import {
  SendFormChatMessage,
  SendFormChatMessage_SendFormChatFragment,
} from './send-form-chat-message';

export const AlliChat_ChatFragment = gql(
  `
  fragment AlliChat_ChatFragment on Chat {
    ...on AgentChat {
      __typename
      id
      ...AgentChatMessage_AgentChatFragment

      agent {
        id
        displayName
        firstName
        lastName

        avatar {
          id
          url
        }
      }
    }

    ...on BotChat {
      __typename
      id
    }

    ...on CarouselChat {
      __typename
      id
      ...CarouselChatMessage_CarouselChatFragment
    }

    ...on SendFormChat {
      __typename
      id
      ...SendFormChatMessage_SendFormChatFragment
    }

    ...AlliChatMedia_ChatFragment
    ...AlliChatFile_ChatFragment
    ...ChatMrcInfo_ChatFragment
    ...ChatOptionInfo_ChatFragment
    ...FormValue_ChatFragment
  }
`,
  [
    AgentChatMessage_AgentChatFragment,
    CarouselChatMessage_CarouselChatFragment,
    SendFormChatMessage_SendFormChatFragment,
    AlliChatMedia_ChatFragment,
    AlliChatFile_ChatFragment,
    ChatMrcInfo_ChatFragment,
    ChatOptionInfo_ChatFragment,
    FormValue_ChatFragment,
  ],
);

export interface AlliChatSlots extends ChatMrcInfoSlots {
  /**
   * @default ChatOptionInfo
   */
  chatOptionInfo?: React.ElementType;
  /**
   * @default ContactAgent
   */
  contactAgent?: React.ElementType;
  /**
   * @default FormValue
   */
  formValue?: React.ElementType;
}

export interface AlliChatSlotProps extends ChatMrcInfoSlotProps {
  chatOptionInfo: SlotProps<React.ElementType<ChatOptionInfoProps>, {}, {}>;
  contactAgent: SlotProps<React.ElementType<ContactAgentProps>, {}, {}>;
  formValue: SlotProps<React.ElementType<FormValueProps>, {}, {}>;
}

export type AlliChatSlotsAndSlotProps = CreateSlotsAndSlotProps<
  AlliChatSlots,
  AlliChatSlotProps
>;

export interface AlliChatProps extends AlliChatSlotsAndSlotProps {
  align?: 'left' | 'right';
  avatar?: React.ReactNode;
  chat: ResultOf<typeof AlliChat_ChatFragment>;
}

export const AlliChat: FunctionComponent<AlliChatProps> = props => {
  const {
    align = 'left',
    avatar: avatarProp,
    chat,
    slotProps = {},
    slots = {},
  } = props;
  const { onTyping } = useContext(TypingContext);

  const [ChatOptionInfoSlot, chatOptionInfoSlotProps] = useSlot(
    'chatOptionInfo',
    {
      elementType: ChatOptionInfo,
      // @ts-expect-error internal prop
      externalForwardedProps: { slots, slotProps },
      ownerState: {},
      className: undefined,
    },
  );
  const [ContactAgentSlot, contactAgentSlotProps] = useSlot('contactAgent', {
    elementType: ContactAgent,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });
  const [FormValueSlot, formValueSlotProps] = useSlot('formValue', {
    elementType: FormValue,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });

  const avatar =
    chat.__typename === 'AgentChat' ? (
      <Avatar
        size="md"
        src={chat.agent.avatar?.url}
        alt={
          chat.agent.displayName ||
          chat.agent.firstName ||
          chat.agent.lastName ||
          undefined
        }
      >
        {(
          chat.agent.displayName ||
          chat.agent.firstName ||
          chat.agent.lastName
        )?.charAt(0)}
      </Avatar>
    ) : (
      avatarProp
    );

  return (
    <ChatStack>
      {chat.__typename === 'AgentChat' && (
        <AgentChatMessage avatar={avatar} chat={chat} />
      )}

      {chat.__typename === 'CarouselChat' && (
        <CarouselChatMessage avatar={avatar} chat={chat} />
      )}

      {chat.__typename === 'SendFormChat' && (
        <SendFormChatMessage avatar={avatar} chat={chat} />
      )}

      <AlliChatMedia avatar={avatar} chat={chat} />
      <AlliChatFile avatar={avatar} chat={chat} />

      <ChatMrcInfo
        chat={chat}
        disablePadding
        slotProps={slotProps}
        slots={{ dialog: Dialog, ...slots }}
      />

      <ChatOptionInfoSlot
        chat={chat}
        disablePadding
        {...chatOptionInfoSlotProps}
      />

      <FormValueSlot
        align={align === 'left' ? 'right' : 'left'}
        chat={chat}
        onTyping={onTyping}
        // @ts-expect-error internal prop
        slots={{ dialog: DatePickerDialog }}
        {...formValueSlotProps}
      />

      <ContactAgentSlot
        align={align === 'left' ? 'right' : 'left'}
        onTyping={onTyping}
        {...contactAgentSlotProps}
      />
    </ChatStack>
  );
};
