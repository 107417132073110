import { useId } from '@allganize/hooks';
import { Button } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@allganize/ui-dialog';
import { Paper } from '@allganize/ui-paper';
import { Text } from '@allganize/ui-text';
import { Tooltip } from '@allganize/ui-tooltip';
import { IcCheckCircle, IcError, IcInfo } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FunctionComponent } from 'react';
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber,
  FormattedRelativeTime,
  useIntl,
} from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';

const ProgressContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const mrcBuildStatus = defineMessages({
  NO_TRAINING_DATA: {
    id: 'FAQ.RetrainButton.MRC.Progress.Status.NoData',
    defaultMessage: 'No training data',
    description: 'Retrain MRC model button status no data text',
  },
  WAITING: {
    id: 'FAQ.RetrainButton.MRC.Progress.Status.Waiting',
    defaultMessage: 'Waiting...',
    description: 'Retrain MRC model button status waiting text',
  },
  INIT: {
    id: 'FAQ.RetrainButton.MRC.Progress.Status.Initiating',
    defaultMessage: 'Initiating...',
    description: 'Retrain MRC model button status initiating text',
  },
  STARTED: {
    id: 'FAQ.RetrainButton.MRC.Progress.Status.Training',
    defaultMessage: 'Training...',
    description: 'Retrain MRC model button status training text',
  },
  UPDATING_VECTORS: {
    id: 'FAQ.RetrainButton.MRC.Progress.Status.Vectorizing',
    defaultMessage: 'Vectorizing',
    description: 'Retrain MRC model button status vectorizing text',
  },
  COMPLETED: {
    id: 'FAQ.RetrainButton.MRC.Progress.Status.Done',
    defaultMessage: 'Done',
    description: 'Retrain MRC model button status done text',
  },
  FAILED: {
    id: 'FAQ.RetrainButton.MRC.Progress.Status.Failed',
    defaultMessage: 'Failed',
    description: 'Retrain MRC model button status failed text',
  },
});

export const KnowledgeBaseBuildStateFragment = gql(`
  fragment KnowledgeBaseBuildStateFragment on Project {
    id
    machineTier
    mrcBuildStateInfo {
      state
      docTitleState
      docTitleTotal
      docTitleSuccess
      docTitleFail
      textSearchState
      lastUpdated
    }
  }
`);

interface TrainingDialogProps extends DialogProps {
  data: FragmentOf<typeof KnowledgeBaseBuildStateFragment>;
  close(): void;
}

export const TrainingDialog: FunctionComponent<TrainingDialogProps> = props => {
  const { close, data: dataProp, ...others } = props;
  const intl = useIntl();
  const dialogId = useId();

  const data = readFragment(KnowledgeBaseBuildStateFragment, dataProp);

  const isCompleted =
    (data.mrcBuildStateInfo?.docTitleState ===
      gql.scalar('MRCBuildDetailState', 'COMPLETED') ||
      data.mrcBuildStateInfo?.docTitleState ===
        gql.scalar('MRCBuildDetailState', 'NO_TRAINING_DATA')) &&
    (data.mrcBuildStateInfo?.textSearchState ===
      gql.scalar('MRCBuildDetailState', 'COMPLETED') ||
      data.mrcBuildStateInfo?.textSearchState ===
        gql.scalar('MRCBuildDetailState', 'NO_TRAINING_DATA'));

  const isFailed =
    data.mrcBuildStateInfo?.docTitleState ===
      gql.scalar('MRCBuildDetailState', 'FAILED') ||
    data.mrcBuildStateInfo?.textSearchState ===
      gql.scalar('MRCBuildDetailState', 'FAILED');

  const inProgress =
    data.mrcBuildStateInfo?.state === gql.scalar('MRCBuildState', 'BUILDING');

  return (
    <Dialog
      aria-labelledby={`${dialogId}-title`}
      aria-describedby={`${dialogId}-description`}
      {...others}
    >
      <DialogTitle id={`${dialogId}-title`}>
        {inProgress ? (
          <FormattedMessage
            id="FAQ.RetrainButton.MRC.Confirm.Title"
            defaultMessage="Retraining in progress"
            description="Retrain MRC model (when building is in progress) button confirm title text"
          />
        ) : (
          <FormattedMessage
            id="FAQ.TrainButton.MRC.Confirm.Title"
            defaultMessage="Train Documents AI Model"
            description="Train MRC model button confirm title text"
          />
        )}
      </DialogTitle>
      <DialogContent id={`${dialogId}-description`}>
        <Paper
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            min-width: 520px;
            padding: 16px;

            > div {
              display: flex;
              flex-direction: column;
              gap: 4px;
            }
          `}
          variant="outlined"
        >
          <div>
            <Text variant="title12">
              <FormattedMessage
                id="FAQ.RetrainButton.MRC.Progress.DocumentTitle"
                defaultMessage="Document title training"
                description="Retrain MRC model button tooltip document title text"
              />
            </Text>
            {data.mrcBuildStateInfo.docTitleState ===
            gql.scalar('MRCBuildDetailState', 'NO_TRAINING_DATA')
              ? intl.formatMessage(
                  mrcBuildStatus[data.mrcBuildStateInfo.docTitleState],
                )
              : data.mrcBuildStateInfo.docTitleTotal !== null &&
                data.mrcBuildStateInfo.docTitleSuccess !== null &&
                data.mrcBuildStateInfo.docTitleFail !== null && (
                  <FormattedMessage
                    id="FAQ.RetrainButton.MRC.Progress.DocumentTitle.value"
                    defaultMessage="{successCount} / {totalCount} ({failedCount} failed)"
                    description="Retrain FAQ model button tooltip last update timestamp text"
                    values={{
                      successCount: (
                        <FormattedNumber
                          value={data.mrcBuildStateInfo.docTitleSuccess}
                        />
                      ),
                      totalCount: (
                        <FormattedNumber
                          value={data.mrcBuildStateInfo.docTitleTotal}
                        />
                      ),
                      failedCount: (
                        <FormattedNumber
                          value={data.mrcBuildStateInfo.docTitleFail}
                        />
                      ),
                    }}
                  />
                )}
          </div>
          <div>
            <Text variant="title12">
              <FormattedMessage
                id="FAQ.RetrainButton.MRC.Progress.TextSearch"
                defaultMessage="Text search model training"
                description="Retrain MRC model button tooltip text search text"
              />
            </Text>
            {data.mrcBuildStateInfo.textSearchState &&
              intl.formatMessage(
                mrcBuildStatus[data.mrcBuildStateInfo.textSearchState],
              )}
          </div>
          <div>
            <Text variant="title12">
              <FormattedMessage
                id="FAQ.RetrainButton.MRC.Progress.Label"
                defaultMessage="Training state"
                description="Retrain MRC model button tooltip training progress label text"
              />
            </Text>
            {isCompleted && (
              <ProgressContent>
                <IcCheckCircle color="success" />
                <FormattedMessage
                  id="FAQ.RetrainButton.MRC.Progress.Success"
                  defaultMessage="Training successful."
                  description="Retrain MRC model button tooltip success text"
                />
              </ProgressContent>
            )}
            {isFailed && (
              <ProgressContent>
                <IcError color="error" />
                <FormattedMessage
                  id="FAQ.RetrainButton.MRC.Progress.Failed"
                  defaultMessage="Training failed."
                  description="Retrain MRC model button tooltip failed text"
                />
                <FormattedMessage
                  id="FAQ.RetrainButton.MRC.Progress.Failed.Description"
                  defaultMessage="If the training keeps failing, please contact customer support with details above."
                  description="Retrain MRC model button tooltip failed description text"
                />
              </ProgressContent>
            )}
            {inProgress && (
              <ProgressContent>
                <CircularProgress size="xs" />
                <FormattedMessage
                  id="FAQ.RetrainButton.MRC.Progress.InProgress"
                  defaultMessage="in progress"
                  description="Retrain MRC model button tooltip in progress text"
                />
              </ProgressContent>
            )}
          </div>
          <div>
            <Text variant="title12">
              <FormattedMessage
                id="FAQ.RetrainButton.Progress.LastUpdate.Label"
                defaultMessage="Last updated"
                description="Retrain FAQ model button tooltip last update timestamp text"
              />
            </Text>
            {data.mrcBuildStateInfo.lastUpdated ? (
              <FormattedRelativeTime
                value={(data.mrcBuildStateInfo.lastUpdated - Date.now()) / 1000}
                numeric="auto"
                updateIntervalInSeconds={60}
              />
            ) : (
              '-'
            )}
          </div>
          <div>
            <Text
              variant="title12"
              css={css`
                display: flex;
                align-items: center;
                gap: 4px;
              `}
            >
              <FormattedMessage
                id="FAQ.RetrainButton.MRC.machineTier.Label"
                defaultMessage="Allocated resources for document search"
                description="Retrain MRC model button project machine tier label"
              />
              <Tooltip
                title={
                  <FormattedMessage
                    id="FAQ.RetrainButton.MRC.machineTier.Tooltip"
                    defaultMessage="If you want to upgrade your tier, please contact your account manager."
                    description="Retrain MRC model button project machine tier tooltip"
                  />
                }
              >
                <IcInfo />
              </Tooltip>
            </Text>
            {data.machineTier ? (
              <FormattedMessage
                id={`ProjectMachineTier.${data.machineTier}`}
                defaultMessage={data.machineTier}
                description={`ProjectMachineTier.${data.machineTier}`}
              />
            ) : (
              '-'
            )}
          </div>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="outlined" size="large">
          <FormattedMessage
            id="actions.close"
            defaultMessage="Close"
            description="close button text"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
