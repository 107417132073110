import { Truncate } from '@allganize/truncate';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedDate } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';

export const KnowledgeBaseLastUpdatedColumn_KnowledgeBaseNodeFragment = gql(`
  fragment KnowledgeBaseLastUpdatedColumn_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    updatedAt

    updatedBy {
      id
      name
    }
  }
`);

interface KnowledgeBaseLastUpdatedColumnProps {
  node: FragmentOf<
    typeof KnowledgeBaseLastUpdatedColumn_KnowledgeBaseNodeFragment
  >;
}

export const KnowledgeBaseLastUpdatedColumn =
  memo<KnowledgeBaseLastUpdatedColumnProps>(props => {
    const { node: nodeProp } = props;
    const node = readFragment(
      KnowledgeBaseLastUpdatedColumn_KnowledgeBaseNodeFragment,
      nodeProp,
    );

    return (
      <Truncate
        css={css`
          word-break: break-all;
        `}
        clamp={1}
      >
        <Text variant="body14">
          <FormattedDate value={node.updatedAt} />
          {node.updatedBy && ` ・ ${node.updatedBy.name}`}
        </Text>
      </Truncate>
    );
  });
