import { format } from 'date-fns';
import { camelCase } from 'lodash-es';
import { gql } from '../gql';

const FILE_TYPE_OPTIONS = [
  gql.scalar('FileTypeEnum', 'TXT'),
  gql.scalar('FileTypeEnum', 'PDF'),
  gql.scalar('FileTypeEnum', 'DOCX'),
] as const;

type FileType = (typeof FILE_TYPE_OPTIONS)[number];

interface DownloadFileOption {
  label: string;
  value: FileType;
}

export const fileTypeOptions = FILE_TYPE_OPTIONS.map(option => ({
  label: option,
  value: option,
}));

export interface DownloadFileFormValues {
  fileName: string;
  fileType: DownloadFileOption;
}

export const getInitialFileName = (name: string) => {
  return `${camelCase(name)}${format(new Date(), 'yyyyMMddHHmmssSSS')}`;
};
