import { useQuery } from '@apollo/client/react';
import { autocompleteFilter, AutocompleteOption } from '@allganize/alli-sdk-ui';
import { useDebounce, useEventCallback } from '@allganize/hooks';
import { IcSearch } from '@allganize/ui-icons';
import { ResultOf } from '@graphql-typed-document-node/core';
import useAutocomplete from '@mui/material/useAutocomplete';
import { compact, uniq } from 'lodash-es';
import { UseFormSetValue } from 'react-hook-form';
import { gql } from '../gql';
import { ChatFormValues } from '../types/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UseChatFormAutocomplete_ChatFragment = gql(`
  fragment UseChatFormAutocomplete_ChatFragment on Chat {
    ... on AgentChat {
      useAutoComplete
    }
    ... on BotChat {
      useAutoComplete
    }
    ... on CarouselChat {
      useAutoComplete
    }
    ... on ContactAgentByEmailChat {
      useAutoComplete
    }
    ... on SendFormChat {
      useAutoComplete
    }
    ... on UserChat {
      useAutoComplete
    }
  }
`);

const AutoCompletionQuery = gql(`
  query AutoCompletionQuery(
    $partial: String!
    $where: ConversationWhereUniqueInput!
  ) {
    autoCompletion(partial: $partial, where: $where) {
      questions
    }
  }
`);

interface UseChatFormAutocompleteOptions {
  enabled: boolean;
  message: string;
  formDisabled: boolean;
  messageFieldEnabled: boolean;
  conversationId?: string | number;
  numAutoComplete?: number | null;
  lastChat: ResultOf<typeof UseChatFormAutocomplete_ChatFragment> | null;
  setValue: UseFormSetValue<ChatFormValues>;
}

export const useChatFormAutocomplete = ({
  enabled,
  message,
  formDisabled,
  messageFieldEnabled,
  conversationId = '',
  numAutoComplete,
  lastChat,
  setValue,
}: UseChatFormAutocompleteOptions) => {
  const useAutoComplete =
    enabled &&
    lastChat &&
    lastChat.__typename !== 'EventChat' &&
    lastChat.__typename !== 'CustomUIChat' &&
    lastChat.__typename !== 'FillSlotRichUXChat'
      ? lastChat.useAutoComplete
      : null;
  const skipAutoCompletion =
    !conversationId || !useAutoComplete || !messageFieldEnabled;

  const {
    data: autoCompletion,
    fetchMore,
    variables,
  } = useQuery(AutoCompletionQuery, {
    skip: skipAutoCompletion,
    variables: {
      partial: '',
      where: { id: conversationId },
    },
  });

  const questionList = compact(
    autoCompletion?.autoCompletion?.questions ?? [],
  ).map(q => ({
    title: q,
    icon: <IcSearch />,
  }));

  const autocomplete = useAutocomplete<AutocompleteOption, false, true, true>({
    autoComplete: false,
    autoHighlight: false,
    autoSelect: false,
    blurOnSelect: false,
    clearOnBlur: false,
    clearOnEscape: false,
    disableClearable: true,
    disableCloseOnSelect: false,
    disabled: formDisabled,
    freeSolo: true,
    options: questionList,
    inputValue: message,
    getOptionLabel(option) {
      return typeof option === 'string' ? option : option.title;
    },
    onInputChange(ev, value) {
      // prevent unnecessary setValue without event
      if (ev) {
        setValue('message', value, { shouldValidate: true, shouldTouch: true });
      }
    },
    filterOptions: (options, state) => {
      const inputValue = state.inputValue.trim();

      if (inputValue === '') {
        return [];
      }

      const filteredOptions = options
        .filter(option =>
          autocompleteFilter(
            {
              value: option.title,
              label: option.description || '',
              data: option,
            },
            inputValue,
          ),
        )
        .slice(0, numAutoComplete ?? 3);

      return filteredOptions;
    },
  });

  const getAutocompleteOptions = useEventCallback((inputValue: string) => {
    if (skipAutoCompletion) {
      return;
    }
    return fetchMore({
      variables: {
        ...variables,
        partial: inputValue,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (
          !(
            fetchMoreResult.autoCompletion?.questions &&
            fetchMoreResult.autoCompletion.questions.length > 0
          )
        ) {
          return prev;
        }

        return {
          ...prev,
          ...fetchMoreResult,
          autoCompletion: {
            ...prev.autoCompletion,
            ...fetchMoreResult.autoCompletion,
            questions: uniq([
              ...(prev.autoCompletion?.questions ?? []),
              ...fetchMoreResult.autoCompletion.questions,
            ]),
          },
        };
      },
    });
  });

  useDebounce(
    () => {
      getAutocompleteOptions(message);
    },
    500,
    [skipAutoCompletion, getAutocompleteOptions, message],
  );

  return enabled ? autocomplete : null;
};

export type UseChatFormAutocompleteReturn = ReturnType<
  typeof useChatFormAutocomplete
>;
