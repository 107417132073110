import { createIllustration } from '../create-illustration';
import singleAction from './single-action.png';
import singleAction2x from './single-action@2x.png';
import singleAction3x from './single-action@3x.png';

export const SingleAction = createIllustration({
  src: singleAction,
  srcSet: [singleAction, singleAction2x, singleAction3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 64,
});
