import {
  AccessControlSelectAgentOption,
  AccessControlSelectUserOption,
  parseAccessAgent,
  parseAccessUserFilter,
} from '@allganize/access-control';
import {
  FileDropzone,
  fileDropzoneClasses,
  FileDropzoneProps,
} from '@allganize/ui-file-input';
import { FormControl, FormHelperText } from '@allganize/ui-form';
import { IcUpload } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { filesize } from 'filesize';
import { uniq } from 'lodash-es';
import { FunctionComponent } from 'react';
import { ErrorCode, FileRejection } from 'react-dropzone';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 } from 'uuid';
import {
  UseAccessControlReturnType,
  useAccessControlValue,
} from '../hooks/use-access-control';
import { FileInput, FileSelectionFormValues } from './use-file-selection-form';

export interface FilesFieldProps
  extends Pick<FileDropzoneProps, 'accept' | 'maxSize'> {
  accessControl?: UseAccessControlReturnType;
  onAppend?(files: FileInput[]): void;
}

export const FilesField: FunctionComponent<FilesFieldProps> = props => {
  const { accept, accessControl: accessControlProp, maxSize, onAppend } = props;
  const theme = useTheme();
  const intl = useIntl();
  const { control, setError } = useFormContext<FileSelectionFormValues>();
  const { field, fieldState } = useController({ control, name: 'files' });
  const accessControl = useAccessControlValue(accessControlProp);
  const acceptFiles = accept
    ? uniq(Object.values(accept).flat()).join(', ')
    : '';

  const handleDropAccepted = (files: File[]) => {
    onAppend?.(
      files.map(file => ({
        id: v4(),
        file,
        name: file.name,
        hashtags: [],
        accessControl: accessControl
          ? {
              isOverwriteFolderAccess: true,
              accessAgents: (accessControl.accessAgents || []).reduce<
                AccessControlSelectAgentOption[]
              >((acc, agent) => {
                const parsedAgent = parseAccessAgent(agent);
                return parsedAgent ? [...acc, parsedAgent] : acc;
              }, []),
              viewAccessToAgent: accessControl.viewAccessToAgent,
              accessUserFilters:
                accessControl.accessUserFilters.map<AccessControlSelectUserOption>(
                  user => parseAccessUserFilter(user, intl),
                ),
              viewAccessToUser: accessControl.viewAccessToUser,
            }
          : {
              isOverwriteFolderAccess: true,
              accessAgents: [],
              viewAccessToAgent: true,
              accessUserFilters: [],
              viewAccessToUser: true,
            },
        submitStatus: 'init',
      })),
    );
  };

  const handleDropRejected = (rejections: FileRejection[]) => {
    const rejection = rejections[0];

    if (!rejection) {
      return;
    }

    const rejectionError = rejection.errors[0];

    if (!rejectionError) {
      return;
    }

    switch (rejectionError.code) {
      case ErrorCode.FileInvalidType:
        setError('files', {
          message: intl.formatMessage(
            {
              id: 'form.errors.file-type',
              defaultMessage: 'Only the following types are allowed: {types}',
              description: 'File type error message',
            },
            { types: acceptFiles },
          ),
        });
        break;
      case ErrorCode.FileTooLarge:
        setError('files', {
          message: intl.formatMessage(
            {
              id: 'Errors.file-max-size',
              defaultMessage: 'File too large (Max. {maxSize})',
              description: 'File max size error message',
            },
            {
              maxSize: filesize(maxSize ?? 0, {
                output: 'string',
                locale: intl.locale,
                localeOptions: intl.formats.date,
              }),
            },
          ),
        });
        break;
      case ErrorCode.FileTooSmall:
      case ErrorCode.TooManyFiles:
      default:
        setError('files', {
          message: intl.formatMessage({
            id: 'form.errors.invalid',
            defaultMessage: 'Invalid value',
            description: 'Invalid value error message',
          }),
        });
        break;
    }
  };

  return (
    <FormControl
      required
      fullWidth
      error={fieldState.invalid}
      disabled={field.disabled}
    >
      <Text
        css={css`
          margin-bottom: 8px;
        `}
        variant="title12"
      >
        <FormattedMessage
          id="FAQ.Documents.UploadForm.Label"
          defaultMessage="Upload file"
          description="FAQ documents upload form label text"
        />
      </Text>

      <FileDropzone
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 16px;
          min-height: 148px;
          border: 0;
          background-color: ${theme.palette.unstable_background.default};
          border-radius: ${theme.radius.sm}px;
          transition: ${theme.transitions.create('background-color', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.leavingScreen,
          })};

          &.${fileDropzoneClasses.dragActive} {
            background-color: ${theme.palette.backgroundInteractive
              .grayGhostAlpha.hover};

            transition: ${theme.transitions.create('background-color', {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.enteringScreen,
            })};
          }

          .${fileDropzoneClasses.dragOverlay} {
            display: none;
          }
        `}
        accept={accept}
        multiple
        maxSize={maxSize}
        onDropAccepted={handleDropAccepted}
        onDropRejected={handleDropRejected}
        disabled={field.disabled}
        inputRef={field.ref}
        inputProps={{ name: field.name }}
        onBlur={field.onBlur}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
          `}
        >
          <IcUpload fontSize="large" />

          <Text variant="body14" align="center">
            <FormattedMessage
              id="FAQ.Documents.UploadForm.File.Label.v2"
              defaultMessage="Drag and drop files here or <highlight>Browse files</highlight>"
              description="FAQ documents upload form file field label text"
              values={{
                highlight: (chunks: ReadonlyArray<React.ReactNode>) => (
                  <Text
                    css={css`
                      color: ${theme.palette.foregroundInteractive.primary};

                      .${fileDropzoneClasses.dragReject} & {
                        color: inherit;
                      }
                    `}
                    variant="subtitle14"
                    component="span"
                  >
                    {chunks}
                  </Text>
                ),
              }}
            />
          </Text>
        </div>
      </FileDropzone>

      {acceptFiles && (
        <ul
          css={css`
            margin: 8px 0 0 0;
            padding-left: 0;
            list-style-position: inside;
            color: ${theme.palette.foreground.secondary};
          `}
        >
          <li>
            <Text component="span" variant="body12">
              <FormattedMessage
                id="FAQ.Documents.UploadForm.NewInstructions.1"
                defaultMessage="Supported file type: {acceptFiles}"
                description="FAQ documents upload form instructions #1"
                values={{
                  acceptFiles,
                }}
              />
            </Text>
          </li>
        </ul>
      )}

      {fieldState.error?.message && (
        <FormHelperText error>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  );
};
