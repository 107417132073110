import { INode, NodeId } from 'react-accessible-treeview';
import { FragmentOf, gql, readFragment, ResultOf } from '../gql';
import { ROOT_NODE_ID } from '../knowledge-base-tree-view/knowledge-base-tree-view';
import { Metadata } from '../knowledge-base-node/knowledge-base-node';

export const ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment = gql(`
  fragment ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment on KnowledgeBaseNodeEdge {
    node {
      id
      name
      ownership

      ...on KnowledgeBaseNodeFile {
        processState
      }

      parent {
        id
      }
    }
  }
`);

export function convertEdgesToNodes<
  TFragment extends
    | FragmentOf<typeof ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment>
    | ResultOf<typeof ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment>,
  TFn extends (fragment: TFragment) => Record<string, any>,
>(
  edges: TFragment[] | undefined,
  extraDataFn?: TFn,
): INode<Metadata & ReturnType<TFn>>[] | undefined {
  if (!edges) {
    return;
  }
  const childrenTree =
    edges.reduce<Record<string, NodeId[]>>((prev, fragment) => {
      const curr = readFragment(
        ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment,
        fragment,
      );
      if (curr.node.parent) {
        if (!prev[curr.node.parent.id]) {
          prev[curr.node.parent.id] = [];
        }
        prev[curr.node.parent.id].push(curr.node.id);
      } else {
        if (!prev[ROOT_NODE_ID]) {
          prev[ROOT_NODE_ID] = [];
        }
        prev[ROOT_NODE_ID].push(curr.node.id);
      }
      return prev;
    }, {}) ?? {};

  return [
    {
      id: ROOT_NODE_ID,
      name: ROOT_NODE_ID,
      parent: null,
      children: childrenTree[ROOT_NODE_ID] ?? [],
      isBranch: false,
      metadata: {
        type: gql.scalar('KnowledgeBaseNodeType', 'FOLDER'),
        ownership: null,
        processState: null,
      },
    },
    ...edges.map(fragment => {
      const edge = readFragment(
        ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment,
        fragment,
      );
      const extraMetadata = extraDataFn?.(fragment);

      return {
        id: edge.node.id,
        name: edge.node.name,
        parent: edge.node.parent?.id ?? ROOT_NODE_ID,
        children: childrenTree[edge.node.id] ?? [],
        isBranch: true,
        metadata: {
          type:
            edge.node.__typename === 'KnowledgeBaseNodeFile'
              ? gql.scalar('KnowledgeBaseNodeType', 'FILE')
              : gql.scalar('KnowledgeBaseNodeType', 'FOLDER'),
          ownership: edge.node.ownership,
          processState:
            edge.node.__typename === 'KnowledgeBaseNodeFile'
              ? edge.node.processState
              : null,
          ...extraMetadata,
        } as Metadata & ReturnType<TFn>,
      };
    }),
  ] as INode<Metadata & ReturnType<TFn>>[];
}
