import { useEventCallback } from '@allganize/hooks';
import { Alert, alertClasses } from '@allganize/ui-alert';
import { IcInfo } from '@allganize/ui-icons';
import { Tab, tabClasses, Tabs } from '@allganize/ui-tab';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccessControlContainer } from '../access-control-container/access-control-container';
import { AccessControlSelectProps } from '../access-control-select/access-control-select-type-map';
import { AccessControlSwitch } from '../access-control-switch/access-control-switch';
import { AccessControlAgentField } from './access-control-agent-field';
import {
  AccessControlFieldBase,
  AccessControlFieldBaseProps,
} from './access-control-field-base';
import { AccessControlUserField } from './access-control-user-field';
import { AccessControlFieldPropBase } from './access-control-field-types';

type TabType = 'agent' | 'user';

export interface AccessControlFieldWithTabProps
  extends Omit<
      AccessControlFieldPropBase,
      'accessAgents' | 'accessUserFilters'
    >,
    Required<
      Pick<AccessControlFieldPropBase, 'accessAgents' | 'accessUserFilters'>
    >,
    Pick<AccessControlSelectProps, 'onAgentOptionsLoad'>,
    AccessControlFieldBaseProps {
  overwriteFolderAccess?: React.ReactNode;
}

export const AccessControlFieldWithTab = forwardRef<
  HTMLFieldSetElement,
  AccessControlFieldWithTabProps
>(
  (
    {
      label,
      accessUserFilters,
      accessAgents,
      viewAccessToAgent,
      viewAccessToUser,
      projectId,
      SelectProps,
      disabled,
      overwriteFolderAccess,
      onAgentOptionsLoad,
      ...others
    },
    ref,
  ) => {
    const theme = useTheme();
    const [selectedTab, setTab] = useState<TabType>('agent');
    const handleTabChange = useEventCallback(
      (evt: React.SyntheticEvent, value: TabType) => {
        evt.stopPropagation();
        setTab(value);
      },
    );
    const showAgentAlert = selectedTab === 'agent' && viewAccessToAgent?.value;
    const showUserAlert = selectedTab === 'user' && viewAccessToUser?.value;

    return (
      <AccessControlFieldBase
        label={
          label ?? (
            <FormattedMessage
              id="access-control.field.label"
              defaultMessage="Who has access"
              description="Access control field label"
            />
          )
        }
        {...others}
        ref={ref}
      >
        {overwriteFolderAccess}
        <AccessControlContainer withTab>
          <Tabs
            css={css`
              border-bottom: 1px solid ${theme.palette.border.divider.subtle};

              .${tabClasses.root} {
                text-transform: capitalize;
              }
            `}
            scrollButtons={false}
            value={selectedTab}
            onChange={handleTabChange}
          >
            <Tab
              value="agent"
              label={
                <FormattedMessage
                  id="access-control.field.tab.agent.label"
                  defaultMessage="Agents"
                  description="Agent tab label of the access control field"
                />
              }
            />
            <Tab
              value="user"
              label={
                <FormattedMessage
                  id="access-control.field.tab.user.label"
                  defaultMessage="Customers(SDK/API)"
                  description="User tab label of the access control field"
                />
              }
            />
          </Tabs>
          <div
            css={css`
              padding: 0 12px;
            `}
          >
            {selectedTab === 'agent' && viewAccessToAgent && (
              <AccessControlSwitch
                {...viewAccessToAgent}
                label={
                  <FormattedMessage
                    id="access-control.field.viewAccessToAgent.label"
                    defaultMessage="Any agents in this project can view"
                    description="View access to agent label"
                  />
                }
                fullWidth
                disabled={disabled}
                onChange={(_, value) => viewAccessToAgent.onChange(value)}
              />
            )}
            {selectedTab === 'user' && viewAccessToUser && (
              <AccessControlSwitch
                {...viewAccessToUser}
                label={
                  <FormattedMessage
                    id="access-control.field.viewAccessToUser.label"
                    defaultMessage="Any customers(SDK/API) can view"
                    description="View access to user label"
                  />
                }
                fullWidth
                disabled={disabled}
                onChange={(_, value) => viewAccessToUser.onChange(value)}
              />
            )}
          </div>
          {(showAgentAlert || showUserAlert) && (
            <div
              css={css`
                padding: 0 12px;

                /* TODO: remove these styles after update ui component */
                .${alertClasses.root} {
                  border: 1px solid ${theme.palette.border.information};
                  background-color: ${theme.palette.unstable_background
                    .information.subtle};
                  color: ${theme.palette.foreground.default};
                  padding: 0 12px;
                }

                .${alertClasses.icon} {
                  color: ${theme.palette.foreground.information};
                  margin-right: 4px;
                }
              `}
            >
              {showAgentAlert && (
                <Alert color="info" icon={<IcInfo />}>
                  <FormattedMessage
                    id="access-control.field.viewAccessToAgent.alert"
                    defaultMessage="The above option allows all agents, including those not listed below, to view."
                    description="View access to user alert message"
                  />
                </Alert>
              )}
              {showUserAlert && (
                <Alert color="info" icon={<IcInfo />}>
                  <FormattedMessage
                    id="access-control.field.viewAccessToUser.alert"
                    defaultMessage="The above option allows all customers, including those not listed below, to view."
                    description="View access to user alert message"
                  />
                </Alert>
              )}
            </div>
          )}
          {selectedTab === 'agent' && (
            <AccessControlAgentField
              projectId={projectId}
              SelectProps={SelectProps as any}
              disabled={disabled}
              {...accessAgents}
              onAgentOptionsLoad={onAgentOptionsLoad}
            />
          )}
          {selectedTab === 'user' && (
            <AccessControlUserField
              projectId={projectId}
              SelectProps={SelectProps as any}
              disabled={disabled}
              {...accessUserFilters}
            />
          )}
        </AccessControlContainer>
      </AccessControlFieldBase>
    );
  },
);
