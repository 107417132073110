import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ChatOptionInfoOptionListItemClassKey =
  | 'root'
  | 'listItem'
  | 'list'
  | 'carousel';
export type ChatOptionInfoOptionListItemClasses = Record<
  ChatOptionInfoOptionListItemClassKey,
  string
>;

export const chatOptionInfoOptionListItemClasses: ChatOptionInfoOptionListItemClasses =
  generateUtilityClasses('ChatOptionInfoOptionListItem', [
    'root',
    'listItem',
    'list',
    'carousel',
  ]);
