import { Truncate } from '@allganize/truncate';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

export const KnowledgeBaseLastUpdatedColumnHeader: FunctionComponent = () => {
  return (
    <Truncate
      css={css`
        word-break: break-all;
      `}
      clamp={1}
    >
      <FormattedMessage
        id="knowledge-base-table.updated-at"
        defaultMessage="Last updated"
        description="Knowledge base table last updated column text"
      />
    </Truncate>
  );
};
