import { Truncate } from '@allganize/truncate';
import { OverrideProps } from '@allganize/types';
import {
  Button,
  ExtendButton,
  ExtendButtonTypeMap,
} from '@allganize/ui-button';
import { IcFolder } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { gql, ResultOf } from '../gql';

export const LocationButton_KnowledgeBaseNodeFragment = gql(`
  fragment LocationButton_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    ownership

    parent {
      id
      name
      ownership
    }
  }
`);

interface LocationButtonOwnProps {
  node: ResultOf<typeof LocationButton_KnowledgeBaseNodeFragment>;
  rootNodeName?: React.ReactNode;
}

type LocationButtonTypeMap<
  P = {},
  D extends React.ElementType = 'button',
> = ExtendButtonTypeMap<{
  props: P & LocationButtonOwnProps;
  defaultComponent: D;
}>;

export type LocationButtonProps<
  D extends React.ElementType = LocationButtonTypeMap['defaultComponent'],
  P = {},
> = OverrideProps<LocationButtonTypeMap<P, D>, D> & {
  component?: React.ElementType;
};

// @ts-expect-error overridable component
export const LocationButton: ExtendButton<LocationButtonTypeMap> = forwardRef(
  (props, ref) => {
    const {
      node,
      rootNodeName = (
        <FormattedMessage
          id="all-files"
          defaultMessage="All files"
          description="All files"
        />
      ),
      ...other
    } = props;

    return (
      <Button startIcon={<IcFolder />} {...other} ref={ref}>
        <Truncate
          clamp={1}
          css={css`
            word-break: break-all;
          `}
        >
          {node.parent ? node.parent.name : rootNodeName}
        </Truncate>
      </Button>
    );
  },
);
