import { useEventCallback } from '@allganize/hooks';
import { css } from '@emotion/react';
import { SetStateAction } from 'jotai';
import { FunctionComponent, useMemo, useState } from 'react';
import TreeView, {
  EventCallback,
  INode,
  ITreeViewOnExpandProps,
  ITreeViewOnLoadDataProps,
  ITreeViewOnSelectProps,
  ITreeViewProps,
  NodeId,
} from 'react-accessible-treeview';
import { useIntl } from 'react-intl';
import { ResultOf } from '../gql';
import {
  KnowledgeBaseNode,
  Metadata,
} from '../knowledge-base-node/knowledge-base-node';
import { ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment } from '../utils/convert-edges-to-nodes';

export const ROOT_NODE_ID: NodeId = 'all-files';

export type KnowledgeBaseTreeViewNode = Omit<
  ResultOf<typeof ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment>['node'],
  'parent' | '__typename'
> | null;

export interface KnowledgeBaseTreeViewProps
  extends Partial<
      Omit<
        ITreeViewProps,
        'onLoadData' | 'data' | 'nodeRenderer' | 'selectedIds'
      >
    >,
    Pick<ITreeViewProps, 'expandedIds'> {
  data: INode<Metadata>[];
  onExpandedIdsChange?(value: SetStateAction<NodeId[]>): void;
  onLoadData?(nodeId: NodeId): Promise<void>;
  onSelectedNodeChange?(
    node: KnowledgeBaseTreeViewNode,
    isSelected?: boolean,
  ): void;
  selectedIds?: NodeId[];
  selectable?: boolean;
  hideRootNode?: boolean;
  rootNodeName?: string;
}

export const KnowledgeBaseTreeView: FunctionComponent<
  KnowledgeBaseTreeViewProps
> = props => {
  const {
    data,
    expandedIds: expandedIdsProp = [],
    onExpandedIdsChange,
    onLoadData,
    onSelectedNodeChange,
    selectedIds: selectedIdsProp = [],
    selectable = false,
    hideRootNode = false,
    rootNodeName,
    ...others
  } = props;
  const intl = useIntl();

  const [loadingIds, setLoadingIds] = useState<NodeId[]>([]);
  const selectedIds = useMemo(() => {
    return selectedIdsProp.filter(id => data.some(node => node.id === id));
  }, [data, selectedIdsProp]);
  const expandedIds = useMemo(() => {
    // the root node should always be expanded
    if (expandedIdsProp.length === 0) {
      return [ROOT_NODE_ID];
    }

    return [
      ROOT_NODE_ID,
      ...expandedIdsProp.filter(expandedId => {
        return data.some(node => node.id === expandedId);
      }),
    ];
  }, [data, expandedIdsProp]);

  const handleNodeExpand = useEventCallback((opts: ITreeViewOnExpandProps) => {
    const { element, isExpanded } = opts;
    if (element.id === ROOT_NODE_ID) return;
    onExpandedIdsChange?.(prev => {
      if (!isExpanded) {
        return prev.filter(id => id !== element.id);
      }

      return [...prev, element.id];
    });
    others.onExpand?.(opts);
  });

  const handleNodeSelect = useEventCallback(
    ({ element, isSelected }: ITreeViewOnSelectProps) => {
      const node =
        element.id === ROOT_NODE_ID
          ? null
          : {
              id: element.id,
              name: element.name,
              ownership: (element.metadata as Metadata)?.ownership ?? null,
            };
      onSelectedNodeChange?.(node, isSelected);
    },
  );

  const handleLoadData = useEventCallback(
    async ({ element }: ITreeViewOnLoadDataProps) => {
      if (!element.isBranch) {
        return;
      }

      if (element.children.length > 0) {
        return;
      }

      setLoadingIds(prev => [...prev, element.id]);

      try {
        await onLoadData?.(element.id);
      } finally {
        setLoadingIds(prev => prev.filter(id => id !== element.id));
      }
    },
  );

  return (
    <>
      {!hideRootNode && (
        <KnowledgeBaseNode
          fullWidth
          leaf
          name={
            rootNodeName ??
            intl.formatMessage({
              id: 'all-files',
              defaultMessage: 'All files',
              description: 'All files',
            })
          }
          selected={selectedIds.length === 0}
          onClick={() => onSelectedNodeChange?.(null, true)}
        />
      )}

      <TreeView
        css={css`
          &,
          ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
        `}
        {...others}
        data={data}
        expandedIds={expandedIds}
        onExpand={handleNodeExpand}
        selectedIds={selectedIds}
        // always allow multi-select and control selectedIds by ourselves
        multiSelect
        togglableSelect={selectable}
        onSelect={handleNodeSelect}
        onLoadData={handleLoadData}
        nodeRenderer={({
          element,
          level,
          isExpanded,
          isSelected,
          isDisabled,
          getNodeProps,
          handleExpand,
          handleSelect,
        }) => {
          const handleClick: EventCallback = ev => {
            if (!isExpanded && !selectable) {
              handleExpand(ev);
            }
            handleSelect(ev);
          };

          return (
            <KnowledgeBaseNode
              {...getNodeProps({ onClick: handleClick })}
              fullWidth
              style={{ paddingLeft: 16 * (level - 1) }}
              expanded={isExpanded}
              selected={isSelected}
              disabled={isDisabled}
              name={element.name}
              metadata={element.metadata as Metadata}
              loading={loadingIds.includes(element.id)}
              onExpand={handleExpand}
            />
          );
        }}
      />
    </>
  );
};
