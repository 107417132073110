import { ConversationContext } from '@allganize/alli-sdk/conversation-detail/conversation-context';
import { FunctionComponent, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

export const ConversationMeta: FunctionComponent = () => {
  const intl = useIntl();
  const { data } = useContext(ConversationContext);
  const title = useMemo(() => {
    return [
      data.conversation?.readInfo?.userUnreadChatCount &&
        `(${intl.formatNumber(
          data.conversation.readInfo.userUnreadChatCount,
        )})`,
      data.conversation?.title,
    ]
      .filter(Boolean)
      .join(' ');
  }, [
    data.conversation?.readInfo?.userUnreadChatCount,
    data.conversation?.title,
    intl,
  ]);

  if (!title) {
    return null;
  }

  return (
    <Helmet key={[data.conversation?.id, title].join('-')}>
      <title>{title}</title>
    </Helmet>
  );
};
