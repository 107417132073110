import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { mapValues } from 'lodash-es';
import { forwardRef } from 'react';
import { SwitchBase } from '../switch-base';
import { switchClasses } from './switch-classes';
import { SwitchProps } from './switch-type-map';

export const Switch = forwardRef<HTMLSpanElement, SwitchProps>((props, ref) => {
  const { classes: classesProp, className, edge = false, ...other } = props;
  const theme = useTheme();
  const classes = mapValues(switchClasses, (value, key) => {
    return clsx(value, classesProp?.[key as keyof typeof switchClasses]);
  });
  const icon = (
    <span
      data-testid="switch-thumb"
      css={css`
        background-color: currentColor;
        width: 16px;
        height: 16px;
        border-radius: ${theme.radius.round}px;
        position: absolute;
        top: 0;
        left: 0;
        margin: 4px;
        transition: ${theme.transitions.create(['left', 'transform'], {
          duration: theme.transitions.duration.shortest,
        })};
        transform: translateX(0);
      `}
      className={classes.thumb}
    />
  );

  return (
    <span
      data-testid="switch"
      css={[
        css`
          display: inline-flex;
          width: 40px;
          height: 24px;
          padding: 2px;
          box-sizing: border-box;
          position: relative;
          flex-shrink: 0;
          z-index: 0;
          vertical-align: middle;

          @media print {
            color-adjust: exact;
          }
        `,
        edge === 'start' &&
          css`
            margin-left: -2px;
          `,
        edge === 'end' &&
          css`
            margin-right: -2px;
          `,
      ]}
      className={clsx(classes.root, className)}
    >
      <SwitchBase
        data-testid="switch-switch-base"
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          padding: 2px;
          color: ${theme.palette.common.white};
          transition: ${theme.transitions.create(['left', 'transform'], {
            duration: theme.transitions.duration.shortest,
          })};

          &.${switchClasses.checked} .${switchClasses.thumb} {
            transform: translateX(16px);
          }

          &.${switchClasses.checked} + .${switchClasses.track} {
            background-color: ${theme.palette.backgroundInteractive.primary
              .default};
          }

          &.${switchClasses.disabled} + .${switchClasses.track} {
            background-color: ${theme.palette.unstable_background.grayDisabled
              .default};
          }

          & .${switchClasses.input} {
            left: 0;
            width: 100%;
          }

          &:hover + .${switchClasses.track} {
            background-color: ${theme.palette.backgroundInteractive.gray.hover};
          }

          &:active + .${switchClasses.track} {
            background-color: ${theme.palette.backgroundInteractive.gray
              .pressed};
          }

          &:hover.${switchClasses.checked} + .${switchClasses.track} {
            background-color: ${theme.palette.backgroundInteractive.primary
              .hover};
          }

          &:active.${switchClasses.checked} + .${switchClasses.track} {
            background-color: ${theme.palette.backgroundInteractive.primary
              .pressed};
          }

          &.${switchClasses.focusVisible} + .${switchClasses.track} {
            outline: ${theme.palette.border.focused} solid 2px;
            outline-offset: 2px;
          }
        `}
        type="checkbox"
        icon={icon}
        checkedIcon={icon}
        {...other}
        classes={{
          ...classes,
          root: clsx(classes.switchBase),
        }}
        ref={ref}
        focusVisibleClassName={clsx(
          classes.focusVisible,
          other.focusVisibleClassName,
        )}
      />

      <span
        data-testid="switch-track"
        css={css`
          height: 100%;
          width: 100%;
          border-radius: ${theme.radius.round}px;
          z-index: -1;
          transition: ${theme.transitions.create(['background-color'], {
            duration: theme.transitions.duration.shortest,
          })};
          background-color: ${theme.palette.backgroundInteractive.gray.default};
        `}
        className={classes.track}
      />
    </span>
  );
});
