import { useId } from '@allganize/hooks';
import { IconButton } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { Divider } from '@allganize/ui-divider';
import {
  IcDelete,
  IcDownload,
  IcFolderMove,
  IcMoreVert,
  IcToggleOff,
  IcToggleOn,
} from '@allganize/ui-icons';
import { ListItemIcon, ListItemText } from '@allganize/ui-list';
import { Menu, MenuItem } from '@allganize/ui-menu';
import { omit } from 'lodash-es';
import { memo, useContext, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { readFragment } from '../gql';
import { KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment } from '../knowledge-base-table/knowledge-base-table-columns';
import {
  KnowledgeBaseActionsContext,
  KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment,
} from './knowledge-base-actions-context';

export const KnowledgeBaseActionsColumnHeader = memo(() => {
  const {
    isEditable = () => true,
    selectedRows,
    setRowSelection,
    onDelete,
    onMove,
    onDownload,
    onToggleStatus,
  } = useContext(KnowledgeBaseActionsContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);
  const menuId = useId();
  const [deleting, setDeleting] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [turningOn, setTurningOn] = useState(false);
  const [turningOff, setTurningOff] = useState(false);

  const isFolderSelected = selectedRows.some(
    row =>
      readFragment(
        KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
        row.original,
      ).node.__typename === 'KnowledgeBaseNodeFolder',
  );
  const isEditableNodes = selectedRows.reduce(
    (acc, curr) =>
      acc &&
      isEditable(
        readFragment(
          KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment,
          readFragment(
            KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
            curr.original,
          ).node,
        ),
      ),
    true,
  );

  const canMove = !!onMove && isEditableNodes;
  const canDelete = !!onDelete && isEditableNodes;
  const canDownload = !isFolderSelected;
  const canToggleStatus =
    !isFolderSelected && !!onToggleStatus && isEditableNodes;

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClick = () => {
    setMenuOpen(true);
  };

  const handleDelete = async () => {
    setDeleting(true);

    try {
      const result = await onDelete?.(...selectedRows.map(row => row.id));

      if (result) {
        setRowSelection(prev =>
          omit(
            prev,
            selectedRows.map(row => row.id),
          ),
        );
      }
    } finally {
      setDeleting(false);
      closeMenu();
    }
  };

  const handleMove = () => {
    const rows = selectedRows.map(row =>
      readFragment(
        KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment,
        readFragment(
          KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
          row.original,
        ).node,
      ),
    );
    onMove?.(...rows);
    closeMenu();
  };

  const handleDownload = async () => {
    setDownloading(true);

    try {
      await onDownload?.(
        ...selectedRows.map(row =>
          readFragment(
            KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment,
            readFragment(
              KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
              row.original,
            ).node,
          ),
        ),
      );
    } finally {
      setDownloading(false);
      closeMenu();
    }
  };

  const handleTurnOn = async () => {
    setTurningOn(true);

    try {
      await onToggleStatus?.({
        ids: selectedRows.map(row => row.id),
        use: true,
      });
    } finally {
      setTurningOn(false);
      closeMenu();
    }
  };

  const handleTurnOff = async () => {
    setTurningOff(true);

    try {
      await onToggleStatus?.({
        ids: selectedRows.map(row => row.id),
        use: false,
      });
    } finally {
      setTurningOff(false);
      closeMenu();
    }
  };

  if (!canDelete && !canDownload && !canToggleStatus) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-controls={menuOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        shape="rounded"
        disabled={selectedRows.length === 0}
        onClick={handleClick}
        ref={menuTriggerRef}
      >
        <IcMoreVert />
      </IconButton>

      <Menu
        id={menuId}
        anchorEl={menuTriggerRef.current}
        open={menuOpen}
        onClose={closeMenu}
      >
        {canMove && (
          <MenuItem onClick={handleMove}>
            <ListItemIcon>
              <IcFolderMove />
            </ListItemIcon>

            <ListItemText
              primary={
                <FormattedMessage
                  id="actions.move"
                  defaultMessage="Move to folder"
                  description="move action text"
                />
              }
            />
          </MenuItem>
        )}

        {canDownload && (
          <MenuItem
            disabled={downloading || !canDownload}
            onClick={handleDownload}
          >
            <ListItemIcon>
              {downloading ? <CircularProgress size="sm" /> : <IcDownload />}
            </ListItemIcon>

            <ListItemText
              primary={
                <FormattedMessage
                  id="actions.download"
                  defaultMessage="Download"
                  description="Download action text"
                />
              }
            />
          </MenuItem>
        )}

        {canToggleStatus && (
          <MenuItem
            disabled={turningOn || !canToggleStatus}
            onClick={handleTurnOn}
          >
            <ListItemIcon>
              {turningOn ? <CircularProgress size="sm" /> : <IcToggleOn />}
            </ListItemIcon>

            <ListItemText
              primary={
                <FormattedMessage
                  id="FAQ.Documents.TurnOnButton"
                  defaultMessage="Turn on selected"
                  description="FAQ document list turn on button"
                />
              }
            />
          </MenuItem>
        )}

        {canToggleStatus && (
          <MenuItem
            disabled={turningOff || !canToggleStatus}
            onClick={handleTurnOff}
          >
            <ListItemIcon>
              {turningOff ? <CircularProgress size="sm" /> : <IcToggleOff />}
            </ListItemIcon>

            <ListItemText
              primary={
                <FormattedMessage
                  id="FAQ.Documents.TurnOffButton"
                  defaultMessage="Turn off selected"
                  description="FAQ document list turn off button"
                />
              }
            />
          </MenuItem>
        )}

        {(canMove || canDownload || canToggleStatus) && canDelete && (
          <Divider component="li" />
        )}

        {canDelete && (
          <MenuItem disabled={deleting} onClick={handleDelete}>
            <ListItemIcon>
              {deleting ? <CircularProgress size="sm" /> : <IcDelete />}
            </ListItemIcon>

            <ListItemText
              primary={
                <FormattedMessage
                  id="actions.delete"
                  defaultMessage="Delete"
                  description="FAQ documents delete button text"
                />
              }
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
});
