import {
  UploadFile as BaseUploadFile,
  FileSelectionFormValues,
  UploadFile_ProjectFragment,
  UploadFileProps,
} from '@allganize/knowledge-base-actions';
import { FormError, FormFieldError } from '@allganize/utils-form';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { useMutation, useSuspenseQuery } from '@apollo/client/react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { gql } from '../gql';

const UploadFileQuery = gql(
  `
  query UploadFileQuery(
    $isUserQuery: Boolean!
  ) {
    project {
      id
      ...UploadFile_ProjectFragment
    }
  }
`,
  [UploadFile_ProjectFragment],
);

const UploadKnowledgeFileWithTokenMutation = gql(`
  mutation UploadKnowledgeFileWithTokenMutation(
    $file: Upload,
    $fileName: String
    $status: Boolean,
    $where: ProjectWhereUniqueInput!
  ) {
    uploadKnowledgeFileWithToken(
      file: $file
      fileName: $fileName
      status: $status
      where: $where
    ) {
      knowledgeBaseNodes {
        id
      }

      duplicateNodes {
        id
        name
      }

      errors {
        __typename
        key
        message
        field
        info
      }
    }
  }
`);

export const UploadFile: FunctionComponent = () => {
  const { data } = useSuspenseQuery(UploadFileQuery, {
    variables: { isUserQuery: true },
  });
  const [mutate] = useMutation(UploadKnowledgeFileWithTokenMutation);
  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();

  const handleSubmit: UploadFileProps['onSubmit'] = async (values, form) => {
    const errors: FormFieldError<FileSelectionFormValues>[] = [];

    await Promise.all(
      values.files.map(async (fileInput, index) => {
        if (fileInput.submitStatus === 'success') {
          return;
        }

        try {
          if (!data.project) {
            throw new Error(formatErrorMessage());
          }

          form.setValue(`files.${index}`, {
            ...fileInput,
            submitStatus: 'isSubmitting',
          });

          const result = await mutate({
            variables: {
              where: { id: data.project.id },
              fileName: fileInput.file.name,
              file: fileInput.file,
              status: false,
            },
          });

          if (
            result.data?.uploadKnowledgeFileWithToken?.duplicateNodes?.length
          ) {
            const duplicateNode =
              result.data.uploadKnowledgeFileWithToken.duplicateNodes[0];

            throw new Error(
              intl.formatMessage(
                {
                  id: 'upload-file.duplicate',
                  defaultMessage: 'The same file already exists: {filename}.',
                  description: 'upload file duplicate text',
                },
                { filename: duplicateNode?.name ?? '' },
              ),
            );
          }

          if (result.data?.uploadKnowledgeFileWithToken?.knowledgeBaseNodes) {
            form.setValue(`files.${index}`, {
              ...fileInput,
              submitStatus: 'success',
            });
            return;
          }

          if (result.data?.uploadKnowledgeFileWithToken?.errors?.length) {
            throw new AlliGraphQLError(
              result.data.uploadKnowledgeFileWithToken.errors,
            );
          }

          throw new Error(formatErrorMessage());
        } catch (err) {
          if (err instanceof AlliGraphQLError) {
            const error = err.errors.find(error => !!error);

            if (error) {
              errors.push({
                name: `files.${index}`,
                message: formatErrorMessage(error),
              });
              form.setValue(`files.${index}`, {
                ...fileInput,
                submitStatus: 'error',
              });
              form.setError(`files.${index}`, {
                message: formatErrorMessage(error),
              });
              return;
            }
          }

          if (err instanceof Error) {
            errors.push({
              name: `files.${index}`,
              message: formatErrorMessage(err),
            });
            form.setValue(`files.${index}`, {
              ...fileInput,
              submitStatus: 'error',
            });
            form.setError(`files.${index}`, {
              message: formatErrorMessage(err),
            });
            return;
          }

          if (typeof err === 'string') {
            errors.push({
              name: `files.${index}`,
              message: err,
            });
            form.setValue(`files.${index}`, {
              ...fileInput,
              submitStatus: 'error',
            });
            form.setError(`files.${index}`, { message: err });
            return;
          }

          errors.push({
            name: `files.${index}`,
            message: formatErrorMessage(),
          });
          form.setValue(`files.${index}`, {
            ...fileInput,
            submitStatus: 'error',
          });
          form.setError(`files.${index}`, { message: formatErrorMessage() });
        }
      }),
    );

    if (errors.length === 0) {
      values.onSubmit?.();
    } else {
      throw new FormError<FileSelectionFormValues>(errors);
    }
  };

  if (!data.project) {
    throw new Error('Project not found');
  }

  return <BaseUploadFile project={data.project} onSubmit={handleSubmit} />;
};
