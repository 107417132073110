import { ChatStackItem, ChatStackItemProps } from '@allganize/alli-chat-base';
import { css } from '@emotion/react';
import { FunctionComponent, useMemo } from 'react';
import {
  FaqCarouselForm,
  FaqCarouselFormProps,
  FaqCarouselOption,
} from '../faq-carousel-form';
import { FragmentOf, gql, readFragment } from '../gql';

const avatarWidth = 32;
const gap = 6;

export const FaqCarousel_ChatFragment = gql(`
  fragment FaqCarousel_ChatFragment on Chat {
    ...on BotChat {
      __typename
      id

      faqCarousel {
        question
        answer
        reusable
        optionBoxTemplate
      }
    }
  }
`);

export interface FaqCarouselProps
  extends Pick<
      FaqCarouselFormProps,
      'disabled' | 'isOptionDisabled' | 'onSubmit'
    >,
    Pick<ChatStackItemProps, 'className'> {
  align?: 'left' | 'right';
  avatar?: React.ReactNode;
  chat: FragmentOf<typeof FaqCarousel_ChatFragment>;
}

export const FaqCarousel: FunctionComponent<FaqCarouselProps> = props => {
  const {
    align = 'right',
    avatar: avatarProp,
    chat: chatProp,
    className,
    disabled,
    isOptionDisabled,
    onSubmit,
  } = props;
  const chat = readFragment(FaqCarousel_ChatFragment, chatProp);
  const options = useMemo(() => {
    if (chat.__typename !== 'BotChat') {
      return [];
    }

    if (!chat.faqCarousel) {
      return [];
    }

    return chat.faqCarousel.reduce<FaqCarouselOption[]>((acc, curr, i) => {
      if (!curr) {
        return acc;
      }

      return [
        ...acc,
        {
          optionBoxTemplate: curr.optionBoxTemplate,
          reusable: curr.reusable ?? false,
          value: i,
          question: curr.question,
          answer: curr.answer,
        },
      ];
    }, []);
  }, [chat]);

  if (chat.__typename !== 'BotChat') {
    return null;
  }

  if (!chat.faqCarousel?.length) {
    return null;
  }

  const avatar = avatarProp && (
    <div
      css={css`
        flex-shrink: 0;
        width: ${avatarWidth}px;
        padding: 6px 0;
      `}
    >
      {avatarProp}
    </div>
  );

  return (
    <ChatStackItem className={className}>
      <div
        css={[
          css`
            display: flex;
            align-items: flex-start;
            gap: ${gap}px;
          `,
          align === 'right' &&
            css`
              justify-content: flex-end;
            `,
        ]}
      >
        {align === 'left' && avatar}

        <div
          css={[
            css`
              max-width: 100%;
            `,
            avatarProp &&
              css`
                max-width: calc(100% - ${avatarWidth + gap}px);
              `,
          ]}
        >
          <FaqCarouselForm
            align={align}
            disabled={disabled}
            isOptionDisabled={isOptionDisabled}
            onSubmit={onSubmit}
            options={options}
          />
        </div>

        {align === 'right' && avatar}
      </div>
    </ChatStackItem>
  );
};
