import { useQuery } from '@apollo/client/react';
import { FragmentOf, gql, readFragment } from '../gql';
import { AgentSelectOption } from './use-contact-agent-form';

export const ContactAgentForm_AgentFragment = gql(`
  fragment ContactAgentForm_AgentFragment on Agent {
    id
    firstName
    lastName
    displayName

    avatar {
      id
      url
    }
  }
`);

const ContactAgentForm_AgentsQuery = gql(
  `
  query ContactAgentForm_AgentsQuery {
    agents {
      ...ContactAgentForm_AgentFragment
    }
  }
`,
  [ContactAgentForm_AgentFragment],
);

export const getContactAgentFormAgentSelectOption = (
  fragment: FragmentOf<typeof ContactAgentForm_AgentFragment>,
): AgentSelectOption => {
  const agent = readFragment(ContactAgentForm_AgentFragment, fragment);

  return {
    label: agent.displayName || agent.firstName || agent.lastName || '',
    value: agent.id,
    avatar: agent.avatar?.url,
  };
};

export const useContactAgentFormAgent = () => {
  const { data, loading } = useQuery(ContactAgentForm_AgentsQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const options = data?.agents.reduce<AgentSelectOption[]>((acc, agent) => {
    if (!agent) {
      return acc;
    }

    return [...acc, getContactAgentFormAgentSelectOption(agent)];
  }, []);

  return { isLoading: loading, options };
};
