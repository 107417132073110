import { DraftInputBaseRef } from '@allganize/draft-input';
import { forwardRef } from 'react';
import { RichText } from '../rich-text';
import { ChatFileProps } from './chat-file.types';
import { useChatFile } from './use-chat-file';

interface ChatFileType {
  <T extends { url: string }>(
    props: React.PropsWithoutRef<ChatFileProps<T>> &
      React.RefAttributes<DraftInputBaseRef>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

export const ChatFile: ChatFileType = forwardRef((props, ref) => {
  const { file, ...other } = props;
  const { value, plugins } = useChatFile({ file });

  return <RichText {...other} ref={ref} plugins={plugins} value={value} />;
});
