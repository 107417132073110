import {
  ParseAccessAgent_AgentFragment,
  ParseAccessUserFilter_AccessUserFilterFragment,
} from '@allganize/access-control';
import { QueryHookOptions, useQuery } from '@apollo/client/react';
import { useMemo } from 'react';
import {
  FragmentOf,
  gql,
  maskFragments,
  readFragment,
  ResultOf,
  VariablesOf,
} from '../gql';

export const KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment = gql(
  `
  fragment KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    viewAccessToUser
    viewAccessToAgent

    accessUserFilters {
      ...ParseAccessUserFilter_AccessUserFilterFragment
    }

    accessAgents {
      accessType
      isAdmin

      agent {
        ...ParseAccessAgent_AgentFragment
      }
    }
  }
`,
  [
    ParseAccessUserFilter_AccessUserFilterFragment,
    ParseAccessAgent_AgentFragment,
  ],
);

const KnowledgeBaseAccessControlNodeQuery = gql(
  `
  query KnowledgeBaseAccessControlNodeQuery(
    $where: KnowledgeBaseNodeWhereUniqueInput!
  ) {
    knowledgeBaseNode(
      where: $where
    ) {
      id
      ...KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment
    }
  }
`,
  [KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment],
);

export const UseRootAccessControl_ProjectFragment = gql(
  `
  fragment UseRootAccessControl_ProjectFragment on Project {
    id
    # TODO: get only admin group
    permissionGroups @skip(if: $isUserQuery) {
      isAdmin
      agents {
        isMe
        ...ParseAccessAgent_AgentFragment
      }
    }
  }
`,
  [ParseAccessAgent_AgentFragment],
);

const RootAccessControlQuery = gql(
  `
  query RootAccessControlQuery(
    $where: ProjectWhereUniqueInput!
    $isUserQuery: Boolean = false
  ) {
    project(
      where: $where
    ) {
      ...UseRootAccessControl_ProjectFragment
    }
  }
`,
  [UseRootAccessControl_ProjectFragment],
);

type AccessAgentFragment = NonNullable<
  ResultOf<
    typeof KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment
  >['accessAgents']
>[number];

interface UseRootAccessControlOptions {
  project?: FragmentOf<typeof UseRootAccessControl_ProjectFragment> | null;
}

export const useRootAccessControl = ({
  project: projectOption,
}: UseRootAccessControlOptions) => {
  const project = readFragment(
    UseRootAccessControl_ProjectFragment,
    projectOption,
  );

  return useMemo(() => {
    if (!project?.permissionGroups) return;
    const adminGroup = project.permissionGroups.find(w => w?.isAdmin);
    let me: AccessAgentFragment | null = null;
    for (const group of project.permissionGroups) {
      if (!group?.agents) continue;
      const meData = group.agents.find(agent => {
        return agent?.isMe;
      });
      if (meData) {
        me = {
          accessType: gql.scalar('ObjectAccessType', 'EDITOR'),
          isAdmin: group.isAdmin,
          agent: meData,
        };
        break;
      }
    }
    return maskFragments(
      [KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment],
      {
        __typename: 'KnowledgeBaseNodeFolder',
        id: '',
        viewAccessToUser: true,
        viewAccessToAgent: true,
        accessUserFilters: [],
        accessAgents: [
          ...(me ? [me] : []),
          ...(adminGroup?.agents?.reduce<AccessAgentFragment[]>(
            (acc, agent) => {
              if (!agent) return acc;
              if (agent.isMe) return acc;

              acc.push({
                accessType: gql.scalar('ObjectAccessType', 'EDITOR'),
                isAdmin: true,
                agent,
              });
              return acc;
            },
            [],
          ) || []),
        ],
      },
    );
  }, [project?.permissionGroups]);
};

export const useRootAccessControlQuery = (
  options: QueryHookOptions<
    ResultOf<typeof RootAccessControlQuery>,
    VariablesOf<typeof RootAccessControlQuery>
  >,
) => {
  return useQuery(RootAccessControlQuery, {
    fetchPolicy: 'cache-first',
    ...options,
  });
};

export type UseAccessControlReturnType = FragmentOf<
  typeof KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment
>;

interface UseAccessControlOptions {
  projectId: string | number;
  /**
   * null for root folder
   */
  nodeId: string | number | null;
}

export const useAccessControl = ({
  projectId,
  nodeId,
}: UseAccessControlOptions): UseAccessControlReturnType | undefined => {
  const { data: nodeData } = useQuery(KnowledgeBaseAccessControlNodeQuery, {
    variables: {
      where: { id: nodeId || '', project: { id: projectId } },
    },
    skip: nodeId === null,
  });

  return nodeData?.knowledgeBaseNode || undefined;
};

export const useAccessControlValue = (
  fragment?: UseAccessControlReturnType,
) => {
  return readFragment(
    KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment,
    fragment,
  );
};
