import {
  AccessControlSelectAgentOption,
  AccessControlSelectUserOption,
} from '@allganize/access-control';
import {
  AccessUserFilterEnum,
  ObjectAccessType,
} from '@allganize/data-access-alli-enums';
import { KnowledgeBaseHashtagSelectOption } from '@allganize/hashtag-select';
import { FormError, FormFieldError } from '@allganize/utils-form';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import { filesize } from 'filesize';
import { useEffect, useMemo } from 'react';
import { FieldPath, useForm, UseFormReturn } from 'react-hook-form';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';

const filesAcceptBase = {
  'text/plain': ['.txt'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.ms-excel.sheet.macroenabled.12': ['.xlsm'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'application/x-hwp': ['.hwp'],
  'application/vnd.hancom.hwp': ['.hwp'],
  'application/haansofthwp': ['.hwp'],
  'application/vnd.hancom.hwpx': ['.hwpx'],
  'application/haansofthwpx': ['.hwpx'],
};

const filesAcceptZipSupport = {
  'application/zip': ['.zip'],
};

const filesAcceptPdfSupport = {
  'application/pdf': ['.pdf'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/msword': ['.doc'],
};

export interface FileInput {
  id: string;
  file: File;
  name: string;
  hashtags: KnowledgeBaseHashtagSelectOption[];
  accessControl: {
    isOverwriteFolderAccess: boolean;
    accessAgents: AccessControlSelectAgentOption[];
    viewAccessToAgent: boolean;
    accessUserFilters: AccessControlSelectUserOption[];
    viewAccessToUser: boolean;
  };
  isOverwriteFolderAccess?: boolean;
  submitStatus: 'init' | 'isSubmitting' | 'success' | 'error';
}

export interface FileSelectionFormValues {
  files: FileInput[];
  imagePdf: boolean;
  selectedFileId: string | null;
}

const defaultFileSelectionFormValues: FileSelectionFormValues = {
  files: [],
  imagePdf: false,
  selectedFileId: null,
};

type FileSelectionFormErrorField = FieldPath<FileSelectionFormValues> | 'root';
const fileSelectionFormErrorFields: FileSelectionFormErrorField[] = [
  'files',
  'imagePdf',
  'root',
];

const isFileSelectionFormErrorField = (
  field: any,
): field is FileSelectionFormErrorField =>
  fileSelectionFormErrorFields.includes(field);

const errorMessages = defineMessages({
  'file-required': {
    id: 'Errors.file-required',
    defaultMessage: 'File is a required field',
    description: 'File required error message',
  },
  'file-maxSize': {
    id: 'Errors.file-max-size',
    defaultMessage: 'File too large (Max. {maxSize})',
    description: 'File max size error message',
  },
});

interface ValidationSchemaOptions {
  files: {
    maxSize?: number;
    maxZipSize?: number;
  };
  intl: IntlShape;
}

const createValidationSchema = ({ files, intl }: ValidationSchemaOptions) => {
  return z.object({
    files: z
      .array(
        z.object({
          id: z.string(),
          file: z
            .instanceof(File)
            .refine(value => {
              return !!value;
            }, intl.formatMessage(errorMessages['file-required']))
            .refine(
              value => {
                if (!value) {
                  return true;
                }

                if (value.type === 'application/zip') {
                  return true;
                }

                if (typeof files.maxSize === 'undefined') {
                  return true;
                }

                return value.size <= files.maxSize;
              },
              intl.formatMessage(errorMessages['file-maxSize'], {
                maxSize: filesize(files.maxSize ?? 0, {
                  output: 'string',
                  locale: intl.locale,
                  localeOptions: intl.formats.date,
                }),
              }),
            )
            .refine(
              value => {
                if (!value) {
                  return true;
                }

                if (value.type !== 'application/zip') {
                  return true;
                }

                if (typeof files.maxZipSize === 'undefined') {
                  return true;
                }

                return value.size <= files.maxZipSize;
              },
              intl.formatMessage(errorMessages['file-maxSize'], {
                maxSize: filesize(files.maxZipSize ?? 0, {
                  output: 'string',
                  locale: intl.locale,
                  localeOptions: intl.formats.date,
                }),
              }),
            ),
          name: z
            .string({
              required_error: intl.formatMessage(
                errorMessages['file-required'],
              ),
              invalid_type_error: intl.formatMessage(
                errorMessages['file-required'],
              ),
            })
            .min(1, intl.formatMessage(errorMessages['file-required'])),
          hashtags: z.array(
            z.object({
              label: z.string(),
              value: z.string(),
            }),
          ),
          accessControl: z.object({
            isOverwriteFolderAccess: z.boolean(),
            accessAgents: z.array(
              z
                .object({
                  accessType: z.nativeEnum(ObjectAccessType),
                  value: z.union([z.string(), z.number()]),
                  label: z.string().nullable(),
                  description: z.string().nullable(),
                })
                .passthrough(),
            ),
            viewAccessToAgent: z.boolean(),
            accessUserFilters: z.array(
              z
                .object({
                  type: z.nativeEnum(AccessUserFilterEnum),
                  value: z.union([z.string(), z.number()]),
                  label: z.string().nullable(),
                  description: z.string().nullable(),
                })
                .passthrough(),
            ),
            viewAccessToUser: z.boolean(),
          }),
          submitStatus: z.enum(['init', 'isSubmitting', 'success', 'error']),
        }),
      )
      .min(1, intl.formatMessage(errorMessages['file-required'])),
    imagePdf: z.boolean(),
    selectedFileId: z.string().nullable(),
  }) satisfies z.ZodSchema<FileSelectionFormValues>;
};

export interface UseFileSelectionFormOptions {
  accept?: ('pdf' | 'zip')[];
  defaultValues?: FileSelectionFormValues;
  onSubmit(
    values: FileSelectionFormValues,
    form: UseFormReturn<FileSelectionFormValues>,
  ): Promise<void>;
  validation: Omit<ValidationSchemaOptions, 'intl'>;
}

export const useFileSelectionForm = ({
  accept: acceptOption = ['zip'],
  defaultValues = defaultFileSelectionFormValues,
  onSubmit,
  validation,
}: UseFileSelectionFormOptions) => {
  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();
  const validationSchema = useMemo(
    () => createValidationSchema({ ...validation, intl }),
    [intl, validation],
  );
  const accept = useMemo(() => {
    return {
      ...filesAcceptBase,
      ...(acceptOption.includes('zip') ? filesAcceptZipSupport : {}),
      ...(acceptOption.includes('pdf') ? filesAcceptPdfSupport : {}),
    };
  }, [acceptOption]);

  const form = useForm<FileSelectionFormValues>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const { reset, setError } = form;

  const submit = async (values: FileSelectionFormValues) => {
    try {
      await onSubmit(values, form);
      reset();
    } catch (err) {
      if (err instanceof FormError) {
        err.fields.forEach((field: FormFieldError<FileSelectionFormValues>) => {
          setError(field.name, field, field.options);
        });

        return;
      }

      if (err instanceof AlliGraphQLError) {
        const errorGroups = err.groupErrorsByField({
          intl,
          isErrorField: isFileSelectionFormErrorField,
        });

        // Set the errors in the form using setError
        Object.entries(errorGroups).forEach(([field, errors]) => {
          if (!errors) {
            return;
          }

          setError(field as FileSelectionFormErrorField, {
            message: errors[0]?.message,
          });
        });

        return;
      }

      if (err instanceof Error) {
        setError('root', {
          message: formatErrorMessage(err),
        });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: formatErrorMessage(),
      });
    }
  };

  // Reset the form when the default values change
  useEffect(() => {
    reset(defaultValues, {
      keepIsSubmitSuccessful: true,
      keepIsSubmitted: true,
      keepSubmitCount: true,
    });
  }, [defaultValues, reset]);

  return {
    files: {
      accept,
    },
    form,
    submit,
  };
};
