import { useEventCallback } from '@allganize/hooks';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import {
  CompositeDecorator,
  ContentBlock,
  DefaultDraftBlockRenderMap,
  DraftBlockRenderMap,
  DraftDecorator,
  DraftDragType,
  DraftHandleValue,
  DraftInlineStyle,
  DraftStyleMap,
  Editor,
  EditorCommand,
  EditorProps,
  EditorState,
  SelectionState,
  getDefaultKeyBinding,
} from 'draft-js';
import { camelCase } from 'lodash-es';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { DraftPluginFunctions } from '../draft-plugin';
import { draftInputBaseClasses } from './draft-input-base-classes';
import {
  DraftInputBaseProps,
  DraftInputBaseRef,
} from './draft-input-base-type-map';

export const DraftInputBase = forwardRef<
  DraftInputBaseRef,
  DraftInputBaseProps
>((props, ref) => {
  const {
    blockRenderMap: blockRenderMapProp,
    blockStyleFn: blockStyleFnProp,
    children,
    classes,
    className,
    customStyleFn: customStyleFnProp,
    customStyleMap: customStyleMapProp,
    disabled = false,
    formatPastedText: formatPastedTextProp,
    handleBeforeInput: handleBeforeInputProp,
    handleDrop: handleDropProp,
    handleDroppedFiles: handleDroppedFilesProp,
    handleKeyCommand: handleKeyCommandProp,
    handlePastedFiles: handlePastedFilesProp,
    handlePastedText: handlePastedTextProp,
    handleReturn: handleReturnProp,
    keyBindingFn: keyBindingFnProp,
    onBlur,
    onCopy,
    onFocus,
    plugins,
    value,
    rootProps,
    onChange,
    ...other
  } = props;
  const theme = useTheme();
  const editorRef = useRef<Editor>(null);

  const setEditorState = useEventCallback((editorState: EditorState) => {
    onChange(
      (plugins ?? []).reduce((acc, curr) => {
        return curr.onChange(acc);
      }, editorState),
    );
  });

  const blur = useEventCallback(() => {
    editorRef.current?.blur();
  });

  const focus = useEventCallback(() => {
    if (disabled) {
      return;
    }

    editorRef.current?.focus();
  });

  const getPluginFunctions = useEventCallback(
    (): DraftPluginFunctions => ({
      blur,
      focus,
      getEditorState: () => value,
      setEditorState,
      getDisabled: () => disabled,
    }),
  );

  const decorators = useMemo(
    () =>
      plugins?.reduce<DraftDecorator[]>((acc, curr) => {
        const decorators = curr.getDecorators();

        if (decorators && decorators.length > 0) {
          return [...acc, ...decorators];
        }

        return acc;
      }, []),
    [plugins],
  );

  const decorator = useMemo(
    () =>
      decorators && decorators.length > 0
        ? new CompositeDecorator(decorators)
        : null,
    [decorators],
  );

  const accessibilityProps = useMemo(() => {
    return plugins?.reduce<
      Pick<
        EditorProps,
        | 'role'
        | 'ariaAutoComplete'
        | 'ariaExpanded'
        | 'ariaOwneeID'
        | 'ariaActiveDescendantID'
      >
    >((acc, plugin) => {
      const props = plugin.getAccessibilityProps();

      return {
        ...acc,
        ...props,
      };
    }, {});
  }, [plugins]);

  useImperativeHandle(
    ref,
    () => ({
      editor: editorRef.current?.editor,
      editorContainer: editorRef.current?.editorContainer,
      focus,
      blur,
    }),
    [blur, focus],
  );

  useEffect(() => {
    if (value.getDecorator() !== decorator) {
      setEditorState(EditorState.set(value, { decorator }));
    }
  }, [decorator, setEditorState, value]);

  const blockRendererFn = (block: ContentBlock) => {
    return plugins?.reduce(
      (acc, plugin) =>
        acc || plugin.blockRendererFn(block, getPluginFunctions()),
      null,
    );
  };

  const blockRenderMap = useMemo<DraftBlockRenderMap>(() => {
    const withPlugins = (plugins ?? []).reduce((acc, plugin) => {
      return acc.merge(plugin.getBlockRenderMap(getPluginFunctions()));
    }, DefaultDraftBlockRenderMap);

    if (blockRenderMapProp) {
      return withPlugins.merge(blockRenderMapProp);
    }

    return withPlugins;
  }, [blockRenderMapProp, getPluginFunctions, plugins]);

  const blockStyleFn = (block: ContentBlock) => {
    const blockType = block.getType();

    return clsx(
      draftInputBaseClasses.block,
      draftInputBaseClasses[
        camelCase(`block-${blockType}`) as keyof typeof draftInputBaseClasses
      ],
      classes?.block,
      classes?.[
        camelCase(`block-${blockType}`) as keyof typeof draftInputBaseClasses
      ],
      plugins?.map(plugin => plugin.blockStyleFn(block, getPluginFunctions())),
      blockStyleFnProp?.(block),
    );
  };

  const customStyleFn = (
    style: DraftInlineStyle,
    block: ContentBlock,
  ): React.CSSProperties => {
    const pluginStyles = plugins?.reduce<React.CSSProperties>((acc, plugin) => {
      return {
        ...acc,
        ...plugin.customStyleFn(style, block, getPluginFunctions()),
      };
    }, {});

    return {
      ...pluginStyles,
      ...customStyleFnProp?.(style, block),
    };
  };

  const customStyleMap = useMemo<DraftStyleMap>(() => {
    return {
      ...plugins?.reduce<DraftStyleMap>(
        (acc, plugin) => ({
          ...acc,
          ...plugin.getCustomStyleMap(getPluginFunctions()),
        }),
        {},
      ),
      ...customStyleMapProp,
    };
  }, [customStyleMapProp, getPluginFunctions, plugins]);

  const keyBindingFn = (e: React.KeyboardEvent): EditorCommand | null => {
    const pluginResult = (plugins ?? []).reduce<EditorCommand | null>(
      (acc, plugin) => {
        const result = plugin.keyBindingFn(e, getPluginFunctions());

        if (result !== null) {
          return result;
        }

        return acc;
      },
      null,
    );

    if (pluginResult) {
      return pluginResult;
    }

    const propResult = keyBindingFnProp?.(e);

    if (propResult) {
      return propResult;
    }

    const defaultResult = getDefaultKeyBinding(e);

    if (defaultResult) {
      return defaultResult;
    }

    return null;
  };

  const handleKeyCommand = (
    command: EditorCommand,
    editorState: EditorState,
    eventTimeStamp: number,
  ): DraftHandleValue => {
    const pluginResult = (plugins ?? []).reduce<DraftHandleValue>(
      (acc, plugin) => {
        const result = plugin.handleKeyCommand(
          command,
          editorState,
          eventTimeStamp,
          getPluginFunctions(),
        );

        if (result !== 'not-handled') {
          return result;
        }

        return acc;
      },
      'not-handled',
    );

    if (pluginResult !== 'not-handled') {
      return pluginResult;
    }

    const propResult = handleKeyCommandProp?.(
      command,
      editorState,
      eventTimeStamp,
    );

    if (propResult && propResult !== 'not-handled') {
      return propResult;
    }

    return 'not-handled';
  };

  const handleReturn = (
    e: React.KeyboardEvent,
    editorState: EditorState,
  ): DraftHandleValue => {
    const pluginResult = (plugins ?? []).reduce<DraftHandleValue>(
      (acc, plugin) => {
        const result = plugin.handleReturn(
          e,
          editorState,
          getPluginFunctions(),
        );

        if (result !== 'not-handled') {
          return result;
        }

        return acc;
      },
      'not-handled',
    );

    if (pluginResult !== 'not-handled') {
      return pluginResult;
    }

    const propResult = handleReturnProp?.(e, editorState);

    if (propResult && propResult !== 'not-handled') {
      return propResult;
    }

    return 'not-handled';
  };

  const handleDrop = (
    selection: SelectionState,
    // eslint-disable-next-line @typescript-eslint/no-wrapper-object-types
    dataTransfer: Object,
    isInternal: DraftDragType,
  ): DraftHandleValue => {
    const pluginResult = (plugins ?? []).reduce<DraftHandleValue>(
      (acc, plugin) => {
        const result = plugin.handleDrop(
          selection,
          dataTransfer,
          isInternal,
          getPluginFunctions(),
        );

        if (result !== 'not-handled') {
          return result;
        }

        return acc;
      },
      'not-handled',
    );

    if (pluginResult !== 'not-handled') {
      return pluginResult;
    }

    const propResult = handleDropProp?.(selection, dataTransfer, isInternal);

    if (propResult && propResult !== 'not-handled') {
      return propResult;
    }

    return 'not-handled';
  };

  const handleDroppedFiles = (
    selection: SelectionState,
    files: Blob[],
  ): DraftHandleValue => {
    const pluginResult = (plugins ?? []).reduce<DraftHandleValue>(
      (acc, plugin) => {
        const result = plugin.handleDroppedFiles(
          selection,
          files,
          getPluginFunctions(),
        );

        if (result !== 'not-handled') {
          return result;
        }

        return acc;
      },
      'not-handled',
    );

    if (pluginResult !== 'not-handled') {
      return pluginResult;
    }

    const propResult = handleDroppedFilesProp?.(selection, files);

    if (propResult && propResult !== 'not-handled') {
      return propResult;
    }

    return 'not-handled';
  };

  const handlePastedFiles = (files: Blob[]): DraftHandleValue => {
    const pluginResult = (plugins ?? []).reduce<DraftHandleValue>(
      (acc, plugin) => {
        const result = plugin.handlePastedFiles(files, getPluginFunctions());

        if (result !== 'not-handled') {
          return result;
        }

        return acc;
      },
      'not-handled',
    );

    if (pluginResult !== 'not-handled') {
      return pluginResult;
    }

    const propResult = handlePastedFilesProp?.(files);

    if (propResult && propResult !== 'not-handled') {
      return propResult;
    }

    return 'not-handled';
  };

  const handlePastedText = (
    text: string,
    html: string | undefined,
    editorState: EditorState,
  ): DraftHandleValue => {
    const pluginResult = (plugins ?? []).reduce<DraftHandleValue>(
      (acc, plugin) => {
        const result = plugin.handlePastedText(
          text,
          html,
          editorState,
          getPluginFunctions(),
        );

        if (result !== 'not-handled') {
          return result;
        }

        return acc;
      },
      'not-handled',
    );

    if (pluginResult !== 'not-handled') {
      return pluginResult;
    }

    const propResult = handlePastedTextProp?.(text, html, editorState);

    if (propResult && propResult !== 'not-handled') {
      return propResult;
    }

    return 'not-handled';
  };

  const formatPastedText = (
    text: string,
    html?: string,
  ): { text: string; html: string | undefined } => {
    const pluginResult = (plugins ?? []).reduce<{
      text: string;
      html: string | undefined;
    }>(
      (acc, plugin) => {
        return plugin.formatPastedText(acc);
      },
      { text, html },
    );

    return (
      formatPastedTextProp?.(pluginResult.text, pluginResult.html) ??
      pluginResult
    );
  };

  const handleBeforeInput = (
    chars: string,
    editorState: EditorState,
    eventTimeStamp: number,
  ): DraftHandleValue => {
    const pluginResult = (plugins ?? []).reduce<DraftHandleValue>(
      (acc, plugin) => {
        const result = plugin.handleBeforeInput(
          chars,
          editorState,
          eventTimeStamp,
          getPluginFunctions(),
        );

        if (result !== 'not-handled') {
          return result;
        }

        return acc;
      },
      'not-handled',
    );

    if (pluginResult !== 'not-handled') {
      return pluginResult;
    }

    const propResult = handleBeforeInputProp?.(
      chars,
      editorState,
      eventTimeStamp,
    );

    if (propResult && propResult !== 'not-handled') {
      return propResult;
    }

    return 'not-handled';
  };

  const handleBlur = (ev: React.SyntheticEvent) => {
    plugins?.forEach(plugin => {
      plugin.onBlur(ev, getPluginFunctions());
    });

    onBlur?.(ev);
  };

  const handleFocus = (ev: React.SyntheticEvent) => {
    plugins?.forEach(plugin => {
      plugin.onFocus(ev, getPluginFunctions());
    });

    onFocus?.(ev);
  };

  const handleCopy = (editor: Editor, e: React.ClipboardEvent) => {
    plugins?.forEach(plugin => {
      plugin.onCopy(editor, e, getPluginFunctions());
    });

    onCopy?.(editor, e);
  };

  return (
    <div
      data-testid="draft-input-base"
      css={css`
        position: relative;

        figure {
          margin: 0;
        }
      `}
      {...rootProps}
      className={clsx(
        draftInputBaseClasses.root,
        {
          [draftInputBaseClasses.disabled]: disabled,
        },
        classes?.root,
        {
          [classes?.disabled ?? '']: disabled,
        },
        className,
        rootProps?.className,
      )}
    >
      <Editor
        webDriverTestID="draft-input-base__content"
        spellCheck
        preserveSelectionOnBlur
        blockRendererFn={blockRendererFn}
        blockRenderMap={blockRenderMap}
        blockStyleFn={blockStyleFn}
        onChange={setEditorState}
        readOnly={disabled}
        editorState={value}
        textDirectionality={theme.direction === 'rtl' ? 'RTL' : 'LTR'}
        customStyleFn={customStyleFn}
        formatPastedText={formatPastedText}
        keyBindingFn={keyBindingFn}
        handleBeforeInput={handleBeforeInput}
        handleDrop={handleDrop}
        handleDroppedFiles={handleDroppedFiles}
        handleKeyCommand={handleKeyCommand}
        handlePastedFiles={handlePastedFiles}
        handlePastedText={handlePastedText}
        handleReturn={handleReturn}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onCopy={handleCopy}
        customStyleMap={customStyleMap}
        {...accessibilityProps}
        {...other}
        ref={editorRef}
      />

      {children}
    </div>
  );
});
