import { Carousel, carouselClasses } from '@allganize/ui-carousel';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { uaParser } from '../utils/ua-parser';
import { ChatCarouselContext } from './chat-carousel-context';
import { chatCarouselClasses } from './chat-carousel.classes';
import { ChatCarouselProps } from './chat-carousel.types';

const CAROUSEL_BUTTON_AREA_WIDTH = 82;

export const ChatCarousel = forwardRef<HTMLDivElement, ChatCarouselProps>(
  (props, ref) => {
    const {
      classes,
      hasCarouselHeightBug = uaParser.hasCarouselHeightBug,
      title,
      children,
      ...other
    } = props;
    const intl = useIntl();
    const contextValue = useMemo(
      () => ({ hasCarouselHeightBug }),
      [hasCarouselHeightBug],
    );

    return (
      <ChatCarouselContext.Provider value={contextValue}>
        <div
          css={css`
            position: relative;
          `}
          {...other}
          className={clsx(
            chatCarouselClasses.root,
            {
              [chatCarouselClasses.hasCarouselHeightBug]: hasCarouselHeightBug,
            },
            classes?.root,
            {
              [classes?.hasCarouselHeightBug ?? '']: hasCarouselHeightBug,
            },
            other.className,
          )}
          ref={ref}
        >
          {title && (
            <div
              css={css`
                position: absolute;
                top: 6px;
                left: 16px;
                width: calc(100% - 16px - ${CAROUSEL_BUTTON_AREA_WIDTH}px);
              `}
              className={clsx(chatCarouselClasses.title, classes?.title)}
            >
              {title}
            </div>
          )}
          <Carousel
            data-testid="alli-chat-carousel"
            css={
              hasCarouselHeightBug &&
              css`
                .${carouselClasses.slide} {
                  display: flex;
                  flex-direction: column;
                  align-items: stretch;
                  justify-content: flex-start;

                  > div {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    justify-content: flex-start;
                  }
                }
              `
            }
            variableWidth
            arrowProps={{
              next: {
                'aria-label': intl.formatMessage({
                  id: 'carousel.arrow.next.aria-label',
                  defaultMessage: 'Move next',
                  description: 'Next button aria label',
                }),
              },
              prev: {
                'aria-label': intl.formatMessage({
                  id: 'carousel.arrow.prev.aria-label',
                  defaultMessage: 'Move previous',
                  description: 'Previous button aria label',
                }),
              },
            }}
            className={clsx(chatCarouselClasses.carousel, classes?.carousel)}
          >
            {children}
          </Carousel>
        </div>
      </ChatCarouselContext.Provider>
    );
  },
);
