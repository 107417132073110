import {
  AlliChatRow,
  AlliChatRowItem,
  AlliChatRowItemProps,
  ChatStackItem,
  TimestampTooltip,
} from '@allganize/alli-chat-base';
import { ChatFile } from '@allganize/alli-chat-content';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

export const AlliChatFile_ChatFragment = gql(`
  fragment AlliChatFile_ChatFragment on Chat {
    ...on AgentChat {
      __typename
      createdAt

      file {
        id
        fileId
        metaInfo
        url
        createdAt
        modifiedAt
      }
    }
  }
`);

export interface AlliChatFileProps
  extends Pick<AlliChatRowItemProps, 'avatar'> {
  chat: FragmentOf<typeof AlliChatFile_ChatFragment>;
}

export const AlliChatFile: FunctionComponent<AlliChatFileProps> = props => {
  const { avatar, chat: chatProp } = props;
  const chat = readFragment(AlliChatFile_ChatFragment, chatProp);

  if (chat.__typename !== 'AgentChat') {
    return null;
  }

  if (!chat.file) {
    return null;
  }

  return (
    <ChatStackItem disablePadding>
      <AlliChatRow>
        <TimestampTooltip align="left" timestamp={chat.createdAt}>
          <AlliChatRowItem avatar={avatar}>
            <ChatFile file={chat.file} />
          </AlliChatRowItem>
        </TimestampTooltip>
      </AlliChatRow>
    </ChatStackItem>
  );
};
