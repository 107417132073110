import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { AppBar, Toolbar } from '@mui/material';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { headerHeight } from '../documents-layout/documents-layout.const';
import { DocumentsSearchForm } from '../documents-search-form/documents-search-form';
import { DocumentsHeaderTabs } from './documents-header-tabs';
import {
  documentsHeaderClasses,
  DocumentsHeaderClasses,
} from './documents-header.classes';

interface DocumentsHeaderProps {
  classes?: Partial<DocumentsHeaderClasses>;
  className?: string;
}

export const DocumentsHeader: FunctionComponent<
  DocumentsHeaderProps
> = props => {
  const { classes, className } = props;
  const theme = useTheme();

  return (
    <AppBar
      css={css`
        box-shadow: none;
        background-color: transparent;
        border-bottom: 1px solid ${theme.palette.border.divider.default};
      `}
      className={clsx(documentsHeaderClasses.root, classes?.root, className)}
      color="default"
      position="static"
    >
      <Toolbar
        css={css`
          padding-left: 24px;
          padding-right: 24px;
          min-height: ${headerHeight - 1}px;
          gap: 12px;

          ${theme.breakpoints.up('xs')} {
            padding-left: 24px;
            padding-right: 24px;
            min-height: ${headerHeight - 1}px;
          }
        `}
        className={clsx(documentsHeaderClasses.toolbar, classes?.toolbar)}
      >
        <Text variant="title20">
          <FormattedMessage
            id="documents"
            defaultMessage="Documents"
            description="documents"
          />
        </Text>

        <div
          css={css`
            flex-grow: 1;
          `}
        >
          <DocumentsHeaderTabs />
        </div>

        <DocumentsSearchForm
          css={css`
            flex-basis: 518px;
          `}
        />
      </Toolbar>
    </AppBar>
  );
};
