import {
  getKnowledgeBaseFilenameIconVariant,
  KnowledgeBaseFileIcon,
} from '@allganize/knowledge-base-icons';
import { Truncate } from '@allganize/truncate';
import {
  ButtonBase,
  ButtonBaseProps,
  IconButton,
  buttonBaseClasses,
} from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import {
  IcArrowNavigateNext,
  IcError,
  IcFolder,
  IcFolderOpen,
  IcPeople,
  IcPerson,
} from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef, memo } from 'react';
import { gql, Scalars } from '../gql';

export type Metadata = {
  type: Scalars<'KnowledgeBaseNodeType'>;
  ownership: Scalars<'KnowledgeBaseNodeOwnership'> | null;
  processState: Scalars<'ProcessStateEnum'> | null;
};

interface KnowledgeBaseNodeProps extends ButtonBaseProps<'div'> {
  expanded?: boolean;
  fullWidth?: boolean;
  leaf?: boolean;
  loading?: boolean;
  name: string;
  metadata?: Metadata;
  onExpand?: React.MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
  showOwnershipIcon?: boolean;
}

export const KnowledgeBaseNode = memo(
  forwardRef<HTMLDivElement, KnowledgeBaseNodeProps>((props, ref) => {
    const {
      expanded,
      fullWidth,
      leaf,
      loading,
      name,
      metadata,
      onExpand,
      selected,
      showOwnershipIcon,
      disabled,
      ...other
    } = props;
    const theme = useTheme();
    const isFolder =
      metadata?.type !== gql.scalar('KnowledgeBaseNodeType', 'FILE');

    const state =
      metadata?.processState === gql.scalar('ProcessStateEnum', 'INIT') ||
      metadata?.processState === gql.scalar('ProcessStateEnum', 'PARSING')
        ? 'loading'
        : metadata?.processState ===
            gql.scalar('ProcessStateEnum', 'RETRYING') ||
          metadata?.processState ===
            gql.scalar('ProcessStateEnum', 'PARSING_FAIL')
        ? 'error'
        : 'default';

    const renderIcon = () => {
      if (isFolder) {
        return expanded ? <IcFolderOpen /> : <IcFolder />;
      }

      if (state === 'loading') {
        return <CircularProgress size="sm" />;
      }

      if (state === 'error') {
        return <IcError />;
      }

      return (
        <KnowledgeBaseFileIcon
          variant={getKnowledgeBaseFilenameIconVariant(name) ?? 'fallback'}
        />
      );
    };

    const handleExpand = (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      onExpand?.(ev);
    };

    return (
      <ButtonBase
        css={[
          css`
            justify-content: flex-start;
            padding: 4px 0;
            height: 40px;
            border-radius: ${theme.radius.sm}px;
            color: ${theme.palette.foreground.default};
            transition: ${theme.transitions.create(
              ['color', 'background-color'],
              { duration: theme.transitions.duration.shortest },
            )};

            &:hover {
              color: ${theme.palette.foreground.default};
              background-color: ${theme.palette.backgroundInteractive
                .grayGhostAlpha.hover};
            }

            &:active {
              color: ${theme.palette.foreground.default};
              background-color: ${theme.palette.backgroundInteractive
                .grayGhostAlpha.pressed};
            }

            &.${buttonBaseClasses.focusVisible} {
              outline: ${theme.palette.border.focused} solid 2px;
              outline-offset: 2px;
            }

            &.${buttonBaseClasses.disabled} {
              color: ${theme.palette.foreground.gray.disabled};
            }
          `,
          selected &&
            css`
              color: ${theme.palette.foreground.primary.default};
              background-color: ${theme.palette.backgroundInteractive
                .primarySubtle.selected};
            `,
          fullWidth &&
            css`
              width: 100%;
            `,
        ]}
        component="div"
        disabled={disabled || state !== 'default'}
        {...other}
        ref={ref}
      >
        <span
          css={css`
            min-width: 8px;
            margin-right: 4px;
            flex-shrink: 0;
          `}
        >
          {!leaf && isFolder && !!onExpand && (
            <IconButton
              shape="rounded"
              disabled={loading}
              onClick={handleExpand}
            >
              {loading ? (
                <CircularProgress size="sm" />
              ) : (
                <IcArrowNavigateNext
                  css={[
                    css`
                      transition: ${theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest,
                      })};
                      transform: rotate(0deg);
                    `,
                    expanded &&
                      css`
                        transform: rotate(90deg);
                      `,
                  ]}
                />
              )}
            </IconButton>
          )}
        </span>

        <span
          css={css`
            min-width: 32px;
            display: inline-flex;
            align-items: center;
            flex-shrink: 0;
          `}
        >
          {renderIcon()}
        </span>

        <Truncate
          css={css`
            flex-grow: 1;
            word-break: break-all;
            user-select: none;
          `}
          clamp={1}
        >
          <Text variant="body14">{name}</Text>
        </Truncate>

        {showOwnershipIcon && (
          <span
            css={css`
              margin-right: 12px;
            `}
          >
            {metadata?.ownership ===
            gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL') ? (
              <IcPerson />
            ) : (
              <IcPeople />
            )}
          </span>
        )}
      </ButtonBase>
    );
  }),
);
