import { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

interface SecureRouteProps {
  children?: React.ReactNode;
}

export const SecureRoute: FunctionComponent<SecureRouteProps> = ({
  children,
}) => {
  const location = useLocation();
  const { isAuthenticated, isEnabled } = useAuth();

  if (isEnabled && !isAuthenticated) {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set(
      'next',
      [location.pathname, location.search, location.hash].join(''),
    );

    return (
      <Navigate
        to={{
          pathname: '/auth/sign-in',
          search: newSearchParams.toString(),
        }}
      />
    );
  }

  return children;
};
