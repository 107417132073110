import { DraftInputBaseRef } from '@allganize/draft-input';
import { forwardRef } from 'react';
import { RichText } from '../rich-text';
import { PlainTextProps } from './plain-text.types';
import { usePlainText } from './use-plain-text';

export const PlainText = forwardRef<DraftInputBaseRef, PlainTextProps>(
  (props, ref) => {
    const { value, plugins } = usePlainText(props);

    return <RichText {...props} ref={ref} plugins={plugins} value={value} />;
  },
);
