import { useEventCallback } from '@allganize/hooks';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';

import { analytics } from '../analytics';
import { ClosePreviewReason } from '../preview/preview-state';
import { PreviewContextProps } from '../preview/preview-context';
import {
  SingleActionPreviewState,
  SingleActionApp,
} from './single-action-preview-context';
import { AppPreviewMetadata } from '../hooks/use-tab-navigator';

interface UseSingleActionPreviewOptions
  extends Pick<
    PreviewContextProps,
    'previewSidebarAnchor' | 'shouldOpenPreviewInDialog'
  > {
  open?: boolean;
  openPreview: (metadata: AppPreviewMetadata) => void;
  closePreview?: () => void;
}

export const useSingleActionPreview = ({
  open = true,
  previewSidebarAnchor,
  shouldOpenPreviewInDialog,
  openPreview,
  closePreview,
}: UseSingleActionPreviewOptions) => {
  const location = useLocation();
  const [singleActionPreviewState, setSingleActionPreviewState] =
    useState<SingleActionPreviewState | null>(null);
  const [conversationIdToSendResult, setConversationIdToSendResult] =
    useState('');

  const openSingleActionPreview = useEventCallback(
    (singleAction: SingleActionApp) => {
      if (!singleAction?.id || !singleAction?.publicToken) {
        return;
      }
      openPreview({
        singleActionApp: {
          id: singleAction.id,
          publicToken: singleAction.publicToken,
        },
      });
      setSingleActionPreviewState({
        key: v4(),
        id: singleAction.id,
        publicToken: singleAction.publicToken,
      });
      analytics?.track('view_single_action_preview', {
        mode: shouldOpenPreviewInDialog ? 'dialog' : 'sidebar',
        previewSidebarAnchor,
        appId: singleAction.id,
      });
    },
  );

  const closeSingleActionPreview = useEventCallback(
    (reason: ClosePreviewReason) => {
      if (!singleActionPreviewState) {
        return;
      }
      closePreview?.();
      setSingleActionPreviewState(null);
      analytics?.track('close_single_action_preview', {
        mode: shouldOpenPreviewInDialog ? 'dialog' : 'sidebar',
        previewSidebarAnchor,
        appId: singleActionPreviewState.id,
        reason,
      });
    },
  );

  useEffect(() => {
    if (!open) {
      closeSingleActionPreview('navigation');
    }
  }, [open, closeSingleActionPreview]);

  useEffect(() => {
    closeSingleActionPreview('navigation');
  }, [location.pathname, closeSingleActionPreview]);

  return {
    singleActionPreviewState,
    conversationIdToSendResult,
    openSingleActionPreview,
    closeSingleActionPreview,
    setConversationIdToSendResult,
  };
};
