import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import MuiTabs from '@mui/material/Tabs';
import useSlotProps from '@mui/utils/useSlotProps';
import { forwardRef, useMemo } from 'react';
import { TabScrollButton } from '../tab-scroll-button';
import { TabsContext, TabsContextValue } from './tabs-context';
import { tabsClasses } from './tabs.classes';
import { TabsOwnerState, TabsTypeMap } from './tabs.types';

// @ts-expect-error overridable component
export const Tabs: OverridableComponent<TabsTypeMap> = forwardRef(
  (props, ref) => {
    const {
      allowScrollButtonsMobile = false,
      centered = false,
      classes,
      className,
      // @ts-expect-error internal prop
      component = 'div',
      fullWidth = false,
      onChange,
      orientation = 'horizontal',
      scrollButtons = 'auto',
      slots = {},
      slotProps = {},
      variant = 'underlined',
      visibleScrollbar = false,
      ...other
    } = props;
    const theme = useTheme();
    const muiTheme = useMuiTheme();
    const scrollable = !fullWidth;
    const vertical = orientation === 'vertical';

    const ownerState: TabsOwnerState = {
      ...props,
      component,
      allowScrollButtonsMobile,
      orientation,
      vertical,
      scrollButtons,
      variant,
      visibleScrollbar,
      fixed: !scrollable,
      hideScrollbar: scrollable && !visibleScrollbar,
      scrollableX: scrollable && !vertical,
      scrollableY: scrollable && vertical,
      centered: centered && !scrollable,
      scrollButtonsHideMobile: !allowScrollButtonsMobile,
    };

    const ScrollButtonComponent = slots.TabScrollButton ?? TabScrollButton;

    const TabScrollButtonProps = useSlotProps({
      elementType: ScrollButtonComponent,
      // @ts-expect-error internal prop
      externalSlotProps: slotProps.tabScrollButton,
      ownerState,
    });

    const startScrollButtonIconProps = useSlotProps({
      elementType: slots.StartScrollButtonIcon,
      externalSlotProps: slotProps.startScrollButtonIcon,
      ownerState,
    });

    const endScrollButtonIconProps = useSlotProps({
      elementType: slots.EndScrollButtonIcon,
      externalSlotProps: slotProps.endScrollButtonIcon,
      ownerState,
    });

    const contextValue = useMemo<TabsContextValue>(() => {
      return {
        fullWidth,
        onChange,
        variant,
      };
    }, [fullWidth, onChange, variant]);

    return (
      <TabsContext.Provider value={contextValue}>
        <ThemeProvider
          theme={{
            ...muiTheme,
            direction: theme.direction,
            transitions: theme.transitions,
          }}
        >
          <ClassNames>
            {({ css, cx }) => (
              <MuiTabs
                data-testid="tabs"
                allowScrollButtonsMobile={allowScrollButtonsMobile}
                centered={centered}
                component={component}
                indicatorColor="primary"
                onChange={onChange}
                orientation={orientation}
                scrollButtons={scrollButtons}
                ScrollButtonComponent={ScrollButtonComponent}
                slots={slots}
                slotProps={{
                  ...slotProps,
                  startScrollButtonIcon: startScrollButtonIconProps,
                  endScrollButtonIcon: endScrollButtonIconProps,
                }}
                TabScrollButtonProps={TabScrollButtonProps}
                textColor="primary"
                variant={fullWidth ? 'fullWidth' : 'scrollable'}
                visibleScrollbar={visibleScrollbar}
                {...other}
                ref={ref}
                classes={{
                  ...classes,
                  root: cx(
                    css`
                      min-height: 36px;
                    `,
                    classes?.root,
                  ),
                  scrollButtonsHideMobile: cx(
                    css`
                      .${tabsClasses.scrollButtonsHideMobile} {
                        ${theme.breakpoints.up('sm')} {
                          display: none;
                        }
                      }
                    `,
                    classes?.scrollButtonsHideMobile,
                  ),
                  scroller: cx(
                    variant === 'filled' &&
                      css`
                        z-index: 0;
                      `,
                    classes?.scroller,
                  ),
                  indicator: cx(
                    css`
                      position: absolute;
                      height: auto;
                      bottom: auto;
                      width: auto;
                      right: auto;
                      transition: ${theme.transitions.create('all')};
                    `,
                    variant === 'underlined' &&
                      css`
                        background-color: ${theme.palette.foregroundInteractive
                          .primary};
                      `,
                    variant === 'underlined' &&
                      !ownerState.vertical &&
                      css`
                        height: 2px;
                        bottom: 0;
                        width: 100%;
                      `,
                    variant === 'underlined' &&
                      ownerState.vertical &&
                      css`
                        height: 100%;
                        width: 2px;
                        right: 0;
                      `,
                    variant === 'filled' &&
                      css`
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        background-color: ${theme.palette.unstable_background
                          .primary.subtle};
                        border-radius: ${theme.radius.sm}px;
                        z-index: -1;
                      `,
                    classes?.indicator,
                  ),
                }}
                className={cx(
                  tabsClasses[variant],
                  classes?.[variant],
                  className,
                )}
              />
            )}
          </ClassNames>
        </ThemeProvider>
      </TabsContext.Provider>
    );
  },
);
