import { Truncate } from '@allganize/truncate';
import {
  ButtonBase,
  buttonBaseClasses,
  ExtendButtonBase,
} from '@allganize/ui-button';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { KNOWLEDGE_BASE_BREADCRUMB_ITEM_MAX_WIDTH } from './constants';
import { KnowledgeBaseBreadcrumbItemTypeMap } from './knowledge-base-breadcrumb-item.types';

// @ts-expect-error overridable component
export const KnowledgeBaseBreadcrumbItem: ExtendButtonBase<KnowledgeBaseBreadcrumbItemTypeMap> =
  forwardRef((props, ref) => {
    const { children, selected = false, ...other } = props;
    const theme = useTheme();

    return (
      <ButtonBase
        css={[
          css`
            transition: ${theme.transitions.create(['color'], {
              duration: theme.transitions.duration.short,
            })};
            color: ${theme.palette.foreground.secondary};
            word-break: break-all;
            max-width: ${KNOWLEDGE_BASE_BREADCRUMB_ITEM_MAX_WIDTH}px;
            width: 100%;
            ${theme.typography.subtitle14}

            &:hover {
              color: ${theme.palette.foreground.default};
            }

            &.${buttonBaseClasses.focusVisible} {
              outline: ${theme.palette.border.focused} solid 2px;
              outline-offset: 2px;
            }
          `,
          selected &&
            css`
              color: ${theme.palette.foreground.default};
            `,
        ]}
        {...other}
        ref={ref}
      >
        <Truncate component="span" clamp={1}>
          {children}
        </Truncate>
      </ButtonBase>
    );
  });
