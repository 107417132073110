import { useEventCallback, useUnmount } from '@allganize/hooks';
import { useEffect, useRef } from 'react';
import { confirm } from './confirm';
import { ConfirmOptions } from './confirm-states';

export const useConfirm = () => {
  const confirmKey = useRef<string | null>(null);

  const cancel = useEventCallback(() => {
    if (confirmKey.current === null) {
      return;
    }

    confirm.cancel(confirmKey.current);
  });

  const enqueue = useEventCallback((options: ConfirmOptions) => {
    const key = confirm({
      key: confirmKey.current ?? undefined,
      ...options,
    });

    confirmKey.current = key;
    return key;
  });

  useUnmount(() => {
    cancel();
  });

  useEffect(() => {
    const unsubscribe = confirm.subscribe(value => {
      const item = value.find(d => d.key === confirmKey.current);

      if (item) {
        return;
      }

      if (confirmKey.current === null) {
        return;
      }

      confirmKey.current = null;
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return { confirmKey, confirm: enqueue, cancel };
};
