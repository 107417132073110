import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcCheckCircle, IcError, IcWarning } from '@allganize/ui-icons';
import { FunctionComponent } from 'react';
import { gql, Scalars } from '../gql';

interface ProcessStateIconProps {
  className?: string;
  state: Scalars<'ProcessStateEnum'>;
}

export const ProcessStateIcon: FunctionComponent<
  ProcessStateIconProps
> = props => {
  const { className, state } = props;

  switch (state) {
    case gql.scalar('ProcessStateEnum', 'PARSING_FAIL'):
      return <IcError className={className} color="error" />;
    case gql.scalar('ProcessStateEnum', 'COMPLETED'):
      return <IcCheckCircle className={className} color="success" />;
    case gql.scalar('ProcessStateEnum', 'RETRYING'):
      return <IcWarning className={className} color="warning" />;
    default:
      return <CircularProgress className={className} size="sm" />;
  }
};
