import { useRunKnowledgeBaseAutoHashtag } from '@allganize/knowledge-base-actions';
import { LoadingButton } from '@allganize/ui-button';
import { Chip } from '@allganize/ui-chip';
import { IcAutoTagging } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';
import { PanelItem } from '../panel-item/panel-item';
import {
  AutoHashtag,
  AutoHashtag_KnowledgeBaseAutoHashtagFragment,
} from './auto-hashtag';

export const Hashtags_KnowledgeBaseNodeFragment = gql(
  `
  fragment Hashtags_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id

    ...on KnowledgeBaseNodeFile {
      hashtags

      autoHashtags {
        id
        state
        ...AutoHashtag_KnowledgeBaseAutoHashtagFragment
      }
    }
  }
`,
  [AutoHashtag_KnowledgeBaseAutoHashtagFragment],
);

export const Hashtags_ProjectFragment = gql(`
  fragment Hashtags_ProjectFragment on Project {
    id
    permissions @skip(if: $isUserQuery)
    autoHashtagOnOff
  }
`);

export interface HashtagsProps {
  node: FragmentOf<typeof Hashtags_KnowledgeBaseNodeFragment>;
  onHashtagClick?(hashtag: string): void;
  project: FragmentOf<typeof Hashtags_ProjectFragment>;
}

export const Hashtags: FunctionComponent<HashtagsProps> = props => {
  const { node: nodeProp, onHashtagClick, project: projectProp } = props;
  const node = readFragment(Hashtags_KnowledgeBaseNodeFragment, nodeProp);
  const project = readFragment(Hashtags_ProjectFragment, projectProp);
  const canViewAutoTagging = project.permissions?.includes(
    gql.scalar('AgentPermissionEnum', 'FAQ_AUTO_TAGGING_READ'),
  );
  const canEditAutoTagging = project.permissions?.includes(
    gql.scalar('AgentPermissionEnum', 'FAQ_AUTO_TAGGING_WRITE'),
  );
  const [runKnowledgeBaseAutoHashtag, { loading: running }] =
    useRunKnowledgeBaseAutoHashtag();

  const filteredAutoHashtags = useMemo(() => {
    if (node.__typename !== 'KnowledgeBaseNodeFile') {
      return [];
    }

    return (
      node.autoHashtags?.reduce<
        NonNullable<NonNullable<typeof node.autoHashtags>[number]>[]
      >((acc, autoHashtag) => {
        if (!autoHashtag) {
          return acc;
        }

        if (
          autoHashtag.state === gql.scalar('AutoHashtagState', 'DECLINED') ||
          autoHashtag.state === gql.scalar('AutoHashtagState', 'APPROVED')
          // don't show approved autohashtag as it's being shown
          // as part of orderedHashtag
        ) {
          return acc;
        }

        if (!canViewAutoTagging) {
          return acc;
        }

        return [...acc, autoHashtag];
      }, []) ?? []
    );
  }, [canViewAutoTagging, node]);

  const handleRunAutoHashtag = () => {
    runKnowledgeBaseAutoHashtag({
      variables: { ids: [node.id], where: { id: project.id } },
    });
  };

  if (node.__typename !== 'KnowledgeBaseNodeFile') {
    return null;
  }

  return (
    <PanelItem
      label={
        <FormattedMessage
          id="knowledge-base-side-panel.hashtags"
          defaultMessage="Hashtag"
          description="Knowledge base side panel hashtag label text"
        />
      }
      action={
        project.autoHashtagOnOff &&
        canEditAutoTagging && (
          <LoadingButton
            size="small"
            startIcon={<IcAutoTagging />}
            loading={running}
            onClick={handleRunAutoHashtag}
          >
            <FormattedMessage
              id="FAQ.Documents.KnowledgeBase.AutoHashtag"
              defaultMessage="Run auto-tagging <sup>β</sup>"
              description="FAQ documents knowledge base auto-tagging reload button text"
              values={{
                sup: (chunks: ReadonlyArray<React.ReactNode>) => (
                  <span
                    css={css`
                      font-size: 75%;
                      vertical-align: super;
                    `}
                  >
                    {chunks}
                  </span>
                ),
              }}
            />
          </LoadingButton>
        )
      }
    >
      {((node.hashtags && node.hashtags.length > 0) ||
        filteredAutoHashtags.length > 0) && (
        <div
          css={css`
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 4px;
          `}
        >
          {node.hashtags?.map(ht => {
            return (
              <Chip
                key={ht}
                label={`#${ht}`}
                clickable={!!onHashtagClick}
                onClick={
                  onHashtagClick ? () => onHashtagClick?.(ht) : undefined
                }
              />
            );
          })}

          {filteredAutoHashtags.map(autoHashtag => {
            return (
              <AutoHashtag
                key={autoHashtag.id}
                autoHashtag={autoHashtag}
                onClick={
                  onHashtagClick
                    ? ht => {
                        onHashtagClick?.(ht.autoHashtag.hashtag);
                      }
                    : undefined
                }
                projectId={project.id}
              />
            );
          })}
        </div>
      )}
    </PanelItem>
  );
};
