import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import useSlot from '@mui/material/utils/useSlot';
import { FunctionComponent } from 'react';
import {
  AccessControlList,
  AccessControlList_KnowledgeBaseNodeFragment,
  AccessControlListProps,
} from '../access-control-list/access-control-list';
import { FragmentOf, gql, readFragment } from '../gql';
import {
  Location,
  Location_KnowledgeBaseNodeFragment,
  LocationSlotProps,
  LocationSlots,
} from '../location/location';
import { PanelItemGroup } from '../panel-item/panel-item-group';
import {
  CreatedBy,
  CreatedBy_KnowledgeBaseNodeFragment,
} from '../panel-user/created-by';
import {
  UpdatedBy,
  UpdatedBy_KnowledgeBaseNodeFragment,
} from '../panel-user/updated-by';
import {
  SidePanelActions,
  SidePanelActions_KnowledgeBaseNodeFragment,
  SidePanelActionsProps,
} from '../side-panel-actions/side-panel-actions';

export const FolderSidePanel_KnowledgeBaseNodeFragment = gql(
  `
  fragment FolderSidePanel_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    ...CreatedBy_KnowledgeBaseNodeFragment
    ...UpdatedBy_KnowledgeBaseNodeFragment
    ...AccessControlList_KnowledgeBaseNodeFragment
    ...SidePanelActions_KnowledgeBaseNodeFragment
    ...Location_KnowledgeBaseNodeFragment

    createdBy {
      id
    }

    updatedBy {
      id
    }
  }
`,
  [
    CreatedBy_KnowledgeBaseNodeFragment,
    UpdatedBy_KnowledgeBaseNodeFragment,
    AccessControlList_KnowledgeBaseNodeFragment,
    SidePanelActions_KnowledgeBaseNodeFragment,
    Location_KnowledgeBaseNodeFragment,
  ],
);

export interface FolderSidePanelSlots extends LocationSlots {
  /**
   * @default AccessControlList
   */
  accessControlList?: React.ElementType;
  /**
   * @default SidePanelActions
   */
  actions?: React.ElementType;
}

export interface FolderSidePanelSlotProps extends LocationSlotProps {
  accessControlList: SlotProps<
    React.ElementType<AccessControlListProps>,
    {},
    {}
  >;
  actions: SlotProps<React.ElementType<SidePanelActionsProps>, {}, {}>;
}

export type FolderSidePanelSlotsAndSlotProps = CreateSlotsAndSlotProps<
  FolderSidePanelSlots,
  FolderSidePanelSlotProps
>;

export interface FolderSidePanelProps extends FolderSidePanelSlotsAndSlotProps {
  data: FragmentOf<typeof FolderSidePanel_KnowledgeBaseNodeFragment>;
}

export const FolderSidePanel: FunctionComponent<
  FolderSidePanelProps
> = props => {
  const { data: dataProp, slotProps = {}, slots = {} } = props;
  const data = readFragment(
    FolderSidePanel_KnowledgeBaseNodeFragment,
    dataProp,
  );

  const [AccessControlListSlot, accessControlListSlotProps] = useSlot(
    'accessControlList',
    {
      elementType: AccessControlList,
      // @ts-expect-error internal prop
      externalForwardedProps: { slots, slotProps },
      ownerState: {},
      className: undefined,
    },
  );
  const [ActionsSlot, actionsSlotProps] = useSlot('actions', {
    elementType: SidePanelActions,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });

  return (
    <>
      <AccessControlListSlot node={data} {...accessControlListSlotProps} />

      <PanelItemGroup>
        <CreatedBy node={data} />

        <UpdatedBy node={data} />

        <Location node={data} slotProps={slotProps} slots={slots} />
      </PanelItemGroup>

      <ActionsSlot node={data} {...actionsSlotProps} />
    </>
  );
};
