import { useProject } from '@allganize/alli-works-project';
import { Button } from '@allganize/ui-button';
import { IcAdd } from '@allganize/ui-icons';
import { List, ListItem, ListProps } from '@allganize/ui-list';
import { css } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { Link, useMatch } from 'react-router-dom';
import { analytics } from '../../analytics';

const menuListStyle = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MenuList = ({ children, ...others }: ListProps) => {
  const matchIndex = useMatch('/');
  const handleClickNewChat = () => {
    analytics.track('lnb::new-chat.click');
  };

  const project = useProject();
  const assistantUiEnabled = !project.hideAssistant;

  return (
    <List css={menuListStyle} disablePadding {...others}>
      {assistantUiEnabled && (
        <ListItem
          css={css`
            padding: 0 8px;
          `}
          disablePadding
        >
          <Button
            fullWidth
            shape="rounded"
            variant="outlined"
            startIcon={<IcAdd />}
            component={Link}
            to="/"
            disabled={!!matchIndex}
            onClick={handleClickNewChat}
          >
            <FormattedMessage
              id="AppMarket.LNB.MenuList.NewChat"
              defaultMessage="New chat"
              description="app market lnb new chat button text"
            />
          </Button>
        </ListItem>
      )}
      {children}
    </List>
  );
};
