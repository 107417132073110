import { gql, Scalars } from '../gql';
import { KnowledgeBaseFileIconVariant } from '../knowledge-base-file-icon';

interface KnowledgeBaseTypeIconVariantOptions {
  type: Scalars<'KnowledgeBaseType'>;
  fileName?: string;
}

export const getKnowledgeBaseTypeIconVariant = ({
  type,
  fileName,
}: KnowledgeBaseTypeIconVariantOptions): Exclude<
  KnowledgeBaseFileIconVariant,
  'fallback'
> | null => {
  switch (type) {
    case gql.scalar('KnowledgeBaseType', 'CSV'):
      return 'csv';
    case gql.scalar('KnowledgeBaseType', 'HWP'):
    case gql.scalar('KnowledgeBaseType', 'IMAGE_HWP'):
      if (fileName?.toLowerCase()?.endsWith('.hwpx')) {
        return 'hwpx';
      }

      return 'hwp';
    case gql.scalar('KnowledgeBaseType', 'IMAGE'):
      if (fileName?.toLowerCase()?.endsWith('.png')) {
        return 'png';
      }

      if (fileName?.toLowerCase()?.endsWith('.jpeg')) {
        return 'jpeg';
      }

      return 'jpg';
    case gql.scalar('KnowledgeBaseType', 'MSEXCEL'):
      if (fileName?.toLowerCase()?.endsWith('.xlsx')) {
        return 'xlsx';
      }

      if (fileName?.toLowerCase()?.endsWith('.xlsm')) {
        return 'xlsm';
      }

      return 'xls';
    case gql.scalar('KnowledgeBaseType', 'MSPPT'):
      if (fileName?.toLowerCase()?.endsWith('.pptx')) {
        return 'pptx';
      }

      return 'ppt';
    case gql.scalar('KnowledgeBaseType', 'MSDOCS'):
    case gql.scalar('KnowledgeBaseType', 'OLDMSDOCS'):
      if (fileName?.toLowerCase()?.endsWith('.docx')) {
        return 'docx';
      }

      return 'doc';
    case gql.scalar('KnowledgeBaseType', 'PDF'):
    case gql.scalar('KnowledgeBaseType', 'IMAGE_PDF'):
      return 'pdf';
    case gql.scalar('KnowledgeBaseType', 'TEXT'):
      return 'txt';
  }

  return null;
};
