import { ChatCarousel } from '@allganize/alli-chat-base';
import { carouselClasses } from '@allganize/ui-carousel';
import { List } from '@allganize/ui-list';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';

export type ChatOptionInfoOptionListVariant = 'list' | 'list-wrap' | 'carousel';

export interface ChatOptionInfoOptionListProps {
  align?: 'left' | 'right';
  children?: React.ReactNode;
  className?: string;
  variant: ChatOptionInfoOptionListVariant;
}

export const ChatOptionInfoOptionList: FunctionComponent<
  ChatOptionInfoOptionListProps
> = props => {
  const { align = 'right', children, className, variant } = props;

  if (variant === 'carousel') {
    return (
      <ChatCarousel
        css={css`
          width: 100%;

          .${carouselClasses.slide} > * {
            height: 100%;
          }
        `}
        className={className}
      >
        {children}
      </ChatCarousel>
    );
  }

  return (
    <List
      css={[
        css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          row-gap: 8px;
          margin: 0 -4px;
        `,
        align === 'right' &&
          css`
            align-items: flex-end;
          `,
        variant === 'list-wrap' &&
          css`
            flex-flow: wrap;
            align-items: stretch;
          `,
        variant === 'list-wrap' &&
          align === 'right' &&
          css`
            justify-content: flex-end;
          `,
      ]}
      className={className}
      component="div"
      disablePadding
    >
      {children}
    </List>
  );
};
