import {
  SIDE_PANEL_MIN_WIDTH,
  SIDE_PANEL_SIDE_SPACE,
  SidePanel as SidePanelBase,
  SidePanelContext,
} from '@allganize/alli-works-side-panel';
import { useTheme } from '@emotion/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Resizable } from 're-resizable';

import { useAuthClient } from '../../auth/use-auth-client';
import { environment } from '../../environments/environment';
import { getRegion } from '../../utils/get-region';

const SIDE_PANEL_CONTAINER_MIN_WIDTH =
  SIDE_PANEL_MIN_WIDTH + SIDE_PANEL_SIDE_SPACE * 2;

export const SidePanel = () => {
  const theme = useTheme();
  const { open } = useContext(SidePanelContext);

  const [panelWidth, setPanelWidth] = useState<string>('50%');
  const [isResizing, setIsResizing] = useState(false);

  const [searchParams] = useSearchParams();
  const region = getRegion(searchParams);
  const singleActionResultDownloadUrl =
    region === 'ja' || region === 'kr'
      ? environment.api.singleActionResultDownload[region]
      : environment.api.singleActionResultDownload.default;

  const [clientHashId, setClientHashId] = useState('');
  const authClient = useAuthClient();
  const getClientHashId = useCallback(async () => {
    if (authClient) {
      const deviceId = (await authClient.deviceIdManager.getDeviceId()) || '';
      setClientHashId(deviceId);
    }
  }, [authClient, setClientHashId]);

  useEffect(() => {
    getClientHashId();
  }, [getClientHashId]);

  return (
    <Resizable
      size={{ width: open ? panelWidth : 0 }}
      minWidth={open ? SIDE_PANEL_CONTAINER_MIN_WIDTH : 0}
      maxWidth="70%"
      onResizeStart={() => {
        setIsResizing(true);
      }}
      onResizeStop={(e, direction, ref) => {
        setIsResizing(false);
        setPanelWidth(ref.style.width);
      }}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      style={{
        marginLeft: -SIDE_PANEL_SIDE_SPACE,
        transition: isResizing
          ? 'none'
          : theme.transitions.create(['width', 'opacity'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
      }}
      handleStyles={{
        left: {
          width: 6,
          height: 32,
          left: 2,
          top: '50%',
          transform: 'translateY(-50%)',
          backgroundColor: theme.palette.backgroundInteractive.gray.default,
          borderRadius: theme.radius.round,
          opacity: open ? 1 : 0,
          transition: theme.transitions.create(['opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: open ? theme.transitions.duration.enteringScreen : 0,
            delay: open ? theme.transitions.duration.enteringScreen : 0,
          }),
        },
      }}
    >
      <SidePanelBase
        clientHashId={clientHashId}
        modalContainerId={theme.modalContainer.id}
        singleActionResultDownloadUrl={singleActionResultDownloadUrl}
      />
    </Resizable>
  );
};
