import { Truncate } from '@allganize/truncate';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@allganize/ui-list';
import { Paper } from '@allganize/ui-paper';
import { HighlightedText } from '@allganize/ui-select';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import Popper, { PopperProps } from '@mui/material/Popper';
import {
  AutocompleteGroupedOption,
  UseAutocompleteReturnValue,
} from '@mui/material/useAutocomplete';
import { forwardRef, ReactNode } from 'react';
import { createFilter } from 'react-select';

const isAutocompleteOption = (
  option: AutocompleteOption | AutocompleteGroupedOption<AutocompleteOption>,
): option is AutocompleteOption => {
  return option && 'title' in option && 'icon' in option;
};

export const autocompleteFilter = createFilter<AutocompleteOption>({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'any',
  stringify(option) {
    return [option.label, option.value].join(' ');
  },
});

export type AutocompleteOption<T = any> = {
  icon: ReactNode;
  title: string;
  description?: string;
  onSelect?: () => void;
  detail?: T;
};

interface ChatFormAutocompleteProps extends Omit<PopperProps, 'open'> {
  autocomplete: UseAutocompleteReturnValue<
    AutocompleteOption,
    false,
    true,
    true
  >;
}

export const ChatFormAutocomplete = forwardRef<
  HTMLDivElement,
  ChatFormAutocompleteProps
>((props, ref) => {
  const { autocomplete, ...other } = props;
  const theme = useTheme();

  return (
    <Popper
      css={css`
        z-index: ${theme.zIndex.modal};
        position: absolute;
      `}
      disablePortal
      role="presentation"
      anchorEl={autocomplete.anchorEl}
      open={autocomplete.popupOpen}
      placement="top"
      {...other}
      ref={ref}
      style={{
        width: autocomplete.anchorEl?.clientWidth ?? undefined,
        ...other.style,
      }}
    >
      <Paper
        css={css`
          margin-bottom: 8px;
          overflow: hidden;
        `}
        elevation={4}
      >
        <List
          css={css`
            max-height: 240px;
            overflow: auto;
          `}
          {...autocomplete.getListboxProps()}
        >
          {autocomplete.groupedOptions.map((option, index) => {
            if (!isAutocompleteOption(option)) {
              return null;
            }

            const optionProps = autocomplete.getOptionProps({
              option,
              index,
            });

            const isTitleOnly = option.description === undefined;

            return (
              <ListItem {...optionProps} disablePadding key={optionProps.id}>
                <ListItemButton>
                  <ListItemIcon
                    css={css`
                      width: 20px;
                    `}
                  >
                    {option.icon}
                  </ListItemIcon>

                  <ListItemText
                    css={css`
                      display: flex;
                      gap: 8px;
                      word-break: break-word;
                    `}
                    primary={
                      <Truncate
                        clamp={isTitleOnly ? 2 : 1}
                        css={[
                          !isTitleOnly &&
                            css`
                              width: 200px;
                            `,
                        ]}
                      >
                        <HighlightedText
                          text={option.title}
                          query={autocomplete.inputValue}
                        />
                      </Truncate>
                    }
                    secondary={
                      <Truncate clamp={1} component="span">
                        {option.description}
                      </Truncate>
                    }
                    secondaryTextProps={{
                      variant: 'body14',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Popper>
  );
});
