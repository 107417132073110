import { Types } from '@allganize/alli-sdk-interfaces';
import { NormalizedCacheObject } from '@apollo/client/cache';
import {
  ApolloClient,
  FetchResult,
  MutationOptions,
} from '@apollo/client/core';
import { ObservableSubscription } from '@apollo/client/utilities';
import { ContextSetter } from '@apollo/client/link/context';
import { ErrorLink } from '@apollo/client/link/error';
import EventEmitter from 'eventemitter3';
import {
  EndConversationMutation,
  EndConversationMutationDocument,
  EndConversationMutationVariables,
} from '../../graphql/mutations/end-conversation-mutation';
import {
  SendChatMutation,
  SendChatMutationDocument,
  SendChatMutationVariables,
} from '../../graphql/mutations/send-chat-mutation';
import {
  StartConversationMutation,
  StartConversationMutationDocument,
  StartConversationMutationVariables,
} from '../../graphql/mutations/start-conversation-mutation';
import {
  TryConversationMutation,
  TryConversationMutationDocument,
  TryConversationMutationVariables,
} from '../../graphql/mutations/try-conversation-mutation';
import {
  TrySendChatMutation,
  TrySendChatMutationDocument,
  TrySendChatMutationVariables,
} from '../../graphql/mutations/try-send-chat-mutation';
import {
  CurrentUserQuery,
  CurrentUserQueryDocument,
  CurrentUserQueryVariables,
} from '../../graphql/queries/current-user-query';
import {
  ProjectQuery,
  ProjectQueryDocument,
  ProjectQueryVariables,
} from '../../graphql/queries/project-query';
import { getBrowserLocale, localeToEnum } from '../../i18n/utils';
import { AlliAuthClient } from '../alli-auth-client/alli-auth-client';
import { AlliChatAuthClient } from '../alli-auth-client/alli-chat-auth-client';
import { AlliKeyAuthClient } from '../alli-auth-client/alli-key-auth-client';
import { AlliLauncherManager } from '../alli-launcher-manager/alli-launcher-manager';
import { AlliPageErrorManager } from '../alli-page-error-manager/alli-page-error-manager';
import { AlliPlacementManager } from '../alli-placement-manager/alli-placement-manager';
import {
  AlliUser,
  AlliUserManager,
  AlliUserVariableValue,
  AlliUserVariables,
} from '../alli-user-manager/alli-user-manager';
import { Logger } from '../logger/logger';
import {
  AlliClientEntryType,
  AlliClientEvents,
  AlliClientOptions,
} from './alli-client-options';
import { ProjectFragment } from '../../graphql/fragments/project-fragment';

export class AlliClient extends EventEmitter<AlliClientEvents> {
  private _initialized = false;
  private debug = false;
  public readonly fullscreen: boolean = false;
  public readonly hideCloseButton: boolean = false;
  private logger: Logger;
  public readonly errorManager: AlliPageErrorManager | null = null;
  public readonly entryType: AlliClientEntryType;
  private _subscriptionClient:
    | import('../../graphql/subscription-client').SubscriptionClient
    | null = null;
  private _apolloClient: ApolloClient<NormalizedCacheObject> | null = null;
  public readonly userManager: AlliUserManager;
  private _project: ProjectFragment | null = null;
  private _subscriptionProjectQuery: ObservableSubscription | null = null;

  constructor(
    public readonly authClient: AlliAuthClient,
    public readonly placementManager: AlliPlacementManager,
    public readonly launcherManager: AlliLauncherManager,
    {
      debug = false,
      entryType = 'web',
      fullscreen = false,
      errorManager,
      hideCloseButton = false,
    }: AlliClientOptions = {},
  ) {
    super();
    this.debug = debug;
    this.logger = new Logger(debug);
    this.entryType = entryType;
    this.fullscreen = fullscreen;
    this.errorManager = errorManager ?? null;
    this.userManager = authClient.userManager;
    this.hideCloseButton = hideCloseButton;
  }

  public get initialized() {
    return this._initialized;
  }

  public get subscriptionClient() {
    return this._subscriptionClient;
  }

  public get apolloClient() {
    return this._apolloClient;
  }

  public get placement() {
    return this.placementManager.placement;
  }

  public get user() {
    return this.userManager.user;
  }

  public get project() {
    return this._project;
  }

  public async initialize() {
    this.userManager.on('new-user', this.resetApolloClient);
    this.authClient.persistedUserIdManager.on('update', this.refetchQueries);

    const [
      { createSubscriptionClient },
      { createApolloClient, createRequestLink },
      { setContext },
      { onError },
    ] = await Promise.all([
      import('../../graphql/subscription-client'),
      import('../../graphql/apollo-client'),
      import('@apollo/client/link/context'),
      import('@apollo/client/link/error'),
    ]);

    // initialize apollo client
    const subscriptionClient = createSubscriptionClient<
      Partial<Record<'authorization', string>>
    >({
      lazy: true,
      shouldRetry: () => true,
      url: this.subscriptionEndpoint,
      connectionParams: this.connectionParams,
      on: {
        error: error => {
          this.logger.error(`[Subscription error]: ${error}`);
        },
      },
    });
    const requestLink = createRequestLink(subscriptionClient);
    this._subscriptionClient = subscriptionClient;
    this._apolloClient = createApolloClient({
      link: [
        onError(this.errorHandler),
        setContext(this.contextSetter),
        requestLink,
      ],
      connectToDevTools: this.debug,
    });

    await this.loadProject();
    this.watchProject();

    this._initialized = true;
    this.emit('initialize');
    return this;
  }

  public destroy() {
    this._initialized = false;
    this.userManager.off('new-user', this.resetApolloClient);
    this.authClient.persistedUserIdManager.off('update', this.refetchQueries);
    this._subscriptionClient?.terminate();
    this._subscriptionClient?.dispose();
    this._subscriptionProjectQuery?.unsubscribe();
    this._apolloClient?.clearStore();
    this._apolloClient = null;
    this._subscriptionClient = null;
    this._subscriptionProjectQuery = null;
  }

  public setPlacement(placement?: string | null) {
    return this.placementManager.setPlacement(placement);
  }

  public setUser(user: AlliUser | null) {
    return this.userManager.setUser(user);
  }

  public setUserId(id: string | number) {
    return this.userManager.setId(id);
  }

  public setUserVariable(key: string, value: AlliUserVariableValue) {
    return this.userManager.setVariable(key, value);
  }

  public setUserVariables(variables: AlliUserVariables) {
    return this.userManager.setVariables(variables);
  }

  private getCurrentUser() {
    return this._apolloClient?.query<
      CurrentUserQuery,
      CurrentUserQueryVariables
    >({
      query: CurrentUserQueryDocument,
    });
  }

  private async loadProject() {
    try {
      const result = await this._apolloClient?.query<
        ProjectQuery,
        ProjectQueryVariables
      >({
        query: ProjectQueryDocument,
      });

      if (!result?.data.project) {
        this.errorManager?.report('project-query-fail');
        throw new Error('Failed to fetch project');
      }

      this._project = result.data.project;

      return this._project;
    } catch {
      this.errorManager?.report('project-query-fail');
      throw new Error('Failed to fetch project');
    }
  }

  private watchProject() {
    const subscriptionProjectQuery = this._apolloClient
      ?.watchQuery<ProjectQuery, ProjectQueryVariables>({
        query: ProjectQueryDocument,
      })
      .subscribe({
        next: ({ data }) => {
          if (data?.project) {
            this._project = data.project;
          }
        },
      });

    this._subscriptionProjectQuery = subscriptionProjectQuery || null;
  }

  public async startConversation({
    startOver = false,
    campaignToken,
  }: { startOver?: boolean; campaignToken?: string } = {}) {
    const isTry =
      campaignToken === undefined &&
      this.user.id === null &&
      !(await this.authClient.persistedUserIdManager.getUserId()) &&
      !(
        (this.authClient instanceof AlliChatAuthClient ||
          this.authClient instanceof AlliKeyAuthClient) &&
        this.authClient.chatToken
      );

    if (isTry) {
      const variables: TryConversationMutationVariables = {
        ...this.getAlliProperties(),
        placement: this.placement ?? '',
        variables: AlliUserManager.variablesToInput(this.user.variables),
        startOver,
      };

      const result = await this._apolloClient?.mutate<
        TryConversationMutation,
        TryConversationMutationVariables
      >({
        mutation: TryConversationMutationDocument,
        variables,
      });

      return result;
    }

    const variables: StartConversationMutationVariables = {
      ...this.getAlliProperties(),
      placement: this.placement,
      variables: AlliUserManager.variablesToInput(this.user.variables),
      startOver,
      campaignToken,
    };

    const result = await this._apolloClient?.mutate<
      StartConversationMutation,
      StartConversationMutationVariables
    >({
      mutation: StartConversationMutationDocument,
      variables,
    });

    return result;
  }

  public async sendChat(
    variables: SendChatMutationVariables,
  ): Promise<FetchResult<SendChatMutation> | undefined> {
    const result = await this._apolloClient?.mutate<
      SendChatMutation,
      SendChatMutationVariables
    >({
      mutation: SendChatMutationDocument,
      variables,
    });

    if (
      (result?.data?.sendChat?.errors ?? []).findIndex(
        err => err?.key === Types.MallyError.RACE_CONDITION,
      ) >= 0
    ) {
      return this.sendChat(variables);
    }

    return result;
  }

  public async trySendChat(
    variables: Omit<TrySendChatMutationVariables, 'locale' | 'variables'>,
  ): Promise<FetchResult<TrySendChatMutation> | undefined> {
    const result = await this._apolloClient?.mutate<
      TrySendChatMutation,
      TrySendChatMutationVariables
    >({
      mutation: TrySendChatMutationDocument,
      variables: {
        locale: this.getAlliProperties().locale,
        variables: AlliUserManager.variablesToInput(this.user.variables),
        ...variables,
      },
    });

    if (result?.data?.trySendChat?.user?.ownUserId) {
      await this.authClient.persistedUserIdManager.setUserId(
        result.data.trySendChat.user.ownUserId,
      );
    }

    if (
      (result?.data?.trySendChat?.errors ?? []).findIndex(
        err => err?.key === Types.MallyError.RACE_CONDITION,
      ) >= 0
    ) {
      return this.trySendChat(variables);
    }

    return result;
  }

  public endConversation(
    options: Omit<
      MutationOptions<
        EndConversationMutation,
        EndConversationMutationVariables
      >,
      'mutation'
    >,
  ) {
    return this._apolloClient?.mutate<
      EndConversationMutation,
      EndConversationMutationVariables
    >({
      mutation: EndConversationMutationDocument,
      ...options,
    });
  }

  public getAlliProperties() {
    const locale = getBrowserLocale();
    const { userAgent } = window.navigator;
    const searchParams = new URLSearchParams(window.location.search);

    return {
      locale: localeToEnum[locale],
      userAgent,
      debug: this.debug,
      sdkReferrer: {
        utmMedium: searchParams.get('utm_medium'),
        utmSource: searchParams.get('utm_source'),
        utmCampaign: searchParams.get('utm_campaign'),
        referrer: window.encodeURIComponent(document.referrer),
      },
    };
  }

  private resetApolloClient = async () => {
    this._subscriptionClient?.restart();
    await this._apolloClient?.resetStore();
    await Promise.all([this.loadProject(), this.getCurrentUser()]);
  };

  private refetchQueries = async () => {
    this._apolloClient?.refetchQueries({
      include: [CurrentUserQueryDocument],
    });
  };

  private subscriptionEndpoint = async (): Promise<string> => {
    return this.authClient.getGraphQLEndpoint().ws;
  };

  private connectionParams = () => {
    return this.authClient.getGraphQLHeaders();
  };

  private errorHandler: ErrorLink.ErrorHandler = error => {
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors) {
      graphQLErrors.forEach(graphQLError => {
        this.logger.error(
          `[GraphQL error]: Message: ${graphQLError.message}, Location: ${graphQLError.locations}, Path: ${graphQLError.path}`,
        );
      });
    }

    if (networkError) {
      this.logger.error(`[Network error]: ${networkError}`);

      if (!('result' in networkError && networkError.result)) {
        return;
      }

      if (!networkError.result || typeof networkError.result === 'string') {
        return;
      }

      if (networkError.result.error?.name === 'EXPIRED_TOKEN') {
        this.errorManager?.report('chat-token-expired');
      }
    }
  };

  private contextSetter: ContextSetter = async (operation, prev) => {
    return {
      ...prev,
      uri: this.authClient.getGraphQLEndpoint().http,
      headers: {
        ...prev.headers,
        ...(await this.authClient.getGraphQLHeaders()),
      },
    };
  };
}
