import { useField } from '../internals/hooks/use-field';
import { useUtils } from '../internals/hooks/use-utils';
import { splitFieldInternalAndForwardedProps } from '../internals/utils/fields';
import { validateTime } from '../internals/utils/validation/validate-time';
import {
  singleItemFieldValueManager,
  singleItemValueManager,
} from '../internals/utils/value-managers';
import {
  UseTimeFieldDefaultizedProps,
  UseTimeFieldParams,
  UseTimeFieldProps,
} from './time-field-type-map';

const useDefaultizedTimeField = <AdditionalProps extends {}>(
  props: UseTimeFieldProps<Date>,
): AdditionalProps & UseTimeFieldDefaultizedProps<Date> => {
  const utils = useUtils<Date>();

  const ampm = props.ampm ?? utils.is12HourCycleInCurrentLocale();
  const defaultFormat = ampm
    ? utils.formats.fullTime12h
    : utils.formats.fullTime24h;

  return {
    ...props,
    disablePast: props.disablePast ?? false,
    disableFuture: props.disableFuture ?? false,
    format: props.format ?? defaultFormat,
  } as any;
};

export const useTimeField = <TChildProps extends {}>({
  props: inProps,
  inputRef,
}: UseTimeFieldParams<Date, TChildProps>) => {
  const props = useDefaultizedTimeField<TChildProps>(inProps);

  const { forwardedProps, internalProps } = splitFieldInternalAndForwardedProps<
    typeof props,
    keyof UseTimeFieldProps<any>
  >(props, 'time');

  return useField({
    inputRef,
    forwardedProps,
    internalProps,
    valueManager: singleItemValueManager,
    fieldValueManager: singleItemFieldValueManager,
    validator: validateTime,
    valueType: 'time',
  });
};
