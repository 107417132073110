import { ChatStack, UserChatRowProps } from '@allganize/alli-chat-base';
import {
  ContactAgent,
  ContactAgentProps,
} from '@allganize/alli-chat-contact-agent';
import {
  FormValue,
  FormValue_ChatFragment,
  FormValueProps,
} from '@allganize/alli-chat-form-value';
import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import useSlot from '@mui/material/utils/useSlot';
import { FunctionComponent, useContext } from 'react';
import { TypingContext } from '../typing-context/typing-context';
import { UserChatFile, UserChatFile_ChatFragment } from './user-chat-file';
import { UserChatMedia, UserChatMedia_ChatFragment } from './user-chat-media';
import {
  UserChatMessage,
  UserChatMessage_ChatFragment,
  UserChatMessageSlotProps,
  UserChatMessageSlots,
} from './user-chat-message';
import { gql, readFragment, ResultOf } from '../gql';

export const UserChat_ChatFragment = gql(
  `
  fragment UserChat_ChatFragment on Chat {
    ...on ContactAgentByEmailChat {
      __typename
      id
    }

    ...on UserChat {
      __typename
      id
    }

    ...UserChatMessage_ChatFragment
    ...UserChatMedia_ChatFragment
    ...UserChatFile_ChatFragment
    ...FormValue_ChatFragment
  }
`,
  [
    UserChatMessage_ChatFragment,
    UserChatMedia_ChatFragment,
    UserChatFile_ChatFragment,
    FormValue_ChatFragment,
  ],
);

export interface UserChatSlots extends UserChatMessageSlots {
  /**
   * @default ContactAgent
   */
  contactAgent?: React.ElementType;
  /**
   * @default FormValue
   */
  formValue?: React.ElementType;
}

export interface UserChatSlotProps extends UserChatMessageSlotProps {
  contactAgent: SlotProps<React.ElementType<ContactAgentProps>, {}, {}>;
  formValue: SlotProps<React.ElementType<FormValueProps>, {}, {}>;
}

export type UserChatSlotsAndSlotProps = CreateSlotsAndSlotProps<
  UserChatSlots,
  UserChatSlotProps
>;

export interface UserChatProps
  extends Pick<UserChatRowProps, 'avatar'>,
    UserChatSlotsAndSlotProps {
  align?: 'left' | 'right';
  chat: ResultOf<typeof UserChat_ChatFragment>;
}

export const UserChat: FunctionComponent<UserChatProps> = props => {
  const {
    align = 'right',
    avatar,
    chat: chatProp,
    slotProps = {},
    slots = {},
  } = props;
  const chat = readFragment(UserChat_ChatFragment, chatProp);
  const { onTyping } = useContext(TypingContext);

  const [ContactAgentSlot, contactAgentSlotProps] = useSlot('contactAgent', {
    elementType: ContactAgent,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });

  const [FormValueSlot, formValueSlotProps] = useSlot('formValue', {
    elementType: FormValue,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });

  return (
    <ChatStack>
      <UserChatMessage
        align={align}
        avatar={avatar}
        chat={chat}
        slotProps={slotProps}
        slots={slots}
      />

      <UserChatMedia align={align} avatar={avatar} chat={chat} />
      <UserChatFile align={align} avatar={avatar} chat={chat} />

      <FormValueSlot
        align={align}
        chat={chat}
        onTyping={onTyping}
        {...formValueSlotProps}
      />

      <ContactAgentSlot
        align={align}
        onTyping={onTyping}
        {...contactAgentSlotProps}
      />
    </ChatStack>
  );
};
