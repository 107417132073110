import { format, parse } from 'date-fns';
import { noop } from 'lodash-es';
import { FileFormValue } from './use-form-value-form';

const dateFormat = 'yyyy-MM-dd';

export const parseDate = (value: string) => {
  return parse(value, dateFormat, new Date());
};

export const formatDate = (value: Date) => {
  return format(value, dateFormat);
};

export const formatFilename = ({
  value,
  window: win,
}: {
  value: FileFormValue['value'];
  window?: typeof globalThis;
}): string | null => {
  if (!value) {
    return null;
  }

  if ((win && value instanceof win.File) || value instanceof File) {
    return value.name;
  }

  if (value.metaInfo) {
    try {
      const parsed = JSON.parse(value.metaInfo);
      return parsed?.filename || parsed?.name;
    } catch {
      // nothing to do
    }
  }

  return value.url || null;
};

export const getFileUrl = ({
  value,
  window: win,
}: {
  value: FileFormValue['value'];
  window?: typeof globalThis;
}) => {
  if (!value) {
    return {
      cleanup: noop,
      url: null,
    };
  }

  if ((win && value instanceof win.File) || value instanceof File) {
    const url = URL.createObjectURL(value);

    return {
      url,
      cleanup: () => URL.revokeObjectURL(url),
    };
  }

  return {
    cleanup: noop,
    url: value.url,
  };
};
