import { SearchField } from '@allganize/ui-search';
import { css } from '@emotion/react';
import { FunctionComponent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

interface DocumentsSearchFormValues {
  query: string;
}

interface DocumentsSearchFormProps {
  className?: string;
}

export const DocumentsSearchForm: FunctionComponent<
  DocumentsSearchFormProps
> = props => {
  const { className } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryParam = searchParams.get('query');

  const { control, handleSubmit, setValue } =
    useForm<DocumentsSearchFormValues>({
      defaultValues: {
        query: queryParam ?? '',
      },
      mode: 'onSubmit',
    });

  useEffect(() => {
    setValue('query', queryParam ?? '');
  }, [queryParam, setValue]);

  const handleClear = () => {
    const newPathname = '/documents/search';

    if (newPathname !== location.pathname) {
      return;
    }

    navigate(-1);
  };

  const submit = (values: DocumentsSearchFormValues) => {
    const newPathname = '/documents/search';

    if (!values.query && newPathname === location.pathname) {
      navigate(-1);
      return;
    }

    const newParams = new URLSearchParams(searchParams);
    newParams.set('query', values.query);
    newParams.delete('detail');

    navigate(
      { pathname: newPathname, search: newParams.toString() },
      { replace: location.pathname === newPathname },
    );
  };

  return (
    <form
      css={css`
        margin: 0;
      `}
      className={className}
      onSubmit={handleSubmit(submit)}
    >
      <Controller
        control={control}
        name="query"
        render={({ field: { ref: fieldRef, ...field } }) => {
          return (
            <SearchField
              {...field}
              inputRef={fieldRef}
              label={null}
              fullWidth
              InputProps={{ variant: 'filled', onClear: handleClear }}
            />
          );
        }}
      />
    </form>
  );
};
