import { Skeleton } from '@allganize/ui-skeleton';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { Toolbar } from '@mui/material';
import { PanelItemLoading } from '../panel-item/panel-item-loading';

export const SidePanelLoading = () => {
  const theme = useTheme();

  return (
    <>
      <Toolbar
        css={css`
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: ${theme.palette.unstable_background.white};
          padding-left: 16px;
          padding-right: 16px;
          gap: 8px;

          ${theme.breakpoints.up('xs')} {
            padding-left: 16px;
            padding-right: 16px;
          }
        `}
      >
        <Skeleton width={20} height={20} />

        <Skeleton variant="text" textVariant="subtitle14" width="40%" />
      </Toolbar>

      <div
        css={css`
          padding: 0 16px;
        `}
      >
        <Skeleton variant="rounded" width="100%" height={200} />
        <PanelItemLoading />
        <PanelItemLoading />
        <PanelItemLoading />
      </div>
    </>
  );
};
