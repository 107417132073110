import { createContext } from 'react';

export interface PreviewContextValue {
  previewSidebarAnchor: 'left' | 'right';
  previewSidebarWidth: number;
  messengerWidthWhenPreviewOpen: number;
  messengerHeightWhenPreviewOpen: number;
  shouldOpenPreviewInDialog: boolean;
}

export const PreviewContext = createContext<PreviewContextValue>({
  previewSidebarAnchor: 'left',
  previewSidebarWidth: 0,
  messengerWidthWhenPreviewOpen: 0,
  messengerHeightWhenPreviewOpen: 0,
  shouldOpenPreviewInDialog: true,
});

export type PreviewContextProps = Pick<
  PreviewContextValue,
  'previewSidebarAnchor' | 'shouldOpenPreviewInDialog'
>;
