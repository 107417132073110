import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { baseStateClasses } from './base-state.classes';
import { BaseStateProps } from './base-state.types';

export const BaseState = forwardRef<HTMLDivElement, BaseStateProps>(
  (props, ref) => {
    const {
      actions,
      classes,
      className,
      description,
      image,
      title,
      variant = 'page',
      ...other
    } = props;
    const theme = useTheme();

    return (
      <div
        css={[
          css`
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
          `,
          variant === 'card' &&
            css`
              gap: 16px;
            `,
        ]}
        {...other}
        ref={ref}
        className={clsx(
          baseStateClasses.root,
          baseStateClasses[variant],
          classes?.root,
          classes?.[variant],
          className,
        )}
      >
        <div
          css={[
            css`
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              gap: 24px;
            `,
            variant === 'modal' &&
              css`
                gap: 16px;
              `,
            variant === 'card' &&
              css`
                gap: 8px;
              `,
          ]}
          className={clsx(baseStateClasses.contents, classes?.contents)}
        >
          {image}

          {(title || description) && (
            <div
              css={[
                css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                `,
                variant !== 'page' &&
                  css`
                    gap: 4px;
                  `,
              ]}
              className={clsx(baseStateClasses.text, classes?.text)}
            >
              {title && (
                <Text
                  css={css`
                    color: ${theme.palette.foreground.secondary};
                  `}
                  variant={variant === 'page' ? 'title20' : 'title16'}
                  align="center"
                  className={clsx(baseStateClasses.title, classes?.title)}
                >
                  {title}
                </Text>
              )}

              {description && (
                <Text
                  css={css`
                    white-space: pre-line;
                    color: ${theme.palette.foreground.helper};
                  `}
                  variant="body14"
                  align="center"
                  className={clsx(
                    baseStateClasses.description,
                    classes?.description,
                  )}
                >
                  {description}
                </Text>
              )}
            </div>
          )}
        </div>

        {actions && (
          <div
            css={css`
              display: flex;
              gap: 8px;
            `}
            className={clsx(baseStateClasses.actions, classes?.actions)}
          >
            {actions}
          </div>
        )}
      </div>
    );
  },
);
