import { useEventCallback } from '@allganize/hooks';
import { css } from '@emotion/react';
import clsx from 'clsx';
import {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { DocumentViewerPageWrapper } from '../document-viewer-page-wrapper';
import { PageProxy } from '../hooks/use-scale';
import { htmlPageClasses } from './html-page-classes';
import { HtmlPageProps } from './html-page-type-map';

export const HtmlPage = forwardRef<HTMLDivElement, HtmlPageProps>(
  (props, ref) => {
    const {
      classes,
      page,
      pageRef,
      scale = 1,
      style: styleProp,
      ...other
    } = props;
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const imagesLoaded = useRef(false);
    const [size, setSize] = useState({
      width:
        iframeRef.current?.contentWindow?.document?.documentElement
          ?.clientWidth,
      height:
        iframeRef.current?.contentWindow?.document?.documentElement
          ?.clientHeight,
    });
    const style = typeof styleProp === 'function' ? styleProp(size) : styleProp;

    const updateSize = useEventCallback(() => {
      const docEl = iframeRef.current?.contentWindow?.document?.documentElement;

      if (!docEl) {
        return;
      }

      const newWidth = Math.ceil(
        Math.max(docEl.clientWidth, docEl.scrollWidth, size.width ?? 0),
      );
      const newHeight = Math.ceil(
        Math.max(docEl.clientHeight, docEl.scrollHeight, size.height ?? 0),
      );

      if (newWidth === size.width && newHeight === size.height) {
        return;
      }

      setSize({
        width: newWidth,
        height: newHeight,
      });
    });

    useImperativeHandle<PageProxy | null, PageProxy | null>(
      pageRef,
      () => ({
        getKey() {
          return page.key;
        },
        getSize() {
          if (
            typeof size.width === 'undefined' ||
            typeof size.height === 'undefined' ||
            size.width === 0 ||
            size.height === 0
          ) {
            return null;
          }

          // make sure size is calculated once all images are loaded
          if (!imagesLoaded.current) {
            return null;
          }

          return { width: size.width, height: size.height };
        },
      }),
      [page.key, size.height, size.width],
    );

    const handleImages = useEventCallback(() => {
      const images =
        iframeRef.current?.contentWindow?.document?.querySelectorAll('img');
      const totalCount = images?.length ?? 0;
      let loadedCount = 0;

      const checkImagesLoaded = () => {
        if (loadedCount >= totalCount) {
          imagesLoaded.current = true;
          return;
        }

        imagesLoaded.current = false;
      };

      const handleImageLoad = () => {
        loadedCount += 1;
        checkImagesLoaded();
        updateSize();
      };

      const attachImageLoadEvent = (image: HTMLImageElement) => {
        if (image.complete) {
          handleImageLoad();
        } else {
          image.addEventListener('load', handleImageLoad);
          image.addEventListener('error', handleImageLoad);
        }
      };

      const removeImageLoadEvent = (image: HTMLImageElement) => {
        image.removeEventListener('load', handleImageLoad);
        image.removeEventListener('error', handleImageLoad);
      };

      imagesLoaded.current = false;
      images?.forEach(attachImageLoadEvent);
      checkImagesLoaded();

      return () => {
        images?.forEach(removeImageLoadEvent);
      };
    });

    useLayoutEffect(() => {
      let rafId: number | null = null;
      let cleanupImages: (() => void) | null = null;

      const cleanup = () => {
        if (rafId !== null) {
          window.cancelAnimationFrame(rafId);
          rafId = null;
        }
      };

      const start = () => {
        rafId = window.requestAnimationFrame(() => {
          if (
            !iframeRef.current?.contentWindow?.document?.body?.innerHTML.trim()
          ) {
            start();
            return;
          }

          cleanup();
          const cleanupImagesFn = handleImages();
          const oldCleanupImages = cleanupImages;

          cleanupImages = () => {
            oldCleanupImages?.();
            cleanupImagesFn();
          };

          updateSize();
        });
      };

      start();

      return () => {
        cleanup();
        cleanupImages?.();
        cleanupImages = null;
      };
    });

    return (
      <DocumentViewerPageWrapper
        data-testid="html-page"
        {...other}
        ref={ref}
        className={clsx(htmlPageClasses.root, classes?.root, other.className)}
        style={{
          width: size.width,
          height: size.height,
          ...style,
        }}
      >
        <iframe
          data-testid="html-page__iframe"
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            min-width: 100%;
          `}
          title={other.title}
          allowFullScreen
          frameBorder={0}
          ref={iframeRef}
          style={{
            width: size.width,
            height: size.height,
            transform: `translate(-50%, -50%) scale(${scale})`,
          }}
          className={clsx(htmlPageClasses.iframe, classes?.iframe)}
          srcDoc={page.html}
        />
      </DocumentViewerPageWrapper>
    );
  },
);
