import { BaseState, Illustration } from '@allganize/ui-state';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

interface KnowledgeBaseListEmptyStateProps {
  isSearch?: boolean;
}

export const KnowledgeBaseListEmptyState: FunctionComponent<
  KnowledgeBaseListEmptyStateProps
> = ({ isSearch }) => {
  return (
    <BaseState
      css={css`
        padding: 60px 0;
      `}
      image={
        isSearch ? (
          <Illustration.NoResult alt="documents empty" />
        ) : (
          <Illustration.Empty alt="documents empty" />
        )
      }
      title={
        isSearch ? (
          <FormattedMessage
            id="document-list.search-empty-state.title"
            defaultMessage="Sorry, we couldn't find any results for your search."
            description="document list search empty state title"
          />
        ) : (
          <FormattedMessage
            id="document-list.search-empty-state.title"
            defaultMessage="No Files Found"
            description="document list search empty state title"
          />
        )
      }
      description={
        isSearch ? (
          <FormattedMessage
            id="document-list.search-empty-state.description"
            defaultMessage="Please try a different keyword or check your spelling."
            description="document list search empty state description"
          />
        ) : (
          <FormattedMessage
            id="document-list.empty-state.description"
            defaultMessage="Upload documents to your personal documents.
          They can be used for cognitive search or for answering your customers questions."
            description="document list empty state description"
          />
        )
      }
    />
  );
};
