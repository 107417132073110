import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { LoadingDots } from '../loading-dots/loading-dots';
import { UserChatRow } from '../user-chat-row';
import { UserChatRowTypingProps } from './user-chat-row-typing.types';

export const UserChatRowTyping = forwardRef<
  HTMLDivElement,
  UserChatRowTypingProps
>((props, ref) => {
  return (
    <UserChatRow {...props} ref={ref}>
      <div
        css={css`
          line-height: 1;
        `}
      >
        <LoadingDots />
      </div>
    </UserChatRow>
  );
});
