import { css } from '@emotion/react';
import { FunctionComponent, HTMLAttributes } from 'react';
import { LLMAppCardSkeleton } from '../llm-app-card/llm-app-card-skeleton';

interface LLMAppListLoadingStateProps extends HTMLAttributes<HTMLDivElement> {
  columns?: number;
}

export const LLMAppListLoadingState: FunctionComponent<
  LLMAppListLoadingStateProps
> = ({ columns = 1, ...others }) => {
  return (
    <div
      css={css`
        display: grid;
        gap: 8px;
        grid-template-columns: repeat(${columns}, 1fr);
      `}
      {...others}
    >
      {Array.from(Array(6)).map((_, i) => (
        <LLMAppCardSkeleton key={i} />
      ))}
    </div>
  );
};
