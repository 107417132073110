import { useId } from '@allganize/hooks';
import { IconButton } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { Divider } from '@allganize/ui-divider';
import {
  IcDelete,
  IcDownload,
  IcEdit,
  IcFolderMove,
  IcMoreVert,
  IcToggleOff,
  IcToggleOn,
} from '@allganize/ui-icons';
import { ListItemIcon, ListItemText } from '@allganize/ui-list';
import { Menu, MenuItem } from '@allganize/ui-menu';
import { omit } from 'lodash-es';
import { memo, useContext, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, readFragment } from '../gql';
import {
  KnowledgeBaseActionsContext,
  KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment,
} from './knowledge-base-actions-context';

interface KnowledgeBaseActionsColumnProps {
  node: FragmentOf<
    typeof KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment
  >;
}

export const KnowledgeBaseActionsColumn = memo<KnowledgeBaseActionsColumnProps>(
  props => {
    const { node: nodeProp } = props;
    const node = readFragment(
      KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment,
      nodeProp,
    );
    const {
      isEditable = () => true,
      setRowSelection,
      onDelete,
      onEdit,
      onMove,
      onDownload,
      onToggleStatus,
    } = useContext(KnowledgeBaseActionsContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const menuTriggerRef = useRef<HTMLButtonElement>(null);
    const menuId = useId();
    const [deleting, setDeleting] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [turningOn, setTurningOn] = useState(false);
    const [turningOff, setTurningOff] = useState(false);

    const isEditableNode = isEditable(node);
    const isFolder = node.__typename === 'KnowledgeBaseNodeFolder';
    const canMove = !!onMove && isEditableNode;
    const canDelete = !!onDelete && isEditableNode;
    const canDownload = !isFolder && !!onDownload;
    const canEdit = !!onEdit && isEditableNode;
    const canToggleStatus = !isFolder && !!onToggleStatus && isEditableNode;

    const closeMenu = () => {
      setMenuOpen(false);
    };

    const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      setMenuOpen(true);
    };

    const handleDelete = async () => {
      setDeleting(true);

      try {
        const result = await onDelete?.(node.id);

        if (result) {
          setRowSelection(prev => {
            return omit(prev, node.id);
          });
        }
      } finally {
        setDeleting(false);
        closeMenu();
      }
    };

    const handleEdit = () => {
      onEdit?.(node);
      closeMenu();
    };

    const handleMove = () => {
      onMove?.(node);
      closeMenu();
    };

    const handleDownload = async () => {
      setDownloading(true);

      try {
        await onDownload?.(node);
      } finally {
        setDownloading(false);
        closeMenu();
      }
    };

    const handleTurnOn = async () => {
      setTurningOn(true);

      try {
        await onToggleStatus?.({ ids: [node.id], use: true });
      } finally {
        setTurningOn(false);
        closeMenu();
      }
    };

    const handleTurnOff = async () => {
      setTurningOff(true);

      try {
        await onToggleStatus?.({
          ids: [node.id],
          use: false,
        });
      } finally {
        setTurningOff(false);
        closeMenu();
      }
    };

    if (!canEdit && !canDownload && !canDelete && !canToggleStatus) {
      return null;
    }

    return (
      <>
        <IconButton
          aria-controls={menuOpen ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          shape="rounded"
          onClick={handleClick}
          ref={menuTriggerRef}
        >
          <IcMoreVert />
        </IconButton>

        <Menu
          id={menuId}
          anchorEl={menuTriggerRef.current}
          open={menuOpen}
          onClose={closeMenu}
          onClick={ev => {
            ev.stopPropagation();
          }}
        >
          {canEdit && (
            <MenuItem onClick={handleEdit}>
              <ListItemIcon>
                <IcEdit />
              </ListItemIcon>

              <ListItemText
                primary={
                  <FormattedMessage
                    id="actions.edit"
                    defaultMessage="Edit"
                    description="Edit action text"
                  />
                }
              />
            </MenuItem>
          )}

          {canMove && (
            <MenuItem onClick={handleMove}>
              <ListItemIcon>
                <IcFolderMove />
              </ListItemIcon>

              <ListItemText
                primary={
                  <FormattedMessage
                    id="actions.move"
                    defaultMessage="Move to folder"
                    description="move action text"
                  />
                }
              />
            </MenuItem>
          )}

          {canDownload && (
            <MenuItem disabled={downloading} onClick={handleDownload}>
              <ListItemIcon>
                {downloading ? <CircularProgress size="sm" /> : <IcDownload />}
              </ListItemIcon>

              <ListItemText
                primary={
                  <FormattedMessage
                    id="actions.download"
                    defaultMessage="Download"
                    description="Download action text"
                  />
                }
              />
            </MenuItem>
          )}

          {canToggleStatus && (
            <MenuItem disabled={turningOn} onClick={handleTurnOn}>
              <ListItemIcon>
                {turningOn ? <CircularProgress size="sm" /> : <IcToggleOn />}
              </ListItemIcon>

              <ListItemText
                primary={
                  <FormattedMessage
                    id="FAQ.Documents.TurnOnButton"
                    defaultMessage="Turn on selected"
                    description="FAQ document list turn on button"
                  />
                }
              />
            </MenuItem>
          )}

          {canToggleStatus && (
            <MenuItem disabled={turningOff} onClick={handleTurnOff}>
              <ListItemIcon>
                {turningOff ? <CircularProgress size="sm" /> : <IcToggleOff />}
              </ListItemIcon>

              <ListItemText
                primary={
                  <FormattedMessage
                    id="FAQ.Documents.TurnOffButton"
                    defaultMessage="Turn off selected"
                    description="FAQ document list turn off button"
                  />
                }
              />
            </MenuItem>
          )}

          {(canEdit || canMove || canDownload || canToggleStatus) &&
            canDelete && <Divider component="li" />}

          {canDelete && (
            <MenuItem disabled={deleting} onClick={handleDelete}>
              <ListItemIcon>
                {deleting ? <CircularProgress size="sm" /> : <IcDelete />}
              </ListItemIcon>

              <ListItemText
                primary={
                  <FormattedMessage
                    id="actions.delete"
                    defaultMessage="Delete"
                    description="FAQ documents delete button text"
                  />
                }
              />
            </MenuItem>
          )}
        </Menu>
      </>
    );
  },
);
