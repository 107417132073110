import {
  FormControl,
  FormControlLabel,
  formControlLabelClasses,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@allganize/ui-form';
import { Switch } from '@allganize/ui-switch';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormValueFormValues } from './use-form-value-form';

interface SwitchFormValueFieldProps {
  disabled?: boolean;
  name: `formValues.${number}`;
}

export const SwitchFormValueField: FunctionComponent<
  SwitchFormValueFieldProps
> = props => {
  const { disabled: disabledProp, name } = props;
  const { control } = useFormContext<FormValueFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name,
    disabled: disabledProp,
  });
  const {
    field: { ref: valueRef, ...value },
    fieldState: valueState,
  } = useController({
    control,
    name: `${name}.value`,
    disabled: disabledProp,
  });
  const disabled = field.disabled || formState.disabled;

  if (field.value.type !== 'switch') {
    return null;
  }

  if (disabled) {
    return (
      <FormControl fullWidth required={field.value.required}>
        <FormLabel>{field.value.label}</FormLabel>

        <Text variant="body14">{field.value.value ? 'ON' : 'OFF'}</Text>
      </FormControl>
    );
  }

  return (
    <FormControl
      component="fieldset"
      fullWidth
      required={field.value.required}
      error={fieldState.invalid || valueState.invalid}
    >
      <FormGroup>
        <FormControlLabel
          css={css`
            &.${formControlLabelClasses.labelPlacementStart} {
              width: 100%;
              margin-left: 0;

              .${formControlLabelClasses.label} {
                flex-grow: 1;
              }
            }
          `}
          labelPlacement="start"
          name={field.name}
          checked={value.value as boolean}
          onChange={(ev, checked) => {
            value.onChange(checked);
          }}
          control={<Switch />}
          label={
            <FormLabel
              css={css`
                margin-bottom: 0;
              `}
              component="legend"
            >
              {field.value.label}
            </FormLabel>
          }
        />
      </FormGroup>

      {valueState.error?.message && (
        <FormHelperText error>{valueState.error.message}</FormHelperText>
      )}

      {fieldState.error?.message && (
        <FormHelperText error>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  );
};
