import { Types } from '@allganize/alli-sdk-interfaces';
import { useCombinedRef } from '@allganize/hooks';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import {
  IcLanguage,
  IcLanguageCN,
  IcLanguageCN2,
  IcLanguageEN,
  IcLanguageES,
  IcLanguageFR,
  IcLanguageJP,
  IcLanguageKO,
  IcLanguageTH,
} from '@allganize/ui-icons';
import { ListItemText } from '@allganize/ui-list';
import { Menu, MenuItem } from '@allganize/ui-menu';
import { forwardRef, Fragment, useRef, useState } from 'react';

const SUPPORT_LOCALES = [
  Types.LocaleEnum.JA,
  Types.LocaleEnum.EN,
  Types.LocaleEnum.ZH_CN,
  Types.LocaleEnum.ZH_TW,
  Types.LocaleEnum.KO,
  Types.LocaleEnum.TH,
  Types.LocaleEnum.FR,
  Types.LocaleEnum.ES,
] as const;

const maps: Partial<
  Record<
    Types.LocaleEnum,
    {
      icon: React.ReactNode;
      message: string;
    }
  >
> = {
  [Types.LocaleEnum.JA]: {
    icon: <IcLanguageJP />,
    message: '日本語',
  },
  [Types.LocaleEnum.EN]: {
    icon: <IcLanguageEN />,
    message: 'English',
  },
  [Types.LocaleEnum.ZH_CN]: {
    icon: <IcLanguageCN />,
    message: '简体中文',
  },
  [Types.LocaleEnum.ZH_TW]: {
    icon: <IcLanguageCN2 />,
    message: '繁體中文',
  },
  [Types.LocaleEnum.KO]: {
    icon: <IcLanguageKO />,
    message: '한국어',
  },
  [Types.LocaleEnum.TH]: {
    icon: <IcLanguageTH />,
    message: 'ภาษาไทย',
  },
  [Types.LocaleEnum.FR]: {
    icon: <IcLanguageFR />,
    message: 'Français',
  },
  [Types.LocaleEnum.ES]: {
    icon: <IcLanguageES />,
    message: 'Español',
  },
};

export interface LanguageSelectionMenuProps extends IconButtonProps {
  currentLocale?: Types.LocaleEnum;
  onCloseLanguageMenu?: (
    event: unknown,
    reason: 'backdropClick' | 'escapeKeyDown' | 'tabKeyDown' | 'menuItemClick',
  ) => void;
  onToggleLanguageMenu?: (menuOpen: boolean) => void;
  handleChangeLocale?: (locale: Types.LocaleEnum) => void;
}

export const LanguageSelectionMenu = forwardRef<
  HTMLButtonElement,
  LanguageSelectionMenuProps
>(
  (
    {
      currentLocale,
      onCloseLanguageMenu,
      onToggleLanguageMenu,
      handleChangeLocale,
      ...other
    },
    ref,
  ) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuToggleRef = useRef<HTMLButtonElement>(null);
    const combinedRef = useCombinedRef(menuToggleRef, ref);

    const closeMenu = (
      event: unknown,
      reason:
        | 'backdropClick'
        | 'escapeKeyDown'
        | 'tabKeyDown'
        | 'menuItemClick',
    ) => {
      setMenuOpen(false);
      onCloseLanguageMenu?.(event, reason);
    };

    const toggleMenu = (ev: React.MouseEvent<HTMLButtonElement>) => {
      setMenuOpen(p => !p);
      onToggleLanguageMenu?.(menuOpen);
      other.onClick?.(ev);
    };

    return (
      <Fragment>
        <IconButton
          id="conversation-list-header-language-menu-toggle"
          aria-controls={
            menuOpen ? 'conversation-list-header-language-menu' : undefined
          }
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          {...other}
          onClick={toggleMenu}
          ref={combinedRef}
        >
          {currentLocale && maps[currentLocale] ? (
            maps[currentLocale].icon
          ) : (
            <IcLanguage />
          )}
        </IconButton>

        <Menu
          id="conversation-list-header-language-menu"
          open={menuOpen}
          anchorEl={menuToggleRef.current}
          onClose={closeMenu}
          // TODO: remove once https://github.com/mui/material-ui/pull/39328 is published
          disableScrollLock
          MenuListProps={{
            'aria-labelledby': 'conversation-list-header-language-menu-toggle',
          }}
        >
          {SUPPORT_LOCALES.map(locale => (
            <MenuItem
              key={locale}
              selected={locale === currentLocale}
              onClick={() => handleChangeLocale?.(locale)}
            >
              <ListItemText primary={maps[locale]?.message || locale} />
            </MenuItem>
          ))}
        </Menu>
      </Fragment>
    );
  },
);
