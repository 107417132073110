import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { chatStackClasses } from './chat-stack.classes';
import { ChatStackProps } from './chat-stack.types';

export const ChatStack = forwardRef<HTMLDivElement, ChatStackProps>(
  (props, ref) => {
    const { children, classes, className, header, ...other } = props;

    return (
      <div
        {...other}
        ref={ref}
        className={clsx(chatStackClasses.root, classes?.root, className)}
      >
        {header}

        <div
          css={css`
            > * {
              margin-top: 16px;
            }

            > *:first-child {
              margin-top: 0;
            }
          `}
          className={clsx(chatStackClasses.content, classes?.content)}
        >
          {children}
        </div>
      </div>
    );
  },
);
