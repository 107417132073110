import { useMeasure } from '@allganize/hooks';
import { useTheme } from '@allganize/ui-theme';
import { useState } from 'react';

export const useLLMAppListColumns = <T extends HTMLElement>() => {
  const theme = useTheme();
  const [columns, setColumns] = useState(1);

  const getColumns = (containerWidth: number) => {
    if (containerWidth > theme.breakpoints.values.lg) {
      return 3;
    } else if (containerWidth > theme.breakpoints.values.sm) {
      return 2;
    } else {
      return 1;
    }
  };

  const { measureRef } = useMeasure<T>({
    offset: true,
    onResize(contentRect) {
      if (!contentRect.offset) {
        return;
      }

      setColumns(getColumns(contentRect.offset.width));
    },
  });

  return { containerRef: measureRef, columns };
};
