import { useTheme } from '@allganize/ui-theme';
import { SingleAction } from '@allganize/single-action-app';
import { IconButton } from '@allganize/ui-button';
import { IcAppMarket } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { useContext } from 'react';
import { AppPreviewMetadata } from './use-tab-navigator';
import { SidePanelContext } from './side-panel-context';
import { SidePanelErrorFallback } from './side-panel-error-fallback';

export interface AppPreviewPanelProps {
  clientHashId: string;
  modalContainerId: string;
  singleActionResultDownloadUrl: string;
}

export const AppPreviewPanel = ({
  clientHashId,
  modalContainerId,
  singleActionResultDownloadUrl,
}: AppPreviewPanelProps) => {
  const theme = useTheme();
  const { activeTab, openAppListTab } = useContext(SidePanelContext);

  if (
    activeTab?.type !== 'APP_PREVIEW' ||
    !activeTab?.metadata ||
    !(activeTab?.metadata as AppPreviewMetadata | undefined)?.singleActionApp
  ) {
    return (
      <SidePanelErrorFallback
        css={css`
          height: 100%;
        `}
      />
    );
  }

  const { publicToken } = (activeTab.metadata as AppPreviewMetadata)
    .singleActionApp;

  return (
    <div
      css={css`
        position: relative;
        height: 100%;
      `}
    >
      <IconButton
        onClick={openAppListTab}
        css={css`
          position: absolute;
          top: 12px;
          right: 16px;
        `}
      >
        <IcAppMarket />
      </IconButton>
      <SingleAction
        publicToken={publicToken}
        clientHashId={clientHashId}
        modalContainerId={modalContainerId}
        singleActionResultDownloadUrl={singleActionResultDownloadUrl}
        css={css`
          background-color: ${theme.palette.unstable_background.default};
        `}
      />
    </div>
  );
};
