import { Skeleton } from '@allganize/ui-skeleton';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';

export const KnowledgeBaseNodeSkeleton = () => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 4px 12px;
        height: 40px;
        border-radius: ${theme.radius.sm}px;
      `}
    >
      <span
        css={css`
          min-width: 32px;
        `}
      >
        <Skeleton variant="rounded" width={20} height={20} />
      </span>

      <div
        css={css`
          flex-grow: 1;
        `}
      >
        <Skeleton variant="text" textVariant="body14" width="100%" />
      </div>
    </div>
  );
};
