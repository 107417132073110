import { noop } from 'lodash-es';
import { createContext } from 'react';
import { DownloadFileFormValues } from '../download-file-dialog/download-file-form-values';
import { Scalars } from '../gql';

interface DownloadFile {
  status: Scalars<'PrepareFileStatusEnum'> | null;
  download: (values: DownloadFileFormValues) => void;
  downloadFile: () => void;
}

export const DownloadFileContext = createContext<DownloadFile>({
  status: null,
  download: noop,
  downloadFile: noop,
});
