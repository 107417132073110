import { LoadingButton } from '@allganize/ui-button';
import { FormHelperText } from '@allganize/ui-form';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ChatOptionInfoOptionListVariant } from './chat-option-info-option-list';
import { MultipleField } from './multiple-field';
import { SingleField } from './single-field';
import {
  ChatOptionInfoOption,
  useChatOptionInfoForm,
  UseChatOptionInfoFormOptions,
} from './use-chat-option-info-form';

export interface ChatOptionInfoFormProps extends UseChatOptionInfoFormOptions {
  align?: 'left' | 'right';
  className?: string;
  isOptionDisabled?(option: ChatOptionInfoOption): boolean;
  options: ChatOptionInfoOption[];
  variant: ChatOptionInfoOptionListVariant;
}

export const ChatOptionInfoForm: FunctionComponent<
  ChatOptionInfoFormProps
> = props => {
  const {
    align = 'right',
    className,
    disabled: disabledProp,
    defaultValues,
    isOptionDisabled,
    onSubmit,
    options,
    variant,
  } = props;
  const { form, submit } = useChatOptionInfoForm({
    defaultValues,
    disabled: disabledProp,
    onSubmit,
  });
  const {
    formState: { disabled, errors, isSubmitting, isValid },
    handleSubmit,
    watch,
  } = form;
  const isMulti = watch('isMulti');

  useEffect(() => {
    if (isMulti) {
      return;
    }

    const subscription = watch(values => {
      if (!values.single) {
        return;
      }

      handleSubmit(submit)();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [handleSubmit, isMulti, submit, watch]);

  return (
    <form
      css={[
        css`
          margin: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        `,
        align === 'right' &&
          css`
            align-items: flex-end;
          `,
        variant !== 'carousel' &&
          css`
            padding: 0 12px;
          `,
      ]}
      className={className}
      onSubmit={handleSubmit(submit)}
    >
      <FormProvider {...form}>
        {isMulti ? (
          <MultipleField
            align={align}
            isOptionDisabled={isOptionDisabled}
            options={options}
            variant={variant}
          />
        ) : (
          <SingleField
            align={align}
            isOptionDisabled={isOptionDisabled}
            options={options}
            variant={variant}
          />
        )}
      </FormProvider>

      {errors.root?.message && (
        <FormHelperText error>
          <Text variant="inherit" align={align}>
            {errors.root.message}
          </Text>
        </FormHelperText>
      )}

      {isMulti && !disabled && (
        <LoadingButton
          css={[
            css`
              margin-top: 8px;
            `,
            variant === 'carousel' &&
              css`
                margin-left: 12px;
                margin-right: 12px;
              `,
          ]}
          type="submit"
          color="primary"
          size="large"
          variant="filled"
          disabled={!isValid}
          loading={isSubmitting}
        >
          <FormattedMessage
            id="actions.submit"
            defaultMessage="Submit"
            description="Submit button text"
          />
        </LoadingButton>
      )}
    </form>
  );
};
