import { atom, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { Scalars } from '../gql';
import { UseEditFileFormOptions } from './use-edit-file-form';

interface EditFileState
  extends Pick<UseEditFileFormOptions, 'fileId' | 'onSubmit' | 'projectId'> {
  open: boolean;
}

export const editFileAtom = atomFamily(
  (projectId: Scalars<'ID'>) =>
    atom<EditFileState>({
      open: false,
      fileId: null,
      projectId,
    }),
  (a, b) => a === b,
);

export const useEditFile = (projectId: Scalars<'ID'>) => {
  return useSetAtom(editFileAtom(projectId));
};
