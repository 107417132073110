import { FunctionComponent } from 'react';
import { KnowledgeBaseNodeSkeleton } from '../knowledge-base-node/knowledge-base-node-skeleton';

interface KnowledgeBaseTreeViewSkeletonProps {
  count?: number;
}

export const KnowledgeBaseTreeViewSkeleton: FunctionComponent<
  KnowledgeBaseTreeViewSkeletonProps
> = props => {
  const { count = 5 } = props;

  return (
    <>
      {new Array(count).fill(null).map((_, index) => {
        return <KnowledgeBaseNodeSkeleton key={index} />;
      })}
    </>
  );
};
