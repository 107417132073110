import { toast } from '@allganize/ui-toast';
import { useFormatErrorMessage } from '@allganize/utils-graphql';
import { useMutation, useSubscription } from '@apollo/client/react';
import { compact } from 'lodash-es';
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { gql, readFragment } from '../gql';
import { AppContext } from './app-context';
import {
  GenerateInput,
  OutputContext,
  OutputData_SingleActionAppDocumentMapReduceResultFragment,
} from './output-context';

const OutputProviderSubscription = gql(
  `
  subscription OutputProviderSubscription(
    $where: ProjectWhereUniqueInput!
    $accessToken: String!
    $publicToken: String!
  ) {
    singleActionAppResultPublic(
      projectWhere: $where
      accessToken: $accessToken
      token: $publicToken
    ) {
      id
      output
      outputOriginal
      isComplete
      documentResults {
        ...OutputData_SingleActionAppDocumentMapReduceResultFragment
      }
    }
  }
`,
  [OutputData_SingleActionAppDocumentMapReduceResultFragment],
);

const GenerateSingleActionAppResultPublicMutation = gql(`
  mutation GenerateSingleActionAppResultPublicMutation(
    $where: ProjectWhereUniqueInput!
    $appId: ID!
    $accessToken: String!
    $publicToken: String!
    $userInput: JSONString
    $singleActionAppSelectedDocumentInputInfo: [SingleActionAppSelectedDocumentInputInfo!]
  ) {
    generateSingleActionAppResultPublic(
      projectWhere: $where
      singleActionAppId: $appId
      accessToken: $accessToken
      token: $publicToken
      userInput: $userInput
      singleActionAppSelectedDocumentInputInfo: $singleActionAppSelectedDocumentInputInfo
    ) {
      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`);

const SendSingleActionAppResultToConversationMutation = gql(`
  mutation SendSingleActionAppResultToConversationMutation(
    $conversationId: ID!
    $singleActionAppResultId: ID!
  ) {
    sendSingleActionAppResultToConversation(
      conversationId: $conversationId
      singleActionAppResultId: $singleActionAppResultId
    ) {
      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`);

const StopSingleActionAppResultPublicMutation = gql(`
  mutation StopSingleActionAppResultPublicMutation(
    $where: ProjectWhereUniqueInput!
    $singleActionAppResultId: ID!
    $publicToken: String!
  ) {
    stopSingleActionAppResultPublic(
      projectWhere: $where
      singleActionAppResultId: $singleActionAppResultId
      token: $publicToken
    ) {
      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`);

const UpdateSingleActionAppResultPublicMutation = gql(`
  mutation UpdateSingleActionAppResultPublicMutation(
    $where: ProjectWhereUniqueInput!
    $resultId: ID!
    $updatedOutput: String!
    $token: String!
    $accessToken: String
  ) {
    updateSingleActionAppResultPublic(
      projectWhere: $where
      singleActionAppResultId: $resultId
      updatedOutput: $updatedOutput
      token: $token
      accessToken: $accessToken
    ) {
      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`);

interface OutputProviderProps {
  conversationIdToSendResult: string;
  children?: ReactNode;
  onGenerate?: () => void;
}

export const OutputProvider: FunctionComponent<OutputProviderProps> = ({
  conversationIdToSendResult,
  children,
  onGenerate,
}) => {
  const formatErrorMessage = useFormatErrorMessage();
  const intl = useIntl();
  const {
    data: appData,
    publicToken,
    accessToken,
    generateAccessToken,
  } = useContext(AppContext);
  const appId = appData?.id || '';
  const projectId = appData?.projectId || '';

  const { data } = useSubscription(OutputProviderSubscription, {
    skip: !publicToken || !accessToken || !projectId,
    variables: {
      where: { id: projectId },
      publicToken,
      accessToken,
    },
    onError: err => toast.error(formatErrorMessage(err)),
  });
  const isComplete = data?.singleActionAppResultPublic?.isComplete;

  const [sendResultToConversation] = useMutation(
    SendSingleActionAppResultToConversationMutation,
    {
      onCompleted: res => {
        const error = compact(
          res.sendSingleActionAppResultToConversation?.errors,
        )[0];
        if (error) {
          toast.error(formatErrorMessage(error));
        }
      },
      onError: err => toast.error(formatErrorMessage(err)),
    },
  );

  useEffect(() => {
    const singleActionAppResultId = data?.singleActionAppResultPublic?.id;
    if (isComplete && conversationIdToSendResult && singleActionAppResultId) {
      sendResultToConversation({
        variables: {
          conversationId: conversationIdToSendResult,
          singleActionAppResultId,
        },
      });
    }
  }, [isComplete]);

  const outputData = useMemo(
    () => ({
      id: data?.singleActionAppResultPublic?.id || '',
      output: data?.singleActionAppResultPublic?.output || '',
      outputOriginal: data?.singleActionAppResultPublic?.outputOriginal || '',
      documentResults: [
        ...readFragment(
          OutputData_SingleActionAppDocumentMapReduceResultFragment,
          data?.singleActionAppResultPublic?.documentResults || [],
        ),
      ],
    }),
    [data?.singleActionAppResultPublic],
  );

  const [mutate, { loading, called, reset }] = useMutation(
    GenerateSingleActionAppResultPublicMutation,
    {
      onCompleted: res => {
        const error = compact(
          res.generateSingleActionAppResultPublic?.errors,
        )[0];

        if (error) {
          const errorMessage = formatErrorMessage(error);

          if (error.key === gql.scalar('MallyError', 'INVALID_ACCESS_TOKEN')) {
            toast.warning(errorMessage);
            generateAccessToken();
          } else {
            toast.error(errorMessage);
          }
        }
      },
      onError: err => toast.error(formatErrorMessage(err)),
    },
  );

  useEffect(() => {
    reset();
  }, [publicToken, reset]);

  const startGenerate = useCallback(
    ({
      userInput,
      singleActionAppSelectedDocumentInputInfo,
    }: GenerateInput) => {
      mutate({
        variables: {
          where: { id: projectId },
          appId: appId,
          accessToken,
          publicToken,
          userInput,
          singleActionAppSelectedDocumentInputInfo,
        },
      });
      onGenerate?.();
    },
    [projectId, appId, accessToken, publicToken, mutate, onGenerate],
  );

  const [stopGenerate] = useMutation(StopSingleActionAppResultPublicMutation, {
    variables: {
      where: { id: projectId },
      publicToken,
      singleActionAppResultId: outputData.id,
    },
    onCompleted: res => {
      const error = compact(res.stopSingleActionAppResultPublic?.errors)[0];
      if (error) {
        toast.error(formatErrorMessage(error));
      }
    },
    onError: err => toast.error(formatErrorMessage(err)),
  });

  const [updateMutate, { loading: updating }] = useMutation(
    UpdateSingleActionAppResultPublicMutation,
    {
      onCompleted: res => {
        const error = compact(res.updateSingleActionAppResultPublic?.errors)[0];
        if (error) {
          toast.error(formatErrorMessage(error));
        }
      },
      onError: err => toast.error(formatErrorMessage(err)),
    },
  );

  const update = useCallback(
    async (updatedOutput: string) => {
      await updateMutate({
        variables: {
          where: {
            id: projectId,
          },
          resultId: outputData.id,
          updatedOutput,
          accessToken,
          token: publicToken,
        },
      });
    },
    [projectId, accessToken, publicToken, outputData.id, updateMutate],
  );

  const [generateDisabled, setGenerateDisabled] = useState(false);

  const onCopy = useCallback(() => {
    toast.success(
      intl.formatMessage({
        id: 'single-action.final-output.copy-toast',
        defaultMessage: 'Copied to clipboard',
        description: 'single action final output copy toast message',
      }),
    );
  }, [intl]);

  const output = useMemo(() => {
    return {
      data: outputData,
      called,
      loading,
      updating,
      updated: outputData.output !== outputData.outputOriginal,
      update,
      revert: () => update(outputData.outputOriginal),
      onCopy,
      stopGenerate,
      startGenerate,
      setGenerateDisabled: (disabled: boolean) => setGenerateDisabled(disabled),
      generateDisabled,
    };
  }, [
    outputData,
    called,
    loading,
    updating,
    update,
    onCopy,
    stopGenerate,
    startGenerate,
    setGenerateDisabled,
    generateDisabled,
  ]);

  return (
    <OutputContext.Provider value={output}>{children}</OutputContext.Provider>
  );
};
