import {
  AutoHashtagChip,
  AutoHashtagChipProps,
} from '@allganize/hashtag-auto-hashtag';
import { useUpdateKnowledgeBaseAutoHashtag } from '@allganize/knowledge-base-actions';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment, ResultOf, Scalars } from '../gql';

export const AutoHashtag_KnowledgeBaseAutoHashtagFragment = gql(`
  fragment AutoHashtag_KnowledgeBaseAutoHashtagFragment on KnowledgeBaseAutoHashtag {
    id

    autoHashtag {
      id
      hashtag
    }
  }
`);

export interface AutoHashtagProps
  extends Pick<
    AutoHashtagChipProps<
      ResultOf<typeof AutoHashtag_KnowledgeBaseAutoHashtagFragment>
    >,
    'onClick'
  > {
  autoHashtag: FragmentOf<typeof AutoHashtag_KnowledgeBaseAutoHashtagFragment>;
  projectId: Scalars<'ID'>;
}

export const AutoHashtag: FunctionComponent<AutoHashtagProps> = props => {
  const { autoHashtag: autoHashtagProp, onClick, projectId } = props;
  const autoHashtag = readFragment(
    AutoHashtag_KnowledgeBaseAutoHashtagFragment,
    autoHashtagProp,
  );
  const updateKnowledgeBaseAutoHashtag = useUpdateKnowledgeBaseAutoHashtag();

  const handleStateChange = async (
    tag: ResultOf<typeof AutoHashtag_KnowledgeBaseAutoHashtagFragment>,
    state: Scalars<'AutoHashtagState'>,
  ) => {
    await updateKnowledgeBaseAutoHashtag({
      where: { id: tag.id, projectWhere: { id: projectId } },
      state,
    });
  };

  return (
    <AutoHashtagChip<
      ResultOf<typeof AutoHashtag_KnowledgeBaseAutoHashtagFragment>
    >
      autoHashtag={autoHashtag}
      onClick={onClick}
      onStateChange={handleStateChange}
    />
  );
};
