import { Optional } from '@allganize/types';
import { Skeleton } from '@allganize/ui-skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableCellPadding,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@allganize/ui-table';
import { useTheme } from '@allganize/ui-theme';
import { css, SerializedStyles } from '@emotion/react';
import { flexRender } from '@tanstack/react-table';
import {
  observeWindowOffset,
  observeWindowRect,
  useVirtualizer,
  VirtualizerOptions,
  windowScroll,
} from '@tanstack/react-virtual';
import { throttle } from 'lodash-es';
import { useEffect } from 'react';
import { FragmentOf, readFragment, ResultOf, Scalars } from '../gql';
import {
  KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
  KnowledgeBaseTableColumnKey,
} from './knowledge-base-table-columns';

const aligns: Partial<
  Record<KnowledgeBaseTableColumnKey, TableCellProps['align']>
> = {
  actions: 'right',
};

const paddings: Partial<
  Record<KnowledgeBaseTableColumnKey, Record<'head' | 'body', TableCellPadding>>
> = {
  select: { head: 'checkbox', body: 'checkbox' },
  location: { head: 'normal', body: 'button' },
  actions: { head: 'button', body: 'button' },
};

const sizes: Record<KnowledgeBaseTableColumnKey, SerializedStyles> = {
  select: css`
    flex-shrink: 0;
    width: 56px;
  `,
  name: css`
    flex-grow: 2;
    width: 50%;
    min-width: 196px;
  `,
  'last-updated': css`
    flex-grow: 1;
    width: 25%;
    min-width: 80px;
    max-width: 240px;
  `,
  status: css`
    flex-shrink: 0;
    width: 100px;
  `,
  location: css`
    flex-grow: 1;
    width: 25%;
    min-width: 64px;
    max-width: 200px;
  `,
  actions: css`
    flex-shrink: 0;
    width: 64px;
  `,
};

const defaultVirtualizer = {
  getScrollElement() {
    return typeof document !== 'undefined' ? window : null;
  },
  initialOffset() {
    return typeof document !== 'undefined' ? window.scrollY : 0;
  },
  observeElementOffset: observeWindowOffset,
  observeElementRect: observeWindowRect,
  scrollToFn: windowScroll,
} satisfies Optional<
  VirtualizerOptions<Window, HTMLTableRowElement>,
  | 'count'
  | 'estimateSize'
  | 'observeElementOffset'
  | 'observeElementRect'
  | 'scrollToFn'
>;

export interface KnowledgeBaseTableBaseProps<TScrollElement extends Element> {
  hasNextPage?: boolean;
  highlighted?: Scalars<'ID'>;
  onNextPageLoad?(): void;
  onRowClick?(
    edge: ResultOf<typeof KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment>,
  ): void;
  onRowDoubleClick?(
    edge: ResultOf<typeof KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment>,
  ): void;
  pageSize?: number;
  table: import('@tanstack/react-table').Table<
    FragmentOf<typeof KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment>
  >;
  virtualizer?: Optional<
    VirtualizerOptions<TScrollElement, HTMLTableRowElement>,
    | 'count'
    | 'estimateSize'
    | 'observeElementOffset'
    | 'observeElementRect'
    | 'scrollToFn'
  >;
}

export const KnowledgeBaseTableBase = <TScrollElement extends Element>(
  props: KnowledgeBaseTableBaseProps<TScrollElement>,
) => {
  const {
    hasNextPage = false,
    highlighted,
    onNextPageLoad,
    onRowClick,
    onRowDoubleClick,
    pageSize = 20,
    table,
    virtualizer = defaultVirtualizer as unknown as NonNullable<
      typeof props.virtualizer
    >,
  } = props;
  const theme = useTheme();
  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer<TScrollElement, HTMLTableRowElement>({
    count: hasNextPage ? rows.length + pageSize : rows.length,
    estimateSize: () => 56,
    overscan: Math.trunc(1.5 * pageSize),
    ...virtualizer,
  });

  const items = rowVirtualizer.getVirtualItems();
  const lastItem = items[items.length - 1];

  useEffect(() => {
    if (!lastItem) {
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasNextPage) {
      onNextPageLoad?.();
    }
  }, [hasNextPage, lastItem, onNextPageLoad, rows.length]);

  return (
    <Table
      css={css`
        width: 100%;
        display: grid;
      `}
    >
      <TableHead
        css={css`
          position: sticky;
          top: 0;
          z-index: 1;
          display: grid;
        `}
      >
        {table.getHeaderGroups().map(headerGroup => {
          return (
            <TableRow
              key={headerGroup.id}
              css={css`
                display: flex;
                width: 100%;
                background-color: ${theme.palette.unstable_background.white};
              `}
            >
              {headerGroup.headers.map(header => {
                const rendered = header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    );

                return (
                  <TableCell
                    key={header.id}
                    css={[
                      css`
                        position: relative;
                        display: flex;
                        align-items: center;
                      `,
                      sizes[header.id as KnowledgeBaseTableColumnKey],
                    ]}
                    padding={
                      paddings[header.id as KnowledgeBaseTableColumnKey]?.head
                    }
                    align={aligns[header.id as KnowledgeBaseTableColumnKey]}
                    colSpan={header.colSpan}
                    sortDirection={
                      (header.column.getCanSort() &&
                        header.column.getIsSorted()) ||
                      undefined
                    }
                  >
                    {header.column.getCanSort() ? (
                      <TableSortLabel
                        onClick={header.column.getToggleSortingHandler()}
                        direction={header.column.getIsSorted() || undefined}
                        active={!!header.column.getIsSorted()}
                      >
                        {rendered}

                        {header.column.getIsSorted() && (
                          <span
                            css={css`
                              border: 0;
                              clip: rect(0 0 0 0);
                              height: 1px;
                              margin: -1px;
                              overflow: hidden;
                              padding: 0;
                              position: absolute;
                              top: 20px;
                              width: 1px;
                            `}
                          >
                            {header.column.getIsSorted() === 'asc' &&
                              'sorted ascending'}
                            {header.column.getIsSorted() === 'desc' &&
                              'sorted descending'}
                          </span>
                        )}
                      </TableSortLabel>
                    ) : (
                      rendered
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableHead>

      <TableBody
        css={css`
          position: relative;
          display: grid;
        `}
        style={{
          height: rowVirtualizer.getTotalSize(),
        }}
      >
        {items.map(virtualRow => {
          const isLoaderRow = virtualRow.index > rows.length - 1;

          if (isLoaderRow) {
            const lastRow = rows[rows.length - 1];

            return (
              <TableRow
                key={virtualRow.index}
                data-index={virtualRow.index}
                ref={rowVirtualizer.measureElement}
                hover
                css={css`
                  display: flex;
                  position: absolute;
                  width: 100%;
                `}
                style={{
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                {lastRow.getVisibleCells().map(cell => {
                  return (
                    <TableCell
                      key={cell.id}
                      css={[
                        css`
                          display: flex;
                          align-items: center;
                        `,
                        sizes[cell.column.id as KnowledgeBaseTableColumnKey],
                      ]}
                      padding={
                        paddings[cell.column.id as KnowledgeBaseTableColumnKey]
                          ?.body
                      }
                      align={
                        aligns[cell.column.id as KnowledgeBaseTableColumnKey]
                      }
                    >
                      <Skeleton
                        variant="text"
                        textVariant="body14"
                        width="100%"
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          }

          const row = rows[virtualRow.index];

          const handleClick = throttle(
            (ev: React.MouseEvent<HTMLTableRowElement>) => {
              if (ev.detail === 2) {
                onRowDoubleClick?.(
                  readFragment(
                    KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
                    row.original,
                  ),
                );
                return;
              }

              onRowClick?.(
                readFragment(
                  KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
                  row.original,
                ),
              );
            },
            600,
            { trailing: true, leading: true },
          );

          return (
            <TableRow
              key={row.id}
              data-index={virtualRow.index}
              ref={rowVirtualizer.measureElement}
              hover
              selected={row.id === highlighted}
              css={css`
                display: flex;
                position: absolute;
                width: 100%;
              `}
              style={{
                transform: `translateY(${virtualRow.start}px)`,
              }}
              onClick={handleClick}
            >
              {row.getVisibleCells().map(cell => {
                return (
                  <TableCell
                    key={cell.id}
                    css={[
                      css`
                        display: flex;
                        align-items: center;
                      `,
                      sizes[cell.column.id as KnowledgeBaseTableColumnKey],
                    ]}
                    padding={
                      paddings[cell.column.id as KnowledgeBaseTableColumnKey]
                        ?.body
                    }
                    align={
                      aligns[cell.column.id as KnowledgeBaseTableColumnKey]
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
