import { atom, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { Scalars } from '../gql';
import { UseEditFolderFormOptions } from './use-edit-folder-form';

interface EditFolderState extends UseEditFolderFormOptions {
  open: boolean;
}

export const editFolderAtom = atomFamily(
  (projectId: Scalars<'ID'>) =>
    atom<EditFolderState>({
      open: false,
      folderId: null,
      projectId,
    }),
  (a, b) => a === b,
);

export const useEditFolder = (projectId: Scalars<'ID'>) => {
  return useSetAtom(editFolderAtom(projectId));
};
