import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';

export interface PanelItemProps {
  label: React.ReactNode;
  action?: React.ReactNode;
  children?: React.ReactNode;
}

export const PanelItem: FunctionComponent<PanelItemProps> = props => {
  const { label, action, children } = props;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin: 16px 0;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 20px;
        `}
      >
        <Text
          variant="title12"
          css={css`
            flex-grow: 1;
            flex-shrink: 0;
          `}
        >
          {label}
        </Text>
        {action}
      </div>

      {children}
    </div>
  );
};
