import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { IntlShape, useIntl } from 'react-intl';
import {
  AlliGraphQLError,
  AlliGraphQLErrorFragment,
} from '../alli-graphql-error/alli-graphql-error';
import { isNetworkError } from '../is-network-error/is-network-error';

type FormatMessageOptions = NonNullable<
  Parameters<IntlShape['formatMessage']>[2]
>;

export const useFormatErrorMessage = () => {
  const intl = useIntl();

  const formatErrorMessage = (
    error?: Error | AlliGraphQLErrorFragment,
    options?: FormatMessageOptions,
  ) => {
    if (!error) {
      const { values, ...descriptor } =
        AlliGraphQLError.getMallyErrorMessageDescriptor({
          __typename: 'Error',
          key: UserQueryTypes.MallyError.UNKNOWN,
          message: null,
          info: null,
          field: null,
        });
      return intl.formatMessage(descriptor, values, options);
    }

    if (error instanceof Error) {
      if (isNetworkError(error)) {
        return intl.formatMessage(
          {
            id: 'Errors.network',
            defaultMessage: 'A network error occurred. Please try again.',
            description: 'Network error message',
          },
          undefined,
          options,
        );
      }

      return intl.formatMessage(
        {
          id: `MallyError.${error.message}`,
          defaultMessage: error.message,
          description: `Error message for ${error.message}`,
        },
        undefined,
        options,
      );
    }

    const { values, ...descriptor } =
      AlliGraphQLError.getMallyErrorMessageDescriptor(error);

    return intl.formatMessage(descriptor, values, options);
  };

  return formatErrorMessage;
};
