import { FunctionComponent } from 'react';
import { ChatListRoot } from '../chat-list/chat-list-root';
import { ChatListLoadingState } from '../chat-list/chat-list-loading-state';
import { AlliBranding } from '../alli-branding/alli-branding';
import { analytics } from '../analytics';

export const ConversationDetailLoadingState: FunctionComponent<{
  conversationId?: string | number;
}> = ({ conversationId }) => {
  return (
    <ChatListRoot>
      <ChatListLoadingState />

      <AlliBranding
        onClick={() =>
          analytics.track('click_alli_branding', {
            conversationId,
            source: 'conversation-detail',
          })
        }
      />
    </ChatListRoot>
  );
};
