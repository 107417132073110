import { FunctionComponent } from 'react';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';

export const ProcessStatePrimaryText_KnowledgeBaseNodeFragment = gql(`
  fragment ProcessStatePrimaryText_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    ...on KnowledgeBaseNodeFile {
      processState
      processRetryAt
    }
  }
`);

interface ProcessStatePrimaryTextProps {
  node: FragmentOf<typeof ProcessStatePrimaryText_KnowledgeBaseNodeFragment>;
}

export const ProcessStatePrimaryText: FunctionComponent<
  ProcessStatePrimaryTextProps
> = props => {
  const { node: nodeProp } = props;
  const node = readFragment(
    ProcessStatePrimaryText_KnowledgeBaseNodeFragment,
    nodeProp,
  );

  if (node.__typename !== 'KnowledgeBaseNodeFile') {
    return null;
  }

  if (node.processState === null) {
    return null;
  }

  switch (node.processState) {
    case gql.scalar('ProcessStateEnum', 'PARSING_FAIL'):
      return (
        <FormattedMessage
          id="ProcessState.PARSING_FAIL"
          defaultMessage="PARSING_FAIL"
          description="ProcessState message for PARSING_FAIL"
        />
      );
    case gql.scalar('ProcessStateEnum', 'COMPLETED'):
      return (
        <FormattedMessage
          id="ProcessState.COMPLETED"
          defaultMessage="COMPLETED"
          description="ProcessState message for COMPLETED"
        />
      );
    case gql.scalar('ProcessStateEnum', 'RETRYING'):
      return (
        <FormattedMessage
          id="ProcessState.RETRYING"
          defaultMessage="Temporary error occurred, will resume in {processRetryAt}."
          description="ProcessState message for RETRYING"
          values={{
            processRetryAt: node.processRetryAt !== null && (
              <FormattedRelativeTime
                value={(node.processRetryAt - Date.now()) / 1000}
                numeric="auto"
                updateIntervalInSeconds={60}
              />
            ),
          }}
        />
      );
    default:
      return (
        <FormattedMessage
          id={`ProcessState.${node.processState}`}
          defaultMessage={node.processState}
          description={`ProcessState message for ${node.processState}`}
        />
      );
  }
};
