import { useProject } from '@allganize/alli-works-project';
import { useEventCallback } from '@allganize/hooks';
import {
  useDeleteKnowledgeBaseNodes,
  useDownloadKnowledgeBaseNodes,
} from '@allganize/knowledge-base-actions';
import {
  KnowledgeBaseSidePanel_ProjectFragment,
  SidePanel,
  SidePanelActionsProps,
  SidePanelLoading,
  SidePanelProps,
} from '@allganize/knowledge-base-side-panel';
import { IconButton } from '@allganize/ui-button';
import { Drawer, drawerClasses } from '@allganize/ui-drawer';
import { IcClose } from '@allganize/ui-icons';
import { Scroller, scrollerClasses } from '@allganize/ui-scroller';
import { useSuspenseQuery } from '@apollo/client/react';
import { css } from '@emotion/react';
import { useSetAtom } from 'jotai';
import { FunctionComponent, Suspense, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSearchParams } from 'react-router-dom';
import { documentPreviewAtom } from '../document-preview/document-preview-state';
import { DocumentsLayoutContext } from '../documents-layout/documents-layout-context';
import { rightPanelWidth } from '../documents-layout/documents-layout.const';
import { gql, Scalars } from '../gql';
import { documentsDeleteEventEmitter } from '../utils/documents-delete-event-emitter';
import { DocumentsRightPanelErrorFallback } from './documents-right-panel-error-fallback';
import {
  DocumentsRightPanelAccessControlList,
  DocumentsRightPanelFileStatus,
  DocumentsRightPanelHashtags,
  DocumentsRightPanelLocationButton,
  DocumentsRightPanelQnaGeneration,
} from './documents-right-panel.slots';

const sidePanelSlots = {
  accessControlList: DocumentsRightPanelAccessControlList,
  fileStatus: DocumentsRightPanelFileStatus,
  hashtags: DocumentsRightPanelHashtags,
  locationButton: DocumentsRightPanelLocationButton,
  qnaGeneration: DocumentsRightPanelQnaGeneration,
};

const isEditable: NonNullable<
  Extract<
    NonNullable<NonNullable<SidePanelProps['slotProps']>['actions']>,
    { isEditable?: any }
  >['isEditable']
> = node => {
  return (
    node.ownership === gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL')
  );
};

const DocumentsRightPanelQuery = gql(
  `
  query DocumentsRightPanelQuery(
    $isUserQuery: Boolean!
  ) {
    project {
      id
      ...KnowledgeBaseSidePanel_ProjectFragment
    }
  }
`,
  [KnowledgeBaseSidePanel_ProjectFragment],
);

export interface DocumentsRightPanelProps {
  className?: string;
}

export const DocumentsRightPanel: FunctionComponent<
  DocumentsRightPanelProps
> = props => {
  const { className } = props;
  const project = useProject();
  const { data } = useSuspenseQuery(DocumentsRightPanelQuery, {
    variables: { isUserQuery: true },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const detailNodeId = searchParams.get('detail');
  const setDocumentPreview = useSetAtom(documentPreviewAtom(project.id));
  const context = useContext(DocumentsLayoutContext);
  const downloadKnowledgeBaseNodes = useDownloadKnowledgeBaseNodes();
  const deleteKnowledgeBaseNodes = useDeleteKnowledgeBaseNodes();

  const handleClose = () => {
    setSearchParams(
      prev => {
        prev.delete('detail');
        return prev;
      },
      { replace: true },
    );
  };

  const handleDownload = useEventCallback<
    NonNullable<SidePanelActionsProps['onDownload']>
  >(async node => {
    await downloadKnowledgeBaseNodes({
      ...(await context.getDownloadKnowledgeBaseNodeOptions()),
      projectId: project.id,
      nodes: [node],
    });
  });

  const handleDelete = useEventCallback(async (nodeId: Scalars<'ID'>) => {
    const result = await deleteKnowledgeBaseNodes({
      projectId: project.id,
      nodeIds: [nodeId],
    });

    if (!result.success) {
      return;
    }

    documentsDeleteEventEmitter.emit('delete', {
      projectId: project.id,
      nodeIds: [nodeId],
    });

    handleClose();
  });

  const handleThumbnailClick = useEventCallback((nodeId: Scalars<'ID'>) => {
    setDocumentPreview({ nodeId });
  });

  if (!data.project) {
    throw new Error('Project not found');
  }

  if (!detailNodeId) {
    return null;
  }

  const closeButton = (
    <IconButton edge="end" onClick={handleClose}>
      <IcClose />
    </IconButton>
  );

  return (
    <Drawer
      css={[
        css`
          overflow: hidden;

          .${drawerClasses.paper} {
            position: relative;
            width: ${rightPanelWidth}px;
            overflow-x: hidden;
          }
        `,
      ]}
      className={className}
      open
      onClose={handleClose}
      elevation={0}
      anchor="right"
      variant="persistent"
    >
      <Scroller
        css={css`
          height: 100%;

          .${scrollerClasses.content} {
            min-height: 100%;
            display: flex;
            flex-direction: column;
          }
        `}
        forceVisible="y"
      >
        <ErrorBoundary
          fallback={<DocumentsRightPanelErrorFallback onClose={handleClose} />}
        >
          <Suspense fallback={<SidePanelLoading />}>
            <SidePanel
              project={data.project}
              knowledgeBaseId={detailNodeId}
              moreActions={closeButton}
              slots={sidePanelSlots}
              slotProps={{
                actions: {
                  isEditable,
                  onDelete: handleDelete,
                  onDownload: handleDownload,
                },
                thumbnail: {
                  base: context.documentPreviewThumbnailBase,
                  onClick: handleThumbnailClick,
                },
              }}
            />
          </Suspense>
        </ErrorBoundary>
      </Scroller>
    </Drawer>
  );
};
