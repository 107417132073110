import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type LinkImageCarouselItemClassKey =
  | 'root'
  | 'imageContainer'
  | 'image'
  | 'contentContainer'
  | 'primary'
  | 'secondary'
  | 'tertiary';
export type LinkImageCarouselItemClasses = Record<
  LinkImageCarouselItemClassKey,
  string
>;

export const linkImageCarouselItemClasses: LinkImageCarouselItemClasses =
  generateUtilityClasses('LinkImageCarouselItem', [
    'root',
    'imageContainer',
    'image',
    'contentContainer',
    'primary',
    'secondary',
    'tertiary',
  ]);
