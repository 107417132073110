import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { AVATAR_DISPLAY_MAX_COUNT, AVATAR_OVERLAP_SIZE } from './constants';

export interface AccessUserContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  count: number;
}

export const AccessUserContainer = forwardRef<
  HTMLDivElement,
  AccessUserContainerProps
>((props, ref) => {
  const { count, children, ...others } = props;
  const restCount = count - AVATAR_DISPLAY_MAX_COUNT;

  return (
    <div
      css={css`
        display: inline-flex;
      `}
      {...others}
      ref={ref}
    >
      <div
        css={[
          css`
            width: ${AVATAR_OVERLAP_SIZE *
            Math.min(AVATAR_DISPLAY_MAX_COUNT, count)}px;
            display: grid;
            margin-right: 4px;
            grid-template-columns: repeat(auto-fit, ${AVATAR_OVERLAP_SIZE}px);
          `,
          restCount > 0 &&
            css`
              margin-right: 12px;
            `,
        ]}
      >
        {children}
      </div>
      {restCount > 0 && <Text variant="body12"> +{restCount}</Text>}
    </div>
  );
});
