import { toast } from '@allganize/ui-toast';
import { FormattedErrorMessage } from '@allganize/utils-graphql';
import { useMutation } from '@apollo/client/react';
import { gql, VariablesOf } from '../gql';

const UpdateKnowledgeBaseAutoHashtagMutation = gql(`
  mutation UpdateKnowledgeBaseAutoHashtag(
    $state: AutoHashtagState!
    $where: KnowledgeBaseAutoHashtagWhereUniqueInput!
  ) {
    updateKnowledgeBaseAutoHashtag(state: $state, where: $where) {
      updated {
        id
        state

        autoHashtag {
          id
          hashtag
        }
      }

      knowledgeBaseNode {
        id

        ...on KnowledgeBaseNodeFile {
          id
          hashtags

          autoHashtags {
            id
            state

            autoHashtag {
              id
              hashtag
            }
          }
        }
      }

      errors {
        __typename
        key
        message
        field
        info
      }
    }
  }
`);

export const useUpdateKnowledgeBaseAutoHashtag = () => {
  const [mutate] = useMutation(UpdateKnowledgeBaseAutoHashtagMutation, {
    onCompleted(result) {
      if (result.updateKnowledgeBaseAutoHashtag?.knowledgeBaseNode) {
        return;
      }

      if (result.updateKnowledgeBaseAutoHashtag?.errors?.[0]) {
        toast.error(
          <FormattedErrorMessage
            error={result.updateKnowledgeBaseAutoHashtag.errors[0]}
          />,
        );

        return;
      }

      toast.error(<FormattedErrorMessage />);
    },
    onError(err) {
      toast.error(<FormattedErrorMessage error={err} />);
    },
  });

  const updateKnowledgeBaseAutoHashtag = (
    variables: VariablesOf<typeof UpdateKnowledgeBaseAutoHashtagMutation>,
  ) => {
    return mutate({ variables });
  };

  return updateKnowledgeBaseAutoHashtag;
};
