import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useContext } from 'react';
import { ChatCarouselContext } from '../chat-carousel/chat-carousel-context';
import { chatCarouselItemClasses } from './chat-carousel-item.classes';
import { ChatCarouselItemProps } from './chat-carousel-item.types';

export const ChatCarouselItem = forwardRef<
  HTMLDivElement,
  ChatCarouselItemProps
>((props, ref) => {
  const {
    classes,
    className,
    hasCarouselHeightBug: hasCarouselHeightBugProp,
    ...other
  } = props;
  const { hasCarouselHeightBug: hasCarouselHeightBugContext } =
    useContext(ChatCarouselContext);
  const hasCarouselHeightBug =
    hasCarouselHeightBugProp ?? hasCarouselHeightBugContext;

  return (
    <div
      css={[
        css`
          padding: 0 4px;
        `,
        hasCarouselHeightBug &&
          css`
            flex-grow: 1;
            display: inline-flex !important;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
          `,
      ]}
      {...other}
      className={clsx(
        chatCarouselItemClasses.root,
        {
          [chatCarouselItemClasses.hasCarouselHeightBug]: hasCarouselHeightBug,
        },
        classes?.root,
        {
          [classes?.hasCarouselHeightBug ?? '']: hasCarouselHeightBug,
        },
        className,
      )}
      ref={ref}
    />
  );
});
