import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import { forwardRef, useContext } from 'react';
import { ListItemButtonContext } from '../list-item-button';
import { listItemIconClasses } from './list-item-icon-classes';
import { ListItemIconProps } from './list-item-icon-type-map';

export const ListItemIcon = forwardRef<HTMLDivElement, ListItemIconProps>(
  (props, ref) => {
    const {
      classes,
      disabled: disabledProp,
      position = 'start',
      ...other
    } = props;
    const theme = useTheme();
    const listItemButtonContext = useContext(ListItemButtonContext);
    const disabled = disabledProp ?? listItemButtonContext.disabled ?? false;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiListItemIcon
            data-testid="list-item-icon"
            {...other}
            ref={ref}
            classes={{
              ...classes,
              root: cx(
                css`
                  min-width: auto;
                  color: ${theme.palette.foreground.default};
                `,
                position === 'start' &&
                  css`
                    margin-right: 8px;
                  `,
                position === 'end' &&
                  css`
                    margin-left: 8px;
                  `,
                disabled &&
                  css`
                    color: ${theme.palette.foreground.gray.disabled};
                  `,
                classes?.root,
              ),
              alignItemsFlexStart: cx(
                css`
                  margin-top: 4px;
                `,
                classes?.alignItemsFlexStart,
              ),
            }}
            className={cx(
              {
                [listItemIconClasses.disabled]: disabled,
                [classes?.disabled ?? '']: disabled,
              },
              other.className,
            )}
          />
        )}
      </ClassNames>
    );
  },
);
