import { Checkbox } from '@allganize/ui-checkbox';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@allganize/ui-form';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FileSelectionFormValues } from './use-file-selection-form';

export const ImagePdfField: FunctionComponent = () => {
  const { control } = useFormContext<FileSelectionFormValues>();
  const {
    field: { ref: fieldRef, ...field },
    fieldState,
  } = useController({ control, name: 'imagePdf' });

  return (
    <FormControl fullWidth error={fieldState.invalid} disabled={field.disabled}>
      <FormControlLabel
        {...field}
        checked={field.value}
        onChange={(ev, checked) => {
          field.onChange(checked);
        }}
        control={<Checkbox />}
        label={
          <FormattedMessage
            id="FAQ.Documents.UploadForm.imagePdf"
            defaultMessage="Handle PDF as image"
            description="FAQ documents upload form file delete button text"
          />
        }
      />

      {fieldState.error?.message && (
        <FormHelperText error>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  );
};
