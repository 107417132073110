import { timeout } from '@allganize/utils-timeout';
import { DownloadFileError } from './download-file-error';

export interface DownloadFileOptions extends RequestInit {
  url: string;
  download?: string;
}

export interface DownloadFileResult {
  downloadExceedance?: number;
  downloadLimitation?: number;
  filename?: string;
  result: Blob;
}

export const downloadFile = async ({
  url,
  download,
  ...init
}: DownloadFileOptions): Promise<DownloadFileResult> => {
  const resp = await fetch(url, init);
  const filename = resp.headers.get('filename') || undefined;
  const downloadExceedance =
    Number.parseInt(resp.headers.get('download-exceedance') || '', 10) ||
    undefined;
  const downloadLimitation =
    Number.parseInt(resp.headers.get('download-limitation') || '', 10) ||
    undefined;

  if (!resp.ok) {
    const { status, statusText } = resp;
    const body = await resp.json();
    throw new DownloadFileError(status, statusText, body.reason);
  }

  const blob = await resp.blob();

  const result = {
    result: blob,
    filename,
    downloadExceedance,
    downloadLimitation,
  } satisfies DownloadFileResult;

  const objectUrl = URL.createObjectURL(result.result);
  const anchorLink = document.createElement('a');
  anchorLink.style.display = 'none';
  anchorLink.href = objectUrl;
  anchorLink.download = download || 'true';
  anchorLink.click();

  await timeout(0);
  anchorLink.remove();
  URL.revokeObjectURL(objectUrl);
  return result;
};
