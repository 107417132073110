import {
  BaseTimePickerProps as MuiBaseTimePickerProps,
  BaseTimePickerSlotsComponent as MuiBaseTimePickerSlotsComponent,
  useTimePickerDefaultizedProps as useMuiTimePickerDefaultizedProps,
} from '@mui/x-date-pickers/TimePicker/shared';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import {
  BaseClockProps,
  BaseTimeValidationProps,
  DefaultizedProps,
  TimeViewWithMeridiem,
} from '../internals/models';
import { UncapitalizeObjectKeys } from '../internals/utils/slots-migration';
import { LocalizedComponent } from '../locales/utils/pickers-locale-text-api';
import { TimeClockSlotsComponentsProps } from '../time-clock';
import {
  ExportedTimePickerToolbarProps,
  TimePickerToolbar,
  TimePickerToolbarProps,
} from '../time-picker-toolbar';
import { TimeViewRendererProps } from '../time-view-renderers';

export interface BaseTimePickerSlotsComponent
  extends MuiBaseTimePickerSlotsComponent<Date> {
  /**
   * Custom component for the toolbar rendered above the views.
   * @default TimePickerToolbar
   */
  Toolbar?: React.JSXElementConstructor<TimePickerToolbarProps>;
}

export interface BaseTimePickerSlotsComponentsProps
  extends TimeClockSlotsComponentsProps {
  toolbar?: ExportedTimePickerToolbarProps;
}

export interface BaseTimePickerProps<TView extends TimeViewWithMeridiem>
  extends Omit<
    MuiBaseTimePickerProps<Date, TView>,
    'components' | 'componentsProps' | 'shouldDisableClock' | 'sx'
  > {
  /**
   * Overridable component slots.
   * @default {}
   */
  slots?: UncapitalizeObjectKeys<BaseTimePickerSlotsComponent>;
  /**
   * The props used for each component slot.
   * @default {}
   */
  slotProps?: BaseTimePickerSlotsComponentsProps;
  /**
   * Define custom view renderers for each section.
   * If `null`, the section will only have field editing.
   * If `undefined`, internally defined view will be the used.
   */
  viewRenderers?: Partial<
    PickerViewRendererLookup<
      Date | null,
      TView,
      TimeViewRendererProps<TView, BaseClockProps<TView>>,
      {}
    >
  >;
}

type UseTimePickerDefaultizedProps<
  TView extends TimeViewWithMeridiem,
  Props extends BaseTimePickerProps<TView>,
> = LocalizedComponent<
  Omit<
    DefaultizedProps<
      Props,
      'views' | 'openTo' | 'ampm' | keyof BaseTimeValidationProps
    >,
    'components' | 'componentsProps'
  >
>;

export const useTimePickerDefaultizedProps = <
  TView extends TimeViewWithMeridiem,
  Props extends BaseTimePickerProps<TView>,
>(
  props: Props,
  name: string,
): UseTimePickerDefaultizedProps<TView, Props> =>
  useMuiTimePickerDefaultizedProps<Date, TView, Props>(
    {
      ...props,
      slots: {
        toolbar: TimePickerToolbar,
        ...props.slots,
      },
    },
    name,
  );
