import { mapValues } from 'lodash-es';
import { AlliAppMarketEnvironment, Region } from './environment.types';

const apiBase: Record<Region, string> = {
  default: 'https://backend.alli.ai',
  ja: 'https://backend-ja.alli.ai',
  kr: 'https://backend-kr.alli.ai',
};

export const environment: AlliAppMarketEnvironment = {
  production: true,
  api: {
    base: apiBase,
    graphql: mapValues(apiBase, base => {
      return {
        http: `${base}/d/user/`,
        ws: `${base.replace('https://', 'wss://')}/d/ws/user`,
      };
    }),
    documentPreviewThumbnail: mapValues(apiBase, base => {
      return `${base}/webapi`;
    }),
    singleActionResultDownload: mapValues(apiBase, base => {
      return `${base}/download/single_action_result_public`;
    }),
  },
  mixpanel: {
    token: 'b84fcd8bfd53eba6ddd7325f992ce0bc',
  },
  pdfjs: {
    workerSrc: `https://sdk.alli.ai/pdfjs-dist/${process.env.NX_PUBLIC_PDFJS_VERSION}/legacy/build/pdf.worker.js`,
    cMapUrl: `https://sdk.alli.ai/pdfjs-dist/${process.env.NX_PUBLIC_PDFJS_VERSION}/cmaps/`,
    standardFontDataUrl: `https://sdk.alli.ai/pdfjs-dist/${process.env.NX_PUBLIC_PDFJS_VERSION}/standard_fonts/`,
  },
  sdk: {
    entrypoint(version: string) {
      return `https://sdk.alli.ai/${version}/alli.esm.min.js`;
    },
  },
  sentry: {
    dsn: 'https://9b531a571aebf30fbc6a7c63e8e4b28c@o133561.ingest.sentry.io/4505933220741120',
    enabled: true,
  },
};
