import { EditorState, isDraftInputEmpty } from '@allganize/draft-input';
import { useWindow } from '@allganize/hooks';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import { filesize } from 'filesize';
import { useEffect, useMemo } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';
import { gql, Scalars } from '../gql';

export interface AgentSelectOption {
  label: string;
  value: Scalars<'ID'>;
  avatar?: string;
}

interface FileInput {
  id: string;
  file: File;
}

export interface ContactAgentFormValues {
  agent: AgentSelectOption | null;
  message: EditorState;
  attachments: FileInput[];
}

const defaultContactAgentFormValues: ContactAgentFormValues = {
  agent: null,
  message: EditorState.createEmpty(),
  attachments: [],
};

type ContactAgentFormErrorField = FieldPath<ContactAgentFormValues> | 'root';
const contactAgentFormErrorFields: ContactAgentFormErrorField[] = [
  'agent',
  'message',
  'attachments',
  'root',
];

const isContactAgentFormErrorField = (
  field: any,
): field is ContactAgentFormErrorField =>
  contactAgentFormErrorFields.includes(field);

const errorMessages = defineMessages({
  'message-required': {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
  'attachments-type': {
    id: 'form.file.errors.type',
    defaultMessage:
      'Invalid file type selected. Allowed file types include the following: {type}.',
    description: 'File invalid type error message',
  },
  'attachments-size': {
    id: 'form.file.errors.max',
    defaultMessage: 'File too large (Max. {size})',
    description: 'File too large error message',
  },
  'attachments-max': {
    id: 'form.file.errors.max-count',
    defaultMessage: 'Select up to {count, number} files.',
    description: 'File max count error message',
  },
});

interface ContactAgentFormValidationOptions {
  attachments?: {
    maxCount: number;
    maxSize: number;
  };
  intl: IntlShape;
  window?: typeof globalThis;
}

const createValidationSchema = ({
  attachments = {
    maxCount: 5,
    maxSize: 0,
  },
  intl,
  window: win,
}: ContactAgentFormValidationOptions) => {
  const FileKlass = win?.File ?? File;

  const agentSelectOptionSchema = z.object({
    label: z.string(),
    value: z.union([z.string(), z.number()]),
    avatar: z.string().optional(),
  }) satisfies z.ZodSchema<AgentSelectOption>;

  return z.object({
    agent: agentSelectOptionSchema.nullable(),
    message: z.instanceof(EditorState).refine(schema => {
      return schema.getCurrentContent().hasText();
    }, intl.formatMessage(errorMessages['message-required'])),
    attachments: z
      .array(
        z.object({
          id: z.string(),
          file: z
            .union([z.instanceof(File), z.instanceof(FileKlass)])
            .refine(
              schema => {
                return schema.size <= attachments.maxSize;
              },
              intl.formatMessage(errorMessages['attachments-size'], {
                size: filesize(attachments.maxSize, {
                  output: 'string',
                  locale: intl.locale,
                  localeOptions: intl.formats.date,
                }),
              }),
            )
            .refine(schema => {
              return schema.type.startsWith('image/');
            }, intl.formatMessage(errorMessages['attachments-type'], { type: ['.png', '.jpg', '.jpeg'].join(', ') })),
        }),
      )
      .max(
        attachments.maxCount,
        intl.formatMessage(errorMessages['attachments-max'], {
          count: attachments.maxCount,
        }),
      ),
  }) satisfies z.ZodSchema<ContactAgentFormValues>;
};

export interface UseContactAgentFormOptions {
  defaultValues?: Partial<ContactAgentFormValues>;
  disabled?: boolean;
  onSubmit?(input: Scalars<'ContactAgentByEmailInput'>): Promise<void>;
  onTyping?(isTyping: boolean): void;
  validation?: Pick<ContactAgentFormValidationOptions, 'attachments'>;
}

export const useContactAgentForm = ({
  defaultValues,
  disabled,
  onSubmit,
  onTyping,
  validation,
}: UseContactAgentFormOptions) => {
  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();
  const { ref: formRef, window: win } = useWindow();
  const validationSchema = useMemo(
    () =>
      zodResolver(
        createValidationSchema({
          intl,
          window: win ?? undefined,
          ...validation,
        }),
      ),
    [intl, validation, win],
  );

  const form = useForm<ContactAgentFormValues>({
    mode: 'all',
    defaultValues: {
      ...defaultContactAgentFormValues,
      ...defaultValues,
    },
    disabled,
    resolver: validationSchema,
  });

  const { setError, watch } = form;

  const submit = async (values: ContactAgentFormValues) => {
    try {
      await onSubmit?.({
        targetAgent: values.agent?.value ?? null,
        message: values.message.getCurrentContent().getPlainText('\n'),
        attachments: values.attachments.map(file => ({
          file: file.file,
          mediaType: gql.scalar('MediaTypeEnum', 'IMAGE'),
          metaInfo: JSON.stringify({
            lastModified: file.file.lastModified,
            lastModifiedDate: (file as any).lastModifiedDate,
            name: file.file.name,
            size: file.file.size,
            type: file.file.type,
          }),
        })),
      });
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const errorGroups = err.groupErrorsByField({
          intl,
          isErrorField: isContactAgentFormErrorField,
        });

        // Set the errors in the form using setError
        Object.entries(errorGroups).forEach(([field, errors]) => {
          if (!errors) {
            return;
          }

          setError(field as ContactAgentFormErrorField, {
            message: errors[0]?.message,
          });
        });

        return;
      }

      if (err instanceof Error) {
        setError('root', {
          message: formatErrorMessage(err),
        });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: formatErrorMessage(),
      });
    }
  };

  useEffect(() => {
    if (disabled) {
      return;
    }

    const subscription = watch(values => {
      const hasAttachments = !!values.attachments?.length;
      const hasText = !isDraftInputEmpty(
        values.message instanceof EditorState ? values.message : null,
      );

      onTyping?.(hasAttachments || hasText);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [disabled, onTyping, watch]);

  return { form, formRef, submit };
};
