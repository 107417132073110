import { useEventCallback } from '@allganize/hooks';
import {
  ApolloCache,
  ApolloQueryResult,
  NormalizedCacheObject,
  RefetchQueriesOptions,
} from '@apollo/client/core';
import {
  useApolloClient,
  useQuery,
  useSubscription,
} from '@apollo/client/react';
import { ColumnSort, OnChangeFn, SortingState } from '@tanstack/react-table';
import { uniqBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import { gql, ResultOf, Scalars, VariablesOf } from '../gql';
import { KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment } from '../knowledge-base-table/knowledge-base-table-columns';

const KnowledgeBaseTableQuery = gql(
  `
  query KnowledgeBaseTableQuery(
    $project: ProjectWhereUniqueInput!
    $filter: KnowledgeBaseNodeFilter
    $order: KnowledgeBaseNodeOrder
    $first: Int
    $after: String
  ) {
    knowledgeBaseNodes(
      project: $project
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        cursor

        node {
          id

          ...on KnowledgeBaseNodeFile {
            processState
          }
        }

        ...KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`,
  [KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment],
);

const KnowledgeBaseTableSubscription = gql(`
  subscription KnowledgeBaseTableSubscription(
    $project: ProjectWhereUniqueInput!
    $ids: [ID!]
  ) {
    knowledgeBaseNodes(
      where: $project
      ids: $ids
    ) {
      id

      ...on KnowledgeBaseNodeFile {
        processState
      }
    }
  }
`);

const getOrder = (
  columnSort: ColumnSort,
): Scalars<'KnowledgeBaseNodeOrder'> => {
  switch (columnSort.id) {
    case 'name':
      return columnSort.desc
        ? gql.scalar('KnowledgeBaseNodeOrder', 'NAME_DESC')
        : gql.scalar('KnowledgeBaseNodeOrder', 'NAME_ASC');
    case 'last-updated':
      return columnSort.desc
        ? gql.scalar('KnowledgeBaseNodeOrder', 'UPDATED_AT_DESC')
        : gql.scalar('KnowledgeBaseNodeOrder', 'UPDATED_AT_ASC');
    default:
      return gql.scalar('KnowledgeBaseNodeOrder', 'UPDATED_AT_DESC');
  }
};

interface UseKnowledgeBaseTableQueryOptions {
  variables: Pick<
    VariablesOf<typeof KnowledgeBaseTableQuery>,
    'project' | 'filter' | 'first'
  >;
}

export const useKnowledgeBaseTableQuery = ({
  variables,
}: UseKnowledgeBaseTableQueryOptions) => {
  const [sorting, setSorting] = useState<ColumnSort>({
    id: 'last-updated',
    desc: true,
  });
  const order = useMemo(() => getOrder(sorting), [sorting]);
  const pageSize = variables.first ?? 50;
  const query = useQuery(KnowledgeBaseTableQuery, {
    variables: {
      ...variables,
      first: pageSize,
      order,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data, fetchMore, loading, refetch } = query;
  const hasNextPage = data?.knowledgeBaseNodes?.pageInfo.hasNextPage;
  const endCursor = data?.knowledgeBaseNodes?.pageInfo.hasNextPage
    ? data.knowledgeBaseNodes.pageInfo.endCursor
    : null;
  const isEmpty =
    !data?.knowledgeBaseNodes?.edges ||
    data.knowledgeBaseNodes.edges.length === 0;
  const incompleteIds = useMemo(() => {
    return (
      data?.knowledgeBaseNodes?.edges.reduce<Scalars<'ID'>[]>((acc, curr) => {
        if (curr.node.__typename !== 'KnowledgeBaseNodeFile') {
          return acc;
        }

        if (
          curr.node.processState ===
            gql.scalar('ProcessStateEnum', 'COMPLETED') ||
          curr.node.processState ===
            gql.scalar('ProcessStateEnum', 'PARSING_FAIL')
        ) {
          return acc;
        }

        return [...acc, curr.node.id];
      }, []) ?? []
    );
  }, [data?.knowledgeBaseNodes?.edges]);

  useSubscription(KnowledgeBaseTableSubscription, {
    variables: {
      project: variables.project,
      ids: incompleteIds,
    },
    skip: incompleteIds.length === 0,
  });

  const handleSortingChange: OnChangeFn<SortingState> = newValue => {
    setSorting(prev => {
      const newSorting =
        typeof newValue === 'function'
          ? newValue(prev ? [prev] : [])
          : newValue;

      if (newSorting.length === 0) {
        return {
          ...prev,
          desc: !prev.desc,
        };
      }

      return newSorting[0] ?? prev;
    });
  };

  const loadNextPage = useEventCallback(async () => {
    if (!endCursor) {
      return;
    }

    if (loading) {
      return;
    }

    await fetchMore({
      variables: {
        ...variables,
        first: pageSize,
        order,
        after: endCursor,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult.knowledgeBaseNodes) {
          return prev;
        }

        return {
          ...prev,
          ...fetchMoreResult,
          knowledgeBaseNodes: {
            ...prev.knowledgeBaseNodes,
            ...fetchMoreResult.knowledgeBaseNodes,
            edges: uniqBy(
              [
                ...(prev.knowledgeBaseNodes?.edges ?? []),
                ...fetchMoreResult.knowledgeBaseNodes.edges,
              ],
              edge => edge.cursor,
            ),
            pageInfo: {
              ...prev.knowledgeBaseNodes?.pageInfo,
              ...fetchMoreResult.knowledgeBaseNodes.pageInfo,
            },
          },
        };
      },
    });
  });

  return {
    edges: data?.knowledgeBaseNodes?.edges,
    hasNextPage,
    isEmpty,
    loading,
    onNextPageLoad: loadNextPage,
    onSortingChange: handleSortingChange,
    pageSize,
    refetch,
    sorting,
  };
};

export const useKnowledgeBaseTableQueryActions = () => {
  const client = useApolloClient();

  const refetch = async (
    options?: Omit<
      RefetchQueriesOptions<
        ApolloCache<NormalizedCacheObject>,
        Promise<ApolloQueryResult<ResultOf<typeof KnowledgeBaseTableQuery>>>
      >,
      'include'
    >,
  ) => {
    return client.refetchQueries({
      ...options,
      include: [KnowledgeBaseTableQuery],
    });
  };

  return { refetch };
};
