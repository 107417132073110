import { noop } from 'lodash-es';
import { createContext } from 'react';
import { Scalars } from '../gql';

export type Mode = 'user' | 'agent';

export type Option = {
  label: string;
  value: string | number;
};

export interface SingleActionInput {
  id: Scalars<'ID'>;
  title: string;
  inputType: Scalars<'SingleActionInputType'>;
  dynamicInputsItemName: string;
  placeholder: string;
  options: Option[];
  knowledgeBases: Option[];
}

interface SingleActionApp {
  id: Scalars<'ID'>;
  projectId: Scalars<'ID'>;
  name: string;
  description: string;
  singleActionInputs: SingleActionInput[];
}

interface App {
  mode: Mode;
  data: SingleActionApp | null;
  error: boolean;
  loading: boolean;
  publicToken: string;
  accessToken: string;
  refetch: () => void;
  generateAccessToken: () => void;
}

export const AppContext = createContext<App>({
  mode: 'user',
  data: null,
  error: false,
  loading: false,
  publicToken: '',
  accessToken: '',
  refetch: noop,
  generateAccessToken: noop,
});
