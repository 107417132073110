import { useMediaQuery } from '@allganize/hooks';
import { Button, LoadingButton } from '@allganize/ui-button';
import {
  Dialog,
  DialogActions,
  DialogCloseButton,
  DialogContent,
  DialogTitle,
} from '@allganize/ui-dialog';
import { FormHelperText } from '@allganize/ui-form';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import useSlot from '@mui/material/utils/useSlot';
import { FunctionComponent } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FeedbackSelectionField } from './feedback-selection-field';
import { SuggestedAnswerField } from './suggested-answer-field';
import { useUserFeedbackImproverForm } from './use-user-feedback-improver-form';
import { UserFeedbackImproverFormProps } from './user-feedback-improver-form.types';

export const UserFeedbackImproverForm: FunctionComponent<
  UserFeedbackImproverFormProps
> = props => {
  const {
    className,
    open,
    onClose,
    onSubmit,
    slotProps = {},
    slots = {},
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { form, submit } = useUserFeedbackImproverForm({
    async onSubmit(values) {
      await onSubmit(values);
      onClose?.();
    },
  });
  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    reset,
  } = form;

  const handleClose = () => {
    onClose?.();
    reset();
  };

  const [DialogSlot, dialogSlotProps] = useSlot('dialog', {
    elementType: Dialog,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    additionalProps: {
      maxWidth: 'sm' as const,
      fullWidth: true,
      fullScreen,
      open,
      onClose: handleClose,
      PaperProps: {
        component: 'form' as const,
        onSubmit: handleSubmit(submit),
      },
    },
    ownerState: props,
    className,
  });

  return (
    <DialogSlot {...dialogSlotProps}>
      <DialogTitle padding="close-button">
        <FormattedMessage
          id="user-feedback-improver-form.header"
          defaultMessage="Your feedback directly enhances quality"
          description="User Negative Feedback header title text"
        />

        <DialogCloseButton />
      </DialogTitle>

      <DialogContent>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 20px;
          `}
        >
          <FormProvider {...form}>
            <SuggestedAnswerField autoFocus={open} />
            <FeedbackSelectionField />
          </FormProvider>
        </div>

        {errors.root?.message && (
          <FormHelperText error>{errors.root?.message}</FormHelperText>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="ghost" size="large" onClick={handleClose}>
          <FormattedMessage
            id="actions.cancel"
            defaultMessage="Cancel"
            description="Cancel button text"
          />
        </Button>

        <LoadingButton
          type="submit"
          variant="filled"
          size="large"
          color="primary"
          disabled={!isValid}
          loading={isSubmitting}
        >
          <FormattedMessage
            id="actions.submit"
            defaultMessage="Submit"
            description="Submit button text"
          />
        </LoadingButton>
      </DialogActions>
    </DialogSlot>
  );
};
