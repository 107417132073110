import { Truncate } from '@allganize/truncate';
import { Checkbox } from '@allganize/ui-checkbox';
import { css } from '@emotion/react';
import { ColumnDef } from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';
import { z } from 'zod';
import { FragmentOf, gql, readFragment } from '../gql';
import { KnowledgeBaseActionsColumn } from '../knowledge-base-actions-column/knowledge-base-actions-column';
import { KnowledgeBaseActionsColumnHeader } from '../knowledge-base-actions-column/knowledge-base-actions-column-header';
import { KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment } from '../knowledge-base-actions-column/knowledge-base-actions-context';
import {
  KnowledgeBaseLastUpdatedColumn,
  KnowledgeBaseLastUpdatedColumn_KnowledgeBaseNodeFragment,
} from '../knowledge-base-last-updated-column/knowledge-base-last-updated-column';
import { KnowledgeBaseLastUpdatedColumnHeader } from '../knowledge-base-last-updated-column/knowledge-base-last-updated-column-header';
import {
  KnowledgeBaseLocationColumn,
  KnowledgeBaseLocationColumn_KnowledgeBaseNodeFragment,
} from '../knowledge-base-location-column/knowledge-base-location-column';
import {
  KnowledgeBaseNameColumn,
  KnowledgeBaseNameColumn_KnowledgeBaseNodeFragment,
} from '../knowledge-base-name-column/knowledge-base-name-column';
import {
  KnowledgeBaseStatusColumn,
  KnowledgeBaseStatusColumn_KnowledgeBaseNodeFragment,
} from '../knowledge-base-status-column/knowledge-base-status-column';

export const KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment = gql(
  `
  fragment KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment on KnowledgeBaseNodeEdge {
    cursor

    node {
      id
      ...KnowledgeBaseNameColumn_KnowledgeBaseNodeFragment
      ...KnowledgeBaseStatusColumn_KnowledgeBaseNodeFragment
      ...KnowledgeBaseLastUpdatedColumn_KnowledgeBaseNodeFragment
      ...KnowledgeBaseLocationColumn_KnowledgeBaseNodeFragment
      ...KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment
    }
  }
`,
  [
    KnowledgeBaseNameColumn_KnowledgeBaseNodeFragment,
    KnowledgeBaseStatusColumn_KnowledgeBaseNodeFragment,
    KnowledgeBaseLastUpdatedColumn_KnowledgeBaseNodeFragment,
    KnowledgeBaseLocationColumn_KnowledgeBaseNodeFragment,
    KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment,
  ],
);

const KNOWLEDGE_BASE_TABLE_COLUMN_KEYS = [
  'select',
  'name',
  'last-updated',
  'status',
  'location',
  'actions',
] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const knowledgeBaseTableColumnKeySchema = z.enum(
  KNOWLEDGE_BASE_TABLE_COLUMN_KEYS,
);
export type KnowledgeBaseTableColumnKey = z.infer<
  typeof knowledgeBaseTableColumnKeySchema
>;

export const columns = [
  // checkbox
  {
    id: 'select',
    enableResizing: false,
    enableSorting: false,
    header({ table }) {
      return (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={(ev, checked) => {
            table.toggleAllRowsSelected(checked);
          }}
        />
      );
    },
    cell({ row }) {
      return (
        <Checkbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          indeterminate={row.getIsSomeSelected()}
          onClick={ev => {
            ev.stopPropagation();
          }}
          onChange={(ev, checked) => {
            row.toggleSelected(checked);
          }}
        />
      );
    },
  },

  // name
  {
    id: 'name',
    accessorFn: row => row,
    header() {
      return (
        <Truncate
          css={css`
            word-break: break-all;
          `}
          clamp={1}
        >
          <FormattedMessage
            id="FAQ.Documents.KnowledgeBaseList.Name"
            defaultMessage="Name"
            description="FAQ documents knowledge base list name column text"
          />
        </Truncate>
      );
    },
    cell(info) {
      const row = readFragment(
        KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
        info.getValue(),
      );

      return (
        <KnowledgeBaseNameColumn
          node={readFragment(
            KnowledgeBaseNameColumn_KnowledgeBaseNodeFragment,
            row.node,
          )}
        />
      );
    },
  },

  // last updated
  {
    id: 'last-updated',
    accessorFn: row => row,
    header() {
      return <KnowledgeBaseLastUpdatedColumnHeader />;
    },
    cell(info) {
      const row = readFragment(
        KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
        info.getValue(),
      );

      return <KnowledgeBaseLastUpdatedColumn node={row.node} />;
    },
  },

  // state
  {
    id: 'status',
    accessorFn: row => row,
    enableResizing: false,
    enableSorting: false,
    header() {
      return (
        <Truncate
          css={css`
            word-break: break-all;
          `}
          clamp={1}
        >
          <FormattedMessage
            id="FAQ.Documents.KnowledgeBaseList.Status"
            defaultMessage="Status"
            description="FAQ documents knowledge base list status column text"
          />
        </Truncate>
      );
    },
    cell(info) {
      const row = readFragment(
        KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
        info.getValue(),
      );

      return <KnowledgeBaseStatusColumn node={row.node} />;
    },
  },

  // location
  {
    id: 'location',
    accessorFn: row => row,
    enableSorting: false,
    header() {
      return (
        <Truncate
          css={css`
            word-break: break-all;
          `}
          clamp={1}
        >
          <FormattedMessage
            id="knowledge-base-table.location"
            defaultMessage="Location"
            description="Knowledge base table location column text"
          />
        </Truncate>
      );
    },
    cell(info) {
      const row = readFragment(
        KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
        info.getValue(),
      );

      return (
        <KnowledgeBaseLocationColumn
          node={readFragment(
            KnowledgeBaseLocationColumn_KnowledgeBaseNodeFragment,
            row.node,
          )}
        />
      );
    },
  },

  // actions
  {
    id: 'actions',
    accessorFn: row => row,
    enableResizing: false,
    enableSorting: false,
    header() {
      return <KnowledgeBaseActionsColumnHeader />;
    },
    cell(info) {
      const row = readFragment(
        KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment,
        info.getValue(),
      );

      return <KnowledgeBaseActionsColumn node={row.node} />;
    },
  },
] satisfies ColumnDef<
  FragmentOf<typeof KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment>,
  FragmentOf<typeof KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment>
>[];
