import { toast } from '@allganize/ui-toast';
import { useFormatErrorMessage } from '@allganize/utils-graphql';
import { useMutation, useQuery } from '@apollo/client/react';
import { compact } from 'lodash-es';
import { useMemo } from 'react';

import { AppContext, Mode } from './app-context';
import { gql } from '../gql';

const AppProviderQuery = gql(`
  query AppProviderQuery($token: String!) {
    singleActionAppFromToken(token: $token) {
      id
      name
      description

      singleActionInputs {
        id
        title
        inputType
        dynamicInputsItemName
        placeholder
        options {
          id
          name
          value
        }

        knowledgeBases {
          __typename

          ... on GoogleDocsKnowledgeBase {
            id
            title
          }

          ... on TextKnowledgeBase {
            id
            title
          }

          ... on ImageKnowledgeBase {
            id
            title
          }

          ... on MSDocsKnowledgeBase {
            id
            title
          }

          ... on PDFKnowledgeBase {
            id
            title
          }

          ... on MSExcelKnowledgeBase {
            id
            title
          }

          ... on MSPPTKnowledgeBase {
            id
            title
          }

          ... on OldMSDocsKnowledgeBase {
            id
            title
          }

          ... on CSVKnowledgeBase {
            id
            title
          }

          ... on HWPKnowledgeBase {
            id
            title
          }
        }
      }

      project {
        id
      }
    }
  }
`);

const GenerateSingleActionAppAccessTokenPublicMutation = gql(`
  mutation GenerateSingleActionAppAccessTokenPublicMutation(
    $publicToken: String!
  ) {
    generateSingleActionAppAccessTokenPublic(token: $publicToken) {
      accessToken
      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`);

interface AppProviderProps {
  mode: Mode;
  publicToken: string;
  children?: React.ReactNode;
  onLoadApp?: (appId: string | number) => void;
}

export const AppProvider = ({
  mode,
  publicToken,
  children,
  onLoadApp,
}: AppProviderProps) => {
  const formatErrorMessage = useFormatErrorMessage();

  const [generateAccessToken, { data: accessTokenData }] = useMutation(
    GenerateSingleActionAppAccessTokenPublicMutation,
    {
      variables: { publicToken },
      onCompleted: res => {
        const error = compact(
          res.generateSingleActionAppAccessTokenPublic?.errors,
        )[0];

        if (error) {
          toast.error(formatErrorMessage(error));
        }
      },
      onError: err => toast.error(formatErrorMessage(err)),
    },
  );

  const accessToken =
    accessTokenData?.generateSingleActionAppAccessTokenPublic?.accessToken ||
    '';

  const { data, error, loading, refetch } = useQuery(AppProviderQuery, {
    variables: {
      token: publicToken,
    },
    onCompleted: ({ singleActionAppFromToken }) => {
      if (singleActionAppFromToken) {
        generateAccessToken();
        onLoadApp?.(singleActionAppFromToken.id);
      }
    },
    onError: err => toast.error(formatErrorMessage(err)),
  });

  const appData = useMemo(() => {
    if (!data?.singleActionAppFromToken) {
      return null;
    }

    const singleActionInputs = (
      data.singleActionAppFromToken.singleActionInputs || []
    ).map(input => {
      const options = (input.options || []).map(option => ({
        label: option.name,
        value: option.value,
      }));

      const knowledgeBases = (input.knowledgeBases || []).map(kb => ({
        label: kb.title,
        value: kb.id,
      }));

      return {
        ...input,
        placeholder: input.placeholder || '',
        options,
        knowledgeBases,
      };
    });

    return {
      id: data.singleActionAppFromToken.id,
      projectId: data.singleActionAppFromToken.project.id,
      name: data.singleActionAppFromToken.name,
      description: data.singleActionAppFromToken.description || '',
      singleActionInputs,
    };
  }, [data?.singleActionAppFromToken]);

  const app = useMemo(
    () => ({
      mode,
      data: appData,
      error: !!error || !publicToken,
      loading,
      accessToken,
      publicToken,
      refetch,
      generateAccessToken,
    }),
    [
      mode,
      appData,
      error,
      loading,
      publicToken,
      accessToken,
      refetch,
      generateAccessToken,
    ],
  );

  return <AppContext.Provider value={app}>{children}</AppContext.Provider>;
};
