/* eslint-disable */
import * as UserQueryTypes from '@allganize/data-access-alli-user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type HideConversationMutationVariables = UserQueryTypes.Exact<{
  ids?: UserQueryTypes.InputMaybe<Array<UserQueryTypes.Scalars['ID']['input']>>;
}>;

export type HideConversationMutation = { __typename: 'UserMutation' } & {
  hideConversations: UserQueryTypes.Maybe<
    { __typename: 'HideConversations' } & {
      errors: UserQueryTypes.Maybe<
        Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const HideConversationMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'HideConversationMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hideConversations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ErrorFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'field' } },
          { kind: 'Field', name: { kind: 'Name', value: 'info' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HideConversationMutation,
  HideConversationMutationVariables
>;
