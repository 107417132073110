import { useEventCallback } from '@allganize/hooks';
import { ApolloQueryResult } from '@apollo/client/core';
import { QueryResult, useQuery } from '@apollo/client/react';
import { uniqBy } from 'lodash-es';
import { useMemo } from 'react';
import { INode } from 'react-accessible-treeview';
import { gql, ResultOf, Scalars, VariablesOf } from '../gql';
import { Metadata } from '../knowledge-base-node/knowledge-base-node';
import {
  convertEdgesToNodes,
  ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment,
} from '../utils/convert-edges-to-nodes';

export const KnowledgeBaseNavigatorQuery = gql(
  `
  query KnowledgeBaseNavigatorQuery(
    $project: ProjectWhereUniqueInput!
    $filter: KnowledgeBaseNodeFilter
    $order: KnowledgeBaseNodeOrder
    $first: Int
  ) {
    knowledgeBaseNodes(
      project: $project
      filter: $filter
      order: $order
      first: $first
    ) {
      edges {
        cursor

        node {
          id
          name
        }

        ...ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment
      }
    }
  }
`,
  [ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment],
);

interface UseKnowledgeBaseNavigatorQueryOptions {
  ownership?: Scalars<'KnowledgeBaseNodeOwnership'>;
  projectId: Scalars<'ID'>;
}

interface UseKnowledgeBaseNavigatorQueryResult
  extends Omit<
    QueryResult<
      ResultOf<typeof KnowledgeBaseNavigatorQuery>,
      VariablesOf<typeof KnowledgeBaseNavigatorQuery>
    >,
    'data'
  > {
  data?: INode<Metadata>[];
  loadNextData(
    newFilter?: Scalars<'KnowledgeBaseNodeFilter'>,
  ): Promise<ApolloQueryResult<ResultOf<typeof KnowledgeBaseNavigatorQuery>>>;
  refresh(): Promise<void>;
}

export const useKnowledgeBaseNavigatorQuery = ({
  ownership = gql.scalar('KnowledgeBaseNodeOwnership', 'SHARED'),
  projectId,
}: UseKnowledgeBaseNavigatorQueryOptions): UseKnowledgeBaseNavigatorQueryResult => {
  const result = useQuery(KnowledgeBaseNavigatorQuery, {
    variables: {
      project: { id: projectId },
      filter: {
        parent: [null],
        type: [gql.scalar('KnowledgeBaseNodeType', 'FOLDER')],
        ownership: [ownership],
      },
      order: gql.scalar('KnowledgeBaseNodeOrder', 'NAME_ASC'),
      first: -1,
    },
    fetchPolicy: 'network-only',
  });

  const { data, fetchMore, refetch } = result;

  const nodes = useMemo(() => {
    return convertEdgesToNodes(data?.knowledgeBaseNodes?.edges);
  }, [data]);

  const loadNextData = useEventCallback(
    async (newFilter?: Scalars<'KnowledgeBaseNodeFilter'>) => {
      return fetchMore({
        variables: {
          project: { id: projectId },
          filter: {
            type: [gql.scalar('KnowledgeBaseNodeType', 'FOLDER')],
            ownership: [ownership],
            ...newFilter,
          },
          order: gql.scalar('KnowledgeBaseNodeOrder', 'NAME_ASC'),
          first: -1,
        },
        updateQuery(prev, { fetchMoreResult }) {
          if (!fetchMoreResult.knowledgeBaseNodes?.edges.length) {
            return prev;
          }

          return {
            ...prev,
            ...fetchMoreResult,
            knowledgeBaseNodes: {
              ...prev.knowledgeBaseNodes,
              ...fetchMoreResult.knowledgeBaseNodes,
              edges: uniqBy(
                [
                  ...fetchMoreResult.knowledgeBaseNodes.edges,
                  ...(prev.knowledgeBaseNodes?.edges ?? []),
                ],
                edge => edge.node.id,
              ),
            },
          };
        },
      });
    },
  );

  const refresh = useEventCallback(async () => {
    const ids = data?.knowledgeBaseNodes?.edges.map(edge => edge.node.id) ?? [];

    if (ids.length === 0) {
      await refetch();
      return;
    }

    await fetchMore({
      variables: {
        project: { id: projectId },
        filter: {
          type: [gql.scalar('KnowledgeBaseNodeType', 'FOLDER')],
          ownership: [ownership],
          parent: [null, ...ids],
        },
        order: gql.scalar('KnowledgeBaseNodeOrder', 'NAME_ASC'),
        first: -1,
      },
      updateQuery(prev, { fetchMoreResult }) {
        return fetchMoreResult;
      },
    });
  });

  return {
    ...result,
    data: nodes,
    loadNextData,
    refresh,
  };
};
