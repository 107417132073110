import { useEventCallback } from '@allganize/hooks';
import { FunctionComponent, useImperativeHandle } from 'react';
import { NodeId } from 'react-accessible-treeview';
import { Scalars } from '../gql';
import { useKnowledgeBaseNavigatorQuery } from '../hooks/use-knowledge-base-navigator-query';
import {
  KnowledgeBaseTreeView,
  KnowledgeBaseTreeViewProps,
} from '../knowledge-base-tree-view/knowledge-base-tree-view';
import { KnowledgeBaseTreeViewSkeleton } from '../knowledge-base-tree-view/knowledge-base-tree-view-skeleton';

export interface KnowledgeBaseNavigatorActions {
  loadData(...nodeIds: NodeId[]): Promise<void>;
  refresh(): Promise<void>;
}

export interface KnowledgeBaseNavigatorProps
  extends Pick<
    KnowledgeBaseTreeViewProps,
    | 'expandedIds'
    | 'onExpandedIdsChange'
    | 'onSelectedNodeChange'
    | 'selectedIds'
    | 'rootNodeName'
  > {
  actions?: React.Ref<KnowledgeBaseNavigatorActions>;
  children?: React.ReactNode;
  ownership?: Scalars<'KnowledgeBaseNodeOwnership'>;
  projectId: Scalars<'ID'>;
}

export const KnowledgeBaseNavigator: FunctionComponent<
  KnowledgeBaseNavigatorProps
> = props => {
  const { actions, children, ownership, projectId, ...other } = props;
  const { data, loading, loadNextData, refresh } =
    useKnowledgeBaseNavigatorQuery({
      ownership,
      projectId,
    });

  const handleLoadData = useEventCallback(async (...nodeIds: NodeId[]) => {
    await loadNextData({
      parent: nodeIds,
    });
  });

  useImperativeHandle(actions, () => ({ loadData: handleLoadData, refresh }), [
    handleLoadData,
    refresh,
  ]);

  if (!data) {
    if (loading) {
      return <KnowledgeBaseTreeViewSkeleton count={25} />;
    }

    return children;
  }

  return (
    <KnowledgeBaseTreeView data={data} {...other} onLoadData={handleLoadData} />
  );
};
