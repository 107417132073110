import { AutocompleteOption, autocompleteFilter } from '@allganize/alli-sdk-ui';
import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import {
  isLLMAppInformationIconVariant,
  LLMAppInformationIcon,
} from '@allganize/llm-app-icons';
import { IcAppMarket } from '@allganize/ui-icons';
import { useQuery } from '@apollo/client/react';
import useAutocomplete, {
  UseAutocompleteProps,
} from '@mui/material/useAutocomplete';
import { compact } from 'lodash-es';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { LLMAppFragment } from '../graphql/fragments/llm-app-fragment';
import { LLMAppsByCategoryQueryDocument } from '../graphql/queries/llm-apps-by-category-query';

interface UseMentionAppProps {
  enabled?: boolean;
  message: string;
  formDisabled: boolean;
  useAutocompleteProps?: Partial<
    UseAutocompleteProps<AutocompleteOption, false, true, true>
  >;
  onSelectOption?: (app?: LLMAppFragment) => void;
}

export const useMentionApp = ({
  enabled,
  message,
  formDisabled,
  useAutocompleteProps,
  onSelectOption,
}: UseMentionAppProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { data } = useQuery(LLMAppsByCategoryQueryDocument, {
    skip: !enabled,
    variables: {
      filter: {
        type: UserQueryTypes.LLMAppTypeFilter.SINGLE_ACTION,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const appList = compact(
    data?.llmAppsByCategory?.llmAppsByCategory ?? [],
  ).reduce<AutocompleteOption[]>((acc, { llmApps }) => {
    if (!llmApps || llmApps.length === 0) {
      return acc;
    }
    const optionList = llmApps.reduce<AutocompleteOption[]>((options, app) => {
      if (!app || !app.singleActionApp) {
        return options;
      }
      const iconVariant = isLLMAppInformationIconVariant(app.icon)
        ? app.icon
        : null;

      const option = {
        icon: iconVariant ? (
          <LLMAppInformationIcon variant={iconVariant} color="primary" />
        ) : null,
        title: app.name,
        description: app.description || '',
        onSelect: () => {
          onSelectOption?.(app);
        },
      };
      return [...options, option];
    }, []);
    return [...acc, ...optionList];
  }, []);

  const defaultOption = {
    icon: <IcAppMarket />,
    title: intl.formatMessage({
      id: 'chat-form.autocomplete.go-to-app-list',
      defaultMessage: 'Go to app list',
      description: 'go to app link text in chat-form mention',
    }),
    description: '',
    onSelect: () => {
      onSelectOption?.();
      navigate('/apps');
    },
  };

  const autocomplete = useAutocomplete<AutocompleteOption, false, true, true>({
    autoComplete: false,
    autoHighlight: false,
    autoSelect: false,
    blurOnSelect: false,
    clearOnBlur: false,
    clearOnEscape: false,
    disableClearable: true,
    disableCloseOnSelect: false,
    disabled: formDisabled,
    freeSolo: true,
    options: appList,
    inputValue: message,
    getOptionLabel(option) {
      return typeof option === 'string' ? option : option.title;
    },
    onChange(ev, value, reason) {
      if (
        reason === 'selectOption' &&
        typeof value !== 'string' &&
        value.onSelect
      ) {
        value.onSelect();
      }
    },
    filterOptions: (options, state) => {
      const isMentioning = state.inputValue.startsWith('@');

      if (!isMentioning) {
        return [];
      }

      const inputValue = state.inputValue.trim().substring(1);

      if (inputValue === '') {
        return [defaultOption, ...options];
      }

      const filteredOptions = options.filter(option =>
        autocompleteFilter(
          {
            value: option.title,
            label: option.description || '',
            data: option,
          },
          inputValue,
        ),
      );

      return [defaultOption, ...filteredOptions];
    },
    ...useAutocompleteProps,
  });

  return enabled ? autocomplete : null;
};
