import { useEventCallback, useId } from '@allganize/hooks';
import { css } from '@emotion/react';
import { useSlotProps } from '@mui/base/utils';
import clsx from 'clsx';
import { forwardRef, useEffect, useMemo, useRef } from 'react';
import { DayCalendar } from '../day-calendar';
import { DayCalendarSkeleton } from '../day-calendar-skeleton';
import { VIEW_HEIGHT } from '../internals/constants/dimensions';
import { useDefaultReduceAnimations } from '../internals/hooks/use-default-reduce-animations';
import { useDefaultDates, useUtils } from '../internals/hooks/use-utils';
import { useControlledValueWithTimezone } from '../internals/hooks/use-value-with-timezone';
import { useViews } from '../internals/hooks/use-views';
import { BaseDateValidationProps } from '../internals/models';
import {
  applyDefaultDate,
  findClosestEnabledDate,
  mergeDateAndTime,
} from '../internals/utils/date-utils';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { MonthCalendar } from '../month-calendar';
import { PickerViewRoot } from '../picker-view-root';
import {
  PickersCalendarHeader,
  PickersCalendarHeaderProps,
} from '../pickers-calendar-header';
import { PickersFadeTransitionGroup } from '../pickers-fade-transition-group';
import { YearCalendar } from '../year-calendar';
import { dateCalendarClasses } from './date-calendar-classes';
import { DateCalendarProps } from './date-calendar-type-map';
import { useCalendarState } from './use-calendar-state';

export const DateCalendar = forwardRef<HTMLDivElement, DateCalendarProps>(
  (props, ref) => {
    const defaultReduceAnimations = useDefaultReduceAnimations();
    const {
      autoFocus,
      onViewChange,
      value: valueProp,
      defaultValue,
      referenceDate: referenceDateProp,
      disableFuture = false,
      disablePast = false,
      defaultCalendarMonth,
      onChange,
      onYearChange,
      onMonthChange,
      reduceAnimations = defaultReduceAnimations,
      shouldDisableDate,
      shouldDisableMonth,
      shouldDisableYear,
      view: inView,
      views = ['year', 'day'],
      openTo = 'day',
      classes,
      className,
      disabled,
      readOnly,
      minDate: minDateProp,
      maxDate: maxDateProp,
      disableHighlightToday,
      focusedView: inFocusedView,
      onFocusedViewChange,
      showDaysOutsideCurrentMonth,
      fixedWeekNumber,
      dayOfWeekFormatter,
      slots,
      slotProps,
      loading = false,
      renderLoading = () => <DayCalendarSkeleton />,
      displayWeekNumber,
      yearsPerRow,
      monthsPerRow,
      timezone: timezoneProp,
      ...other
    } = props;
    const utils = useUtils<Date>();
    const defaultDates = useDefaultDates<Date>();
    const minDate = applyDefaultDate(utils, minDateProp, defaultDates.minDate);
    const maxDate = applyDefaultDate(utils, maxDateProp, defaultDates.maxDate);
    const id = useId();

    const { value, handleValueChange, timezone } =
      useControlledValueWithTimezone({
        name: 'DateCalendar',
        timezone: timezoneProp,
        value: valueProp,
        defaultValue,
        onChange,
        valueManager: singleItemValueManager,
      });

    const {
      view,
      setView,
      focusedView,
      setFocusedView,
      goToNextView,
      setValueAndGoToNextView,
    } = useViews({
      view: inView,
      views,
      openTo,
      onChange: handleValueChange,
      onViewChange,
      autoFocus,
      focusedView: inFocusedView,
      onFocusedViewChange,
    });

    const {
      referenceDate,
      calendarState,
      changeFocusedDay,
      changeMonth,
      handleChangeMonth,
      isDateDisabled,
      onMonthSwitchingAnimationEnd,
    } = useCalendarState<Date>({
      value,
      defaultCalendarMonth,
      referenceDate: referenceDateProp,
      reduceAnimations,
      onMonthChange,
      minDate,
      maxDate,
      shouldDisableDate,
      disablePast,
      disableFuture,
      timezone,
    });

    // When disabled, limit the view to the selected date
    const minDateWithDisabled = (disabled && value) || minDate;
    const maxDateWithDisabled = (disabled && value) || maxDate;

    const gridLabelId = `${id}-grid-label`;
    const hasFocus = focusedView !== null;

    const CalendarHeader = slots?.calendarHeader ?? PickersCalendarHeader;
    const calendarHeaderProps: PickersCalendarHeaderProps = useSlotProps({
      elementType: CalendarHeader,
      externalSlotProps: slotProps?.calendarHeader,
      additionalProps: {
        views,
        view,
        currentMonth: calendarState.currentMonth,
        onViewChange: setView,
        onMonthChange: (newMonth, direction) =>
          handleChangeMonth({ newMonth, direction }),
        minDate: minDateWithDisabled,
        maxDate: maxDateWithDisabled,
        disabled,
        disablePast,
        disableFuture,
        reduceAnimations,
        timezone,
        labelId: gridLabelId,
        slots,
        slotProps,
      },
      ownerState: props,
    });

    const handleDateMonthChange = useEventCallback((newDate: Date) => {
      const startOfMonth = utils.startOfMonth(newDate);
      const endOfMonth = utils.endOfMonth(newDate);

      const closestEnabledDate = isDateDisabled(newDate)
        ? findClosestEnabledDate({
            utils,
            date: newDate,
            minDate: utils.isBefore(minDate, startOfMonth)
              ? startOfMonth
              : minDate,
            maxDate: utils.isAfter(maxDate, endOfMonth) ? endOfMonth : maxDate,
            disablePast,
            disableFuture,
            isDateDisabled,
            timezone,
          })
        : newDate;

      if (closestEnabledDate) {
        setValueAndGoToNextView(closestEnabledDate, 'finish');
        onMonthChange?.(startOfMonth);
      } else {
        goToNextView();
        changeMonth(startOfMonth);
      }

      changeFocusedDay(closestEnabledDate, true);
    });

    const handleDateYearChange = useEventCallback((newDate: Date) => {
      const startOfYear = utils.startOfYear(newDate);
      const endOfYear = utils.endOfYear(newDate);

      const closestEnabledDate = isDateDisabled(newDate)
        ? findClosestEnabledDate({
            utils,
            date: newDate,
            minDate: utils.isBefore(minDate, startOfYear)
              ? startOfYear
              : minDate,
            maxDate: utils.isAfter(maxDate, endOfYear) ? endOfYear : maxDate,
            disablePast,
            disableFuture,
            isDateDisabled,
            timezone,
          })
        : newDate;

      if (closestEnabledDate) {
        setValueAndGoToNextView(closestEnabledDate, 'finish');
        onYearChange?.(closestEnabledDate);
      } else {
        goToNextView();
        changeMonth(startOfYear);
      }

      changeFocusedDay(closestEnabledDate, true);
    });

    const handleSelectedDayChange = useEventCallback((day: Date | null) => {
      if (day) {
        // If there is a date already selected, then we want to keep its time
        return handleValueChange(
          mergeDateAndTime(utils, day, value ?? referenceDate),
          'finish',
          view,
        );
      }

      return handleValueChange(day, 'finish', view);
    });

    useEffect(() => {
      if (value != null && utils.isValid(value)) {
        changeMonth(value);
      }
    }, [value]);

    const baseDateValidationProps: Required<BaseDateValidationProps<Date>> = {
      disablePast,
      disableFuture,
      maxDate,
      minDate,
    };

    const commonViewProps = {
      disableHighlightToday,
      readOnly,
      disabled,
      timezone,
      gridLabelId,
    };

    const prevOpenViewRef = useRef(view);
    useEffect(() => {
      // If the view change and the focus was on the previous view
      // Then we update the focus.
      if (prevOpenViewRef.current === view) {
        return;
      }

      if (focusedView === prevOpenViewRef.current) {
        setFocusedView(view, true);
      }
      prevOpenViewRef.current = view;
    }, [focusedView, setFocusedView, view]);

    const selectedDays = useMemo(() => [value], [value]);

    return (
      <PickerViewRoot
        date-testid="date-calendar"
        css={css`
          display: flex;
          flex-direction: column;
          max-height: ${VIEW_HEIGHT}px;
        `}
        {...other}
        ref={ref}
        className={clsx(dateCalendarClasses.root, classes?.root, className)}
      >
        <CalendarHeader {...calendarHeaderProps} />

        <PickersFadeTransitionGroup
          reduceAnimations={reduceAnimations}
          className={clsx(
            dateCalendarClasses.viewTransitionContainer,
            classes?.viewTransitionContainer,
          )}
          transKey={view}
        >
          <div>
            {view === 'year' && (
              <YearCalendar
                {...baseDateValidationProps}
                {...commonViewProps}
                value={value}
                onChange={handleDateYearChange}
                shouldDisableYear={shouldDisableYear}
                hasFocus={hasFocus}
                onFocusedViewChange={isViewFocused =>
                  setFocusedView('year', isViewFocused)
                }
                yearsPerRow={yearsPerRow}
                referenceDate={referenceDate}
              />
            )}

            {view === 'month' && (
              <MonthCalendar
                {...baseDateValidationProps}
                {...commonViewProps}
                hasFocus={hasFocus}
                className={className}
                value={value}
                onChange={handleDateMonthChange}
                shouldDisableMonth={shouldDisableMonth}
                onFocusedViewChange={isViewFocused =>
                  setFocusedView('month', isViewFocused)
                }
                monthsPerRow={monthsPerRow}
                referenceDate={referenceDate}
              />
            )}

            {view === 'day' && (
              <DayCalendar
                {...calendarState}
                {...baseDateValidationProps}
                {...commonViewProps}
                onMonthSwitchingAnimationEnd={onMonthSwitchingAnimationEnd}
                onFocusedDayChange={changeFocusedDay}
                reduceAnimations={reduceAnimations}
                selectedDays={selectedDays}
                onSelectedDaysChange={handleSelectedDayChange}
                shouldDisableDate={shouldDisableDate}
                shouldDisableMonth={shouldDisableMonth}
                shouldDisableYear={shouldDisableYear}
                hasFocus={hasFocus}
                onFocusedViewChange={isViewFocused =>
                  setFocusedView('day', isViewFocused)
                }
                showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
                fixedWeekNumber={fixedWeekNumber}
                dayOfWeekFormatter={dayOfWeekFormatter}
                displayWeekNumber={displayWeekNumber}
                slots={slots}
                slotProps={slotProps}
                loading={loading}
                renderLoading={renderLoading}
              />
            )}
          </div>
        </PickersFadeTransitionGroup>
      </PickerViewRoot>
    );
  },
);
