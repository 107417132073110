import {
  AlliChatRow,
  AlliChatRowItem,
  AlliChatRowItemProps,
  ChatStackItem,
  TimestampTooltip,
} from '@allganize/alli-chat-base';
import { ChatMedia } from '@allganize/alli-chat-content';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

export const AlliChatMedia_MediaFragment = gql(`
  fragment AlliChatMedia_MediaFragment on Media {
    id
    mediaType
    metaInfo
    url
    width
    height
  }
`);

export const AlliChatMedia_ChatFragment = gql(
  `
  fragment AlliChatMedia_ChatFragment on Chat {
    ...on AgentChat {
      __typename
      createdAt

      media {
        ...AlliChatMedia_MediaFragment
      }
    }

    ...on BotChat {
      __typename
      createdAt

      media {
        ...AlliChatMedia_MediaFragment
      }
    }

    ...on CarouselChat {
      __typename
      createdAt

      media {
        ...AlliChatMedia_MediaFragment
      }
    }

    ...on SendFormChat {
      __typename
      createdAt

      media {
        ...AlliChatMedia_MediaFragment
      }
    }
  }
`,
  [AlliChatMedia_MediaFragment],
);

export interface AlliChatMediaProps
  extends Pick<AlliChatRowItemProps, 'avatar'> {
  chat: FragmentOf<typeof AlliChatMedia_ChatFragment>;
  children?: React.ReactNode;
}

export const AlliChatMedia: FunctionComponent<AlliChatMediaProps> = props => {
  const { avatar, chat: chatProp, children } = props;
  const chat = readFragment(AlliChatMedia_ChatFragment, chatProp);

  if (
    chat.__typename !== 'AgentChat' &&
    chat.__typename !== 'BotChat' &&
    chat.__typename !== 'SendFormChat' &&
    chat.__typename !== 'CarouselChat'
  ) {
    return null;
  }

  if (!chat.media) {
    return null;
  }

  return (
    <ChatStackItem disablePadding>
      <AlliChatRow>
        <TimestampTooltip align="left" timestamp={chat.createdAt}>
          <AlliChatRowItem avatar={avatar}>
            <ChatMedia
              media={readFragment(AlliChatMedia_MediaFragment, chat.media)}
            />

            {children}
          </AlliChatRowItem>
        </TimestampTooltip>
      </AlliChatRow>
    </ChatStackItem>
  );
};
