import { AccessControlSelectAgentOption } from '../access-control-agent-select/access-control-agent-select';
import { FragmentOf, gql, readFragment, Scalars } from '../gql';

export const ParseAccessAgent_AgentFragment = gql(`
  fragment ParseAccessAgent_AgentFragment on Agent {
    __typename
    id
    isMe
    displayName
    name
    email

    avatar {
      id
      url
    }
  }
`);

export const parseAccessAgent = (value: {
  accessType: Scalars<'ObjectAccessType'>;
  agent: FragmentOf<typeof ParseAccessAgent_AgentFragment> | null;
  isAdmin?: boolean | null;
}): AccessControlSelectAgentOption | undefined => {
  const agent = readFragment(ParseAccessAgent_AgentFragment, value.agent);

  if (!agent?.id) return;

  return {
    accessType: value.accessType,
    isMe: agent.isMe || false,
    isAdmin: value.isAdmin || false,
    value: agent.id,
    label: agent.displayName || agent.name || null,
    description: agent?.email || null,
    avatar: {
      src: agent.avatar?.url,
      name: agent.displayName,
    },
  };
};
