import { Truncate } from '@allganize/truncate';
import { OverrideProps } from '@allganize/types';
import {
  Button,
  ExtendButton,
  ExtendButtonTypeMap,
} from '@allganize/ui-button';
import { IcFolder } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { gql, ResultOf } from '../gql';

export const KnowledgeBaseLocationColumn_KnowledgeBaseNodeFragment = gql(`
  fragment KnowledgeBaseLocationColumn_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    ownership

    parent {
      id
      name
      ownership
    }
  }
`);

interface KnowledgeBaseLocationColumnOwnProps {
  node: ResultOf<typeof KnowledgeBaseLocationColumn_KnowledgeBaseNodeFragment>;
  rootNodeName?: React.ReactNode;
}

type KnowledgeBaseLocationColumnTypeMap<
  P = {},
  D extends React.ElementType = 'button',
> = ExtendButtonTypeMap<{
  props: P & KnowledgeBaseLocationColumnOwnProps;
  defaultComponent: D;
}>;

export type KnowledgeBaseLocationColumnProps<
  D extends React.ElementType = KnowledgeBaseLocationColumnTypeMap['defaultComponent'],
  P = {},
> = OverrideProps<KnowledgeBaseLocationColumnTypeMap<P, D>, D> & {
  component?: React.ElementType;
};

// @ts-expect-error overridable component
export const KnowledgeBaseLocationColumn: ExtendButton<KnowledgeBaseLocationColumnTypeMap> =
  forwardRef((props, ref) => {
    const {
      node,
      onClick,
      rootNodeName = (
        <FormattedMessage
          id="all-files"
          defaultMessage="All files"
          description="All files"
        />
      ),
      ...other
    } = props;

    const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      onClick?.(ev);
    };

    if (!node.parent) {
      return (
        <Button
          startIcon={<IcFolder />}
          {...other}
          ref={ref}
          onClick={handleClick}
        >
          <Truncate
            component="span"
            css={css`
              word-break: break-all;
            `}
            clamp={1}
          >
            {rootNodeName}
          </Truncate>
        </Button>
      );
    }

    return (
      <Button
        startIcon={<IcFolder />}
        {...other}
        ref={ref}
        onClick={handleClick}
      >
        <Truncate
          component="span"
          css={css`
            word-break: break-all;
          `}
          clamp={1}
        >
          {node.parent.name}
        </Truncate>
      </Button>
    );
  });
