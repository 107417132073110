import { createIllustration } from '../create-illustration';
import rock from './rock.png';
import rock2x from './rock@2x.png';
import rock3x from './rock@3x.png';

export const Rock = createIllustration({
  src: rock,
  srcSet: [rock, rock2x, rock3x].map((src, i) => `${src} ${i + 1}x`).join(','),
  size: 160,
});
