import { useId } from '@allganize/hooks';
import { useTheme } from '@allganize/ui-theme';
import { Fade } from '@allganize/ui-transition';
import { ClassNames } from '@emotion/react';
import MuiDialog from '@mui/material/Dialog';
import { forwardRef } from 'react';
import { dialogClasses } from './dialog-classes';
import { DialogContext } from './dialog-context';
import { DialogBackdrop, DialogPaper, DialogRoot } from './dialog-slots';
import { DialogProps } from './dialog-type-map';

export const Dialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
  const {
    'aria-labelledby': ariaLabelledbyProp,
    classes,
    onClose,
    PaperProps,
    slots,
    ...other
  } = props;
  const theme = useTheme();
  const reactId = useId();
  const ariaLabelledby = ariaLabelledbyProp ?? reactId;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <DialogContext.Provider value={{ onClose, titleId: ariaLabelledby }}>
          <MuiDialog
            data-testid="dialog"
            as={DialogRoot}
            aria-labelledby={ariaLabelledby}
            // @ts-expect-error internal component
            PaperComponent={DialogPaper}
            TransitionComponent={Fade}
            onClose={onClose}
            {...other}
            ref={ref}
            classes={{
              ...classes,
              paperWidthXs: cx(
                css`
                  max-width: ${theme.breakpoints.unit === 'px'
                    ? `${Math.max(theme.breakpoints.values.xs, 444)}px`
                    : `max(${theme.breakpoints.values.xs}${theme.breakpoints.unit}, 444px)`};

                  &.${dialogClasses.paperScrollBody} {
                    ${theme.breakpoints.down(
                      Math.max(theme.breakpoints.values.xs, 444) + 32 * 2,
                    )} {
                      max-width: calc(100% - 64px);
                    }
                  }
                `,
                classes?.paperWidthXs,
              ),
              paperWidthSm: cx(
                css`
                  max-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit};

                  &.${dialogClasses.paperScrollBody} {
                    ${theme.breakpoints.down(
                      theme.breakpoints.values.sm + 32 * 2,
                    )} {
                      max-width: calc(100% - 64px);
                    }
                  }
                `,
                classes?.paperWidthSm,
              ),
              paperWidthMd: cx(
                css`
                  max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit};

                  &.${dialogClasses.paperScrollBody} {
                    ${theme.breakpoints.down(
                      theme.breakpoints.values.md + 32 * 2,
                    )} {
                      max-width: calc(100% - 64px);
                    }
                  }
                `,
                classes?.paperWidthMd,
              ),
              paperWidthLg: cx(
                css`
                  max-width: ${theme.breakpoints.values.lg}${theme.breakpoints.unit};

                  &.${dialogClasses.paperScrollBody} {
                    ${theme.breakpoints.down(
                      theme.breakpoints.values.lg + 32 * 2,
                    )} {
                      max-width: calc(100% - 64px);
                    }
                  }
                `,
                classes?.paperWidthLg,
              ),
              paperWidthXl: cx(
                css`
                  max-width: ${theme.breakpoints.values.xl}${theme.breakpoints.unit};

                  &.${dialogClasses.paperScrollBody} {
                    ${theme.breakpoints.down(
                      theme.breakpoints.values.xl + 32 * 2,
                    )} {
                      max-width: calc(100% - 64px);
                    }
                  }
                `,
                classes?.paperWidthXl,
              ),
              paperFullScreen: cx(
                css`
                  max-width: 100%;

                  &.${dialogClasses.paperScrollBody} {
                    max-width: 100%;
                  }
                `,
                classes?.paperFullScreen,
              ),
            }}
            PaperProps={{
              elevation: 4,
              ...PaperProps,
            }}
            slots={{
              backdrop: DialogBackdrop,
              ...slots,
            }}
          />
        </DialogContext.Provider>
      )}
    </ClassNames>
  );
});
