import { TabNavigator } from '@allganize/tab-navigator';
import { Drawer, drawerClasses } from '@allganize/ui-drawer';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { Suspense, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl';

import { AppPreviewPanel, AppPreviewPanelProps } from './app-preview-panel';
import { AppListPanel } from './app-list-panel';
import { DocumentListPanel } from './document-list-panel';
import { DocumentPreviewPanel } from './document-preview-panel';
import { SidePanelHeader } from './side-panel-header';
import { SidePanelCloseButton } from './side-panel-close-button';
import { SidePanelErrorFallback } from './side-panel-error-fallback';
import { SidePanelContext } from './side-panel-context';
import { TabNavigatorContext } from './use-tab-navigator';

export const SIDE_PANEL_MIN_WIDTH = 416;
export const SIDE_PANEL_SIDE_SPACE = 16;
const SIDE_PANEL_HEADER_HEIGHT = 40;

export const SidePanel = (props: AppPreviewPanelProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const { open, closeSidePanel } = useContext(SidePanelContext);
  const tabNavigator = useContext(TabNavigatorContext);
  const { activeTab } = tabNavigator;

  return (
    <Drawer
      css={[
        css`
          flex-shrink: 0;
          overflow-x: hidden;
          width: 100%;
          height: 100%;
          padding: 24px ${SIDE_PANEL_SIDE_SPACE}px;

          & > .${drawerClasses.paper} {
            position: relative;
            border-radius: ${theme.radius.lg}px;
            overflow: hidden;
            transform: translateX(100%);
            opacity: 0;

            transition: ${theme.transitions.create(['transform', 'opacity'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            })};
          }
        `,
        open &&
          css`
            & > .${drawerClasses.paper} {
              transform: translateX(0);
              opacity: 1;

              transition: ${theme.transitions.create(['transform', 'opacity'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              })};
            }
          `,
      ]}
      variant="permanent"
      anchor="right"
      open={open}
      PaperProps={{
        elevation: 3,
      }}
    >
      <div
        css={css`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        `}
      >
        <ErrorBoundary fallback={<SidePanelErrorFallback />}>
          <Suspense fallback={null}>
            <SidePanelHeader
              css={css`
                height: ${SIDE_PANEL_HEADER_HEIGHT}px;
              `}
            >
              <TabNavigator {...tabNavigator} />
              <SidePanelCloseButton
                onClick={closeSidePanel}
                aria-label={intl.formatMessage({
                  id: 'side-panel.close-button.label',
                  defaultMessage: 'Close side panel',
                  description: 'side panel close button title',
                })}
              />
            </SidePanelHeader>

            <div
              css={css`
                height: calc(100% - ${SIDE_PANEL_HEADER_HEIGHT}px);
              `}
            >
              {activeTab?.type === 'DOCUMENT_LIST' && <DocumentListPanel />}

              {activeTab?.type === 'DOCUMENT_PREVIEW' && (
                <DocumentPreviewPanel />
              )}

              {activeTab?.type === 'APP_LIST' && <AppListPanel />}

              {activeTab?.type === 'APP_PREVIEW' && (
                <AppPreviewPanel {...props} />
              )}
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>

      <SidePanelCloseButton
        onClick={closeSidePanel}
        aria-label={intl.formatMessage({
          id: 'side-panel.close-button.label',
          defaultMessage: 'Close side panel',
          description: 'side panel close button title',
        })}
        css={css`
          position: absolute;
          top: 0;
          right: 0;
        `}
      />
    </Drawer>
  );
};
