/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment UseChatFormAutocomplete_ChatFragment on Chat {\n    ... on AgentChat {\n      useAutoComplete\n    }\n    ... on BotChat {\n      useAutoComplete\n    }\n    ... on CarouselChat {\n      useAutoComplete\n    }\n    ... on ContactAgentByEmailChat {\n      useAutoComplete\n    }\n    ... on SendFormChat {\n      useAutoComplete\n    }\n    ... on UserChat {\n      useAutoComplete\n    }\n  }\n": types.UseChatFormAutocomplete_ChatFragmentFragmentDoc,
    "\n  query AutoCompletionQuery(\n    $partial: String!\n    $where: ConversationWhereUniqueInput!\n  ) {\n    autoCompletion(partial: $partial, where: $where) {\n      questions\n    }\n  }\n": types.AutoCompletionQueryDocument,
    "\n  fragment ChatForm__ChatFragment on Chat {\n    ... on AgentChat {\n      nextInputType\n    }\n    ... on BotChat {\n      nextInputType\n      placeholder\n      generatingState\n      allowKnowledgeBaseFile {\n        extensions\n        maxSize\n      }\n      saveAsVariable {\n        validationCustom\n        validationFailedMessage\n      }\n    }\n    ... on CarouselChat {\n      nextInputType\n      placeholder\n    }\n    ... on ContactAgentByEmailChat {\n      nextInputType\n    }\n    ... on SendFormChat {\n      nextInputType\n    }\n    ... on UserChat {\n      nextInputType\n    }\n  }\n": types.ChatForm__ChatFragmentFragmentDoc,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UseChatFormAutocomplete_ChatFragment on Chat {\n    ... on AgentChat {\n      useAutoComplete\n    }\n    ... on BotChat {\n      useAutoComplete\n    }\n    ... on CarouselChat {\n      useAutoComplete\n    }\n    ... on ContactAgentByEmailChat {\n      useAutoComplete\n    }\n    ... on SendFormChat {\n      useAutoComplete\n    }\n    ... on UserChat {\n      useAutoComplete\n    }\n  }\n"): (typeof documents)["\n  fragment UseChatFormAutocomplete_ChatFragment on Chat {\n    ... on AgentChat {\n      useAutoComplete\n    }\n    ... on BotChat {\n      useAutoComplete\n    }\n    ... on CarouselChat {\n      useAutoComplete\n    }\n    ... on ContactAgentByEmailChat {\n      useAutoComplete\n    }\n    ... on SendFormChat {\n      useAutoComplete\n    }\n    ... on UserChat {\n      useAutoComplete\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AutoCompletionQuery(\n    $partial: String!\n    $where: ConversationWhereUniqueInput!\n  ) {\n    autoCompletion(partial: $partial, where: $where) {\n      questions\n    }\n  }\n"): (typeof documents)["\n  query AutoCompletionQuery(\n    $partial: String!\n    $where: ConversationWhereUniqueInput!\n  ) {\n    autoCompletion(partial: $partial, where: $where) {\n      questions\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ChatForm__ChatFragment on Chat {\n    ... on AgentChat {\n      nextInputType\n    }\n    ... on BotChat {\n      nextInputType\n      placeholder\n      generatingState\n      allowKnowledgeBaseFile {\n        extensions\n        maxSize\n      }\n      saveAsVariable {\n        validationCustom\n        validationFailedMessage\n      }\n    }\n    ... on CarouselChat {\n      nextInputType\n      placeholder\n    }\n    ... on ContactAgentByEmailChat {\n      nextInputType\n    }\n    ... on SendFormChat {\n      nextInputType\n    }\n    ... on UserChat {\n      nextInputType\n    }\n  }\n"): (typeof documents)["\n  fragment ChatForm__ChatFragment on Chat {\n    ... on AgentChat {\n      nextInputType\n    }\n    ... on BotChat {\n      nextInputType\n      placeholder\n      generatingState\n      allowKnowledgeBaseFile {\n        extensions\n        maxSize\n      }\n      saveAsVariable {\n        validationCustom\n        validationFailedMessage\n      }\n    }\n    ... on CarouselChat {\n      nextInputType\n      placeholder\n    }\n    ... on ContactAgentByEmailChat {\n      nextInputType\n    }\n    ... on SendFormChat {\n      nextInputType\n    }\n    ... on UserChat {\n      nextInputType\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;