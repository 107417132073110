import { FormControl, FormGroup, FormHelperText } from '@allganize/ui-form';
import { List } from '@allganize/ui-list';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FaqCarouselOptionListItem } from './faq-carousel-option-list-item';
import {
  FaqCarouselFormValues,
  FaqCarouselOption,
} from './use-faq-carousel-form';

export interface SelectedFieldProps {
  align?: 'left' | 'right';
  isOptionDisabled?(option: FaqCarouselOption): boolean;
  options: FaqCarouselOption[];
}

export const SelectedField: FunctionComponent<SelectedFieldProps> = props => {
  const { align = 'right', isOptionDisabled, options } = props;
  const { control } = useFormContext<FaqCarouselFormValues>();
  const {
    field,
    fieldState,
    formState: { disabled: formDisabled, isSubmitting },
  } = useController({ control, name: 'selected' });
  const fieldDisabled = isSubmitting || formDisabled || field.disabled;

  return (
    <FormControl fullWidth error={fieldState.invalid} disabled={fieldDisabled}>
      <FormGroup role="radiogroup" onBlur={field.onBlur}>
        <List
          css={[
            css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
            `,
            align === 'right' &&
              css`
                align-items: flex-end;
              `,
          ]}
          component="div"
          disablePadding
        >
          {options.map(option => {
            const checked = option.value === field.value?.value;
            // form gets submitted on click for single choice, so we have to disable
            const disabled = fieldDisabled || isOptionDisabled?.(option);

            return (
              <FaqCarouselOptionListItem
                key={option.value}
                checked={checked}
                disabled={disabled}
                onBlur={field.onBlur}
                onClick={() => {
                  if (checked) {
                    return;
                  }

                  field.onChange(option);
                }}
                option={option}
              />
            );
          })}
        </List>
      </FormGroup>

      {fieldState.error?.message && (
        <FormHelperText error>
          <Text variant="inherit" align={align}>
            {fieldState.error.message}
          </Text>
        </FormHelperText>
      )}
    </FormControl>
  );
};
