import { z } from 'zod';

export const LOCALES = [
  'en',
  'ja',
  'ko',
  'fr',
  'es',
  'th',
  'zh-cn',
  'zh-tw',
] as const;
export const localeSchema = z.enum(LOCALES);
export type Locale = z.infer<typeof localeSchema>;
export const defaultLocale: Locale = 'en';

export type MessageIds =
  | keyof typeof import('./en.json')
  | keyof typeof import('./ja.json')
  | keyof typeof import('./ko.json')
  | keyof typeof import('./fr.json')
  | keyof typeof import('./es.json')
  | keyof typeof import('./th.json')
  | keyof typeof import('./zh_cn.json')
  | keyof typeof import('./zh_tw.json');

export const loadMessages = async (locale: Locale) => {
  switch (locale) {
    case 'en':
      return import('./en.json').then(mod => mod.default);
    case 'ja':
      return import('./ja.json').then(mod => mod.default);
    case 'ko':
      return import('./ko.json').then(mod => mod.default);
    case 'fr':
      return import('./fr.json').then(mod => mod.default);
    case 'es':
      return import('./es.json').then(mod => mod.default);
    case 'th':
      return import('./th.json').then(mod => mod.default);
    case 'zh-cn':
      return import('./zh_cn.json').then(mod => mod.default);
    case 'zh-tw':
      return import('./zh_tw.json').then(mod => mod.default);
    default:
      throw new Error(`Unsupported locale: ${locale}`);
  }
};
