import { css } from '@emotion/react';
import { FunctionComponent, lazy, Suspense } from 'react';
import { MarkdownTextProps } from './markdown-text.types';

const Markdown = lazy(() =>
  import('@allganize/markdown').then(mod => ({ default: mod.Markdown })),
);

export const MarkdownText: FunctionComponent<MarkdownTextProps> = props => {
  const { children, ...other } = props;

  if (!children) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <Markdown
        css={css`
          > *:first-child {
            margin-top: 0;
          }

          > *:last-child {
            margin-bottom: 0;
          }
        `}
        {...other}
      >
        {children}
      </Markdown>
    </Suspense>
  );
};
