import { createContext } from 'react';
import { TabsVariant } from './tabs.types';

export interface TabsContextValue {
  fullWidth?: boolean;
  onChange?: (event: React.SyntheticEvent, value: any) => void;
  variant?: TabsVariant;
}

export const TabsContext = createContext<TabsContextValue>({});
