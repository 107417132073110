import {
  ChatStackItem,
  TimestampTooltip,
  UserChatRow,
  UserChatRowProps,
} from '@allganize/alli-chat-base';
import { ChatMedia } from '@allganize/alli-chat-content';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

const UserChatMedia_MediaFragment = gql(`
  fragment UserChatMedia_MediaFragment on Media {
    id
    mediaType
    metaInfo
    url
    width
    height
  }
`);

export const UserChatMedia_ChatFragment = gql(
  `
  fragment UserChatMedia_ChatFragment on Chat {
    ...on ContactAgentByEmailChat {
      __typename
      createdAt

      media {
        ...UserChatMedia_MediaFragment
      }
    }

    ...on UserChat {
      __typename
      createdAt

      media {
        ...UserChatMedia_MediaFragment
      }
    }
  }
`,
  [UserChatMedia_MediaFragment],
);

export interface UserChatMediaProps extends Pick<UserChatRowProps, 'avatar'> {
  align?: 'left' | 'right';
  chat: FragmentOf<typeof UserChatMedia_ChatFragment>;
}

export const UserChatMedia: FunctionComponent<UserChatMediaProps> = props => {
  const { align = 'right', avatar, chat: chatProp } = props;
  const chat = readFragment(UserChatMedia_ChatFragment, chatProp);

  if (
    chat.__typename !== 'ContactAgentByEmailChat' &&
    chat.__typename !== 'UserChat'
  ) {
    return null;
  }

  if (!chat.media) {
    return null;
  }

  return (
    <ChatStackItem>
      <TimestampTooltip align={align} timestamp={chat.createdAt}>
        <UserChatRow align={align} avatar={avatar}>
          <ChatMedia
            media={readFragment(UserChatMedia_MediaFragment, chat.media)}
          />
        </UserChatRow>
      </TimestampTooltip>
    </ChatStackItem>
  );
};
