import { forwardRef, memo } from 'react';

export const createIllustration = (
  options: Pick<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet'> & {
    size: number;
  },
) => {
  return memo(
    forwardRef<HTMLImageElement, React.ImgHTMLAttributes<HTMLImageElement>>(
      (props, ref) => {
        return (
          <img
            src={options.src}
            srcSet={options.srcSet}
            width={options.size}
            height={options.size}
            alt={props.alt}
            {...props}
            ref={ref}
          />
        );
      },
    ),
  );
};
