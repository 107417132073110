import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ChatCarouselClassKey =
  | 'root'
  | 'title'
  | 'carousel'
  | 'hasCarouselHeightBug';
export type ChatCarouselClasses = Record<ChatCarouselClassKey, string>;

export const chatCarouselClasses: ChatCarouselClasses = generateUtilityClasses(
  'ChatCarousel',
  ['root', 'title', 'carousel', 'hasCarouselHeightBug'],
);
