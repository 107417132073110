import { toast } from '@allganize/ui-toast';
import { FormattedErrorMessage } from '@allganize/utils-graphql';
import { MutationHookOptions, useMutation } from '@apollo/client/react';
import { gql, ResultOf, VariablesOf } from '../gql';

const CancelKnowledgeBaseLlmTaskMutation = gql(`
  mutation CancelKnowledgeBaseLlmTaskMutation(
    $taskId: ID!
    $where: ProjectWhereUniqueInput!
  ) {
    cancelKnowledgeBaseLlmTask(taskId: $taskId, where: $where) {
      taskInfo {
        id
        generatedCandidatesCount

        progress {
          ...on KnowledgeBaseLLMTaskQNAProgress {
            status
            startedAt
          }

          ...on KnowledgeBaseLLMTaskSummaryProgress {
            status
            startedAt
          }

          ...on KnowledgeBaseLLMTaskCustomProgress {
            status
            startedAt
          }
        }
      }

      errors {
        __typename
        key
        field
        message
        info
      }
    }
  }
`);

export const useCancelKnowledgeBaseLlmTask = (
  options?: MutationHookOptions<
    ResultOf<typeof CancelKnowledgeBaseLlmTaskMutation>,
    VariablesOf<typeof CancelKnowledgeBaseLlmTaskMutation>
  >,
) => {
  return useMutation(CancelKnowledgeBaseLlmTaskMutation, {
    ...options,
    onCompleted(result) {
      options?.onCompleted?.(result);

      if (result.cancelKnowledgeBaseLlmTask?.taskInfo) {
        return;
      }

      if (result.cancelKnowledgeBaseLlmTask?.errors?.[0]) {
        toast.error(
          <FormattedErrorMessage
            error={result.cancelKnowledgeBaseLlmTask.errors[0]}
          />,
        );
        return;
      }

      toast.error(<FormattedErrorMessage />);
    },
    onError(err) {
      options?.onError?.(err);
      toast.error(<FormattedErrorMessage error={err} />);
    },
  });
};
