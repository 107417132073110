import { DatePicker, DatePickerProps } from '@allganize/ui-date-picker';
import { FormControl, FormLabel } from '@allganize/ui-form';
import { Text } from '@allganize/ui-text';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DateFormValue, FormValueFormValues } from './use-form-value-form';
import { formatDate } from './use-form-value-form.utils';

export interface DateFormValueFieldProps {
  disabled?: boolean;
  name: `formValues.${number}`;
  slotProps?: Pick<NonNullable<DatePickerProps['slotProps']>, 'dialog'>;
  slots?: Pick<NonNullable<DatePickerProps['slots']>, 'dialog'>;
}

export const DateFormValueField: FunctionComponent<
  DateFormValueFieldProps
> = props => {
  const { disabled: disabledProp, name, slotProps, slots } = props;
  const { control } = useFormContext<FormValueFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name,
    disabled: disabledProp,
  });
  const {
    field: { ref: valueRef, ...value },
    fieldState: valueState,
  } = useController({ control, name: `${name}.value`, disabled: disabledProp });
  const disabled = field.disabled || formState.disabled;

  if (field.value.type !== 'date') {
    return null;
  }

  if (disabled) {
    return (
      <FormControl fullWidth required={field.value.required}>
        <FormLabel>{field.value.label}</FormLabel>

        <Text variant="body14">
          {field.value.value ? formatDate(field.value.value) : '-'}
        </Text>
      </FormControl>
    );
  }

  return (
    <DatePicker
      inputRef={valueRef}
      value={value.value as DateFormValue['value']}
      onChange={value.onChange}
      label={field.value.label}
      slotProps={{
        dialog: slotProps?.dialog,
        field: {
          clearable: true,
        },
        inputField: {
          name: value.name,
          fullWidth: true,
          required: field.value.required,
          error: fieldState.invalid || valueState.invalid,
          helperText: fieldState.error?.message || valueState.error?.message,
          onBlur: value.onBlur,
        },
      }}
      slots={slots}
    />
  );
};
