import {
  LLMAppInformationIconVariant,
  llmAppInformationIconVariants,
} from './llm-app-information-icon.types';

export const isLLMAppInformationIconVariant = (
  value: any,
): value is LLMAppInformationIconVariant => {
  return llmAppInformationIconVariants.includes(value);
};
