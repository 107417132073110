import { ChatForm } from '@allganize/alli-chat-form';
import { useProject } from '@allganize/alli-works-project';
import { css, useTheme } from '@emotion/react';
import { useAutocomplete } from './use-autocomplete';
import { useChatFooter } from './use-chat-footer';
import {
  AppListSidePanelButton,
  DocumentListSidePanelButton,
  UploadDocumentButton,
} from '../side-panel/side-panel-buttons';

export const Footer = () => {
  const { appCollectionSettings } = useProject();
  const theme = useTheme();

  const {
    chatForm,
    lastChat,
    assistantEnabled,
    assistantPlaceholder,
    cautionMessage,
  } = useChatFooter();
  const autocomplete = useAutocomplete({ chatForm, lastChat });

  return (
    <footer
      css={css`
        position: sticky;
        bottom: 0;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 12px;
        background-color: ${theme.palette.unstable_background.white};
      `}
    >
      <ChatForm
        variant="block"
        open={true}
        chatForm={chatForm}
        autocomplete={autocomplete}
        MessageFieldInputProps={{
          startAdornment: (
            <>
              <AppListSidePanelButton />
              {assistantEnabled && (
                <>
                  <UploadDocumentButton />
                  {!appCollectionSettings?.hideWorksKb && (
                    <DocumentListSidePanelButton
                      control={chatForm.form.control}
                    />
                  )}
                </>
              )}
            </>
          ),
          ...(assistantEnabled ? { placeholder: assistantPlaceholder } : {}),
        }}
      />
      {cautionMessage && (
        <div
          css={css`
            text-align: center;
            font-size: 10px;
            line-height: 10px;
            font-weight: 500;
            color: ${theme.palette.foreground.placeholder};
          `}
        >
          {cautionMessage}
        </div>
      )}
    </footer>
  );
};
