import { OverridableComponent } from '@allganize/types';
import { forwardRef } from 'react';
import {
  IcFileCSV,
  IcFileDOC,
  IcFileDOCX,
  IcFileFallback,
  IcFileHWP,
  IcFileHWPX,
  IcFileJPEG,
  IcFileJPG,
  IcFilePDF,
  IcFilePNG,
  IcFilePPT,
  IcFilePPTX,
  IcFileTXT,
  IcFileXLS,
  IcFileXLSM,
  IcFileXLSX,
  IcFileZIP,
} from './icons';
import {
  KnowledgeBaseFileIconTypeMap,
  KnowledgeBaseFileIconVariant,
} from './knowledge-base-file-icon.types';

const variantMapping: Record<KnowledgeBaseFileIconVariant, typeof IcFileCSV> = {
  csv: IcFileCSV,
  doc: IcFileDOC,
  docx: IcFileDOCX,
  fallback: IcFileFallback,
  hwp: IcFileHWP,
  hwpx: IcFileHWPX,
  jpeg: IcFileJPEG,
  jpg: IcFileJPG,
  pdf: IcFilePDF,
  png: IcFilePNG,
  ppt: IcFilePPT,
  pptx: IcFilePPTX,
  txt: IcFileTXT,
  xls: IcFileXLS,
  xlsm: IcFileXLSM,
  xlsx: IcFileXLSX,
  zip: IcFileZIP,
};

// @ts-expect-error overridable component
export const KnowledgeBaseFileIcon: OverridableComponent<KnowledgeBaseFileIconTypeMap> =
  forwardRef((props, ref) => {
    const { variant, ...other } = props;
    const Component = variantMapping[variant];

    return <Component {...other} ref={ref} />;
  });
