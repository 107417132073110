import { forwardRef } from 'react';
import { useDatePickerDefaultizedProps } from '../date-picker/date-picker.shared';
import { renderDateViewCalendar } from '../date-view-renderers';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import { useStaticPicker } from '../internals/hooks/use-static-picker';
import { validateDate } from '../internals/utils/validation/validate-date';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { DateView } from '../models';
import { StaticDatePickerProps } from './static-date-picker-type-map';

export const StaticDatePicker = forwardRef<
  HTMLDivElement,
  StaticDatePickerProps
>((inProps, ref) => {
  const defaultizedProps = useDatePickerDefaultizedProps<StaticDatePickerProps>(
    inProps,
    'MuiStaticDatePicker',
  );

  const displayStaticWrapperAs =
    defaultizedProps.displayStaticWrapperAs ?? 'mobile';

  const viewRenderers: PickerViewRendererLookup<
    Date | null,
    DateView,
    any,
    {}
  > = {
    day: renderDateViewCalendar,
    month: renderDateViewCalendar,
    year: renderDateViewCalendar,
    ...defaultizedProps.viewRenderers,
  };

  // Props with the default values specific to the static variant
  const props = {
    ...defaultizedProps,
    viewRenderers,
    displayStaticWrapperAs,
    yearsPerRow:
      defaultizedProps.yearsPerRow ??
      (displayStaticWrapperAs === 'mobile' ? 3 : 4),
    slotProps: {
      ...defaultizedProps.slotProps,
      toolbar: {
        hidden: displayStaticWrapperAs === 'desktop',
        ...defaultizedProps.slotProps?.toolbar,
      },
    },
  };

  const { renderPicker } = useStaticPicker<DateView, typeof props>({
    props,
    valueManager: singleItemValueManager,
    valueType: 'date',
    validator: validateDate,
    ref,
  });

  return renderPicker();
});
