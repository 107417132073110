import { v4 } from 'uuid';
import {
  ConfirmDialogState,
  ConfirmOptions,
  confirmAtom,
} from './confirm-states';
import { store } from './confirm-store';

const enqueueConfirm = (options: ConfirmOptions) => {
  const key = options.key ?? v4();
  store.set(confirmAtom, prev => [...prev, { key, options }]);
  return key;
};

const cancelConfirm = (key: string) => {
  store.set(confirmAtom, prev => prev.filter(d => d.key !== key));
};

const subscribe = (callback: (value: ConfirmDialogState[]) => void) => {
  return store.sub(confirmAtom, () => {
    const state = store.get(confirmAtom);
    callback(state);
  });
};

export const confirm = Object.assign(enqueueConfirm, {
  cancel: cancelConfirm,
  subscribe,
});
