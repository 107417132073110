import { useEventCallback } from '@allganize/hooks';
import { DialogProps } from '@allganize/ui-dialog';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FragmentOf, gql, readFragment, Scalars } from '../gql';
import { EditFileForm, EditFileFormProps } from '../edit-file/edit-file-form';
import { EditFileFormValues } from '../edit-file/use-edit-file-form';
import {
  FileSelectionForm,
  FileSelectionForm_ProjectFragment,
  FileSelectionFormProps,
} from './file-selection-form';
import { FileInput, FileSelectionFormValues } from './use-file-selection-form';

export const UploadFileForm_ProjectFragment = gql(
  `
  fragment UploadFileForm_ProjectFragment on Project {
    id
    ...FileSelectionForm_ProjectFragment
  }
`,
  [FileSelectionForm_ProjectFragment],
);

export interface UploadFileFormProps
  extends Pick<
      FileSelectionFormProps,
      'defaultValues' | 'onSubmit' | 'slotProps' | 'slots' | 'accessControl'
    >,
    Pick<
      DialogProps,
      'open' | 'fullScreen' | 'fullWidth' | 'maxWidth' | 'onClose'
    >,
    Pick<EditFileFormProps, 'enableOverwriteFolderAccess'> {
  ownership: Scalars<'KnowledgeBaseNodeOwnership'>;
  project: FragmentOf<typeof UploadFileForm_ProjectFragment>;
}

export const UploadFileForm: FunctionComponent<UploadFileFormProps> = props => {
  const {
    defaultValues: defaultValuesProp,
    accessControl,
    enableOverwriteFolderAccess,
    onClose,
    ownership,
    project: projectProp,
    ...other
  } = props;
  const project = readFragment(UploadFileForm_ProjectFragment, projectProp);
  const [selectedFile, setSelectedFile] = useState<FileInput | null>(null);
  const [defaultValues, setDefaultValues] = useState<
    FileSelectionFormValues | undefined
  >(defaultValuesProp);

  const editFileDefaultValues = useMemo(
    () =>
      selectedFile
        ? {
            name: selectedFile.name,
            hashtags: selectedFile.hashtags,
            accessControl: selectedFile.accessControl,
          }
        : undefined,
    [selectedFile],
  );

  useEffect(() => {
    setDefaultValues(defaultValuesProp);
    setSelectedFile(null);
  }, [defaultValuesProp]);

  const handleEditSubmit = useEventCallback(
    (fileValues: EditFileFormValues) => {
      const selectedFileId = selectedFile?.id;

      if (typeof selectedFileId === 'undefined') {
        return;
      }

      setDefaultValues(prev => {
        if (!prev) {
          return prev;
        }

        const index = prev.files.findIndex(file => file.id === selectedFileId);

        if (index < 0) {
          return prev;
        }

        return {
          ...prev,
          files: [
            ...prev.files.slice(0, index),
            {
              ...prev.files[index],
              ...fileValues,
            },
            ...prev.files.slice(index + 1),
          ],
        };
      });

      setSelectedFile(null);
    },
  );

  const handleEditClose = useEventCallback(() => {
    setSelectedFile(null);
  });

  const handleEdit = useEventCallback(
    (file: FileInput, values: FileSelectionFormValues) => {
      if (ownership === gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL')) {
        return;
      }

      setDefaultValues(values);
      setSelectedFile(file);
    },
  );

  const handleClose = useEventCallback(() => {
    setDefaultValues(defaultValuesProp);
    setSelectedFile(null);
    onClose?.({}, 'closeButtonClick');
  });

  if (
    selectedFile &&
    ownership !== gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL')
  ) {
    return (
      <EditFileForm
        open={other.open}
        onClose={handleEditClose}
        onEditSubmit={handleEditSubmit}
        projectId={project.id}
        fileId={selectedFile.id}
        defaultValues={editFileDefaultValues}
        parentAccessControl={accessControl}
        enableOverwriteFolderAccess={enableOverwriteFolderAccess}
      />
    );
  }

  return (
    <FileSelectionForm
      {...other}
      defaultValues={defaultValues}
      ownership={ownership}
      project={project}
      accessControl={accessControl}
      onEdit={
        ownership === gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL')
          ? undefined
          : handleEdit
      }
      onClose={handleClose}
    />
  );
};
