import { toast } from '@allganize/ui-toast';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { useMutation } from '@apollo/client/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { compact, noop } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { gql, Scalars } from '../gql';

export interface MoveNodeFormValues {
  targetNodeId: Scalars<'ID'> | null;
  maintainAuthority?: boolean;
}

const createValidationSchema = () => {
  return z.object({
    targetNodeId: z.string().nullable(),
    maintainAuthority: z.boolean().optional(),
  }) satisfies z.ZodSchema<MoveNodeFormValues>;
};

const MoveKnowledgeBaseNodeMutation = gql(`
  mutation MoveKnowledgeBaseNodeMutation(
    $nodeIds: [ID!]
    $project: ProjectWhereUniqueInput!
    $targetNodeId: ID
    $maintainAuthority: Boolean
  ) {
    moveKnowledgeBaseNodes(
      nodeIds: $nodeIds
      project: $project
      targetNodeId: $targetNodeId
      maintainAuthority: $maintainAuthority
    ) {
      # to update the cache
      knowledgeBaseNodes {
        id
        name
        parent {
          id
        }
        ancestors {
          id
          name
        }
      }

      failedNodes {
        id
      }

      errors {
        __typename
        key
        message
        field
        info
      }
    }
  }
`);

export interface UseMoveNodeFormOptions {
  onSubmit?(): void;
  nodeIds: Scalars<'ID'>[];
  projectId: Scalars<'ID'>;
  defaultValues?: MoveNodeFormValues;
}

export const useMoveNodeForm = ({
  onSubmit,
  nodeIds,
  projectId,
  defaultValues,
}: UseMoveNodeFormOptions) => {
  const intl = useIntl();
  const validationSchema = useMemo(() => createValidationSchema(), []);
  const [mutate] = useMutation(MoveKnowledgeBaseNodeMutation);

  const formatErrorMessage = useFormatErrorMessage();

  const form = useForm<MoveNodeFormValues>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const { reset } = form;

  const submit = async (values: MoveNodeFormValues) => {
    if (!nodeIds.length) {
      return;
    }

    try {
      const result = await mutate({
        variables: {
          nodeIds,
          project: { id: projectId },
          targetNodeId: values.targetNodeId,
          maintainAuthority: values.maintainAuthority,
        },
        onError: noop,
      });

      if (result.data?.moveKnowledgeBaseNodes?.errors?.length) {
        throw new AlliGraphQLError(result.data.moveKnowledgeBaseNodes.errors);
      }

      if (
        !result.data?.moveKnowledgeBaseNodes ||
        (!result.data.moveKnowledgeBaseNodes.knowledgeBaseNodes?.length &&
          !result.data.moveKnowledgeBaseNodes.failedNodes?.length)
      ) {
        throw new Error(formatErrorMessage());
      }

      if (result.data.moveKnowledgeBaseNodes.knowledgeBaseNodes?.length) {
        toast.success(
          intl.formatMessage(
            {
              id: 'knowledge-base.move-node.success',
              defaultMessage:
                'Successfully moved {count, plural, one {# item} other {# items}}',
              description: 'Success message when moved nodes',
            },
            {
              count: nodeIds.length,
            },
          ),
        );
        onSubmit?.();
      }

      if (result.data.moveKnowledgeBaseNodes.failedNodes?.length) {
        toast.error(
          intl.formatMessage(
            {
              id: 'knowledge-base.move-node.error.failed-nodes',
              defaultMessage:
                'Failed to move {count, plural, one {# item} other {# items}}',
              description: 'Error message when failed to move nodes',
            },
            {
              count: result.data.moveKnowledgeBaseNodes.failedNodes.length,
            },
          ),
        );
      }
      reset();
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const error = compact(err.errors)[0];

        if (error) {
          toast.error(
            intl.formatMessage(
              AlliGraphQLError.getMallyErrorMessageDescriptor(error),
            ),
          );
        }
        return;
      }

      if (err instanceof Error) {
        toast.error(err.message);
      }

      if (typeof err === 'string') {
        toast.error(err);
      }
    }
  };

  // Reset the form when the default values change
  useEffect(() => {
    reset(defaultValues, {
      keepIsSubmitSuccessful: true,
      keepIsSubmitted: true,
      keepSubmitCount: true,
      keepDirty: false,
    });
  }, [defaultValues, reset]);

  return {
    form,
    submit,
  };
};
