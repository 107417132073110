import {
  SubscriptionHookOptions,
  useQuery,
  useSubscription,
} from '@apollo/client/react';
import { gql, ResultOf, Scalars, VariablesOf } from '../gql';
import { KnowledgeBaseBuildStateFragment } from './training-dialog';

const KnowledgeBaseBuildStateQuery = gql(
  `
  query KnowledgeBaseBuildStateQuery(
    $where: ProjectWhereUniqueInput!
  ) {
    project(
      where: $where
    ) {
      id
      ...KnowledgeBaseBuildStateFragment
    }
  }
`,
  [KnowledgeBaseBuildStateFragment],
);

const KnowledgeBaseBuildStateSubscription = gql(
  `
  subscription KnowledgeBaseBuildStateSubscription(
    $where: ProjectWhereUniqueInput!
  ) {
    mrcBuild(
      where: $where
    ) {
      id
      ...KnowledgeBaseBuildStateFragment
    }
  }
`,
  [KnowledgeBaseBuildStateFragment],
);

export const useKnowledgeBaseBuildStateQuery = (projectId: Scalars<'ID'>) => {
  const result = useQuery(KnowledgeBaseBuildStateQuery, {
    variables: {
      where: { id: projectId },
    },
  });

  return result;
};

export const useKnowledgeBaseBuildStateSubscription = (
  projectId: Scalars<'ID'>,
  opts?: Partial<
    SubscriptionHookOptions<
      ResultOf<typeof KnowledgeBaseBuildStateSubscription>,
      VariablesOf<typeof KnowledgeBaseBuildStateSubscription>
    >
  >,
) => {
  return useSubscription(KnowledgeBaseBuildStateSubscription, {
    variables: {
      where: { id: projectId },
    },
    ...opts,
  });
};
