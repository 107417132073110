import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { IntlShape, MessageDescriptor, PrimitiveType } from 'react-intl';

export interface AlliGraphQLErrorFragment
  extends Pick<
    UserQueryTypes.Error,
    '__typename' | 'field' | 'info' | 'message'
  > {
  key: UserQueryTypes.MallyError | `${UserQueryTypes.MallyError}`;
}

interface GroupErrorsByFieldOptions<K extends string> {
  intl: IntlShape;
  isErrorField(field: string | null): field is K;
}

export class AlliGraphQLError extends Error {
  public static getMallyErrorMessageDescriptor(
    error: AlliGraphQLErrorFragment,
  ): MessageDescriptor & {
    values?: Record<string, PrimitiveType>;
  } {
    let values: Record<string, PrimitiveType> | undefined;

    if (error.info) {
      try {
        values = JSON.parse(error.info);
      } catch {
        //
      }
    }

    return {
      id: `MallyError.${error.key}`,
      defaultMessage: error.message || error.key,
      description: `MallyError.${error.key}`,
      values,
    };
  }

  constructor(public readonly errors: (AlliGraphQLErrorFragment | null)[]) {
    super();
  }

  public groupErrorsByField<K extends string>({
    intl,
    isErrorField,
  }: GroupErrorsByFieldOptions<K>) {
    const errorGroups = this.errors.reduce<
      Partial<
        Record<
          K | 'root',
          Array<{
            error: AlliGraphQLErrorFragment;
            message: string;
          }>
        >
      >
    >((acc, curr) => {
      // Skip if the current error is null or undefined
      if (!curr) {
        return acc;
      }

      // Determine the field associated with the error, defaulting to 'root' if not specified
      const field: K | 'root' = isErrorField(curr.field) ? curr.field : 'root';
      const descriptor = AlliGraphQLError.getMallyErrorMessageDescriptor(curr);

      // Add the error message to the corresponding error group
      return {
        ...acc,
        [field]: [
          ...(acc[field] ?? []),
          {
            error: curr,
            message: intl.formatMessage(descriptor, descriptor.values),
          },
        ],
      };
    }, {});

    return errorGroups;
  }
}
