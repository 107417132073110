import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { MessageDescriptor, defineMessages } from 'react-intl';

export const mallyErrors = defineMessages({
  [UserQueryTypes.MallyError.UNKNOWN]: {
    id: 'MallyError.UNKNOWN',
    defaultMessage: 'Something went wrong. Please try again later.',
    description: 'MallyError.UNKNOWN',
  },
  [UserQueryTypes.MallyError.INVALID_ACCESS_TOKEN]: {
    id: 'MallyError.INVALID_ACCESS_TOKEN',
    defaultMessage: 'Invalid access token',
    description: 'MallyError.INVALID_ACCESS_TOKEN',
  },
}) satisfies Partial<Record<UserQueryTypes.MallyError, MessageDescriptor>>;
