import { components } from '@allganize/ui-select';
import { css } from '@emotion/react';
import { MenuListProps } from 'react-select';
import { AccessControlSelectOption } from './access-control-select-type-map';
import { LoadingButton } from '@allganize/ui-button';
import { IcAdd } from '@allganize/ui-icons';
import { Tab, tabClasses, Tabs } from '@allganize/ui-tab';
import { useTheme } from '@allganize/ui-theme';
import {
  AccessControlSelectTabContextValue,
  AccessControlSelectCreatableContextValue,
} from './access-control-select-context';

const CreateOption: React.FC<AccessControlSelectCreatableContextValue> = ({
  inputValue,
  isCreatable,
  onCreateOption,
  formatCreateLabel,
  loading,
}) => {
  const theme = useTheme();

  if (!inputValue || !isCreatable) return null;

  return (
    <LoadingButton
      css={css`
        display: flex;
        gap: 4px;
        padding: 8px 12px;
        height: 36px;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-top: 1px solid ${theme.palette.border.divider.subtle};
        border-radius: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        ${theme.typography.label14};
        &:hover {
          border-color: ${theme.palette.backgroundInteractive.primarySubtle
            .hover};
        }
      `}
      onClick={() => onCreateOption?.(inputValue)}
      startIcon={<IcAdd />}
      loading={loading}
      color="primary"
    >
      {formatCreateLabel(inputValue)}
    </LoadingButton>
  );
};

const TabArea: React.FC<AccessControlSelectTabContextValue> = ({
  selectedTab,
  onTabChange,
  tabOptions,
  TabsProps,
}) => {
  const theme = useTheme();

  return (
    <Tabs
      css={css`
        border-bottom: 1px solid ${theme.palette.border.divider.default};

        .${tabClasses.root} {
          text-transform: capitalize;
        }
      `}
      {...TabsProps}
      value={selectedTab}
      onChange={onTabChange}
    >
      {tabOptions.map(tabOption => (
        <Tab
          key={tabOption.value}
          value={tabOption.value}
          label={tabOption.label}
        />
      ))}
    </Tabs>
  );
};

export const AccessControlSelectMenuList = <
  T extends AccessControlSelectOption = AccessControlSelectOption,
>({
  selectProps,
  ...others
}: MenuListProps<T, true>) => {
  const { tabContextValue, creatableContextValue } = selectProps as any;

  return (
    <div
      css={css`
        padding: 8px 0;
      `}
    >
      {tabContextValue && <TabArea {...tabContextValue} />}
      <components.MenuList {...others} selectProps={selectProps} />
      {creatableContextValue && <CreateOption {...creatableContextValue} />}
    </div>
  );
};
