import { isApolloError } from '@apollo/client/errors';

const getErrorStatusCode = (err: Error): number | null => {
  if (!isApolloError(err)) {
    return null;
  }

  if (!err.networkError) {
    return null;
  }

  if (!('statusCode' in err.networkError)) {
    return null;
  }

  if (typeof err.networkError.statusCode !== 'number') {
    return null;
  }

  return err.networkError.statusCode;
};

const NETWORK_ERROR_STATUS_CODES = [499, 502, 503, 403];

export const isNetworkError = (err: Error): boolean => {
  const statusCode = getErrorStatusCode(err);
  return statusCode !== null && NETWORK_ERROR_STATUS_CODES.includes(statusCode);
};
