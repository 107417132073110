import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';
import { PanelItem } from '../panel-item/panel-item';
import { PanelUser, PanelUser_AgentFragment } from './panel-user';

export const UpdatedBy_KnowledgeBaseNodeFragment = gql(
  `
  fragment UpdatedBy_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    updatedAt

    updatedBy {
      ...PanelUser_AgentFragment
    }
  }
`,
  [PanelUser_AgentFragment],
);

interface UpdatedByProps {
  node: FragmentOf<typeof UpdatedBy_KnowledgeBaseNodeFragment>;
}

export const UpdatedBy: FunctionComponent<UpdatedByProps> = props => {
  const { node: nodeProp } = props;
  const node = readFragment(UpdatedBy_KnowledgeBaseNodeFragment, nodeProp);

  if (!node.updatedBy) {
    return null;
  }

  return (
    <PanelItem
      label={
        <FormattedMessage
          id="knowledge-base-side-panel.updated-by"
          defaultMessage="Last updated by"
          description="Knowledge base side panel last updated by label text"
        />
      }
    >
      <PanelUser agent={node.updatedBy} timestamp={node.updatedAt} />
    </PanelItem>
  );
};
