import { useTheme } from '@allganize/ui-theme';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';

const dotAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0.4;
  }
  17% {
    transform: translateY(-6px);
    opacity: 1;
  }
  33% {
    transform: translateY(0);
    opacity: 0.4;
  }
  50% {
    transform: translateY(0);
    opacity: 0.4;
  }
  67% {
    transform: translateY(0);
    opacity: 0.4;
  }
  83% {
    transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    transform: translateY(0);
    opacity: 0.4;
  }
`;

const Dot = styled.span`
  position: relative;
  display: inline-block;
  width: 6px;
  height: 6px;
  transform: translateY(0);
  background-color: currentColor;
  opacity: 0.4;
  animation: ${dotAnimation} 1.5s infinite linear;

  &:first-of-type {
    margin-left: 0;
  }

  &:nth-of-type(1) {
    animation-delay: 0ms;
  }

  &:nth-of-type(2) {
    animation-delay: 0.5s;
  }

  &:nth-of-type(3) {
    animation-delay: 1s;
  }
`;

export const LoadingDots = forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();

  return (
    <div
      data-testid="loading-dots"
      css={css`
        display: inline-flex;
        align-items: center;
        height: 20px;
        gap: 4px;
      `}
      ref={ref}
    >
      <Dot
        css={css`
          color: ${theme.palette.grayAlpha[500]};
          border-radius: ${theme.radius.round}px;
        `}
      />
      <Dot
        css={css`
          color: ${theme.palette.grayAlpha[500]};
          border-radius: ${theme.radius.round}px;
        `}
      />
      <Dot
        css={css`
          color: ${theme.palette.grayAlpha[500]};
          border-radius: ${theme.radius.round}px;
        `}
      />
    </div>
  );
});
