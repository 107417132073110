import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { AlliChatRow } from '../alli-chat-row';
import { AlliChatRowItem } from '../alli-chat-row-item';
import { LoadingDots } from '../loading-dots/loading-dots';
import { AlliChatRowTypingProps } from './alli-chat-row-typing.types';

export const AlliChatRowTyping = forwardRef<
  HTMLDivElement,
  AlliChatRowTypingProps
>((props, ref) => {
  const { avatar, className } = props;

  return (
    <AlliChatRow
      data-testid="alli-chat-row-typing"
      className={className}
      ref={ref}
    >
      <AlliChatRowItem avatar={avatar}>
        <div
          css={css`
            line-height: 1;
          `}
        >
          <LoadingDots />
        </div>
      </AlliChatRowItem>
    </AlliChatRow>
  );
});
