import { useMeasure } from '@allganize/hooks';
import { useState } from 'react';
import { KNOWLEDGE_BASE_BREADCRUMB_ITEM_MAX_WIDTH } from '../knowledge-base-breadcrumb-item/constants';
import {
  KNOWLEDGE_BASE_BREADCRUMBS_COLLAPSE_WIDTH,
  KNOWLEDGE_BASE_BREADCRUMBS_SEPARATOR_HORIZONTAL_MARGIN,
  KNOWLEDGE_BASE_BREADCRUMBS_SEPARATOR_WIDTH,
} from '../knowledge-base-breadcrumbs/constants';

const calculateMaxItems = (containerWidth: number): number => {
  const spaceWithoutCollapse =
    containerWidth -
    KNOWLEDGE_BASE_BREADCRUMBS_COLLAPSE_WIDTH -
    KNOWLEDGE_BASE_BREADCRUMBS_SEPARATOR_HORIZONTAL_MARGIN;
  const breadcrumbWithSeparatorWidth =
    KNOWLEDGE_BASE_BREADCRUMBS_SEPARATOR_WIDTH +
    KNOWLEDGE_BASE_BREADCRUMBS_SEPARATOR_HORIZONTAL_MARGIN +
    KNOWLEDGE_BASE_BREADCRUMB_ITEM_MAX_WIDTH;

  return Math.max(
    1,
    Math.trunc(spaceWithoutCollapse / breadcrumbWithSeparatorWidth),
  );
};

export const useKnowledgeBaseBreadcrumbs = <T extends HTMLElement>() => {
  const [maxItems, setMaxItems] = useState(1);
  const { measureRef } = useMeasure<T>({
    offset: true,
    onResize(contentRect) {
      if (!contentRect.offset) {
        return;
      }

      setMaxItems(calculateMaxItems(contentRect.offset.width));
    },
  });

  return { containerRef: measureRef, maxItems };
};
