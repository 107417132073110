import {
  AccessControlSelectAgentOption,
  AccessControlSelectUserOption,
  parseAccessAgent,
  ParseAccessAgent_AgentFragment,
  parseAccessUserFilter,
  ParseAccessUserFilter_AccessUserFilterFragment,
} from '@allganize/access-control';
import { useUnmount } from '@allganize/hooks';
import { useQuery } from '@apollo/client/react';
import { useAtom } from 'jotai';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { gql, Scalars } from '../gql';
import {
  KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment,
  useRootAccessControl,
  useRootAccessControlQuery,
} from '../hooks/use-access-control';
import { EditFolderForm } from './edit-folder-form';
import { editFolderAtom } from './use-edit-folder';
import { EditFolderFormValues } from './use-edit-folder-form';

const defaultEditFolderFormValues: EditFolderFormValues = {
  name: '',
  accessControl: {
    isOverwriteFolderAccess: true,
    accessAgents: [],
    viewAccessToAgent: true,
    accessUserFilters: [],
    viewAccessToUser: true,
  },
};

const KnowledgeBaseNodeFolderQuery = gql(
  `
  query KnowledgeBaseNodeFolderQuery(
    $where: KnowledgeBaseNodeWhereUniqueInput!
  ) {
    knowledgeBaseNode(
      where: $where
    ) {
      id
      name
      viewAccessToUser
      viewAccessToAgent
      isOverwriteFolderAccess

      parent {
        id
      }

      accessUserFilters {
        ...ParseAccessUserFilter_AccessUserFilterFragment
      }

      accessAgents {
        accessType
        isAdmin

        agent {
          ...ParseAccessAgent_AgentFragment
        }
      }

      parent {
        id
        ...KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment
      }
    }
  }
`,
  [
    ParseAccessUserFilter_AccessUserFilterFragment,
    ParseAccessAgent_AgentFragment,
    KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment,
  ],
);

export interface EditFolderProps {
  projectId: Scalars<'ID'>;
}

export const EditFolder: FunctionComponent<EditFolderProps> = props => {
  const { projectId } = props;
  const intl = useIntl();
  const [state, setState] = useAtom(editFolderAtom(projectId));

  const { data, loading, refetch } = useQuery(KnowledgeBaseNodeFolderQuery, {
    variables: {
      where: {
        id: state.folderId || '',
        project: {
          id: projectId,
        },
      },
    },
    skip: state.folderId === null,
    fetchPolicy: 'network-only',
  });
  const rootNodeAccessControlQueryResult = useRootAccessControlQuery({
    variables: { where: { id: projectId } },
    skip: data?.knowledgeBaseNode?.parent !== null,
  });
  const rootNodeAccessControl = useRootAccessControl({
    project: rootNodeAccessControlQueryResult.data?.project,
  });

  const parentAccessControl = useMemo(() => {
    if (data?.knowledgeBaseNode?.parent === null) return rootNodeAccessControl;
    return data?.knowledgeBaseNode?.parent || undefined;
  }, [data?.knowledgeBaseNode?.parent, rootNodeAccessControl]);

  const defaultValues: EditFolderFormValues = useMemo(
    () =>
      data?.knowledgeBaseNode
        ? {
            name: data.knowledgeBaseNode.name,
            accessControl: {
              isOverwriteFolderAccess:
                data.knowledgeBaseNode.isOverwriteFolderAccess,
              accessAgents: (data.knowledgeBaseNode.accessAgents || []).reduce<
                AccessControlSelectAgentOption[]
              >((acc, agent) => {
                const parsedAgent = parseAccessAgent(agent);
                return parsedAgent ? [...acc, parsedAgent] : acc;
              }, []),
              viewAccessToAgent: data.knowledgeBaseNode.viewAccessToAgent,
              accessUserFilters:
                data.knowledgeBaseNode.accessUserFilters.map<AccessControlSelectUserOption>(
                  user => parseAccessUserFilter(user, intl),
                ),
              viewAccessToUser: data.knowledgeBaseNode.viewAccessToUser,
            },
          }
        : defaultEditFolderFormValues,
    [data, intl],
  );

  const handleClose = () => {
    setState({
      projectId,
      open: false,
      folderId: null,
    });
  };

  useEffect(() => {
    if (!state.open) return;
    refetch();
  }, [state.open, refetch]);

  useUnmount(handleClose);

  if (loading) return null;

  return (
    <EditFolderForm
      {...state}
      defaultValues={defaultValues}
      parentAccessControl={parentAccessControl}
      enableOverwriteFolderAccess={!!data?.knowledgeBaseNode?.parent}
      onClose={handleClose}
    />
  );
};
