/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces';

import { AnswerHighlightFragment } from '../fragments/answer-highlight-fragment';
import { PreviewCapacityFragment } from '../fragments/preview-capacity-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type KnowledgeBasePreviewQueryVariables = Types.Exact<{
  where: Types.KnowledgeBaseWhereUniqueInput;
  pageNo?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  highlightIndexes?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['Int']['input']>>
  >;
  highlights?: Types.InputMaybe<Types.KnowledgeBasePreviewHighlightsInput>;
  exactAnswer?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type KnowledgeBasePreviewQuery = { __typename: 'UserQuery' } & {
  knowledgeBasePreview: Types.Maybe<
    { __typename: 'KnowledgeBasePreview' } & Pick<
      Types.KnowledgeBasePreview,
      | 'totalPageCount'
      | 'pageNo'
      | 'html'
      | 'highlightIndexes'
      | 'exactAnswer'
      | 'pdf'
    > & {
        highlights: Types.Maybe<
          { __typename: 'KnowledgeBasePreviewHighlights' } & Pick<
            Types.KnowledgeBasePreviewHighlights,
            'indexes'
          > & {
              pageIndexes: Types.Maybe<
                Array<
                  { __typename: 'KnowledgeBasePreviewPageHighlight' } & Pick<
                    Types.KnowledgeBasePreviewPageHighlight,
                    'page' | 'indexes'
                  >
                >
              >;
            }
        >;
        answerHighlights: Types.Maybe<
          { __typename: 'AnswerHighlightList' } & {
            highlights: Array<
              { __typename: 'AnswerHighlight' } & AnswerHighlightFragment
            >;
          }
        >;
        draftjs: Types.Maybe<
          { __typename: 'KnowledgeBaseDraftjsPreview' } & Pick<
            Types.KnowledgeBaseDraftjsPreview,
            'id' | 'answer'
          >
        >;
        knowledgeBase: Types.Maybe<
          | ({ __typename: 'CSVKnowledgeBase' } & Pick<
              Types.CSVKnowledgeBase,
              'id' | 'title' | 'originalWebUri'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'GoogleDocsKnowledgeBase' } & Pick<
              Types.GoogleDocsKnowledgeBase,
              'id' | 'title' | 'originalWebUri'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'HWPKnowledgeBase' } & Pick<
              Types.HWPKnowledgeBase,
              'id' | 'title' | 'originalWebUri'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'ImageKnowledgeBase' } & Pick<
              Types.ImageKnowledgeBase,
              'id' | 'title' | 'originalWebUri'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'MSDocsKnowledgeBase' } & Pick<
              Types.MSDocsKnowledgeBase,
              'id' | 'title' | 'originalWebUri'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'MSExcelKnowledgeBase' } & Pick<
              Types.MSExcelKnowledgeBase,
              'id' | 'title' | 'originalWebUri'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'MSPPTKnowledgeBase' } & Pick<
              Types.MSPPTKnowledgeBase,
              'id' | 'title' | 'originalWebUri'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'OldMSDocsKnowledgeBase' } & Pick<
              Types.OldMSDocsKnowledgeBase,
              'id' | 'title' | 'originalWebUri'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'PDFKnowledgeBase' } & Pick<
              Types.PDFKnowledgeBase,
              'id' | 'title' | 'originalWebUri'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'TextKnowledgeBase' } & Pick<
              Types.TextKnowledgeBase,
              'id' | 'title' | 'originalWebUri'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
        >;
      }
  >;
};

export const KnowledgeBasePreviewQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'KnowledgeBasePreviewQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'KnowledgeBaseWhereUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageNo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'highlightIndexes' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'highlights' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'KnowledgeBasePreviewHighlightsInput',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactAnswer' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeBasePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageNo' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'highlightIndexes' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'highlightIndexes' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'highlights' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'highlights' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'exactAnswer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'exactAnswer' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalPageCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pageNo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'highlightIndexes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'exactAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pdf' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'highlights' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'indexes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageIndexes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'page' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'indexes' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answerHighlights' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'highlights' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AnswerHighlightFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'draftjs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answer' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'knowledgeBase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'GoogleDocsKnowledgeBase',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalWebUri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewCapacity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PreviewCapacityFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TextKnowledgeBase' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalWebUri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewCapacity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PreviewCapacityFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ImageKnowledgeBase' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalWebUri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewCapacity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PreviewCapacityFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'MSDocsKnowledgeBase' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalWebUri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewCapacity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PreviewCapacityFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PDFKnowledgeBase' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalWebUri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewCapacity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PreviewCapacityFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'MSExcelKnowledgeBase' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalWebUri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewCapacity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PreviewCapacityFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'MSPPTKnowledgeBase' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalWebUri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewCapacity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PreviewCapacityFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'OldMSDocsKnowledgeBase',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalWebUri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewCapacity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PreviewCapacityFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CSVKnowledgeBase' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalWebUri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewCapacity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PreviewCapacityFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HWPKnowledgeBase' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalWebUri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewCapacity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PreviewCapacityFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnswerHighlightFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnswerHighlight' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bbox' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                { kind: 'Field', name: { kind: 'Name', value: 'right' } },
                { kind: 'Field', name: { kind: 'Name', value: 'top' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PreviewCapacityFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PreviewCapacity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pdf' } },
          { kind: 'Field', name: { kind: 'Name', value: 'html' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draftjs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  KnowledgeBasePreviewQuery,
  KnowledgeBasePreviewQueryVariables
>;
