import { ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment } from '@allganize/knowledge-base-tree-view';
import { useEventCallback } from '@allganize/hooks';
import { useQuery, useSubscription } from '@apollo/client/react';
import { uniqBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import { gql, Scalars } from '../gql';

export const KnowledgeBaseList__KnowledgeBaseNodeEdgeFragment = gql(`
  fragment KnowledgeBaseList__KnowledgeBaseNodeEdgeFragment on KnowledgeBaseNodeEdge {
    node {
      id
      name
      ownership

      parent {
        id
      }
    }
  }
`);

export const KnowledgeBaseListQuery = gql(
  `
  query KnowledgeBaseListQuery(
    $project: ProjectWhereUniqueInput!
    $filter: KnowledgeBaseNodeFilter
    $order: KnowledgeBaseNodeOrder
    $first: Int
  ) {
    knowledgeBaseNodes(
      project: $project
      filter: $filter
      order: $order
      first: $first
    ) {
      edges {
        cursor

        node {
          id
          name
          ownership

          ...on KnowledgeBaseNodeFile {
            processState
          }

          parent {
            id
          }
        }

        ...ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment
      }
    }
  }
`,
  [ConvertEdgesToNodes_KnowledgeBaseNodeEdgeFragment],
);

const KnowledgeBaseListSubscription = gql(`
  subscription KnowledgeBaseListSubscription(
    $project: ProjectWhereUniqueInput!
    $ids: [ID!]
  ) {
    knowledgeBaseNodes(
      where: $project
      ids: $ids
    ) {
      id

      ...on KnowledgeBaseNodeFile {
        processState
      }
    }
  }
`);

export interface UseKnowledgeBaseListQueryOptions {
  projectId: string | number;
  ownership?: Scalars<'KnowledgeBaseNodeOwnership'> | null;
  searchTerm?: string;
}

export const useKnowledgeBaseListQuery = ({
  projectId,
  ownership,
  searchTerm,
}: UseKnowledgeBaseListQueryOptions) => {
  const variables = {
    project: { id: projectId },
    filter: {
      parent: [null],
      type: [
        gql.scalar('KnowledgeBaseNodeType', 'FILE'),
        gql.scalar('KnowledgeBaseNodeType', 'FOLDER'),
      ],
      ownership: ownership ? [ownership] : null,
      searchTerm,
      processState:
        ownership === gql.scalar('KnowledgeBaseNodeOwnership', 'SHARED')
          ? [
              gql.scalar('ProcessStateEnum', 'INIT'),
              gql.scalar('ProcessStateEnum', 'PARSING'),
              gql.scalar('ProcessStateEnum', 'COMPLETED'),
            ]
          : [],
    },
    order: gql.scalar('KnowledgeBaseNodeOrder', 'NAME_ASC'),
    first: -1,
  };

  const [initialDataCount, setInitialDataCount] = useState<number | null>(null);
  const result = useQuery(KnowledgeBaseListQuery, {
    variables,
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (initialDataCount === null) {
        setInitialDataCount((data.knowledgeBaseNodes?.edges ?? []).length);
      }
    },
  });
  const { data, fetchMore, refetch } = result;

  const incompleteIds = useMemo(() => {
    return (
      data?.knowledgeBaseNodes?.edges.reduce<Scalars<'ID'>[]>((acc, curr) => {
        if (curr.node.__typename !== 'KnowledgeBaseNodeFile') {
          return acc;
        }

        if (
          curr.node.processState ===
            gql.scalar('ProcessStateEnum', 'COMPLETED') ||
          curr.node.processState ===
            gql.scalar('ProcessStateEnum', 'PARSING_FAIL')
        ) {
          return acc;
        }

        return [...acc, curr.node.id];
      }, []) ?? []
    );
  }, [data?.knowledgeBaseNodes?.edges]);

  useSubscription(KnowledgeBaseListSubscription, {
    variables: {
      project: variables.project,
      ids: incompleteIds,
    },
    skip: incompleteIds.length === 0,
  });

  const loadNextData = async (
    newFilter?: Scalars<'KnowledgeBaseNodeFilter'>,
  ) => {
    return fetchMore({
      variables: {
        ...variables,
        filter: {
          ...variables.filter,
          ...newFilter,
        },
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult.knowledgeBaseNodes?.edges.length) {
          return prev;
        }

        return {
          ...prev,
          ...fetchMoreResult,
          knowledgeBaseNodes: {
            ...prev.knowledgeBaseNodes,
            ...fetchMoreResult.knowledgeBaseNodes,
            edges: uniqBy(
              [
                ...fetchMoreResult.knowledgeBaseNodes.edges,
                ...(prev.knowledgeBaseNodes?.edges ?? []),
              ],
              edge => edge.node.id,
            ),
          },
        };
      },
    });
  };

  const refresh = useEventCallback(async () => {
    const ids = data?.knowledgeBaseNodes?.edges.map(edge => edge.node.id) ?? [];

    if (ids.length === 0) {
      await refetch();
      return;
    }

    await fetchMore({
      variables: {
        ...variables,
        filter: {
          ...variables.filter,
          parent: [null, ...ids],
        },
      },
      updateQuery(prev, { fetchMoreResult }) {
        return fetchMoreResult;
      },
    });
  });

  return {
    ...result,
    initialDataCount,
    loadNextData,
    refresh,
  };
};
