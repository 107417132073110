import { SelectProps } from '@allganize/ui-select';
import { useQuery } from '@apollo/client/react';
import { uniqBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { GroupBase, InputActionMeta } from 'react-select';
import { useDebounce } from 'use-debounce';
import { gql, VariablesOf } from '../gql';
import { HashtagSelectOptionBase } from '../hashtag-option-base';

export const KnowledgeBaseHashtagSelect_KnowledgeBaseHashtagsQuery = gql(`
  query KnowledgeBaseHashtagSelect_KnowledgeBaseHashtagsQuery(
    $where: ProjectWhereUniqueInput!
    $offset: Int
    $limit: Int
    $searchTerm: String
  ) {
    knowledgeBaseHashtags(
      where: $where
      offset: $offset
      limit: $limit
      searchTerm: $searchTerm
    ) {
      hashtags {
        hashtag
        count
      }

      totalCount
    }
  }
`);

export interface KnowledgeBaseHashtagSelectOption
  extends HashtagSelectOptionBase {
  data?: { count?: number };
}

export interface UseKnowledgeBaseHashtagSelectOptions<
  IsMulti extends boolean,
  Group extends GroupBase<KnowledgeBaseHashtagSelectOption> = GroupBase<KnowledgeBaseHashtagSelectOption>,
> extends SelectProps<KnowledgeBaseHashtagSelectOption, IsMulti, Group> {
  variables: Pick<
    VariablesOf<typeof KnowledgeBaseHashtagSelect_KnowledgeBaseHashtagsQuery>,
    'where' | 'limit'
  >;
}

export const useKnowledgeBaseHashtagSelect = <
  IsMulti extends boolean,
  Group extends GroupBase<KnowledgeBaseHashtagSelectOption>,
>({
  variables,
  inputValue: inputValueOption = '',
  onInputChange,
  onMenuScrollToBottom,
  ...other
}: UseKnowledgeBaseHashtagSelectOptions<IsMulti, Group>) => {
  const [inputValue, setInputValue] = useState(inputValueOption);
  const [searchTerm] = useDebounce(inputValue, 500);
  const intl = useIntl();

  const { data, fetchMore, loading, refetch } = useQuery(
    KnowledgeBaseHashtagSelect_KnowledgeBaseHashtagsQuery,
    {
      variables: {
        limit: 1000,
        ...variables,
        searchTerm,
        offset: 0,
      },
    },
  );

  const count = data?.knowledgeBaseHashtags?.hashtags.length ?? 0;
  const totalCount = data?.knowledgeBaseHashtags?.totalCount ?? 0;

  const options = data?.knowledgeBaseHashtags?.hashtags.reduce<
    HashtagSelectOptionBase[]
  >((acc, curr) => {
    if (!curr?.hashtag) {
      return acc;
    }

    return [
      ...acc,
      {
        label: `#${curr.hashtag}`,
        value: curr.hashtag,
        data: { count: curr.count },
        description: intl.formatMessage(
          {
            id: 'FAQ.Documents.HashtagForm.Hashtags.count',
            // eslint-disable-next-line formatjs/enforce-plural-rules
            defaultMessage: '{count, plural, =1 {# FAQ} other {# FAQs}}',
            description: 'FAQ document hashtag form hashtags count label text',
          },
          {
            count: curr.count,
          },
        ),
      },
    ];
  }, []);

  useEffect(() => {
    setInputValue(inputValueOption);
  }, [inputValueOption]);

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    setInputValue(newValue);
    onInputChange?.(newValue, actionMeta);
  };

  const handleMenuScrollToBottom = async (event: WheelEvent | TouchEvent) => {
    onMenuScrollToBottom?.(event);

    if (loading) {
      return;
    }

    if (count >= totalCount) {
      return;
    }

    await fetchMore({
      variables: {
        limit: 1000,
        ...variables,
        searchTerm,
        offset: count,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult.knowledgeBaseHashtags?.hashtags.length) {
          return prev;
        }

        return {
          ...prev,
          ...fetchMoreResult,
          knowledgeBaseHashtags: {
            ...prev.knowledgeBaseHashtags,
            ...fetchMoreResult.knowledgeBaseHashtags,
            hashtags: uniqBy(
              [
                ...(prev.knowledgeBaseHashtags?.hashtags ?? []),
                ...fetchMoreResult.knowledgeBaseHashtags.hashtags,
              ],
              hashtag => hashtag?.hashtag,
            ),
          },
        };
      },
    });
  };

  return {
    isClearable: true,
    isSearchable: true,
    hideSelectedOptions: true,
    isLoading: loading,
    options,
    ...other,
    components: { DropdownIndicator: null, ...other.components },
    inputValue,
    onInputChange: handleInputChange,
    onMenuScrollToBottom: handleMenuScrollToBottom,
    refetch,
  };
};
