import { useConversationList } from '@allganize/alli-sdk/hooks/use-conversation-list';
import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import {
  differenceInDays,
  isThisYear,
  isToday,
  isYesterday,
  set,
  sub,
} from 'date-fns';
import { sortBy } from 'lodash-es';
import { useMemo } from 'react';

type ConversationConnection = NonNullable<
  NonNullable<ReturnType<typeof useConversationList>['data']>['conversations']
>;
type Conversation = NonNullable<
  NonNullable<ConversationConnection['edges'][number]>['node']
>;

export type GroupedConversationListItem =
  | {
      type: 'conversation';
      conversation: NonNullable<
        NonNullable<ConversationConnection['edges'][number]>['node']
      >;
    }
  | {
      type: 'group-label';
      label:
        | 'today'
        | 'yesterday'
        | `date-${number}`
        | 'last-month'
        | `month-${number}`
        | `year-${number}`;
    };

const groupConversationsByDate = (edges: ConversationConnection['edges']) => {
  const groupedNodes = new Map<
    | 'today'
    | 'yesterday'
    | `date-${number}`
    | 'last-month'
    | `month-${number}`
    | `year-${number}`,
    {
      date: Date;
      conversations: Conversation[];
    }
  >();

  edges.forEach(edge => {
    if (!edge?.node) {
      return;
    }

    const date = set(edge.node.createdAt, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    if (isToday(date)) {
      const group = groupedNodes.get('today') ?? {
        date,
        conversations: [],
      };
      group.conversations.push(edge.node);
      groupedNodes.set('today', group);
      return;
    }

    if (isYesterday(date)) {
      const group = groupedNodes.get('yesterday') ?? {
        date,
        conversations: [],
      };
      group.conversations.push(edge.node);
      groupedNodes.set('yesterday', group);
      return;
    }

    if (differenceInDays(new Date(), date) <= 7) {
      const key: `date-${number}` = `date-${date.valueOf()}`;
      const group = groupedNodes.get(key) ?? {
        date,
        conversations: [],
      };
      group.conversations.push(edge.node);
      groupedNodes.set(key, group);
      return;
    }

    if (differenceInDays(new Date(), date) <= 30) {
      const group = groupedNodes.get('last-month') ?? {
        date,
        conversations: [],
      };
      group.conversations.push(edge.node);
      groupedNodes.set('last-month', group);
      return;
    }

    if (isThisYear(date)) {
      const month = date.getMonth();
      const group = groupedNodes.get(`month-${month}`) ?? {
        date,
        conversations: [],
      };
      group.conversations.push(edge.node);
      groupedNodes.set(`month-${month}`, group);
      return;
    }

    const year = date.getFullYear();
    const group = groupedNodes.get(`year-${year}`) ?? {
      date,
      conversations: [],
    };

    group.conversations.push(edge.node);
    groupedNodes.set(`year-${year}`, group);
  });

  return groupedNodes;
};

export const useGroupedConversationList = () => {
  const result = useConversationList({
    filter: {
      hiddenByUser: false,
    },
    order: UserQueryTypes.ConversationOrder.CREATED_AT_DESC,
  });
  const { data } = result;
  const groups = useMemo(() => {
    const groupedNodes = groupConversationsByDate(
      data?.conversations?.edges ?? [],
    );
    const result: GroupedConversationListItem[] = [];
    const groups = sortBy(Array.from(groupedNodes.keys()), key => {
      const now = set(Date.now(), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

      switch (key) {
        case 'today':
          return -now;
        case 'yesterday':
          return -sub(now, { days: 1 });
        case 'last-month':
          return -sub(now, { days: 30 });
        default:
          break;
      }

      if (key.startsWith('date-')) {
        const date = Number.parseInt(key.slice(5), 10);

        if (Number.isNaN(date)) {
          return Number.MAX_SAFE_INTEGER;
        }

        return -date;
      }

      if (key.startsWith('month-')) {
        const month = Number.parseInt(key.slice(6), 10);

        if (Number.isNaN(month)) {
          return Number.MAX_SAFE_INTEGER;
        }

        const year = new Date().getFullYear();
        const date = new Date(year, month, 1);
        return -date.valueOf();
      }

      if (key.startsWith('year-')) {
        const year = Number.parseInt(key.slice(5), 10);

        if (Number.isNaN(year)) {
          return Number.MAX_SAFE_INTEGER;
        }

        const date = new Date(year, 0, 1);
        return -date.valueOf();
      }

      return Number.MAX_SAFE_INTEGER;
    });

    groups.forEach(group => {
      if (!groupedNodes.has(group)) {
        return;
      }

      result.push({
        type: 'group-label',
        label: group,
      });
      result.push(
        ...(groupedNodes.get(group)?.conversations.map(conversation => ({
          type: 'conversation' as const,
          conversation,
        })) ?? []),
      );
    });

    return result;
  }, [data?.conversations?.edges]);

  return {
    ...result,
    items: groups,
  };
};
