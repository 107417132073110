import { resolveComponentProps } from '@mui/base/utils';
import { forwardRef } from 'react';
import { useMobilePicker } from '../internals/hooks/use-mobile-picker';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import { useLocaleText, useUtils } from '../internals/hooks/use-utils';
import { resolveTimeFormat } from '../internals/utils/time-utils';
import { extractValidationProps } from '../internals/utils/validation/extract-validation-props';
import { validateTime } from '../internals/utils/validation/validate-time';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { TimeView } from '../models';
import { TimeField } from '../time-field';
import { useTimePickerDefaultizedProps } from '../time-picker/time-picker.shared';
import { renderTimeViewClock } from '../time-view-renderers';
import { MobileTimePickerProps } from './mobile-time-picker-type-map';

export const MobileTimePicker = forwardRef<
  HTMLDivElement,
  MobileTimePickerProps
>((inProps, ref) => {
  const localeText = useLocaleText<Date>();
  const utils = useUtils<Date>();

  // Props with the default values common to all time pickers
  const defaultizedProps = useTimePickerDefaultizedProps<
    TimeView,
    MobileTimePickerProps
  >(inProps, 'MuiMobileTimePicker');

  const viewRenderers: PickerViewRendererLookup<
    Date | null,
    TimeView,
    any,
    {}
  > = {
    hours: renderTimeViewClock,
    minutes: renderTimeViewClock,
    seconds: renderTimeViewClock,
    ...defaultizedProps.viewRenderers,
  };
  const ampmInClock = defaultizedProps.ampmInClock ?? false;

  // Props with the default values specific to the mobile variant
  const props = {
    ...defaultizedProps,
    ampmInClock,
    viewRenderers,
    format: resolveTimeFormat(utils, defaultizedProps),
    slots: {
      field: TimeField,
      ...defaultizedProps.slots,
    },
    slotProps: {
      ...defaultizedProps.slotProps,
      field: (ownerState: any) => ({
        ...resolveComponentProps(defaultizedProps.slotProps?.field, ownerState),
        ...extractValidationProps(defaultizedProps),
        ref,
      }),
      toolbar: {
        hidden: false,
        ampmInClock,
        ...defaultizedProps.slotProps?.toolbar,
      },
    },
  };

  // @ts-expect-error internal prop
  const { renderPicker } = useMobilePicker<TimeView, typeof props>({
    props,
    valueManager: singleItemValueManager,
    valueType: 'time',
    getOpenDialogAriaText: localeText.openTimePickerDialogue,
    validator: validateTime,
  });

  return renderPicker();
});
