import { KnowledgeBaseNodeOwnership } from '@allganize/data-access-alli-enums';
import { Tab, Tabs } from '@allganize/ui-tab';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { gql, Scalars } from '../gql';

const tabs = Object.values(KnowledgeBaseNodeOwnership);
export const defaultTab = gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL');
export type TabOption = Scalars<'KnowledgeBaseNodeOwnership'>;

const tabLabels = defineMessages<TabOption>({
  [gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL')]: {
    id: 'personal',
    defaultMessage: 'Personal',
    description: 'Personal',
  },
  [gql.scalar('KnowledgeBaseNodeOwnership', 'SHARED')]: {
    id: 'shared',
    defaultMessage: 'Shared',
    description: 'Shared',
  },
});

interface DocumentsPickerTabsProps {
  tab?: TabOption | null;
  onTabChange: (tab: TabOption) => void;
}

export const DocumentsPickerTabs = ({
  tab = defaultTab,
  onTabChange,
}: DocumentsPickerTabsProps) => {
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, value: any) => {
    onTabChange(value);
  };

  return (
    <div
      css={css`
        height: 56px;
        padding: 10px 8px;
        background-color: ${theme.palette.unstable_background.white};
        border-bottom: 1px solid ${theme.palette.border.divider.default};
      `}
    >
      <Tabs value={tab ?? false} variant="filled" onChange={handleChange}>
        {tabs.map(option => {
          return (
            <Tab
              key={option}
              value={option}
              label={<FormattedMessage {...tabLabels[option]} />}
            />
          );
        })}
      </Tabs>
    </div>
  );
};
