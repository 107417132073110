import { DraftFilePlugin } from '@allganize/draft-file-plugin';
import { DraftImagePlugin } from '@allganize/draft-image-plugin';
import {
  ContentState,
  EditorState,
  removeBlockIfEmpty,
} from '@allganize/draft-input';

export const getChatMediaValue = <
  T extends { mediaType?: string | null; url: string },
>({
  media,
}: {
  media: T | null | (T | null)[];
}): ContentState => {
  const editorState = (Array.isArray(media) ? media : [media]).reduce(
    (acc, curr) => {
      if (!curr) {
        return acc;
      }

      if (curr.mediaType === 'IMAGE') {
        return DraftImagePlugin.addImage(acc, curr.url, curr);
      }

      return DraftFilePlugin.addFile(acc, curr.url, curr);
    },
    EditorState.createEmpty(),
  );
  const contentStateWithMedia = editorState.getCurrentContent();
  const firstBlockRemoved = removeBlockIfEmpty(
    contentStateWithMedia,
    contentStateWithMedia.getBlockMap().first(),
  );
  return removeBlockIfEmpty(
    firstBlockRemoved,
    firstBlockRemoved.getBlockMap().last(),
  );
};
