import { useId, useWindow } from '@allganize/hooks';
import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import {
  FileDropzone,
  FileList,
  FileListItem,
  FileListItemIcon,
  FileListItemText,
  fileListItemTextClasses,
} from '@allganize/ui-file-input';
import { FormControl, FormHelperText, FormLabel } from '@allganize/ui-form';
import { IcClose, IcOpenInNew, IcUpload } from '@allganize/ui-icons';
import { InputLabel } from '@allganize/ui-input';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { noop } from 'lodash-es';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FileFormValue, FormValueFormValues } from './use-form-value-form';
import { formatFilename, getFileUrl } from './use-form-value-form.utils';

interface FileFormValueFieldProps {
  disabled?: boolean;
  name: `formValues.${number}`;
}

export const FileFormValueField: FunctionComponent<
  FileFormValueFieldProps
> = props => {
  const { disabled: disabledProp, name } = props;
  const theme = useTheme();
  const { ref: rootRef, window: win } = useWindow();
  const { control } = useFormContext<FormValueFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name,
    disabled: disabledProp,
  });
  const {
    field: { ref: valueRef, ...value },
    fieldState: valueState,
  } = useController({ control, name: `${name}.value`, disabled: disabledProp });
  const disabled = field.disabled || formState.disabled;
  const reactId = useId();
  const inputId = `${reactId}-${field.name}`;
  const labelId = `${inputId}-label`;
  const helperTextId = `${inputId}-helper-text`;
  const { cleanup, url } = useMemo(() => {
    if (field.value.type !== 'file') {
      return { cleanup: noop, url: null };
    }

    return getFileUrl({ value: field.value.value, window: win ?? undefined });
  }, [field.value.type, field.value.value, win]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  if (field.value.type !== 'file') {
    return null;
  }

  if (disabled) {
    const filename = formatFilename({
      value: field.value.value,
      window: win ?? undefined,
    });

    return (
      <FormControl fullWidth required={field.value.required} ref={rootRef}>
        <FormLabel>{field.value.label}</FormLabel>

        {!field.value.value && <Text variant="body14">-</Text>}

        {field.value.value && (
          <FileList disablePadding>
            <FileListItem
              secondaryAction={
                <IconButton
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  edge="end"
                  href={url ?? undefined}
                  download={filename}
                >
                  <IcOpenInNew />
                </IconButton>
              }
            >
              <FileListItemIcon />

              <FileListItemText
                primary={<Truncate clamp={1}>{filename}</Truncate>}
                primaryTextProps={{ title: filename ?? undefined }}
              />
            </FileListItem>
          </FileList>
        )}
      </FormControl>
    );
  }

  const fieldValue = value.value as FileFormValue['value'];
  const filename = formatFilename({
    value: fieldValue,
    window: win ?? undefined,
  });
  const errorMessage = fieldState.error?.message || valueState.error?.message;

  return (
    <FormControl
      fullWidth
      required={field.value.required}
      error={fieldState.invalid || valueState.invalid}
      ref={rootRef}
    >
      <InputLabel htmlFor={inputId} id={labelId}>
        {field.value.label}
      </InputLabel>

      <FileDropzone
        inputProps={{
          name: field.name,
          'aria-describedby': helperTextId,
        }}
        inputRef={field.ref}
        maxFiles={1}
        onDropAccepted={files => {
          value.onChange(files[0] ?? null);
        }}
        onBlur={field.onBlur}
        css={css`
          padding: 12px;
          display: flex;
          align-items: center;
          flex-direction: column;
          text-align: center;
        `}
        dragOverlay={
          <div
            css={css`
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              text-align: center;
            `}
          >
            <IcUpload
              css={css`
                margin-bottom: 4px;
              `}
            />

            <Text variant="body14">
              <FormattedMessage
                id="form.file.placeholder.drag"
                defaultMessage="Drop file here"
                description="File input placeholder dragging state"
              />
            </Text>
          </div>
        }
      >
        <IcUpload
          css={css`
            margin-bottom: 4px;
          `}
        />

        <Text variant="body14">
          <FormattedMessage
            id="form.file.placeholder"
            defaultMessage="Click or drag & drop{br}a file to upload"
            description="File input placeholder"
            values={{
              br: <br />,
            }}
          />
        </Text>
      </FileDropzone>

      {errorMessage && !fieldValue && (
        <FormHelperText id={helperTextId} error>
          {errorMessage}
        </FormHelperText>
      )}

      {fieldValue && (
        <FileList
          disablePadding
          css={css`
            margin-top: 8px;
          `}
        >
          <FileListItem
            secondaryAction={
              <IconButton
                edge="end"
                onClick={() => {
                  value.onChange(null);
                }}
              >
                <IcClose />
              </IconButton>
            }
          >
            <FileListItemIcon />

            <FileListItemText
              css={css`
                .${fileListItemTextClasses.secondary} {
                  color: ${theme.palette.foreground.error};
                }
              `}
              primary={<Truncate clamp={1}>{filename}</Truncate>}
              primaryTextProps={{ title: filename ?? undefined }}
              secondary={errorMessage}
              secondaryTextProps={{ id: helperTextId }}
            />
          </FileListItem>
        </FileList>
      )}
    </FormControl>
  );
};
