import { useEventCallback } from '@allganize/hooks';
import { useSuspenseQuery } from '@apollo/client/react';
import { CreateSlotsAndSlotProps } from '@mui/material/utils/types';
import { FunctionComponent, useImperativeHandle } from 'react';
import {
  FileSidePanel,
  FileSidePanel_KnowledgeBaseNodeFragment,
  FileSidePanel_ProjectFragment,
  FileSidePanelSlotProps,
  FileSidePanelSlots,
} from '../file-side-panel/file-side-panel';
import {
  FolderSidePanel,
  FolderSidePanel_KnowledgeBaseNodeFragment,
  FolderSidePanelSlotProps,
  FolderSidePanelSlots,
} from '../folder-side-panel/folder-side-panel';
import { FragmentOf, gql, readFragment } from '../gql';
import { SidePanelHeader_KnowledgeBaseNodeFragment } from './side-panel-header';
import {
  SidePanelContainer,
  SidePanelContainerProps,
} from './side-panel-container';

export const KnowledgeBaseSidePanel_KnowledgeBaseNodeQuery = gql(
  `
  query KnowledgeBaseSidePanel_KnowledgeBaseNodeQuery(
    $where: KnowledgeBaseNodeWhereUniqueInput!
  ) {
    knowledgeBaseNode(where: $where) {
      ...SidePanelHeader_KnowledgeBaseNodeFragment
      ...FileSidePanel_KnowledgeBaseNodeFragment
      ...FolderSidePanel_KnowledgeBaseNodeFragment
    }
  }
`,
  [
    SidePanelHeader_KnowledgeBaseNodeFragment,
    FileSidePanel_KnowledgeBaseNodeFragment,
    FolderSidePanel_KnowledgeBaseNodeFragment,
  ],
);

export const KnowledgeBaseSidePanel_ProjectFragment = gql(
  `
  fragment KnowledgeBaseSidePanel_ProjectFragment on Project {
    id
    ...FileSidePanel_ProjectFragment
  }
`,
  [FileSidePanel_ProjectFragment],
);

export interface SidePanelActions {
  refetch(): Promise<void>;
}

export interface SidePanelSlots
  extends FileSidePanelSlots,
    FolderSidePanelSlots {}

export interface SidePanelSlotProps
  extends FileSidePanelSlotProps,
    FolderSidePanelSlotProps {}

export type SidePanelSlotsAndSlotProps = CreateSlotsAndSlotProps<
  SidePanelSlots,
  SidePanelSlotProps
>;

export interface SidePanelProps
  extends Pick<SidePanelContainerProps, 'moreActions'>,
    SidePanelSlotsAndSlotProps {
  actions?: React.Ref<SidePanelActions>;
  project: FragmentOf<typeof KnowledgeBaseSidePanel_ProjectFragment>;
  knowledgeBaseId: string | number;
}

export const SidePanel: FunctionComponent<SidePanelProps> = props => {
  const {
    actions,
    knowledgeBaseId,
    moreActions,
    project: projectProp,
    slotProps,
    slots,
  } = props;
  const project = readFragment(
    KnowledgeBaseSidePanel_ProjectFragment,
    projectProp,
  );
  const { data, refetch } = useSuspenseQuery(
    KnowledgeBaseSidePanel_KnowledgeBaseNodeQuery,
    {
      variables: {
        where: { id: knowledgeBaseId, project: { id: project.id } },
      },
    },
  );

  const refresh = useEventCallback(async () => {
    await refetch();
  });

  useImperativeHandle(
    actions,
    () => ({
      refetch: refresh,
    }),
    [refresh],
  );

  if (data.knowledgeBaseNode?.__typename === 'KnowledgeBaseNodeFile') {
    return (
      <SidePanelContainer
        data={data.knowledgeBaseNode}
        moreActions={moreActions}
      >
        <FileSidePanel
          data={data.knowledgeBaseNode}
          project={project}
          slotProps={{
            ...slotProps,
            qnaGeneration: slotProps?.qnaGeneration
              ? ownerState => {
                  const base =
                    typeof slotProps?.qnaGeneration === 'function'
                      ? slotProps.qnaGeneration(ownerState)
                      : slotProps?.qnaGeneration;

                  return {
                    onQnaGenerateCancel: refresh,
                    ...base,
                  };
                }
              : undefined,
          }}
          slots={slots}
        />
      </SidePanelContainer>
    );
  }

  if (data.knowledgeBaseNode?.__typename === 'KnowledgeBaseNodeFolder') {
    return (
      <SidePanelContainer
        data={data.knowledgeBaseNode}
        moreActions={moreActions}
      >
        <FolderSidePanel
          data={data.knowledgeBaseNode}
          slotProps={slotProps}
          slots={slots}
        />
      </SidePanelContainer>
    );
  }

  throw new Error('Knowledge base node not found');
};
