import { useDocumentsTab } from './use-documents-tab';

export const useDocumentsLayout = () => {
  const { tab } = useDocumentsTab();
  const showLeftPanel = tab?.value === 'shared';

  return {
    showLeftPanel,
  };
};
