import { Option } from '../contexts/app-context';
import { gql, Scalars } from '../gql';

export const getIsDocumentInput = (
  inputType: Scalars<'SingleActionInputType'>,
  knowledgeBases?: Option[],
) => {
  return {
    isDocumentInput:
      inputType === gql.scalar('SingleActionInputType', 'DOCUMENT_BASIC') ||
      inputType === gql.scalar('SingleActionInputType', 'DOCUMENT_ADVANCED') ||
      inputType === gql.scalar('SingleActionInputType', 'IMAGE_BASIC') ||
      inputType === gql.scalar('SingleActionInputType', 'IMAGE_ADVANCED'),
    selectMode: !!knowledgeBases && knowledgeBases.length > 0,
  };
};
