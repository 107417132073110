import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';
import { PanelItem } from '../panel-item/panel-item';
import { ProcessStateIcon } from './process-state-icon';
import {
  ProcessStatePrimaryText,
  ProcessStatePrimaryText_KnowledgeBaseNodeFragment,
} from './process-state-primary-text';

export const ProcessState_KnowledgeBaseNodeFragment = gql(
  `
  fragment ProcessState_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    ownership
    ...ProcessStatePrimaryText_KnowledgeBaseNodeFragment

    ...on KnowledgeBaseNodeFile {
      processState
    }
  }
`,
  [ProcessStatePrimaryText_KnowledgeBaseNodeFragment],
);

interface ProcessStateProps {
  node: FragmentOf<typeof ProcessState_KnowledgeBaseNodeFragment>;
}

export const ProcessState: FunctionComponent<ProcessStateProps> = props => {
  const { node: nodeProp } = props;
  const theme = useTheme();
  const node = readFragment(ProcessState_KnowledgeBaseNodeFragment, nodeProp);

  if (node.__typename !== 'KnowledgeBaseNodeFile') {
    return null;
  }

  if (!node.processState) {
    return null;
  }

  return (
    <PanelItem
      label={
        <FormattedMessage
          id="knowledge-base-side-panel.process-state"
          defaultMessage="Parsing"
          description="Knowledge base side panel process state label text"
        />
      }
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 4px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <ProcessStateIcon state={node.processState} />

          <Text variant="body14">
            <ProcessStatePrimaryText node={node} />
          </Text>
        </div>

        {node.processState ===
          gql.scalar('ProcessStateEnum', 'PARSING_FAIL') && (
          <Text
            css={css`
              color: ${theme.palette.foreground.secondary};
            `}
            variant="body12"
          >
            {node.ownership ===
            gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL') ? (
              <FormattedMessage
                id="ProcessStateEnum.PARSING_FAIL.description.personal"
                defaultMessage="The content could not be processed successfully. Please reach out to support for further assistance."
                description="PARSING_FAIL description"
              />
            ) : (
              <FormattedMessage
                id="ProcessStateEnum.PARSING_FAIL.description"
                defaultMessage="The content could not be processed successfully. Please reach out to support for further assistance."
                description="PARSING_FAIL description"
              />
            )}
          </Text>
        )}
      </div>
    </PanelItem>
  );
};
