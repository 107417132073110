import type { introspection } from '@allganize/data-access-alli-user-query';
import { initGraphQLTada } from 'gql.tada';
export const gql = initGraphQLTada<{
  introspection: introspection;
  scalars: {
    ID: string | number;
    DateTime: string;
    JSONString: string;
    Upload: File | Blob | FileList;
    Timestamp: number;
  };
}>();
export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada';
export { maskFragments, readFragment } from 'gql.tada';
export type Scalars<T extends string> = ReturnType<typeof gql.scalar<T>>;
