import { useEventCallback } from '@allganize/hooks';
import { toast } from '@allganize/ui-toast';
import { useFormatErrorMessage } from '@allganize/utils-graphql';
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client/react';
import { compact, findKey, map, omit } from 'lodash-es';
import { FunctionComponent, ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { gql, readFragment, Scalars } from '../gql';
import {
  getKBProcessStateErrorMessage,
  GetKBProcessStateErrorMessage_KnowledgeBaseFragment,
} from '../utils/error';
import { getIsDocumentInput } from '../utils/misc';
import { AppContext } from './app-context';
import {
  KnowledgeBaseList,
  MAX_KB_FILE_SIZE,
  maxFileSize,
  SingleActionInput_KnowledgeBaseFragment,
  UploadBlock,
  UploadFileContext,
} from './upload-file-context';

const UploadFileProviderQuery = gql(`
  query UploadFileProviderQuery($where: ProjectWhereUniqueInput!) {
    project(where: $where) {
      id
      maxTrialKbFileCount
      trialExpiredAt
    }
  }
`);

const UploadFileProviderSubscription = gql(
  `
  subscription KnowledgeBasesPublicSubscription(
    $where: ProjectWhereUniqueInput
    $ids: [ID]
    $accessToken: String!
  ) {
    knowledgeBasesPublic(where: $where, ids: $ids, accessToken: $accessToken) {
      ... on GoogleDocsKnowledgeBase {
        id
      }

      ... on TextKnowledgeBase {
        id
      }

      ... on ImageKnowledgeBase {
        id
      }

      ... on MSDocsKnowledgeBase {
        id
      }

      ... on PDFKnowledgeBase {
        id
      }

      ... on MSExcelKnowledgeBase {
        id
      }

      ... on MSPPTKnowledgeBase {
        id
      }

      ... on OldMSDocsKnowledgeBase {
        id
      }

      ... on CSVKnowledgeBase {
        id
      }

      ... on HWPKnowledgeBase {
        id
      }

      ...SingleActionInput_KnowledgeBaseFragment
      ...GetKBProcessStateErrorMessage_KnowledgeBaseFragment
    }
  }
`,
  [
    SingleActionInput_KnowledgeBaseFragment,
    GetKBProcessStateErrorMessage_KnowledgeBaseFragment,
  ],
);

const UploadFileProviderKnowledgeBasesCountQuery = gql(`
  query UploadFileProviderKnowledgeBasesCountQuery($where: ProjectWhereUniqueInput!) {
    knowledgeBases(where: $where) {
      count
    }
  }
`);

const UploadKnowledgeFileWithTokenMutation = gql(
  `
  mutation UploadKnowledgeFileWithTokenMutation(
    $file: Upload
    $fileName: String
    $filePath: String
    $where: ProjectWhereUniqueInput!
    $accessToken: String
    $status: Boolean
  ) {
    uploadKnowledgeFileWithToken(
      file: $file
      fileName: $fileName
      filePath: $filePath
      where: $where
      accessToken: $accessToken
      status: $status
    ) {
      knowledgeBase {
        ...SingleActionInput_KnowledgeBaseFragment
        ...GetKBProcessStateErrorMessage_KnowledgeBaseFragment
      }

      duplicates {
        ...SingleActionInput_KnowledgeBaseFragment
        ...GetKBProcessStateErrorMessage_KnowledgeBaseFragment
      }

      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`,
  [
    SingleActionInput_KnowledgeBaseFragment,
    GetKBProcessStateErrorMessage_KnowledgeBaseFragment,
  ],
);

interface UploadFileProviderProps {
  children?: ReactNode;
}

export const UploadFileProvider: FunctionComponent<UploadFileProviderProps> = ({
  children,
}) => {
  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();
  const { data: app, accessToken } = useContext(AppContext);
  const projectId = app?.projectId || '';
  const hasDocumentUploadInput =
    !!app &&
    app?.singleActionInputs.findIndex(({ inputType, knowledgeBases }) => {
      const { isDocumentInput, selectMode } = getIsDocumentInput(
        inputType,
        knowledgeBases,
      );
      return isDocumentInput && !selectMode;
    }) >= 0;

  const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBaseList>({});
  const [uploadBlock, setUploadBlock] = useState<UploadBlock | null>(null);

  const openUploadBlockDialog = (uploadBlock: UploadBlock) =>
    setUploadBlock(uploadBlock);
  const closeUploadBlockDialog = () => setUploadBlock(null);

  const setError = (id: Scalars<'ID'>, error: ReactNode) => {
    setKnowledgeBases(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        error,
      },
    }));
  };

  const setLoading = (id: Scalars<'ID'>, loading: boolean) => {
    setKnowledgeBases(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        loading,
      },
    }));
  };

  const [mutate] = useMutation(UploadKnowledgeFileWithTokenMutation, {
    onError: err => toast.error(formatErrorMessage(err)),
  });

  const { data: projectData } = useQuery(UploadFileProviderQuery, {
    skip: !hasDocumentUploadInput,
    variables: {
      where: {
        id: projectId,
      },
    },
  });

  const [getKnowledgeBasesCount] = useLazyQuery(
    UploadFileProviderKnowledgeBasesCountQuery,
    {
      variables: {
        where: {
          id: projectId,
        },
      },
      fetchPolicy: 'no-cache',
    },
  );

  const knowledgeBasesIdList = map(knowledgeBases, 'id');

  useSubscription(UploadFileProviderSubscription, {
    variables: {
      accessToken,
      ids: knowledgeBasesIdList,
    },
    skip: knowledgeBasesIdList.length === 0,
    onData: ({ data }) => {
      const kb = data.data?.knowledgeBasesPublic;
      const kbKey = findKey(
        knowledgeBases,
        knowledgeBases => knowledgeBases.id === kb?.id,
      );
      if (kb && kbKey) {
        setKnowledgeBases(prev => ({
          ...prev,
          [kbKey]: {
            ...prev[kbKey],
            ...readFragment(SingleActionInput_KnowledgeBaseFragment, kb),
            error: getKBProcessStateErrorMessage(kb, intl),
          },
        }));
      }
    },
  });

  const uploadFile = useEventCallback(async (id: Scalars<'ID'>, file: File) => {
    if (file.size > MAX_KB_FILE_SIZE) {
      setError(
        id,
        intl.formatMessage(
          {
            id: 'single-action.upload-file.file-max-size-error-message',
            defaultMessage: 'File too large (Max. {maxSize})',
            description: 'single action upload file max size error message',
          },
          {
            maxSize: maxFileSize,
          },
        ),
      );
      return;
    }

    setLoading(id, true);

    try {
      if (
        projectData?.project?.trialExpiredAt &&
        projectData.project.maxTrialKbFileCount
      ) {
        const { data: kbCount } = await getKnowledgeBasesCount();
        if (
          kbCount?.knowledgeBases?.count &&
          kbCount.knowledgeBases.count >=
            projectData.project.maxTrialKbFileCount
        ) {
          openUploadBlockDialog({
            docLimit: projectData.project.maxTrialKbFileCount,
            docNum: kbCount.knowledgeBases.count,
          });
          return;
        }
      }
    } catch {
      // do nothing
    }

    await mutate({
      variables: {
        where: {
          id: projectId,
        },
        file,
        fileName: file.name,
        accessToken,
        status: false,
      },
      onCompleted: ({ uploadKnowledgeFileWithToken }) => {
        const kb = uploadKnowledgeFileWithToken?.knowledgeBase;
        const serverError = compact(uploadKnowledgeFileWithToken?.errors)[0];
        const processStateError = getKBProcessStateErrorMessage(kb, intl);
        const error = serverError
          ? formatErrorMessage(serverError)
          : processStateError;

        setKnowledgeBases(prev => ({
          ...prev,
          [id]: {
            ...prev[id],
            ...readFragment(SingleActionInput_KnowledgeBaseFragment, kb),
            error,
            loading: false,
          },
        }));
      },
    });
  });

  const deleteFile = (id: Scalars<'ID'>) => {
    setKnowledgeBases(prev => omit(prev, id));
  };

  return (
    <UploadFileContext.Provider
      value={{
        knowledgeBases,
        uploadBlock,
        uploadFile,
        deleteFile,
        setError,
        closeUploadBlockDialog,
      }}
    >
      {children}
    </UploadFileContext.Provider>
  );
};
