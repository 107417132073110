import { DraftFilePlugin } from '@allganize/draft-file-plugin';
import { DraftImagePlugin } from '@allganize/draft-image-plugin';
import { useMemo } from 'react';
import { defaultPlugins } from '../rich-text/use-rich-text.const';
import { getChatMediaValue } from './use-chat-media.utils';

const plugins = defaultPlugins.filter(
  plugin =>
    plugin instanceof DraftImagePlugin || plugin instanceof DraftFilePlugin,
);

export interface UseChatMediaOptions<
  T extends { mediaType?: string | null; url: string },
> {
  media: T | null | (T | null)[];
}

export const useChatMedia = <
  T extends { mediaType?: string | null; url: string },
>({
  media,
}: UseChatMediaOptions<T>) => {
  const value = useMemo(() => getChatMediaValue<T>({ media }), [media]);
  return { value, plugins };
};
