import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames, css } from '@emotion/react';
import { useFormControl } from '@mui/material/FormControl';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { forwardRef, isValidElement } from 'react';
import { formControlLabelClasses } from './form-control-label-classes';
import { FormControlLabelProps } from './form-control-label-type-map';

/**
 * Drop-in replacement of the Radio, Switch and Checkbox component.
 * Use this component if you want to display an extra label.
 */
export const FormControlLabel = forwardRef<
  HTMLLabelElement,
  FormControlLabelProps
>((props, ref) => {
  const {
    classes,
    disabled: disabledProp,
    disableTypography,
    label: labelProp,
    labelPlacement = 'end',
    slotProps,
    ...other
  } = props;
  const theme = useTheme();
  const muiTheme = useMuiTheme();
  const muiFormControl = useFormControl();
  const disabled =
    disabledProp ?? other.control?.props?.disabled ?? muiFormControl?.disabled;
  let label = labelProp;

  if (
    label !== null &&
    !(isValidElement(label) && label.type === Text) &&
    !disableTypography
  ) {
    label = (
      <Text
        css={css`
          padding-top: 2px;
          word-break: break-word;
        `}
        component="span"
        variant="subtitle14"
        {...slotProps?.text}
        className={clsx(
          formControlLabelClasses.label,
          { [formControlLabelClasses.disabled]: disabled },
          classes?.label,
          { [classes?.disabled ?? '']: disabled },
          slotProps?.text?.className,
        )}
      >
        {label}
      </Text>
    );
  }

  return (
    <ThemeProvider
      theme={{
        ...muiTheme,
        breakpoints: theme.breakpoints,
        spacing: theme.spacing,
      }}
    >
      <ClassNames>
        {({ css, cx }) => (
          <MuiFormControlLabel
            disableTypography
            disabled={disabled}
            labelPlacement={labelPlacement}
            {...other}
            ref={ref}
            label={label}
            classes={{
              ...classes,
              root: cx(
                css`
                  align-items: flex-start;
                  gap: 4px;
                `,
                labelPlacement === 'end' &&
                  css`
                    margin-left: -2px;
                    margin-right: 4px;
                  `,
                labelPlacement === 'start' &&
                  css`
                    margin-left: 4px;
                    margin-right: -2px;
                  `,
                css`
                  & .${formControlLabelClasses.label} {
                    &.${formControlLabelClasses.disabled} {
                      color: ${theme.palette.text.disabled};
                    }
                  }
                `,
                classes?.root,
              ),
              asterisk: cx(
                css`
                  color: ${theme.palette.error.main};
                `,
                classes?.asterisk,
              ),
            }}
          />
        )}
      </ClassNames>
    </ThemeProvider>
  );
});
