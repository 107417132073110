import { useEventCallback } from '@allganize/hooks';
import { Divider } from '@allganize/ui-divider';
import { FormControl, FormControlProps } from '@allganize/ui-form';
import { SelectProps, SelectRef } from '@allganize/ui-select';
import { css } from '@emotion/react';
import { forwardRef, Fragment, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { MultiValue } from 'react-select';
import { AccessControlListItem } from '../access-control-list-item/access-control-list-item';
import {
  AccessControlSelectUserOption,
  AccessControlUserSelect,
} from '../access-control-user-select/access-control-user-select';
import { FieldProps } from './access-control-field-types';

interface AccessControlUserFieldProps
  extends Omit<FormControlProps, 'onChange'>,
    FieldProps<AccessControlSelectUserOption[]> {
  projectId: string | number;
  SelectProps?: Partial<SelectProps<AccessControlSelectUserOption, true>>;
}

export const AccessControlUserField = forwardRef<
  HTMLDivElement,
  AccessControlUserFieldProps
>(({ value, onChange, disabled, projectId, SelectProps, ...others }, ref) => {
  const selectRef =
    useRef<SelectRef<AccessControlSelectUserOption, true>>(null);

  const handleChange = useEventCallback(
    (newValue: MultiValue<AccessControlSelectUserOption>) => {
      if (!newValue) return;

      if (newValue.length > value.length) {
        onChange([
          ...value,
          ...newValue.filter(nv => !value.find(v => nv.value === v.value)),
        ]);
        return;
      }

      if (newValue.length < value.length) {
        onChange([
          ...value.filter(v => newValue.find(nv => v.value === nv.value)),
        ]);
        return;
      }
    },
  );

  const handleDelete = useEventCallback(
    (value: AccessControlSelectUserOption) => {
      selectRef.current?.removeValue(value);
    },
  );

  return (
    <FormControl
      css={css`
        gap: 16px;
        overflow: hidden;
        padding: 2px;
        margin: -2px;
      `}
      {...others}
      ref={ref}
    >
      <AccessControlUserSelect
        {...SelectProps}
        value={value}
        ref={selectRef}
        projectId={projectId}
        disabled={disabled}
        onChange={handleChange}
        placeholder={
          <FormattedMessage
            id="access-control.field.user-select.placeholder"
            defaultMessage="Search Customer group or ID"
            description="access control field user select placeholder"
          />
        }
        controlShouldRenderValue={false}
        isClearable={false}
        css={css`
          padding: 0 12px;
        `}
      />
      <div
        css={css`
          padding: 8px 12px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          overflow: auto;
        `}
      >
        {value.map((v, idx) => {
          return (
            <Fragment key={v.value}>
              {idx !== 0 && <Divider />}
              <AccessControlListItem
                name={v.label}
                avatar={v.avatar}
                readOnly={disabled}
                description={v.description}
                onDelete={() => handleDelete(v)}
              />
            </Fragment>
          );
        })}
      </div>
    </FormControl>
  );
});
