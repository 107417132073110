import { useProject } from '@allganize/alli-works-project';
import { Text } from '@allganize/ui-text';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Suspense, useMemo } from 'react';

import { Layout } from '../styled-components';
import { LNB } from '../../lnb';
import { SidePanel } from '../../side-panel/side-panel';

const Wrapper = styled.div`
  height: 36px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left center;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

interface LNBLayoutProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  children: React.ReactNode;
}

export const LNBLayout = ({ children, ...other }: LNBLayoutProps) => {
  const theme = useTheme();
  const project = useProject();
  const title = useMemo(() => {
    if (project.appCollectionSettings?.companyLogo?.url) {
      return (
        <Wrapper>
          <Image
            src={project.appCollectionSettings.companyLogo.url}
            alt="company logo"
          />
        </Wrapper>
      );
    }

    if (project.appCollectionSettings?.companyName) {
      return (
        <Text
          css={css`
            padding: 2px 0;
          `}
          variant="title24"
        >
          {project.appCollectionSettings.companyName}
        </Text>
      );
    }
    return undefined;
  }, [
    project.appCollectionSettings?.companyLogo?.url,
    project.appCollectionSettings?.companyName,
  ]);

  return (
    <Layout
      css={css`
        display: flex;
        min-width: ${theme.breakpoints.values.lg}px;
      `}
      {...other}
    >
      <LNB logo={title} />
      <ContentWrapper>
        <Content>
          <Suspense fallback={null}>{children}</Suspense>
        </Content>
        <SidePanel />
      </ContentWrapper>
    </Layout>
  );
};
