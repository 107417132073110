import { KnowledgeBaseTreeViewNode } from '@allganize/knowledge-base-tree-view';
import { Truncate } from '@allganize/truncate';
import { Chip } from '@allganize/ui-chip';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@allganize/ui-accordion';
import { IcArrowExpandMore, IcPeople, IcPerson } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { Tooltip } from '@allganize/ui-tooltip';
import { Skeleton } from '@allganize/ui-skeleton';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { gql } from '../gql';

const LABEL_HEIGHT = 32;

export type SelectedKnowledgeBases = NonNullable<KnowledgeBaseTreeViewNode>[];

interface SelectedKnowledgeBaseListProps {
  data: SelectedKnowledgeBases;
  loading: boolean;
  initialDataCount: number;
  onDelete?: (id: string | number) => void;
}

export const SelectedKnowledgeBaseList = ({
  data,
  loading,
  initialDataCount,
  onDelete,
}: SelectedKnowledgeBaseListProps) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const hasSelectedKnowledgeBase = data.length !== 0;

  useEffect(() => {
    setExpanded(hasSelectedKnowledgeBase);
  }, [hasSelectedKnowledgeBase]);

  if (loading || data.length === 0) {
    return (
      <div
        css={css`
          height: ${LABEL_HEIGHT}px;
        `}
      >
        {loading ? (
          <Skeleton variant="text" textVariant="body14" width="50%" />
        ) : (
          <Truncate clamp={1}>
            <Text
              variant="body14"
              css={css`
                height: ${LABEL_HEIGHT}px;
                line-height: ${LABEL_HEIGHT}px;
                color: ${theme.palette.foreground.secondary};
              `}
            >
              <FormattedMessage
                id="documents-picker.selected-item-list-accordion.label"
                // eslint-disable-next-line formatjs/enforce-plural-rules
                defaultMessage="{initialDataCount, plural, =0 {Watching None} other {Watching all}}"
                description="documents picker selected items accordion label"
                values={{ initialDataCount }}
              />
            </Text>
          </Truncate>
        )}
      </div>
    );
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(e, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary
        expandIcon={<IcArrowExpandMore />}
        css={css`
          height: ${LABEL_HEIGHT}px;
          min-height: auto;
          padding: 0;
        `}
      >
        <Truncate clamp={1}>
          <Text
            variant="body14"
            css={css`
              height: ${LABEL_HEIGHT}px;
              line-height: ${LABEL_HEIGHT}px;
              color: ${theme.palette.foreground.secondary};
            `}
          >
            <FormattedMessage
              id="documents-picker.selected-item-list-accordion.label"
              defaultMessage="Watching {dataCount, plural, one {# item} other {# items}}"
              description="documents picker selected items accordion label"
              values={{ dataCount: data.length }}
            />
          </Text>
        </Truncate>
      </AccordionSummary>

      <AccordionDetails
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          max-height: 72px;
          overflow-y: auto;
          padding: 0 0 12px 0;
          border-bottom: 1px solid ${theme.palette.border.divider.default};
          margin-top: 12px;
          margin-bottom: 12px;
        `}
      >
        {data.map(document => (
          <Tooltip
            key={document.id}
            title={document.name}
            placement="top-start"
          >
            <Chip
              icon={
                document.ownership ===
                gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL') ? (
                  <IcPerson />
                ) : (
                  <IcPeople />
                )
              }
              label={document.name}
              onDelete={() => onDelete?.(document.id)}
              css={css`
                max-width: 184px;
              `}
            />
          </Tooltip>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
