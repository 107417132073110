import {
  getKnowledgeBaseTypeIconVariant,
  KnowledgeBaseFileIcon,
} from '@allganize/knowledge-base-icons';
import { Truncate } from '@allganize/truncate';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcError, IcFolder, IcWarning } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { memo } from 'react';
import { gql, ResultOf } from '../gql';

export const KnowledgeBaseNameColumn_KnowledgeBaseNodeFragment = gql(`
  fragment KnowledgeBaseNameColumn_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    name
    ownership

    ...on KnowledgeBaseNodeFile {
      type
      processState
    }
  }
`);

export interface KnowledgeBaseNameColumnProps {
  processStateErrorMessage?: React.ReactNode;
  node: ResultOf<typeof KnowledgeBaseNameColumn_KnowledgeBaseNodeFragment>;
}

export const KnowledgeBaseNameColumn = memo<KnowledgeBaseNameColumnProps>(
  props => {
    const { node, processStateErrorMessage } = props;

    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 12px;
        `}
      >
        {node.__typename === 'KnowledgeBaseNodeFolder' && (
          <IcFolder fontSize="large" />
        )}

        {node.__typename === 'KnowledgeBaseNodeFile' && (
          <>
            {(!node.processState ||
              node.processState ===
                gql.scalar('ProcessStateEnum', 'COMPLETED')) && (
              <KnowledgeBaseFileIcon
                fontSize="large"
                variant={
                  getKnowledgeBaseTypeIconVariant({
                    type: node.type,
                    fileName: node.name,
                  }) ?? 'fallback'
                }
              />
            )}

            {node.processState ===
              gql.scalar('ProcessStateEnum', 'PARSING_FAIL') && (
              <Tooltip title={processStateErrorMessage}>
                <IcError color="error" fontSize="large" />
              </Tooltip>
            )}

            {node.processState ===
              gql.scalar('ProcessStateEnum', 'RETRYING') && (
              <IcWarning color="warning" fontSize="large" />
            )}

            {(node.processState === gql.scalar('ProcessStateEnum', 'INIT') ||
              node.processState ===
                gql.scalar('ProcessStateEnum', 'PARSING')) && (
              <CircularProgress
                size="md"
                css={css`
                  flex-shrink: 0;
                `}
              />
            )}
          </>
        )}

        <Truncate
          css={css`
            flex-grow: 1;
            word-break: break-all;
          `}
          clamp={1}
        >
          <Text variant="body14">{node.name}</Text>
        </Truncate>
      </div>
    );
  },
);
