import {
  AlliChatRow,
  AlliChatRowItem,
  AlliChatRowItemProps,
  ChatStackItem,
  TimestampTooltip,
} from '@allganize/alli-chat-base';
import { RichText, useRichText } from '@allganize/alli-chat-content';
import {
  DraftDocumentList,
  useDocumentEntities,
} from '@allganize/alli-chat-document';
import { isDraftInputEmpty } from '@allganize/draft-input';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

export const CarouselChatMessage_CarouselChatFragment = gql(`
  fragment CarouselChatMessage_CarouselChatFragment on CarouselChat {
    createdAt
    messageContentState @client

    media {
      id
    }
  }
`);

export interface CarouselChatMessageProps
  extends Pick<AlliChatRowItemProps, 'avatar'> {
  chat: FragmentOf<typeof CarouselChatMessage_CarouselChatFragment>;
}

export const CarouselChatMessage: FunctionComponent<
  CarouselChatMessageProps
> = props => {
  const { avatar, chat: chatProp } = props;
  const chat = readFragment(CarouselChatMessage_CarouselChatFragment, chatProp);
  const { value: message, onChange: onMessageChange } = useRichText({
    value: chat.messageContentState,
  });
  const { documentEntities } = useDocumentEntities({ value: message });
  const hasMedia = !!chat.media;
  const hasMessage = !hasMedia && !isDraftInputEmpty(message);
  const hasDocumentEntities = documentEntities.length > 0;

  if (!hasMessage) {
    return null;
  }

  return (
    <ChatStackItem disablePadding>
      <AlliChatRow>
        <TimestampTooltip align="left" timestamp={chat.createdAt}>
          <AlliChatRowItem avatar={avatar}>
            <RichText value={message} onChange={onMessageChange} />

            {hasDocumentEntities && (
              <DraftDocumentList data={documentEntities} />
            )}
          </AlliChatRowItem>
        </TimestampTooltip>
      </AlliChatRow>
    </ChatStackItem>
  );
};
