import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type TabClassKey =
  | 'root'
  | 'underlined'
  | 'filled'
  | 'focusVisible'
  | 'selected'
  | 'disabled'
  | 'fullWidth'
  | 'wrapped'
  | 'iconWrapper';
export type TabClasses = Record<TabClassKey, string>;

export const tabClasses: TabClasses = generateUtilityClasses('Tab', [
  'root',
  'underlined',
  'filled',
  'focusVisible',
  'selected',
  'disabled',
  'fullWidth',
  'wrapped',
  'iconWrapper',
]);
