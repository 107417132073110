import { IcPeople, IcPerson } from '@allganize/ui-icons';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { gql, Scalars } from '../gql';

const tabs = ['my', 'shared'] as const;
const defaultTab = 'my';
const tabSchema = z.enum(tabs).default(defaultTab).catch(defaultTab);
type TabOption = z.infer<typeof tabSchema>;

const tabLabels = defineMessages<TabOption>({
  my: {
    id: 'personal',
    defaultMessage: 'Personal',
    description: 'Personal',
  },
  shared: {
    id: 'shared',
    defaultMessage: 'Shared',
    description: 'Shared',
  },
});

const tabIcons: Record<TabOption, React.ReactNode> = {
  my: <IcPerson />,
  shared: <IcPeople />,
};

const tabMapping: Record<TabOption, Scalars<'KnowledgeBaseNodeOwnership'>> = {
  my: gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL'),
  shared: gql.scalar('KnowledgeBaseNodeOwnership', 'SHARED'),
};

export const useDocumentsTab = () => {
  const intl = useIntl();
  const isDocumentsRoot = useMatch({
    path: '/documents',
    end: true,
  });
  const isDocumentsSearch = useMatch('/documents/search');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');
  const options = useMemo(() => {
    return tabs.map(option => {
      return {
        value: option,
        icon: tabIcons[option],
        label: intl.formatMessage(tabLabels[option]),
        ownership: tabMapping[option],
      };
    });
  }, [intl]);
  const tab = useMemo(() => {
    if (isDocumentsSearch) {
      return null;
    }

    const value = tabSchema.parse(tabParam);

    return (
      options.find(option => option.value === value) ??
      options.find(option => option.value === defaultTab) ??
      options[0]
    );
  }, [isDocumentsSearch, options, tabParam]);

  const setTab = (value: any) => {
    const parsed = tabSchema.safeParse(value);

    if (!parsed.success) {
      return;
    }

    if (isDocumentsRoot) {
      setSearchParams(prev => {
        if (parsed.data === defaultTab) {
          prev.delete('tab');
        } else {
          prev.set('tab', parsed.data);
        }

        prev.delete('detail');
        return prev;
      });

      return;
    }

    const newSearchParams = new URLSearchParams(searchParams);

    if (parsed.data === defaultTab) {
      newSearchParams.delete('tab');
    } else {
      newSearchParams.set('tab', parsed.data);
    }

    newSearchParams.delete('detail');

    navigate({
      pathname: '/documents',
      search: newSearchParams.toString(),
    });
  };

  return {
    options,
    setTab,
    tab,
  };
};
