import {
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from '@allganize/ui-list';
import { Skeleton } from '@allganize/ui-skeleton';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { listItemSize } from './history-list-item';

interface HistoryListItemSkeletonProps
  extends Pick<ListItemProps, 'className' | 'style'> {}

export const HistoryListItemSkeleton: FunctionComponent<
  HistoryListItemSkeletonProps
> = props => {
  const { className, style } = props;

  return (
    <ListItem
      css={css`
        min-height: ${listItemSize}px;
        padding: 0 8px;
      `}
      disablePadding
      component="div"
      className={className}
      style={style}
    >
      <ListItemIcon
        css={css`
          margin-right: 4px;
        `}
      >
        <Skeleton variant="rounded" width={16} height={16} />
      </ListItemIcon>

      <ListItemText
        primaryTextProps={{ variant: 'body14' }}
        primary={<Skeleton variant="text" textVariant="body14" width="100%" />}
      />
    </ListItem>
  );
};
