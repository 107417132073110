import { useUnmount } from '@allganize/hooks';
import { useSuspenseQuery } from '@apollo/client/react';
import { useAtom } from 'jotai';
import { FunctionComponent, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FragmentOf, gql, readFragment } from '../gql';
import {
  useAccessControl,
  useRootAccessControl,
  UseRootAccessControl_ProjectFragment,
} from '../hooks/use-access-control';
import { BlockUploadFile } from './block-upload-file';
import {
  UploadFileForm,
  UploadFileForm_ProjectFragment,
  UploadFileFormProps,
} from './upload-file-form';
import { FileSelectionFormValues } from './use-file-selection-form';
import { uploadFileAtom, UploadFileState } from './use-upload-file';

export const UploadFileQuery = gql(`
  query UploadFileQuery($project: ProjectWhereUniqueInput!) {
    knowledgeBases(where: $project) {
      count
    }
  }
`);

export const UploadFile_ProjectFragment = gql(
  `
  fragment UploadFile_ProjectFragment on Project {
    id
    maxTrialKbFileCount
    trialExpiredAt
    ...UploadFileForm_ProjectFragment
    ...UseRootAccessControl_ProjectFragment
  }
`,
  [UploadFileForm_ProjectFragment, UseRootAccessControl_ProjectFragment],
);

export interface UploadFileProps
  extends Pick<UploadFileFormProps, 'slotProps' | 'slots'> {
  onSubmit(
    values: FileSelectionFormValues &
      Pick<UploadFileState, 'parentId' | 'projectId' | 'onSubmit'>,
    form: UseFormReturn<FileSelectionFormValues>,
  ): Promise<void>;
  project: FragmentOf<typeof UploadFile_ProjectFragment>;
}

export const UploadFile: FunctionComponent<UploadFileProps> = props => {
  const { onSubmit, project: projectProp, slotProps, slots } = props;
  const project = readFragment(UploadFile_ProjectFragment, projectProp);
  const [state, setState] = useAtom(uploadFileAtom(project.id));
  const [overLimit, setOverLimit] = useState(false);
  const [defaultValues, setDefaultValues] =
    useState<FileSelectionFormValues | null>(null);
  const { data } = useSuspenseQuery(UploadFileQuery, {
    variables: { project: { id: project.id } },
  });
  const rootAccessControl = useRootAccessControl({
    project,
  });
  const accessControl = useAccessControl({
    projectId: project.id,
    nodeId: state.parentId,
  });
  const parentAccessControl =
    state.parentId === null ? rootAccessControl : accessControl;
  const trialExpiredAt = project.trialExpiredAt ?? null;
  const maxTrialKbFileCount = project.maxTrialKbFileCount ?? null;
  const count = data.knowledgeBases?.count ?? 0;

  const closeUploadFile = () => {
    setState(prev => ({
      ...prev,
      open: false,
      parentId: null,
    }));
    setDefaultValues(null);
  };

  const handleBlockUploadFileClose = () => {
    if (overLimit) {
      setOverLimit(false);
      return;
    }

    closeUploadFile();
  };

  const handleSubmit = async (
    values: FileSelectionFormValues,
    form: UseFormReturn<FileSelectionFormValues>,
  ) => {
    if (
      trialExpiredAt !== null &&
      maxTrialKbFileCount &&
      values.files.length + count > maxTrialKbFileCount
    ) {
      setOverLimit(true);
      setDefaultValues(values);
      return;
    }

    await onSubmit({ ...values, ...state }, form);
    closeUploadFile();
  };

  useUnmount(closeUploadFile);

  if (
    trialExpiredAt !== null &&
    maxTrialKbFileCount &&
    (count >= maxTrialKbFileCount || overLimit)
  ) {
    return (
      <BlockUploadFile
        count={count}
        maxTrialKbFileCount={maxTrialKbFileCount}
        onClose={handleBlockUploadFileClose}
        open={state.open}
      />
    );
  }

  return (
    <UploadFileForm
      defaultValues={defaultValues ?? undefined}
      onClose={closeUploadFile}
      onSubmit={handleSubmit}
      open={state.open}
      accessControl={parentAccessControl}
      ownership={state.ownership}
      project={project}
      enableOverwriteFolderAccess={!!state.parentId}
      slotProps={slotProps}
      slots={slots}
    />
  );
};
