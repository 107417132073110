import generateUtilityClasses from '@mui/material/generateUtilityClasses';
import { BaseStateVariant } from './base-state.types';

export type BaseStateClassKey =
  | 'root'
  | BaseStateVariant
  | 'contents'
  | 'text'
  | 'title'
  | 'description'
  | 'actions';
export type BaseStateClasses = Record<BaseStateClassKey, string>;

export const baseStateClasses: BaseStateClasses = generateUtilityClasses(
  'BaseState',
  [
    'root',
    'page',
    'modal',
    'card',
    'contents',
    'text',
    'title',
    'description',
    'actions',
  ],
);
