import { Truncate } from '@allganize/truncate';
import { Indicator } from '@allganize/ui-indicator';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';

const maxItemsCount = 7;

export const ChatKnowledgeBases_ChatFragment = gql(`
  fragment ChatKnowledgeBases_ChatFragment on Chat {
    ...on UserChat {
      __typename
      id

      knowledgeBaseNodes {
        id
        name
      }
    }
  }
`);

export interface ChatKnowledgeBasesProps {
  chat: FragmentOf<typeof ChatKnowledgeBases_ChatFragment>;
  className?: string;
}

export const ChatKnowledgeBases: FunctionComponent<
  ChatKnowledgeBasesProps
> = props => {
  const { chat: chatProp, className } = props;
  const chat = readFragment(ChatKnowledgeBases_ChatFragment, chatProp);

  if (chat.__typename !== 'UserChat') {
    return null;
  }

  if (!chat.knowledgeBaseNodes?.length) {
    return null;
  }

  const totalCount = chat.knowledgeBaseNodes.length;
  const activeItems = chat.knowledgeBaseNodes.filter(node => node);
  const deletedItemsCount = totalCount - activeItems.length;
  const visibleItemsCount = maxItemsCount - (deletedItemsCount > 0 ? 1 : 0);
  const truncatedItemsCount = activeItems.length - visibleItemsCount;

  return (
    <div className={className}>
      <Tooltip
        title={
          <ul
            css={css`
              list-style-type: none;
              padding-left: 0;
              margin: 0;
              max-width: 256px;
            `}
          >
            {activeItems.slice(0, visibleItemsCount).map(node => {
              if (!node) {
                return null;
              }

              return (
                <li key={node.id}>
                  <Truncate
                    css={css`
                      word-break: break-all;
                    `}
                    clamp={1}
                  >
                    {node.name}
                  </Truncate>
                </li>
              );
            })}

            {deletedItemsCount > 0 && (
              <li>
                <FormattedMessage
                  id="chat-knowledge-bases-deleted"
                  defaultMessage="{count, number} {count, plural, one {item} other {items}} deleted from documents"
                  description="Deleted items count"
                  values={{ count: deletedItemsCount }}
                />
              </li>
            )}

            {truncatedItemsCount > 0 && (
              <li>
                <FormattedMessage
                  id="chat-knowledge-bases-truncated"
                  defaultMessage="+{count, number} more {count, plural, one {item} other {items}}"
                  description="Truncated items count"
                  values={{ count: truncatedItemsCount }}
                />
              </li>
            )}
          </ul>
        }
      >
        <Indicator
          color="gray-subtle"
          size="small"
          label={
            <FormattedMessage
              id="chat-knowledge-bases"
              defaultMessage="Referenced items: {count, number}"
              description="Referenced items count"
              values={{ count: chat.knowledgeBaseNodes.length }}
            />
          }
        />
      </Tooltip>
    </div>
  );
};
