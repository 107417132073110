import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@allganize/ui-dialog';
import { IcCheckCircle, IcError, IcInfo } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { ConfirmDialogColor, ConfirmDialogProps } from './confirm-dialog.types';

const iconMapping: Record<ConfirmDialogColor, React.ReactNode> = {
  default: (
    <IcInfo
      css={css`
        vertical-align: middle;
        margin-right: 8px;
      `}
      color="inherit"
    />
  ),
  primary: (
    <IcCheckCircle
      css={css`
        vertical-align: middle;
        margin-right: 8px;
      `}
      color="inherit"
    />
  ),
  error: (
    <IcError
      css={css`
        vertical-align: middle;
        margin-right: 8px;
      `}
      color="inherit"
    />
  ),
};

export const ConfirmDialog = forwardRef<HTMLDivElement, ConfirmDialogProps>(
  (props, ref) => {
    const {
      actions,
      color = 'default',
      content,
      icon: iconProp,
      title,
      ...other
    } = props;
    const theme = useTheme();
    const icon =
      typeof iconProp === 'undefined' ? iconMapping[color] : iconProp;

    return (
      <Dialog maxWidth="sm" {...other} ref={ref}>
        {title && (
          <DialogTitle
            css={[
              color === 'error' &&
                css`
                  color: ${theme.palette.error.main};
                `,
              color === 'primary' &&
                css`
                  color: ${theme.palette.primary.main};
                `,
            ]}
          >
            {icon}

            <span
              css={css`
                vertical-align: middle;
              `}
            >
              {title}
            </span>
          </DialogTitle>
        )}

        {content && <DialogContent>{content}</DialogContent>}

        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    );
  },
);
