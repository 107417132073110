import { Storage } from '@allganize/utils-storage';
import EventEmitter from 'eventemitter3';
import { config } from '../../config';

interface AlliPersistedUserIdManagerEvents {
  update(): void;
}

export class AlliPersistedUserIdManager extends EventEmitter<AlliPersistedUserIdManagerEvents> {
  private storageKey: string = config.storage.persistedUserId;

  constructor(private storage: Storage, keySuffix = '') {
    super();
    this.storageKey += keySuffix;
  }

  public async getUserId(): Promise<string | undefined> {
    const value = await this.storage.getItem(this.storageKey);
    return value;
  }

  public async setUserId(_value: string) {
    const value = await this.storage.getItem(this.storageKey);
    if (value !== _value) {
      this.emit('update');
    }
    return this.storage.setItem(this.storageKey, _value);
  }
}
