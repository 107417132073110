import { Analytics } from '@allganize/analytics';

import { environment } from './environments/environment';

export const analytics = new Analytics<AlliAppMarketAnalyticsEventTypeMap>({
  name: 'alli-app-market',
  mixpanel: environment.mixpanel
    ? {
        token: environment.mixpanel.token,
        config: {
          track_pageview: false,
        },
      }
    : undefined,
});

export interface AlliAppMarketAnalyticsEventTypeMap {
  /**
   * Home page event tracking
   */
  'home::page.view': void;
  'home::chat-input__send-chat-button.click': void;
  'home::recommended-questions.click': {
    index: number;
  };
  'home::recommended-apps.click': {
    index: number;
    appId: string | number;
    appType?: 'CONVERSATION' | 'SINGLE_ACTION';
  };
  'home::view-all-app.click': void;
  'home::chat-input__kb-button.click': void;
  'home::chat-input__kb-button__menu-item.click': {
    source: 'KnowledgeBase' | 'Computer';
  };
  'home::chat-input__kb-search-modal.view': void;
  'home::chat-input__kb-search-modal.close': {
    reason?: string;
  };
  'home::chat-input__kb-search-modal.confirm': void;

  /**
   * LNB event tracking
   */
  'lnb::all-apps.click': void;
  'lnb::new-chat.click': void;
  'lnb::logo.click': void;
  'lnb::conversation-history.click': {
    conversationId: string | number;
    appId?: string | number;
    conversationType?: 'app' | 'chat';
  };
  'lnb::conversation-history__delete-button.click': {
    conversationId: string | number;
    appId?: string | number;
    conversationType?: 'app' | 'chat';
  };
  'lnb::conversation-history__popup-delete-button.click': {
    conversationId: string | number;
    appId?: string | number;
    conversationType?: 'app' | 'chat';
  };

  /**
   * Apps page event tracking
   */
  'apps::page.view': {
    category?: string;
  };
  'apps::app-card.click': {
    appId: string | number;
    appType?: 'CONVERSATION' | 'SINGLE_ACTION';
  };
  'apps::app-search.submit': object;
  'apps::app-title-sort.click': {
    order: string;
  };
  'apps::app-type-filter.click': {
    appType?: 'CONVERSATION' | 'SINGLE_ACTION' | 'ALL';
  };
  'apps::app__delete-button.click': {
    appId: string | number;
  };

  /**
   * Conversation page event tracking
   */
  'conversation-detail::page.view': {
    conversationId: string | number;
    appId?: string | number;
  };
  'conversation-detail::chat-input__kb-button.click': {
    conversationId?: string | number;
    appId?: string | number;
  };
  'conversation-detail::chat-input__kb-button__menu-item.click': {
    conversationId?: string | number;
    appId?: string | number;
    source: 'KnowledgeBase' | 'Computer';
  };
  'conversation-detail::chat-input__kb-search-modal.view': {
    conversationId?: string | number;
    appId?: string | number;
  };
  'conversation-detail::chat-input__kb-search-modal.close': {
    conversationId?: string | number;
    appId?: string | number;
    reason?: string;
  };
  'conversation-detail::chat-input__kb-search-modal.confirm': {
    conversationId?: string | number;
    appId?: string | number;
  };
  'conversation-detail::chat-input__send-chat-button.click': {
    conversationId?: string | number;
    appId?: string | number;
  };
  'conversation-detail::chat-input__app-mention-selector.open': {
    conversationId?: string | number;
    appId?: string | number;
  };
  'conversation-detail::chat-input__app-mention-selector__app.click': {
    conversationId?: string | number;
    appId?: string | number;
    type: 'app-list' | 'app';
    clickedAppId?: string | number;
  };

  /**
   * Single action page event tracking
   */
  'single-action::page.view': {
    publicToken?: string;
    appId?: string | number;
  };
}
