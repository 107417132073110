import { DraftInlineStyle, DraftPlugin } from '@allganize/draft-input';

export class DraftTextColorPlugin extends DraftPlugin {
  public customStyleFn(style: DraftInlineStyle): React.CSSProperties {
    const color =
      style
        .filter(inlineStyle => !!inlineStyle?.startsWith('color-'))
        .toArray()[0]
        ?.split('-')[1] || undefined;

    return { color };
  }
}
