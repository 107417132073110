import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { camelCase } from 'lodash-es';
import { cloneElement, forwardRef, isValidElement } from 'react';
import { indicatorClasses, IndicatorClassKey } from './indicator.classes';
import { IndicatorTypeMap } from './indicator.types';

// @ts-expect-error overridable component
export const Indicator: OverridableComponent<IndicatorTypeMap> = forwardRef(
  (props, ref) => {
    const {
      classes,
      className,
      color = 'primary',
      // @ts-expect-error overridable component
      component: Component = 'div',
      icon: iconProp,
      label,
      size = 'medium',
      ...other
    } = props;
    const theme = useTheme();

    let icon: React.ReactNode = null;

    if (iconProp && isValidElement(iconProp)) {
      icon = cloneElement(iconProp, {
        // @ts-expect-error className is not a valid prop for all components
        className: clsx(
          indicatorClasses.icon,
          indicatorClasses[camelCase(`icon-${size}`) as IndicatorClassKey],
          classes?.icon,
          classes?.[camelCase(`icon-${size}`) as IndicatorClassKey],
          // @ts-expect-error className is not a valid prop for all components
          iconProp.props?.className,
        ),
      });
    }

    return (
      <Component
        css={[
          css`
            max-width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: ${theme.radius.round}px;
            white-space: nowrap;
            transition: ${theme.transitions.create([
              'background-color',
              'color',
            ])};
            cursor: unset;
            outline: 0;
            text-decoration: none;
            border: 0;
            vertical-align: middle;
            box-sizing: border-box;
          `,
          size === 'small' &&
            css`
              ${theme.typography.body12}
              height: 20px;
              min-width: 20px;
              gap: 2px;
              padding: 0 6px;

              .${indicatorClasses.icon} {
                font-size: 16px;
              }
            `,
          size === 'medium' &&
            css`
              ${theme.typography.body14}
              height: 24px;
              min-width: 24px;
              gap: 3px;
              padding: 0 8px;

              .${indicatorClasses.icon} {
                font-size: 20px;
              }
            `,
          (color === 'primary' ||
            color === 'gray' ||
            color === 'success' ||
            color === 'information' ||
            color === 'alert' ||
            color === 'error') &&
            css`
              background-color: ${theme.palette.unstable_background[color]
                .default};
              color: ${theme.palette.foreground.inverse};
            `,
          (color === 'primary-subtle' ||
            color === 'success-subtle' ||
            color === 'information-subtle' ||
            color === 'alert-subtle' ||
            color === 'error-subtle') &&
            css`
              background-color: ${theme.palette.unstable_background[
                color.split('-')[0] as
                  | 'primary'
                  | 'success'
                  | 'information'
                  | 'alert'
                  | 'error'
              ].subtle};
              color: ${theme.palette.foreground.default};
            `,
          color === 'gray-subtle' &&
            css`
              background-color: ${theme.palette.unstable_background.graySubtle
                .default};
              color: ${theme.palette.foreground.default};
            `,
          (color === 'primary-ghost' ||
            color === 'gray-ghost' ||
            color === 'success-ghost' ||
            color === 'information-ghost' ||
            color === 'alert-ghost' ||
            color === 'error-ghost') &&
            css`
              color: ${theme.palette.foreground.default};
              padding: 0;
            `,
        ]}
        className={clsx(
          indicatorClasses.root,
          indicatorClasses[camelCase(`size-${size}`) as IndicatorClassKey],
          indicatorClasses[camelCase(`color-${color}`) as IndicatorClassKey],
          classes?.root,
          classes?.[camelCase(`size-${size}`) as IndicatorClassKey],
          classes?.[camelCase(`color-${color}`) as IndicatorClassKey],
          className,
        )}
        {...other}
        ref={ref}
      >
        {icon}

        <span
          css={css`
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
          className={clsx(
            indicatorClasses.label,
            indicatorClasses[camelCase(`label-${size}`) as IndicatorClassKey],
            classes?.label,
            classes?.[camelCase(`label-${size}`) as IndicatorClassKey],
          )}
        >
          {label}
        </span>
      </Component>
    );
  },
);
