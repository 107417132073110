import { useCombinedRef } from '@allganize/hooks';
import { IconButton } from '@allganize/ui-button';
import { IcClose, IcSearch } from '@allganize/ui-icons';
import { Input, InputAdornment, inputClasses } from '@allganize/ui-input';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { camelCase } from 'lodash-es';
import { forwardRef, useRef, useState } from 'react';
import { searchInputClasses } from './search-input.classes';
import { SearchInputProps } from './search-input.types';

export const SearchInput = forwardRef<HTMLDivElement, SearchInputProps>(
  (props, ref) => {
    const {
      classes,
      disabled = false,
      inputRef: inputRefProp,
      onChange,
      onClear,
      size = 'medium',
      value,
      variant = 'outlined',
      ...other
    } = props;
    const theme = useTheme();
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const combinedInputRef = useCombinedRef(inputRef, inputRefProp ?? null);
    const [showClear, setShowClear] = useState(!!value);

    const handleChange = (
      ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setShowClear(!!ev.target.value);
      onChange?.(ev);
    };

    const handleClear = (ev: React.MouseEvent<HTMLButtonElement>) => {
      onClear?.(ev);

      if (!inputRef.current) {
        return;
      }

      const nativeInputValueSetter =
        inputRef.current.tagName === 'TEXTAREA'
          ? Object.getOwnPropertyDescriptor(
              HTMLTextAreaElement.prototype,
              'value',
            )?.set
          : Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')
              ?.set;

      if (!nativeInputValueSetter) {
        return;
      }

      nativeInputValueSetter.call(inputRef.current, '');
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    };

    return (
      <ClassNames>
        {({ css, cx }) => (
          <Input
            data-testid="search-input"
            startAdornment={
              <InputAdornment position="start">
                <IcSearch
                  css={
                    disabled &&
                    css`
                      color: ${theme.palette.foreground.gray.disabled};
                    `
                  }
                />
              </InputAdornment>
            }
            endAdornment={
              showClear &&
              !disabled && (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleClear}>
                    <IcClose />
                  </IconButton>
                </InputAdornment>
              )
            }
            disabled={disabled}
            inputRef={combinedInputRef}
            value={value}
            onChange={handleChange}
            {...other}
            ref={ref}
            className={cx(
              searchInputClasses.root,
              searchInputClasses[variant],
              searchInputClasses[
                camelCase(`size_${size}`) as keyof typeof searchInputClasses
              ],
              { [searchInputClasses.disabled]: disabled },
              classes?.root,
              classes?.[variant],
              classes?.[
                camelCase(`size_${size}`) as keyof typeof searchInputClasses
              ],
              { [classes?.disabled ?? '']: disabled },
              other.className,
            )}
            classes={{
              root: cx(
                css`
                  border-radius: ${theme.radius.round}px;
                `,
                variant === 'filled' &&
                  css`
                    background-color: ${theme.palette.unstable_background
                      .default};

                    &.${inputClasses.focused} {
                      background-color: transparent;
                    }

                    &.${inputClasses.disabled} {
                      background-color: ${theme.palette.grayAlpha[50]};
                    }
                  `,
              ),
              notchedOutline: cx(
                variant === 'filled' &&
                  css`
                    border-color: transparent;
                  `,
              ),
              input: cx(
                css`
                  padding-top: 10px;
                  padding-bottom: 10px;
                `,
                size === 'large' &&
                  css`
                    padding-top: 18px;
                    padding-bottom: 18px;
                  `,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  },
);
