export interface DocumentPreviewThumbnailUrlOptions {
  base?: string;
  knowledgeBaseId: string | number;
  projectId: string | number;
  /**
   * If no page number is specified, the first page is used.
   * @optional
   */
  pageNo?: number;
  /**
   * Width in px
   */
  width?: number;
  /**
   * Height in px
   */
  height?: number;
}

export const getDocumentPreviewThumbnailUrl = ({
  base = '',
  knowledgeBaseId,
  projectId,
  ...params
}: DocumentPreviewThumbnailUrlOptions) => {
  const urlParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && typeof value !== 'undefined') {
      urlParams.append(key, value.toString());
    }
  });

  const urlParamsString = urlParams.toString();

  return `${base}/projects/${projectId}/knowledge_bases/${knowledgeBaseId}/thumbnail${
    urlParamsString ? `?${urlParamsString}` : ''
  }`;
};
