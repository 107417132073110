import { OverridableComponent } from '@allganize/types';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useContext } from 'react';
import { DialogContext } from '../dialog/dialog-context';
import { dialogTitleClasses } from './dialog-title-classes';
import { DialogTitleTypeMap } from './dialog-title-type-map';

// @ts-expect-error overridable component
export const DialogTitle: OverridableComponent<DialogTitleTypeMap> = forwardRef(
  (props, ref) => {
    const { classes, id: idProp, padding = 'default', ...other } = props;
    const { titleId = idProp } = useContext(DialogContext);

    return (
      <Text
        data-testid="dialog-title"
        css={[
          css`
            padding: 20px 24px;
            flex: 0 0 auto;
          `,
          padding === 'close-button' &&
            css`
              padding-right: 60px;
            `,
        ]}
        component="h2"
        variant="title16"
        {...other}
        ref={ref}
        className={clsx(
          dialogTitleClasses.root,
          classes?.root,
          other.className,
        )}
        id={idProp ?? titleId}
      />
    );
  },
);
