import {
  DocumentPreviewThumbnailUrlOptions,
  getDocumentPreviewThumbnailUrl,
} from '@allganize/alli-document';
import { useImageLoaded } from '@allganize/hooks';
import {
  ButtonBase,
  buttonBaseClasses,
  IconButton,
  iconButtonClasses,
} from '@allganize/ui-button';
import { IcDescription, IcZoomIn } from '@allganize/ui-icons';
import { Skeleton } from '@allganize/ui-skeleton';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Scalars } from '../gql';

export interface ThumbnailProps extends DocumentPreviewThumbnailUrlOptions {
  fileName?: string;
  onClick?(nodeId: Scalars<'ID'>): void;
}

export const Thumbnail: FunctionComponent<ThumbnailProps> = props => {
  const { width = 328, height = 200, fileName, onClick, ...rest } = props;
  const theme = useTheme();
  const url = getDocumentPreviewThumbnailUrl({
    ...rest,
    width,
  });
  const [loaded] = useImageLoaded({ src: url });

  const handleClick = () => {
    onClick?.(props.knowledgeBaseId);
  };

  return (
    <ButtonBase
      css={css`
        width: 100%;
        border-radius: ${theme.radius.xs}px;
        background-color: ${theme.palette.unstable_background.graySubtle
          .default};
        border: 1px solid ${theme.palette.border.divider.default};
        overflow: hidden;

        &.${buttonBaseClasses.focusVisible} {
          outline: ${theme.palette.border.focused} solid 2px;
          outline-offset: 2px;
        }
      `}
      style={{ height }}
      onClick={handleClick}
    >
      {!loaded && <Skeleton variant="rounded" width="100%" height="100%" />}

      {loaded === 'loaded' && (
        <img
          src={url}
          alt={`thumbnail of ${fileName}`} // TODO i18n
          css={css`
            object-fit: cover;
            width: 100%;
            height: 100%;
          `}
        />
      )}

      {loaded === 'error' && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 24px;
            align-items: center;
          `}
        >
          <IcDescription fontSize="large" />

          <Text
            css={css`
              color: ${theme.palette.foreground.secondary};
            `}
            variant="body14"
          >
            <FormattedMessage
              id="knowledge-base.thumbnail.error"
              defaultMessage="An issue occurred while loading the document, so the preview image cannot be displayed."
              description="Error message when the thumbnail image cannot be loaded"
            />
          </Text>
        </div>
      )}

      <IconButton
        css={css`
          position: absolute;
          bottom: 8px;
          right: 8px;

          &.${iconButtonClasses.disabled} {
            background-color: ${theme.palette.backgroundInteractive
              .grayDarkAlpha.default};
            color: ${theme.palette.foreground.inverse};
          }
        `}
        shape="rounded"
        component="div"
        disabled
      >
        <IcZoomIn />
      </IconButton>
    </ButtonBase>
  );
};
