import { FormHelperText, FormHelperTextProps } from '@allganize/ui-form';
import { InputLabel, InputLabelProps } from '@allganize/ui-input';
import { css } from '@emotion/react';
import { forwardRef, HTMLAttributes } from 'react';

export interface AccessControlFieldBaseProps
  extends HTMLAttributes<HTMLFieldSetElement> {
  label?: React.ReactNode;
  InputLabelProps?: Partial<InputLabelProps>;
  helperText?: React.ReactNode;
  FormHelperTextProps?: Partial<FormHelperTextProps>;
}

export const AccessControlFieldBase = forwardRef<
  HTMLFieldSetElement,
  AccessControlFieldBaseProps
>(
  (
    {
      label,
      InputLabelProps,
      helperText,
      FormHelperTextProps,
      children,
      ...others
    },
    ref,
  ) => {
    return (
      <fieldset
        css={css`
          margin: 0;
          padding: 0;
          width: 100%;
          outline: none;
          border: none;
        `}
        {...others}
        ref={ref}
      >
        {label && (
          <InputLabel
            css={css`
              width: 100%;
            `}
            component="legend"
            {...InputLabelProps}
          >
            {label}
          </InputLabel>
        )}
        <div
          css={css`
            overflow: hidden;
          `}
        >
          {children}
        </div>
        {helperText && (
          <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>
        )}
      </fieldset>
    );
  },
);
