import { DraftFilePlugin } from '@allganize/draft-file-plugin';
import { EditorState, removeBlockIfEmpty } from '@allganize/draft-input';

export const getChatFileValue = <T extends { url: string }>({
  file,
}: {
  file: T | null | (T | null)[];
}) => {
  const contentStateWithFile = (Array.isArray(file) ? file : [file])
    .reduce((acc, curr) => {
      if (!curr) {
        return acc;
      }

      return DraftFilePlugin.addFile(acc, curr.url, curr);
    }, EditorState.createEmpty())
    .getCurrentContent();
  const firstBlockRemoved = removeBlockIfEmpty(
    contentStateWithFile,
    contentStateWithFile.getBlockMap().first(),
  );
  return removeBlockIfEmpty(
    firstBlockRemoved,
    firstBlockRemoved.getBlockMap().last(),
  );
};
