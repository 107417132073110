import { useEventCallback } from '@allganize/hooks';
import { useToggleKnowledgeBase } from '@allganize/knowledge-base-actions';
import { Truncate } from '@allganize/truncate';
import { IcIndicatorBullet } from '@allganize/ui-icons';
import { Switch } from '@allganize/ui-switch';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment, ResultOf, Scalars } from '../gql';
import { PanelItem } from '../panel-item/panel-item';

export const FileStatus_KnowledgeBaseNodeFragment = gql(`
  fragment FileStatus_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    __typename
    id
    ownership
    accessAgents {
      accessType
      agent {
        id
        isMe
      }
    }

    ...on KnowledgeBaseNodeFile {
      status
    }
  }
`);

export interface FileStatusProps {
  projectId: Scalars<'ID'>;
  data: FragmentOf<typeof FileStatus_KnowledgeBaseNodeFragment>;
  isEditable?(
    node: ResultOf<typeof FileStatus_KnowledgeBaseNodeFragment>,
  ): boolean;
}

export const FileStatus: FunctionComponent<FileStatusProps> = ({
  projectId,
  data: dataProp,
  isEditable = () => true,
}) => {
  const [loading, setLoading] = useState(false);
  const [toggleKnowledgeBase] = useToggleKnowledgeBase({});
  const theme = useTheme();

  const data = readFragment(FileStatus_KnowledgeBaseNodeFragment, dataProp);
  const isEditableNode = isEditable(data);

  const handleToggleStatus = useEventCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setLoading(true);

      try {
        await toggleKnowledgeBase({
          variables: {
            ids: [data.id],
            use: checked,
            project: { id: projectId },
          },
        });
      } finally {
        setLoading(false);
      }
    },
  );

  if (data.__typename !== 'KnowledgeBaseNodeFile') {
    return null;
  }

  return (
    <PanelItem
      label={
        <FormattedMessage
          id="knowledge-base-side-panel.activate"
          defaultMessage="Activate"
          description="Knowledge base side panel activate label text"
        />
      }
      action={
        isEditableNode ? (
          <Switch
            edge="end"
            checked={
              data.status === gql.scalar('KnowledgeBaseNodeOnOffState', 'ON')
            }
            disabled={loading}
            onChange={handleToggleStatus}
          />
        ) : (
          <div
            css={css`
              display: inline-flex;
              align-items: center;
              gap: 3px;
            `}
          >
            <IcIndicatorBullet
              css={[
                css`
                  flex-shrink: 0;
                  color: ${theme.palette.foreground.secondary};
                `,
                data.status ===
                  gql.scalar('KnowledgeBaseNodeOnOffState', 'ON') &&
                  css`
                    color: ${theme.palette.foreground.primary.default};
                  `,
              ]}
              fontSize="small"
            />

            <Truncate
              css={css`
                flex-grow: 1;
                word-break: break-all;
              `}
              clamp={1}
            >
              <Text variant="body14">{data.status}</Text>
            </Truncate>
          </div>
        )
      }
    />
  );
};
