import { createIllustration } from '../create-illustration';
import qna from './qna.png';
import qna2x from './qna@2x.png';
import qna3x from './qna@3x.png';

export const Qna = createIllustration({
  src: qna,
  srcSet: [qna, qna2x, qna3x].map((src, i) => `${src} ${i + 1}x`).join(','),
  size: 64,
});
