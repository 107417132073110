import { useTheme } from '@allganize/ui-theme';
import { Tooltip, tooltipClasses } from '@allganize/ui-tooltip';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { TimestampTooltipProps } from './timestamp-tooltip.types';

export const TimestampTooltip = forwardRef<
  HTMLDivElement,
  TimestampTooltipProps
>((props, ref) => {
  const { align, timestamp, ...other } = props;
  const theme = useTheme();
  const intl = useIntl();
  const title = intl.formatTime(timestamp);
  const placement = align === 'left' ? 'top-start' : 'top-end';

  return (
    <ClassNames>
      {({ css }) => (
        <Tooltip
          enterDelay={500}
          enterNextDelay={500}
          {...other}
          ref={ref}
          classes={{
            popper: css`
              z-index: ${Math.min(
                theme.zIndex.tooltip,
                theme.zIndex.appBar - 1,
              )};
            `,
            tooltip: css`
              .${tooltipClasses.popper}[data-popper-placement*="top"] & {
                margin: 0 0 -16px 0;
              }

              .${tooltipClasses.popper}[data-popper-placement*="bottom"] & {
                margin: -16px 0 0 0;
              }
            `,
          }}
          placement={placement}
          title={title}
          slotProps={{
            popper: {
              disablePortal: true,
            },
          }}
        />
      )}
    </ClassNames>
  );
});
