import { IconButton } from '@allganize/ui-button';
import { IcClose } from '@allganize/ui-icons';
import { BaseState, Illustration } from '@allganize/ui-state';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { Toolbar } from '@mui/material';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

interface DocumentsRightPanelErrorFallbackProps {
  onClose?(): void;
}

export const DocumentsRightPanelErrorFallback: FunctionComponent<
  DocumentsRightPanelErrorFallbackProps
> = props => {
  const theme = useTheme();

  return (
    <>
      <Toolbar
        css={css`
          position: sticky;
          top: 0;
          z-index: 1;
          justify-content: flex-end;
          background-color: ${theme.palette.unstable_background.white};
          padding-left: 16px;
          padding-right: 16px;

          ${theme.breakpoints.up('xs')} {
            padding-left: 16px;
            padding-right: 16px;
          }
        `}
      >
        <IconButton edge="end" onClick={props.onClose}>
          <IcClose />
        </IconButton>
      </Toolbar>

      <div
        css={css`
          padding: 100px 16px;
        `}
      >
        <BaseState
          variant="card"
          image={<Illustration.Error width={64} height={64} />}
          title={
            <FormattedMessage
              id="knowledge-base-side-panel.error-title"
              defaultMessage="Unable to load file information"
              description="Knowledge base side panel error title text"
            />
          }
          description={
            <FormattedMessage
              id="knowledge-base-side-panel.error-description"
              defaultMessage="Please check your network connection or try again later."
              description="Knowledge base side panel error description text"
            />
          }
        />
      </div>
    </>
  );
};
