import { ChatStackItemProps } from '@allganize/alli-chat-base';
import { FunctionComponent } from 'react';
import {
  GeneratingState,
  GeneratingState_BotChatFragment,
} from '../generating-state';
import { FragmentOf, gql, readFragment } from '../gql';
import {
  IntermediateSteps,
  IntermediateSteps_BotChatFragment,
} from '../intermediate-steps';

export const AnswerProgress_ChatFragment = gql(
  `
  fragment AnswerProgress_ChatFragment on Chat {
    ...on BotChat {
      __typename
      id

      intermediateSteps {
        id
      }

      ...GeneratingState_BotChatFragment
      ...IntermediateSteps_BotChatFragment
    }
  }
`,
  [GeneratingState_BotChatFragment, IntermediateSteps_BotChatFragment],
);

export interface AnswerProgressProps extends ChatStackItemProps {
  chat: FragmentOf<typeof AnswerProgress_ChatFragment>;
}

export const AnswerProgress: FunctionComponent<AnswerProgressProps> = props => {
  const { chat: chatProp, ...other } = props;
  const chat = readFragment(AnswerProgress_ChatFragment, chatProp);

  if (chat.__typename !== 'BotChat') {
    return null;
  }

  if (chat.intermediateSteps?.length) {
    return <IntermediateSteps chat={chat} {...other} />;
  }

  return <GeneratingState chat={chat} {...other} />;
};
