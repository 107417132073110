import { ConversationContext } from '@allganize/alli-sdk/conversation-detail/conversation-context';
import {
  UseConversationOptions,
  useConversation,
} from '@allganize/alli-sdk/hooks/use-conversation';
import { FunctionComponent } from 'react';

interface ConversationProviderProps extends UseConversationOptions {
  children?: React.ReactNode;
}

export const ConversationProvider: FunctionComponent<
  ConversationProviderProps
> = props => {
  const conversation = useConversation(props);
  return (
    <ConversationContext.Provider value={conversation}>
      {props.children}
    </ConversationContext.Provider>
  );
};
