import {
  ChatCarousel,
  ChatCarouselItem,
  ChatStackItem,
  ChatStackItemProps,
} from '@allganize/alli-chat-base';
import { carouselArrowClasses, carouselClasses } from '@allganize/ui-carousel';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';
import {
  LinkImageCarouselItem,
  LinkImageCarouselItem_CarouselOptionFragment,
  LinkImageCarouselItemProps,
} from './link-image-carousel-item';

const horizontalPadding = 12;

export const LinkImageCarousel_ChatFragment = gql(
  `
  fragment LinkImageCarousel_ChatFragment on Chat {
    ...on BotChat {
      __typename
      id

      carouselOptions {
        id
        ...LinkImageCarouselItem_CarouselOptionFragment
      }
    }
  }
`,
  [LinkImageCarouselItem_CarouselOptionFragment],
);

export interface LinkImageCarouselProps
  extends Pick<ChatStackItemProps, 'className' | 'disablePadding'> {
  chat: FragmentOf<typeof LinkImageCarousel_ChatFragment>;
  onCarouselItemClick?: LinkImageCarouselItemProps['onClick'];
}

export const LinkImageCarousel: FunctionComponent<
  LinkImageCarouselProps
> = props => {
  const {
    chat: chatProp,
    className,
    disablePadding,
    onCarouselItemClick,
  } = props;
  const chat = readFragment(LinkImageCarousel_ChatFragment, chatProp);

  if (chat.__typename !== 'BotChat') {
    return null;
  }

  if (!chat.carouselOptions?.length) {
    return null;
  }

  return (
    <ChatStackItem className={className} disablePadding={disablePadding}>
      <ChatCarousel
        css={css`
          .${carouselArrowClasses.root}.${carouselArrowClasses.prev} {
            right: ${horizontalPadding + 36}px;
          }

          .${carouselArrowClasses.root}.${carouselArrowClasses.next} {
            right: ${horizontalPadding}px;
          }

          .${carouselClasses.list} {
            padding-left: ${horizontalPadding - 4}px;
          }
        `}
      >
        {chat.carouselOptions.map(option => (
          <ChatCarouselItem key={option.id}>
            <LinkImageCarouselItem
              option={option}
              onClick={onCarouselItemClick}
            />
          </ChatCarouselItem>
        ))}
      </ChatCarousel>
    </ChatStackItem>
  );
};
