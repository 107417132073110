import { FormHelperText } from '@allganize/ui-form';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { SelectedField, SelectedFieldProps } from './selected-field';
import {
  useFaqCarouselForm,
  UseFaqCarouselFormOptions,
} from './use-faq-carousel-form';

export interface FaqCarouselFormProps
  extends UseFaqCarouselFormOptions,
    SelectedFieldProps {
  align?: 'left' | 'right';
}

export const FaqCarouselForm: FunctionComponent<
  FaqCarouselFormProps
> = props => {
  const {
    align = 'right',
    disabled,
    isOptionDisabled,
    onSubmit,
    options,
  } = props;
  const { form, submit } = useFaqCarouselForm({ disabled, onSubmit });
  const {
    formState: { errors },
    handleSubmit,
    watch,
  } = form;

  useEffect(() => {
    const subscription = watch(values => {
      if (!values.selected) {
        return;
      }

      handleSubmit(submit)();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [handleSubmit, submit, watch]);

  return (
    <form
      css={[
        css`
          margin: 0;
          display: flex;
          flex-direction: column;
        `,
        align === 'right' &&
          css`
            align-items: flex-end;
          `,
      ]}
      onSubmit={handleSubmit(submit)}
    >
      <FormProvider {...form}>
        <SelectedField
          align={align}
          isOptionDisabled={isOptionDisabled}
          options={options}
        />
      </FormProvider>

      {errors.root?.message && (
        <FormHelperText error>
          <Text variant="inherit" align={align}>
            {errors.root.message}
          </Text>
        </FormHelperText>
      )}
    </form>
  );
};
