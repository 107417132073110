import { Button, ButtonProps } from '@allganize/ui-button';
import { IcArrowNavigateNext } from '@allganize/ui-icons';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface KnowledgeBaseManageLinkProps extends ButtonProps {
  knowledgeBaseNodeId: string | number;
}

export const KnowledgeBaseManageLink: FunctionComponent<
  KnowledgeBaseManageLinkProps
> = ({ knowledgeBaseNodeId, ...others }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const newParams = new URLSearchParams(searchParams);
  newParams.set('detail', knowledgeBaseNodeId.toString());

  return (
    <Button
      component="a"
      size="small"
      endIcon={<IcArrowNavigateNext />}
      onClick={() => {
        navigate({
          pathname: '/documents',
          search: newParams.toString(),
        });
      }}
      {...others}
    >
      <FormattedMessage
        id="knowledge-base-list.error-state-node.manage-button.title"
        defaultMessage="Manage"
        description="knowledge base list error state node manage button title"
      />
    </Button>
  );
};
