import type { DraftDocumentEntity } from '@allganize/draft-document-plugin';
import type { Element } from 'hast';
import type { Root, Text } from 'mdast';
import type { Transformer } from 'unified';
import { type BuildVisitor, visit } from 'unist-util-visit';

export interface RehypeCitationOptions {
  citations: (DraftDocumentEntity['data'] & { id: string | number })[];
}

export function rehypeCitation(
  options: RehypeCitationOptions,
): Transformer<Root> {
  return root => {
    const visitor: BuildVisitor<Root | Element, string> = node => {
      if (node.type !== 'element') {
        return;
      }

      if (node.tagName !== 'cite') {
        return;
      }

      const citationId = node.properties?.['citation-id'];

      if (typeof citationId !== 'string') {
        return;
      }

      const citation = options.citations.find(
        c => c.id.toString() === citationId,
      );

      if (!citation) {
        return;
      }

      let text = '';

      visit<Element | Text, string>(node, 'text', node => {
        if (node.type !== 'text') {
          return;
        }

        text += node.value;
      });

      const data = node.data || {};
      data.citation = {
        key: citationId,
        text: text || citationId,
        data: citation,
      } satisfies DraftDocumentEntity;
      node.data = data;
      return;
    };

    visit<Root | Element, string>(root, 'element', visitor);
    return root;
  };
}
