import { List } from '@allganize/ui-list';
import styled from '@emotion/styled';
import {
  defaultRangeExtractor,
  Range,
  useVirtualizer,
} from '@tanstack/react-virtual';
import { useEffect, useMemo, useRef } from 'react';
import {
  HistoryListGroupLabel,
  historyListGroupLabelHeight,
} from './history-list-group-label';
import { HistoryListItem, listItemSize } from './history-list-item';
import { HistoryListItemSkeleton } from './history-list-item-skeleton';
import { useGroupedConversationList } from './use-grouped-conversation-list';

const skeletonCount = 5;
const groupGap = 12;

const ListWrapper = styled.div`
  padding: 0 8px;
  height: 100%;
  overflow: hidden auto;
  flex: 1;
`;

const ListContent = styled(List)`
  a {
    text-decoration: none;
  }
` as typeof List;

export const HistoryList = () => {
  const listWrapperRef = useRef<HTMLDivElement>(null);
  const { items, loadNextPage, isPending } = useGroupedConversationList();
  const activeStickyIndexRef = useRef<number>(0);
  const stickyIndexes = useMemo(
    () =>
      items.reduce<number[]>((acc, item, index) => {
        if (item.type === 'group-label') {
          return [...acc, index];
        }

        return acc;
      }, []),
    [items],
  );
  const isActiveSticky = (index: number) =>
    activeStickyIndexRef.current === index;

  const rangeExtractor = (range: Range): number[] => {
    activeStickyIndexRef.current =
      [...stickyIndexes].reverse().find(index => range.startIndex >= index) ??
      0;

    const next = new Set([
      activeStickyIndexRef.current,
      ...defaultRangeExtractor(range),
    ]);

    return [...next].sort((a, b) => a - b);
  };

  const virtualizer = useVirtualizer({
    getScrollElement: () => listWrapperRef.current,
    count: isPending ? items.length + skeletonCount : items.length,
    estimateSize: index => {
      if (items[index]?.type === 'group-label') {
        return historyListGroupLabelHeight;
      }

      if (items[index + 1]?.type === 'group-label') {
        return listItemSize + groupGap;
      }

      return listItemSize;
    },
    overscan: skeletonCount,
    paddingEnd: 24,
    rangeExtractor,
  });
  const virtualItems = virtualizer.getVirtualItems();
  const lastItem = virtualItems[virtualItems.length - 1];

  useEffect(() => {
    if (!lastItem) {
      return;
    }

    if (lastItem.index >= items.length - 1 - skeletonCount) {
      loadNextPage();
    }
  }, [lastItem, loadNextPage, items.length]);

  return (
    <ListWrapper ref={listWrapperRef}>
      <ListContent
        disablePadding
        component="div"
        style={{
          position: 'relative',
          width: '100%',
          height: virtualizer.getTotalSize(),
        }}
      >
        {virtualItems.map(virtualRow => {
          if (!virtualRow) {
            return null;
          }

          const item = items[virtualRow.index];

          if (virtualRow.index >= items.length) {
            return (
              <HistoryListItemSkeleton
                key={virtualRow.key}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: virtualRow.size,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              />
            );
          }

          if (item.type === 'group-label') {
            const sticky = isActiveSticky(virtualRow.index);

            return (
              <HistoryListGroupLabel
                key={virtualRow.key}
                style={{
                  position: sticky ? 'sticky' : 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: virtualRow.size,
                  transform: sticky
                    ? undefined
                    : `translateY(${virtualRow.start}px)`,
                  zIndex: 1,
                }}
                label={item.label}
              />
            );
          }

          if (item.type === 'conversation') {
            return (
              <div
                key={virtualRow.key}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: virtualRow.size,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <HistoryListItem data={item.conversation} />
              </div>
            );
          }

          return null;
        })}
      </ListContent>
    </ListWrapper>
  );
};
