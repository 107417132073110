import { DraftInputBaseRef } from '@allganize/draft-input';
import { forwardRef } from 'react';
import { RichText } from '../rich-text';
import { ChatMediaProps } from './chat-media.types';
import { useChatMedia } from './use-chat-media';

interface ChatMediaType {
  <T extends { mediaType?: string | null; url: string }>(
    props: React.PropsWithoutRef<ChatMediaProps<T>> &
      React.RefAttributes<DraftInputBaseRef>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

export const ChatMedia: ChatMediaType = forwardRef((props, ref) => {
  const { media, ...other } = props;
  const { value, plugins } = useChatMedia({ media });

  return <RichText {...other} ref={ref} plugins={plugins} value={value} />;
});
