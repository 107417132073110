import {
  DocumentViewer,
  DocumentViewerPageInfo,
  DocumentViewerProps,
  DraftPageInfo,
  getDocumentPreviewThumbnailUrl,
  HtmlPageInfo,
  ImagePageInfo,
  PdfPageInfo,
} from '@allganize/alli-document';
import { useProject } from '@allganize/alli-works-project';
import { convertFromRaw, EditorState } from '@allganize/draft-input';
import { useEventCallback, useMediaQuery } from '@allganize/hooks';
import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import { Dialog, dialogClasses, DialogCloseButton } from '@allganize/ui-dialog';
import { IcOpenInNew } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { Tooltip } from '@allganize/ui-tooltip';
import {
  skipToken,
  useApolloClient,
  useSuspenseQuery,
} from '@apollo/client/react';
import { css } from '@emotion/react';
import { useAtom } from 'jotai';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DocumentsLayoutContext } from '../documents-layout/documents-layout-context';
import { gql, readFragment } from '../gql';
import { documentPreviewAtom } from './document-preview-state';

const DocumentPreview_KnowledgeBasePreviewTotalPageCountQuery = gql(`
  query DocumentPreview_KnowledgeBasePreviewTotalPageCountQuery(
    $node: KnowledgeBaseNodeWhereUniqueInput!
    $document: KnowledgeBaseWhereUniqueInput!
  ) {
    knowledgeBaseNode(where: $node) {
      id
      name

      ...on KnowledgeBaseNodeFile {
        originalWebUri
      }
    }

    knowledgeBasePreview(where: $document) {
      totalPageCount
    }
  }
`);

const DocumentPreview_PreviewCapacityFragment = gql(`
  fragment DocumentPreview_PreviewCapacityFragment on PreviewCapacity {
    pdf
    html
    draftjs
    image
  }
`);

const DocumentPreview_KnowledgeBasePreviewQuery = gql(
  `
  query DocumentPreview_KnowledgeBasePreviewQuery(
    $where: KnowledgeBaseWhereUniqueInput!
    $pageNo: Int
  ) {
    knowledgeBasePreview(
      where: $where
      pageNo: $pageNo
    ) {
      totalPageCount
      pageNo
      html
      pdf

      draftjs {
        id
        answer
      }

      knowledgeBase {
        ... on GoogleDocsKnowledgeBase {
          id

          previewCapacity {
            ...DocumentPreview_PreviewCapacityFragment
          }
        }

        ... on TextKnowledgeBase {
          id

          previewCapacity {
            ...DocumentPreview_PreviewCapacityFragment
          }
        }

        ... on ImageKnowledgeBase {
          id

          previewCapacity {
            ...DocumentPreview_PreviewCapacityFragment
          }
        }

        ... on MSDocsKnowledgeBase {
          id

          previewCapacity {
            ...DocumentPreview_PreviewCapacityFragment
          }
        }

        ... on PDFKnowledgeBase {
          id

          previewCapacity {
            ...DocumentPreview_PreviewCapacityFragment
          }
        }

        ... on MSExcelKnowledgeBase {
          id

          previewCapacity {
            ...DocumentPreview_PreviewCapacityFragment
          }
        }

        ... on MSPPTKnowledgeBase {
          id

          previewCapacity {
            ...DocumentPreview_PreviewCapacityFragment
          }
        }

        ... on OldMSDocsKnowledgeBase {
          id

          previewCapacity {
            ...DocumentPreview_PreviewCapacityFragment
          }
        }

        ... on CSVKnowledgeBase {
          id

          previewCapacity {
            ...DocumentPreview_PreviewCapacityFragment
          }
        }

        ... on HWPKnowledgeBase {
          id

          previewCapacity {
            ...DocumentPreview_PreviewCapacityFragment
          }
        }
      }
    }
  }
`,
  [DocumentPreview_PreviewCapacityFragment],
);

export const DocumentPreview: FunctionComponent = () => {
  const project = useProject();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [state, setState] = useAtom(documentPreviewAtom(project.id));
  const client = useApolloClient();
  const context = useContext(DocumentsLayoutContext);
  const { data } = useSuspenseQuery(
    DocumentPreview_KnowledgeBasePreviewTotalPageCountQuery,
    state.nodeId === null
      ? skipToken
      : {
          variables: {
            node: { id: state.nodeId, project: { id: project.id } },
            document: {
              id: state.nodeId,
              projectWhere: { id: project.id },
            },
          },
        },
  );
  const [viewerPages, setViewerPages] = useState<
    Pick<DocumentViewerProps, 'pages' | 'thumbnails'>
  >({ pages: [], thumbnails: [] });
  const name = data?.knowledgeBaseNode?.name;
  const previewUrl =
    data?.knowledgeBaseNode?.__typename === 'KnowledgeBaseNodeFile' &&
    data.knowledgeBaseNode.originalWebUri;
  const totalPageCount = data?.knowledgeBasePreview?.totalPageCount ?? 0;

  const handleClose = () => {
    setState(prev => ({ ...prev, nodeId: null }));
  };

  const getPages = useEventCallback(async () => {
    if (state.nodeId === null) {
      setViewerPages({ pages: [], thumbnails: [] });
      return;
    }

    if (totalPageCount === 0) {
      setViewerPages({ pages: [], thumbnails: [] });
      return;
    }

    const kbId = state.nodeId;

    const pages = Array.from({ length: totalPageCount }).map((_, index) => {
      return async (): Promise<DocumentViewerPageInfo> => {
        const pageNo = index + 1;

        const result = await client.query({
          query: DocumentPreview_KnowledgeBasePreviewQuery,
          variables: {
            where: { id: kbId, projectWhere: { id: project.id } },
            pageNo,
          },
        });

        const kbp = result.data.knowledgeBasePreview;
        const previewCapacity = readFragment(
          DocumentPreview_PreviewCapacityFragment,
          kbp?.knowledgeBase?.previewCapacity,
        );

        const imagePage: ImagePageInfo = {
          type: 'image',
          key: `${kbId}-${index}`,
          url: getDocumentPreviewThumbnailUrl({
            projectId: project.id,
            knowledgeBaseId: kbId,
            pageNo,
            base: context.documentPreviewThumbnailBase,
          }),
          alt: name,
        };

        if (previewCapacity?.image) {
          return imagePage;
        }

        if (previewCapacity?.draftjs && kbp?.draftjs) {
          const draftPage: DraftPageInfo = {
            type: 'draft',
            key: kbp.draftjs.id,
            editorState: EditorState.createWithContent(
              convertFromRaw(kbp.draftjs.answer),
            ),
          };

          return draftPage;
        }

        if (previewCapacity?.html && kbp?.html) {
          const htmlPage: HtmlPageInfo = {
            type: 'html',
            key: `${kbId}-${index}`,
            html: kbp.html,
            fallback: imagePage,
          };

          return htmlPage;
        }

        if (previewCapacity?.pdf && kbp?.pdf) {
          const pdfPage: PdfPageInfo = {
            type: 'pdf',
            key: `${kbId}-${index}`,
            url: kbp.pdf,
            options: context.pdfjs,
            fallback: imagePage,
            answerHighlights: null,
          };

          return pdfPage;
        }

        return imagePage;
      };
    });

    const thumbnails: DocumentViewerProps['thumbnails'] = Array.from({
      length: totalPageCount,
    }).map((_, index) => {
      return {
        src: getDocumentPreviewThumbnailUrl({
          projectId: project.id,
          knowledgeBaseId: kbId,
          pageNo: index + 1,
          width: 180,
          base: context.documentPreviewThumbnailBase,
        }),
      };
    });

    setViewerPages({ pages, thumbnails });
  });

  useEffect(() => {
    getPages();
  }, [client, getPages, project.id, state.nodeId, totalPageCount]);

  return (
    <Dialog
      css={css`
        .${dialogClasses.paper} {
          height: 100%;
        }
      `}
      fullWidth
      maxWidth={false}
      fullScreen={fullScreen}
      open={state.nodeId !== null && !!data}
      onClose={handleClose}
    >
      <DocumentViewer
        css={css`
          width: 100%;
          height: 100%;
        `}
        initialPage={0}
        pages={viewerPages.pages}
        thumbnails={viewerPages.thumbnails}
        header={
          <>
            <Text variant="title12">
              <Truncate clamp={1}>{name}</Truncate>
            </Text>

            {previewUrl && (
              <Tooltip
                title={
                  <FormattedMessage
                    id="document-preview.original-web-uri"
                    defaultMessage="Open page in a new tab"
                    description="Document preview original web uri tooltip"
                  />
                }
              >
                <IconButton
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={previewUrl}
                >
                  <IcOpenInNew />
                </IconButton>
              </Tooltip>
            )}

            <DialogCloseButton edge="end" />
          </>
        }
      />
    </Dialog>
  );
};
