import { useId } from '@allganize/hooks';
import { Button } from '@allganize/ui-button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@allganize/ui-dialog';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

interface BlockUploadFileProps
  extends Pick<
    DialogProps,
    'open' | 'fullScreen' | 'fullWidth' | 'maxWidth' | 'onClose'
  > {
  count: number;
  maxTrialKbFileCount: number;
}

export const BlockUploadFile: FunctionComponent<
  BlockUploadFileProps
> = props => {
  const { count, maxTrialKbFileCount, onClose, ...other } = props;
  const theme = useTheme();
  const titleId = useId();

  const handleConfirm = () => {
    onClose?.({}, 'closeButtonClick');
  };

  return (
    <Dialog aria-labelledby={titleId} onClose={onClose} {...other}>
      <DialogTitle id={titleId}>
        <FormattedMessage
          id="FAQ.Documents.UploadDialog.ExceedError.Title"
          defaultMessage="Document upload limit"
          description="document limit exceed error modal title"
        />
      </DialogTitle>

      <DialogContent>
        {count >= maxTrialKbFileCount ? (
          <FormattedMessage
            id="FAQ.Documents.UploadDialog.ExceedError.NoMore"
            defaultMessage="During the trial period, you can upload only up to {docLimit} documents. There are already {docNum} documents uploaded."
            description="document limit exceed error message"
            values={{
              docLimit: maxTrialKbFileCount,
              docNum: count,
            }}
          />
        ) : (
          <FormattedMessage
            id="FAQ.Documents.UploadDialog.ExceedError.MoreThanLimit"
            defaultMessage="During the trial period, there is a limit of uploading up to {docLimit} documents. {docNum} documents are already uploaded, <highlight>you can only upload {docSub} more.</highlight>"
            description="document limit exceed error message"
            values={{
              docLimit: maxTrialKbFileCount,
              docNum: count,
              docSub: maxTrialKbFileCount - count,
              highlight(chunks) {
                return (
                  <span
                    css={css`
                      font-weight: ${theme.typography.fontWeightBold};
                    `}
                  >
                    {chunks}
                  </span>
                );
              },
            }}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="filled"
          size="large"
          onClick={handleConfirm}
        >
          <FormattedMessage
            id="actions.confirm"
            defaultMessage="Confirm"
            description="confirm button text"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
