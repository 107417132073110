import {
  ButtonBase,
  buttonBaseClasses,
  ExtendButtonBase,
} from '@allganize/ui-button';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { cloneElement, forwardRef, isValidElement, useContext } from 'react';
import { TabsContext } from '../tabs/tabs-context';
import { tabClasses } from './tab.classes';
import { TabTypeMap } from './tab.types';

// @ts-expect-error overridable component
export const Tab: ExtendButtonBase<TabTypeMap> = forwardRef((props, ref) => {
  const {
    classes,
    className,
    disabled = false,
    focusVisibleClassName,
    fullWidth: fullWidthProp,
    icon: iconProp,
    indicator,
    label,
    onChange: onChangeProp,
    onClick,
    selected,
    selectionFollowsFocus,
    textColor,
    value,
    wrapped = false,
    ...other
  } = props;
  const theme = useTheme();
  const {
    fullWidth,
    onChange,
    variant = 'underlined',
  } = useContext(TabsContext);
  const icon =
    iconProp && isValidElement(iconProp)
      ? cloneElement(iconProp, {
          // @ts-expect-error className is not defined in ReactElement
          className: clsx(
            tabClasses.iconWrapper,
            classes?.iconWrapper,
            iconProp.props?.className,
          ),
        })
      : iconProp;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!selected) {
      onChange?.(event, value);
    }

    onClick?.(event);
  };

  return (
    // @ts-expect-error overridable component
    <ButtonBase
      data-testid="tab"
      css={[
        css`
          ${theme.typography.label14}
          max-width: 360px;
          min-width: 40px;
          position: relative;
          min-height: 36px;
          flex-shrink: 0;
          padding: 2px 12px;
          gap: 4px;
          overflow: hidden;
          white-space: normal;
          text-align: center;
          color: ${theme.palette.foregroundInteractive.gray.default};
          border-radius: ${theme.radius.sm}px;
          transition: ${theme.transitions.create(
            ['background-color', 'color'],
            { duration: theme.transitions.duration.short },
          )};

          &.${tabClasses.selected} {
            color: ${theme.palette.foregroundInteractive.primary};
          }

          &:hover {
            background-color: ${theme.palette.backgroundInteractive
              .grayGhostAlpha.hover};
          }

          &:active {
            background-color: ${theme.palette.backgroundInteractive
              .grayGhostAlpha.pressed};
          }

          &.${buttonBaseClasses.focusVisible} {
            outline: ${theme.palette.border.focused} solid 2px;
            outline-offset: 2px;
          }

          &.${tabClasses.disabled} {
            color: ${theme.palette.foreground.gray.disabled};
          }
        `,
        fullWidth &&
          css`
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: 0;
            max-width: none;
          `,
      ]}
      aria-selected={selected}
      disabled={disabled}
      onClick={handleClick}
      role="tab"
      tabIndex={selected ? 0 : -1}
      {...other}
      ref={ref}
      className={clsx(
        tabClasses.root,
        tabClasses[variant],
        {
          [tabClasses.fullWidth]: fullWidth,
          [tabClasses.wrapped]: wrapped,
          [tabClasses.selected]: selected,
          [tabClasses.disabled]: disabled,
        },
        classes?.root,
        classes?.[variant],
        {
          [classes?.fullWidth ?? '']: fullWidth,
          [classes?.wrapped ?? '']: wrapped,
          [classes?.selected ?? '']: selected,
          [classes?.disabled ?? '']: disabled,
        },
        className,
      )}
      focusVisibleClassName={clsx(
        tabClasses.focusVisible,
        classes?.focusVisible,
        focusVisibleClassName,
      )}
    >
      {icon}

      {label}

      {indicator}
    </ButtonBase>
  );
});
