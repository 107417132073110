import { IcRadioButtonCheck, IcRadioButtonUncheck } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiRadio from '@mui/material/Radio';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { radioClasses } from './radio-classes';
import { RadioProps } from './radio-type-map';

/**
 * Radio allow the user to select one item from a set.
 */
export const Radio = forwardRef<HTMLButtonElement, RadioProps>((props, ref) => {
  const { classes, edge = false, ...other } = props;
  const theme = useTheme();

  return (
    <MuiRadio
      data-testid="radio"
      css={[
        css`
          padding: 2px;
          transition: ${theme.transitions.create(
            ['color', 'background-color'],
            { duration: theme.transitions.duration.shortest },
          )};
          color: ${theme.palette.borderInteractive.gray.default};

          &:hover {
            color: ${theme.palette.borderInteractive.gray.hover};
            background-color: ${theme.palette.backgroundInteractive
              .grayGhostAlpha.hover};

            @media (hover: none) {
              background-color: transparent;
            }
          }

          &:active {
            color: ${theme.palette.borderInteractive.gray.pressed};
            background-color: ${theme.palette.backgroundInteractive
              .grayGhostAlpha.pressed};

            @media (hover: none) {
              background-color: transparent;
            }
          }

          &.${radioClasses.checked} {
            color: ${theme.palette.backgroundInteractive.primary.default};

            &:hover {
              color: ${theme.palette.backgroundInteractive.primary.hover};
              background-color: ${theme.palette.backgroundInteractive
                .primarySubtle.hover};

              @media (hover: none) {
                background-color: transparent;
              }
            }

            &:active {
              color: ${theme.palette.backgroundInteractive.primary.pressed};
              background-color: ${theme.palette.backgroundInteractive
                .primarySubtle.pressed};

              @media (hover: none) {
                background-color: transparent;
              }
            }
          }

          &.${radioClasses.disabled} {
            color: ${theme.palette.foreground.gray.disabled};
          }

          &.${radioClasses.focusVisible} {
            outline: ${theme.palette.border.focused} solid 2px;
            outline-offset: -2px;
          }
        `,
        edge === 'start' &&
          css`
            margin-left: -2px;
          `,
        edge === 'end' &&
          css`
            margin-right: -2px;
          `,
      ]}
      checkedIcon={<IcRadioButtonCheck />}
      icon={<IcRadioButtonUncheck />}
      color="primary"
      classes={classes}
      edge={edge}
      {...other}
      ref={ref}
      focusVisibleClassName={clsx(
        radioClasses.focusVisible,
        classes?.focusVisible,
        other.focusVisibleClassName,
      )}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      focusRipple={false}
    />
  );
});
