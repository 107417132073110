import { createContext } from 'react';
import { noop } from 'lodash-es';
import {
  AppPreviewMetadata,
  DocumentListMetadata,
  DocumentPreviewMetadata,
  TabNavigatorContextValue,
} from './use-tab-navigator';

export interface SidePanelContextValue {
  open: boolean;
  activeTab: TabNavigatorContextValue['activeTab'];
  selectedKnowledgeBaseIds: (string | number)[];
  openAppListTab: () => void;
  openAppPreviewTab: (metadata: AppPreviewMetadata) => void;
  openDocumentListTab: (metadata: DocumentListMetadata) => void;
  openDocumentPreviewTab: (metadata: DocumentPreviewMetadata) => void;
  uploadDocument: () => void;
  closeSidePanel: () => void;
}

export const SidePanelContext = createContext<SidePanelContextValue>({
  open: false,
  activeTab: null,
  selectedKnowledgeBaseIds: [],
  openAppListTab: noop,
  openAppPreviewTab: noop,
  openDocumentListTab: noop,
  openDocumentPreviewTab: noop,
  uploadDocument: noop,
  closeSidePanel: noop,
});
