import { CamelCase } from '@allganize/types';
import generateUtilityClasses from '@mui/material/generateUtilityClasses';
import { SearchInputSize, SearchInputVariant } from './search-input.types';

export type SearchInputClassKey =
  | 'root'
  | 'disabled'
  | SearchInputVariant
  | CamelCase<`size_${SearchInputSize}`>;
export type SearchInputClasses = Record<SearchInputClassKey, string>;

export const searchInputClasses: SearchInputClasses = generateUtilityClasses(
  'SearchInput',
  ['root', 'disabled', 'outlined', 'filled', 'sizeMedium', 'sizeLarge'],
);
