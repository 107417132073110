import {
  ChatFormRow,
  ChatFormRowProps,
  ChatStackItem,
  ChatStackItemProps,
} from '@allganize/alli-chat-base';
import { ContentState, EditorState } from '@allganize/draft-input';
import { isValid } from 'date-fns';
import { FunctionComponent, useMemo } from 'react';
import {
  AgentSelectOption,
  FileFormValue_FileFragment,
  FormValueBase,
  FormValueBase_VariableFragment,
  FormValueForm,
  FormValueFormProps,
  FormValueFormValues,
  FormValue as FormValueItem,
  FormValueOption,
  FormValueOption_MediaFragment,
  parseDate,
} from '../form-value-form';
import { FragmentOf, gql, readFragment, ResultOf } from '../gql';

export const FormValue_FormValueFragment = gql(
  `
  fragment FormValue_FormValueFragment on FormValue {
    display
    value
    mandatory
    choices
    multipleOption
    inputType

    file {
      ...FileFormValue_FileFragment
    }

    variable {
      type
      ...FormValueBase_VariableFragment
    }

    formValueInfos {
      longOption

      media {
        ...FormValueOption_MediaFragment
      }
    }
  }
`,
  [
    FileFormValue_FileFragment,
    FormValueBase_VariableFragment,
    FormValueOption_MediaFragment,
  ],
);

export const FormValue_ChatFragment = gql(
  `
  fragment FormValue_ChatFragment on Chat {
    ...on SendFormChat {
      __typename
      id
      enableAgentSelection

      targetAgents {
        id
        firstName
        lastName
        displayName

        avatar {
          id
          url
        }
      }

      formValues {
        ...FormValue_FormValueFragment
      }
    }

    ...on UserChat {
      __typename
      id

      formValues {
        ...FormValue_FormValueFragment
      }
    }
  }
`,
  [FormValue_FormValueFragment],
);

const getAgentSelectOptions = (
  targetAgents: NonNullable<
    Extract<
      ResultOf<typeof FormValue_ChatFragment>,
      { __typename: 'SendFormChat' }
    >['targetAgents']
  >,
) => {
  return targetAgents.reduce<AgentSelectOption[]>((acc, agent) => {
    if (!agent) {
      return acc;
    }

    return [
      ...acc,
      {
        label: agent.displayName || agent.firstName || agent.lastName || '',
        value: agent.id,
        avatar: agent.avatar?.url,
      },
    ];
  }, []);
};

export interface FormValueProps
  extends Pick<
      FormValueFormProps,
      'onSubmit' | 'onTyping' | 'slotProps' | 'slots' | 'validation'
    >,
    Pick<ChatFormRowProps, 'align' | 'avatar'>,
    Pick<ChatStackItemProps, 'className'> {
  chat: FragmentOf<typeof FormValue_ChatFragment>;
  disabled?: boolean;
}

export const FormValue: FunctionComponent<FormValueProps> = props => {
  const {
    align = 'right',
    avatar,
    chat: chatProp,
    className,
    disabled,
    onSubmit,
    onTyping,
    slotProps,
    slots,
    validation,
  } = props;
  const chat = readFragment(FormValue_ChatFragment, chatProp);

  const defaultValues = useMemo<Partial<FormValueFormValues>>(() => {
    if (chat.__typename !== 'SendFormChat' && chat.__typename !== 'UserChat') {
      return {};
    }

    if (chat.__typename === 'UserChat' && !chat.formValues?.length) {
      return {};
    }

    return {
      formValues: (chat.formValues ?? []).reduce<FormValueItem[]>(
        (acc, fragment) => {
          const curr = readFragment(FormValue_FormValueFragment, fragment);

          if (!curr) {
            return acc;
          }

          if (!curr.variable) {
            return acc;
          }

          const base: FormValueBase = {
            label: curr.display,
            required: curr.mandatory,
            variable: readFragment(
              FormValueBase_VariableFragment,
              curr.variable,
            ),
          };

          const options = (curr.formValueInfos ?? []).reduce<FormValueOption[]>(
            (acc, curr, i) => {
              if (!curr) {
                return acc;
              }

              return [
                ...acc,
                {
                  value: i,
                  label: curr.longOption,
                  media: curr.media
                    ? {
                        ...readFragment(
                          FormValueOption_MediaFragment,
                          curr.media,
                        ),
                        isOptionType: true,
                      }
                    : undefined,
                },
              ];
            },
            [],
          );

          const choices = (curr.choices ?? []).reduce<FormValueOption[]>(
            (acc, curr) => {
              if (curr === null) {
                return acc;
              }

              const option = options[curr];

              return [
                ...acc,
                {
                  value: curr,
                  label: option?.label ?? '',
                  media: option?.media,
                },
              ];
            },
            [],
          );

          if (
            curr.inputType ===
              gql.scalar('FormVariableInputTypeEnum', 'BUTTON') &&
            curr.formValueInfos?.length
          ) {
            if (curr.multipleOption) {
              return [
                ...acc,
                {
                  ...base,
                  type: 'checkbox',
                  options,
                  value: choices.map(curr => curr.value),
                },
              ];
            }

            return [
              ...acc,
              {
                ...base,
                type: 'radio',
                options,
                value: choices[0]?.value ?? null,
              },
            ];
          }

          if (
            curr.inputType ===
              gql.scalar('FormVariableInputTypeEnum', 'DROPDOWN') &&
            curr.formValueInfos?.length
          ) {
            if (curr.multipleOption) {
              return [
                ...acc,
                {
                  ...base,
                  type: 'multi-select',
                  options,
                  value: choices,
                },
              ];
            }

            return [
              ...acc,
              {
                ...base,
                type: 'single-select',
                options,
                value: choices[0] ?? null,
              },
            ];
          }

          if (curr.variable.type === gql.scalar('VariableType', 'NUMBER')) {
            const parsedValue = curr.value
              ? Number.parseFloat(curr.value)
              : null;

            return [
              ...acc,
              {
                ...base,
                type: 'number',
                value:
                  parsedValue !== null && Number.isNaN(parsedValue)
                    ? null
                    : parsedValue,
              },
            ];
          }

          if (curr.variable.type === gql.scalar('VariableType', 'BOOLEAN')) {
            return [
              ...acc,
              {
                ...base,
                type: 'switch',
                value: curr.value?.toLowerCase() === 'true',
              },
            ];
          }

          if (curr.variable.type === gql.scalar('VariableType', 'DATETIME')) {
            try {
              const parsedValue = curr.value ? parseDate(curr.value) : null;

              return [
                ...acc,
                {
                  ...base,
                  type: 'date',
                  value: isValid(parsedValue) ? parsedValue : null,
                },
              ];
            } catch {
              return [
                ...acc,
                {
                  ...base,
                  type: 'date',
                  value: null,
                },
              ];
            }
          }

          // FILE input type only when variable type is FILE
          if (curr.variable.type === gql.scalar('VariableType', 'FILE')) {
            return [
              ...acc,
              {
                ...base,
                type: 'file',
                value: readFragment(FileFormValue_FileFragment, curr.file),
              },
            ];
          }

          return [
            ...acc,
            {
              ...base,
              type: 'text',
              value: EditorState.createWithContent(
                ContentState.createFromText(curr.value ?? '', '\n'),
              ),
            },
          ];
        },
        [],
      ),
    };
  }, [chat]);

  const targetAgents = useMemo<FormValueFormProps['targetAgents']>(() => {
    return {
      disabled:
        chat.__typename === 'SendFormChat' ? !chat.enableAgentSelection : true,
      options:
        chat.__typename === 'SendFormChat'
          ? getAgentSelectOptions(chat.targetAgents ?? [])
          : [],
    };
  }, [chat]);

  if (disabled) {
    return null;
  }

  if (chat.__typename !== 'SendFormChat' && chat.__typename !== 'UserChat') {
    return null;
  }

  if (chat.__typename === 'UserChat' && !chat.formValues?.length) {
    return null;
  }

  return (
    <ChatStackItem className={className}>
      <ChatFormRow align={align} avatar={avatar}>
        <FormValueForm
          defaultValues={defaultValues}
          disabled={chat.__typename !== 'SendFormChat'}
          onSubmit={onSubmit}
          onTyping={onTyping}
          slotProps={slotProps}
          slots={slots}
          targetAgents={targetAgents}
          validation={validation}
        />
      </ChatFormRow>
    </ChatStackItem>
  );
};
