import { IntlShape } from 'react-intl';
import { AccessControlSelectUserOption } from '../access-control-user-select/access-control-user-select';
import { FragmentOf, gql, readFragment, ResultOf } from '../gql';

export const ParseAccessUserFilter_AccessUserFilterFragment = gql(`
  fragment ParseAccessUserFilter_AccessUserFilterFragment on AccessUserFilter {
    variableName
    displayValue
    value
  }
`);

export const parseAccessUserFilter = (
  fragment:
    | FragmentOf<typeof ParseAccessUserFilter_AccessUserFilterFragment>
    | ResultOf<typeof ParseAccessUserFilter_AccessUserFilterFragment>,
  intl: IntlShape,
): AccessControlSelectUserOption => {
  const value = readFragment(
    ParseAccessUserFilter_AccessUserFilterFragment,
    fragment,
  );
  const isGroup =
    value.variableName === gql.scalar('AccessUserFilterEnum', 'CUSTOMER_GROUP');

  if (isGroup) {
    return {
      type: gql.scalar('AccessUserFilterEnum', 'CUSTOMER_GROUP'),
      label: value.displayValue,
      value: value.value,
      description: intl.formatMessage({
        id: 'access-control.select.customer-group.description',
        defaultMessage: 'Group',
        description:
          'Description for customer group in access control select option',
      }),
      avatar: { variant: 'group' },
    };
  }

  return {
    type: gql.scalar('AccessUserFilterEnum', 'CUSTOMER_ID'),
    label:
      value.displayValue ||
      intl.formatMessage({
        id: 'access-control.user.name.placeholder',
        defaultMessage: 'Guest',
        description: 'Placeholder text for user name in access control',
      }),
    value: value.value,
    description: `ID: ${value.value}`,
    avatar: { name: value.displayValue },
  };
};
