import { UseRichTextOptions, useRichText } from '@allganize/alli-chat-content';
import { ContentState } from '@allganize/draft-input';
import {
  DraftDocumentEntity,
  DraftDocumentPlugin,
} from '@allganize/draft-document-plugin';
import { omit } from 'lodash-es';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FragmentOf, gql, readFragment, ResultOf } from '../gql';

export const DocumentEntities_KnowledgeBasePreviewFragment = gql(`
  fragment DocumentEntities_KnowledgeBasePreviewFragment on KnowledgeBasePreview {
    pageNo
    totalPageCount
    highlightIndexes
    exactAnswer

    draftjs {
      id
      answer
    }

    highlights {
      indexes

      pageIndexes {
        page
        indexes
      }
    }

    answerHighlightId

    knowledgeBaseEntity {
      id
      title
      originalWebUri
    }

    knowledgeBase {
      ... on GoogleDocsKnowledgeBase {
        id
        title
      }

      ... on TextKnowledgeBase {
        id
        title
      }

      ... on ImageKnowledgeBase {
        id
        title
      }

      ... on MSDocsKnowledgeBase {
        id
        title
      }

      ... on PDFKnowledgeBase {
        id
        title
      }

      ... on MSExcelKnowledgeBase {
        id
        title
      }

      ... on MSPPTKnowledgeBase {
        id
        title
      }

      ... on OldMSDocsKnowledgeBase {
        id
        title
      }

      ... on CSVKnowledgeBase {
        id
        title
      }

      ... on HWPKnowledgeBase {
        id
        title
      }
    }
  }
`);

export const DocumentEntities_CitationReferenceFragment = gql(
  `
  fragment DocumentEntities_CitationReferenceFragment on CitationReference {
    id
    url
    text
    isDeleted

    knowledgeBasePreview {
      ...DocumentEntities_KnowledgeBasePreviewFragment
    }
  }
`,
  [DocumentEntities_KnowledgeBasePreviewFragment],
);

export const getDocumentEntities = ({
  contentState,
  citationReferences,
  knowledgeBasePreview,
  knowledgeBasePreviewKeyPrefix,
  fallbackText = 'Preview',
}: {
  contentState?: ContentState;
  citationReferences?: readonly ResultOf<
    typeof DocumentEntities_CitationReferenceFragment
  >[];
  knowledgeBasePreview?: Omit<
    ResultOf<typeof DocumentEntities_KnowledgeBasePreviewFragment>,
    'answerHighlightId' | 'knowledgeBaseEntity'
  > | null;
  knowledgeBasePreviewKeyPrefix?: string;
  fallbackText?: string;
}) => {
  const result: DraftDocumentEntity[] = [];

  if (contentState) {
    result.push(...DraftDocumentPlugin.getDocumentEntities(contentState));
  }

  if (citationReferences) {
    citationReferences.forEach(cr => {
      const kbp = readFragment(
        DocumentEntities_KnowledgeBasePreviewFragment,
        cr.knowledgeBasePreview,
      );

      if (!kbp) {
        return;
      }

      const kbTitle =
        kbp.knowledgeBaseEntity?.title ?? kbp.knowledgeBase?.title;

      result.push({
        key: cr.id.toString(),
        text: cr.text ?? (kbTitle ? '' : fallbackText),
        data: {
          ...cr,
          isDeleted: cr.isDeleted ?? false,
          knowledgeBasePreview: {
            ...kbp,
            knowledgeBase: kbp.knowledgeBaseEntity ?? kbp.knowledgeBase,
          },
        },
      });
    });
  }

  if (knowledgeBasePreview?.draftjs || knowledgeBasePreview?.knowledgeBase) {
    result.push({
      key: [
        knowledgeBasePreviewKeyPrefix,
        knowledgeBasePreview.draftjs?.id,
        knowledgeBasePreview.knowledgeBase?.id,
        knowledgeBasePreview.exactAnswer,
        knowledgeBasePreview.pageNo,
        knowledgeBasePreview.totalPageCount,
        knowledgeBasePreview.highlights?.indexes?.join('--'),
        knowledgeBasePreview.highlights?.pageIndexes?.map(pi =>
          [pi.page, pi.indexes.join('----')].join('---'),
        ),
        `kbp-${result.length}`,
      ].join('-'),
      text: knowledgeBasePreview.knowledgeBase?.title ? '' : fallbackText,
      data: {
        knowledgeBasePreview: {
          pageNo: knowledgeBasePreview.pageNo,
          totalPageCount: knowledgeBasePreview.totalPageCount,
          draftjs: knowledgeBasePreview.draftjs,
          highlightIndexes: knowledgeBasePreview.highlightIndexes,
          highlights: knowledgeBasePreview.highlights
            ? omit(
                {
                  ...knowledgeBasePreview.highlights,
                  pageIndexes: knowledgeBasePreview.highlights.pageIndexes?.map(
                    pi => (pi ? omit(pi, '__typename') : pi),
                  ),
                },
                '__typename',
              )
            : knowledgeBasePreview.highlights,
          exactAnswer: knowledgeBasePreview.exactAnswer,
          knowledgeBase: knowledgeBasePreview.knowledgeBase,
        },
      },
    });
  }

  return result;
};

interface UseDocumentEntitiesOptions extends UseRichTextOptions {
  citationReferences?: readonly FragmentOf<
    typeof DocumentEntities_CitationReferenceFragment
  >[];
  knowledgeBasePreview?: FragmentOf<
    typeof DocumentEntities_KnowledgeBasePreviewFragment
  > | null;
  knowledgeBasePreviewKeyPrefix?: string;
}

export const useDocumentEntities = (options: UseDocumentEntitiesOptions) => {
  const {
    citationReferences,
    knowledgeBasePreview,
    knowledgeBasePreviewKeyPrefix,
  } = options;
  const intl = useIntl();
  const { value } = useRichText(options);

  const documentEntities = useMemo(
    () =>
      getDocumentEntities({
        contentState: value?.getCurrentContent(),
        citationReferences: readFragment(
          DocumentEntities_CitationReferenceFragment,
          citationReferences ?? [],
        ),
        knowledgeBasePreview: readFragment(
          DocumentEntities_KnowledgeBasePreviewFragment,
          knowledgeBasePreview,
        ),
        knowledgeBasePreviewKeyPrefix,
        fallbackText: intl.formatMessage(
          {
            id: 'preview',
            defaultMessage: '{count, plural, one {Preview} other {Previews}}',
            description: 'Document preview title missing fallback',
          },
          { count: 1 },
        ),
      }),
    [
      citationReferences,
      intl,
      knowledgeBasePreview,
      knowledgeBasePreviewKeyPrefix,
      value,
    ],
  );

  return {
    documentEntities,
  };
};
