import { Text } from '@allganize/ui-text';
import { css, useTheme } from '@emotion/react';
import { format } from 'date-fns';
import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { GroupedConversationListItem } from './use-grouped-conversation-list';

export const historyListGroupLabelHeight = 34;

interface HistoryListGroupLabelProps {
  className?: string;
  label: Extract<GroupedConversationListItem, { type: 'group-label' }>['label'];
  style?: React.CSSProperties;
}

export const HistoryListGroupLabel: FunctionComponent<
  HistoryListGroupLabelProps
> = props => {
  const { className, label: labelProp, style } = props;
  const theme = useTheme();
  const intl = useIntl();
  const label = useMemo(() => {
    if (labelProp === 'today') {
      return (
        <FormattedMessage
          id="today"
          defaultMessage="Today"
          description="Today"
        />
      );
    }

    if (labelProp === 'yesterday') {
      return (
        <FormattedMessage
          id="yesterday"
          defaultMessage="Yesterday"
          description="Yesterday"
        />
      );
    }

    if (labelProp.startsWith('date-')) {
      const date = Number.parseInt(labelProp.slice(5), 10);

      if (Number.isNaN(date)) {
        return null;
      }

      return intl.formatDate(date, { month: 'long', day: 'numeric' });
    }

    if (labelProp === 'last-month') {
      return (
        <FormattedMessage
          id="previous-n-days"
          defaultMessage="Previous {count, number} days"
          description="Previous n days"
          values={{ count: 30 }}
        />
      );
    }

    if (labelProp.startsWith('month-')) {
      const month = Number.parseInt(labelProp.slice(6), 10);

      if (Number.isNaN(month)) {
        return null;
      }

      const thisYear = new Date().getFullYear();
      return format(new Date(thisYear, month), 'MMMM');
    }

    if (labelProp.startsWith('year-')) {
      const year = Number.parseInt(labelProp.slice(5), 10);

      if (Number.isNaN(year)) {
        return null;
      }

      return <FormattedNumber value={year} />;
    }

    return null;
  }, [intl, labelProp]);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 8px;
        height: ${historyListGroupLabelHeight}px;
        background-color: ${theme.palette.unstable_background.default};
      `}
      className={className}
      style={style}
    >
      <Text
        variant="title12"
        css={css`
          color: ${theme.palette.foreground.secondary};
        `}
      >
        {label}
      </Text>
    </div>
  );
};
