import { Skeleton } from '@allganize/ui-skeleton';
import { css } from '@emotion/react';

export const PanelItemLoading = () => {
  return (
    <div
      css={css`
        padding: 16px 0;
      `}
    >
      <Skeleton width="50%" />
      <Skeleton
        width="100%"
        css={css`
          margin-top: 8px;
        `}
      />
    </div>
  );
};
