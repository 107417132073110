import { ErrorOption, FieldPath, FieldValues } from 'react-hook-form';

export interface FormFieldError<TFieldValues extends FieldValues>
  extends ErrorOption {
  name: FieldPath<TFieldValues> | `root.${string}` | 'root';
  options?: {
    shouldFocus: boolean;
  };
}

export class FormError<TFieldValues extends FieldValues> extends Error {
  constructor(public readonly fields: FormFieldError<TFieldValues>[]) {
    super();
  }
}
