import { OverridableComponent, OverrideProps } from '@allganize/types';
import { IconButton, IconButtonTypeMap } from '@allganize/ui-button';
import { IcArrowNavigateNext } from '@allganize/ui-icons';
import { Tooltip } from '@allganize/ui-tooltip';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Scalars } from '../gql';

type QnaGenerationCandidatesTypeMap<
  P = {},
  D extends React.ElementType = 'button',
> = IconButtonTypeMap<P & { nodeId: Scalars<'ID'> }, D>;

export type QnaGenerationCandidatesProps<
  D extends React.ElementType = IconButtonTypeMap['defaultComponent'],
  P = {},
> = OverrideProps<QnaGenerationCandidatesTypeMap<P, D>, D> & {
  component?: React.ElementType;
};

// @ts-expect-error overridable component
export const QnaGenerationCandidates: OverridableComponent<QnaGenerationCandidatesTypeMap> =
  forwardRef((props, ref) => {
    const { nodeId, ...other } = props;

    return (
      <Tooltip
        title={
          <FormattedMessage
            id="KnowledgeBaseV2.LLM.Content.ViewCandidates.Btn.text"
            defaultMessage="View Candidates"
            description="KnowledgeBaseV2 LLM content View Candidates Button text"
          />
        }
      >
        <IconButton shape="rounded" size="small" {...other} ref={ref}>
          <IcArrowNavigateNext />
        </IconButton>
      </Tooltip>
    );
  });
