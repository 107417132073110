import { ChatStackItem, ChatStackItemProps } from '@allganize/alli-chat-base';
import { carouselArrowClasses, carouselClasses } from '@allganize/ui-carousel';
import { css } from '@emotion/react';
import { FunctionComponent, useMemo } from 'react';
import {
  ChatOptionInfoForm,
  ChatOptionInfoFormProps,
  ChatOptionInfoFormValues,
  ChatOptionInfoOption,
  ChatOptionInfoOption_MediaFragment,
  ChatOptionInfoOption_OptionStyleFragment,
  ChatOptionInfoOptionListVariant,
} from '../chat-option-info-form';
import { FragmentOf, gql, readFragment, Scalars } from '../gql';

const horizontalPadding = 12;

const ChatOptionInfo_ChatOptionInfoFragment = gql(
  `
  fragment ChatOptionInfo_ChatOptionInfoFragment on ChatOptionInfo {
    shortenOption
    longOption
    url
    optionBoxTemplate
    reusable

    urlVariable {
      id
      displayText
    }

    media {
      ...ChatOptionInfoOption_MediaFragment
    }

    style {
      ...ChatOptionInfoOption_OptionStyleFragment
    }
  }
`,
  [
    ChatOptionInfoOption_MediaFragment,
    ChatOptionInfoOption_OptionStyleFragment,
  ],
);

export const ChatOptionInfo_ChatFragment = gql(
  `
  fragment ChatOptionInfo_ChatFragment on Chat {
    ...on BotChat {
      __typename
      id
      multipleOption

      faqCarousel {
        __typename
      }

      chatOptionInfos {
        ...ChatOptionInfo_ChatOptionInfoFragment
      }
    }

    ...on CarouselChat {
      __typename
      id
      multipleOption

      chatOptionInfos {
        ...ChatOptionInfo_ChatOptionInfoFragment
      }
    }
  }
`,
  [ChatOptionInfo_ChatOptionInfoFragment],
);

export interface ChatOptionInfoProps
  extends Pick<
      ChatOptionInfoFormProps,
      'disabled' | 'isOptionDisabled' | 'onSubmit'
    >,
    Pick<ChatStackItemProps, 'className' | 'disablePadding'> {
  align?: 'left' | 'right';
  chat: FragmentOf<typeof ChatOptionInfo_ChatFragment>;
  chatOptionAlign?: Scalars<'ChatOptionAlign'>;
}

export const ChatOptionInfo: FunctionComponent<ChatOptionInfoProps> = props => {
  const {
    align = 'right',
    chat: chatProp,
    chatOptionAlign,
    disabled,
    isOptionDisabled,
    onSubmit,
    ...other
  } = props;
  const chat = readFragment(ChatOptionInfo_ChatFragment, chatProp);
  const defaultValues = useMemo<Partial<ChatOptionInfoFormValues>>(() => {
    if (chat.__typename !== 'BotChat' && chat.__typename !== 'CarouselChat') {
      return {};
    }

    return {
      isMulti: chat.multipleOption ?? false,
    };
  }, [chat]);
  const options = useMemo(() => {
    if (chat.__typename !== 'BotChat' && chat.__typename !== 'CarouselChat') {
      return [];
    }

    if (!chat.chatOptionInfos?.length) {
      return [];
    }

    return chat.chatOptionInfos.reduce<ChatOptionInfoOption[]>(
      (acc, curr, i) => {
        const option = readFragment(
          ChatOptionInfo_ChatOptionInfoFragment,
          curr,
        );

        if (!option) {
          return acc;
        }

        return [
          ...acc,
          {
            optionBoxTemplate: option.optionBoxTemplate,
            reusable: option.reusable ?? false,
            value: i,
            text: option.shortenOption || option.longOption,
            url: option.url || option.urlVariable?.displayText || null,
            media: readFragment(
              ChatOptionInfoOption_MediaFragment,
              option.media,
            ),
            style: readFragment(
              ChatOptionInfoOption_OptionStyleFragment,
              option.style,
            ),
          },
        ];
      },
      [],
    );
  }, [chat]);
  const variant = useMemo<ChatOptionInfoOptionListVariant>(() => {
    if (chat.__typename !== 'BotChat' && chat.__typename !== 'CarouselChat') {
      return 'list';
    }

    if (chat.__typename === 'CarouselChat') {
      return 'carousel';
    }

    if (
      chatOptionAlign === gql.scalar('ChatOptionAlign', 'HORIZONTAL') &&
      !chat.chatOptionInfos?.some(
        info =>
          readFragment(ChatOptionInfo_ChatOptionInfoFragment, info)?.media,
      )
    ) {
      return 'list-wrap';
    }

    return 'list';
  }, [chat, chatOptionAlign]);

  if (chat.__typename !== 'BotChat' && chat.__typename !== 'CarouselChat') {
    return null;
  }

  if (chat.__typename === 'BotChat' && chat.faqCarousel?.length) {
    return null;
  }

  if (!options.length) {
    return null;
  }

  return (
    <ChatStackItem {...other}>
      <ChatOptionInfoForm
        css={css`
          .${carouselArrowClasses.root}.${carouselArrowClasses.prev} {
            right: ${horizontalPadding + 36}px;
          }

          .${carouselArrowClasses.root}.${carouselArrowClasses.next} {
            right: ${horizontalPadding}px;
          }

          .${carouselClasses.list} {
            padding-left: ${horizontalPadding - 4}px;
          }
        `}
        align={align}
        defaultValues={defaultValues}
        disabled={disabled}
        isOptionDisabled={isOptionDisabled}
        onSubmit={onSubmit}
        options={options}
        variant={variant}
      />
    </ChatStackItem>
  );
};
