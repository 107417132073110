import {
  DocumentsLayout as BaseDocumentsLayout,
  DocumentsLayoutContext,
} from '@allganize/alli-works-documents';
import { useEventCallback } from '@allganize/hooks';
import { css } from '@emotion/react';
import { FunctionComponent, useContext, useMemo } from 'react';
import { pdfjs } from 'react-pdf';
import { Outlet, useSearchParams } from 'react-router-dom';
import { environment } from '../../environments/environment';
import { GraphQLClientContext } from '../../graphql/graphql-client-context';
import { getRegion } from '../../utils/get-region';

pdfjs.GlobalWorkerOptions.workerSrc = environment.pdfjs.workerSrc;

export const DocumentsLayout: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const region = getRegion(searchParams);
  const graphqlClient = useContext(GraphQLClientContext);

  const getDownloadKnowledgeBaseNodeOptions = useEventCallback(async () => {
    return {
      origin:
        region === 'ja' || region === 'kr'
          ? environment.api.base[region]
          : environment.api.base.default,
      headers: await graphqlClient?.getHeaders(),
    };
  });

  const contextValue = useMemo(() => {
    return {
      documentPreviewThumbnailBase:
        region === 'ja' || region === 'kr'
          ? environment.api.documentPreviewThumbnail[region]
          : environment.api.documentPreviewThumbnail.default,
      getDownloadKnowledgeBaseNodeOptions,
      pdfjs: {
        cMapUrl: environment.pdfjs.cMapUrl,
        cMapPacked: true,
        standardFontDataUrl: environment.pdfjs.standardFontDataUrl,
      },
    };
  }, [getDownloadKnowledgeBaseNodeOptions, region]);

  return (
    <DocumentsLayoutContext.Provider value={contextValue}>
      <BaseDocumentsLayout
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        <Outlet />
      </BaseDocumentsLayout>
    </DocumentsLayoutContext.Provider>
  );
};
