import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  listItemButtonClasses,
  listItemIconClasses,
  listItemTextClasses,
} from '@allganize/ui-list';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

const MenuLink = styled(NavLink)`
  text-decoration: none;
  width: 100%;

  .${listItemButtonClasses.root} {
    padding-left: 8px;
    padding-right: 8px;
    border-radius: ${props => props.theme.radius.xs}px;

    &.${listItemButtonClasses.selected} {
      background-color: ${props =>
        props.theme.palette.unstable_background.white};
    }
  }

  .${listItemIconClasses.root} {
    margin-right: 4px;
  }

  .${listItemTextClasses.root} {
    padding: 0;
    color: ${props => props.theme.palette.foreground.default};
  }
`;

interface MenuListItemProps extends Omit<NavLinkProps, 'children'> {
  children?: ReactNode;
  icon?: ReactNode;
}

export const MenuListItem = ({
  icon,
  children,
  ...others
}: MenuListItemProps) => {
  return (
    <ListItem disablePadding>
      <MenuLink {...others}>
        {({ isActive }) => (
          <ListItemButton selected={isActive}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primaryTextProps={{ variant: 'label14' }}>
              {children}
            </ListItemText>
          </ListItemButton>
        )}
      </MenuLink>
    </ListItem>
  );
};
