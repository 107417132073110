import { EditorState } from '@allganize/draft-input';
import { getSelectedBlocks } from './get-selected-blocks';

export const selectionHasDefaultColor = (editorState: EditorState) => {
  const selection = editorState.getSelection();

  if (selection.isCollapsed()) {
    try {
      return editorState
        .getCurrentInlineStyle()
        .every(value => !value?.startsWith('color-'));
    } catch {
      return false;
    }
  }

  const selectedBlocks = getSelectedBlocks(
    editorState.getCurrentContent(),
    selection,
  );

  if (selectedBlocks.length === 0) {
    return true;
  }

  for (let i = 0; i < selectedBlocks.length; i += 1) {
    const block = selectedBlocks[i];
    let start = 0;
    let end = block.getLength();

    if (i === 0) {
      start = selection.getStartOffset();
    }

    if (i === selectedBlocks.length - 1) {
      end = selection.getEndOffset();
    }

    for (let j = start; j < end; j += 1) {
      const inlineStyle = block.getInlineStyleAt(j);

      if (inlineStyle.size === 0) {
        return true;
      }

      if (inlineStyle.every(value => !value?.startsWith('color-'))) {
        return true;
      }
    }
  }

  return false;
};
