import { Divider } from '@allganize/ui-divider';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';

export interface PanelItemGroupProps {
  children?: React.ReactNode;
}

export const PanelItemGroup: FunctionComponent<PanelItemGroupProps> = ({
  children,
}) => {
  return (
    <>
      {children}

      <Divider
        css={css`
          margin: 16px 0;
        `}
      />
    </>
  );
};
