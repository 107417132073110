import { Types } from '@allganize/alli-sdk-interfaces';
import { useEventCallback } from '@allganize/hooks';
import { useSubscription, useSuspenseQuery } from '@apollo/client/react';
import { uniqBy } from 'lodash-es';
import { useTransition } from 'react';
import {
  ConversationsQueryDocument,
  ConversationsQueryVariables,
} from '../graphql/queries/conversations-query';
import { ConversationsSubscriptionDocument } from '../graphql/subscriptions/conversations-subscription';

const variables: ConversationsQueryVariables = {
  order: Types.ConversationOrder.LAST_CHAT_TIME_DESC,
  first: 20,
};

export const useConversationList = (opts?: ConversationsQueryVariables) => {
  const result = useSuspenseQuery(ConversationsQueryDocument, {
    variables: {
      ...variables,
      ...opts,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data, fetchMore } = result;
  const pageInfo = data.conversations?.pageInfo;
  const endCursor = pageInfo?.endCursor;
  const hasNextPage = pageInfo?.hasNextPage;
  const [isPending, startTransition] = useTransition();

  useSubscription(ConversationsSubscriptionDocument, {
    variables: {
      ...variables,
      ...opts,
    },
  });

  const loadNextPage = useEventCallback(() => {
    if (!hasNextPage || !endCursor) {
      return;
    }

    startTransition(() => {
      fetchMore({
        variables: {
          after: endCursor,
        },
        updateQuery(prev, { fetchMoreResult }) {
          if (!fetchMoreResult.conversations?.edges) {
            return prev;
          }

          return {
            ...prev,
            conversations: prev.conversations
              ? {
                  ...prev.conversations,
                  edges: uniqBy(
                    [
                      ...prev.conversations.edges,
                      ...fetchMoreResult.conversations.edges,
                    ],
                    edge => edge?.node?.id,
                  ),
                  pageInfo: {
                    ...prev.conversations.pageInfo,
                    hasNextPage:
                      fetchMoreResult.conversations.pageInfo.hasNextPage,
                    endCursor: fetchMoreResult.conversations.pageInfo.endCursor,
                  },
                }
              : fetchMoreResult.conversations,
          };
        },
      });
    });
  });

  return {
    ...result,
    loadNextPage,
    isPending,
  };
};
