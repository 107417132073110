import { IconButton } from '@allganize/ui-button';
import {
  IcThumbsDown,
  IcThumbsDownFilled,
  IcThumbsUp,
  IcThumbsUpFilled,
} from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { CreateSlotsAndSlotProps } from '@mui/material/utils/types';
import { FunctionComponent, useState } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';
import {
  UserFeedbackImproverForm,
  UserFeedbackImproverFormProps,
  UserFeedbackImproverFormSlotProps,
  UserFeedbackImproverFormSlots,
  UserFeedbackImproverFormValues,
} from '../user-feedback-improver-form';

const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();

export const UserFeedback_BotChatFragment = gql(`
  fragment UserFeedback_BotChatFragment on BotChat {
    __typename
    id
    mrcAnswerType
    ratable
    rating
  }
`);

export const UserFeedback_ChatMRCInfoFragment = gql(`
  fragment UserFeedback_ChatMRCInfoFragment on ChatMRCInfo {
    __typename
    id
    mrcAnswerType
    ratable
    rating
  }
`);

export interface UserFeedbackSubmitValues {
  rating: boolean | null;
  mrcInfoId?: string;
}

export interface UserFeedbackSlots extends UserFeedbackImproverFormSlots {}

export interface UserFeedbackSlotProps
  extends UserFeedbackImproverFormSlotProps {}

export type UserFeedbackSlotsAndSlotProps = CreateSlotsAndSlotProps<
  UserFeedbackSlots,
  UserFeedbackSlotProps
>;

export interface UserFeedbackProps extends UserFeedbackSlotsAndSlotProps {
  /**
   * @default 'right'
   */
  align?: 'left' | 'right';
  className?: string;
  data:
    | FragmentOf<typeof UserFeedback_BotChatFragment>
    | FragmentOf<typeof UserFeedback_ChatMRCInfoFragment>;
  onUserFeedbackImproverSubmit?: UserFeedbackImproverFormProps['onSubmit'];
  onUserFeedbackSubmit?(values: UserFeedbackSubmitValues): void;
  readOnly?: boolean;
}

export const UserFeedback: FunctionComponent<UserFeedbackProps> = props => {
  const {
    align = 'right',
    className,
    data: dataProp,
    onUserFeedbackImproverSubmit,
    onUserFeedbackSubmit,
    readOnly,
    slotProps,
    slots,
  } = props;
  const data = readFragment(
    UserFeedback_ChatMRCInfoFragment,
    readFragment(UserFeedback_BotChatFragment, dataProp),
  );
  const [feedbackImproverOpen, setFeedbackImproverOpen] = useState(false);
  const rating =
    data.__typename === 'BotChat' || data.__typename === 'ChatMRCInfo'
      ? data.rating
      : null;
  const feedbackImproverEnabled =
    !readOnly &&
    (data.__typename === 'BotChat' || data.__typename === 'ChatMRCInfo') &&
    data.mrcAnswerType === gql.scalar('MRCAnswerType', 'GENERATED');

  const handleThumbsUpOnClick = () => {
    if (readOnly) {
      return;
    }

    const finalRating = !rating ? true : null;
    onUserFeedbackSubmit?.({
      rating: finalRating,
      mrcInfoId:
        data.__typename === 'ChatMRCInfo' ? data.id.toString() : undefined,
    });
  };

  const handleThumbsDownOnClick = () => {
    if (readOnly) {
      return;
    }

    const finalRating = rating === null || rating ? false : null;
    onUserFeedbackSubmit?.({
      rating: finalRating,
      mrcInfoId:
        data.__typename === 'ChatMRCInfo' ? data.id.toString() : undefined,
    });

    if (finalRating === false && feedbackImproverEnabled) {
      setFeedbackImproverOpen(true);
    }
  };

  const closeFeedbackImprover = () => {
    setFeedbackImproverOpen(false);
  };

  const handleFeedbackImproverSubmit = async (
    values: UserFeedbackImproverFormValues,
  ) => {
    return onUserFeedbackImproverSubmit?.(values);
  };

  if (data.__typename !== 'BotChat' && data.__typename !== 'ChatMRCInfo') {
    return null;
  }

  if (!data.ratable) {
    return null;
  }

  return (
    <div
      css={[
        css`
          display: flex;
          align-items: center;
          gap: 4px;
        `,
        align === 'right' &&
          css`
            justify-content: flex-end;
          `,
      ]}
      className={className}
      onClick={stopPropagation}
      onMouseDown={stopPropagation}
      onMouseMove={stopPropagation}
      onMouseUp={stopPropagation}
      onMouseEnter={stopPropagation}
      onMouseLeave={stopPropagation}
      onMouseOut={stopPropagation}
      onMouseOver={stopPropagation}
      onTouchCancel={stopPropagation}
      onTouchEnd={stopPropagation}
      onTouchMove={stopPropagation}
      onTouchStart={stopPropagation}
      onFocus={stopPropagation}
    >
      <IconButton
        size="small"
        color={rating ? 'primary' : 'default'}
        onClick={handleThumbsUpOnClick}
      >
        {rating ? (
          <IcThumbsUpFilled fontSize="small" />
        ) : (
          <IcThumbsUp fontSize="small" />
        )}
      </IconButton>

      <IconButton
        size="small"
        color={rating === false ? 'primary' : 'default'}
        onClick={handleThumbsDownOnClick}
      >
        {rating === false ? (
          <IcThumbsDownFilled fontSize="small" />
        ) : (
          <IcThumbsDown fontSize="small" />
        )}
      </IconButton>

      {feedbackImproverEnabled && (
        <UserFeedbackImproverForm
          open={feedbackImproverOpen}
          onClose={closeFeedbackImprover}
          onSubmit={handleFeedbackImproverSubmit}
          slotProps={slotProps}
          slots={slots}
        />
      )}
    </div>
  );
};
