import { useImageLoaded } from '@allganize/hooks';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useImperativeHandle } from 'react';
import { DocumentViewerPageState } from '../document-viewer-page-state';
import { DocumentViewerPageWrapper } from '../document-viewer-page-wrapper';
import { HighlightLayer } from '../highlight-layer';
import { PageProxy } from '../hooks/use-scale';
import { imagePageClasses } from './image-page-classes';
import { ImagePageProps } from './image-page-type-map';

export const ImagePage = forwardRef<HTMLDivElement, ImagePageProps>(
  (props, ref) => {
    const {
      classes,
      page,
      pageRef,
      scale = 1,
      style: styleProp,
      blendMode,
      ...other
    } = props;
    const theme = useTheme();
    const [loaded, imageAttrs] = useImageLoaded({ src: page.url });
    const size = imageAttrs ?? {
      width: page.width,
      height: page.height,
    };
    const style = typeof styleProp === 'function' ? styleProp(size) : styleProp;
    const pageWidth = (size.width ?? 0) * scale;
    const pageHeight = (size.height ?? 0) * scale;

    useImperativeHandle<PageProxy | null, PageProxy | null>(
      pageRef,
      () => ({
        getKey() {
          return page.key;
        },
        getSize() {
          if (
            typeof size.width === 'undefined' ||
            typeof size.height === 'undefined' ||
            loaded !== 'loaded' ||
            size.width === 0 ||
            size.height === 0
          ) {
            return null;
          }

          return {
            width: size.width,
            height: size.height,
          };
        },
      }),
      [loaded, page.key, size.height, size.width],
    );

    return (
      <DocumentViewerPageWrapper
        data-testid="image-page"
        {...other}
        ref={ref}
        className={clsx(imagePageClasses.root, classes?.root, other.className)}
        style={{
          width: size.width,
          height: size.height,
          ...style,
        }}
      >
        {loaded === 'loaded' && (
          <HighlightLayer
            highlights={page.answerHighlights?.highlights}
            width={pageWidth}
            height={pageHeight}
            blendMode={blendMode}
            css={css`
              position: absolute;
              z-index: 1;
            `}
          />
        )}
        <div
          data-testid="image-page__image"
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
          `}
          style={{
            width: loaded === 'loaded' ? undefined : size.width,
            height: loaded === 'loaded' ? undefined : size.height,
            transform: `translate(-50%, -50%) scale(${scale})`,
          }}
          className={clsx(
            imagePageClasses.image,
            {
              [imagePageClasses.imageError]: loaded === 'error',
              [imagePageClasses.imageLoaded]: loaded === 'loaded',
              [imagePageClasses.imageLoading]: !loaded,
            },
            classes?.image,
            {
              [classes?.imageError ?? '']: loaded === 'error',
              [classes?.imageLoaded ?? '']: loaded === 'loaded',
              [classes?.imageLoading ?? '']: !loaded,
            },
          )}
        >
          {loaded === 'loaded' && (
            <img
              css={css`
                flex-shrink: 0;
                display: block;
                width: auto;
                height: auto;
                max-width: none;
                object-fit: contain;
                user-select: none;
                word-break: break-all;
                background-color: ${theme.palette.unstable_background.white};
              `}
              src={page.url}
              alt={page.alt}
            />
          )}

          {loaded === 'error' && <DocumentViewerPageState type="error" />}

          {!loaded && <DocumentViewerPageState type="loading" />}
        </div>
      </DocumentViewerPageWrapper>
    );
  },
);
