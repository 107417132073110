import { createIllustration } from '../create-illustration';
import chatting from './chatting.png';
import chatting2x from './chatting@2x.png';
import chatting3x from './chatting@3x.png';

export const Chatting = createIllustration({
  src: chatting,
  srcSet: [chatting, chatting2x, chatting3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 64,
});
