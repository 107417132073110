import { createIllustration } from '../create-illustration';
import passwordError from './password-error.png';
import passwordError2x from './password-error@2x.png';
import passwordError3x from './password-error@3x.png';

export const PasswordError = createIllustration({
  src: passwordError,
  srcSet: [passwordError, passwordError2x, passwordError3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 64,
});
