import { Ref, RefCallback, useCallback } from 'react';
import { combineRefs } from './combine-refs';

export const useCombinedRef = <T>(...refs: Ref<T>[]): RefCallback<T> => {
  const combinedRef = useCallback<RefCallback<T>>(
    val => combineRefs(...refs)(val),
    [...refs],
  );

  return combinedRef;
};
