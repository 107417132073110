import {
  ChatCarouselItem,
  chatCarouselItemClasses,
} from '@allganize/alli-chat-base';
import { ChatMedia } from '@allganize/alli-chat-content';
import { draftImageClasses } from '@allganize/draft-image-plugin';
import { DraftLinkContext } from '@allganize/draft-link-plugin';
import { useId } from '@allganize/hooks';
import { IcArrowExpandMore } from '@allganize/ui-icons';
import {
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemCheckbox,
  ListItemSecondaryText,
  ListItemText,
  listItemTextClasses,
} from '@allganize/ui-list';
import { Radio } from '@allganize/ui-radio';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { cloneElement, FunctionComponent, useContext, useMemo } from 'react';
import { gql } from '../gql';
import {
  ChatOptionInfoOptionListItemClasses,
  chatOptionInfoOptionListItemClasses,
} from './chat-option-info-option-list-item.classes';
import { ChatOptionInfoOption } from './use-chat-option-info-form';

interface ChatOptionInfoOptionListItemProps
  extends Pick<ListItemButtonProps, 'onBlur' | 'onClick'> {
  classes?: Partial<ChatOptionInfoOptionListItemClasses>;
  className?: string;
  control?: React.ReactElement;
  checked?: boolean;
  disabled?: boolean;
  option: ChatOptionInfoOption;
  variant?: 'list' | 'carousel';
}

export const ChatOptionInfoOptionListItem: FunctionComponent<
  ChatOptionInfoOptionListItemProps
> = props => {
  const {
    classes,
    className,
    checked,
    control,
    disabled,
    onBlur,
    onClick,
    option,
    variant = 'list',
  } = props;
  const theme = useTheme();
  const reactId = useId();
  const labelId = `chat-option-info-option-list-item-${reactId}-${option.value}`;
  const { getLinkTarget } = useContext(DraftLinkContext);

  const media = useMemo(() => {
    if (!option.media) {
      return option.media;
    }

    return {
      ...option.media,
      isOptionType: true,
    };
  }, [option.media]);

  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    if (option.url) {
      const target = getLinkTarget(option.url);

      window.open(
        option.url,
        target,
        target === '_blank' ? 'noopener,noreferrer' : undefined,
      );
    }

    onClick?.(ev);
  };

  return (
    <ChatCarouselItem
      css={
        variant === 'carousel' &&
        css`
          height: 100%;
        `
      }
      className={clsx(
        chatOptionInfoOptionListItemClasses.root,
        chatOptionInfoOptionListItemClasses[variant],
        classes?.root,
        classes?.[variant],
        className,
      )}
      hasCarouselHeightBug={variant === 'carousel' ? undefined : false}
    >
      <ListItem
        css={[
          css`
            display: inline-flex;
            flex-direction: column;
            align-items: stretch;
            border-radius: ${theme.radius.md}px;
            border: 1px solid ${theme.palette.border.divider.default};
            background-color: ${theme.palette.unstable_background.white};
            overflow: hidden;
            width: auto;
            max-width: 480px;
          `,
          option.style?.bgColor &&
            css`
              background-color: ${option.style.bgColor};
            `,
          option.style?.lineColor &&
            css`
              border-color: ${option.style.lineColor};
            `,
          disabled &&
            css`
              border-color: ${theme.palette.border.disabled};
            `,
          variant === 'carousel' &&
            css`
              width: 292px;
              height: 100%;

              .${chatCarouselItemClasses.hasCarouselHeightBug} & {
                height: auto;
                flex-grow: 1;
              }
            `,
        ]}
        component="div"
        disablePadding
        className={clsx(
          chatOptionInfoOptionListItemClasses.listItem,
          classes?.listItem,
        )}
      >
        {media && (
          <ChatMedia
            css={css`
              .${draftImageClasses.root} {
                border: 0;
                border-radius: 0;
              }
            `}
            media={media}
          />
        )}

        <ListItemButton
          css={css`
            padding: 7px 15px;
            align-items: flex-start;
          `}
          role={undefined}
          onBlur={onBlur}
          onClick={handleClick}
          disabled={disabled}
        >
          <ListItemCheckbox
            css={[
              css`
                padding: 2px 0;
              `,
              !control &&
                css`
                  display: none;
                `,
            ]}
          >
            {control ? (
              cloneElement(control, {
                checked,
                edge: 'start',
                tabIndex: -1,
                inputProps: { 'aria-labelledby': labelId },
              })
            ) : (
              <Radio
                checked={checked}
                edge="start"
                tabIndex={-1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            )}
          </ListItemCheckbox>

          <ListItemText
            css={[
              css`
                word-break: break-word;
              `,
              !disabled &&
                option.style?.fontColor &&
                css`
                  color: ${option.style.fontColor};
                `,
              option.style?.italic &&
                css`
                  font-style: italic;
                `,
              option.style?.underline &&
                css`
                  text-decoration: underline;
                `,
              option.style?.bold &&
                css`
                  .${listItemTextClasses.primary} {
                    font-weight: ${theme.typography.fontWeightBold};
                  }
                `,
            ]}
            id={labelId}
            primary={option.text}
            primaryTextProps={{
              variant:
                option.optionBoxTemplate ===
                gql.scalar('OptionBoxTemplate', 'NEXT_PAGE')
                  ? 'title14'
                  : 'body14',
            }}
          />

          {option.optionBoxTemplate ===
            gql.scalar('OptionBoxTemplate', 'NEXT_PAGE') && (
            <ListItemSecondaryText
              css={css`
                margin-left: 4px;
                padding: 4px 0;
              `}
            >
              <IcArrowExpandMore />
            </ListItemSecondaryText>
          )}
        </ListItemButton>
      </ListItem>
    </ChatCarouselItem>
  );
};
