import { Types } from '@allganize/alli-sdk-interfaces';
import { defaultLocale, Locale, LOCALES } from './locales';

export const getBrowserLocale = () => {
  const navigatorLocales = (
    [
      window.navigator.language,
      ...window.navigator.languages,
      'userLanguage' in window.navigator &&
        typeof window.navigator.userLanguage === 'string' &&
        window.navigator.userLanguage,
      'browserLanguage' in window.navigator &&
        typeof window.navigator.browserLanguage === 'string' &&
        window.navigator.browserLanguage,
      'systemLanguage' in window.navigator &&
        typeof window.navigator.systemLanguage === 'string' &&
        window.navigator.systemLanguage,
    ].filter(Boolean) as string[]
  )
    .map(locale => locale.split('-')[0])
    .filter(locale => LOCALES.includes(locale as any)) as Locale[];

  return navigatorLocales[0] || defaultLocale;
};

export const localeToEnum: Record<Locale, Types.LocaleEnum> = {
  en: Types.LocaleEnum.EN,
  ko: Types.LocaleEnum.KO,
  ja: Types.LocaleEnum.JA,
  fr: Types.LocaleEnum.FR,
  es: Types.LocaleEnum.ES,
  th: Types.LocaleEnum.TH,
  'zh-cn': Types.LocaleEnum.ZH_CN,
  'zh-tw': Types.LocaleEnum.ZH_TW,
};

export const getBrowserLocaleEnum = () => localeToEnum[getBrowserLocale()];

export const enumToLocale: Record<Types.LocaleEnum, Locale> = {
  [Types.LocaleEnum.EN]: 'en',
  [Types.LocaleEnum.KO]: 'ko',
  [Types.LocaleEnum.JA]: 'ja',
  [Types.LocaleEnum.CN]: defaultLocale,
  [Types.LocaleEnum.DE]: defaultLocale,
  [Types.LocaleEnum.FR]: 'fr',
  [Types.LocaleEnum.ZH_CN]: 'zh-cn',
  [Types.LocaleEnum.ZH_TW]: 'zh-tw',
  [Types.LocaleEnum.ES]: 'es',
  [Types.LocaleEnum.TH]: 'th',
};
