import { CamelCase } from '@allganize/types';
import {
  getAvatarUtilityClass,
  avatarClasses as muiAvatarClasses,
  AvatarClassKey as MuiAvatarClassKey,
} from '@mui/material/Avatar';
import { AvatarSize } from './avatar-type-map';

export type AvatarClassKey =
  | MuiAvatarClassKey
  | CamelCase<`size_${AvatarSize}`>;
export type AvatarClasses = Record<AvatarClassKey, string>;

export const avatarClasses: AvatarClasses = {
  ...muiAvatarClasses,
  sizeXs: getAvatarUtilityClass('sizeXs'),
  sizeSm: getAvatarUtilityClass('sizeSm'),
  sizeMd: getAvatarUtilityClass('sizeMd'),
  sizeLg: getAvatarUtilityClass('sizeLg'),
  sizeXl: getAvatarUtilityClass('sizeXl'),
  size2xl: getAvatarUtilityClass('size2xl'),
};
