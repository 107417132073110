import { useProject } from '@allganize/alli-works-project';
import { useUploadFile } from '@allganize/knowledge-base-actions';
import {
  KnowledgeBaseBreadcrumbItem,
  KnowledgeBaseBreadcrumbs,
  useKnowledgeBaseBreadcrumbs,
} from '@allganize/knowledge-base-breadcrumbs';
import { Truncate } from '@allganize/truncate';
import { Button } from '@allganize/ui-button';
import { IcUpload } from '@allganize/ui-icons';
import { ListItemText } from '@allganize/ui-list';
import { MenuItem } from '@allganize/ui-menu';
import { Text } from '@allganize/ui-text';
import { useSuspenseQuery } from '@apollo/client/react';
import { css } from '@emotion/react';
import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useSearchParams } from 'react-router-dom';
import { gql, Scalars } from '../gql';
import { useDocumentsTab } from '../hooks/use-documents-tab';
import {
  DocumentsDetailActions,
  DocumentsDetailActions_KnowledgeBaseNodeFragment,
} from './documents-detail-actions';

export const DocumentsDetailPageHeaderQuery = gql(
  `
  query DocumentsDetailPageHeaderQuery($where: KnowledgeBaseNodeWhereUniqueInput!) {
    knowledgeBaseNode(where: $where) {
      id
      name
      ownership

      ancestors {
        id
        name
      }

      ...DocumentsDetailActions_KnowledgeBaseNodeFragment
    }
  }
`,
  [DocumentsDetailActions_KnowledgeBaseNodeFragment],
);

interface DocumentsDetailPageHeaderProps {
  nodeId: Scalars<'ID'>;
  onUpload?(): void;
}

export const DocumentsDetailPageHeader: FunctionComponent<
  DocumentsDetailPageHeaderProps
> = props => {
  const { nodeId, onUpload } = props;
  const project = useProject();
  const [searchParams] = useSearchParams();
  const { options } = useDocumentsTab();
  const { data } = useSuspenseQuery(DocumentsDetailPageHeaderQuery, {
    variables: { where: { id: nodeId, project: { id: project.id } } },
  });
  const node = data.knowledgeBaseNode;
  const { containerRef, maxItems } = useKnowledgeBaseBreadcrumbs();
  const uploadFile = useUploadFile(project.id);

  const ownershipOption = useMemo(
    () =>
      options.find(
        option =>
          option.ownership ===
          (node?.ownership ??
            gql.scalar('KnowledgeBaseNodeOwnership', 'SHARED')),
      ),
    [node?.ownership, options],
  );
  const ancestors = useMemo(
    () => [
      {
        __typename: 'KnowledgeBaseNodeFolder' as const,
        id: 'all-files',
        name: ownershipOption?.label ?? '',
      },
      ...(node?.ancestors ?? []),
    ],
    [node?.ancestors, ownershipOption?.label],
  );

  const handleUpload = () => {
    uploadFile({
      open: true,
      ownership:
        node?.ownership ?? gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL'),
      projectId: project.id,
      parentId: nodeId,
      onSubmit: onUpload,
    });
  };

  if (!node) {
    throw new Error('Node not found');
  }

  return (
    <div ref={containerRef}>
      <KnowledgeBaseBreadcrumbs
        ancestors={ancestors}
        maxItems={maxItems}
        renderAncestor={({ ancestor, context }) => {
          const newParams = new URLSearchParams(searchParams);
          newParams.delete('detail');

          if (context === 'menu') {
            return (
              <MenuItem
                key={ancestor.id}
                component={Link}
                to={{
                  pathname:
                    ancestor.id === 'all-files'
                      ? `/documents`
                      : `/documents/${ancestor.id}`,
                  search: newParams.toString(),
                }}
              >
                <ListItemText primary={ancestor.name} />
              </MenuItem>
            );
          }

          return (
            <KnowledgeBaseBreadcrumbItem
              key={ancestor.id}
              component={Link}
              to={{
                pathname:
                  ancestor.id === 'all-files'
                    ? `/documents`
                    : `/documents/${ancestor.id}`,
                search: newParams.toString(),
              }}
            >
              {ancestor.name}
            </KnowledgeBaseBreadcrumbItem>
          );
        }}
      />

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        <div
          css={css`
            flex-grow: 1;
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <Truncate
            css={css`
              word-break: break-all;
            `}
            clamp={1}
          >
            <Text variant="title20">{node.name}</Text>
          </Truncate>

          <DocumentsDetailActions node={node} />
        </div>

        {node.ownership ===
          gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL') && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            <Button
              startIcon={<IcUpload />}
              variant="outlined"
              size="small"
              onClick={handleUpload}
            >
              <FormattedMessage
                id="actions.upload"
                defaultMessage="Upload"
                description="upload action text"
              />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
