import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcCheckCircle } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';

export const IntermediateStepsSummary_BotChatFragment = gql(`
  fragment IntermediateStepsSummary_BotChatFragment on BotChat {
    id
    generatingState

    intermediateSteps {
      id
      completed
      statusMessage
    }
  }
`);

interface IntermediateStepsSummaryProps {
  chat: FragmentOf<typeof IntermediateStepsSummary_BotChatFragment>;
}

export const IntermediateStepsSummary: FunctionComponent<
  IntermediateStepsSummaryProps
> = props => {
  const { chat: chatProp } = props;
  const chat = readFragment(IntermediateStepsSummary_BotChatFragment, chatProp);

  if (!chat.intermediateSteps?.length) {
    return null;
  }

  const incompleteStepStatusMessage = chat.intermediateSteps.find(
    step => !step.completed,
  )?.statusMessage;

  if (incompleteStepStatusMessage) {
    return (
      <div
        css={css`
          display: inline-flex;
          align-items: flex-start;
          gap: 8px;
        `}
      >
        <CircularProgress size="sm" />

        <Text variant="body14">{incompleteStepStatusMessage}</Text>
      </div>
    );
  }

  if (chat.generatingState !== gql.scalar('SearchContextStep', 'COMPLETE')) {
    return (
      <div
        css={css`
          display: inline-flex;
          align-items: flex-start;
          gap: 8px;
        `}
      >
        <IcCheckCircle color="primary" />

        <Text variant="body14">
          {
            chat.intermediateSteps[chat.intermediateSteps.length - 1]
              ?.statusMessage
          }
        </Text>
      </div>
    );
  }

  return (
    <div
      css={css`
        display: inline-flex;
        align-items: flex-start;
        gap: 8px;
      `}
    >
      <Text variant="body14">
        <FormattedMessage
          id="intermediate-step.complete"
          defaultMessage="complete"
          description="intermediate step complete status text"
        />
      </Text>
    </div>
  );
};
