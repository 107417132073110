import { createIllustration } from '../create-illustration';
import calendar from './calendar.png';
import calendar2x from './calendar@2x.png';
import calendar3x from './calendar@3x.png';

export const Calendar = createIllustration({
  src: calendar,
  srcSet: [calendar, calendar2x, calendar3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 160,
});
