import { useEventCallback } from '@allganize/hooks';
import { toast } from '@allganize/ui-toast';
import { downloadFile as downloadFileUtil } from '@allganize/utils-file';
import { useFormatErrorMessage } from '@allganize/utils-graphql';
import { useMutation, useSubscription } from '@apollo/client/react';
import { compact } from 'lodash-es';
import { FunctionComponent, ReactNode, useContext } from 'react';

import { DownloadFileFormValues } from '../download-file-dialog/download-file-form-values';
import { gql } from '../gql';
import { AppContext } from './app-context';
import { DownloadFileContext } from './download-file-context';
import { OutputContext } from './output-context';

const SingleActionResultDownloadFilePublicMutation = gql(`
  mutation SingleActionResultDownloadFilePublicMutation(
    $where: ProjectWhereUniqueInput!
    $clientHashId: String
    $input: AsyncSingleActionResultDownloadFileInput!
    $accessToken: String!
    $token: String!
  ) {
    asyncSingleActionResultDownloadFilePublic(
      where: $where
      clientHashId: $clientHashId
      input: $input
      accessToken: $accessToken
      token: $token
    ) {
      taskId
      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`);

const SingleActionAppResultDownloadPublicSubscription = gql(`
  subscription SingleActionAppResultDownloadPublicSubscription(
    $where: ProjectWhereUniqueInput!
    $accessToken: String!
    $token: String!
  ) {
    singleActionAppResultDownloadPublic(
      projectWhere: $where
      accessToken: $accessToken
      token: $token
    ) {
      id
      status
      fileName
      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`);

interface DownloadFileProviderProps {
  url: string;
  clientHashId: string;
  children?: ReactNode;
}

export const DownloadFileProvider: FunctionComponent<
  DownloadFileProviderProps
> = ({ url, clientHashId, children }) => {
  const formatErrorMessage = useFormatErrorMessage();
  const { data: output } = useContext(OutputContext);
  const { data: app, mode, publicToken, accessToken } = useContext(AppContext);
  const projectId = app?.projectId || '';

  const [mutate, { data: mutateData, reset }] = useMutation(
    SingleActionResultDownloadFilePublicMutation,
    {
      onCompleted: res => {
        const error = compact(
          res.asyncSingleActionResultDownloadFilePublic?.errors,
        )[0];
        if (error) {
          toast.error(formatErrorMessage(error));
        }
      },
      onError: err => toast.error(formatErrorMessage(err)),
    },
  );

  const { data } = useSubscription(
    SingleActionAppResultDownloadPublicSubscription,
    {
      skip: mode === 'agent' || !projectId || !accessToken || !publicToken,
      variables: {
        where: { id: projectId },
        accessToken,
        token: publicToken,
      },
      onData: res => {
        const error = compact(
          res.data.data?.singleActionAppResultDownloadPublic?.errors,
        )[0];
        if (error) {
          toast.error(formatErrorMessage(error));
        }
      },
      onError: err => toast.error(formatErrorMessage(err)),
    },
  );

  const taskId = mutateData?.asyncSingleActionResultDownloadFilePublic?.taskId;
  const status =
    taskId === data?.singleActionAppResultDownloadPublic?.id
      ? data?.singleActionAppResultDownloadPublic?.status || null
      : null;

  const fileName = data?.singleActionAppResultDownloadPublic?.fileName || '';

  const download = useEventCallback((values: DownloadFileFormValues) => {
    mutate({
      variables: {
        where: { id: projectId },
        clientHashId,
        input: {
          fileName: values.fileName,
          fileType: values.fileType.value,
          singleActionResultId: output.id,
        },
        accessToken,
        token: publicToken,
      },
    });
  });

  const downloadFile = useEventCallback(async () => {
    if (taskId) {
      reset();
      await downloadFileUtil({
        url: `${url}?task_id=${taskId}`,
        download: fileName,
        headers: {
          accessToken,
          publicToken,
        },
      });
    }
  });

  return (
    <DownloadFileContext.Provider value={{ status, download, downloadFile }}>
      {children}
    </DownloadFileContext.Provider>
  );
};
