import {
  LocationButton,
  LocationButtonProps,
} from '@allganize/knowledge-base-side-panel';
import { FunctionComponent } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { gql } from '../gql';
import { useDocumentsTab } from '../hooks/use-documents-tab';

export const DocumentsRightPanelAccessControlList: FunctionComponent = () => {
  return null;
};

export const DocumentsRightPanelFileStatus: FunctionComponent = () => {
  return null;
};

export const DocumentsRightPanelHashtags: FunctionComponent = () => {
  return null;
};

export const DocumentsRightPanelQnaGeneration: FunctionComponent = () => {
  return null;
};

export const DocumentsRightPanelLocationButton: FunctionComponent<
  LocationButtonProps
> = props => {
  const { node } = props;
  const [searchParams] = useSearchParams();
  const { options } = useDocumentsTab();
  const ownership =
    node.parent?.ownership ??
    node.ownership ??
    gql.scalar('KnowledgeBaseNodeOwnership', 'SHARED');
  const ownershipOption = options.find(
    option => option.ownership === ownership,
  );
  const newParams = new URLSearchParams(searchParams);
  newParams.set('tab', ownershipOption?.value ?? 'my');
  newParams.delete('detail');

  return (
    <LocationButton
      component={Link}
      to={{
        pathname: ['/documents', node.parent?.id].filter(Boolean).join('/'),
        search: newParams.toString(),
      }}
      node={node}
      rootNodeName={ownershipOption?.label}
    />
  );
};
