import { gql, ResultOf, Scalars } from '../gql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsContactAgentDisabled_ChatFragment = gql(`
  fragment IsContactAgentDisabled_ChatFragment on Chat {
    ...on AgentChat {
      __typename
      id
      nextInputType
    }

    ...on BotChat {
      __typename
      id
      nextInputType
    }

    ...on CarouselChat {
      __typename
      id
      nextInputType
    }

    ...on ContactAgentByEmailChat {
      __typename
      id
      nextInputType
    }

    ...on SendFormChat {
      __typename
      id
      nextInputType
    }

    ...on UserChat {
      __typename
      id
      nextInputType
    }

    ...on EventChat {
      __typename
    }

    ...on FillSlotRichUXChat {
      __typename
    }

    ...on CustomUIChat {
      __typename
    }
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsContactAgentDisabled_ConversationFragment = gql(`
  fragment IsContactAgentDisabled_ConversationFragment on Conversation {
    state
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsContactAgentDisabled_LastChatFragment = gql(`
  fragment IsContactAgentDisabled_LastChatFragment on Chat {
    ...on AgentChat {
      id
    }

    ...on BotChat {
      id
    }

    ...on CarouselChat {
      id
    }

    ...on EventChat {
      id
    }

    ...on CustomUIChat {
      id
    }

    ...on FillSlotRichUXChat {
      id
    }

    ...on ContactAgentByEmailChat {
      id
    }

    ...on SendFormChat {
      id
    }

    ...on UserChat {
      id
    }
  }
`);

const conversationStates: Array<Scalars<'ConversationStateEnum'> | undefined> =
  [
    gql.scalar('ConversationStateEnum', 'END_BY_USER'),
    gql.scalar('ConversationStateEnum', 'END_OF_CHATBOT'),
    gql.scalar('ConversationStateEnum', 'END_BY_AGENT'),
    gql.scalar('ConversationStateEnum', 'END_OF_OBSERVER'),
    gql.scalar('ConversationStateEnum', 'INTERRUPTED'),
  ];

export interface IsContactAgentDisabledOptions {
  chat: ResultOf<typeof IsContactAgentDisabled_ChatFragment>;
  conversation?: ResultOf<
    typeof IsContactAgentDisabled_ConversationFragment
  > | null;
  lastChat?: ResultOf<typeof IsContactAgentDisabled_LastChatFragment>;
}

export const isContactAgentDisabled = ({
  chat,
  conversation,
  lastChat,
}: IsContactAgentDisabledOptions): boolean => {
  if (
    chat.__typename === 'EventChat' ||
    chat.__typename === 'CustomUIChat' ||
    chat.__typename === 'FillSlotRichUXChat'
  ) {
    return true;
  }

  if (
    !chat.nextInputType.includes(
      gql.scalar('ChatInputType', 'CONTACT_AGENT_BY_EMAIL_RICH_UX'),
    )
  ) {
    return true;
  }

  if (chat.id !== lastChat?.id) {
    return true;
  }

  return conversationStates.includes(conversation?.state);
};
