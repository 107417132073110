import { atom, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { gql, Scalars } from '../gql';

export interface UploadFileState {
  open: boolean;
  ownership: Scalars<'KnowledgeBaseNodeOwnership'>;
  parentId: Scalars<'ID'> | null;
  projectId: Scalars<'ID'>;
  onSubmit?(): void;
}

export const uploadFileAtom = atomFamily(
  (projectId: Scalars<'ID'>) =>
    atom<UploadFileState>({
      open: false,
      ownership: gql.scalar('KnowledgeBaseNodeOwnership', 'SHARED'),
      parentId: null,
      projectId,
    }),
  (a, b) => a === b,
);

export const useUploadFile = (projectId: Scalars<'ID'>) => {
  return useSetAtom(uploadFileAtom(projectId));
};
