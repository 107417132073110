import { DraftField } from '@allganize/draft-input';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export const MessageField: FunctionComponent = () => {
  const { control } = useFormContext();
  const {
    field: { ref: fieldRef, ...field },
    fieldState,
  } = useController({
    control,
    name: 'message',
  });

  return (
    <DraftField
      {...field}
      inputRef={fieldRef}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      fullWidth
      required
      label={
        <FormattedMessage
          id="message"
          defaultMessage="{count, plural, one {Message} other {Messages}}"
          description="contact agent by email form message field label"
          values={{ count: 1 }}
        />
      }
    />
  );
};
