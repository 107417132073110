import { useEventCallback, useUnmount } from '@allganize/hooks';
import { toast } from '@allganize/ui-toast';
import { downloadFile } from '@allganize/utils-file';
import { FormattedErrorMessage } from '@allganize/utils-graphql';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { gql, ResultOf, Scalars } from '../gql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DownloadKnowledgeBaseNodes_KnowledgeBaseNodeFragment = gql(`
  fragment DownloadKnowledgeBaseNodes_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    name
  }
`);

export interface DownloadKnowledgeBaseNodesOptions {
  headers?: HeadersInit;
  nodes: ResultOf<
    typeof DownloadKnowledgeBaseNodes_KnowledgeBaseNodeFragment
  >[];
  origin: string;
  projectId: Scalars<'ID'>;
}

export const useDownloadKnowledgeBaseNodes = () => {
  const downloadToastKeyRef = useRef<string | number | null>(null);

  const closeDownloadSnackbar = useEventCallback(() => {
    if (downloadToastKeyRef.current !== null) {
      toast.dismiss(downloadToastKeyRef.current);
      downloadToastKeyRef.current = null;
    }
  });

  useUnmount(() => {
    closeDownloadSnackbar();
  });

  const downloadKnowledgeBaseNodes = async ({
    headers,
    nodes,
    origin,
    projectId,
  }: DownloadKnowledgeBaseNodesOptions) => {
    closeDownloadSnackbar();

    if (nodes.length === 0) {
      toast.error(<FormattedErrorMessage />);
      return;
    }

    downloadToastKeyRef.current = toast(
      <FormattedMessage
        id="FAQ.Documents.Download"
        defaultMessage="Downloading {fileName}..."
        description="FAQ document download in progress message"
        values={{
          fileName:
            nodes.length === 1 ? (
              nodes[0]?.name
            ) : (
              <FormattedMessage
                id="items"
                defaultMessage="{count, plural, one {# item} other {# items}}"
                description="Pluralized item count"
                values={{ count: nodes.length }}
              />
            ),
        }}
      />,
      { autoClose: false },
    );

    const downloadPromises = nodes.map(node => {
      const url = `${origin}/download_knowledge_base/${projectId}/${node.id}`;

      return downloadFile({
        url,
        download: node.name,
        headers,
      });
    });

    try {
      await Promise.all(downloadPromises);
    } catch (err) {
      toast.error(<FormattedErrorMessage error={err as any} />);
    } finally {
      closeDownloadSnackbar();
    }
  };

  return downloadKnowledgeBaseNodes;
};
