import { PlainText } from '@allganize/alli-chat-content';
import { DraftField, EditorState } from '@allganize/draft-input';
import { FormControl, FormLabel } from '@allganize/ui-form';
import { Text } from '@allganize/ui-text';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormValueFormValues } from './use-form-value-form';

interface TextFormValueFieldProps {
  disabled?: boolean;
  name: `formValues.${number}`;
}

export const TextFormValueField: FunctionComponent<
  TextFormValueFieldProps
> = props => {
  const { disabled: disabledProp, name } = props;
  const { control } = useFormContext<FormValueFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name,
    disabled: disabledProp,
  });
  const {
    field: { ref: valueRef, ...value },
    fieldState: valueState,
  } = useController({ control, name: `${name}.value`, disabled: disabledProp });
  const disabled = field.disabled || formState.disabled;

  if (field.value.type !== 'text') {
    return null;
  }

  if (disabled) {
    return (
      <FormControl fullWidth required={field.value.required}>
        <FormLabel>{field.value.label}</FormLabel>

        <Text variant="body14">
          <PlainText
            value={
              field.value.value?.getCurrentContent().getPlainText('\n') || '-'
            }
          />
        </Text>
      </FormControl>
    );
  }

  return (
    <DraftField
      {...value}
      value={value.value as EditorState}
      inputRef={valueRef}
      label={field.value.label}
      required={field.value.required}
      error={fieldState.invalid || valueState.invalid}
      helperText={fieldState.error?.message || valueState.error?.message}
      fullWidth
    />
  );
};
