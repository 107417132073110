import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { chatStackItemClasses } from './chat-stack-item.classes';
import { ChatStackItemProps } from './chat-stack-item.types';

export const ChatStackItem = forwardRef<HTMLDivElement, ChatStackItemProps>(
  (props, ref) => {
    const {
      children,
      classes,
      className,
      disablePadding = false,
      ...other
    } = props;

    return (
      <div
        css={css`
          max-width: 744px;
          margin-left: auto;
          margin-right: auto;
        `}
        {...other}
        ref={ref}
        className={clsx(
          chatStackItemClasses.root,
          { [chatStackItemClasses.padding]: !disablePadding },
          classes?.root,
          { [classes?.padding ?? '']: !disablePadding },
          className,
        )}
      >
        <div
          css={
            !disablePadding &&
            css`
              padding: 0 12px;
            `
          }
          className={clsx(chatStackItemClasses.content, classes?.content)}
        >
          {children}
        </div>
      </div>
    );
  },
);
