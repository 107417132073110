import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcArrowHideSide } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';

export const SidePanelCloseButton: FunctionComponent<
  IconButtonProps
> = props => {
  return (
    <IconButton
      css={css`
        margin: 4px;
      `}
      {...props}
    >
      <IcArrowHideSide />
    </IconButton>
  );
};
