import { IconButton } from '@allganize/ui-button';
import {
  IcCheckCircle,
  IcClose,
  IcError,
  IcInfo,
  IcWarning,
} from '@allganize/ui-icons';
import { Paper } from '@allganize/ui-paper';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { darken, lighten } from '@mui/material/styles';
import useSlot from '@mui/material/utils/useSlot';
import clsx from 'clsx';
import { camelCase } from 'lodash-es';
import { forwardRef } from 'react';
import { alertClasses } from './alert-classes';
import { AlertAction, AlertIcon, AlertMessage } from './alert-slots';
import { AlertOwnerState, AlertProps } from './alert-type-map';

const defaultIconMapping = {
  success: <IcCheckCircle fontSize="inherit" />,
  warning: <IcWarning fontSize="inherit" />,
  error: <IcError fontSize="inherit" />,
  info: <IcInfo fontSize="inherit" />,
};

export const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  const {
    action,
    children,
    classes,
    className,
    closeText = 'Close',
    color,
    icon,
    iconMapping = defaultIconMapping,
    onClose,
    role = 'alert',
    severity = 'success',
    slotProps = {},
    slots = {},
    variant = 'standard',
    ...other
  } = props;
  const theme = useTheme();
  const colorSeverity = color || severity;

  const ownerState: AlertOwnerState = {
    ...props,
    color,
    severity,
    variant,
  };

  const externalForwardedProps = {
    slots,
    slotProps,
  };

  const [CloseButtonSlot, closeButtonProps] = useSlot('closeButton', {
    elementType: IconButton,
    // @ts-expect-error internal prop
    externalForwardedProps,
    ownerState,
    className: undefined,
  });

  const [CloseIconSlot, closeIconProps] = useSlot('closeIcon', {
    elementType: IcClose,
    // @ts-expect-error internal prop
    externalForwardedProps,
    ownerState,
    className: undefined,
  });

  const getColor = theme.palette.mode === 'light' ? darken : lighten;
  const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;

  return (
    <Paper
      data-testid="alert"
      css={[
        css`
          ${theme.typography.body12}
          background-color: transparent;
          display: flex;
          padding: 6px 16px;
        `,
        variant === 'standard' &&
          css`
            color: ${getColor(theme.palette[colorSeverity].light, 0.6)};
            background-color: ${getBackgroundColor(
              theme.palette[colorSeverity].light,
              0.9,
            )};

            .${alertClasses.icon} {
              color: ${theme.palette[colorSeverity].main};
            }
          `,
        variant === 'outlined' &&
          css`
            color: ${getColor(theme.palette[colorSeverity].light, 0.6)};
            border: 1px solid ${theme.palette[colorSeverity].light};

            .${alertClasses.icon} {
              color: ${theme.palette[colorSeverity].main};
            }
          `,
        variant === 'filled' &&
          css`
            font-weight: ${theme.typography.fontWeightMedium};
            background-color: ${theme.palette.mode === 'dark'
              ? theme.palette[colorSeverity].dark
              : theme.palette[colorSeverity].main};
            color: ${theme.palette[colorSeverity].contrastText};
          `,
      ]}
      role={role}
      elevation={0}
      {...other}
      ref={ref}
      className={clsx(
        alertClasses.root,
        alertClasses[
          camelCase(`color_${colorSeverity}`) as keyof typeof alertClasses
        ],
        alertClasses[variant],
        alertClasses[
          camelCase(`${variant}_${colorSeverity}`) as keyof typeof alertClasses
        ],
        classes?.root,
        classes?.[variant],
        classes?.[camelCase(`color_${colorSeverity}`) as keyof typeof classes],
        classes?.[
          camelCase(`${variant}_${colorSeverity}`) as keyof typeof classes
        ],
        className,
      )}
    >
      {icon !== false ? (
        <AlertIcon className={clsx(alertClasses.icon, classes?.icon)}>
          {icon || iconMapping[severity] || defaultIconMapping[severity]}
        </AlertIcon>
      ) : null}

      <AlertMessage className={clsx(alertClasses.message, classes?.message)}>
        {children}
      </AlertMessage>

      {action != null ? (
        <AlertAction className={clsx(alertClasses.action, classes?.action)}>
          {action}
        </AlertAction>
      ) : null}

      {action == null && onClose ? (
        <AlertAction className={clsx(alertClasses.action, classes?.action)}>
          <CloseButtonSlot
            size="small"
            aria-label={closeText}
            title={closeText}
            onClick={onClose}
            {...closeButtonProps}
          >
            <CloseIconSlot fontSize="small" {...closeIconProps} />
          </CloseButtonSlot>
        </AlertAction>
      ) : null}
    </Paper>
  );
});
