import { useConfirm } from '@allganize/confirm';
import { Button } from '@allganize/ui-button';
import { toast } from '@allganize/ui-toast';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { useMutation } from '@apollo/client/react';
import { compact } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { gql, Scalars } from '../gql';

const DeleteKnowledgeBaseNodesMutation = gql(`
  mutation DeleteKnowledgeBaseNodesMutation($filter: KnowledgeBaseNodeFilter!, $project: ProjectWhereUniqueInput!) {
    deleteKnowledgeBaseNodes(filter: $filter, project: $project) {
      ok

      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`);

interface DeleteKnowledgeBaseNodesOptions {
  projectId: Scalars<'ID'>;
  nodeIds: Scalars<'ID'>[];
}

export const useDeleteKnowledgeBaseNodes = () => {
  const confirm = useConfirm();
  const formatErrorMessage = useFormatErrorMessage();
  const [mutate] = useMutation(DeleteKnowledgeBaseNodesMutation);

  const confirmDelete = (nodeIds: Scalars<'ID'>[]) => {
    confirm.cancel();

    return new Promise<boolean>(resolve => {
      confirm.confirm({
        onClose: () => {
          resolve(false);
        },
        icon: null,
        title: (
          <FormattedMessage
            id="delete-items"
            defaultMessage="Delete items"
            description="Delete items"
          />
        ),
        actions: (
          <>
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                confirm.cancel();
                resolve(false);
              }}
            >
              <FormattedMessage
                id="actions.cancel"
                defaultMessage="Cancel"
                description="Cancel action text"
              />
            </Button>

            <Button
              color="primary"
              variant="filled"
              size="large"
              onClick={() => {
                resolve(true);
                confirm.cancel();
              }}
            >
              <FormattedMessage
                id="actions.delete"
                defaultMessage="Delete"
                description="Delete action text"
              />
            </Button>
          </>
        ),
        content: (
          <FormattedMessage
            id="delete-items.description"
            defaultMessage="Are you sure you want to delete {count, plural, one {# item} other {# items}}?"
            description="Delete items description text"
            values={{ count: nodeIds.length }}
          />
        ),
      });
    });
  };

  const deleteKnowledgeBaseNodes = async ({
    nodeIds,
    projectId,
  }: DeleteKnowledgeBaseNodesOptions) => {
    try {
      if (!(await confirmDelete(nodeIds))) {
        return { success: false };
      }

      const result = await mutate({
        variables: {
          project: { id: projectId },
          filter: { id: nodeIds },
        },
      });

      if (result.data?.deleteKnowledgeBaseNodes?.ok) {
        toast(
          <FormattedMessage
            id="delete-items.success"
            defaultMessage="{count, plural, one {# item} other {# items}} deleted"
            description="delete items success"
            values={{ count: nodeIds.length }}
          />,
        );

        return { success: true };
      }

      if (result.data?.deleteKnowledgeBaseNodes?.errors?.length) {
        throw new AlliGraphQLError(result.data.deleteKnowledgeBaseNodes.errors);
      }

      throw new Error(formatErrorMessage());
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const error = compact(err.errors)[0];

        if (error) {
          toast.error(formatErrorMessage(error));
          return { success: false };
        }
      }

      if (err instanceof Error) {
        toast.error(formatErrorMessage(err));
        return { success: false };
      }

      if (typeof err === 'string') {
        toast.error(err);
        return { success: false };
      }

      toast.error(formatErrorMessage());
    }

    return { success: false };
  };

  return deleteKnowledgeBaseNodes;
};
