import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { alpha } from '@mui/material/styles';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { alliChatRowClasses } from './alli-chat-row.classes';
import { AlliChatRowProps } from './alli-chat-row.types';

export const AlliChatRow = forwardRef<HTMLDivElement, AlliChatRowProps>(
  (props, ref) => {
    const { classes, className, ...other } = props;
    const theme = useTheme();

    return (
      <div
        data-testid="alli-chat-row"
        css={css`
          padding: 0 11px;
          border: 1px solid
            ${alpha(theme.palette.unstable_background.white, 0.56)};
          border-radius: ${theme.radius.md}px;
          background: linear-gradient(
            180deg,
            ${alpha(theme.palette.unstable_background.white, 0.48)} 0%,
            ${alpha(theme.palette.unstable_background.white, 0.32)} 100%
          );
        `}
        {...other}
        ref={ref}
        className={clsx(alliChatRowClasses.root, classes?.root, className)}
      />
    );
  },
);
