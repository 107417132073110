import { createIllustration } from '../create-illustration';
import empty from './empty.png';
import empty2x from './empty@2x.png';
import empty3x from './empty@3x.png';

export const Empty = createIllustration({
  src: empty,
  srcSet: [empty, empty2x, empty3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 160,
});
