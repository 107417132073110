import { useField } from '../internals/hooks/use-field';
import { useDefaultDates, useUtils } from '../internals/hooks/use-utils';
import { applyDefaultDate } from '../internals/utils/date-utils';
import { splitFieldInternalAndForwardedProps } from '../internals/utils/fields';
import { validateDateTime } from '../internals/utils/validation/validate-date-time';
import {
  singleItemFieldValueManager,
  singleItemValueManager,
} from '../internals/utils/value-managers';
import {
  UseDateTimeFieldDefaultizedProps,
  UseDateTimeFieldParams,
  UseDateTimeFieldProps,
} from './date-time-field-type-map';

const useDefaultizedDateTimeField = <AdditionalProps extends {}>(
  props: UseDateTimeFieldProps<Date>,
): AdditionalProps & UseDateTimeFieldDefaultizedProps<Date> => {
  const utils = useUtils<Date>();
  const defaultDates = useDefaultDates<Date>();

  const ampm = props.ampm ?? utils.is12HourCycleInCurrentLocale();
  const defaultFormat = ampm
    ? utils.formats.keyboardDateTime12h
    : utils.formats.keyboardDateTime24h;

  return {
    ...props,
    disablePast: props.disablePast ?? false,
    disableFuture: props.disableFuture ?? false,
    format: props.format ?? defaultFormat,
    disableIgnoringDatePartForTimeValidation: Boolean(
      props.minDateTime || props.maxDateTime,
    ),
    minDate: applyDefaultDate(
      utils,
      props.minDateTime ?? props.minDate,
      defaultDates.minDate,
    ),
    maxDate: applyDefaultDate(
      utils,
      props.maxDateTime ?? props.maxDate,
      defaultDates.maxDate,
    ),
    minTime: props.minDateTime ?? props.minTime,
    maxTime: props.maxDateTime ?? props.maxTime,
  } as any;
};

export const useDateTimeField = <TChildProps extends {}>({
  props: inProps,
  inputRef,
}: UseDateTimeFieldParams<Date, TChildProps>) => {
  const props = useDefaultizedDateTimeField<TChildProps>(inProps);

  const { forwardedProps, internalProps } = splitFieldInternalAndForwardedProps<
    typeof props,
    keyof UseDateTimeFieldProps<any>
  >(props, 'date-time');

  return useField({
    inputRef,
    forwardedProps,
    internalProps,
    valueManager: singleItemValueManager,
    fieldValueManager: singleItemFieldValueManager,
    validator: validateDateTime,
    valueType: 'date-time',
  });
};
