import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';

const getWindowSize = () => {
  return {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  };
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};

export const usePreview = () => {
  const theme = useTheme();
  const windowSize = useWindowSize();

  const previewSidebarWidth =
    windowSize.width -
    theme.messenger.width -
    theme.messenger.horizontalPadding * 2;
  const messengerHeightWhenPreviewOpen =
    windowSize.height -
    theme.messenger.bottomOffset -
    theme.messenger.verticalPadding;
  const messengerWidthWhenPreviewOpen =
    windowSize.width - theme.messenger.horizontalPadding * 2;
  const previewSidebarAnchor: 'left' | 'right' =
    theme.alignment === 'left' ? 'right' : 'left';
  const shouldOpenPreviewInDialog =
    previewSidebarWidth < theme.sidebar.minWidth;

  return {
    previewSidebarAnchor,
    previewSidebarWidth,
    messengerWidthWhenPreviewOpen,
    messengerHeightWhenPreviewOpen,
    shouldOpenPreviewInDialog,
  };
};
