import { DraftDocumentPlugin } from '@allganize/draft-document-plugin';
import { DraftFilePlugin } from '@allganize/draft-file-plugin';
import { DraftImagePlugin } from '@allganize/draft-image-plugin';
import {
  DraftAutoLinkPlugin,
  DraftLinkPlugin,
} from '@allganize/draft-link-plugin';
import {
  DraftTextColorPlugin,
  DraftTextSizePlugin,
  DraftTextStylePlugin,
} from '@allganize/draft-text-format-plugin';
import { DraftVideoPlugin } from '@allganize/draft-video-plugin';

export const defaultPlugins = [
  new DraftTextStylePlugin(),
  new DraftTextColorPlugin(),
  new DraftTextSizePlugin(),
  new DraftDocumentPlugin(),
  new DraftLinkPlugin(),
  new DraftAutoLinkPlugin(),
  new DraftImagePlugin({ preserveRatio: true }),
  new DraftVideoPlugin(),
  new DraftFilePlugin(),
];
