import { useTheme } from '@allganize/ui-theme';
import { Skeleton } from '@allganize/ui-skeleton';
import { css } from '@emotion/react';

export const LLMAppCardSkeleton = (
  props: React.HTMLAttributes<HTMLDivElement>,
) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        height: 136px;
        padding: 16px;
        text-align: left;
        border-radius: ${theme.radius.sm}px;
        border: 1px solid ${theme.palette.border.divider.default};
        background-color: ${theme.palette.unstable_background.white};
      `}
      {...props}
    >
      <Skeleton height="1.25rem" width="1.25rem" variant="circular" />
      <Skeleton height="1.25rem" width="12rem" />
      <Skeleton height="3rem" width="12rem" />
      <Skeleton height="1.25rem" width="4rem" />
    </div>
  );
};
