import { Checkbox } from '@allganize/ui-checkbox';
import { FormControl, FormGroup, FormHelperText } from '@allganize/ui-form';
import { Text } from '@allganize/ui-text';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  ChatOptionInfoOptionList,
  ChatOptionInfoOptionListProps,
} from './chat-option-info-option-list';
import { ChatOptionInfoOptionListItem } from './chat-option-info-option-list-item';
import {
  ChatOptionInfoFormValues,
  ChatOptionInfoOption,
} from './use-chat-option-info-form';

export interface MultipleFieldProps
  extends Pick<ChatOptionInfoOptionListProps, 'variant'> {
  align?: 'left' | 'right';
  isOptionDisabled?(option: ChatOptionInfoOption): boolean;
  options: ChatOptionInfoOption[];
}

export const MultipleField: FunctionComponent<MultipleFieldProps> = props => {
  const { align = 'right', isOptionDisabled, options, variant } = props;
  const { control } = useFormContext<ChatOptionInfoFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name: 'multiple',
  });
  const fieldDisabled = field.disabled || formState.disabled;

  return (
    <FormControl
      component="fieldset"
      fullWidth
      required
      error={fieldState.invalid}
      disabled={fieldDisabled}
    >
      <FormGroup role="group" onBlur={field.onBlur}>
        <ChatOptionInfoOptionList align={align} variant={variant}>
          {options.map(option => {
            const checked = field.value.some(opt => opt.value === option.value);

            // form gets submitted on click for single choice, so we have to disable
            const disabled = fieldDisabled || isOptionDisabled?.(option);

            return (
              <ChatOptionInfoOptionListItem
                key={option.value}
                checked={checked}
                control={<Checkbox />}
                disabled={disabled}
                onBlur={field.onBlur}
                onClick={() => {
                  field.onChange(
                    checked
                      ? field.value.filter(opt => opt.value !== option.value)
                      : [...field.value, option],
                  );
                }}
                option={option}
                variant={variant === 'carousel' ? 'carousel' : 'list'}
              />
            );
          })}
        </ChatOptionInfoOptionList>
      </FormGroup>

      {fieldState.error?.message && (
        <FormHelperText error>
          <Text variant="inherit" align={align}>
            {fieldState.error.message}
          </Text>
        </FormHelperText>
      )}
    </FormControl>
  );
};
