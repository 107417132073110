import {
  LLMAppList,
  LLMAppListLoadingState,
  useLLMAppListColumns,
} from '@allganize/llm-app-list';
import { useTheme } from '@allganize/ui-theme';
import { toast } from '@allganize/ui-toast';
import { SearchField, searchInputClasses } from '@allganize/ui-search';
import { css } from '@emotion/react';
import { Suspense, useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SidePanelContext } from './side-panel-context';
import { gql } from '../gql';

type AppListSearchFormValues = {
  query: string;
};

export const AppListPanel = () => {
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const { openAppPreviewTab } = useContext(SidePanelContext);
  const { containerRef, columns } = useLLMAppListColumns();

  const [searchTerm, setSearchTerm] = useState('');

  const { control, handleSubmit, watch } = useForm<AppListSearchFormValues>({
    defaultValues: {
      query: searchTerm,
    },
    mode: 'onSubmit',
  });

  const query = watch('query');

  useEffect(() => {
    if (!query) {
      setSearchTerm(query);
    }
  }, [query]);

  return (
    <div
      ref={containerRef}
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 16px 16px 16px;
        overflow-y: auto;
        background-color: ${theme.palette.unstable_background.default};
      `}
    >
      <form
        css={css`
          position: sticky;
          top: 0;
          z-index: 1;
          padding: 20px 0 16px 0;
          background-color: ${theme.palette.unstable_background.default};
        `}
        onSubmit={handleSubmit(values => setSearchTerm(values.query))}
      >
        <Controller
          control={control}
          name="query"
          render={({ field: { ref: fieldRef, ...field } }) => {
            return (
              <SearchField
                {...field}
                inputRef={fieldRef}
                label={null}
                fullWidth
                css={css`
                  .${searchInputClasses.root} {
                    background-color: ${theme.palette.unstable_background
                      .white};
                  }
                `}
              />
            );
          }}
        />
      </form>
      <ErrorBoundary fallback={null}>
        <Suspense fallback={<LLMAppListLoadingState columns={columns} />}>
          <LLMAppList
            columns={columns}
            filter={{ searchTerm }}
            onClickLLMApp={app => {
              if (app?.type === gql.scalar('LLMAppType', 'SINGLE_ACTION')) {
                if (
                  app.singleActionApp?.id &&
                  app.singleActionApp?.publicToken
                ) {
                  openAppPreviewTab({
                    singleActionApp: {
                      id: app.singleActionApp.id,
                      publicToken: app.singleActionApp.publicToken,
                    },
                  });
                } else {
                  toast.error(
                    intl.formatMessage({
                      id: 'MallyError.INVALID_PUBLIC_TOKEN',
                      defaultMessage:
                        'This URL has expired. Please use the newly generated URL.',
                      description: 'MallyError.INVALID_PUBLIC_TOKEN',
                    }),
                  );
                }
                return;
              }

              if (app?.type === gql.scalar('LLMAppType', 'CAMPAIGN')) {
                const testUrl =
                  (app?.type === gql.scalar('LLMAppType', 'CAMPAIGN') &&
                    app.campaign?.popupUrl) ||
                  undefined;

                if (testUrl) {
                  const popupUrl = new URL(testUrl);
                  navigate({
                    pathname: '/conversations/new',
                    search: `?campaignToken=${encodeURIComponent(
                      popupUrl.searchParams.get('campaign_token') || '',
                    )}&startOver=true`,
                  });
                } else {
                  toast.error(
                    intl.formatMessage({
                      id: 'MallyError.UNKNOWN',
                      defaultMessage:
                        'Something went wrong. Please try again later.',
                      description: 'MallyError.UNKNOWN',
                    }),
                  );
                }
              }
            }}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
