import { PickersModalDialogRoot } from '@allganize/ui-date-picker';
import { Dialog } from '@allganize/ui-dialog';
import { CacheProvider } from '@emotion/react';
import { forwardRef, useContext } from 'react';
import { ModalContext } from './modal-context';

export const DatePickerDialog: typeof Dialog = forwardRef((props, ref) => {
  const { emotionCache, container } = useContext(ModalContext);
  const dialog = (
    <PickersModalDialogRoot container={container} {...props} ref={ref} />
  );

  if (!emotionCache) {
    return dialog;
  }

  return <CacheProvider value={emotionCache}>{dialog}</CacheProvider>;
});
