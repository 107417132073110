import { ButtonBase } from '@allganize/ui-button';
import {
  IcArrowNavigateBefore,
  IcArrowNavigateNext,
} from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import useSlotProps from '@mui/utils/useSlotProps';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { tabScrollButtonClasses } from './tab-scroll-button.classes';
import { TabScrollButtonProps } from './tab-scroll-button.types';

export const TabScrollButton = forwardRef<HTMLDivElement, TabScrollButtonProps>(
  (props, ref) => {
    const {
      children,
      classes,
      className,
      slots = {},
      slotProps = {},
      direction,
      disabled,
      orientation,
      ...other
    } = props;
    const theme = useTheme();
    const isRtl = theme.direction === 'rtl';
    const ownerState = { isRtl, ...props };
    const StartButtonIcon =
      slots.StartScrollButtonIcon ?? IcArrowNavigateBefore;
    const EndButtonIcon = slots.EndScrollButtonIcon ?? IcArrowNavigateNext;

    const startButtonIconProps = useSlotProps({
      elementType: StartButtonIcon,
      externalSlotProps: slotProps.startScrollButtonIcon,
      ownerState,
    });

    const endButtonIconProps = useSlotProps({
      elementType: EndButtonIcon,
      externalSlotProps: slotProps.endScrollButtonIcon,
      ownerState,
    });

    return (
      <ButtonBase
        css={[
          css`
            width: 36px;
            flex-shrink: 0;
            opacity: 0.8;

            &.${tabScrollButtonClasses.disabled} {
              opacity: 0;
            }
          `,
          orientation === 'vertical' &&
            css`
              width: 100%;
              height: 36px;

              svg {
                transform: rotate(${isRtl ? -90 : 90}deg);
              }
            `,
        ]}
        component="div"
        role={undefined}
        tabIndex={undefined}
        {...other}
        ref={ref}
        className={clsx(
          tabScrollButtonClasses.root,
          {
            [tabScrollButtonClasses.vertical]: orientation === 'vertical',
            [tabScrollButtonClasses.disabled]: disabled,
          },
          classes?.root,
          {
            [classes?.vertical ?? '']: orientation === 'vertical',
            [classes?.disabled ?? '']: disabled,
          },
          className,
        )}
      >
        {direction === 'left' ? (
          <StartButtonIcon {...startButtonIconProps} />
        ) : (
          <EndButtonIcon {...endButtonIconProps} />
        )}
      </ButtonBase>
    );
  },
);
