import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

import { FragmentOf, gql, readFragment } from '../gql';

export const GetKBProcessStateErrorMessage_KnowledgeBaseFragment = gql(`
  fragment GetKBProcessStateErrorMessage_KnowledgeBaseFragment on KnowledgeBase {
    __typename

    ... on TextKnowledgeBase {
      processState
      processRetryAt
    }

    ... on ImageKnowledgeBase {
      processState
      processRetryAt
    }

    ... on MSDocsKnowledgeBase {
      processState
      processRetryAt
    }

    ... on PDFKnowledgeBase {
      processState
      processRetryAt
    }

    ... on MSExcelKnowledgeBase {
      processState
      processRetryAt
    }

    ... on MSPPTKnowledgeBase {
      processState
      processRetryAt
    }

    ... on OldMSDocsKnowledgeBase {
      processState
      processRetryAt
    }

    ... on CSVKnowledgeBase {
      processState
      processRetryAt
    }

    ... on HWPKnowledgeBase {
      processState
      processRetryAt
    }
  }
`);

export const getKBProcessStateErrorMessage = (
  fragment:
    | FragmentOf<typeof GetKBProcessStateErrorMessage_KnowledgeBaseFragment>
    | null
    | undefined,
  intl: IntlShape,
): ReactNode => {
  const knowledgeBase = readFragment(
    GetKBProcessStateErrorMessage_KnowledgeBaseFragment,
    fragment,
  );

  if (
    !knowledgeBase ||
    knowledgeBase.__typename === 'GoogleDocsKnowledgeBase'
  ) {
    return null;
  }

  if (
    knowledgeBase.processState === gql.scalar('ProcessStateEnum', 'COMPLETED')
  ) {
    return null;
  } else if (
    knowledgeBase.processState === gql.scalar('ProcessStateEnum', 'RETRYING')
  ) {
    return intl.formatMessage(
      {
        id: 'single-action.upload-file.retrying-error-message',
        defaultMessage:
          'Temporary error occurred, will resume in {processRetryAt}.',
        description:
          'single action upload file process state RETRYING error message',
      },
      {
        processRetryAt:
          knowledgeBase.processRetryAt !== null &&
          intl.formatRelativeTime(knowledgeBase.processRetryAt),
      },
    );
  } else if (
    knowledgeBase.processState ===
    gql.scalar('ProcessStateEnum', 'PARSING_FAIL')
  ) {
    return intl.formatMessage({
      id: 'single-action.upload-file.parsing-fail-error-message',
      defaultMessage: 'Parsing failed',
      description:
        'single action upload file process state parsing file error message',
    });
  } else {
    return null;
  }
};
