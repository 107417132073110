import {
  getTabsUtilityClass,
  tabsClasses as muiTabsClasses,
  TabsClassKey as MuiTabsClassKey,
} from '@mui/material/Tabs';

export type TabsClassKey = MuiTabsClassKey | 'underlined' | 'filled';
export type TabsClasses = Record<TabsClassKey, string>;

export const tabsClasses: TabsClasses = {
  ...muiTabsClasses,
  underlined: getTabsUtilityClass('underlined'),
  filled: getTabsUtilityClass('filled'),
};
