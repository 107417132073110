import {
  DocumentViewerPageInfo,
  DocumentViewerPageThumbnailInfo,
} from '@allganize/alli-document';
import { DraftDocumentEntity } from '@allganize/draft-document-plugin';
import { ChatFragment } from '../graphql/fragments/chat-fragment';

export interface DocumentPreviewState {
  chat: ChatFragment;
  entity: DraftDocumentEntity;
  initialPage: number;
  pages: (DocumentViewerPageInfo | (() => Promise<DocumentViewerPageInfo>))[];
  thumbnails: DocumentViewerPageThumbnailInfo[];
  title: string;
  originalWebUri?: string | null;
  trigger: 'click' | 'auto';
}

export const isDocumentPreviewStateEqual = (
  prev: Pick<DocumentPreviewState, 'chat' | 'entity'> | null,
  next: Pick<DocumentPreviewState, 'chat' | 'entity'> | null,
): boolean => {
  if (prev === null || next === null) {
    return false;
  }

  return prev.chat.id === next.chat.id && prev.entity.key === next.entity.key;
};
