import { useField } from '../internals/hooks/use-field';
import { useDefaultDates, useUtils } from '../internals/hooks/use-utils';
import { applyDefaultDate } from '../internals/utils/date-utils';
import { splitFieldInternalAndForwardedProps } from '../internals/utils/fields';
import { validateDate } from '../internals/utils/validation/validate-date';
import {
  singleItemFieldValueManager,
  singleItemValueManager,
} from '../internals/utils/value-managers';
import {
  UseDateFieldDefaultizedProps,
  UseDateFieldParams,
  UseDateFieldProps,
} from './date-field-type-map';

const useDefaultizedDateField = <AdditionalProps extends {}>(
  props: UseDateFieldProps<Date>,
): AdditionalProps & UseDateFieldDefaultizedProps<Date> => {
  const utils = useUtils<Date>();
  const defaultDates = useDefaultDates<Date>();

  return {
    ...props,
    disablePast: props.disablePast ?? false,
    disableFuture: props.disableFuture ?? false,
    format: props.format ?? utils.formats.keyboardDate,
    minDate: applyDefaultDate(utils, props.minDate, defaultDates.minDate),
    maxDate: applyDefaultDate(utils, props.maxDate, defaultDates.maxDate),
  } as any;
};

export const useDateField = <TChildProps extends {}>({
  props: inProps,
  inputRef,
}: UseDateFieldParams<Date, TChildProps>) => {
  const props = useDefaultizedDateField<TChildProps>(inProps);

  const { forwardedProps, internalProps } = splitFieldInternalAndForwardedProps<
    typeof props,
    keyof UseDateFieldProps<Date>
  >(props, 'date');

  return useField({
    inputRef,
    forwardedProps,
    internalProps,
    valueManager: singleItemValueManager,
    fieldValueManager: singleItemFieldValueManager,
    validator: validateDate,
    valueType: 'date',
  });
};
