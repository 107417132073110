import { getDocumentEntities } from '@allganize/alli-chat-document';
import { Types } from '@allganize/alli-sdk-interfaces';
import { useEventCallback } from '@allganize/hooks';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { DocumentPreviewContext } from '../document-preview/document-preview-context';
import { DocumentPreviewState } from '../document-preview/document-preview-state';
import { ChatFragment } from '../graphql/fragments/chat-fragment';
import { ChatsQuery } from '../graphql/queries/chats-query';
import { PreviewContext } from '../preview/preview-context';

interface Props {
  edge: NonNullable<ChatsQuery['chats']>['edges'][number];
}

const getKnowledgeBasePreview = (chat?: ChatFragment | null) => {
  if (chat?.__typename === 'CarouselChat') {
    return chat.chatMrcInfos?.[0]?.knowledgeBasePreview;
  }

  if (chat?.__typename === 'BotChat') {
    return chat.knowledgeBasePreview;
  }

  return null;
};

interface GenerateDocumentPreviewOptions {
  chat: ChatFragment | null;
  documentPreviewState?: DocumentPreviewState | null;
  fallbackText?: string;
}

export const generateDocumentPreview = ({
  chat: node,
  documentPreviewState,
  fallbackText,
}: GenerateDocumentPreviewOptions) => {
  let chat: ChatFragment | null = null;

  if (node?.__typename === 'BotChat') {
    const isCompletedGAAnswer =
      node.mrcAnswerType === Types.MRCAnswerType.GENERATED &&
      node.completeState === Types.ChatCompleteState.SUCCESS;

    if (isCompletedGAAnswer) {
      chat = node;
    } else {
      const isNewAnswerGroup = !(
        documentPreviewState?.chat.__typename === 'BotChat' &&
        node.knowledgeBaseUserAskId ===
          documentPreviewState?.chat.knowledgeBaseUserAskId
      );

      if (isNewAnswerGroup) {
        chat = node;
      }
    }
  }

  if (node?.__typename === 'CarouselChat') {
    const isMrcInfoCarousel = !!getKnowledgeBasePreview(node);

    if (isMrcInfoCarousel) {
      chat = node;
    }
  }

  if (!chat) {
    return null;
  }

  const citationReferences =
    (chat.__typename === 'BotChat' && chat.citationReferences) || undefined;

  const [firstDocument] = getDocumentEntities({
    contentState: citationReferences?.length
      ? undefined
      : chat?.messageContentState ?? undefined,
    knowledgeBasePreview: citationReferences?.length
      ? null
      : getKnowledgeBasePreview(chat),
    citationReferences,
    fallbackText,
  });

  if (!firstDocument) {
    return null;
  }

  return { entity: firstDocument, chat };
};

export const useAutoOpenDocumentPreview = ({ edge }: Props) => {
  const intl = useIntl();
  const { shouldOpenPreviewInDialog } = useContext(PreviewContext);
  const { documentPreviewState, openDocumentPreview } = useContext(
    DocumentPreviewContext,
  );

  const canOpenDocumentPreview = !shouldOpenPreviewInDialog;

  const openFirstDocument = useEventCallback((node: ChatFragment | null) => {
    if (!canOpenDocumentPreview) {
      return;
    }

    const documentPreviewOptions = generateDocumentPreview({
      chat: node,
      documentPreviewState,
      fallbackText: intl.formatMessage(
        {
          id: 'preview',
          defaultMessage: '{count, plural, one {Preview} other {Previews}}',
          description: 'Document preview title missing fallback',
        },
        { count: 1 },
      ),
    });

    if (!documentPreviewOptions) {
      return;
    }

    openDocumentPreview({
      ...documentPreviewOptions,
      trigger: 'auto',
    });
  });

  const node = edge?.node ?? null;

  useEffect(() => {
    openFirstDocument(node);
  }, [node, openFirstDocument]);
};
