import { useEventCallback } from '@allganize/hooks';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { Select, SelectRef } from '@allganize/ui-select';
import { toast } from '@allganize/ui-toast';
import { useLazyQuery, useMutation } from '@apollo/client/react';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { GroupBase } from 'react-select';
import { useAsyncPaginate, wrapMenuList } from 'react-select-async-paginate';
import {
  AccessControlSelectCreatableContextValue,
  AccessControlSelectTabContextValue,
} from '../access-control-select/access-control-select-context';
import { AccessControlSelectMenuList } from '../access-control-select/access-control-select-menu-list';
import { AccessControlSelectOptionComponent } from '../access-control-select/access-control-select-option';
import {
  AccessControlSelectOption,
  AccessControlSelectProps,
  LoadOptionType,
} from '../access-control-select/access-control-select-type-map';
import { gql, Scalars } from '../gql';
import { formatUserName } from '../utils/formatUserName';

export interface AccessControlSelectUserOption
  extends AccessControlSelectOption {
  type: Scalars<'AccessUserFilterEnum'>;
}

export type AccessControlSelectUserProps =
  AccessControlSelectProps<AccessControlSelectUserOption>;

// User
export const CustomerGroupSystemVariable = '@sys.customer_group';
export const CustomerIdSystemVariable = '@sys.customer_id';

export const AccessControlUserSelect_UsersQuery = gql(
  `
  query AccessControlUserSelect_UsersQuery(
  $where: ProjectWhereUniqueInput!
  $filter: UserFilter
  $after: String
  $first: Int
) {
  users(
    where: $where
    filter: $filter
    after: $after
    first: $first
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        ownUserId
        firstName
        lastName
      }
    }
  }
}
`,
);

export const AccessControlUserSelect_CategoryElementsQuery = gql(`
  query AccessControlUserSelect_CategoryElementsQuery(
  $variableWhere: CustomVariableWhereUniqueInput!
  $filter: CategoryElementFilter
  $after: String
  $first: Int
) {
  categoryElementsByCursor(
    variableWhere: $variableWhere
    filter: $filter
    after: $after
    first: $first
  ) {
    edges {
      node {
        id
        value
        users {
          users {
            id
            name
            avatar
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`);

export const AccessControlUserSelect_CreateCategoryElementMutation = gql(
  `
  mutation AccessControlUserSelect_CreateCategoryElementMutation(
    $where: ProjectWhereUniqueInput!
    $variableWhere: CustomVariableWhereUniqueInput!
    $input: CategoryElementInput!
  ) {
    createCategoryElement(
      input: $input
      variableWhere: $variableWhere
      where: $where
    ) {
      categoryElement {
        id
        value
      }
      errors {
        key
        message
        field
        info
      }
    }
  }
  `,
);

const AccessControlSelectMenuListAsync = wrapMenuList(
  AccessControlSelectMenuList,
);

export const AccessControlUserSelect = forwardRef<
  SelectRef<AccessControlSelectUserOption, true>,
  AccessControlSelectUserProps
>(({ projectId, ...selectProps }, ref) => {
  const intl = useIntl();
  const [selectedTab, setTab] = useState<Scalars<'AccessUserFilterEnum'>>(
    gql.scalar('AccessUserFilterEnum', 'CUSTOMER_GROUP'),
  );
  const [refetchFlag, setRefetchFlag] = useState(0);
  const abortController = useRef(new AbortController());

  const [fetchUsers] = useLazyQuery(AccessControlUserSelect_UsersQuery, {
    variables: {
      where: {
        id: projectId,
      },
      first: 20,
    },
  });

  const [fetchUserGroups, { data: userGroupData }] = useLazyQuery(
    AccessControlUserSelect_CategoryElementsQuery,
    {
      variables: {
        variableWhere: {
          id: CustomerGroupSystemVariable,
          projectWhere: {
            id: projectId,
          },
        },
        first: 20,
      },
    },
  );

  const [createUserGroup, { loading: createGroupLoading }] = useMutation(
    AccessControlUserSelect_CreateCategoryElementMutation,
    {
      refetchQueries: [AccessControlUserSelect_CategoryElementsQuery],
    },
  );

  const triggerRefetch = useEventCallback(() => {
    setRefetchFlag(prev => prev + 1);
    abortController.current.abort();
    abortController.current = new AbortController();
  });

  const handleTabChange = useEventCallback(
    (_: React.SyntheticEvent, value: Scalars<'AccessUserFilterEnum'>) => {
      setTab(value);
      triggerRefetch();
    },
  );

  const handleCreateOption = useEventCallback(async inputValue => {
    try {
      const { data } = await createUserGroup({
        variables: {
          where: {
            id: projectId,
          },
          variableWhere: {
            id: CustomerGroupSystemVariable,
            projectWhere: {
              id: projectId,
            },
          },
          input: {
            value: inputValue,
          },
        },
      });

      if (data?.createCategoryElement?.errors?.length) {
        throw data.createCategoryElement.errors[0];
      }

      if (!data?.createCategoryElement?.categoryElement) {
        throw new Error('unknown error');
      }

      triggerRefetch();
    } catch {
      toast.error(
        intl.formatMessage({
          id: 'access-control.select.customer-group.create.error',
          defaultMessage: 'Failed to create the group. Please try again.',
          description: 'Error message when failed to create customer group',
        }),
      );
    }
  });

  const isValidOption = useCallback(
    (inputValue: string) => {
      return !userGroupData?.categoryElementsByCursor?.edges?.some(
        w => w?.node?.value === inputValue,
      );
    },
    [userGroupData?.categoryElementsByCursor],
  );

  const handleLoadOptions: LoadOptionType<AccessControlSelectUserOption> =
    useEventCallback(async (inputValue, prevOptions, additional) => {
      const normalizedInputValue = inputValue.trim();
      try {
        if (
          selectedTab === gql.scalar('AccessUserFilterEnum', 'CUSTOMER_GROUP')
        ) {
          const { data } = await fetchUserGroups({
            variables: {
              variableWhere: {
                id: CustomerGroupSystemVariable,
                projectWhere: {
                  id: projectId,
                },
              },
              filter: {
                searchTerm: normalizedInputValue,
              },
              after: additional?.endCursor,
            },
            context: {
              fetchOptions: {
                signal: abortController.current.signal,
              },
            },
          });
          if (!data?.categoryElementsByCursor?.edges) {
            throw new Error('No data group');
          }
          const result = data.categoryElementsByCursor.edges.reduce<
            AccessControlSelectUserOption[]
          >((prev, curr) => {
            if (!curr?.node || !curr.node.id) {
              return prev;
            }

            prev.push({
              type: gql.scalar('AccessUserFilterEnum', 'CUSTOMER_GROUP'),
              label: curr.node.value,
              value: curr.node.id,
              description: intl.formatMessage({
                id: 'access-control.select.customer-group.description',
                defaultMessage: 'Group',
                description:
                  'Description for customer group in access control select option',
              }),
              avatar: {
                variant: 'group',
              },
              accessList: curr.node.users?.users,
            });
            return prev;
          }, []);
          return {
            options: result,
            hasMore: data.categoryElementsByCursor.pageInfo.hasNextPage,
            additional: {
              endCursor: data.categoryElementsByCursor.pageInfo.endCursor,
            },
          };
        } else if (
          selectedTab === gql.scalar('AccessUserFilterEnum', 'CUSTOMER_ID')
        ) {
          const { data } = await fetchUsers({
            variables: {
              where: {
                id: projectId,
              },
              filter: {
                searchTerm: normalizedInputValue,
              },
              after: additional?.endCursor,
            },
            context: {
              fetchOptions: {
                signal: abortController.current.signal,
              },
            },
          });

          if (!data?.users?.edges) {
            throw new Error('No data users');
          }

          const result = data.users.edges.reduce<
            AccessControlSelectUserOption[]
          >((prev, curr) => {
            if (!curr?.node || !curr.node.ownUserId) {
              return prev;
            }
            const name = formatUserName(curr.node, intl.locale);
            prev.push({
              type: gql.scalar('AccessUserFilterEnum', 'CUSTOMER_ID'),
              label:
                name ||
                intl.formatMessage({
                  id: 'access-control.user.name.placeholder',
                  defaultMessage: 'Guest',
                  description:
                    'Placeholder text for user name in access control',
                }),
              value: curr.node.ownUserId,
              description: `ID: ${curr.node.ownUserId}`,
              avatar: {
                name,
              },
            });
            return prev;
          }, []);
          return {
            options: result,
            hasMore: data.users.pageInfo.hasNextPage,
            additional: {
              endCursor: data.users.pageInfo.endCursor,
            },
          };
        }
      } catch {
        toast.error(
          intl.formatMessage({
            id: 'access-control.select.load.error',
            defaultMessage: 'Failed to load the list. Please try again.',
            description: 'Error message when failed to load list',
          }),
        );
      }
      return {
        options: [],
        hasMore: false,
      };
    });

  const loadOptions: LoadOptionType<AccessControlSelectUserOption> =
    useEventCallback(async (...args) => {
      return new Promise((resolve, reject) => {
        abortController.current.signal.addEventListener('abort', reject);
        handleLoadOptions(...args)
          .then(resolve, reject)
          .finally(() => {
            abortController.current.signal.removeEventListener('abort', reject);
          });
      });
    });

  const formatCreateLabel = useCallback(
    (inputValue: string) =>
      intl.formatMessage(
        {
          id: 'access-control.select.customer-group.create.label',
          defaultMessage: 'Create "{inputValue}" group',
          description:
            'Create button label text in access control customer group select menu',
        },
        {
          inputValue,
        },
      ),
    [intl],
  );

  const asyncPaginateProps = useAsyncPaginate(
    {
      loadOptions,
      // should be true after the first render
      // to refetch the data when the flag is changed
      defaultOptions: refetchFlag !== 0,
      debounceTimeout: 100,
    },
    [refetchFlag],
  );

  const tabContextValue = useMemo<AccessControlSelectTabContextValue>(
    () => ({
      selectedTab,
      onTabChange: handleTabChange,
      tabOptions: [
        {
          value: gql.scalar('AccessUserFilterEnum', 'CUSTOMER_GROUP'),
          label: intl.formatMessage({
            id: 'access-control.select.menu-list.tab.customer-group.label',
            defaultMessage: 'Group',
            description:
              'Label for customer group tab in access control select menu list',
          }),
        },
        {
          value: gql.scalar('AccessUserFilterEnum', 'CUSTOMER_ID'),
          label: intl.formatMessage({
            id: 'access-control.select.menu-list.tab.customer-id.label',
            defaultMessage: 'Customer',
            description:
              'Label for customer id tab in access control select menu list',
          }),
        },
      ],
    }),
    [selectedTab, handleTabChange, intl],
  );

  const creatableContextValue =
    useMemo<AccessControlSelectCreatableContextValue | null>(
      () =>
        selectedTab === gql.scalar('AccessUserFilterEnum', 'CUSTOMER_GROUP')
          ? {
              loading: createGroupLoading,
              isCreatable: isValidOption(asyncPaginateProps.inputValue),
              inputValue: asyncPaginateProps.inputValue,
              onCreateOption: handleCreateOption,
              formatCreateLabel,
            }
          : null,
      [
        asyncPaginateProps.inputValue,
        isValidOption,
        handleCreateOption,
        formatCreateLabel,
        createGroupLoading,
        selectedTab,
      ],
    );

  return (
    <Select<
      AccessControlSelectUserOption,
      true,
      GroupBase<AccessControlSelectUserOption>
    >
      maxMenuHeight={244}
      hideSelectedOptions
      loadingMessage={() => <CircularProgress />}
      {...asyncPaginateProps}
      {...selectProps}
      isMulti
      backspaceRemovesValue={false}
      ref={ref}
      components={{
        ...selectProps.components,
        MenuList: AccessControlSelectMenuListAsync,
        Option: AccessControlSelectOptionComponent,
      }}
      // @ts-expect-error additional select props
      tabContextValue={tabContextValue}
      creatableContextValue={creatableContextValue}
    />
  );
});
