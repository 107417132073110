import { createIllustration } from '../create-illustration';
import error from './error.png';
import error2x from './error@2x.png';
import error3x from './error@3x.png';

export const Error = createIllustration({
  src: error,
  srcSet: [error, error2x, error3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 160,
});
