import { toast } from '@allganize/ui-toast';
import { FormattedErrorMessage } from '@allganize/utils-graphql';
import { useMutation } from '@apollo/client/react';
import { gql } from '../gql';

const ToggleKnowledgeBaseMutation = gql(`
  mutation ToggleKnowledgeBaseMutation($ids: [ID!]!, $use: Boolean!, $project: ProjectWhereUniqueInput!) {
    toggleKnowledgeBase(ids: $ids, use: $use, where: $project) {
      knowledgeBaseNodes {
        id

        ...on KnowledgeBaseNodeFile {
          status
        }
      }

      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`);

interface UseToggleKnowledgeBaseOptions {
  onToggle?(): void;
}

export const useToggleKnowledgeBase = ({
  onToggle,
}: UseToggleKnowledgeBaseOptions) => {
  return useMutation(ToggleKnowledgeBaseMutation, {
    onCompleted(result) {
      if (result.toggleKnowledgeBase?.knowledgeBaseNodes) {
        onToggle?.();
        return;
      }

      if (result.toggleKnowledgeBase?.errors?.[0]) {
        toast.error(
          <FormattedErrorMessage
            error={result.toggleKnowledgeBase.errors[0]}
          />,
        );
        return;
      }

      toast.error(<FormattedErrorMessage />);
    },
    onError(err) {
      toast.error(<FormattedErrorMessage error={err} />);
    },
  });
};
