import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ChatFormRowClassKey =
  | 'root'
  | 'avatar'
  | 'header'
  | 'content'
  | 'alignLeft'
  | 'alignRight';
export type ChatFormRowClasses = Record<ChatFormRowClassKey, string>;

export const chatFormRowClasses: ChatFormRowClasses = generateUtilityClasses(
  'ChatFormRow',
  ['root', 'avatar', 'header', 'content', 'alignLeft', 'alignRight'],
);
