import { FunctionComponent } from 'react';
import { ProjectContext } from './contexts/project-context';
import {
  useProjectQuery,
  UseProjectQueryOptions,
} from './hooks/use-project-query';

interface ProjectProviderProps extends UseProjectQueryOptions {
  children?: React.ReactNode;
}

export const ProjectProvider: FunctionComponent<
  ProjectProviderProps
> = props => {
  const { children } = props;
  const { data } = useProjectQuery(props);

  if (!data.project) {
    throw new Error('Project not found');
  }

  return (
    <ProjectContext.Provider value={data.project}>
      {children}
    </ProjectContext.Provider>
  );
};
