import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { ChatGroupLabelProps } from './chat-group-label.types';

export const ChatGroupLabel = forwardRef<HTMLDivElement, ChatGroupLabelProps>(
  (props, ref) => {
    const theme = useTheme();

    return (
      <div
        css={css`
          display: inline-flex;
          align-items: center;
          border-radius: ${theme.radius.round}px;
          height: 24px;
          padding: 0 11px;
          ${theme.typography.body12}
          color: ${theme.palette.foreground.secondary};
          border: 1px solid ${theme.palette.border.divider.default};
        `}
        {...props}
        ref={ref}
      />
    );
  },
);
