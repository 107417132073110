import { useCombinedRef, useEventCallback } from '@allganize/hooks';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { useControlled } from '@mui/material/utils';
import clsx from 'clsx';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DIALOG_WIDTH,
  MAX_CALENDAR_HEIGHT,
} from '../internals/constants/dimensions';
import {
  useDefaultDates,
  useNow,
  useUtils,
} from '../internals/hooks/use-utils';
import { useControlledValueWithTimezone } from '../internals/hooks/use-value-with-timezone';
import { applyDefaultDate } from '../internals/utils/date-utils';
import { SECTION_TYPE_GRANULARITY } from '../internals/utils/get-default-reference-date';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { PickersYear } from '../pickers-year';
import { yearCalendarClasses } from './year-calendar-classes';
import { YearCalendarProps } from './year-calendar-type-map';

export const YearCalendar = forwardRef<HTMLDivElement, YearCalendarProps>(
  (props, ref) => {
    const {
      autoFocus,
      classes,
      value: valueProp,
      defaultValue,
      referenceDate: referenceDateProp,
      disabled,
      disableFuture = false,
      disablePast = false,
      maxDate: maxDateProp,
      minDate: minDateProp,
      onChange,
      readOnly,
      shouldDisableYear,
      disableHighlightToday,
      onYearFocus,
      hasFocus,
      onFocusedViewChange,
      yearsPerRow = 4,
      timezone: timezoneProp,
      gridLabelId,
      ...other
    } = props;
    const utils = useUtils<Date>();
    const defaultDates = useDefaultDates<Date>();
    const minDate = applyDefaultDate(utils, minDateProp, defaultDates.minDate);
    const maxDate = applyDefaultDate(utils, maxDateProp, defaultDates.maxDate);

    const { value, handleValueChange, timezone } =
      useControlledValueWithTimezone({
        name: 'YearCalendar',
        timezone: timezoneProp,
        value: valueProp,
        defaultValue,
        onChange: onChange as (value: Date | null) => void,
        valueManager: singleItemValueManager,
      });

    const now = useNow<Date>(timezone);
    const theme = useTheme();

    const referenceDate = useMemo(
      () =>
        singleItemValueManager.getInitialReferenceValue({
          value,
          utils,
          props,
          timezone,
          referenceDate: referenceDateProp,
          granularity: SECTION_TYPE_GRANULARITY.year,
        }),
      [],
    );

    const todayYear = useMemo(() => utils.getYear(now), [utils, now]);
    const selectedYear = useMemo(() => {
      if (value != null) {
        return utils.getYear(value);
      }

      if (disableHighlightToday) {
        return null;
      }

      return utils.getYear(referenceDate);
    }, [value, utils, disableHighlightToday, referenceDate]);

    const [focusedYear, setFocusedYear] = useState(
      () => selectedYear || todayYear,
    );

    const [internalHasFocus, setInternalHasFocus] = useControlled({
      name: 'YearCalendar',
      state: 'hasFocus',
      controlled: hasFocus,
      default: autoFocus ?? false,
    });

    const changeHasFocus = useEventCallback((newHasFocus: boolean) => {
      setInternalHasFocus(newHasFocus);

      if (onFocusedViewChange) {
        onFocusedViewChange(newHasFocus);
      }
    });

    const isYearDisabled = useCallback(
      (dateToValidate: Date) => {
        if (disablePast && utils.isBeforeYear(dateToValidate, now)) {
          return true;
        }
        if (disableFuture && utils.isAfterYear(dateToValidate, now)) {
          return true;
        }
        if (minDate && utils.isBeforeYear(dateToValidate, minDate)) {
          return true;
        }
        if (maxDate && utils.isAfterYear(dateToValidate, maxDate)) {
          return true;
        }

        if (!shouldDisableYear) {
          return false;
        }

        const yearToValidate = utils.startOfYear(dateToValidate);
        return shouldDisableYear(yearToValidate);
      },
      [
        disableFuture,
        disablePast,
        maxDate,
        minDate,
        now,
        shouldDisableYear,
        utils,
      ],
    );

    const handleYearSelection = useEventCallback(
      (event: React.MouseEvent, year: number) => {
        if (readOnly) {
          return;
        }

        const newDate = utils.setYear(value ?? referenceDate, year);
        handleValueChange(newDate);
      },
    );

    const focusYear = useEventCallback((year: number) => {
      if (!isYearDisabled(utils.setYear(value ?? referenceDate, year))) {
        setFocusedYear(year);
        changeHasFocus(true);
        onYearFocus?.(year);
      }
    });

    useEffect(() => {
      setFocusedYear(prevFocusedYear =>
        selectedYear !== null && prevFocusedYear !== selectedYear
          ? selectedYear
          : prevFocusedYear,
      );
    }, [selectedYear]);

    const handleKeyDown = useEventCallback(
      (event: React.KeyboardEvent, year: number) => {
        switch (event.key) {
          case 'ArrowUp':
            focusYear(year - yearsPerRow);
            event.preventDefault();
            break;
          case 'ArrowDown':
            focusYear(year + yearsPerRow);
            event.preventDefault();
            break;
          case 'ArrowLeft':
            focusYear(year + (theme.direction === 'ltr' ? -1 : 1));
            event.preventDefault();
            break;
          case 'ArrowRight':
            focusYear(year + (theme.direction === 'ltr' ? 1 : -1));
            event.preventDefault();
            break;
          default:
            break;
        }
      },
    );

    const handleYearFocus = useEventCallback(
      (event: React.FocusEvent, year: number) => {
        focusYear(year);
      },
    );

    const handleYearBlur = useEventCallback(
      (event: React.FocusEvent, year: number) => {
        if (focusedYear === year) {
          changeHasFocus(false);
        }
      },
    );

    const scrollerRef = useRef<HTMLDivElement>(null);
    const handleRef = useCombinedRef(scrollerRef, ref);

    useEffect(() => {
      if (autoFocus || scrollerRef.current === null) {
        return;
      }
      const tabbableButton =
        scrollerRef.current.querySelector<HTMLElement>('[tabindex="0"]');
      if (!tabbableButton) {
        return;
      }

      // Taken from useScroll in x-data-grid, but vertically centered
      const offsetHeight = tabbableButton.offsetHeight;
      const offsetTop = tabbableButton.offsetTop;

      const clientHeight = scrollerRef.current.clientHeight;
      const scrollTop = scrollerRef.current.scrollTop;

      const elementBottom = offsetTop + offsetHeight;

      if (offsetHeight > clientHeight || offsetTop < scrollTop) {
        // Button already visible
        return;
      }

      scrollerRef.current.scrollTop =
        elementBottom - clientHeight / 2 - offsetHeight / 2;
    }, [autoFocus]);

    return (
      <div
        data-testid="year-calendar"
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          overflow-y: auto;
          height: 100%;
          padding: 8px 20px;
          width: ${DIALOG_WIDTH}px;
          max-height: ${MAX_CALENDAR_HEIGHT}px;
          box-sizing: border-box;
          position: relative;
        `}
        role="radiogroup"
        aria-labelledby={gridLabelId}
        {...other}
        ref={handleRef}
        className={clsx(
          yearCalendarClasses.root,
          classes?.root,
          other.className,
        )}
      >
        {utils.getYearRange(minDate, maxDate).map(year => {
          const yearNumber = utils.getYear(year);
          const isSelected = yearNumber === selectedYear;
          const isDisabled = disabled || isYearDisabled(year);

          return (
            <PickersYear
              key={utils.format(year, 'year')}
              selected={isSelected}
              value={yearNumber}
              onClick={handleYearSelection}
              onKeyDown={handleKeyDown}
              autoFocus={internalHasFocus && yearNumber === focusedYear}
              disabled={isDisabled}
              tabIndex={yearNumber === focusedYear ? 0 : -1}
              onFocus={handleYearFocus}
              onBlur={handleYearBlur}
              aria-current={todayYear === yearNumber ? 'date' : undefined}
              yearsPerRow={yearsPerRow}
            >
              {utils.format(year, 'year')}
            </PickersYear>
          );
        })}
      </div>
    );
  },
);
