import {
  DocumentsPicker,
  DocumentPickerActions,
} from '@allganize/alli-works-documents';
import { useProject } from '@allganize/alli-works-project';
import { FunctionComponent, useContext, useEffect, useRef } from 'react';
import { SidePanelContext } from './side-panel-context';
import { isDocumentListTab } from './use-tab-navigator';

export const DocumentListPanel: FunctionComponent = () => {
  const { appCollectionSettings } = useProject();
  const { activeTab, openDocumentListTab } = useContext(SidePanelContext);
  const documentListPanelActions = useRef<DocumentPickerActions>(null);

  const handleSelectedKnowledgeBaseIdsChange = (
    selectedKnowledgeBaseIds: (string | number)[],
  ) => {
    openDocumentListTab({ selectedKnowledgeBaseIds });
  };

  const tab = isDocumentListTab(activeTab)
    ? activeTab.metadata?.tab
    : undefined;

  useEffect(() => {
    if (
      isDocumentListTab(activeTab) &&
      activeTab.metadata?.openUpload &&
      documentListPanelActions.current?.upload
    ) {
      documentListPanelActions.current.upload();
      openDocumentListTab({ openUpload: false });
    }
  }, [
    activeTab,
    documentListPanelActions.current?.upload,
    openDocumentListTab,
  ]);

  return (
    <DocumentsPicker
      tab={tab}
      actions={documentListPanelActions}
      hideHeader={!!appCollectionSettings?.hideWorksKb}
      onTabChange={tab => openDocumentListTab({ tab })}
      onSelectedKnowledgeBaseIdsChange={handleSelectedKnowledgeBaseIdsChange}
    />
  );
};
