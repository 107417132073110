import { BaseState, Illustration } from '@allganize/ui-state';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

export const DocumentsLeftPanelErrorFallback: FunctionComponent = () => {
  return (
    <BaseState
      css={css`
        padding: 100px 0;
      `}
      image={<Illustration.Error width={64} height={64} />}
      variant="card"
      title={
        <FormattedMessage
          id="something-went-wrong"
          defaultMessage="Oops! Something went wrong."
          description="something went wrong error message"
        />
      }
      description={
        <FormattedMessage
          id="try-again-later"
          defaultMessage="Something went wrong. Please try refreshing the page or come back later."
          description="try again later error message"
        />
      }
    />
  );
};
