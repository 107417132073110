import { Divider } from '@allganize/ui-divider';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { ChatDividerProps } from './chat-divider.types';

export const ChatDivider = forwardRef<HTMLDivElement, ChatDividerProps>(
  (props, ref) => {
    const theme = useTheme();

    return (
      <ClassNames>
        {({ css }) => (
          <Divider
            data-testid="chat-divider"
            component="div"
            {...props}
            ref={ref}
            classes={{
              root: css`
                white-space: normal;
              `,
              wrapper: css`
                ${theme.typography.body12}
                max-width: 252px;
                flex-shrink: 0;
                color: ${theme.palette.foreground.secondary};
              `,
            }}
          />
        )}
      </ClassNames>
    );
  },
);
