import { atom, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { Scalars } from '../gql';
import { MoveNodeFormProps } from './move-node-form';

export const moveNodeAtom = atomFamily(
  (projectId: Scalars<'ID'>) =>
    atom<MoveNodeFormProps>({
      open: false,
      projectId,
      currentFolderPath: [],
      nodeIds: [],
    }),
  (a, b) => a === b,
);

export const useMoveNode = (projectId: Scalars<'ID'>) => {
  return useSetAtom(moveNodeAtom(projectId));
};
