import { createIllustration } from '../create-illustration';
import customer from './customer.png';
import customer2x from './customer@2x.png';
import customer3x from './customer@3x.png';

export const Customer = createIllustration({
  src: customer,
  srcSet: [customer, customer2x, customer3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 64,
});
