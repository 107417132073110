import { DraftDecoratorComponentProps } from '@allganize/draft-input';
import { DraftLink, DraftLinkProps } from '@allganize/draft-link-plugin';
import { ChipProps } from '@allganize/ui-chip';
import { forwardRef, useContext } from 'react';
import { DraftDocumentContext } from './draft-document-context';
import { DraftDocumentEntity } from './draft-document-entity';
import { DraftDocumentLinkBase } from './draft-document-link-base';

export type DraftDocumentLinkProps = Omit<ChipProps, 'label'> & DraftLinkProps;

export const DraftDocumentLink = forwardRef<
  HTMLDivElement,
  DraftDecoratorComponentProps & DraftDocumentLinkProps
>((props, ref) => {
  const {
    blockKey,
    contentState,
    decoratedText,
    end,
    entityKey,
    offsetKey,
    start,
    children,
    ...other
  } = props;
  const { onClick } = useContext(DraftDocumentContext);
  const entity = entityKey ? contentState.getEntity(entityKey) : null;
  const entityData = entity?.getData();
  const documentEntity =
    entityData && entityKey
      ? ({
          key: entityKey,
          text: decoratedText,
          data: entityData,
        } satisfies DraftDocumentEntity)
      : undefined;
  const clickable = other.onClick || (onClick && documentEntity);

  if (documentEntity?.data.isDeleted) {
    return (
      <DraftDocumentLinkBase
        data-testid="draft-document-link"
        entity={documentEntity}
        disabled
        {...other}
        ref={ref}
        label={children}
      />
    );
  }

  if (!clickable) {
    return <DraftLink data-testid="draft-document-link" {...props} />;
  }

  return (
    <DraftDocumentLinkBase
      data-testid="draft-document-link"
      entity={documentEntity}
      {...other}
      ref={ref}
      label={children}
      onClick={clickable ? other.onClick : undefined}
    />
  );
});
