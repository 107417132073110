import { useProject } from '@allganize/alli-works-project';
import {
  KnowledgeBaseNavigator,
  KnowledgeBaseNavigatorActions,
  KnowledgeBaseNavigatorProps,
} from '@allganize/knowledge-base-tree-view';
import { Scroller } from '@allganize/ui-scroller';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { uniq } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { leftPanelWidth } from '../documents-layout/documents-layout.const';
import { Scalars } from '../gql';
import { useDocumentsTab } from '../hooks/use-documents-tab';
import { DocumentsLeftPanelErrorFallback } from './documents-left-panel-error-fallback';

interface DocumentsLeftPanelProps {
  className?: string;
}

export const DocumentsLeftPanel: FunctionComponent<
  DocumentsLeftPanelProps
> = props => {
  const { className } = props;
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = useParams<{ nodeId?: string }>();
  const project = useProject();
  const { tab } = useDocumentsTab();
  const navigatorActions = useRef<KnowledgeBaseNavigatorActions>(null);
  const [expandedIds, setExpandedIds] = useState<Scalars<'ID'>[]>([]);

  const expandNode = (...nodeIds: Scalars<'ID'>[]) => {
    setExpandedIds(prev => uniq([...prev, ...nodeIds]));
  };

  const handleSelectedNodeChange: KnowledgeBaseNavigatorProps['onSelectedNodeChange'] =
    async (node, isSelected) => {
      if (!isSelected) {
        return;
      }

      // search route
      if (node?.id === 'search') {
        return;
      }

      const pathname = ['/documents', node?.id].filter(Boolean).join('/');
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('detail');

      if (location.pathname !== pathname) {
        navigate({ pathname, search: newParams.toString() });
      }

      if (node === null) {
        return;
      }

      await navigatorActions.current?.loadData(node.id);
      expandNode(node.id);
    };

  return (
    <div
      css={css`
        overflow: hidden;
        width: ${leftPanelWidth}px;
        border-right: 1px solid ${theme.palette.border.divider.default};
      `}
      className={className}
    >
      <Scroller
        css={css`
          height: 100%;
        `}
        forceVisible="y"
      >
        <div
          css={css`
            padding: 16px;
          `}
        >
          <ErrorBoundary fallback={<DocumentsLeftPanelErrorFallback />}>
            <KnowledgeBaseNavigator
              actions={navigatorActions}
              projectId={project.id}
              expandedIds={expandedIds}
              onExpandedIdsChange={setExpandedIds}
              selectedIds={params.nodeId ? [params.nodeId] : []}
              onSelectedNodeChange={handleSelectedNodeChange}
              ownership={tab?.ownership}
              rootNodeName={tab?.label}
            >
              <DocumentsLeftPanelErrorFallback />
            </KnowledgeBaseNavigator>
          </ErrorBoundary>
        </div>
      </Scroller>
    </div>
  );
};
