import { Truncate } from '@allganize/truncate';
import { IcIndicatorBullet } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { memo } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

export const KnowledgeBaseStatusColumn_KnowledgeBaseNodeFragment = gql(`
  fragment KnowledgeBaseStatusColumn_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    ...on KnowledgeBaseNodeFile {
      status
    }
  }
`);

interface KnowledgeBaseStatusColumnProps {
  node: FragmentOf<typeof KnowledgeBaseStatusColumn_KnowledgeBaseNodeFragment>;
}

export const KnowledgeBaseStatusColumn = memo<KnowledgeBaseStatusColumnProps>(
  props => {
    const { node: nodeProp } = props;
    const node = readFragment(
      KnowledgeBaseStatusColumn_KnowledgeBaseNodeFragment,
      nodeProp,
    );
    const theme = useTheme();

    if (node.__typename !== 'KnowledgeBaseNodeFile' || node.status === null) {
      return null;
    }

    return (
      <div
        css={css`
          display: inline-flex;
          align-items: center;
          gap: 3px;
        `}
      >
        <IcIndicatorBullet
          css={[
            css`
              flex-shrink: 0;
              color: ${theme.palette.foreground.secondary};
            `,
            node.status === gql.scalar('KnowledgeBaseNodeOnOffState', 'ON') &&
              css`
                color: ${theme.palette.foreground.primary.default};
              `,
          ]}
          fontSize="small"
        />

        <Truncate
          css={css`
            flex-grow: 1;
            word-break: break-all;
          `}
          clamp={1}
        >
          <Text variant="body14">{node.status}</Text>
        </Truncate>
      </div>
    );
  },
);
