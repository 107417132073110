import { UserQueryTypes } from '@allganize/data-access-alli-user-query';

export const CONVERSATION_STATE_WITH_AGENT: UserQueryTypes.ConversationStateEnum[] =
  [
    UserQueryTypes.ConversationStateEnum.WAIT_AGENT,
    UserQueryTypes.ConversationStateEnum.INTERRUPTED,
  ];

export const CONVERSATION_STATE_WAIT_USER_ANSWER: UserQueryTypes.ConversationStateEnum[] =
  [
    UserQueryTypes.ConversationStateEnum.WAIT_USER_ANSWER,
    UserQueryTypes.ConversationStateEnum.WAIT_USER_ANSWER_2,
  ];

export const CONVERSATION_STATE_ENDED: UserQueryTypes.ConversationStateEnum[] =
  [
    UserQueryTypes.ConversationStateEnum.END_BY_USER,
    UserQueryTypes.ConversationStateEnum.END_OF_CHATBOT,
    UserQueryTypes.ConversationStateEnum.END_BY_AGENT,
    UserQueryTypes.ConversationStateEnum.END_OF_OBSERVER,
  ];
