import { Scroller } from '@allganize/ui-scroller';
import { css } from '@emotion/react';
import { FunctionComponent, Suspense, useMemo, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { DocumentPreview } from '../document-preview/document-preview';
import { DocumentsHeader } from '../documents-header';
import { DocumentsLeftPanel } from '../documents-left-panel/documents-left-panel';
import { DocumentsRightPanel } from '../documents-right-panel/documents-right-panel';
import {
  DocumentsTableContext,
  DocumentsTableContextValue,
} from '../documents-table/documents-table-context';
import { useDocumentsLayout } from '../hooks/use-documents-layout';
import { UploadFile } from '../upload-file';

interface DocumentsLayoutProps {
  children?: React.ReactNode;
  className?: string;
}

export const DocumentsLayout: FunctionComponent<
  DocumentsLayoutProps
> = props => {
  const { children, className } = props;
  const { showLeftPanel } = useDocumentsLayout();
  const mainContentScrollableNodeRef = useRef<HTMLDivElement>(null);

  const scrollableNodeProps = useMemo(() => {
    return { ref: mainContentScrollableNodeRef };
  }, []);

  const documentsTableContextValue = useMemo<DocumentsTableContextValue>(() => {
    return {
      virtualizer: {
        getScrollElement() {
          return mainContentScrollableNodeRef.current;
        },
      },
    };
  }, []);

  return (
    <div
      css={css`
        flex-grow: 1;
        overflow: hidden;
      `}
      className={className}
    >
      <div
        css={[
          css`
            height: 100%;
            overflow: hidden;
            display: grid;
            grid-template-areas: 'header header header' 'main main right';
            grid-template-columns: auto 1fr auto;
            grid-template-rows: auto 1fr;
          `,
          showLeftPanel &&
            css`
              grid-template-areas: 'header header header' 'left main right';
            `,
        ]}
      >
        <DocumentsHeader
          css={css`
            grid-area: header;
          `}
        />

        {showLeftPanel && (
          <DocumentsLeftPanel
            css={css`
              grid-area: left;
            `}
          />
        )}

        <div
          css={css`
            grid-area: main;
            overflow: hidden;
          `}
        >
          <Scroller
            css={css`
              width: 100%;
              height: 100%;
            `}
            forceVisible="y"
            scrollableNodeProps={scrollableNodeProps}
          >
            <DocumentsTableContext.Provider value={documentsTableContextValue}>
              {children}
            </DocumentsTableContext.Provider>
          </Scroller>
        </div>

        <ErrorBoundary fallback={null}>
          <Suspense fallback={null}>
            <DocumentsRightPanel
              css={css`
                grid-area: right;
              `}
            />
          </Suspense>
        </ErrorBoundary>
      </div>

      <ErrorBoundary fallback={null}>
        <Suspense fallback={null}>
          <UploadFile />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary fallback={null}>
        <Suspense fallback={null}>
          <DocumentPreview />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
