import { useCancelKnowledgeBaseLlmTask } from '@allganize/knowledge-base-actions';
import { LoadingButton } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcGenerate, IcStopGenerate } from '@allganize/ui-icons';
import { ListItemSecondaryText, ListItemText } from '@allganize/ui-list';
import { SelectField } from '@allganize/ui-select';
import { Text } from '@allganize/ui-text';
import { Tooltip } from '@allganize/ui-tooltip';
import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import useSlot from '@mui/material/utils/useSlot';
import { css } from '@emotion/react';
import {
  FunctionComponent,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FragmentOf, gql, readFragment, ResultOf, Scalars } from '../gql';
import { PanelItem } from '../panel-item/panel-item';
import { PanelItemGroup } from '../panel-item/panel-item-group';
import {
  QnaGenerationCandidates,
  QnaGenerationCandidatesProps,
} from './qna-generation-candidates';

export const QnaGeneration_KnowledgeBaseNodeFragment = gql(`
  fragment QnaGeneration_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    __typename
    id
    name

    ...on KnowledgeBaseNodeFile {
      processState

      llmTaskInfos(task: QA_GEN) {
        id
        generatedCandidatesCount

        progress {
          ...on KnowledgeBaseLLMTaskQNAProgress {
            status
            startedAt
          }
        }
      }
    }
  }
`);

type KnowledgeBaseLLMTaskInfo = NonNullable<
  Extract<
    ResultOf<typeof QnaGeneration_KnowledgeBaseNodeFragment>,
    { __typename: 'KnowledgeBaseNodeFile' }
  >['llmTaskInfos']
>[number];

export interface QnaGenerationSlots {
  /**
   * @default QnaGenerationCandidates
   */
  qnaGenerationCandidates?: React.ElementType;
}

export interface QnaGenerationSlotProps {
  qnaGenerationCandidates: SlotProps<
    React.ElementType<QnaGenerationCandidatesProps>,
    {},
    {}
  >;
}

export type QnaGenerationSlotsAndSlotProps = CreateSlotsAndSlotProps<
  QnaGenerationSlots,
  QnaGenerationSlotProps
>;

export interface QnaGenerationActions {
  selectTask(taskId: Scalars<'ID'>): void;
}

export interface QnaGenerationProps extends QnaGenerationSlotsAndSlotProps {
  actions?: React.Ref<QnaGenerationActions>;
  node: FragmentOf<typeof QnaGeneration_KnowledgeBaseNodeFragment>;
  projectId: Scalars<'ID'>;
  onQnaGenerate?(
    node: ResultOf<typeof QnaGeneration_KnowledgeBaseNodeFragment>,
  ): void;
  onQnaGenerateCancel?(): void;
}

export const QnaGeneration: FunctionComponent<QnaGenerationProps> = props => {
  const {
    actions,
    node: nodeProp,
    onQnaGenerate,
    onQnaGenerateCancel,
    projectId,
    slotProps = {},
    slots = {},
  } = props;
  const intl = useIntl();
  const node = readFragment(QnaGeneration_KnowledgeBaseNodeFragment, nodeProp);
  const llmTaskInfos = useMemo(() => {
    if (node.__typename !== 'KnowledgeBaseNodeFile') {
      return [];
    }

    return node.llmTaskInfos ?? [];
  }, [node]);
  const [selectedTaskId, setSelectedTaskId] = useState<Scalars<'ID'> | null>(
    llmTaskInfos[0]?.id ?? null,
  );
  const selectedTask = useMemo(() => {
    if (selectedTaskId === null) {
      return null;
    }

    return llmTaskInfos.find(task => task.id === selectedTaskId) ?? null;
  }, [llmTaskInfos, selectedTaskId]);

  const [cancelTask, { loading: canceling }] = useCancelKnowledgeBaseLlmTask({
    variables: selectedTask
      ? {
          taskId: selectedTask.id,
          where: { id: projectId },
        }
      : undefined,
    onCompleted: onQnaGenerateCancel,
  });
  const generateButtonDisabled =
    node.__typename === 'KnowledgeBaseNodeFile' &&
    node.processState !== gql.scalar('ProcessStateEnum', 'COMPLETED');

  const [CandidatesSlot, candidatesProps] = useSlot('qnaGenerationCandidates', {
    elementType: QnaGenerationCandidates,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });

  useImperativeHandle(
    actions,
    () => ({
      selectTask(taskId) {
        setSelectedTaskId(taskId);
      },
    }),
    [],
  );

  const handleGenerate = () => {
    onQnaGenerate?.(node);
  };

  const handleGenerateCancel = async () => {
    cancelTask();
  };

  const handleTaskChange = (newValue: KnowledgeBaseLLMTaskInfo | null) => {
    setSelectedTaskId(newValue?.id ?? null);
  };

  const getTaskOptionLabel = (option: KnowledgeBaseLLMTaskInfo) => {
    if (option.progress?.__typename !== 'KnowledgeBaseLLMTaskQNAProgress') {
      return '';
    }

    if (option.progress.startedAt === null) {
      return '';
    }

    return [
      intl.formatDate(option.progress.startedAt),
      intl.formatTime(option.progress.startedAt),
    ].join(' ');
  };

  if (node.__typename !== 'KnowledgeBaseNodeFile') {
    return null;
  }

  return (
    <PanelItemGroup>
      <PanelItem
        label={
          <FormattedMessage
            id="FAQ.Documents.KnowledgeBaseList.Doc.right.Tabs.QnAGeneration.Title"
            defaultMessage="Q&A Generation"
            description="Right side title Q&A Generation Tab"
          />
        }
        action={
          selectedTask?.progress?.__typename ===
            'KnowledgeBaseLLMTaskQNAProgress' &&
          (selectedTask.progress.status ===
            gql.scalar('LLMKnowledgeTaskStatus', 'QA_GEN_STARTED') ||
            selectedTask.progress.status ===
              gql.scalar('LLMKnowledgeTaskStatus', 'STARTED')) ? (
            <Tooltip
              title={
                <FormattedMessage
                  id="KnowledgeBaseV2.LLM.Content.Parsing.text.QA_GEN"
                  defaultMessage="QA_GEN cannot be generated while parsing the document"
                  description="KnowledgeBaseV2 LLM content Parsing text for QA_GEN"
                />
              }
              disableFocusListener={generateButtonDisabled}
              disableHoverListener={generateButtonDisabled}
              disableTouchListener={generateButtonDisabled}
            >
              <LoadingButton
                size="small"
                startIcon={<IcStopGenerate />}
                loading={canceling}
                onClick={handleGenerateCancel}
              >
                <FormattedMessage
                  id="KnowledgeBaseV2.LLM.Generate.Cancel.btn.text"
                  defaultMessage="Cancel"
                  description="KnowledgeBaseV2 LLM generate cancel btn text"
                />
              </LoadingButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <FormattedMessage
                  id="KnowledgeBaseV2.LLM.Content.Empty.text.QA_GEN"
                  defaultMessage='Please click "Generate" to generate Q&As from the document.'
                  description="KnowledgeBaseV2 LLM content Parsing text for QA_GEN"
                />
              }
              disableFocusListener={generateButtonDisabled}
              disableHoverListener={generateButtonDisabled}
              disableTouchListener={generateButtonDisabled}
            >
              <div
                css={css`
                  display: inline-flex;
                `}
              >
                <LoadingButton
                  size="small"
                  startIcon={<IcGenerate />}
                  disabled={generateButtonDisabled}
                  onClick={handleGenerate}
                >
                  <FormattedMessage
                    id="KnowledgeBaseV2.LLM.Generate.btn.text"
                    defaultMessage="Generate"
                    description="KnowledgeBaseV2 LLM Generate btn text"
                  />
                </LoadingButton>
              </div>
            </Tooltip>
          )
        }
      >
        <SelectField
          label={null}
          fullWidth
          value={selectedTask}
          onChange={handleTaskChange}
          options={llmTaskInfos}
          SelectProps={{
            menuPortalTarget: document.body,
            menuPlacement: 'top',
            isClearable: true,
            isSearchable: false,
            placeholder: (
              <FormattedMessage
                id="knowledge-base.qna-generation.select.placeholder"
                defaultMessage="Select..."
                description="knowledge base qna generation select placeholder text"
              />
            ),
            getOptionLabel: getTaskOptionLabel,
            getOptionValue(option) {
              return `${option.id}`;
            },
            noOptionsMessage() {
              return (
                <FormattedMessage
                  id="knowledge-base.qna-generation.select.no-option"
                  defaultMessage="No options"
                  description="knowledge base qna generation select no option text"
                />
              );
            },
            formatOptionLabel(option, meta) {
              if (meta.context === 'value') {
                return getTaskOptionLabel(option);
              }

              const label = getTaskOptionLabel(option);

              return (
                <>
                  <ListItemText primary={label} />

                  {option.progress?.__typename ===
                    'KnowledgeBaseLLMTaskQNAProgress' &&
                    (option.progress.status ===
                      gql.scalar('LLMKnowledgeTaskStatus', 'QA_GEN_STARTED') ||
                      option.progress.status ===
                        gql.scalar('LLMKnowledgeTaskStatus', 'STARTED')) && (
                      <ListItemSecondaryText>
                        <CircularProgress size="sm" />
                      </ListItemSecondaryText>
                    )}
                </>
              );
            },
          }}
        />

        {selectedTask?.progress?.__typename ===
          'KnowledgeBaseLLMTaskQNAProgress' &&
          (selectedTask.progress.status ===
            gql.scalar('LLMKnowledgeTaskStatus', 'QA_GEN_STARTED') ||
            selectedTask.progress.status ===
              gql.scalar('LLMKnowledgeTaskStatus', 'STARTED')) && (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                margin-top: 8px;
                min-height: 24px;
              `}
            >
              <CircularProgress size="sm" />

              <FormattedMessage
                id="KnowledgeBaseV2.LLM.Generate.loading.text"
                defaultMessage="Generating..."
                description="KnowledgeBaseV2 LLM Generate loading text"
              />
            </div>
          )}

        {selectedTask && selectedTask.generatedCandidatesCount !== null && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              margin-top: 8px;
            `}
          >
            <Text variant="body14">
              <FormattedMessage
                id="KnowledgeBaseV2.LLM.Content.CandidatesNumber.text"
                defaultMessage="{count} Candidates"
                description="KnowledgeBaseV2 LLM content Candidates number text"
                values={{
                  count: selectedTask.generatedCandidatesCount,
                }}
              />
            </Text>

            <CandidatesSlot nodeId={node.id} {...candidatesProps} />
          </div>
        )}
      </PanelItem>
    </PanelItemGroup>
  );
};
