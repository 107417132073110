import { useEventCallback } from '@allganize/hooks';
import { Divider } from '@allganize/ui-divider';
import { SelectProps, SelectRef } from '@allganize/ui-select';
import { css } from '@emotion/react';
import { forwardRef, Fragment, HTMLAttributes, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { MultiValue } from 'react-select';
import {
  AccessControlAgentSelect,
  AccessControlSelectAgentOption,
} from '../access-control-agent-select/access-control-agent-select';
import { AccessControlListItem } from '../access-control-list-item/access-control-list-item';
import { AccessControlSelectProps } from '../access-control-select/access-control-select-type-map';
import { Scalars } from '../gql';
import { FieldProps } from './access-control-field-types';

interface AccessControlAgentFieldProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>,
    FieldProps<AccessControlSelectAgentOption[]>,
    Pick<AccessControlSelectProps, 'onAgentOptionsLoad'> {
  projectId: string | number;
  SelectProps?: Partial<SelectProps<AccessControlSelectAgentOption, true>>;
}

export const AccessControlAgentField = forwardRef<
  HTMLDivElement,
  AccessControlAgentFieldProps
>((props, ref) => {
  const {
    value,
    onChange,
    disabled,
    projectId,
    SelectProps,
    onAgentOptionsLoad,
    ...others
  } = props;
  const selectRef =
    useRef<SelectRef<AccessControlSelectAgentOption, true>>(null);

  const handleChange = useEventCallback(
    (newValue: MultiValue<AccessControlSelectAgentOption>) => {
      if (!newValue) return;

      if (newValue.length > value.length) {
        onChange([
          ...value,
          ...newValue.filter(nv => !value.find(v => nv.value === v.value)),
        ]);
        return;
      }

      if (newValue.length < value.length) {
        onChange([
          ...value.filter(v => newValue.find(nv => v.value === nv.value)),
        ]);
        return;
      }
    },
  );

  const handleDelete = useEventCallback(
    (value: AccessControlSelectAgentOption) => {
      selectRef.current?.removeValue(value);
    },
  );

  const handlePermissonChange = useEventCallback(
    (accessType: Scalars<'ObjectAccessType'>, agentId: string | number) => {
      onChange(
        value.map(v => {
          if (v.value === agentId) {
            return {
              ...v,
              accessType,
            };
          }

          return v;
        }),
      );
    },
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: hidden;
        padding: 2px;
        margin: -2px;
      `}
      {...others}
      ref={ref}
    >
      <AccessControlAgentSelect
        {...SelectProps}
        value={value}
        ref={selectRef}
        projectId={projectId}
        disabled={disabled}
        onChange={handleChange}
        placeholder={
          <FormattedMessage
            id="access-control.field.agent-select.placeholder"
            defaultMessage="Search Agent"
            description="access control field agent select placeholder"
          />
        }
        controlShouldRenderValue={false}
        isClearable={false}
        onAgentOptionsLoad={onAgentOptionsLoad}
        css={css`
          padding: 0 12px;
        `}
      />
      <div
        css={css`
          padding: 8px 12px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          overflow: auto;
        `}
      >
        {value.map((v, idx) => {
          return (
            <Fragment key={v.value}>
              {idx !== 0 && <Divider />}
              <AccessControlListItem
                name={v.label}
                avatar={v.avatar}
                description={v.description}
                accessType={v.accessType}
                readOnly={v.isMe || v.isAdmin || disabled}
                onPermissionChange={newValue =>
                  handlePermissonChange(newValue, v.value)
                }
                onDelete={() => handleDelete(v)}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
});
