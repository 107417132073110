import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type IndicatorClassKey =
  | 'root'
  | 'sizeSmall'
  | 'sizeMedium'
  | 'colorPrimary'
  | 'colorPrimarySubtle'
  | 'colorPrimaryGhost'
  | 'colorGray'
  | 'colorGraySubtle'
  | 'colorGrayGhost'
  | 'colorSuccess'
  | 'colorSuccessSubtle'
  | 'colorSuccessGhost'
  | 'colorInformation'
  | 'colorInformationSubtle'
  | 'colorInformationGhost'
  | 'colorAlert'
  | 'colorAlertSubtle'
  | 'colorAlertGhost'
  | 'colorError'
  | 'colorErrorSubtle'
  | 'colorErrorGhost'
  | 'icon'
  | 'iconSmall'
  | 'iconMedium'
  | 'label'
  | 'labelSmall'
  | 'labelMedium';
export type IndicatorClasses = Record<IndicatorClassKey, string>;

export const indicatorClasses: IndicatorClasses = generateUtilityClasses(
  'Indicator',
  [
    'root',
    'sizeSmall',
    'sizeMedium',
    'colorPrimary',
    'colorPrimarySubtle',
    'colorPrimaryGhost',
    'colorGray',
    'colorGraySubtle',
    'colorGrayGhost',
    'colorSuccess',
    'colorSuccessSubtle',
    'colorSuccessGhost',
    'colorInformation',
    'colorInformationSubtle',
    'colorInformationGhost',
    'colorAlert',
    'colorAlertSubtle',
    'colorAlertGhost',
    'colorError',
    'colorErrorSubtle',
    'colorErrorGhost',
    'icon',
    'iconSmall',
    'iconMedium',
    'label',
    'labelSmall',
    'labelMedium',
  ],
);
