import { useId } from '@allganize/hooks';
import { ListItemText } from '@allganize/ui-list';
import { Menu, MenuItem } from '@allganize/ui-menu';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import React, { FunctionComponent, useRef, useState } from 'react';
import invariant from 'tiny-invariant';
import { gql, ResultOf } from '../gql';
import { KnowledgeBaseBreadcrumbItem } from '../knowledge-base-breadcrumb-item';
import {
  KNOWLEDGE_BASE_BREADCRUMBS_COLLAPSE_WIDTH,
  KNOWLEDGE_BASE_BREADCRUMBS_SEPARATOR_HORIZONTAL_MARGIN,
  KNOWLEDGE_BASE_BREADCRUMBS_SEPARATOR_WIDTH,
} from './constants';

export const KnowledgeBaseBreadcrumbs_AncestorFragment = gql(`
  fragment KnowledgeBaseBreadcrumbs_AncestorFragment on KnowledgeBaseNode {
    id
    name
  }
`);

export interface RenderAncestorParams {
  ancestor: ResultOf<typeof KnowledgeBaseBreadcrumbs_AncestorFragment>;
  selected: boolean;
  context: 'breadcrumb' | 'menu';
}

const defaultRenderAncestor = ({
  ancestor,
  selected,
  context,
}: RenderAncestorParams) => {
  if (context === 'menu') {
    return (
      <MenuItem key={ancestor.id} component="div" selected={selected}>
        <ListItemText primary={ancestor.name} />
      </MenuItem>
    );
  }

  return (
    <KnowledgeBaseBreadcrumbItem key={ancestor.id} selected={selected}>
      {ancestor.name}
    </KnowledgeBaseBreadcrumbItem>
  );
};

export interface KnowledgeBaseBreadcrumbsProps {
  ancestors: ResultOf<typeof KnowledgeBaseBreadcrumbs_AncestorFragment>[];
  /**
   * The maximum number of breadcrumbs to display before collapsing.
   * Must be greater than 0.
   */
  maxItems: number;
  renderAncestor?(params: RenderAncestorParams): React.ReactNode;
}

export const KnowledgeBaseBreadcrumbs: FunctionComponent<
  KnowledgeBaseBreadcrumbsProps
> = props => {
  const { ancestors, maxItems, renderAncestor = defaultRenderAncestor } = props;
  invariant(maxItems > 0, '`maxItems` must be greater than 0');
  const theme = useTheme();
  const menuTriggerRef = useRef<HTMLButtonElement>(null);
  const menuId = useId();
  const [menuOpen, setMenuOpen] = useState(false);
  const collapsed = ancestors.length > maxItems;
  const invisibleAncestors = collapsed
    ? ancestors.slice(0, ancestors.length - maxItems)
    : [];
  const visibleAncestors = collapsed ? ancestors.slice(-maxItems) : ancestors;

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleExpand = () => {
    setMenuOpen(true);
  };

  return (
    <>
      <Breadcrumbs
        css={css`
          .${breadcrumbsClasses.ol} {
            flex-wrap: nowrap;
            min-height: 32px;
          }

          .${breadcrumbsClasses.li} {
            line-height: 0;
            flex-shrink: 0;
          }

          .${breadcrumbsClasses.separator} {
            text-align: center;
            width: ${KNOWLEDGE_BASE_BREADCRUMBS_SEPARATOR_WIDTH}px;
            margin-left: ${KNOWLEDGE_BASE_BREADCRUMBS_SEPARATOR_HORIZONTAL_MARGIN}px;
            margin-right: ${KNOWLEDGE_BASE_BREADCRUMBS_SEPARATOR_HORIZONTAL_MARGIN}px;
            ${theme.typography.subtitle14}
            color: ${theme.palette.foreground.secondary};
          }
        `}
        maxItems={Number.MAX_SAFE_INTEGER}
        itemsBeforeCollapse={0}
        separator="/"
      >
        {collapsed && (
          <KnowledgeBaseBreadcrumbItem
            css={css`
              width: ${KNOWLEDGE_BASE_BREADCRUMBS_COLLAPSE_WIDTH}px;
            `}
            aria-controls={menuOpen ? menuId : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen}
            onClick={handleExpand}
            ref={menuTriggerRef}
          >
            ...
          </KnowledgeBaseBreadcrumbItem>
        )}

        {visibleAncestors.map((ancestor, i, arr) => {
          const selected = i === arr.length - 1;

          return renderAncestor({ ancestor, selected, context: 'breadcrumb' });
        })}
      </Breadcrumbs>

      <Menu
        id={menuId}
        anchorEl={menuTriggerRef.current}
        open={menuOpen && !!menuTriggerRef.current}
        onClose={closeMenu}
        MenuListProps={{ component: 'div' }}
      >
        {invisibleAncestors.map(ancestor => {
          return renderAncestor({ ancestor, selected: false, context: 'menu' });
        })}
      </Menu>
    </>
  );
};
