import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type UserChatRowClassKey =
  | 'root'
  | 'avatar'
  | 'content'
  | 'alignLeft'
  | 'alignRight';
export type UserChatRowClasses = Record<UserChatRowClassKey, string>;

export const userChatRowClasses: UserChatRowClasses = generateUtilityClasses(
  'UserChatRow',
  ['root', 'avatar', 'content', 'alignLeft', 'alignRight'],
);
