import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import {
  FileList,
  FileListItem,
  FileListItemIcon,
  FileListItemText,
  fileListItemTextClasses,
  FileListProps,
} from '@allganize/ui-file-input';
import { IcClose } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import {
  Controller,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';
import { ContactAgentFormValues } from './use-contact-agent-form';

interface AttachmentListProps extends Pick<FileListProps, 'className'> {
  disabled?: boolean;
  fieldArray: UseFieldArrayReturn<ContactAgentFormValues, 'attachments', 'id'>;
}

export const AttachmentList: FunctionComponent<AttachmentListProps> = props => {
  const { disabled, fieldArray, ...other } = props;
  const theme = useTheme();
  const { control } = useFormContext<ContactAgentFormValues>();
  const { fields, remove } = fieldArray;

  if (fields.length === 0) {
    return null;
  }

  return (
    <FileList disablePadding {...other}>
      {fields.map((item, index) => {
        const handleRemove = () => {
          remove(index);
        };

        return (
          <Controller
            key={item.id}
            control={control}
            name={`attachments.${index}.file`}
            render={({ field, fieldState }) => {
              return (
                <FileListItem
                  secondaryAction={
                    !disabled && (
                      <IconButton edge="end" onClick={handleRemove}>
                        <IcClose />
                      </IconButton>
                    )
                  }
                >
                  <FileListItemIcon />

                  <FileListItemText
                    css={css`
                      .${fileListItemTextClasses.secondary} {
                        color: ${theme.palette.foreground.error};
                      }
                    `}
                    primary={<Truncate clamp={1}>{field.value.name}</Truncate>}
                    primaryTextProps={{ title: field.value.name }}
                    secondary={fieldState.error?.message}
                  />
                </FileListItem>
              );
            }}
          />
        );
      })}
    </FileList>
  );
};
