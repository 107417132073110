import { FunctionComponent, useMemo } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';
import { AccessUserListBase } from './access-user-list-base';

export const AccessCustomerList_AccessUserFilterFragment = gql(`
  fragment AccessCustomerList_AccessUserFilterFragment on AccessUserFilter {
    variableName
    value
    displayValue
  }
`);

export interface AccessCustomerListProps {
  customers: FragmentOf<typeof AccessCustomerList_AccessUserFilterFragment>[];
}

export const AccessCustomerList: FunctionComponent<
  AccessCustomerListProps
> = props => {
  const { customers: customersProp } = props;
  const customers = readFragment(
    AccessCustomerList_AccessUserFilterFragment,
    customersProp,
  );
  const users = useMemo(
    () =>
      customers.map(c => ({
        id: [c.variableName, c.value, c.displayValue].join(','),
        name: c.displayValue,
        avatar: null,
      })),
    [customers],
  );

  return <AccessUserListBase data={users} />;
};
