import { DownloadKnowledgeBaseNodesOptions } from '@allganize/knowledge-base-actions';
import { createContext } from 'react';

interface DocumentsLayoutContextValue {
  documentPreviewThumbnailBase?: string;
  getDownloadKnowledgeBaseNodeOptions(): Promise<
    Pick<DownloadKnowledgeBaseNodesOptions, 'headers' | 'origin'>
  >;
  pdfjs?: {
    cMapUrl?: string;
    cMapPacked?: boolean;
    standardFontDataUrl?: string;
  };
}

export const DocumentsLayoutContext =
  createContext<DocumentsLayoutContextValue>({
    documentPreviewThumbnailBase: 'https://backend.alli.ai/webapi',
    async getDownloadKnowledgeBaseNodeOptions() {
      return {
        origin: 'https://backend.alli.ai',
      };
    },
  });
