import { gql, ResultOf, Scalars } from '../gql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsChatOptionInfoDisabled_ChatFragment = gql(`
  fragment IsChatOptionInfoDisabled_ChatFragment on Chat {
    ...on BotChat {
      __typename
      id
      multipleOption
      reusable
    }

    ...on CarouselChat {
      __typename
      id
    }

    ...on EventChat {
      __typename
    }

    ...on CustomUIChat {
      __typename
    }

    ...on FillSlotRichUXChat {
      __typename
    }
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsChatOptionInfoDisabled_ConversationFragment = gql(`
  fragment IsChatOptionInfoDisabled_ConversationFragment on Conversation {
    state
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsChatOptionInfoDisabled_LastChatFragment = gql(`
  fragment IsChatOptionInfoDisabled_LastChatFragment on Chat {
    ...on AgentChat {
      id
    }

    ...on BotChat {
      id
    }

    ...on CarouselChat {
      id
    }

    ...on EventChat {
      id
    }

    ...on CustomUIChat {
      id
    }

    ...on FillSlotRichUXChat {
      id
    }

    ...on ContactAgentByEmailChat {
      id
    }

    ...on SendFormChat {
      id
    }

    ...on UserChat {
      id
    }
  }
`);

const conversationStates: (Scalars<'ConversationStateEnum'> | undefined)[] = [
  gql.scalar('ConversationStateEnum', 'WAIT_AGENT'),
  gql.scalar('ConversationStateEnum', 'INTERRUPTED'),
  gql.scalar('ConversationStateEnum', 'END_BY_USER'),
  gql.scalar('ConversationStateEnum', 'END_OF_CHATBOT'),
  gql.scalar('ConversationStateEnum', 'END_BY_AGENT'),
  gql.scalar('ConversationStateEnum', 'END_OF_OBSERVER'),
];

export interface IsChatOptionInfoDisabledOptions {
  chat: ResultOf<typeof IsChatOptionInfoDisabled_ChatFragment>;
  conversation?: ResultOf<
    typeof IsChatOptionInfoDisabled_ConversationFragment
  > | null;
  lastChat?: ResultOf<typeof IsChatOptionInfoDisabled_LastChatFragment>;
}

export const isChatOptionInfoDisabled = ({
  chat,
  conversation,
  lastChat,
}: IsChatOptionInfoDisabledOptions): boolean => {
  if (
    chat.__typename === 'EventChat' ||
    chat.__typename === 'CustomUIChat' ||
    chat.__typename === 'FillSlotRichUXChat'
  ) {
    return true;
  }

  // if multiple choice, disable form entirely if it's not the last chat and not reusable
  if (chat.__typename === 'BotChat' && chat.multipleOption) {
    return (
      !(chat.reusable || chat.id === lastChat?.id) ||
      conversationStates.includes(conversation?.state)
    );
  }

  return conversationStates.includes(conversation?.state);
};
