import { DraftInputBase, DraftInputBaseRef } from '@allganize/draft-input';
import { forwardRef } from 'react';
import { RichTextProps } from './rich-text.types';
import { useRichText } from './use-rich-text';

export const RichText = forwardRef<DraftInputBaseRef, RichTextProps>(
  (props, ref) => {
    const { className, onBlur, ...other } = props;
    const { onChange, plugins, value } = useRichText(other);

    if (!value) {
      return null;
    }

    return (
      <DraftInputBase
        className={className}
        disabled
        onChange={onChange}
        plugins={plugins}
        rootProps={{ onBlur }}
        value={value}
        ref={ref}
      />
    );
  },
);
