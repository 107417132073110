import { Checkbox } from '@allganize/ui-checkbox';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@allganize/ui-form';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  USER_ANSWER_FEEDBACK_SELECTIONS,
  UserFeedbackImproverFormValues,
} from './use-user-feedback-improver-form';

export const FeedbackSelectionField: FunctionComponent = () => {
  const { control } = useFormContext<UserFeedbackImproverFormValues>();
  const { field, fieldState } = useController({
    control,
    name: 'feedbackSelection',
  });

  return (
    <FormControl
      component="fieldset"
      fullWidth
      disabled={field.disabled}
      error={fieldState.invalid}
    >
      <FormLabel component="legend">
        <FormattedMessage
          id="user-feedback-improver-form.feedback-selection"
          defaultMessage="What was the issue with the response?"
          description="User Negative Feedback Suggestions title text"
        />
      </FormLabel>

      <FormGroup>
        {USER_ANSWER_FEEDBACK_SELECTIONS.map(option => {
          const checked = field.value.includes(option);

          return (
            <FormControlLabel
              key={option}
              value={option}
              checked={checked}
              disabled={field.disabled}
              control={<Checkbox />}
              onChange={(ev, checked) => {
                field.onChange(
                  checked
                    ? [...field.value, option]
                    : field.value.filter(v => v !== option),
                );
              }}
              label={
                <FormattedMessage
                  id={`UserAnswerFeedbackSelection.${option}`}
                  defaultMessage={option}
                  description={`UserAnswerFeedbackSelection.${option}`}
                />
              }
            />
          );
        })}
      </FormGroup>

      {fieldState.error?.message && (
        <FormHelperText error>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  );
};
