import {
  isLLMAppInformationIconVariant,
  LLMAppInformationIcon,
} from '@allganize/llm-app-icons';
import {
  ButtonBase,
  buttonBaseClasses,
  ButtonBaseProps,
} from '@allganize/ui-button';
import { useTheme } from '@allganize/ui-theme';
import { Text } from '@allganize/ui-text';
import { Truncate } from '@allganize/truncate';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment, ResultOf } from '../gql';
import { LLMAppTypeBadge } from '../llm-app-type-badge/llm-app-type-badge';

export const LLMAppCard__LLMAppFragment = gql(`
  fragment LLMAppCard__LLMAppFragment on LLMApp {
    id
    name
    description
    type
    icon

    campaign {
      id
      popupUrl
    }

    singleActionApp {
      id
      publicToken
    }
  }
`);

export interface LLMAppCardProps extends ButtonBaseProps {
  data: FragmentOf<typeof LLMAppCard__LLMAppFragment>;
  onClickLLMApp?: (llmApp: ResultOf<typeof LLMAppCard__LLMAppFragment>) => void;
}

export const LLMAppCard: FunctionComponent<LLMAppCardProps> = ({
  data,
  onClickLLMApp,
  ...others
}) => {
  const theme = useTheme();
  const node = readFragment(LLMAppCard__LLMAppFragment, data);
  const { icon, name, description, type } = node;
  const iconVariant = isLLMAppInformationIconVariant(icon)
    ? icon
    : 'ic-document-search';

  return (
    <ButtonBase
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
        height: 136px;
        padding: 16px;
        text-align: left;
        border-radius: ${theme.radius.sm}px;
        border: 1px solid ${theme.palette.border.divider.default};
        background-color: ${theme.palette.unstable_background.white};

        &.${buttonBaseClasses.disabled} {
          opacity: 0.5;
        }
      `}
      onClick={() => onClickLLMApp?.(node)}
      {...others}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        `}
      >
        <LLMAppInformationIcon variant={iconVariant} color="primary" />
        <LLMAppTypeBadge type={type ?? gql.scalar('LLMAppType', 'CAMPAIGN')} />
      </div>
      <Truncate clamp={1}>
        <Text
          variant="title16"
          css={css`
            color: ${theme.palette.foreground.default};
          `}
        >
          {name}
        </Text>
      </Truncate>
      <Truncate clamp={2}>
        <Text
          variant="body14"
          css={css`
            color: ${theme.palette.foreground.secondary};
          `}
        >
          {description}
        </Text>
      </Truncate>
    </ButtonBase>
  );
};
