import { createContext } from 'react';
import { noop } from 'lodash-es';

export type TabBase = { type: string; label: string; metadata?: any };

export interface TabWithId<T extends TabBase> {
  id: string;
  type: T['type'];
  label: T['label'];
  metadata?: T extends { metadata: infer U } ? U : never;
}

export interface TabNavigatorContextValue<T extends TabBase> {
  tabs: TabWithId<T>[];
  activeTab: TabWithId<T> | null;
  openTab: (tab: TabBase) => void;
  closeTab: (id: string) => void;
  closeAllTabs: () => void;
}

export const createTabNavigatorContext = <T extends TabBase>() =>
  createContext<TabNavigatorContextValue<T>>({
    tabs: [],
    activeTab: null,
    openTab: noop,
    closeTab: noop,
    closeAllTabs: noop,
  });
