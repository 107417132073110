import {
  convertEdgesToNodes,
  KnowledgeBaseTreeView,
  KnowledgeBaseTreeViewNode,
  KnowledgeBaseTreeViewSkeleton,
} from '@allganize/knowledge-base-tree-view';
import { useEventCallback } from '@allganize/hooks';
import { Scroller } from '@allganize/ui-scroller';
import { css } from '@emotion/react';
import { uniqBy } from 'lodash-es';
import {
  FunctionComponent,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { KnowledgeBaseListEmptyState } from './knowledge-base-list-empty-state';
import {
  SelectedKnowledgeBaseList,
  SelectedKnowledgeBases,
} from './selected-knowledge-base-list';
import { KnowledgeBaseListView } from './knowledge-base-list-view';
import {
  useKnowledgeBaseListQuery,
  UseKnowledgeBaseListQueryOptions,
} from './use-knowledge-base-list-query';

type NodeId = string | number;

export interface KnowledgeBaseListActions {
  loadData(...nodeIds: NodeId[]): Promise<void>;
  refresh(): Promise<void>;
}

export interface KnowledgeBaseListProps
  extends UseKnowledgeBaseListQueryOptions {
  className?: string;
  viewType?: 'list' | 'tree';
  actions?: React.Ref<KnowledgeBaseListActions>;
  onSelectedIdsChange?: (ids: NodeId[]) => void;
}

export const KnowledgeBaseList: FunctionComponent<KnowledgeBaseListProps> = ({
  className,
  viewType = 'list',
  actions,
  projectId,
  ownership,
  searchTerm,
  onSelectedIdsChange,
}) => {
  const [selectedNodes, setSelectedNodes] = useState<SelectedKnowledgeBases>(
    [],
  );
  const [expandedIds, setExpandedIds] = useState<NodeId[]>([]);

  const selectedIds = useMemo(
    () => selectedNodes.map(node => node.id),
    [selectedNodes],
  );

  useEffect(() => {
    onSelectedIdsChange?.(selectedIds);
  }, [selectedIds]);

  const handleSelectedNodeChange = (
    node: KnowledgeBaseTreeViewNode,
    isSelected: boolean,
  ) => {
    if (node === null) {
      return;
    }
    if (isSelected) {
      setSelectedNodes(prev => uniqBy([...prev, node], 'id'));
    } else {
      setSelectedNodes(prev => prev.filter(prev => prev.id !== node.id));
    }
  };

  const handleDeleteSelectedNode = (id: string | number) => {
    setSelectedNodes(prev => prev.filter(node => node.id !== id));
  };

  const handleLoadData = useEventCallback(async (...nodeIds: NodeId[]) => {
    await loadNextData({
      parent: nodeIds,
    });
  });

  const { data, initialDataCount, loading, loadNextData, refresh } =
    useKnowledgeBaseListQuery({
      projectId,
      ownership,
      searchTerm,
    });

  const nodes = useMemo(() => {
    if (!data?.knowledgeBaseNodes?.edges) return []; //?
    return (
      convertEdgesToNodes(data?.knowledgeBaseNodes?.edges, edge => {
        return edge;
      }) ?? []
    );
  }, [data]);

  useImperativeHandle(actions, () => ({ loadData: handleLoadData, refresh }), [
    handleLoadData,
    refresh,
  ]);

  const status = useMemo(() => {
    if (loading && !data?.knowledgeBaseNodes?.edges) {
      return 'loading';
    }
    if (
      !data?.knowledgeBaseNodes?.edges ||
      data.knowledgeBaseNodes.edges.length === 0
    ) {
      return 'empty';
    }
    return 'result';
  }, [data?.knowledgeBaseNodes?.edges, loading]);

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 12px;
      `}
    >
      <SelectedKnowledgeBaseList
        data={selectedNodes}
        initialDataCount={initialDataCount ?? 0}
        onDelete={handleDeleteSelectedNode}
        loading={status === 'loading'}
      />

      {status === 'loading' && <KnowledgeBaseTreeViewSkeleton count={5} />}

      {status === 'empty' && (
        <KnowledgeBaseListEmptyState isSearch={!!searchTerm} />
      )}

      {status === 'result' && (
        <Scroller
          forceVisible="y"
          css={css`
            flex-grow: 1;
            min-height: 0;
            padding-bottom: 12px;
          `}
        >
          {viewType === 'list' && (
            <KnowledgeBaseListView
              data={nodes}
              selectedIds={selectedIds}
              showOwnershipIcon={!!searchTerm}
              onSelectedNodeChange={handleSelectedNodeChange}
            />
          )}

          {viewType === 'tree' && (
            <KnowledgeBaseTreeView
              data={nodes}
              expandedIds={expandedIds}
              selectedIds={selectedIds}
              onLoadData={handleLoadData}
              onExpandedIdsChange={setExpandedIds}
              onSelectedNodeChange={handleSelectedNodeChange}
              hideRootNode
              selectable
            />
          )}
        </Scroller>
      )}
    </div>
  );
};
