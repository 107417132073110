import { useId } from '@allganize/hooks';
import { Alert } from '@allganize/ui-alert';
import {
  Button,
  IconButton,
  IconButtonProps,
  LoadingButton,
} from '@allganize/ui-button';
import {
  Dialog,
  DialogActions,
  DialogCloseButton,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@allganize/ui-dialog';
import { FormHelperText } from '@allganize/ui-form';
import { IcInfo, IcOpenInNew } from '@allganize/ui-icons';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import useSlot from '@mui/material/utils/useSlot';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment, Scalars } from '../gql';
import { FilesField, FilesFieldProps } from './files-field';
import { FilesFieldList, FilesFieldListProps } from './files-field-list';
import { ImagePdfField } from './image-pdf-field';
import {
  useFileSelectionForm,
  UseFileSelectionFormOptions,
} from './use-file-selection-form';

export const FileSelectionForm_ProjectFragment = gql(`
  fragment FileSelectionForm_ProjectFragment on Project {
    id
    pdfSupport

    limitation {
      maxKbFileSize
      maxKbZipFileSize
    }
  }
`);

interface FileSelectionFormSlots {
  /**
   * @default IconButton
   */
  retrieverPerformanceLink?: React.ElementType;
}

type FileSelectionFormSlotsAndSlotProps = CreateSlotsAndSlotProps<
  FileSelectionFormSlots,
  {
    retrieverPerformanceLink: SlotProps<
      React.ElementType<IconButtonProps>,
      {},
      {}
    >;
  }
>;

export interface FileSelectionFormProps
  extends Pick<UseFileSelectionFormOptions, 'defaultValues' | 'onSubmit'>,
    FileSelectionFormSlotsAndSlotProps,
    Pick<
      DialogProps,
      'open' | 'fullScreen' | 'fullWidth' | 'maxWidth' | 'onClose'
    >,
    Pick<FilesFieldProps, 'accessControl'>,
    Pick<FilesFieldListProps, 'onEdit'> {
  ownership: Scalars<'KnowledgeBaseNodeOwnership'>;
  project: FragmentOf<typeof FileSelectionForm_ProjectFragment>;
}

export const FileSelectionForm: FunctionComponent<
  FileSelectionFormProps
> = props => {
  const {
    defaultValues,
    onClose,
    onSubmit,
    ownership,
    project: projectProp,
    accessControl,
    slotProps = {},
    slots = {},
    onEdit,
    ...other
  } = props;
  const project = readFragment(FileSelectionForm_ProjectFragment, projectProp);
  const titleId = useId();
  const accept = useMemo(() => {
    const result: UseFileSelectionFormOptions['accept'] = [];

    if (project.pdfSupport) {
      result.push('pdf');
    }

    if (ownership !== gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL')) {
      result.push('zip');
    }

    return result;
  }, [ownership, project.pdfSupport]);

  const [RetrieverPerformanceLinkSlot, retrieverPerformanceLinkProps] = useSlot(
    'retrieverPerformanceLink',
    {
      elementType: IconButton,
      // @ts-expect-error internal prop
      externalForwardedProps: { slots, slotProps },
      ownerState: {},
      className: undefined,
    },
  );

  const validationOptions = useMemo(() => {
    return {
      files: {
        maxSize: project.limitation?.maxKbFileSize ?? undefined,
        maxZipSize: project.limitation?.maxKbZipFileSize ?? undefined,
      },
    };
  }, [project.limitation?.maxKbFileSize, project.limitation?.maxKbZipFileSize]);

  const { files, form, submit } = useFileSelectionForm({
    accept,
    defaultValues,
    onSubmit,
    validation: validationOptions,
  });
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    reset,
  } = form;
  const [hasFiles, setHasFiles] = useState(false);
  const [hasPdfFiles, setHasPdfFiles] = useState(false);
  const showImagePdfField =
    hasFiles &&
    hasPdfFiles &&
    ownership !== gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL');
  const showRetrieverAlert =
    hasFiles &&
    ownership !== gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL');
  const filesFieldArray = useFieldArray({ control, name: 'files' });

  useEffect(() => {
    const files = filesFieldArray.fields;
    setHasFiles(files.length > 0);
    setHasPdfFiles(files.some(file => file.file.type === 'application/pdf'));
  }, [filesFieldArray.fields]);

  const handleClose = () => {
    onClose?.({}, 'closeButtonClick');
    reset();
  };

  return (
    <Dialog aria-labelledby={titleId} onClose={handleClose} {...other}>
      <form onSubmit={handleSubmit(submit)}>
        <DialogTitle id={titleId} padding="close-button">
          <FormattedMessage
            id="FAQ.Documents.UploadForm"
            defaultMessage="Create new document"
            description="FAQ documents upload form title text"
          />

          <DialogCloseButton disabled={isSubmitting} />
        </DialogTitle>

        <DialogContent>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 20px;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
              `}
            >
              <FormProvider {...form}>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                  `}
                >
                  <FilesField
                    accept={files.accept}
                    accessControl={accessControl}
                    maxSize={
                      Math.max(
                        validationOptions.files.maxSize ?? 0,
                        validationOptions.files.maxZipSize ?? 0,
                      ) || undefined
                    }
                    onAppend={filesFieldArray.append}
                  />

                  <FilesFieldList files={filesFieldArray} onEdit={onEdit} />
                </div>

                {showImagePdfField && <ImagePdfField />}
              </FormProvider>
            </div>

            {showRetrieverAlert && (
              <Alert
                icon={<IcInfo />}
                color="info"
                action={
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="FAQ.Documents.UploadForm.RetrieverOptimization.Tooltip"
                        defaultMessage="Go to retriever performance page"
                        description="documents upload form retriever performance recommendation tooltip"
                      />
                    }
                  >
                    <RetrieverPerformanceLinkSlot
                      shape="rounded"
                      size="small"
                      {...retrieverPerformanceLinkProps}
                    >
                      <IcOpenInNew />
                    </RetrieverPerformanceLinkSlot>
                  </Tooltip>
                }
              >
                <FormattedMessage
                  id="FAQ.Documents.UploadForm.RetrieverOptimization.Alert"
                  defaultMessage="업로드한 문서를 활용하여 검색(RAG) 품질을 향상시킬 수 있습니다.{br}문서 업로드 후 retriever performance page에서 실행할 수 있습니다."
                  description="documents upload form retrieve performance recommendation alert"
                  values={{
                    br: <br />,
                  }}
                />
              </Alert>
            )}
          </div>

          {errors.root?.message && (
            <FormHelperText error>{errors.root.message}</FormHelperText>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            disabled={isSubmitting}
            onClick={handleClose}
          >
            <FormattedMessage
              id="actions.cancel"
              defaultMessage="Cancel"
              description="FAQ documents upload form cancel button text"
            />
          </Button>

          <LoadingButton
            variant="filled"
            size="large"
            color="primary"
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
          >
            <FormattedMessage
              id="actions.upload"
              defaultMessage="Upload"
              description="FAQ documents upload form submit button text"
            />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
