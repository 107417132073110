import {
  ChatCarousel,
  ChatCarouselItem,
  chatCarouselItemClasses,
  ChatCarouselProps,
} from '@allganize/alli-chat-base';
import { carouselClasses } from '@allganize/ui-carousel';
import { css } from '@emotion/react';
import { CreateSlotsAndSlotProps } from '@mui/material/utils/types';
import { forwardRef } from 'react';
import {
  ChatMrcInfoListItem,
  ChatMrcInfoListItem_ChatMRCInfoFragment,
  ChatMrcInfoListItemSlotProps,
  ChatMrcInfoListItemSlots,
} from '../chat-mrc-info-list-item';
import { FragmentOf, gql, readFragment } from '../gql';

export const ChatMrcInfoList_CarouselChatFragment = gql(
  `
  fragment ChatMrcInfoList_CarouselChatFragment on CarouselChat {
    id

    chatMrcInfos {
      id
      ...ChatMrcInfoListItem_ChatMRCInfoFragment
    }
  }
`,
  [ChatMrcInfoListItem_ChatMRCInfoFragment],
);

export interface ChatMrcInfoListSlots extends ChatMrcInfoListItemSlots {}

export interface ChatMrcInfoListSlotProps
  extends ChatMrcInfoListItemSlotProps {}

export type ChatMrcInfoListSlotsAndSlotProps = CreateSlotsAndSlotProps<
  ChatMrcInfoListSlots,
  ChatMrcInfoListSlotProps
>;

export interface ChatMrcInfoListProps
  extends ChatMrcInfoListSlotsAndSlotProps,
    Pick<ChatCarouselProps, 'className'> {
  chat: FragmentOf<typeof ChatMrcInfoList_CarouselChatFragment>;
}

export const ChatMrcInfoList = forwardRef<HTMLDivElement, ChatMrcInfoListProps>(
  (props, ref) => {
    const { chat: chatProp, className, slotProps, slots } = props;
    const chat = readFragment(ChatMrcInfoList_CarouselChatFragment, chatProp);

    return (
      <ChatCarousel
        data-testid="chat-mrc-info-list"
        css={css`
          .${carouselClasses.slide} > * {
            height: 100%;
          }
        `}
        className={className}
        ref={ref}
      >
        {chat.chatMrcInfos?.map(mrcInfo => {
          if (!mrcInfo) {
            return null;
          }

          return (
            <ChatCarouselItem
              key={mrcInfo.id}
              css={css`
                height: 100%;
              `}
            >
              <ChatMrcInfoListItem
                css={css`
                  height: 100%;

                  .${chatCarouselItemClasses.hasCarouselHeightBug} & {
                    height: auto;
                    flex-grow: 1;
                  }
                `}
                mrcInfo={mrcInfo}
                slotProps={slotProps}
                slots={slots}
              />
            </ChatCarouselItem>
          );
        })}
      </ChatCarousel>
    );
  },
);
