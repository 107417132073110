import { Fragment, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment, ResultOf } from '../gql';

export const EventChatMessage_EventChatFragment = gql(`
  fragment EventChatMessage_EventChatFragment on EventChat {
    eventType
    eventAdditionalInfo
    eventFallbackMessage

    enteringAgent {
      id
      displayName
      firstName
      lastName
    }
  }
`);

interface EventChatMessageProps {
  chat:
    | FragmentOf<typeof EventChatMessage_EventChatFragment>
    | ResultOf<typeof EventChatMessage_EventChatFragment>;
}

export const EventChatMessage: FunctionComponent<
  EventChatMessageProps
> = props => {
  const { chat: chatProp } = props;
  const chat = readFragment(EventChatMessage_EventChatFragment, chatProp);

  if (
    chat.eventType === gql.scalar('ChatEventEnum', 'AGENT_ENTER') &&
    chat.enteringAgent
  ) {
    return (
      <FormattedMessage
        id="event-chat-message.agent-enter"
        defaultMessage="Agent {displayName} joined this conversation."
        description="Conversation agent entrance text"
        values={{
          displayName:
            chat.enteringAgent.displayName ||
            chat.enteringAgent.firstName ||
            chat.enteringAgent.lastName,
        }}
      />
    );
  }

  if (
    chat.eventType === gql.scalar('ChatEventEnum', 'SEND_CONVERSATION_TO_EMAIL')
  ) {
    const eventAdditionalInfo = JSON.parse(chat.eventAdditionalInfo || '{}');

    if (eventAdditionalInfo.email) {
      return (
        <FormattedMessage
          id="event-chat-message.send-conversation-to-email"
          defaultMessage="User sent transcript to {email}"
          description="Conversation user get transcript text"
          values={{
            email: eventAdditionalInfo.email,
          }}
        />
      );
    }
  }

  return <Fragment>{chat.eventFallbackMessage}</Fragment>;
};
