import {
  AccessControlSelectAgentOption,
  AccessControlSelectUserOption,
  parseAccessAgent,
  parseAccessUserFilter,
} from '@allganize/access-control';
import {
  AccessUserFilterEnum,
  ObjectAccessType,
} from '@allganize/data-access-alli-enums';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { useMutation } from '@apollo/client/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';
import { gql, Scalars } from '../gql';
import {
  KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment,
  UseAccessControlReturnType,
  useAccessControlValue,
} from '../hooks/use-access-control';

export interface EditFolderFormValues {
  name: string;
  accessControl: {
    isOverwriteFolderAccess: boolean;
    accessAgents: AccessControlSelectAgentOption[];
    viewAccessToAgent: boolean;
    accessUserFilters: AccessControlSelectUserOption[];
    viewAccessToUser: boolean;
  };
}

type EditFolderFormErrorField = FieldPath<EditFolderFormValues> | 'root';
const editFolderFormErrorFields: EditFolderFormErrorField[] = ['name', 'root'];

const isEditFolderFormErrorField = (
  field: any,
): field is EditFolderFormErrorField =>
  editFolderFormErrorFields.includes(field);

const errorMessages = defineMessages({
  'name-required': {
    id: 'Errors.folderName-required',
    defaultMessage: 'Folder name is a required field',
    description: 'Folder name required error message',
  },
  'name-max-length': {
    id: 'Errors.folderName-max',
    defaultMessage: 'Folder name should be less than 50 characters',
    description: 'Folder name length less than 50 message',
  },
});

const createValidationSchema = (intl: IntlShape) => {
  return z.object({
    name: z
      .string({
        required_error: intl.formatMessage(errorMessages['name-required']),
        invalid_type_error: intl.formatMessage(errorMessages['name-required']),
      })
      .min(1, intl.formatMessage(errorMessages['name-required']))
      .max(50, intl.formatMessage(errorMessages['name-max-length'])),
    accessControl: z.object({
      isOverwriteFolderAccess: z.boolean(),
      accessAgents: z.array(
        z
          .object({
            accessType: z.nativeEnum(ObjectAccessType),
            value: z.union([z.string(), z.number()]),
            label: z.string().nullable(),
            description: z.string().nullable(),
          })
          .passthrough(),
      ),
      viewAccessToAgent: z.boolean(),
      accessUserFilters: z.array(
        z
          .object({
            type: z.nativeEnum(AccessUserFilterEnum),
            value: z.union([z.string(), z.number()]),
            label: z.string().nullable(),
            description: z.string().nullable(),
          })
          .passthrough(),
      ),
      viewAccessToUser: z.boolean(),
    }),
  }) satisfies z.ZodSchema<EditFolderFormValues>;
};

const UpdateFolderMutation = gql(
  `
  mutation UpdateFolderMutation(
    $input: FolderInput!
    $targetFolderId: ID!
    $where: ProjectWhereUniqueInput!
  ) {
    updateFolder(
      input: $input
      targetFolderId: $targetFolderId
      where: $where
    ) {
      updatedNode {
        id
        name
        ...KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment
      }

      errors {
        __typename
        key
        message
        field
        info
      }
    }
  }
`,
  [KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment],
);

export interface UseEditFolderFormOptions {
  onSubmit?(nodeId: Scalars<'ID'>): void;
  parentAccessControl?: UseAccessControlReturnType;
  folderId: Scalars<'ID'> | null;
  projectId: Scalars<'ID'>;
  defaultValues?: EditFolderFormValues;
}

export const useEditFolderForm = ({
  onSubmit,
  parentAccessControl: parentAccessControlProp,
  folderId,
  projectId,
  defaultValues,
}: UseEditFolderFormOptions) => {
  const intl = useIntl();
  const validationSchema = useMemo(() => createValidationSchema(intl), [intl]);
  const [mutate] = useMutation(UpdateFolderMutation);
  const parentAccessControl = useAccessControlValue(parentAccessControlProp);

  const formatErrorMessage = useFormatErrorMessage();

  const form = useForm<EditFolderFormValues>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const { reset, setError, watch, setValue } = form;

  const isOverwriteFolderAccess = watch(
    'accessControl.isOverwriteFolderAccess',
  );

  useEffect(() => {
    if (isOverwriteFolderAccess) {
      setValue(
        'accessControl.viewAccessToAgent',
        !!parentAccessControl?.viewAccessToAgent,
      );
      setValue(
        'accessControl.accessAgents',
        (parentAccessControl?.accessAgents || []).reduce<
          AccessControlSelectAgentOption[]
        >((acc, agent) => {
          const parsedAgent = parseAccessAgent(agent);
          return parsedAgent ? [...acc, parsedAgent] : acc;
        }, []),
      );
      setValue(
        'accessControl.accessUserFilters',
        (
          parentAccessControl?.accessUserFilters || []
        ).map<AccessControlSelectUserOption>(user =>
          parseAccessUserFilter(user, intl),
        ),
      );
      setValue(
        'accessControl.viewAccessToUser',
        !!parentAccessControl?.viewAccessToUser,
      );
    }
  }, [isOverwriteFolderAccess, parentAccessControl, intl, setValue]);

  const submit = async (values: EditFolderFormValues) => {
    if (folderId === null) {
      return;
    }

    try {
      const result = await mutate({
        variables: {
          where: { id: projectId },
          targetFolderId: folderId,
          input: {
            name: values.name,
            folderType: gql.scalar('FolderType', 'DOC'),
            isOverwriteFolderAccess:
              values.accessControl.isOverwriteFolderAccess,
            accessAgents: values.accessControl.accessAgents.map(agent => ({
              agentId: agent.value,
              accessType: agent.accessType,
            })),
            viewAccessToAgent: values.accessControl.viewAccessToAgent,
            accessUserFilters: values.accessControl.accessUserFilters.map(
              user => ({
                variableName: user.type,
                value: `${user.value}`,
              }),
            ),
            viewAccessToUser: values.accessControl.viewAccessToUser,
          },
        },
      });

      if (result.data?.updateFolder?.updatedNode) {
        onSubmit?.(result.data.updateFolder.updatedNode.id);
        reset();
        return;
      }

      if (result.data?.updateFolder?.errors?.length) {
        throw new AlliGraphQLError(result.data.updateFolder.errors);
      }

      throw new Error(formatErrorMessage());
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const errorGroups = err.groupErrorsByField({
          intl,
          isErrorField: isEditFolderFormErrorField,
        });

        // Set the errors in the form using setError
        Object.entries(errorGroups).forEach(([field, errors]) => {
          if (!errors) {
            return;
          }

          setError(field as EditFolderFormErrorField, {
            message: errors[0]?.message,
          });
        });

        return;
      }

      if (err instanceof Error) {
        setError('root', {
          message: formatErrorMessage(err),
        });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: formatErrorMessage(),
      });
    }
  };

  // Reset the form when the default values change
  useEffect(() => {
    reset(defaultValues, {
      keepIsSubmitSuccessful: true,
      keepIsSubmitted: true,
      keepSubmitCount: true,
    });
  }, [defaultValues, reset]);

  return {
    form,
    submit,
  };
};
