import { useWindow } from '@allganize/hooks';
import { FormControl, FormLabel } from '@allganize/ui-form';
import { SelectField } from '@allganize/ui-select';
import { Text } from '@allganize/ui-text';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormValueFormValues } from './use-form-value-form';

interface SingleSelectFormValueFieldProps {
  disabled?: boolean;
  name: `formValues.${number}`;
}

export const SingleSelectFormValueField: FunctionComponent<
  SingleSelectFormValueFieldProps
> = props => {
  const { disabled: disabledProp, name } = props;
  const { ref: rootRef, window: win } = useWindow();
  const { control } = useFormContext<FormValueFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name,
    disabled: disabledProp,
  });
  const {
    field: { ref: valueRef, ...value },
    fieldState: valueState,
  } = useController({
    control,
    name: `${name}.value`,
    disabled: disabledProp,
  });
  const disabled = field.disabled || formState.disabled;

  if (field.value.type !== 'single-select') {
    return null;
  }

  if (disabled) {
    return (
      <FormControl fullWidth required={field.value.required}>
        <FormLabel>{field.value.label}</FormLabel>

        <Text variant="body14">{field.value.value?.label}</Text>
      </FormControl>
    );
  }

  return (
    <SelectField
      {...value}
      selectRef={valueRef}
      label={field.value.label}
      required={field.value.required}
      options={field.value.options}
      error={fieldState.invalid || valueState.invalid}
      helperText={fieldState.error?.message || valueState.error?.message}
      fullWidth
      placeholder={
        <FormattedMessage
          id="select.placeholder"
          defaultMessage="Select..."
          description="Select placeholder"
        />
      }
      ref={rootRef}
      SelectProps={{ menuPortalTarget: win?.document.body }}
    />
  );
};
