import {
  ChatStackItem,
  TimestampTooltip,
  UserChatRow,
  UserChatRowProps,
} from '@allganize/alli-chat-base';
import { ChatFile } from '@allganize/alli-chat-content';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

export const UserChatFile_ChatFragment = gql(`
  fragment UserChatFile_ChatFragment on Chat {
    ...on UserChat {
      __typename
      createdAt

      file {
        id
        fileId
        metaInfo
        url
        createdAt
        modifiedAt
      }
    }
  }
`);

export interface UserChatFileProps extends Pick<UserChatRowProps, 'avatar'> {
  align?: 'left' | 'right';
  chat: FragmentOf<typeof UserChatFile_ChatFragment>;
}

export const UserChatFile: FunctionComponent<UserChatFileProps> = props => {
  const { align = 'right', avatar, chat: chatProp } = props;
  const chat = readFragment(UserChatFile_ChatFragment, chatProp);

  if (chat.__typename !== 'UserChat') {
    return null;
  }

  if (!chat.file) {
    return null;
  }

  return (
    <ChatStackItem>
      <TimestampTooltip align={align} timestamp={chat.createdAt}>
        <UserChatRow align={align} avatar={avatar}>
          <ChatFile file={chat.file} />
        </UserChatRow>
      </TimestampTooltip>
    </ChatStackItem>
  );
};
