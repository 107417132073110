import {
  AlliChatRowSkeleton,
  ChatStack,
  ChatStackItem,
  UserChatRowSkeleton,
} from '@allganize/alli-chat-base';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';

interface ChatListLoadingStateProps {
  count?: number;
}

export const ChatListLoadingState: FunctionComponent<
  ChatListLoadingStateProps
> = ({ count = 4 }) => {
  return Array.from({ length: count }).map((_, index) => {
    const isEven = index % 2 === 0;

    return (
      <div
        css={css`
          margin: 16px 0;
        `}
        key={index}
      >
        <ChatStack>
          <ChatStackItem disablePadding={isEven}>
            {isEven ? (
              <AlliChatRowSkeleton />
            ) : (
              <UserChatRowSkeleton align="right" />
            )}
          </ChatStackItem>
        </ChatStack>
      </div>
    );
  });
};
