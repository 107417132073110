import { IframeContext } from '@allganize/react-iframe';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { autoUpdate } from '@floating-ui/dom';
import clsx from 'clsx';
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { GroupBase } from 'react-select';
import { MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu';
import { coercePlacement } from '../utils/coerce-placement';
import { getBoundingClientObject } from '../utils/get-bounding-client-object';
import { PortalPlacementContext } from './portal-placement-context';
import { selectClasses } from './select-classes';

interface ComputedPosition {
  offset: number;
  rect: { left: number; width: number };
}

export const MenuPortal = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: MenuPortalProps<Option, IsMulti, Group>,
) => {
  const {
    appendTo,
    children,
    className,
    controlElement,
    getClassNames,
    innerProps,
    menuPlacement,
    menuPosition,
  } = props;
  const theme = useTheme();
  const { window: contentWindow } = useContext(IframeContext);

  const menuPortalRef = useRef<HTMLDivElement | null>(null);
  const cleanupRef = useRef<(() => void) | void | null>(null);

  const [placement, setPortalPlacement] = useState<'bottom' | 'top'>(
    coercePlacement(menuPlacement),
  );
  const portalPlacementContext = useMemo(
    () => ({
      setPortalPlacement,
    }),
    [],
  );
  const [computedPosition, setComputedPosition] =
    useState<ComputedPosition | null>(null);

  const updateComputedPosition = useCallback(() => {
    if (!controlElement) return;

    const rect = getBoundingClientObject(controlElement);
    const scrollDistance =
      menuPosition === 'fixed' ? 0 : contentWindow.pageYOffset;
    const offset = rect[placement] + scrollDistance;
    if (
      offset !== computedPosition?.offset ||
      rect.left !== computedPosition?.rect.left ||
      rect.width !== computedPosition?.rect.width
    ) {
      setComputedPosition({ offset, rect });
    }
  }, [
    contentWindow,
    controlElement,
    menuPosition,
    placement,
    computedPosition?.offset,
    computedPosition?.rect.left,
    computedPosition?.rect.width,
  ]);

  useLayoutEffect(() => {
    updateComputedPosition();
  }, [updateComputedPosition]);

  const runAutoUpdate = useCallback(() => {
    if (typeof cleanupRef.current === 'function') {
      cleanupRef.current();
      cleanupRef.current = null;
    }

    if (controlElement && menuPortalRef.current) {
      cleanupRef.current = autoUpdate(
        controlElement,
        menuPortalRef.current,
        updateComputedPosition,
        { elementResize: 'ResizeObserver' in window },
      );
    }
  }, [controlElement, updateComputedPosition]);

  useLayoutEffect(() => {
    runAutoUpdate();
  }, [runAutoUpdate]);

  const setMenuPortalElement = useCallback(
    (menuPortalElement: HTMLDivElement) => {
      menuPortalRef.current = menuPortalElement;
      runAutoUpdate();
    },
    [runAutoUpdate],
  );

  // bail early if required elements aren't present
  if ((!appendTo && menuPosition !== 'fixed') || !computedPosition) return null;

  // same wrapper element whether fixed or portalled
  const menuWrapper = (
    <div
      css={css`
        position: ${menuPosition};
        z-index: ${theme.zIndex.modal};
      `}
      ref={setMenuPortalElement}
      {...innerProps}
      className={clsx(
        selectClasses.menuPortal,
        getClassNames('menuPortal', {
          ...props,
          offset: computedPosition.offset,
          position: menuPosition,
          rect: computedPosition.rect,
        }),
        className,
        innerProps?.className,
      )}
      style={{
        left: computedPosition.rect.left,
        top: computedPosition.offset,
        width: computedPosition.rect.width,
        ...innerProps?.style,
      }}
    >
      {children}
    </div>
  );

  return (
    <PortalPlacementContext.Provider value={portalPlacementContext}>
      {appendTo ? createPortal(menuWrapper, appendTo) : menuWrapper}
    </PortalPlacementContext.Provider>
  );
};
