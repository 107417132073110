import { filesize } from 'filesize';
import { noop } from 'lodash-es';
import { createContext } from 'react';
import { gql, ResultOf, Scalars } from '../gql';

export const MAX_KB_FILE_SIZE = 50 * 1048576;
export const maxFileSize = filesize(MAX_KB_FILE_SIZE, { output: 'string' });

export const SingleActionInput_KnowledgeBaseFragment = gql(`
  fragment SingleActionInput_KnowledgeBaseFragment on KnowledgeBase {
    __typename

    ... on GoogleDocsKnowledgeBase {
      id
      title
    }

    ... on TextKnowledgeBase {
      id
      title
      processState
    }

    ... on ImageKnowledgeBase {
      id
      title
      processState
    }

    ... on MSDocsKnowledgeBase {
      id
      title
      processState
    }

    ... on PDFKnowledgeBase {
      id
      title
      processState
    }

    ... on MSExcelKnowledgeBase {
      id
      title
      processState
    }

    ... on MSPPTKnowledgeBase {
      id
      title
      processState
    }

    ... on OldMSDocsKnowledgeBase {
      id
      title
      processState
    }

    ... on CSVKnowledgeBase {
      id
      title
      processState
    }

    ... on HWPKnowledgeBase {
      id
      title
      processState
    }
  }
`);

export type KnowledgeBase = {
  loading: boolean;
  error: React.ReactNode;
} & ResultOf<typeof SingleActionInput_KnowledgeBaseFragment>;

export type KnowledgeBaseList = Record<Scalars<'ID'>, KnowledgeBase>;

export type UploadBlock = {
  docLimit: number;
  docNum: number;
};

interface UploadFile {
  knowledgeBases: KnowledgeBaseList;
  uploadBlock: UploadBlock | null;
  uploadFile: (id: Scalars<'ID'>, file: File) => void;
  deleteFile: (id: Scalars<'ID'>) => void;
  setError: (id: Scalars<'ID'>, error: React.ReactNode) => void;
  closeUploadBlockDialog: () => void;
}

export const UploadFileContext = createContext<UploadFile>({
  knowledgeBases: {},
  uploadBlock: null,
  uploadFile: noop,
  deleteFile: noop,
  setError: noop,
  closeUploadBlockDialog: noop,
});
