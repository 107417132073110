import { IconButton } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import {
  IcDelete,
  IcDownload,
  IcEdit,
  IcFolderMove,
} from '@allganize/ui-icons';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment, ResultOf, Scalars } from '../gql';

export const SidePanelActions_KnowledgeBaseNodeFragment = gql(`
  fragment SidePanelActions_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    __typename
    id
    name
    ownership
    ancestors {
      id
    }
    accessAgents {
      accessType
      agent {
        id
        isMe
      }
    }
  }
`);

export interface SidePanelActionsProps {
  isEditable?(
    node: ResultOf<typeof SidePanelActions_KnowledgeBaseNodeFragment>,
  ): boolean;
  node: FragmentOf<typeof SidePanelActions_KnowledgeBaseNodeFragment>;
  onDelete?(nodeId: Scalars<'ID'>): Promise<void>;
  onDownload?(
    node: ResultOf<typeof SidePanelActions_KnowledgeBaseNodeFragment>,
  ): Promise<void>;
  onEdit?(
    node: ResultOf<typeof SidePanelActions_KnowledgeBaseNodeFragment>,
  ): void;
  onMove?(
    node: ResultOf<typeof SidePanelActions_KnowledgeBaseNodeFragment>,
  ): void;
}

export const SidePanelActions: FunctionComponent<
  SidePanelActionsProps
> = props => {
  const {
    isEditable = () => true,
    node: nodeProp,
    onDelete,
    onDownload,
    onEdit,
    onMove,
  } = props;
  const node = readFragment(
    SidePanelActions_KnowledgeBaseNodeFragment,
    nodeProp,
  );
  const [deleting, setDeleting] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const isEditableNode = isEditable(node);
  const canEdit = !!onEdit && isEditableNode;
  const canDownload =
    !!onDownload && node.__typename !== 'KnowledgeBaseNodeFolder';
  const canDelete = !!onDelete && isEditableNode;
  const canMove = !!onMove && isEditableNode;

  const handleDelete = async () => {
    setDeleting(true);

    try {
      await onDelete?.(node.id);
    } finally {
      setDeleting(false);
    }
  };

  const handleDownload = async () => {
    setDownloading(true);

    try {
      await onDownload?.(node);
    } finally {
      setDownloading(false);
    }
  };

  const handleEdit = () => {
    onEdit?.(node);
  };

  const handleMove = () => {
    onMove?.(node);
  };

  if (!canEdit && !canDownload && !canDelete && !canMove) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        margin: 16px 0;
      `}
    >
      {canEdit && (
        <Tooltip
          title={
            <FormattedMessage
              id="knowledge-base-side-panel.actions.edit"
              defaultMessage="Edit"
              description="Knowledge base side panel actions edit tooltip text"
            />
          }
        >
          <IconButton shape="rounded" onClick={handleEdit}>
            <IcEdit />
          </IconButton>
        </Tooltip>
      )}

      {canDownload && (
        <Tooltip
          title={
            <FormattedMessage
              id="knowledge-base-side-panel.actions.download"
              defaultMessage="Download"
              description="Knowledge base side panel actions download tooltip text"
            />
          }
        >
          <IconButton
            shape="rounded"
            disabled={downloading}
            onClick={handleDownload}
          >
            {downloading ? <CircularProgress size="sm" /> : <IcDownload />}
          </IconButton>
        </Tooltip>
      )}

      {canMove && (
        <Tooltip
          title={
            <FormattedMessage
              id="knowledge-base-side-panel.actions.move"
              defaultMessage="Move"
              description="Knowledge base side panel actions move tooltip text"
            />
          }
        >
          <IconButton shape="rounded" onClick={handleMove}>
            <IcFolderMove />
          </IconButton>
        </Tooltip>
      )}

      {canDelete && (
        <Tooltip
          title={
            <FormattedMessage
              id="knowledge-base-side-panel.actions.delete"
              defaultMessage="Delete"
              description="Knowledge base side panel actions delete tooltip text"
            />
          }
        >
          <IconButton
            shape="rounded"
            disabled={deleting}
            onClick={handleDelete}
          >
            {deleting ? <CircularProgress size="sm" /> : <IcDelete />}
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
