import { createIllustration } from '../create-illustration';
import noResult from './no-result.png';
import noResult2x from './no-result@2x.png';
import noResult3x from './no-result@3x.png';

export const NoResult = createIllustration({
  src: noResult,
  srcSet: [noResult, noResult2x, noResult3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 160,
});
