import { listItemButtonClasses, listItemTextClasses } from '@allganize/ui-list';
import { components } from '@allganize/ui-select';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { OptionProps } from 'react-select';
import { AccessControlAvatar } from '../access-control-avatar/access-control-avatar';
import { AccessUserListBase } from '../access-user-list/access-user-list-base';
import { AccessControlSelectOption } from './access-control-select-type-map';

export const AccessControlSelectOptionComponent = <
  T extends AccessControlSelectOption = AccessControlSelectOption,
>({
  children,
  ...others
}: OptionProps<T, true>) => {
  const { data } = others;
  const theme = useTheme();

  return (
    <components.Option
      css={css`
        .${listItemButtonClasses.root} {
          padding: 8px 16px 8px 8px;
          gap: 8px;
        }

        .${listItemTextClasses.root} {
          padding: 0;
          line-height: 20px;
        }
      `}
      {...others}
    >
      {data?.avatar && <AccessControlAvatar {...data.avatar} />}
      {children}
      {data.accessList && (
        <AccessUserListBase
          css={css`
            ${theme.typography.body12};
            color: ${theme.palette.foreground.secondary};
          `}
          data={data.accessList}
          emptyMessage="0"
        />
      )}
    </components.Option>
  );
};
