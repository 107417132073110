import { Text } from '@allganize/ui-text';
import { filesize } from 'filesize';
import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';
import { PanelItem } from '../panel-item/panel-item';

export const FileSize_KnowledgeBaseNodeFragment = gql(`
  fragment FileSize_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id

    ...on KnowledgeBaseNodeFile {
      size
    }
  }
`);

interface FileSizeProps {
  node: FragmentOf<typeof FileSize_KnowledgeBaseNodeFragment>;
}

export const FileSize: FunctionComponent<FileSizeProps> = props => {
  const { node: nodeProp } = props;
  const node = readFragment(FileSize_KnowledgeBaseNodeFragment, nodeProp);
  const intl = useIntl();

  if (node.__typename !== 'KnowledgeBaseNodeFile') {
    return null;
  }

  if (!node.size) {
    return null;
  }

  return (
    <PanelItem
      label={
        <FormattedMessage
          id="knowledge-base-side-panel.file-size"
          defaultMessage="File size"
          description="Knowledge base side panel file size label text"
        />
      }
    >
      <Text variant="body14">
        {filesize(node.size, {
          output: 'string',
          locale: intl.locale,
          localeOptions: intl.formats.date,
        })}
      </Text>
    </PanelItem>
  );
};
