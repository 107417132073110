import { KnowledgeBaseFileIconVariant } from '../knowledge-base-file-icon';

export const getKnowledgeBaseFilenameIconVariant = (
  fileName: string,
): Exclude<KnowledgeBaseFileIconVariant, 'fallback'> | null => {
  const extname = fileName.toLowerCase().split('.').pop();

  switch (extname) {
    case 'csv':
    case 'doc':
    case 'docx':
    case 'hwp':
    case 'hwpx':
    case 'jpeg':
    case 'jpg':
    case 'pdf':
    case 'png':
    case 'ppt':
    case 'pptx':
    case 'txt':
    case 'xls':
    case 'xlsm':
    case 'xlsx':
    case 'zip':
      return extname;
  }

  return null;
};
