import { OverrideProps } from '@allganize/types';
import { SvgIconOwnProps } from '@allganize/ui-icons';

export const llmAppInformationIconVariants = [
  'ic-balance',
  'ic-variable',
  'ic-article',
  'ic-analytics',
  'ic-auto-mode',
  'ic-cake',
  'ic-calendar',
  'ic-update',
  'ic-business',
  'ic-document-search',
  'ic-domain',
  'ic-face',
  'ic-fact-check',
  'ic-favorite',
  'ic-fitbit',
  'ic-folder-zip',
  'ic-format',
  'ic-people',
  'ic-send-message',
  'ic-file',
  'ic-auto-graph',
  'ic-light-bulb',
  'ic-manage-search',
  'ic-outgoing-mail',
  'ic-edit',
  'ic-podcast',
  'ic-post-add',
  'ic-room-preference',
  'ic-saving',
  'ic-school',
  'ic-security',
  'ic-emoji-smile',
  'ic-automation',
  'ic-travel-explore',
  'ic-optimization',
] as const;

export type LLMAppInformationIconVariant =
  (typeof llmAppInformationIconVariants)[number];

export interface LLMAppInformationIconOwnProps {
  variant: LLMAppInformationIconVariant;
}

export interface LLMAppInformationIconTypeMap<
  P = {},
  D extends React.ElementType = 'svg',
> {
  props: P & SvgIconOwnProps & LLMAppInformationIconOwnProps;
  defaultComponent: D;
}

export type LLMAppInformationIconProps<
  D extends React.ElementType = LLMAppInformationIconTypeMap['defaultComponent'],
  P = {},
> = OverrideProps<LLMAppInformationIconTypeMap<P, D>, D> & {
  component?: React.ElementType;
};
