import { useState } from 'react';
import { v4 } from 'uuid';
import { TabBase, TabWithId } from './tab-navigator-context';

export const useTabNavigator = <T extends TabBase>() => {
  const [tabs, setTabs] = useState<TabWithId<T>[]>([]);
  const [activeTab, setActiveTab] = useState<TabWithId<T> | null>(null);

  const openTab = (tab: TabBase) => {
    setTabs(prevTabs => {
      const existingTabIndex = prevTabs.findIndex(
        prevTab => prevTab.type === tab.type,
      );

      if (existingTabIndex >= 0) {
        const updatedTab = {
          ...prevTabs[existingTabIndex],
          ...tab,
          metadata: {
            ...(prevTabs[existingTabIndex].metadata || {}),
            ...tab.metadata,
          },
        };

        const newTabs = [...prevTabs];
        newTabs[existingTabIndex] = updatedTab;
        setActiveTab(updatedTab);
        return newTabs;
      }

      const newTab = { ...tab, id: v4() };
      setActiveTab(newTab);
      return [...prevTabs, newTab];
    });
  };

  const closeTab = (id: string) => {
    setTabs(prevTabs => {
      const updatedTabs = prevTabs.filter(tab => tab.id !== id);

      if (id === activeTab?.id) {
        setActiveTab(updatedTabs.length > 0 ? updatedTabs[0] : null);
      }
      return updatedTabs;
    });
  };

  const closeAllTabs = () => {
    setTabs([]);
    setActiveTab(null);
  };

  return {
    tabs,
    activeTab,
    openTab,
    closeTab,
    closeAllTabs,
  };
};
