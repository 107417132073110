import {
  useCombinedRef,
  useEventCallback,
  useMeasureState,
} from '@allganize/hooks';
import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { Divider } from '@allganize/ui-divider';
import {
  IcArrowExpandLess,
  IcArrowExpandMore,
  IcFitScreen,
  IcSidePanel,
  IcZoomIn,
  IcZoomOut,
} from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { Collapse } from '@allganize/ui-transition';
import { css } from '@emotion/react';
import clsx from 'clsx';
import {
  Suspense,
  forwardRef,
  lazy,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedNumber } from 'react-intl';
import {
  DocumentViewerHeader,
  DocumentViewerHeaderSpacer,
} from '../document-viewer-header';
import { documentViewerHeaderHeight } from '../document-viewer-header/constants';
import { DocumentViewerPageState } from '../document-viewer-page-state';
import {
  DocumentViewerPageThumbnailList,
  DocumentViewerPageThumbnailListActionsRef,
} from '../document-viewer-page-thumbnail';
import { DocumentViewerSidebar } from '../document-viewer-sidebar';
import { documentViewerSidebarWidth } from '../document-viewer-sidebar/constants';
import {
  DocumentViewerToolbar,
  DocumentViewerToolbarSpacer,
} from '../document-viewer-toolbar';
import { documentViewerToolbarHeight } from '../document-viewer-toolbar/constants';
import { draftPageClasses } from '../draft-page';
import { usePages } from '../hooks/use-pages';
import { useScale } from '../hooks/use-scale';
import { htmlPageClasses } from '../html-page';
import { documentViewerClasses } from './document-viewer-classes';
import { DocumentViewerProps } from './document-viewer-type-map';

const DraftPage = lazy(() =>
  import('../draft-page').then(mod => ({ default: mod.DraftPage })),
);
const HtmlPage = lazy(() =>
  import('../html-page').then(mod => ({ default: mod.HtmlPage })),
);
const ImagePage = lazy(() =>
  import('../image-page').then(mod => ({ default: mod.ImagePage })),
);
const PdfPage = lazy(() =>
  import('../pdf-page/pdf-page').then(mod => ({ default: mod.PdfPage })),
);

const stopPropagation = (ev: React.SyntheticEvent) => ev.stopPropagation();

export const DocumentViewer = forwardRef<HTMLDivElement, DocumentViewerProps>(
  (props, ref) => {
    const {
      classes,
      header,
      initialPage,
      pages: pagesProp,
      sidebarVariant = 'persistent',
      thumbnails,
      viewerRef,
      ...other
    } = props;
    const theme = useTheme();
    const {
      currentPage,
      currentPageIndex,
      goToNextPage,
      goToPreviousPage,
      hasNextPage,
      hasPreviousPage,
      loading,
      setCurrentPage,
      totalPages,
    } = usePages(props);
    const rootRef = useRef<HTMLDivElement>(null);
    const combinedRootRef = useCombinedRef(rootRef, ref);
    const sidebarPaperRef = useRef<HTMLDivElement>(null);
    const thumbnailActionsRef =
      useRef<DocumentViewerPageThumbnailListActionsRef>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const { contentRect, measureRef } = useMeasureState({ offset: true });
    const contentCombinedRef = useCombinedRef(contentRef, measureRef);
    const [showToolbars, setShowToolbars] = useState(true);
    const [showSidebar, setShowSidebar] = useState(false);
    const disableSidebar = thumbnails.length === 0;
    const showSidebarEffective = showSidebar && !disableSidebar;

    const scrollToCurrentIndex = useEventCallback(() => {
      thumbnailActionsRef.current?.scrollToIndexIfNeeded(currentPageIndex, {
        align: 'start',
      });
    });

    const {
      canDecrementScale,
      canIncrementScale,
      scale,
      incrementScale,
      decrementScale,
      updateScale,
      getFitPageScale,
      setPageProxy,
      resetFitPage,
      getPageStyle,
      disableScale,
      blendMode,
    } = useScale({
      page: typeof currentPage === 'function' ? null : currentPage,
      containerRef: contentRef,
      getContentOffset() {
        return [-16, -16 - (showToolbars ? documentViewerToolbarHeight : 0)];
      },
      onInitialPageScale: scrollToCurrentIndex,
    });

    useImperativeHandle(viewerRef, () => ({ resetScale: resetFitPage }), [
      resetFitPage,
    ]);

    const toggleSidebar = () => {
      if (showSidebar) {
        setShowSidebar(false);
        return;
      }

      if (disableSidebar) {
        return;
      }

      flushSync(() => {
        setShowSidebar(true);
      });

      scrollToCurrentIndex();
    };

    useEffect(() => {
      if (disableSidebar) {
        setShowSidebar(false);
      }
    }, [disableSidebar]);

    const loadingState = (
      <CircularProgress
        size="lg"
        css={[
          css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: ${theme.transitions.create(
              ['margin-top', 'margin-left', 'margin-bottom'],
              {
                duration: theme.transitions.duration.shortest,
              },
            )};
          `,
          showSidebarEffective &&
            css`
              margin-left: ${documentViewerSidebarWidth / 2}px;
            `,
          showToolbars &&
            css`
              margin-top: ${documentViewerHeaderHeight / 2}px;
              margin-bottom: ${documentViewerToolbarHeight / 2}px;
            `,
        ]}
      />
    );

    return (
      <div
        data-testid="document-viewer"
        css={css`
          position: relative;
          overflow: hidden;
          background-color: ${theme.palette.grey[100]};
          color: ${theme.palette.text.primary};
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
        `}
        {...other}
        ref={combinedRootRef}
        className={clsx(
          documentViewerClasses.root,
          classes?.root,
          other.className,
        )}
      >
        <DocumentViewerSidebar
          open={showSidebarEffective}
          variant={sidebarVariant}
          onClose={() => setShowSidebar(false)}
          container={rootRef.current}
          className={clsx(documentViewerClasses.sidebar, classes?.sidebar)}
          paperRef={sidebarPaperRef}
          onTransitionEnd={ev => {
            if (ev.target !== sidebarPaperRef.current) {
              return;
            }

            /**
             * When the scroll element's transition ends, force-update the virtualizer.
             * Resolves an issue where the virtualizer's size is incorrect when the
             * scroll element's height changes due to a transition.
             *
             * @see QA-643
             */
            flushSync(() => {
              thumbnailActionsRef.current?.forceUpdate();
            });

            scrollToCurrentIndex();
          }}
        >
          <Collapse
            css={css`
              flex-shrink: 0;
            `}
            in={showToolbars}
          >
            <DocumentViewerHeaderSpacer />
          </Collapse>

          <DocumentViewerPageThumbnailList
            actionsRef={thumbnailActionsRef}
            getScrollElement={() => sidebarPaperRef.current}
            onPageSelect={setCurrentPage}
            pages={thumbnails}
            selectedPage={currentPageIndex}
          />
        </DocumentViewerSidebar>

        <Suspense fallback={loadingState}>
          <div
            css={css`
              flex-grow: 1;
              height: 100%;
              position: relative;
              overflow: auto;
              -webkit-overflow-scrolling: touch;

              .${draftPageClasses.input} {
                min-width: ${Math.max(
                  0,
                  (contentRect.offset?.width ?? 0) - 16,
                )}px;
              }

              .${htmlPageClasses.iframe} {
                min-width: ${Math.max(
                  0,
                  (contentRect.offset?.width ?? 0) - 16,
                )}px;
              }
            `}
            className={clsx(documentViewerClasses.content, classes?.content)}
            ref={contentCombinedRef}
            onClick={() => {
              setShowSidebar(false);
              setShowToolbars(p => !p);
            }}
          >
            <Collapse
              css={css`
                flex-shrink: 0;
              `}
              in={showToolbars}
            >
              <DocumentViewerHeaderSpacer />
            </Collapse>

            <div
              css={css`
                position: relative;
                flex-grow: 1;
                padding: 16px;
                text-align: center;
                display: inline-block;
                min-width: 100%;
                align-self: center;

                .${documentViewerClasses.page} {
                  display: inline-block;
                  text-align: left;
                }
              `}
            >
              {currentPage &&
                typeof currentPage !== 'function' &&
                currentPage.type === 'draft' && (
                  <DraftPage
                    page={currentPage}
                    role="region"
                    aria-label={`Page ${currentPageIndex + 1}`}
                    className={clsx(documentViewerClasses.page, classes?.page)}
                    onClick={stopPropagation}
                  />
                )}

              {currentPage &&
                typeof currentPage !== 'function' &&
                currentPage.type === 'html' && (
                  <ErrorBoundary
                    fallback={
                      <ImagePage
                        page={
                          currentPage.fallback ?? {
                            type: 'image',
                            key: currentPage.key,
                            url: '',
                          }
                        }
                        pageRef={setPageProxy}
                        scale={scale}
                        style={getPageStyle}
                        role="region"
                        aria-label={`Page ${currentPageIndex + 1}`}
                        className={clsx(
                          documentViewerClasses.page,
                          classes?.page,
                        )}
                        onClick={stopPropagation}
                        blendMode={blendMode}
                      />
                    }
                  >
                    <HtmlPage
                      key={currentPage.key}
                      page={currentPage}
                      pageRef={setPageProxy}
                      scale={scale}
                      style={getPageStyle}
                      role="region"
                      aria-label={`Page ${currentPageIndex + 1}`}
                      className={clsx(
                        documentViewerClasses.page,
                        classes?.page,
                      )}
                      onClick={stopPropagation}
                    />
                  </ErrorBoundary>
                )}

              {currentPage &&
                typeof currentPage !== 'function' &&
                currentPage.type === 'image' && (
                  <ImagePage
                    page={currentPage}
                    pageRef={setPageProxy}
                    scale={scale}
                    style={getPageStyle}
                    role="region"
                    aria-label={`Page ${currentPageIndex + 1}`}
                    className={clsx(documentViewerClasses.page, classes?.page)}
                    onClick={stopPropagation}
                    blendMode={blendMode}
                  />
                )}

              {currentPage &&
                typeof currentPage !== 'function' &&
                currentPage.type === 'pdf' && (
                  <ErrorBoundary
                    fallback={
                      <ImagePage
                        page={
                          currentPage.fallback ?? {
                            type: 'image',
                            key: currentPage.key,
                            url: '',
                            width: currentPage.width,
                            height: currentPage.height,
                          }
                        }
                        pageRef={setPageProxy}
                        scale={scale}
                        style={getPageStyle}
                        role="region"
                        aria-label={`Page ${currentPageIndex + 1}`}
                        className={clsx(
                          documentViewerClasses.page,
                          classes?.page,
                        )}
                        onClick={stopPropagation}
                        blendMode={blendMode}
                      />
                    }
                  >
                    <PdfPage
                      page={currentPage}
                      pageRef={setPageProxy}
                      scale={scale}
                      style={getPageStyle}
                      role="region"
                      aria-label={`Page ${currentPageIndex + 1}`}
                      blendMode={blendMode}
                      className={clsx(
                        documentViewerClasses.page,
                        classes?.page,
                      )}
                      onClick={stopPropagation}
                      error={() => {
                        if (currentPage.fallback) {
                          throw new Error('Failed to load PDF page');
                        }

                        return <DocumentViewerPageState type="error" />;
                      }}
                    />
                  </ErrorBoundary>
                )}
            </div>

            <Collapse
              css={css`
                flex-shrink: 0;
              `}
              in={showToolbars}
            >
              <div
                css={css`
                  padding-bottom: 16px;
                `}
              >
                <DocumentViewerToolbarSpacer />
              </div>
            </Collapse>
          </div>

          {(loading || !currentPage) && loadingState}
        </Suspense>

        <DocumentViewerHeader
          hidden={!showToolbars}
          css={css`
            z-index: ${theme.zIndex.drawer + 1};
            background-color: ${theme.palette.grey[100]};
          `}
          className={clsx(documentViewerClasses.header, classes?.header)}
        >
          <IconButton
            edge="start"
            disabled={disableSidebar}
            onClick={toggleSidebar}
          >
            <IcSidePanel />
          </IconButton>

          {header}
        </DocumentViewerHeader>

        <DocumentViewerToolbar
          hidden={!showToolbars}
          css={[
            css`
              z-index: ${theme.zIndex.drawer};
              position: absolute;
              bottom: 16px;
              left: 50%;
              background-color: ${theme.palette.background.paper};
              box-shadow: ${theme.elevations[4]};
              transform: translate(
                  calc(
                    -50% + ${showSidebarEffective ? documentViewerSidebarWidth / 2 : 0}px
                  ),
                  100%
                )
                scaleX(0);
              word-break: break-all;
            `,
            showToolbars &&
              css`
                transform: translate(
                    calc(
                      -50% + ${showSidebarEffective ? documentViewerSidebarWidth / 2 : 0}px
                    ),
                    0
                  )
                  scaleX(1);
              `,
          ]}
          className={clsx(documentViewerClasses.toolbar, classes?.toolbar)}
        >
          <IconButton
            size="small"
            disabled={!hasPreviousPage}
            onClick={() =>
              goToPreviousPage(() => {
                scrollToCurrentIndex();
              })
            }
          >
            <IcArrowExpandLess />
          </IconButton>

          <Truncate
            css={css`
              flex-grow: 1;
              text-align: center;
            `}
            clamp={1}
          >
            <FormattedNumber value={currentPageIndex + 1} /> /{' '}
            <FormattedNumber value={totalPages} />
          </Truncate>

          <IconButton
            size="small"
            disabled={!hasNextPage}
            onClick={() =>
              goToNextPage(() => {
                scrollToCurrentIndex();
              })
            }
          >
            <IcArrowExpandMore />
          </IconButton>

          <Divider
            orientation="vertical"
            css={css`
              max-height: 20px;
              margin: 0 8px;
            `}
          />

          <IconButton
            size="small"
            disabled={disableScale || !canDecrementScale}
            onClick={decrementScale}
          >
            <IcZoomOut />
          </IconButton>

          <IconButton
            size="small"
            disabled={disableScale || !canIncrementScale}
            onClick={incrementScale}
          >
            <IcZoomIn />
          </IconButton>

          <IconButton
            size="small"
            disabled={disableScale}
            onClick={() => {
              const fitPageScale = getFitPageScale();

              if (fitPageScale === null) {
                return;
              }

              updateScale(fitPageScale.scale);
            }}
          >
            <IcFitScreen />
          </IconButton>
        </DocumentViewerToolbar>
      </div>
    );
  },
);
