import styled from '@emotion/styled';

export const AlertIcon = styled.div`
  margin-right: 12px;
  padding: 7px 0;
  display: flex;
  font-size: 22px;
  opacity: 0.9;
`;

export const AlertMessage = styled.div`
  padding: 8px 0;
  min-width: 0;
  overflow: auto;
`;

export const AlertAction = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 4px 0 0 16px;
  margin-left: auto;
  margin-right: -8px;
`;
