import { selectClasses } from '@allganize/ui-select';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';

interface AccessControlContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  withTab?: boolean;
}

export const AccessControlContainer = forwardRef<
  HTMLDivElement,
  AccessControlContainerProps
>(({ withTab, children, ...others }, ref) => {
  const theme = useTheme();
  return (
    <div
      data-testid="access-control-container"
      css={css`
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: ${withTab ? 420 : 320}px;
        padding: 12px 0;
        border-radius: ${theme.radius.sm}px;
        border: 1px solid ${theme.palette.border.divider.subtle};
        background-color: ${theme.palette.unstable_background.default};

        .${selectClasses.control} {
          background-color: ${theme.palette.unstable_background.white};
        }
      `}
      {...others}
      ref={ref}
    >
      {children}
    </div>
  );
});
