import { Avatar } from '@allganize/ui-avatar';
import { Tooltip, TooltipProps } from '@allganize/ui-tooltip';
import { forwardRef, Fragment, useMemo } from 'react';
import { defineMessage, IntlShape, useIntl } from 'react-intl';
import { AccessUserContainer } from './access-user-container';
import { AVATAR_DISPLAY_MAX_COUNT } from './constants';

const TOOLTIP_DISPLAY_MAX_COUNT = 9;

const leftPeopleMessage = defineMessage({
  id: 'access-control.access-user-list.left-people',
  defaultMessage: '+ {count, plural, one {# person} other {# people}}',
  description: 'Left people message in access user list tooltip',
});

const getUserTooltipMessage = (users: UserListItem[], intl: IntlShape) => {
  if (users.length > TOOLTIP_DISPLAY_MAX_COUNT) {
    const leftCount = users.length - TOOLTIP_DISPLAY_MAX_COUNT;
    return (
      <Fragment>
        {users.slice(0, TOOLTIP_DISPLAY_MAX_COUNT).map(user => (
          <Fragment key={user.id}>
            {user.name}
            <br />
          </Fragment>
        ))}
        {intl.formatMessage(leftPeopleMessage, { count: leftCount })}
      </Fragment>
    );
  }
  return users.map((user, idx) => (
    <Fragment key={user.id}>
      {idx > 0 && <br />}
      {user.name}
    </Fragment>
  ));
};

export interface UserListItem {
  id: string | number;
  name: string;
  avatar: string | null | undefined;
}

interface AccessUserListInnerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  data: UserListItem[];
  tooltip?: boolean | React.ReactNode;
  TooltipProps?: Partial<Omit<TooltipProps, 'title' | 'ref'>>;
}

const AccessUserListInner = forwardRef<
  HTMLDivElement,
  AccessUserListInnerProps
>(({ data, tooltip = true, TooltipProps, ...others }, ref) => {
  const intl = useIntl();
  const tooltipMessage = useMemo(() => {
    if (tooltip === false) {
      return null;
    }
    if (tooltip === true) {
      return getUserTooltipMessage(data, intl);
    }
    return tooltip;
  }, [data, intl, tooltip]);

  return (
    <Tooltip placement="top" {...TooltipProps} title={tooltipMessage}>
      <AccessUserContainer {...others} count={data.length} ref={ref}>
        {data.slice(0, AVATAR_DISPLAY_MAX_COUNT).map(user => {
          return (
            <Avatar
              key={user.id}
              size="sm"
              variant="circular"
              src={user.avatar ?? undefined}
              alt={user.name}
            >
              {user.name.charAt(0)}
            </Avatar>
          );
        })}
      </AccessUserContainer>
    </Tooltip>
  );
});

export interface AccessUserListBaseProps extends AccessUserListInnerProps {
  emptyMessage?: React.ReactNode;
}

export const AccessUserListBase = forwardRef<
  HTMLDivElement,
  AccessUserListBaseProps
>(({ emptyMessage = '-', ...others }, ref) => {
  if (others.data.length === 0) {
    return (
      <div {...others} ref={ref}>
        {emptyMessage}
      </div>
    );
  }

  return <AccessUserListInner {...others} ref={ref} />;
});
