import { BrowserTransportOptions } from '@sentry/browser/types/transports/types';
import {
  BrowserClient,
  Hub,
  defaultIntegrations,
  defaultStackParser,
  makeFetchTransport as makeFetchTransportCore,
} from '@sentry/react';
import type { Transport } from '@sentry/types';
import { v4 } from 'uuid';
import { config } from './config';

const namespace = `alli-sdk-${v4()}`;
let reqId = 0;

let sandboxLoaded = false;
export const sandboxId = 'alli-sdk-error-sandbox';
let sandbox = document.getElementById(sandboxId) as HTMLIFrameElement | null;

if (!sandbox && config.sentry.proxy) {
  sandbox = document.createElement('iframe');
  sandbox.id = sandboxId;
  sandbox.src = config.sentry.proxy;
  sandbox.hidden = true;
  sandbox.style.display = 'none';
  document.body.appendChild(sandbox);
}

const waitForSandboxLoad = () =>
  new Promise<void>((resolve, reject) => {
    if (sandboxLoaded) {
      resolve();
      return;
    }

    const handleLoad = () => {
      sandboxLoaded = true;
      cleanup();
      resolve();
    };

    const handleError = () => {
      cleanup();
      reject();
    };

    const cleanup = () => {
      sandbox?.removeEventListener('load', handleLoad);
      sandbox?.removeEventListener('error', handleError);
    };

    sandbox?.addEventListener('load', handleLoad);
    sandbox?.addEventListener('error', handleError);
  });

const sandboxFetch: typeof fetch = (url, options) => {
  const requestId = reqId;
  reqId += 1;

  return new Promise<Response>((resolve, reject) => {
    const handleMessageError = (ev: MessageEvent) => {
      cleanup();
      reject(ev.data);
    };

    const handleMessage = (ev: MessageEvent) => {
      if (
        ev.data?.namespace !== namespace ||
        ev.data?.type !== 'response' ||
        ev.data?.id !== requestId
      ) {
        return;
      }

      cleanup();

      if (ev.data.error) {
        reject(ev.data.error);
        return;
      }

      if (ev.data.response) {
        resolve(new Response(ev.data.response.text, ev.data.response));
        return;
      }

      reject(new Error('Unknown response type'));
    };

    const cleanup = () => {
      window.removeEventListener('messageerror', handleMessageError);
      window.removeEventListener('message', handleMessage);
    };

    window.addEventListener('messageerror', handleMessageError);
    window.addEventListener('message', handleMessage);

    waitForSandboxLoad()
      .then(() => {
        sandbox?.contentWindow?.postMessage(
          {
            namespace,
            type: 'request',
            id: requestId,
            url,
            options,
          },
          '*',
        );
      })
      .catch(() => {
        cleanup();
        reject();
      });
  });
};

export let hub: Hub | null = null;

function makeFetchTransport(options: BrowserTransportOptions): Transport {
  return makeFetchTransportCore(options, sandboxFetch ?? undefined);
}

if (config.sentry.dsn) {
  const client = new BrowserClient({
    dsn: config.sentry.dsn,
    enabled: config.sentry.enabled,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: defaultIntegrations,
    allowUrls: [/https?:\/\/.*\.alli.ai/],
    ignoreErrors: [
      /<unknown>/i,
      /AbortError/i,
      /SecurityError/i,
      /NotAllowedError/i,
      /NotFoundError/i,
      /Loading chunk (\d+) failed/i,
      /QuotaExceededError/i,
      /NS_ERROR_DOM_QUOTA_REACHED/i,
      /Out of memory/i,
      /about:blank/i,
      'null',
      'undefined',
    ],
  });

  hub = new Hub(client);
}
