import styled from '@emotion/styled';
import { DIALOG_WIDTH, VIEW_HEIGHT } from '../internals/constants';

export const PickerViewRoot = styled.div`
  overflow: hidden;
  width: ${DIALOG_WIDTH}px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-height: ${VIEW_HEIGHT}px;
`;
