import {
  AnswerProgress,
  AnswerProgress_ChatFragment,
  GeneratingErrorState,
  GeneratingErrorState_ChatFragment,
} from '@allganize/alli-chat-answer-progress';
import {
  AlliChatRow,
  AlliChatRowItem,
  AlliChatRowTyping,
  ChatStack,
  ChatStackItem,
  TimestampTooltip,
} from '@allganize/alli-chat-base';
import {
  ContactAgent,
  ContactAgentProps,
} from '@allganize/alli-chat-contact-agent';
import {
  MarkdownText,
  RichText,
  useRichText,
} from '@allganize/alli-chat-content';
import {
  DocumentEntities_CitationReferenceFragment,
  DocumentEntities_KnowledgeBasePreviewFragment,
  DraftDocumentList,
  useDocumentEntities,
} from '@allganize/alli-chat-document';
import {
  FaqCarousel,
  FaqCarousel_ChatFragment,
  FaqCarouselProps,
} from '@allganize/alli-chat-faq-carousel';
import {
  UserFeedback,
  UserFeedback_BotChatFragment,
  UserFeedbackProps,
} from '@allganize/alli-chat-feedback';
import {
  CreatedLLMAppOptions,
  CreatedLLMAppOptions_ChatFragment,
  CreatedLLMAppOptionsProps,
  LLMAppCarousel,
  LLMAppCarousel_ChatFragment,
  LLMAppCarouselProps,
} from '@allganize/alli-chat-llm-app';
import {
  LinkImageCarousel,
  LinkImageCarousel_ChatFragment,
  LinkImageCarouselProps,
} from '@allganize/alli-chat-link-image-carousel';
import {
  ChatOptionInfo,
  ChatOptionInfo_ChatFragment,
  ChatOptionInfoProps,
} from '@allganize/alli-chat-option-info';
import { isDraftInputEmpty } from '@allganize/draft-input';
import { RehypeCitationOptions, rehypeCitation } from '@allganize/markdown';
import { css } from '@emotion/react';
import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import useSlot from '@mui/material/utils/useSlot';
import { FunctionComponent, Suspense, useContext, useMemo } from 'react';
import rehypeRaw from 'rehype-raw';
import {
  AlliChatFile,
  AlliChatFile_ChatFragment,
} from '../alli-chat/alli-chat-file';
import {
  AlliChatMedia,
  AlliChatMedia_ChatFragment,
} from '../alli-chat/alli-chat-media';
import { gql, readFragment, ResultOf } from '../gql';
import { Dialog } from '../modal/dialog';
import { TypingContext } from '../typing-context/typing-context';

export const BotChat_BotChatFragment = gql(
  `
  fragment BotChat_BotChatFragment on BotChat {
    id
    messageContentState @client
    markdownMessage
    generatingState
    createdAt

    media {
      id
    }

    citationReferences {
      id
      isDeleted

      knowledgeBasePreview {
        pageNo
        totalPageCount
        highlightIndexes
        exactAnswer
        answerHighlightId

        highlights {
          indexes

          pageIndexes {
            page
            indexes
          }
        }

        draftjs {
          id
          answer
        }

        knowledgeBase {
          ... on GoogleDocsKnowledgeBase {
            id
            title
            originalWebUri
          }

          ... on TextKnowledgeBase {
            id
            title
            originalWebUri
          }

          ... on ImageKnowledgeBase {
            id
            title
            originalWebUri
          }

          ... on MSDocsKnowledgeBase {
            id
            title
            originalWebUri
          }

          ... on PDFKnowledgeBase {
            id
            title
            originalWebUri
          }

          ... on MSExcelKnowledgeBase {
            id
            title
            originalWebUri
          }

          ... on MSPPTKnowledgeBase {
            id
            title
            originalWebUri
          }

          ... on OldMSDocsKnowledgeBase {
            id
            title
            originalWebUri
          }

          ... on CSVKnowledgeBase {
            id
            title
            originalWebUri
          }

          ... on HWPKnowledgeBase {
            id
            title
            originalWebUri
          }
        }

        knowledgeBaseEntity {
          id
          title
          originalWebUri
        }
      }

      ...DocumentEntities_CitationReferenceFragment
    }


    knowledgeBasePreview {
      ...DocumentEntities_KnowledgeBasePreviewFragment
    }

    ...UserFeedback_BotChatFragment
    ...AnswerProgress_ChatFragment
    ...AlliChatMedia_ChatFragment
    ...AlliChatFile_ChatFragment
    ...GeneratingErrorState_ChatFragment
    ...LinkImageCarousel_ChatFragment
    ...LLMAppCarousel_ChatFragment
    ...FaqCarousel_ChatFragment
    ...CreatedLLMAppOptions_ChatFragment
    ...ChatOptionInfo_ChatFragment
  }
`,
  [
    DocumentEntities_KnowledgeBasePreviewFragment,
    DocumentEntities_CitationReferenceFragment,
    UserFeedback_BotChatFragment,
    AnswerProgress_ChatFragment,
    AlliChatMedia_ChatFragment,
    AlliChatFile_ChatFragment,
    GeneratingErrorState_ChatFragment,
    LinkImageCarousel_ChatFragment,
    LLMAppCarousel_ChatFragment,
    FaqCarousel_ChatFragment,
    CreatedLLMAppOptions_ChatFragment,
    ChatOptionInfo_ChatFragment,
  ],
);

export interface BotChatSlots {
  /**
   * @default ChatOptionInfo
   */
  chatOptionInfo?: React.ElementType;
  /**
   * @default ContactAgent
   */
  contactAgent?: React.ElementType;
  /**
   * @default CreatedLLMAppOptions
   */
  createdLlmAppOptions?: React.ElementType;
  /**
   * @default FaqCarousel
   */
  faqCarousel?: React.ElementType;
  /**
   * @default LinkImageCarousel
   */
  linkImageCarousel?: React.ElementType;
  /**
   * @default LLMAppCarousel
   */
  llmAppCarousel?: React.ElementType;
  /**
   * @default UserFeedback
   */
  userFeedback?: React.ElementType;
}

export interface BotChatSlotProps {
  chatOptionInfo: SlotProps<React.ElementType<ChatOptionInfoProps>, {}, {}>;
  contactAgent: SlotProps<React.ElementType<ContactAgentProps>, {}, {}>;
  createdLlmAppOptions: SlotProps<
    React.ElementType<CreatedLLMAppOptionsProps>,
    {},
    {}
  >;
  faqCarousel: SlotProps<React.ElementType<FaqCarouselProps>, {}, {}>;
  linkImageCarousel: SlotProps<
    React.ElementType<LinkImageCarouselProps>,
    {},
    {}
  >;
  llmAppCarousel: SlotProps<React.ElementType<LLMAppCarouselProps>, {}, {}>;
  userFeedback: SlotProps<React.ElementType<UserFeedbackProps>, {}, {}>;
}

export type BotChatSlotsAndSlotProps = CreateSlotsAndSlotProps<
  BotChatSlots,
  BotChatSlotProps
>;

export interface BotChatProps extends BotChatSlotsAndSlotProps {
  align?: 'left' | 'right';
  avatar?: React.ReactNode;
  chat: ResultOf<typeof BotChat_BotChatFragment>;
}

export const BotChat: FunctionComponent<BotChatProps> = props => {
  const {
    align = 'left',
    avatar,
    chat: chatProp,
    slotProps = {},
    slots = {},
  } = props;
  const chat = readFragment(BotChat_BotChatFragment, chatProp);
  const { onTyping } = useContext(TypingContext);
  const { value: message, onChange: onMessageChange } = useRichText({
    value: chat.messageContentState,
  });
  const citations = useMemo(
    () =>
      (chat.citationReferences ?? []).reduce<
        RehypeCitationOptions['citations']
      >((acc, curr) => {
        if (!curr.knowledgeBasePreview) {
          return acc;
        }

        return [
          ...acc,
          {
            ...curr,
            isDeleted: curr.isDeleted ?? false,
            knowledgeBasePreview: {
              ...curr.knowledgeBasePreview,
              knowledgeBase:
                curr.knowledgeBasePreview.knowledgeBaseEntity ??
                curr.knowledgeBasePreview.knowledgeBase,
            },
          },
        ];
      }, []),
    [chat.citationReferences],
  );
  const { documentEntities } = useDocumentEntities({
    value: chat.citationReferences?.length ? null : message,
    citationReferences: chat.citationReferences ?? undefined,
    knowledgeBasePreview: chat.citationReferences?.length
      ? null
      : chat.knowledgeBasePreview,
  });
  const hasMedia = !!chat.media;
  const hasMarkdownMessage = !!chat.markdownMessage;
  const hasDraftMessage = !hasMarkdownMessage && !isDraftInputEmpty(message);
  const hasMessage = !hasMedia && (hasDraftMessage || hasMarkdownMessage);
  const hasDocumentEntities = documentEntities.length > 0;
  const hasGeneratingStateMessage =
    chat.generatingState &&
    chat.generatingState !== gql.scalar('SearchContextStep', 'COMPLETE');
  const hasTypingIndicator =
    hasGeneratingStateMessage &&
    !(
      chat.generatingState ===
        gql.scalar('SearchContextStep', 'GENERATING_ANSWER') &&
      !isDraftInputEmpty(message)
    );
  const [ChatOptionInfoSlot, chatOptionInfoSlotProps] = useSlot(
    'chatOptionInfo',
    {
      elementType: ChatOptionInfo,
      // @ts-expect-error internal prop
      externalForwardedProps: { slots, slotProps },
      ownerState: {},
      className: undefined,
    },
  );
  const [ContactAgentSlot, contactAgentSlotProps] = useSlot('contactAgent', {
    elementType: ContactAgent,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });
  const [CreatedLlmAppOptionsSlot, createdLlmAppOptionsSlotProps] = useSlot(
    'createdLlmAppOptions',
    {
      elementType: CreatedLLMAppOptions,
      // @ts-expect-error internal prop
      externalForwardedProps: { slots, slotProps },
      ownerState: {},
      className: undefined,
    },
  );
  const [FaqCarouselSlot, faqCarouselSlotProps] = useSlot('faqCarousel', {
    elementType: FaqCarousel,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });
  const [LinkImageCarouselSlot, linkImageCarouselSlotProps] = useSlot(
    'linkImageCarousel',
    {
      elementType: LinkImageCarousel,
      // @ts-expect-error internal prop
      externalForwardedProps: { slots, slotProps },
      ownerState: {},
      className: undefined,
    },
  );
  const [LlmAppCarouselSlot, llmAppCarouselSlotProps] = useSlot(
    'llmAppCarousel',
    {
      elementType: LLMAppCarousel,
      // @ts-expect-error internal prop
      externalForwardedProps: { slots, slotProps },
      ownerState: {},
      className: undefined,
    },
  );
  const [UserFeedbackSlot, feedbackSlotProps] = useSlot('userFeedback', {
    elementType: UserFeedback,
    // @ts-expect-error internal prop
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: undefined,
  });

  const feedback = (
    <UserFeedbackSlot
      css={css`
        margin-top: 8px;
      `}
      data={chat}
      slots={{ dialog: Dialog }}
      {...feedbackSlotProps}
    />
  );

  return (
    <ChatStack
      header={
        <AnswerProgress
          data-testid="generating-state-message"
          chat={chat}
          disablePadding
        />
      }
    >
      {hasTypingIndicator && (
        <ChatStackItem disablePadding>
          <AlliChatRowTyping avatar={avatar} />
        </ChatStackItem>
      )}

      {(hasMessage || hasDocumentEntities) && (
        <ChatStackItem disablePadding>
          <AlliChatRow>
            <TimestampTooltip align="left" timestamp={chat.createdAt}>
              <AlliChatRowItem avatar={avatar}>
                <Suspense fallback={null}>
                  <MarkdownText
                    rehypePlugins={[rehypeRaw, [rehypeCitation, { citations }]]}
                  >
                    {chat.markdownMessage}
                  </MarkdownText>
                </Suspense>

                {!chat.markdownMessage && (
                  <RichText value={message} onChange={onMessageChange} />
                )}

                {hasDocumentEntities && (
                  <DraftDocumentList
                    css={[
                      !hasMessage &&
                        css`
                          margin-top: -2px;
                        `,
                    ]}
                    data={documentEntities}
                  />
                )}

                {feedback}
              </AlliChatRowItem>
            </TimestampTooltip>
          </AlliChatRow>
        </ChatStackItem>
      )}

      <AlliChatMedia avatar={avatar} chat={chat}>
        {feedback}
      </AlliChatMedia>

      <AlliChatFile avatar={avatar} chat={chat} />

      <GeneratingErrorState
        data-testid="generating-error-state"
        avatar={avatar}
        chat={chat}
        disablePadding
      />

      <LinkImageCarouselSlot
        chat={chat}
        disablePadding
        {...linkImageCarouselSlotProps}
      />

      <LlmAppCarouselSlot
        chat={chat}
        disablePadding
        {...llmAppCarouselSlotProps}
      />

      <FaqCarouselSlot
        align={align === 'left' ? 'right' : 'left'}
        chat={chat}
        {...faqCarouselSlotProps}
      />

      <CreatedLlmAppOptionsSlot
        chat={chat}
        {...createdLlmAppOptionsSlotProps}
      />

      <ChatOptionInfoSlot
        chat={chat}
        disablePadding
        {...chatOptionInfoSlotProps}
      />

      <ContactAgentSlot
        align={align === 'left' ? 'right' : 'left'}
        onTyping={onTyping}
        {...contactAgentSlotProps}
      />
    </ChatStack>
  );
};
