import {
  ChatStackItem,
  TimestampTooltip,
  UserChatRow,
  UserChatRowProps,
} from '@allganize/alli-chat-base';
import { ChatMedia, RichText, useRichText } from '@allganize/alli-chat-content';
import {
  ChatKnowledgeBases,
  ChatKnowledgeBases_ChatFragment,
  ChatKnowledgeBasesProps,
  DraftDocumentList,
  useDocumentEntities,
} from '@allganize/alli-chat-document';
import { isDraftInputEmpty } from '@allganize/draft-input';
import { css } from '@emotion/react';
import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import useSlot from '@mui/material/utils/useSlot';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

export const UserChatMessage_ChatFragment = gql(
  `
  fragment UserChatMessage_ChatFragment on Chat {
    ...on ContactAgentByEmailChat {
      __typename
      createdAt
      messageContentState @client

      attachments {
        id
        mediaType
        metaInfo
        url
        width
        height
      }
    }

    ...on UserChat {
      __typename
      createdAt
      messageContentState @client

      media {
        id
      }

      file {
        id
      }

      formValues {
        __typename
      }
    }

    ...ChatKnowledgeBases_ChatFragment
  }
`,
  [ChatKnowledgeBases_ChatFragment],
);

export interface UserChatMessageSlots {
  /**
   * @default ChatKnowledgeBases
   */
  chatKnowledgeBases?: React.ElementType;
}

export interface UserChatMessageSlotProps {
  chatKnowledgeBases: SlotProps<
    React.ElementType<ChatKnowledgeBasesProps>,
    {},
    {}
  >;
}

export type UserChatMessageSlotsAndSlotProps = CreateSlotsAndSlotProps<
  UserChatMessageSlots,
  UserChatMessageSlotProps
>;

export interface UserChatMessageProps
  extends Pick<UserChatRowProps, 'avatar'>,
    UserChatMessageSlotsAndSlotProps {
  align?: 'left' | 'right';
  chat: FragmentOf<typeof UserChatMessage_ChatFragment>;
}

export const UserChatMessage: FunctionComponent<
  UserChatMessageProps
> = props => {
  const {
    align = 'right',
    avatar,
    chat: chatProp,
    slotProps = {},
    slots = {},
  } = props;
  const chat = readFragment(UserChatMessage_ChatFragment, chatProp);
  const { value: message, onChange: onMessageChange } = useRichText({
    value:
      chat.__typename === 'ContactAgentByEmailChat' ||
      chat.__typename === 'UserChat'
        ? chat.messageContentState
        : undefined,
  });
  const { documentEntities } = useDocumentEntities({ value: message });
  const hasDocumentEntities = documentEntities.length > 0;
  const isMessageNonEmpty = !isDraftInputEmpty(message);
  const hasFormValues =
    chat.__typename === 'UserChat' &&
    chat.formValues &&
    chat.formValues.length > 0;
  const hasFile = chat.__typename === 'UserChat' && !!chat.file;
  const hasMessage =
    !hasFormValues &&
    !(chat.__typename === 'UserChat' && !!chat.media) &&
    !hasFile &&
    isMessageNonEmpty;

  const hasAttachments =
    chat.__typename === 'ContactAgentByEmailChat' &&
    chat.attachments &&
    chat.attachments.length > 0;

  const [ChatKnowledgeBasesSlot, chatKnowledgeBasesSlotProps] = useSlot(
    'chatKnowledgeBases',
    {
      elementType: ChatKnowledgeBases,
      // @ts-expect-error internal prop
      externalForwardedProps: { slots, slotProps },
      ownerState: {},
      className: undefined,
    },
  );

  if (
    chat.__typename !== 'ContactAgentByEmailChat' &&
    chat.__typename !== 'UserChat'
  ) {
    return null;
  }

  if (!hasMessage && !hasAttachments && !hasDocumentEntities) {
    return null;
  }

  return (
    <ChatStackItem>
      <TimestampTooltip align={align} timestamp={chat.createdAt}>
        <UserChatRow align={align} avatar={avatar}>
          {hasMessage && (
            <RichText value={message} onChange={onMessageChange} />
          )}

          {hasAttachments && (
            <ChatMedia
              css={
                hasMessage &&
                css`
                  margin-top: 20px;
                `
              }
              media={chat.attachments}
            />
          )}

          {hasDocumentEntities && <DraftDocumentList data={documentEntities} />}

          <ChatKnowledgeBasesSlot
            css={css`
              margin-top: 8px;
            `}
            chat={chat}
            {...chatKnowledgeBasesSlotProps}
          />
        </UserChatRow>
      </TimestampTooltip>
    </ChatStackItem>
  );
};
