import { createContext } from 'react';
import { uaParser } from '../utils/ua-parser';

interface ChatCarouselContextValue {
  hasCarouselHeightBug: boolean;
}

export const ChatCarouselContext = createContext<ChatCarouselContextValue>({
  hasCarouselHeightBug: uaParser.hasCarouselHeightBug,
});
