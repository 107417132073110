import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { SidePanelHeader, SidePanelHeaderProps } from './side-panel-header';

export interface SidePanelContainerProps extends SidePanelHeaderProps {
  children: React.ReactNode;
}

export const SidePanelContainer: FunctionComponent<
  SidePanelContainerProps
> = props => {
  const { children, ...rest } = props;
  return (
    <>
      <SidePanelHeader {...rest} />

      <div
        css={css`
          padding: 0 16px;
        `}
      >
        {children}
      </div>
    </>
  );
};
