import { useProject } from '@allganize/alli-works-project';
import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { ConversationLayout } from '../conversation/conversation-layout';
import { LNBLayout } from './templates/lnb-layout';

export const Layout = () => {
  const intl = useIntl();
  const project = useProject();
  const newHomeEnabled = project.enabledAddOns?.includes(
    UserQueryTypes.AddOnType.WA_APP_LAUNCHER_NEW_HOME,
  );

  const meta = project.appCollectionSettings?.companyName && (
    <Helmet
      key={[project.id, project.appCollectionSettings.companyName].join('-')}
      defaultTitle={`${
        project.appCollectionSettings.companyName
      } | ${intl.formatMessage({
        id: 'meta.title',
        defaultMessage: 'Alli App Market',
        description: 'Page meta title text',
      })}`}
      titleTemplate={`%s | ${project.appCollectionSettings.companyName}`}
    />
  );

  if (!newHomeEnabled) {
    return (
      <>
        {meta}
        <Outlet />
      </>
    );
  }

  return (
    <ConversationLayout>
      <LNBLayout>
        {meta}

        <Outlet />
      </LNBLayout>
    </ConversationLayout>
  );
};
