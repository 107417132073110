import mixpanel, {
  Mixpanel,
  RegisterOptions,
  RequestOptions,
  Response,
} from 'mixpanel-browser';
import { AnalyticsOptions } from './analytics.types';

export class Analytics<EventMap extends { [eventName: string]: any }> {
  private static namespaces: { [name: string]: Analytics<any> } = {};

  public static get<EventMap extends { [eventName: string]: any }>(
    name: string,
  ): Analytics<EventMap> | null {
    return Analytics.namespaces[name] ?? null;
  }

  private name: string;
  public readonly mixpanel: Mixpanel | null = null;

  constructor(options: AnalyticsOptions) {
    this.name = options.name;

    if (options.mixpanel) {
      this.mixpanel = mixpanel.init(
        options.mixpanel.token,
        options.mixpanel.config ?? {},
        this.name,
      );
    }

    Analytics.namespaces[this.name] = this;
  }

  public setUserId(id: string | null) {
    if (id === null) {
      this.mixpanel?.reset();
    } else {
      this.mixpanel?.identify(id);
    }
  }

  public get user() {
    return {
      mixpanel: this.mixpanel?.people,
    };
  }

  public track = async <K extends keyof EventMap>(
    eventName: K,
    properties?: EventMap[K],
    options?: { mixpanel?: RequestOptions },
  ) => {
    const mixpanel = await new Promise<Response | void>(resolve => {
      if (this.mixpanel) {
        this.mixpanel?.track(
          eventName.toString(),
          properties || undefined,
          options?.mixpanel,
          resolve,
        );
      } else {
        resolve();
      }
    });

    return { mixpanel };
  };

  public async register(
    props: { [key: string]: any },
    options?: { mixpanel?: number | Partial<RegisterOptions> },
  ) {
    this.mixpanel?.register(props, options?.mixpanel);
  }

  public async unregister(
    property: string,
    options?: { mixpanel?: Partial<RegisterOptions> },
  ) {
    this.mixpanel?.unregister(property, options?.mixpanel);
  }

  public reset() {
    this.mixpanel?.reset();
  }
}
