import {
  useKnowledgeBaseHashtagSelect,
  UseKnowledgeBaseHashtagSelectOptions,
} from '@allganize/hashtag-select';
import { useEventCallback } from '@allganize/hooks';
import { IcInfo } from '@allganize/ui-icons';
import { CreatableField } from '@allganize/ui-select';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { useController, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { EditFileFormValues } from '../edit-file/use-edit-file-form';

interface HashtagFieldProps
  extends Pick<UseKnowledgeBaseHashtagSelectOptions<true>, 'variables'> {
  // control: Control<EditFileFormValues>;
  form: UseFormReturn<EditFileFormValues>;
  disabled?: boolean;
}

export const HashtagField = (props: HashtagFieldProps) => {
  const {
    form: { control, setValue, getValues },
    disabled,
    variables,
  } = props;
  const {
    field: { ref: fieldRef, ...field },
    fieldState,
    // @ts-expect-error generic type is not inferred correctly
  } = useController<T, 'hashtags'>({
    control,
    name: 'hashtags',
    disabled,
  });

  const handleCreateOption = useEventCallback(newValue => {
    setValue('hashtags', [
      ...getValues('hashtags'),
      { label: `#${newValue}`, value: newValue },
    ]);
  });

  const { refetch, options, ...other } = useKnowledgeBaseHashtagSelect({
    variables,
  });

  return (
    <CreatableField
      {...field}
      selectRef={fieldRef}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      options={options}
      isMulti
      placeholder={
        <FormattedMessage
          id="FAQ.Documents.HashtagForm.Hashtags.placeholder"
          defaultMessage="Select or create"
          description="FAQ documents hashtag form hashtags placeholder text"
        />
      }
      label={
        <>
          <FormattedMessage
            id="FAQ.Documents.HashtagForm.Hashtags"
            defaultMessage="Hashtags"
            description="FAQ documents hashtag form hashtags label text"
          />{' '}
          <Tooltip
            title={
              <FormattedMessage
                id="FAQ.Documents.HashtagForm.Hashtags.tooltip"
                defaultMessage="Identify a keyword or topic of interest to facilitate a search for it."
                description="FAQ documents hashtag form hashtags tooltip text"
              />
            }
          >
            <IcInfo
              css={css`
                vertical-align: middle;
              `}
            />
          </Tooltip>
        </>
      }
      fullWidth
      SelectProps={{
        ...other,
        onCreateOption: handleCreateOption,
        menuPortalTarget: document.body,
      }}
    />
  );
};
