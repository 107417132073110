import { ChatMedia } from '@allganize/alli-chat-content';
import { useId } from '@allganize/hooks';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from '@allganize/ui-form';
import { Radio, RadioGroup } from '@allganize/ui-radio';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormValueFormValues } from './use-form-value-form';

interface RadioFormValueFieldProps {
  disabled?: boolean;
  name: `formValues.${number}`;
}

export const RadioFormValueField: FunctionComponent<
  RadioFormValueFieldProps
> = props => {
  const { disabled: disabledProp, name } = props;
  const { control } = useFormContext<FormValueFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name,
    disabled: disabledProp,
  });
  const { field: value, fieldState: valueState } = useController({
    control,
    name: `${name}.value`,
    disabled: disabledProp,
  });
  const disabled = field.disabled || formState.disabled;
  const reactId = useId();
  const labelId = `${reactId}-${field.name}-label`;

  if (field.value.type !== 'radio') {
    return null;
  }

  if (disabled) {
    const { value: fieldValue } = field.value;

    return (
      <FormControl fullWidth required={field.value.required}>
        <FormLabel>{field.value.label}</FormLabel>

        <Text variant="body14">
          {field.value.options.find(option => option.value === fieldValue)
            ?.label ?? '-'}
        </Text>
      </FormControl>
    );
  }

  return (
    <FormControl
      fullWidth
      required={field.value.required}
      error={fieldState.invalid || valueState.invalid}
    >
      <FormLabel id={labelId}>{field.value.label}</FormLabel>

      <RadioGroup
        aria-labelledby={labelId}
        name={value.name}
        value={value.value}
        onBlur={value.onBlur}
        onChange={(ev, newValue) => {
          value.onChange(Number(newValue));
        }}
      >
        {field.value.options.map(option => {
          return (
            <FormControlLabel
              key={option.value}
              control={<Radio />}
              value={option.value}
              label={
                <>
                  {option.label}

                  {option.media && (
                    <ChatMedia
                      media={option.media}
                      onBlur={e => e.stopPropagation()}
                      css={css`
                        margin-top: 4px;
                        margin-bottom: 4px;
                      `}
                    />
                  )}
                </>
              }
            />
          );
        })}
      </RadioGroup>

      {valueState.error?.message && (
        <FormHelperText error>{valueState.error.message}</FormHelperText>
      )}

      {fieldState.error?.message && (
        <FormHelperText error>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  );
};
