import { useProject } from '@allganize/alli-works-project';
import { useId } from '@allganize/hooks';
import { useDeleteKnowledgeBaseNodes } from '@allganize/knowledge-base-actions';
import { IconButton } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcArrowDropDown, IcDelete } from '@allganize/ui-icons';
import { ListItemIcon, ListItemText } from '@allganize/ui-list';
import { Menu, MenuItem } from '@allganize/ui-menu';
import { FunctionComponent, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FragmentOf, gql, readFragment } from '../gql';
import { documentsDeleteEventEmitter } from '../utils/documents-delete-event-emitter';

export const DocumentsDetailActions_KnowledgeBaseNodeFragment = gql(`
  fragment DocumentsDetailActions_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    ownership

    parent {
      id
    }
  }
`);

interface DocumentsDetailActionsProps {
  node: FragmentOf<typeof DocumentsDetailActions_KnowledgeBaseNodeFragment>;
}

export const DocumentsDetailActions: FunctionComponent<
  DocumentsDetailActionsProps
> = props => {
  const { node: nodeProp } = props;
  const node = readFragment(
    DocumentsDetailActions_KnowledgeBaseNodeFragment,
    nodeProp,
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const project = useProject();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);
  const menuId = useId();
  const [deleting, setDeleting] = useState(false);
  const deleteKnowledgeBaseNodes = useDeleteKnowledgeBaseNodes();

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClick = () => {
    setMenuOpen(true);
  };

  const handleDelete = async () => {
    setDeleting(true);

    try {
      const result = await deleteKnowledgeBaseNodes({
        projectId: project.id,
        nodeIds: [node.id],
      });

      if (!result.success) {
        return;
      }

      const params = new URLSearchParams(searchParams);

      if (params.get('detail') === `${node.id}`) {
        params.delete('detail');
      }

      navigate({
        pathname: ['/documents', node.parent?.id].filter(Boolean).join('/'),
        search: params.toString(),
      });

      documentsDeleteEventEmitter.emit('delete', {
        projectId: project.id,
        nodeIds: [node.id],
      });
    } finally {
      setDeleting(false);
      closeMenu();
    }
  };

  if (node.ownership !== gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL')) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-controls={menuOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        shape="rounded"
        onClick={handleClick}
        ref={menuTriggerRef}
      >
        <IcArrowDropDown />
      </IconButton>

      <Menu
        id={menuId}
        anchorEl={menuTriggerRef.current}
        open={menuOpen}
        onClose={closeMenu}
      >
        <MenuItem disabled={deleting} onClick={handleDelete}>
          <ListItemIcon>
            {deleting ? <CircularProgress size="sm" /> : <IcDelete />}
          </ListItemIcon>

          <ListItemText
            primary={
              <FormattedMessage
                id="FAQ.Documents.DeleteButton"
                defaultMessage="Delete"
                description="FAQ documents delete button text"
              />
            }
          />
        </MenuItem>
      </Menu>
    </>
  );
};
