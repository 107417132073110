import { gql, ResultOf, Scalars } from '../gql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsFormValueDisabled_ChatFragment = gql(`
  fragment IsFormValueDisabled_ChatFragment on Chat {
    ...on SendFormChat {
      __typename
      id
    }

    ...on UserChat {
      __typename
      id
    }

    ...on EventChat {
      __typename
    }

    ...on CustomUIChat {
      __typename
    }

    ...on FillSlotRichUXChat {
      __typename
    }
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsFormValueDisabled_ConversationFragment = gql(`
  fragment IsFormValueDisabled_ConversationFragment on Conversation {
    state
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsFormValueDisabled_LastChatFragment = gql(`
  fragment IsFormValueDisabled_LastChatFragment on Chat {
    ...on AgentChat {
      id
    }

    ...on BotChat {
      id
    }

    ...on CarouselChat {
      id
    }

    ...on EventChat {
      id
    }

    ...on CustomUIChat {
      id
    }

    ...on FillSlotRichUXChat {
      id
    }

    ...on ContactAgentByEmailChat {
      id
    }

    ...on SendFormChat {
      id
    }

    ...on UserChat {
      id
    }
  }
`);

const conversationStates: (Scalars<'ConversationStateEnum'> | undefined)[] = [
  gql.scalar('ConversationStateEnum', 'WAIT_AGENT'),
  gql.scalar('ConversationStateEnum', 'INTERRUPTED'),
  gql.scalar('ConversationStateEnum', 'END_BY_USER'),
  gql.scalar('ConversationStateEnum', 'END_OF_CHATBOT'),
  gql.scalar('ConversationStateEnum', 'END_BY_AGENT'),
  gql.scalar('ConversationStateEnum', 'END_OF_OBSERVER'),
];

export interface IsFormValueDisabledOptions {
  chat: ResultOf<typeof IsFormValueDisabled_ChatFragment>;
  conversation?: ResultOf<
    typeof IsFormValueDisabled_ConversationFragment
  > | null;
  lastChat?: ResultOf<typeof IsFormValueDisabled_LastChatFragment>;
}

export const isFormValueDisabled = ({
  chat,
  conversation,
  lastChat,
}: IsFormValueDisabledOptions): boolean => {
  if (
    chat.__typename === 'EventChat' ||
    chat.__typename === 'CustomUIChat' ||
    chat.__typename === 'FillSlotRichUXChat'
  ) {
    return true;
  }

  if (chat.__typename !== 'SendFormChat') {
    return false;
  }

  if (chat.id !== lastChat?.id) {
    return true;
  }

  return conversationStates.includes(conversation?.state);
};
