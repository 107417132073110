import { ChatFormValidation } from '../types/types';

export const validation: ChatFormValidation = {
  file: {
    maxSize: {
      default: 75 * 1048576,
      image: 10 * 1048576,
    },
  },
};
