import { ContentState } from '@allganize/draft-input';
import { DraftAutoLinkPlugin } from '@allganize/draft-link-plugin';
import { useMemo } from 'react';
import { defaultPlugins } from '../rich-text/use-rich-text.const';

const plugins = defaultPlugins.filter(
  plugin => plugin instanceof DraftAutoLinkPlugin,
);

export interface UsePlainTextOptions {
  value: string | null;
}

export const usePlainText = ({ value: valueProp }: UsePlainTextOptions) => {
  const value = useMemo<ContentState | null>(() => {
    if (!valueProp) {
      return null;
    }

    return ContentState.createFromText(valueProp, '\n');
  }, [valueProp]);

  return { value, plugins };
};
