import {
  ExtendIconButton,
  ExtendIconButtonTypeMap,
  IconButton,
} from '@allganize/ui-button';
import { IcArrowDropDown } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { pickersCalendarHeaderClasses } from './pickers-calendar-header-classes';
import { PickersCalendarHeaderOwnerState } from './pickers-calendar-header-type-map';

type PickersCalendarHeaderSwitchViewButtonTypeMap<
  P = {},
  D extends React.ElementType = 'button',
> = ExtendIconButtonTypeMap<{
  props: P & {
    ownerState: PickersCalendarHeaderOwnerState;
  };
  defaultComponent: D;
}>;

// @ts-expect-error overridable component
export const PickersCalendarHeaderSwitchViewButton: ExtendIconButton<PickersCalendarHeaderSwitchViewButtonTypeMap> =
  forwardRef((props, ref) => {
    const { ownerState, ...other } = props;

    return (
      <IconButton
        css={[
          css`
            margin-right: auto;
          `,
          ownerState.view === 'year' &&
            css`
              .${pickersCalendarHeaderClasses.switchViewIcon} {
                transform: rotate(180deg);
              }
            `,
        ]}
        {...other}
        ref={ref}
      />
    );
  });

// @ts-expect-error overridable component
export const PickersCalendarHeaderSwitchViewIcon: typeof IcArrowDropDown =
  forwardRef((props, ref) => {
    const theme = useTheme();

    return (
      <IcArrowDropDown
        css={css`
          will-change: transform;
          transition: ${theme.transitions.create('transform')};
          transform: rotate(0deg);
        `}
        {...props}
        ref={ref}
      />
    );
  });
