import { useProject } from '@allganize/alli-works-project';
import { useEventCallback } from '@allganize/hooks';
import { IconButton } from '@allganize/ui-button';
import { Drawer, drawerClasses } from '@allganize/ui-drawer';
import {
  IcAppMarket,
  IcArrowHideSide,
  IcArrowOpenSide,
  IcDocuments,
} from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { toastClasses } from '@allganize/ui-toast';
import { css, Global, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode, Suspense, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { analytics } from '../../analytics';
import { AlliBranding } from '../alli-branding/alli-branding';
import { HistoryList } from './history-list';
import { MenuList } from './menu-list';
import { MenuListItem } from './menu-list-item';
import { UserMenu } from './user-menu';

const lnbWidth = 280;
const lnbWidthCollapsed = 48;

const LogoArea = styled(Link)`
  text-decoration: none;
`;

const MenuArea = styled.div`
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

const ContentArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

interface LNBProps {
  logo: ReactNode;
}

export const LNB = ({ logo }: LNBProps) => {
  const theme = useTheme();
  const { appCollectionSettings } = useProject();
  const [isLnbOpen, setIsLnbOpen] = useState(true);
  const [animatingWidth, setAnimatingWidth] = useState(false);

  const handleClickApps = useEventCallback(() => {
    analytics.track('lnb::all-apps.click');
  });
  const handleClickLogo = useEventCallback(() => {
    analytics.track('lnb::logo.click');
  });

  const handleLnbToggleClick = () => {
    setIsLnbOpen(prev => !prev);
    setAnimatingWidth(true);
  };

  const handleTransitionEnd = (ev: React.TransitionEvent<HTMLDivElement>) => {
    if (ev.target !== ev.currentTarget) {
      return;
    }

    setAnimatingWidth(false);
  };

  return (
    <Drawer
      css={[
        css`
          flex-shrink: 0;
          overflow-x: hidden;

          .${drawerClasses.paper} {
            background-color: ${theme.palette.unstable_background.default};
            border-right: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
          }

          &,
          & .${drawerClasses.paper} {
            transition: ${theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            })};
            width: ${lnbWidthCollapsed}px;
          }
        `,
        isLnbOpen &&
          css`
            &,
            & .${drawerClasses.paper} {
              transition: ${theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              })};
              width: ${lnbWidth}px;
            }
          `,
      ]}
      variant="permanent"
      open={isLnbOpen}
      onTransitionEnd={handleTransitionEnd}
    >
      <Global
        styles={css`
          .${toastClasses.positionBottomLeft},
            .${toastClasses.positionTopLeft} {
            margin-left: ${isLnbOpen ? lnbWidth : lnbWidthCollapsed}px;
          }
        `}
      />

      <div
        css={[
          css`
            display: flex;
            align-items: flex-start;
            min-height: 68px;
            margin-bottom: 8px;
            padding: 16px 8px;
            transition: ${theme.transitions.create('padding', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            })};
          `,
          isLnbOpen &&
            css`
              padding: 16px;
              transition: ${theme.transitions.create('padding', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              })};
            `,
        ]}
      >
        <div
          css={css`
            flex-grow: 1;
          `}
        >
          {isLnbOpen && !animatingWidth && (
            <LogoArea to="/" onClick={handleClickLogo}>
              {logo}
            </LogoArea>
          )}
        </div>

        <IconButton
          css={css`
            margin: 2px 0;
          `}
          shape="rounded"
          onClick={handleLnbToggleClick}
        >
          {isLnbOpen ? <IcArrowOpenSide /> : <IcArrowHideSide />}
        </IconButton>
      </div>

      {isLnbOpen && !animatingWidth && (
        <>
          <MenuArea>
            <MenuList>
              <MenuListItem
                to="/apps"
                icon={<IcAppMarket />}
                onClick={handleClickApps}
              >
                <FormattedMessage
                  id="AppMarket.LNB.MenuList.Apps"
                  defaultMessage="Apps"
                  description="app market lnb apps menu text"
                />
              </MenuListItem>

              {!appCollectionSettings?.hideWorksKb && (
                <MenuListItem
                  to="/documents"
                  icon={<IcDocuments />}
                  onClick={handleClickApps}
                >
                  <span
                    css={css`
                      display: inline-flex;
                      align-items: center;
                    `}
                  >
                    <FormattedMessage
                      id="documents"
                      defaultMessage="Documents"
                      description="documents text"
                    />

                    <Text
                      variant="subtitle12"
                      component="span"
                      css={css`
                        display: inline-flex;
                        align-items: center;
                        padding: 0 4px;
                        height: 18px;
                        border-radius: ${theme.radius.xs}px;
                        margin-left: 6px;
                        font-size: 10px;
                        line-height: 1.6;
                        background-color: ${theme.palette.unstable_background
                          .primary.default};
                        color: ${theme.palette.foreground.inverse};
                      `}
                    >
                      New
                    </Text>
                  </span>
                </MenuListItem>
              )}
            </MenuList>
          </MenuArea>
          <ContentArea>
            <Suspense fallback={null}>
              <HistoryList />
            </Suspense>
          </ContentArea>
          <footer>
            <UserMenu />

            <AlliBranding />
          </footer>
        </>
      )}
    </Drawer>
  );
};
