import { createIllustration } from '../create-illustration';
import candidates from './candidates.png';
import candidates2x from './candidates@2x.png';
import candidates3x from './candidates@3x.png';

export const Candidates = createIllustration({
  src: candidates,
  srcSet: [candidates, candidates2x, candidates3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 64,
});
