import { LoadingButton } from '@allganize/ui-button';
import { FormHelperText } from '@allganize/ui-form';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormValuesField, FormValuesFieldProps } from './form-values-field';
import {
  TargetAgentsField,
  TargetAgentsFieldProps,
} from './target-agents-field';
import {
  useFormValueForm,
  UseFormValueFormOptions,
} from './use-form-value-form';

export interface FormValueFormProps
  extends Pick<FormValuesFieldProps, 'slotProps' | 'slots'>,
    UseFormValueFormOptions {
  targetAgents?: Pick<TargetAgentsFieldProps, 'disabled' | 'options'>;
}

export const FormValueForm: FunctionComponent<FormValueFormProps> = props => {
  const {
    defaultValues,
    disabled,
    onSubmit,
    targetAgents,
    slotProps,
    slots,
    validation,
  } = props;
  const { form, formRef, submit } = useFormValueForm({
    defaultValues,
    disabled,
    onSubmit,
    targetAgents,
    validation,
  });

  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = form;

  return (
    <form
      css={css`
        margin: 0;
      `}
      onSubmit={handleSubmit(submit)}
      ref={formRef}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <FormProvider {...form}>
          <FormValuesField slotProps={slotProps} slots={slots} />

          <TargetAgentsField {...targetAgents} />
        </FormProvider>
      </div>

      {errors.root?.message && (
        <FormHelperText error>{errors.root?.message}</FormHelperText>
      )}

      {!disabled && (
        <LoadingButton
          css={css`
            margin-top: 16px;
          `}
          type="submit"
          fullWidth
          color="primary"
          size="large"
          variant="filled"
          disabled={!isValid}
          loading={isSubmitting}
        >
          <FormattedMessage
            id="actions.submit"
            defaultMessage="Submit"
            description="Submit button text"
          />
        </LoadingButton>
      )}
    </form>
  );
};
