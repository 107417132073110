import { useConversationList } from '@allganize/alli-sdk/hooks/use-conversation-list';
import { useAssistantCampaign } from '@allganize/alli-sdk/project/use-assistant-campaign';
import { useEventCallback } from '@allganize/hooks';
import {
  isLLMAppInformationIconVariant,
  LLMAppInformationIcon,
} from '@allganize/llm-app-icons';
import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import { IcClose, IcConversations } from '@allganize/ui-icons';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  listItemButtonClasses,
  listItemClasses,
  listItemSecondaryActionClasses,
} from '@allganize/ui-list';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { MouseEvent, forwardRef, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useMatch } from 'react-router-dom';
import { analytics } from '../../analytics';
import { DeleteConversationModal } from '../modal/delete-conversation-modal';

export const listItemSize = 36;

const catchOnClickEvent = (e: MouseEvent) => {
  // catch click event to prevent unnecessary navigation
  e.preventDefault();
  e.stopPropagation();
};

const ItemWrapper = styled(ListItemButton)`
  border-radius: ${props => props.theme.radius.sm}px;
  min-height: ${listItemSize}px;
  padding: 0 8px;

  &.${listItemButtonClasses.selected} {
    &,
    &:hover {
      background-color: ${props =>
        props.theme.palette.unstable_background.white};
      cursor: auto;
    }
  }
`;

type ConversationsQuery = ReturnType<typeof useConversationList>['data'];
type ConversationNode = NonNullable<
  NonNullable<
    NonNullable<ConversationsQuery['conversations']>['edges'][number]
  >['node']
>;

interface HistoryListItemBaseProps {
  data: Pick<ConversationNode, 'id' | 'llmApp' | 'title' | 'createdAt'>;
  selected?: boolean;
}

const HistoryListItemBase = forwardRef<
  HTMLDivElement,
  HistoryListItemBaseProps
>(({ data, selected }: HistoryListItemBaseProps, ref) => {
  const { llmApp } = data;
  const theme = useTheme();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { isAssistantCampaign } = useAssistantCampaign();
  const conversationType = useMemo(
    () => (isAssistantCampaign(llmApp?.campaign?.id) ? 'chat' : 'app'),
    [llmApp?.campaign?.id, isAssistantCampaign],
  );
  const handleOpenModal = useEventCallback((e: MouseEvent) => {
    analytics.track('lnb::conversation-history__delete-button.click', {
      conversationId: data.id,
      appId: data.llmApp?.id,
      conversationType,
    });
    catchOnClickEvent(e);
    setOpenDeleteModal(true);
  });
  const handleCloseModal = useEventCallback((e: MouseEvent) => {
    catchOnClickEvent(e);
    setOpenDeleteModal(false);
  });
  const handleClick = useEventCallback(() => {
    analytics.track('lnb::conversation-history.click', {
      appId: data.llmApp?.id,
      conversationType,
      conversationId: data.id,
    });
  });
  const handleConfirm = useEventCallback(() => {
    analytics.track('lnb::conversation-history__popup-delete-button.click', {
      appId: data.llmApp?.id,
      conversationType,
      conversationId: data.id,
    });
  });

  let icon = <IcConversations fontSize="small" />;
  const appIconVariant = isLLMAppInformationIconVariant(llmApp?.icon)
    ? llmApp.icon
    : null;

  if (conversationType === 'app' && appIconVariant) {
    icon = <LLMAppInformationIcon variant={appIconVariant} fontSize="small" />;
  }

  return (
    <ListItem
      disablePadding
      component="div"
      css={css`
        &.${listItemClasses.secondaryAction} > .${listItemButtonClasses.root} {
          padding-right: 0;
        }

        .${listItemSecondaryActionClasses.root} {
          display: none;
        }

        &:hover {
          &.${listItemClasses.secondaryAction}
            > .${listItemButtonClasses.root} {
            padding-right: 36px;
          }

          .${listItemSecondaryActionClasses.root} {
            display: block;
          }
        }
      `}
      secondaryAction={
        <IconButton
          edge="end"
          shape="rounded"
          variant="ghost"
          onClick={handleOpenModal}
        >
          <IcClose />
        </IconButton>
      }
    >
      <ItemWrapper selected={selected} ref={ref} onClick={handleClick}>
        <ListItemIcon
          css={css`
            color: ${theme.palette.foreground.secondary};
            margin-right: 4px;
          `}
        >
          {icon}
        </ListItemIcon>

        <ListItemText
          primaryTextProps={{ variant: 'body14', component: 'div' }}
          primary={
            <Truncate clamp={1}>
              {data.title || (
                <FormattedMessage
                  id="conversation.title.fallback"
                  defaultMessage="Conversation at {createdAt, date}"
                  description="conversation default title"
                  values={{
                    createdAt: data.createdAt,
                  }}
                />
              )}
            </Truncate>
          }
        />

        <DeleteConversationModal
          open={openDeleteModal}
          conversationId={data.id}
          onClose={handleCloseModal}
          onClick={catchOnClickEvent}
          onConfirm={handleConfirm}
        />
      </ItemWrapper>
    </ListItem>
  );
});

interface HistoryListItemProps extends Pick<HistoryListItemBaseProps, 'data'> {}

export const HistoryListItem = ({ data }: HistoryListItemProps) => {
  const match = useMatch(`/conversations/${data.id}`);

  if (match) {
    return <HistoryListItemBase selected data={data} />;
  }

  return (
    <Link to={`/conversations/${data.id}`}>
      <HistoryListItemBase data={data} />
    </Link>
  );
};
