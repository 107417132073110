import { FormControl, FormLabel } from '@allganize/ui-form';
import { NumberField } from '@allganize/ui-number-input';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormValueFormValues } from './use-form-value-form';
import { Text } from '@allganize/ui-text';

interface NumberFormValueFieldProps {
  disabled?: boolean;
  name: `formValues.${number}`;
}

export const NumberFormValueField: FunctionComponent<
  NumberFormValueFieldProps
> = props => {
  const { disabled: disabledProp, name } = props;
  const { control } = useFormContext<FormValueFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name,
    disabled: disabledProp,
  });
  const {
    field: { ref: valueRef, ...value },
    fieldState: valueState,
  } = useController({
    control,
    name: `${name}.value`,
    disabled: disabledProp,
  });
  const disabled = field.disabled || formState.disabled;

  if (field.value.type !== 'number') {
    return null;
  }

  if (disabled) {
    return (
      <FormControl fullWidth required={field.value.required}>
        <FormLabel>{field.value.label}</FormLabel>

        <Text variant="body14">{field.value.value ?? '-'}</Text>
      </FormControl>
    );
  }

  return (
    <NumberField
      {...value}
      onChange={undefined}
      value={value.value as number | null}
      onValueChange={({ floatValue }) => {
        value.onChange(floatValue ?? null);
      }}
      inputRef={valueRef}
      label={field.value.label}
      required={field.value.required}
      error={fieldState.invalid || valueState.invalid}
      helperText={fieldState.error?.message || valueState.error?.message}
      fullWidth
    />
  );
};
