import { createIllustration } from '../create-illustration';
import noCredit from './no-credit.png';
import noCredit2x from './no-credit@2x.png';
import noCredit3x from './no-credit@3x.png';

export const NoCredit = createIllustration({
  src: noCredit,
  srcSet: [noCredit, noCredit2x, noCredit3x]
    .map((src, i) => `${src} ${i + 1}x`)
    .join(','),
  size: 160,
});
