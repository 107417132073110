import {
  DocumentViewerPageInfo,
  DocumentViewerPageThumbnailInfo,
} from '@allganize/alli-document';
import { TabOption } from '@allganize/alli-works-documents';
import {
  useTabNavigator as useTabNavigatorBase,
  TabNavigatorContextValue as TabNavigatorContextValueBase,
  createTabNavigatorContext,
  TabWithId,
} from '@allganize/tab-navigator';

export type DocumentPreviewMetadata = {
  initialPage: number;
  pages: (DocumentViewerPageInfo | (() => Promise<DocumentViewerPageInfo>))[];
  thumbnails: DocumentViewerPageThumbnailInfo[];
  title: string;
  originalWebUri?: string | null;
};

export type DocumentListMetadata =
  | {
      tab?: TabOption;
      searchTerm?: string;
      selectedKnowledgeBaseIds?: (string | number)[];
      openUpload?: boolean;
    }
  | undefined;

export type AppPreviewMetadata = {
  singleActionApp: { id: string | number; publicToken: string };
};

export type Tab =
  | {
      type: 'DOCUMENT_PREVIEW';
      label: string;
      metadata: DocumentPreviewMetadata;
    }
  | {
      type: 'APP_PREVIEW';
      label: string;
      metadata: AppPreviewMetadata;
    }
  | {
      type: 'DOCUMENT_LIST';
      label: string;
      metadata: DocumentListMetadata;
    }
  | {
      type: 'APP_LIST';
      label: string;
    };

export type TabNavigatorContextValue = TabNavigatorContextValueBase<Tab>;

export const TabNavigatorContext = createTabNavigatorContext<Tab>();

export const useTabNavigator = useTabNavigatorBase<Tab>;

export const isDocumentPreviewTab = (
  tab: TabWithId<Tab> | null,
): tab is TabWithId<Extract<Tab, { type: 'DOCUMENT_PREVIEW' }>> =>
  tab?.type === 'DOCUMENT_PREVIEW';

export const isDocumentListTab = (
  tab: TabWithId<Tab> | null,
): tab is TabWithId<Extract<Tab, { type: 'DOCUMENT_LIST' }>> =>
  tab?.type === 'DOCUMENT_LIST';
