import {
  AccessAgentList,
  AccessAgentList_AccessAgentFragment,
  AccessCustomerList,
  AccessCustomerList_AccessUserFilterFragment,
} from '@allganize/access-control';
import { useTheme } from '@allganize/ui-theme';
import { Text } from '@allganize/ui-text';
import { IcLock, IcLockOpen } from '@allganize/ui-icons';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';
import { PanelItem } from '../panel-item/panel-item';
import { PanelItemGroup } from '../panel-item/panel-item-group';

const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const AccessControlList_KnowledgeBaseNodeFragment = gql(
  `
  fragment AccessControlList_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    id
    viewAccessToAgent
    viewAccessToUser

    accessAgents {
      ...AccessAgentList_AccessAgentFragment
    }

    accessUserFilters {
      ...AccessCustomerList_AccessUserFilterFragment
    }
  }
`,
  [
    AccessAgentList_AccessAgentFragment,
    AccessCustomerList_AccessUserFilterFragment,
  ],
);

export interface AccessControlListProps {
  node: FragmentOf<typeof AccessControlList_KnowledgeBaseNodeFragment>;
}

export const AccessControlList: FunctionComponent<
  AccessControlListProps
> = props => {
  const { node: nodeProp } = props;
  const node = readFragment(
    AccessControlList_KnowledgeBaseNodeFragment,
    nodeProp,
  );
  const theme = useTheme();

  if (!node.accessAgents && !node.accessUserFilters) {
    return null;
  }

  return (
    <PanelItemGroup>
      <PanelItem
        label={
          <FormattedMessage
            id="knowledge-base-side-panel.access-control"
            defaultMessage="Who has access"
            description="Knowledge base side panel access control label text"
          />
        }
      >
        <div
          css={css`
            color: ${theme.palette.foreground.secondary};
          `}
        >
          <Container>
            <Text
              variant="subtitle12"
              css={css`
                flex-grow: 1;
              `}
            >
              <FormattedMessage
                id="knowledge-base-side-panel.access-control.agent"
                defaultMessage="Agent"
                description="Knowledge base side panel access control agent label text"
              />
            </Text>
            <AccessAgentList agents={node.accessAgents ?? []} />
            {node.viewAccessToAgent ? (
              <Tooltip
                title={
                  <FormattedMessage
                    id="knowledge-base-side-panel.access-control.agent-unlock"
                    defaultMessage="Allow all agents to view"
                    description="Knowledge base side panel access control agent unlock tooltip text"
                  />
                }
              >
                <IcLockOpen />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <FormattedMessage
                    id="knowledge-base-side-panel.access-control.agent-lock"
                    defaultMessage="Allow listed agents can only view"
                    description="Knowledge base side panel access control agent lock tooltip text"
                  />
                }
              >
                <IcLock />
              </Tooltip>
            )}
          </Container>
          <Container
            css={css`
              margin-top: 8px;
            `}
          >
            <Text
              variant="subtitle12"
              css={css`
                flex-grow: 1;
              `}
            >
              <FormattedMessage
                id="knowledge-base-side-panel.access-control.customer"
                defaultMessage="Customer"
                description="Knowledge base side panel access control customer label text"
              />
            </Text>
            <AccessCustomerList customers={node.accessUserFilters} />
            {node.viewAccessToUser ? (
              <Tooltip
                title={
                  <FormattedMessage
                    id="knowledge-base-side-panel.access-control.customer-unlock"
                    defaultMessage="Any customers can view"
                    description="Knowledge base side panel access control customer unlock tooltip text"
                  />
                }
              >
                <IcLockOpen />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <FormattedMessage
                    id="knowledge-base-side-panel.access-control.customer-lock"
                    defaultMessage="Restrict to specific customers"
                    description="Knowledge base side panel access control customer lock tooltip text"
                  />
                }
              >
                <IcLock />
              </Tooltip>
            )}
          </Container>
        </div>
      </PanelItem>
    </PanelItemGroup>
  );
};
