import { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';
import { formatUserName } from '../utils/formatUserName';
import { AccessUserListBase } from './access-user-list-base';

export const AccessAgentList_AccessAgentFragment = gql(`
  fragment AccessAgentList_AccessAgentFragment on AccessAgent {
    accessType

    agent {
      id
      firstName
      lastName

      avatar {
        id
        url
      }
    }
  }
`);

export interface AccessAgentListProps {
  agents: FragmentOf<typeof AccessAgentList_AccessAgentFragment>[];
}

export const AccessAgentList: FunctionComponent<
  AccessAgentListProps
> = props => {
  const { agents: agentsProp } = props;
  const agents = readFragment(AccessAgentList_AccessAgentFragment, agentsProp);
  const intl = useIntl();
  const data = useMemo(
    () =>
      agents.map((agent, i) => ({
        id: [agent.agent?.id, i].join(','),
        name: agent.agent ? formatUserName(agent.agent, intl.locale) : '',
        avatar: agent.agent?.avatar?.url,
      })),
    [agents, intl.locale],
  );

  return <AccessUserListBase data={data} />;
};
