import { OverridableComponent } from '@allganize/types';
import {
  IcAnalytics,
  IcArticle,
  IcAutoGraph,
  IcAutoMode,
  IcAutomation,
  IcBalance,
  IcBusiness,
  IcCake,
  IcCalendar,
  IcDocumentSearch,
  IcDomain,
  IcEdit,
  IcEmojiSmile,
  IcFace,
  IcFactCheck,
  IcFavorite,
  IcFile,
  IcFitbit,
  IcFolderZip,
  IcFormat,
  IcLightBulb,
  IcManageSearch,
  IcOptimization,
  IcOutgoingMail,
  IcPeople,
  IcPodcast,
  IcPostAdd,
  IcRoomPreference,
  IcSaving,
  IcSchool,
  IcSecurity,
  IcSendMessage,
  IcTravelExplore,
  IcUpdate,
  IcVariable,
} from '@allganize/ui-icons';
import { forwardRef } from 'react';
import { LLMAppInformationIconTypeMap } from './llm-app-information-icon.types';

// @ts-expect-error overridable component
export const LLMAppInformationIcon: OverridableComponent<LLMAppInformationIconTypeMap> =
  forwardRef((props, ref) => {
    const { variant, ...other } = props;

    switch (variant) {
      case 'ic-balance':
        return <IcBalance {...other} ref={ref} />;
      case 'ic-variable':
        return <IcVariable {...other} ref={ref} />;
      case 'ic-article':
        return <IcArticle {...other} ref={ref} />;
      case 'ic-analytics':
        return <IcAnalytics {...other} ref={ref} />;
      case 'ic-auto-mode':
        return <IcAutoMode {...other} ref={ref} />;
      case 'ic-cake':
        return <IcCake {...other} ref={ref} />;
      case 'ic-calendar':
        return <IcCalendar {...other} ref={ref} />;
      case 'ic-update':
        return <IcUpdate {...other} ref={ref} />;
      case 'ic-business':
        return <IcBusiness {...other} ref={ref} />;
      case 'ic-document-search':
        return <IcDocumentSearch {...other} ref={ref} />;
      case 'ic-domain':
        return <IcDomain {...other} ref={ref} />;
      case 'ic-face':
        return <IcFace {...other} ref={ref} />;
      case 'ic-fact-check':
        return <IcFactCheck {...other} ref={ref} />;
      case 'ic-favorite':
        return <IcFavorite {...other} ref={ref} />;
      case 'ic-fitbit':
        return <IcFitbit {...other} ref={ref} />;
      case 'ic-folder-zip':
        return <IcFolderZip {...other} ref={ref} />;
      case 'ic-format':
        return <IcFormat {...other} ref={ref} />;
      case 'ic-people':
        return <IcPeople {...other} ref={ref} />;
      case 'ic-send-message':
        return <IcSendMessage {...other} ref={ref} />;
      case 'ic-file':
        return <IcFile {...other} ref={ref} />;
      case 'ic-auto-graph':
        return <IcAutoGraph {...other} ref={ref} />;
      case 'ic-light-bulb':
        return <IcLightBulb {...other} ref={ref} />;
      case 'ic-manage-search':
        return <IcManageSearch {...other} ref={ref} />;
      case 'ic-outgoing-mail':
        return <IcOutgoingMail {...other} ref={ref} />;
      case 'ic-edit':
        return <IcEdit {...other} ref={ref} />;
      case 'ic-podcast':
        return <IcPodcast {...other} ref={ref} />;
      case 'ic-post-add':
        return <IcPostAdd {...other} ref={ref} />;
      case 'ic-room-preference':
        return <IcRoomPreference {...other} ref={ref} />;
      case 'ic-saving':
        return <IcSaving {...other} ref={ref} />;
      case 'ic-school':
        return <IcSchool {...other} ref={ref} />;
      case 'ic-security':
        return <IcSecurity {...other} ref={ref} />;
      case 'ic-emoji-smile':
        return <IcEmojiSmile {...other} ref={ref} />;
      case 'ic-automation':
        return <IcAutomation {...other} ref={ref} />;
      case 'ic-travel-explore':
        return <IcTravelExplore {...other} ref={ref} />;
      case 'ic-optimization':
        return <IcOptimization {...other} ref={ref} />;
      default:
        break;
    }

    return null;
  });
