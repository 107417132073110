import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { useMutation } from '@apollo/client/react';
import { gql, Scalars } from '../gql';
import { KnowledgeBaseBuildStateFragment } from './training-dialog';

const BuildMRCMutation = gql(
  `
  mutation BuildMRCMutation(
    $where: ProjectWhereUniqueInput!
    $cancelPrevious: Boolean
  ) {
    buildMrc(
      where: $where
      cancelPrevious: $cancelPrevious
    ) {
      project {
        id
        ...KnowledgeBaseBuildStateFragment
      }

      errors {
        __typename
        key
        message
        info
        field
      }
    }
  }
`,
  [KnowledgeBaseBuildStateFragment],
);

export interface UseBuildMRCOptions {
  projectId: Scalars<'ID'>;
  onSubmit?(): void;
}

export const useBuildMRC = ({ projectId, onSubmit }: UseBuildMRCOptions) => {
  const formatErrorMessage = useFormatErrorMessage();
  const [mutate] = useMutation(BuildMRCMutation);

  const submit = async (cancelPrevious = false) => {
    const result = await mutate({
      variables: {
        where: { id: projectId },
        cancelPrevious,
      },
    });

    if (result.data?.buildMrc?.project) {
      onSubmit?.();
      return;
    }

    if (result.data?.buildMrc?.errors?.length) {
      throw new AlliGraphQLError(result.data.buildMrc.errors);
    }

    throw new Error(formatErrorMessage());
  };

  return {
    submit,
  };
};
