import { ChatStackItem, ChatStackItemProps } from '@allganize/alli-chat-base';
import { carouselArrowClasses, carouselClasses } from '@allganize/ui-carousel';
import { css } from '@emotion/react';
import { CreateSlotsAndSlotProps } from '@mui/material/utils/types';
import { forwardRef } from 'react';
import {
  ChatMrcInfoList,
  ChatMrcInfoList_CarouselChatFragment,
  ChatMrcInfoListSlotProps,
  ChatMrcInfoListSlots,
} from '../chat-mrc-info-list';
import { FragmentOf, gql, readFragment } from '../gql';

const horizontalPadding = 12;

export const ChatMrcInfo_ChatFragment = gql(
  `
  fragment ChatMrcInfo_ChatFragment on Chat {
    ...on CarouselChat {
      __typename
      id

      chatMrcInfos {
        id
      }

      ...ChatMrcInfoList_CarouselChatFragment
    }
  }
`,
  [ChatMrcInfoList_CarouselChatFragment],
);

export interface ChatMrcInfoSlots extends ChatMrcInfoListSlots {}

export interface ChatMrcInfoSlotProps extends ChatMrcInfoListSlotProps {}

export type ChatMrcInfoSlotsAndSlotProps = CreateSlotsAndSlotProps<
  ChatMrcInfoSlots,
  ChatMrcInfoSlotProps
>;

export interface ChatMrcInfoProps
  extends ChatStackItemProps,
    ChatMrcInfoSlotsAndSlotProps {
  chat: FragmentOf<typeof ChatMrcInfo_ChatFragment>;
}

export const ChatMrcInfo = forwardRef<HTMLDivElement, ChatMrcInfoProps>(
  (props, ref) => {
    const { chat: chatProp, slotProps, slots, ...other } = props;
    const chat = readFragment(ChatMrcInfo_ChatFragment, chatProp);

    if (chat.__typename !== 'CarouselChat') {
      return null;
    }

    if (!chat.chatMrcInfos?.length) {
      return null;
    }

    return (
      <ChatStackItem {...other} ref={ref}>
        <ChatMrcInfoList
          css={css`
            .${carouselArrowClasses.root}.${carouselArrowClasses.prev} {
              right: ${horizontalPadding + 36}px;
            }

            .${carouselArrowClasses.root}.${carouselArrowClasses.next} {
              right: ${horizontalPadding}px;
            }

            .${carouselClasses.list} {
              padding-left: ${horizontalPadding - 4}px;
            }
          `}
          chat={chat}
          slotProps={slotProps}
          slots={slots}
        />
      </ChatStackItem>
    );
  },
);
