import {
  AlliChatRow,
  AlliChatRowItem,
  AlliChatRowItemProps,
  ChatStackItem,
  ChatStackItemProps,
  TimestampTooltip,
} from '@allganize/alli-chat-base';
import { IcError } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentOf, gql, readFragment } from '../gql';

export const GeneratingErrorState_ChatFragment = gql(`
  fragment GeneratingErrorState_ChatFragment on Chat {
    ...on BotChat {
      __typename
      id
      createdAt
      completeState
    }
  }
`);

interface GeneratingErrorStateProps
  extends Pick<AlliChatRowItemProps, 'avatar'>,
    ChatStackItemProps {
  chat: FragmentOf<typeof GeneratingErrorState_ChatFragment>;
}

export const GeneratingErrorState: FunctionComponent<
  GeneratingErrorStateProps
> = props => {
  const { avatar, chat: chatProp, ...other } = props;
  const chat = readFragment(GeneratingErrorState_ChatFragment, chatProp);
  const theme = useTheme();

  if (chat.__typename !== 'BotChat') {
    return null;
  }

  if (
    chat.completeState === null ||
    chat.completeState === gql.scalar('ChatCompleteState', 'SUCCESS') ||
    chat.completeState === gql.scalar('ChatCompleteState', 'PROGRESS')
  ) {
    return null;
  }

  return (
    <ChatStackItem {...other}>
      <AlliChatRow>
        <TimestampTooltip align="left" timestamp={chat.createdAt}>
          <AlliChatRowItem avatar={avatar}>
            <IcError
              color="error"
              css={css`
                vertical-align: middle;
              `}
            />{' '}
            <Text
              css={css`
                vertical-align: middle;
                color: ${theme.palette.foreground.error};
              `}
              variant="body14"
              component="span"
            >
              <FormattedMessage
                id={`ChatCompletedErr.${chat.completeState}`}
                defaultMessage={`Something's wrong, please try again ${chat.completeState}`}
                description={`Generative ans error msg for ${chat.completeState} state`}
              />
            </Text>
          </AlliChatRowItem>
        </TimestampTooltip>
      </AlliChatRow>
    </ChatStackItem>
  );
};
