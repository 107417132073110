import { createSvgIcon } from '@allganize/ui-icons';

export const IcFileZIP = createSvgIcon(
  <>
    <path
      d="M6 22.5469H20.3077C21.1361 22.5469 21.8077 21.8753 21.8077 21.0469V7.69265C21.8077 7.29885 21.6528 6.92085 21.3765 6.64024L16.7099 1.90072C16.428 1.61437 16.0429 1.45312 15.6411 1.45312H6C5.17157 1.45312 4.5 2.1247 4.5 2.95312V21.0469C4.5 21.8753 5.17157 22.5469 6 22.5469Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0.889999H11V3H13V4H11V5H13V6H11V7H13V8H11V11H10V9H8V8H10V7H8V6H10V5H8V4H10V3H8V2H10V0.889999Z"
      fill="#666666"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.0625 2.95312C5.0625 2.43536 5.48223 2.01562 6 2.01562H15.6411C15.6631 2.01562 15.685 2.0164 15.7068 2.01793V5.57812C15.7068 6.71721 16.6302 7.64062 17.7693 7.64062H21.2437C21.2447 7.6579 21.2452 7.67525 21.2452 7.69265V21.0469C21.2452 21.5646 20.8255 21.9844 20.3077 21.9844H6C5.48223 21.9844 5.0625 21.5646 5.0625 21.0469V2.95312ZM20.4644 6.51562L16.8318 2.82621V5.57812C16.8318 6.09589 17.2515 6.51562 17.7693 6.51562H20.4644ZM6 0.890625C4.86091 0.890625 3.9375 1.81404 3.9375 2.95312V21.0469C3.9375 22.186 4.86092 23.1094 6 23.1094H20.3077C21.4468 23.1094 22.3702 22.186 22.3702 21.0469V7.69265C22.3702 7.15117 22.1573 6.63142 21.7774 6.24559L17.1108 1.50607C16.7231 1.11234 16.1936 0.890625 15.6411 0.890625H6Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M0.75 12.75C0.75 11.0931 2.09315 9.75 3.75 9.75H11.5C13.1569 9.75 14.5 11.0931 14.5 12.75V17.25C14.5 18.9069 13.1569 20.25 11.5 20.25H3.75C2.09315 20.25 0.75 18.9069 0.75 17.25V12.75Z"
      fill="#666666"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.1001 16.5915V17.2395H6.3641V16.3395H4.4201L6.3461 13.4175V12.7695H3.2861V13.6635H5.0261L3.1001 16.5915ZM7.09717 17.2395V12.7695H8.17117V17.2395H7.09717ZM9.19482 17.2395V12.7695H10.7668C11.7448 12.7695 12.5548 13.1235 12.5548 14.2275C12.5548 15.2895 11.7388 15.7575 10.7908 15.7575H10.2688V17.2395H9.19482ZM10.2688 14.9115H10.7368C11.2588 14.9115 11.5108 14.6655 11.5108 14.2275C11.5108 13.7775 11.2228 13.6215 10.7068 13.6215H10.2688V14.9115Z"
      fill="white"
    />
  </>,
  'ic-file-zip',
);
