import {
  ChatDivider,
  ChatStack,
  ChatStackItem,
} from '@allganize/alli-chat-base';
import { Avatar } from '@allganize/ui-avatar';
import { IcOutgoingMail } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { gql, ResultOf } from '../gql';
import {
  EventChatMessage,
  EventChatMessage_EventChatFragment,
} from './event-chat-message';

export const EventChat_EventChatFragment = gql(
  `
  fragment EventChat_EventChatFragment on EventChat {
    id
    eventType

    enteringAgent {
      id
      firstName
      lastName
      displayName

      avatar {
        id
        url
      }
    }

    ...EventChatMessage_EventChatFragment
  }
`,
  [EventChatMessage_EventChatFragment],
);

const iconStyle = css`
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
`;

interface EventChatProps {
  chat: ResultOf<typeof EventChat_EventChatFragment>;
}

export const EventChat = forwardRef<HTMLDivElement, EventChatProps>(
  (props, ref) => {
    const { chat } = props;

    return (
      <ChatStack ref={ref}>
        <ChatStackItem>
          <ChatDivider>
            {chat.eventType ===
              gql.scalar('ChatEventEnum', 'SEND_CONVERSATION_TO_EMAIL') && (
              <span css={iconStyle}>
                <IcOutgoingMail />
              </span>
            )}

            {chat.eventType === gql.scalar('ChatEventEnum', 'AGENT_ENTER') && (
              <span css={iconStyle}>
                <Avatar
                  size="sm"
                  src={chat.enteringAgent?.avatar?.url}
                  alt={
                    chat.enteringAgent?.displayName ||
                    chat.enteringAgent?.firstName ||
                    chat.enteringAgent?.lastName ||
                    undefined
                  }
                >
                  {(
                    chat.enteringAgent?.displayName ||
                    chat.enteringAgent?.firstName ||
                    chat.enteringAgent?.lastName
                  )?.charAt(0)}
                </Avatar>
              </span>
            )}

            <EventChatMessage chat={chat} />
          </ChatDivider>
        </ChatStackItem>
      </ChatStack>
    );
  },
);
