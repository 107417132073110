import { ChatMedia } from '@allganize/alli-chat-content';
import { Checkbox } from '@allganize/ui-checkbox';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@allganize/ui-form';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormValueFormValues } from './use-form-value-form';

interface CheckboxFormValueFieldProps {
  disabled?: boolean;
  name: `formValues.${number}`;
}

export const CheckboxFormValueField: FunctionComponent<
  CheckboxFormValueFieldProps
> = props => {
  const { disabled: disabledProp, name } = props;
  const { control } = useFormContext<FormValueFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name,
    disabled: disabledProp,
  });
  const {
    field: { ref: valueRef, ...value },
    fieldState: valueState,
  } = useController({ control, name: `${name}.value`, disabled: disabledProp });
  const disabled = field.disabled || formState.disabled;

  if (field.value.type !== 'checkbox') {
    return null;
  }

  if (disabled) {
    const { options } = field.value;
    const values = field.value.value
      .map(value => options.find(option => option.value === value)?.label)
      .join(', ');

    return (
      <FormControl fullWidth required={field.value.required}>
        <FormLabel>{field.value.label}</FormLabel>

        <Text variant="body14">{values}</Text>
      </FormControl>
    );
  }

  return (
    <FormControl
      component="fieldset"
      fullWidth
      required={field.value.required}
      error={fieldState.invalid || valueState.invalid}
    >
      <FormLabel component="legend">{field.value.label}</FormLabel>

      <FormGroup>
        {field.value.options.map(option => {
          const checked =
            (value.value as number[])?.includes(option.value) ?? false;

          return (
            <FormControlLabel
              key={option.value}
              control={<Checkbox />}
              checked={checked}
              value={option.value}
              onChange={(ev, checked) => {
                value.onChange(
                  checked
                    ? [...(value.value as number[]), option.value]
                    : (value.value as number[]).filter(v => v !== option.value),
                );
              }}
              label={
                <>
                  {option.label}

                  {option.media && (
                    <ChatMedia
                      media={option.media}
                      onBlur={e => e.stopPropagation()}
                      css={css`
                        margin-top: 4px;
                        margin-bottom: 4px;
                      `}
                    />
                  )}
                </>
              }
            />
          );
        })}
      </FormGroup>

      {valueState.error?.message && (
        <FormHelperText error>{valueState.error.message}</FormHelperText>
      )}

      {fieldState.error?.message && (
        <FormHelperText error>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  );
};
