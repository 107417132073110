import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { gql, Scalars } from '../gql';

export interface LLMAppTypeBadgeProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  type: Scalars<'LLMAppType'>;
}

export const LLMAppTypeBadge = ({ type, ...others }: LLMAppTypeBadgeProps) => {
  const theme = useTheme();
  return (
    <Text
      component="span"
      variant="body12"
      css={css`
        display: inline-block;
        height: 20px;
        padding: 0 6px;
        border-radius: ${theme.radius.round}px;
        background-color: ${type === gql.scalar('LLMAppType', 'CAMPAIGN')
          ? theme.palette.unstable_background.alert.subtle
          : theme.palette.unstable_background.success.subtle};
      `}
      {...others}
    >
      <FormattedMessage
        id={`llm-app.app-type-badge.${type}`}
        defaultMessage={type}
        description={`llm app card ${type} badge`}
      />
    </Text>
  );
};
