import {
  AccessControlSelectAgentOption,
  AccessControlSelectUserOption,
  parseAccessAgent,
  ParseAccessAgent_AgentFragment,
  parseAccessUserFilter,
  ParseAccessUserFilter_AccessUserFilterFragment,
} from '@allganize/access-control';
import { useUnmount } from '@allganize/hooks';
import { useQuery } from '@apollo/client/react';
import { useAtom } from 'jotai';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { gql, Scalars } from '../gql';
import {
  KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment,
  useRootAccessControl,
  useRootAccessControlQuery,
} from '../hooks/use-access-control';
import { EditFileForm } from './edit-file-form';
import { editFileAtom } from './use-edit-file';
import { EditFileFormValues } from './use-edit-file-form';

const EditFileKnowledgeBaseNodeQuery = gql(
  `
  query EditFileKnowledgeBaseNodeQuery(
    $where: KnowledgeBaseNodeWhereUniqueInput!
  ) {
    knowledgeBaseNode(
      where: $where
    ) {
      id
      name
      viewAccessToUser
      viewAccessToAgent
      isOverwriteFolderAccess

      accessUserFilters {
        ...ParseAccessUserFilter_AccessUserFilterFragment
      }

      accessAgents {
        accessType
        isAdmin

        agent {
          ...ParseAccessAgent_AgentFragment
        }
      }

      parent {
        id
        ...KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment
      }

      ...on KnowledgeBaseNodeFile {
        hashtags
      }
    }
  }
`,
  [
    ParseAccessUserFilter_AccessUserFilterFragment,
    ParseAccessAgent_AgentFragment,
    KnowledgeBaseAccessControl_KnowledgeBaseNodeFragment,
  ],
);

const defaultEditFileFormValues: EditFileFormValues = {
  name: '',
  hashtags: [],
  accessControl: {
    isOverwriteFolderAccess: true,
    accessAgents: [],
    viewAccessToAgent: true,
    accessUserFilters: [],
    viewAccessToUser: true,
  },
};

export interface EditFileProps {
  projectId: Scalars<'ID'>;
}

export const EditFile: FunctionComponent<EditFileProps> = props => {
  const { projectId } = props;
  const intl = useIntl();
  const [state, setState] = useAtom(editFileAtom(projectId));

  const { data, loading, refetch } = useQuery(EditFileKnowledgeBaseNodeQuery, {
    variables: {
      where: {
        id: state.fileId || '',
        project: {
          id: projectId,
        },
      },
    },
    skip: state.fileId === null,
    fetchPolicy: 'network-only',
  });
  const rootAccessControlQueryResult = useRootAccessControlQuery({
    variables: { where: { id: projectId } },
    skip: data?.knowledgeBaseNode?.parent !== null,
  });
  const rootNodeAccessControl = useRootAccessControl({
    project: rootAccessControlQueryResult.data?.project,
  });

  const parentAccessControl = useMemo(() => {
    if (data?.knowledgeBaseNode?.parent === null) return rootNodeAccessControl;
    return data?.knowledgeBaseNode?.parent || undefined;
  }, [data?.knowledgeBaseNode?.parent, rootNodeAccessControl]);

  const defaultValues = useMemo<EditFileFormValues>(() => {
    if (
      data?.knowledgeBaseNode &&
      data?.knowledgeBaseNode.__typename === 'KnowledgeBaseNodeFile'
    ) {
      return {
        name: data.knowledgeBaseNode.name,
        hashtags: (data.knowledgeBaseNode.hashtags || []).map(ht => ({
          label: `#${ht}`,
          value: ht,
        })),
        accessControl: {
          isOverwriteFolderAccess:
            data.knowledgeBaseNode.isOverwriteFolderAccess,
          accessAgents: (data.knowledgeBaseNode.accessAgents || []).reduce<
            AccessControlSelectAgentOption[]
          >((acc, agent) => {
            const parsedAgent = parseAccessAgent(agent);
            return parsedAgent ? [...acc, parsedAgent] : acc;
          }, []),
          viewAccessToAgent: data.knowledgeBaseNode.viewAccessToAgent,
          accessUserFilters:
            data.knowledgeBaseNode.accessUserFilters.map<AccessControlSelectUserOption>(
              user => parseAccessUserFilter(user, intl),
            ),
          viewAccessToUser: data.knowledgeBaseNode.viewAccessToUser,
        },
      };
    }

    return defaultEditFileFormValues;
  }, [data, intl]);

  const handleClose = () => {
    setState({
      projectId,
      open: false,
      fileId: null,
    });
  };

  useEffect(() => {
    if (!state.open) return;
    refetch();
  }, [state.open, refetch]);

  useUnmount(handleClose);

  if (loading) {
    return null;
  }

  return (
    <EditFileForm
      {...state}
      defaultValues={defaultValues}
      parentAccessControl={parentAccessControl}
      enableOverwriteFolderAccess={!!data?.knowledgeBaseNode?.parent}
      onClose={handleClose}
    />
  );
};
