import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, isValidElement, useContext } from 'react';
import { ListItemButtonContext } from '../list-item-button';
import { listItemTextClasses } from './list-item-text-classes';
import { ListItemTextProps } from './list-item-text-type-map';

// custom typing for list item in order to make it generic
interface ListItemTextType {
  <
    PrimaryTextComponent extends React.ElementType = 'span',
    SecondaryTextComponent extends React.ElementType = 'p',
  >(
    props: React.PropsWithoutRef<
      ListItemTextProps<PrimaryTextComponent, SecondaryTextComponent>
    > &
      React.RefAttributes<HTMLDivElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

export const ListItemText: ListItemTextType = forwardRef((props, ref) => {
  const {
    children,
    classes,
    className,
    disabled: disabledProp,
    disableTypography = false,
    inset = false,
    primary: primaryProp,
    primaryTextProps,
    secondary: secondaryProp,
    secondaryTextProps,
    ...other
  } = props;
  const theme = useTheme();
  const listItemButtonContext = useContext(ListItemButtonContext);
  const disabled = disabledProp ?? listItemButtonContext.disabled ?? false;
  let primary = primaryProp != null ? primaryProp : children;
  let secondary = secondaryProp;

  if (
    primary != null &&
    !(isValidElement(primary) && primary.type === Text) &&
    !disableTypography
  ) {
    primary = (
      <Text
        data-testid="list-item-text__primary"
        css={[
          css`
            display: block;
          `,
          disabled &&
            css`
              color: ${theme.palette.foreground.gray.disabled};
            `,
        ]}
        variant="body14"
        className={clsx(listItemTextClasses.primary, classes?.primary)}
        component={primaryTextProps?.variant ? undefined : 'span'}
        {...primaryTextProps}
      >
        {primary}
      </Text>
    );
  }

  if (
    secondary != null &&
    !(isValidElement(secondary) && secondary.type === Text) &&
    !disableTypography
  ) {
    secondary = (
      <Text
        data-testid="list-item-text__secondary"
        css={[
          css`
            display: block;
            color: ${theme.palette.foreground.secondary};
          `,
          disabled &&
            css`
              color: ${theme.palette.foreground.gray.disabled};
            `,
        ]}
        variant="body12"
        className={clsx(listItemTextClasses.secondary, classes?.secondary)}
        {...secondaryTextProps}
      >
        {secondary}
      </Text>
    );
  }

  return (
    <div
      data-testid="list-item-text"
      css={[
        css`
          flex: 1 1 auto;
          min-width: 0;
          padding: 4px 0;
        `,
        inset === 'large' &&
          css`
            padding-left: 44px;
          `,
        inset === 'small' &&
          css`
            padding-left: 28px;
          `,
      ]}
      {...other}
      ref={ref}
      className={clsx(
        listItemTextClasses.root,
        {
          [listItemTextClasses.multiline]: primary && secondary,
          [listItemTextClasses.insetLarge]: inset === 'large',
          [listItemTextClasses.insetSmall]: inset === 'small',
          [listItemTextClasses.disabled]: disabled,
        },
        classes?.root,
        {
          [classes?.multiline ?? '']: primary && secondary,
          [classes?.insetLarge ?? '']: inset === 'large',
          [classes?.insetSmall ?? '']: inset === 'small',
          [classes?.disabled ?? '']: disabled,
        },
        className,
      )}
    >
      {primary}
      {secondary}
    </div>
  );
});
