import { resolveComponentProps } from '@mui/base/utils';
import { forwardRef } from 'react';
import { DateTimeField } from '../date-time-field';
import { useDateTimePickerDefaultizedProps } from '../date-time-picker/date-time-picker.shared';
import { renderDateViewCalendar } from '../date-view-renderers';
import { useMobilePicker } from '../internals/hooks/use-mobile-picker';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import { useLocaleText, useUtils } from '../internals/hooks/use-utils';
import { resolveDateTimeFormat } from '../internals/utils/date-time-utils';
import { extractValidationProps } from '../internals/utils/validation/extract-validation-props';
import { validateDateTime } from '../internals/utils/validation/validate-date-time';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { DateOrTimeView } from '../models';
import { renderTimeViewClock } from '../time-view-renderers';
import { MobileDateTimePickerProps } from './mobile-date-time-picker-type-map';

export const MobileDateTimePicker = forwardRef<
  HTMLDivElement,
  MobileDateTimePickerProps
>((inProps, ref) => {
  const localeText = useLocaleText<Date>();
  const utils = useUtils<Date>();

  // Props with the default values common to all date time pickers
  const defaultizedProps = useDateTimePickerDefaultizedProps<
    DateOrTimeView,
    MobileDateTimePickerProps
  >(inProps, 'MuiMobileDateTimePicker');

  const viewRenderers: PickerViewRendererLookup<
    Date | null,
    DateOrTimeView,
    any,
    {}
  > = {
    day: renderDateViewCalendar,
    month: renderDateViewCalendar,
    year: renderDateViewCalendar,
    hours: renderTimeViewClock,
    minutes: renderTimeViewClock,
    seconds: renderTimeViewClock,
    ...defaultizedProps.viewRenderers,
  };
  const ampmInClock = defaultizedProps.ampmInClock ?? false;

  // Props with the default values specific to the mobile variant
  const props = {
    ...defaultizedProps,
    viewRenderers,
    format: resolveDateTimeFormat(utils, defaultizedProps),
    ampmInClock,
    slots: {
      field: DateTimeField,
      ...defaultizedProps.slots,
    },
    slotProps: {
      ...defaultizedProps.slotProps,
      field: (ownerState: any) => ({
        ...resolveComponentProps(defaultizedProps.slotProps?.field, ownerState),
        ...extractValidationProps(defaultizedProps),
        ref,
      }),
      toolbar: {
        hidden: false,
        ampmInClock,
        ...defaultizedProps.slotProps?.toolbar,
      },
      tabs: {
        hidden: false,
        ...defaultizedProps.slotProps?.tabs,
      },
    },
  };

  // @ts-expect-error internal prop
  const { renderPicker } = useMobilePicker<DateOrTimeView, typeof props>({
    props,
    valueManager: singleItemValueManager,
    valueType: 'date-time',
    getOpenDialogAriaText: localeText.openDatePickerDialogue,
    validator: validateDateTime,
  });

  return renderPicker();
});
