import { FormControl, FormHelperText } from '@allganize/ui-form';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { CheckboxFormValueField } from './checkbox-form-value-field';
import {
  DateFormValueField,
  DateFormValueFieldProps,
} from './date-form-value-field';
import { FileFormValueField } from './file-form-value-field';
import { MultiSelectFormValueField } from './multi-select-form-value-field';
import { NumberFormValueField } from './number-form-value-field';
import { RadioFormValueField } from './radio-form-value-field';
import { SingleSelectFormValueField } from './single-select-form-value-field';
import { SwitchFormValueField } from './switch-form-value-field';
import { TextFormValueField } from './text-form-value-field';
import { FormValueFormValues } from './use-form-value-form';

export interface FormValuesFieldProps
  extends Pick<DateFormValueFieldProps, 'slotProps' | 'slots'> {
  disabled?: boolean;
}

export const FormValuesField: FunctionComponent<
  FormValuesFieldProps
> = props => {
  const { disabled: disabledProp, slotProps, slots } = props;
  const { control } = useFormContext<FormValueFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name: 'formValues',
    disabled: disabledProp,
  });
  const { fields } = useFieldArray({ control, name: 'formValues' });
  const disabled = field.disabled || formState.disabled;

  return (
    <FormControl
      component="fieldset"
      fullWidth
      error={fieldState.invalid}
      disabled={disabled}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        {fields.map((item, index) => {
          switch (item.type) {
            case 'checkbox':
              return (
                <CheckboxFormValueField
                  key={item.id}
                  name={`formValues.${index}`}
                  disabled={disabled}
                />
              );
            case 'date':
              return (
                <DateFormValueField
                  key={item.id}
                  name={`formValues.${index}`}
                  disabled={disabled}
                  slotProps={slotProps}
                  slots={slots}
                />
              );
            case 'file':
              return (
                <FileFormValueField
                  key={item.id}
                  name={`formValues.${index}`}
                  disabled={disabled}
                />
              );
            case 'multi-select':
              return (
                <MultiSelectFormValueField
                  key={item.id}
                  name={`formValues.${index}`}
                  disabled={disabled}
                />
              );
            case 'number':
              return (
                <NumberFormValueField
                  key={item.id}
                  name={`formValues.${index}`}
                  disabled={disabled}
                />
              );
            case 'radio':
              return (
                <RadioFormValueField
                  key={item.id}
                  name={`formValues.${index}`}
                  disabled={disabled}
                />
              );
            case 'single-select':
              return (
                <SingleSelectFormValueField
                  key={item.id}
                  name={`formValues.${index}`}
                  disabled={disabled}
                />
              );
            case 'switch':
              return (
                <SwitchFormValueField
                  key={item.id}
                  name={`formValues.${index}`}
                  disabled={disabled}
                />
              );
            case 'text':
              return (
                <TextFormValueField
                  key={item.id}
                  name={`formValues.${index}`}
                  disabled={disabled}
                />
              );
          }

          return null;
        })}
      </div>

      {fieldState.error?.message && (
        <FormHelperText error>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  );
};
