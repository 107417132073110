import { ContactAgentProps } from '@allganize/alli-chat-contact-agent';
import { FormValueProps } from '@allganize/alli-chat-form-value';

export const validation = {
  contactAgentByEmail: {
    attachments: {
      maxCount: 5,
      maxSize: 10 * 1048576,
    },
  } satisfies NonNullable<ContactAgentProps['validation']>,
  formValue: {
    formValues: {
      file: {
        maxSize: {
          default: 75 * 1048576,
          image: 10 * 1048576,
        },
      },
    },
  } satisfies NonNullable<FormValueProps['validation']>,
};
