import { Row, RowSelectionState, Updater } from '@tanstack/react-table';
import { createContext } from 'react';
import { FragmentOf, gql, ResultOf, Scalars } from '../gql';
import { KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment } from '../knowledge-base-table/knowledge-base-table-columns';

export const KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment = gql(`
  fragment KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment on KnowledgeBaseNode {
    __typename
    id
    name
    ownership
    ancestors {
      id
    }
    accessAgents {
      accessType
      agent {
        id
        isMe
      }
    }
  }
`);

type KnowledgeBaseNodeType = ResultOf<
  typeof KnowledgeBaseActionsContext_KnowledgeBaseNodeFragment
>;

export interface KnowledgeBaseActionsContextValue {
  isEditable?(
    node: Pick<
      KnowledgeBaseNodeType,
      '__typename' | 'accessAgents' | 'ownership'
    >,
  ): boolean;
  selectedRows: Row<
    FragmentOf<typeof KnowledgeBaseTable_KnowledgeBaseNodeEdgeFragment>
  >[];
  setRowSelection(updater: Updater<RowSelectionState>): void;
  onDelete?(...nodeIds: Scalars<'ID'>[]): Promise<boolean>;
  onEdit?(node: Pick<KnowledgeBaseNodeType, '__typename' | 'id'>): void;
  onMove?(
    ...nodes: Pick<KnowledgeBaseNodeType, '__typename' | 'id' | 'ancestors'>[]
  ): void;
  onDownload?(
    ...nodes: Pick<KnowledgeBaseNodeType, '__typename' | 'id' | 'name'>[]
  ): Promise<void>;
  onToggleStatus?(args: { ids: Scalars<'ID'>[]; use: boolean }): Promise<void>;
}

export const KnowledgeBaseActionsContext =
  createContext<KnowledgeBaseActionsContextValue>({
    isEditable() {
      return true;
    },
    selectedRows: [],
    setRowSelection() {
      // noop
    },
    async onDelete() {
      return false;
    },
    onEdit() {
      // noop
    },
    onMove() {
      // noop
    },
    async onDownload() {
      // noop
    },
    async onToggleStatus() {
      // noop
    },
  });
