import { Switch, SwitchProps } from '@allganize/ui-switch';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';

interface AccessControlSwitchProps
  extends Omit<SwitchProps, 'value' | 'checked'> {
  fullWidth?: boolean;
  label?: React.ReactNode;
  value: boolean;
}
export const AccessControlSwitch = forwardRef<
  HTMLDivElement,
  AccessControlSwitchProps
>(({ fullWidth = false, label, ...others }, ref) => {
  const theme = useTheme();

  return (
    <div
      css={[
        css`
          display: flex;
          align-items: center;
          color: ${theme.palette.foreground.secondary};
        `,
        fullWidth &&
          css`
            width: 100%;
            > p:first-of-type {
              flex-grow: 1;
            }
          `,
      ]}
      ref={ref}
    >
      <Text variant="body12">{label}</Text>
      <Switch {...others} checked={others.value} />
    </div>
  );
});
