import { FormControl } from '@allganize/ui-form';
import { List } from '@allganize/ui-list';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import {
  useController,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';
import {
  FilesFieldListItem,
  FilesFieldListItemProps,
} from './files-field-list-item';
import { FileSelectionFormValues } from './use-file-selection-form';

export interface FilesFieldListProps
  extends Pick<FilesFieldListItemProps, 'onEdit'> {
  files: UseFieldArrayReturn<FileSelectionFormValues, 'files', 'id'>;
}

export const FilesFieldList: FunctionComponent<FilesFieldListProps> = props => {
  const { files, onEdit } = props;
  const theme = useTheme();
  const { control } = useFormContext<FileSelectionFormValues>();
  const { field, fieldState } = useController({ control, name: 'files' });
  const { fields, remove } = files;

  if (fields.length === 0) {
    return null;
  }

  return (
    <FormControl
      css={css`
        border-radius: ${theme.radius.sm}px;
        background-color: ${theme.palette.unstable_background.default};
      `}
      component="fieldset"
      required
      fullWidth
      error={fieldState.invalid}
      disabled={field.disabled}
    >
      <List>
        {fields.map((item, i) => {
          return (
            <FilesFieldListItem
              key={item.id}
              index={i}
              onDelete={remove}
              onEdit={onEdit}
            />
          );
        })}
      </List>
    </FormControl>
  );
};
