import { IconButton } from '@allganize/ui-button';
import { IcClose } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { ToastContentProps as BaseToastContentProps } from 'react-toastify';
import { ToastIcon } from '../toast-icon/toast-icon';
import {
  ToastContentClasses,
  toastContentClasses,
} from './toast-content.classes';

export interface ToastContentProps<Data = {}>
  extends BaseToastContentProps<Data>,
    React.HTMLAttributes<HTMLDivElement> {
  action?: React.ReactNode;
  /**
   * @default 'row'
   */
  direction?: 'row' | 'column';
  icon?: React.ReactNode;
  classes?: Partial<ToastContentClasses>;
}

interface ToastContentType {
  <Data = {}>(
    props: React.PropsWithoutRef<ToastContentProps<Data>> &
      React.RefAttributes<HTMLDivElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

export const ToastContent: ToastContentType = forwardRef((props, ref) => {
  const {
    action,
    icon,
    children,
    classes,
    closeToast,
    data,
    direction = 'row',
    toastProps,
    ...other
  } = props;
  const theme = useTheme();

  return (
    <div
      data-testid="toast-content"
      css={[
        css`
          display: flex;
          align-items: flex-start;
          gap: 8px;
          min-height: 56px;
          padding: 12px 16px;
          box-sizing: border-box;
        `,
        direction === 'column' &&
          css`
            flex-direction: column;
            align-items: stretch;
          `,
      ]}
      {...other}
      ref={ref}
      className={clsx(
        toastContentClasses.root,
        {
          [toastContentClasses.directionRow]: direction === 'row',
          [toastContentClasses.directionColumn]: direction === 'column',
        },
        classes?.root,
        {
          [classes?.directionRow ?? '']: direction === 'row',
          [classes?.directionColumn ?? '']: direction === 'column',
        },
        other.className,
      )}
    >
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          padding: 6px 0;
          gap: 8px;
          flex-grow: 1;
        `}
        className={clsx(toastContentClasses.contents, classes?.contents)}
      >
        {icon || (
          <ToastIcon
            type={toastProps.type}
            theme={toastProps.theme}
            className={clsx(toastContentClasses.icon, classes?.icon)}
          />
        )}

        <Text
          css={css`
            flex-grow: 1;
            color: ${theme.palette.foreground.default};
          `}
          variant="body14"
          className={clsx(toastContentClasses.message, classes?.message)}
        >
          {children}
        </Text>
      </div>

      {(action || typeof action === 'undefined') && (
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
          `}
          className={clsx(toastContentClasses.actions, classes?.actions)}
        >
          {typeof action === 'undefined' ? (
            <IconButton onClick={closeToast}>
              <IcClose />
            </IconButton>
          ) : (
            action
          )}
        </div>
      )}
    </div>
  );
});
