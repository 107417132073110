import {
  getKnowledgeBaseFilenameIconVariant,
  KnowledgeBaseFileIcon,
} from '@allganize/knowledge-base-icons';
import { IconButton } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { FormControl } from '@allganize/ui-form';
import { IcCheck, IcClose, IcEdit } from '@allganize/ui-icons';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  listItemClasses,
} from '@allganize/ui-list';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { filesize } from 'filesize';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileInput, FileSelectionFormValues } from './use-file-selection-form';

export interface FilesFieldListItemProps {
  index: number;
  onDelete?(index: number): void;
  onEdit?(file: FileInput, values: FileSelectionFormValues): void;
}

export const FilesFieldListItem: FunctionComponent<
  FilesFieldListItemProps
> = props => {
  const { index, onDelete, onEdit } = props;
  const theme = useTheme();
  const intl = useIntl();
  const { control, getValues } = useFormContext<FileSelectionFormValues>();
  const { field, fieldState, formState } = useController({
    control,
    name: `files.${index}`,
  });
  const errorMessage =
    fieldState.error?.message ||
    fieldState.error?.root?.message ||
    formState.errors.files?.[index]?.id?.message ||
    formState.errors.files?.[index]?.id?.root?.message ||
    formState.errors.files?.[index]?.file?.message ||
    formState.errors.files?.[index]?.file?.root?.message;
  const dirty =
    field.value.hashtags.length > 0 ||
    !field.value.accessControl.isOverwriteFolderAccess;

  const handleDelete = () => {
    onDelete?.(index);
  };

  const handleEdit = () => {
    onEdit?.(field.value, getValues());
  };

  return (
    <ListItem
      css={css`
        flex-direction: row;

        &.${listItemClasses.secondaryAction} {
          padding-right: 74px;
        }
      `}
      component={FormControl}
      required
      fullWidth
      error={fieldState.invalid}
      disabled={field.disabled}
      secondaryAction={
        <>
          {!field.value.file.name.endsWith('.zip') && onEdit && (
            <Tooltip
              title={
                <FormattedMessage
                  id="knowledge-base-actions.upload-file.edit-tooltip"
                  defaultMessage="Edit hashtag"
                  description="Knowledge base actions upload file edit button tooltip"
                />
              }
            >
              <IconButton
                shape="rounded"
                disabled={
                  field.value.submitStatus === 'isSubmitting' ||
                  field.value.submitStatus === 'success'
                }
                onClick={handleEdit}
              >
                <IcEdit />
                {dirty && (
                  <div
                    css={css`
                      position: absolute;
                      right: 4px;
                      bottom: 4px;
                      font-size: ${theme.spacing(1)};
                      border-radius: 50%;
                      width: 1em;
                      height: 1em;
                      border: 1px solid ${theme.palette.primary.contrastText};
                      background-color: ${theme.palette.primary.main};
                    `}
                  />
                )}
              </IconButton>
            </Tooltip>
          )}

          <Tooltip
            title={
              <FormattedMessage
                id="actions.delete"
                defaultMessage="Delete"
                description="FAQ documents upload form file delete button text"
              />
            }
          >
            <IconButton
              shape="rounded"
              edge="end"
              disabled={
                field.value.submitStatus === 'isSubmitting' ||
                field.value.submitStatus === 'success'
              }
              onClick={handleDelete}
            >
              <IcClose />
            </IconButton>
          </Tooltip>
        </>
      }
    >
      <ListItemIcon>
        {field.value.submitStatus === 'isSubmitting' && (
          <CircularProgress size="sm" />
        )}

        {field.value.submitStatus === 'success' && <IcCheck color="success" />}

        {(field.value.submitStatus === 'init' ||
          field.value.submitStatus === 'error' ||
          !field.value.submitStatus) && (
          <KnowledgeBaseFileIcon
            variant={
              getKnowledgeBaseFilenameIconVariant(field.value.file.name) ??
              'fallback'
            }
          />
        )}
      </ListItemIcon>

      <ListItemText
        primary={field.value.name}
        secondary={
          errorMessage ? (
            <Text
              component="span"
              variant="inherit"
              css={css`
                color: ${theme.palette.error.main};
              `}
            >
              {errorMessage}
            </Text>
          ) : (
            filesize(field.value.file.size, {
              output: 'string',
              locale: intl.locale,
              localeOptions: intl.formats.date,
            })
          )
        }
      />
    </ListItem>
  );
};
