import { useProject } from '@allganize/alli-works-project';
import { Button } from '@allganize/ui-button';
import { useTheme } from '@allganize/ui-theme';
import { IcUpload } from '@allganize/ui-icons';
import { useUploadFile } from '@allganize/knowledge-base-actions';
import { css } from '@emotion/react';
import {
  FunctionComponent,
  Suspense,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import {
  defaultTab,
  DocumentsPickerTabs,
  TabOption,
} from './documents-picker-tabs';
import { DocumentsPickerSearchForm } from './documents-picker-search-form';
import {
  KnowledgeBaseList,
  KnowledgeBaseListActions,
  KnowledgeBaseListProps,
} from '../knowledge-base-list/knowledge-base-list';
import { UploadFile } from '../upload-file';
import { gql } from '../gql';

export interface DocumentPickerActions {
  upload: () => void;
}

interface DocumentsPickerProps {
  className?: string;
  tab?: TabOption;
  actions?: React.Ref<DocumentPickerActions>;
  hideHeader?: boolean;
  onTabChange?: (tab: TabOption) => void;
  onSelectedKnowledgeBaseIdsChange?: KnowledgeBaseListProps['onSelectedIdsChange'];
}

export const DocumentsPicker: FunctionComponent<DocumentsPickerProps> = ({
  className,
  tab: tabProp,
  actions,
  hideHeader,
  onTabChange,
  onSelectedKnowledgeBaseIdsChange,
}) => {
  const theme = useTheme();
  const project = useProject();

  const [tab, setTab] = useState<TabOption | null>(defaultTab);
  const [prevTab, setPrevTab] = useState<TabOption>(defaultTab);
  const [searchTerm, setSearchTerm] = useState('');
  const isPersonalTab =
    tab === gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL');

  const handleTabChange = (tab: TabOption | null) => {
    if (tab !== null) {
      setSearchTerm('');
      onTabChange?.(tab);
    }
    setTab(prev => {
      if (prev !== null) {
        setPrevTab(prev);
      }
      return tab;
    });
  };

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    if (searchTerm) {
      handleTabChange(null);
    } else {
      handleTabChange(prevTab);
    }
  };

  const knowledgeBaseListActions = useRef<KnowledgeBaseListActions>(null);

  const uploadFile = useUploadFile(project.id);
  const handleUpload = useCallback(() => {
    uploadFile({
      open: true,
      ownership: gql.scalar('KnowledgeBaseNodeOwnership', 'PERSONAL'),
      projectId: project.id,
      parentId: null,
      onSubmit: () => {
        knowledgeBaseListActions.current?.refresh();
      },
    });
  }, [uploadFile, project.id]);

  useImperativeHandle(actions, () => ({ upload: handleUpload }), [
    handleUpload,
  ]);

  useEffect(() => {
    if (tabProp) {
      setTab(tabProp);
    }
  }, [tabProp]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        min-height: 0;
      `}
      className={className}
    >
      {!hideHeader && (
        <header
          css={css`
            height: 120px;
          `}
        >
          <DocumentsPickerTabs tab={tab} onTabChange={handleTabChange} />
          <DocumentsPickerSearchForm
            searchTerm={searchTerm}
            onSubmit={handleSearchTermChange}
          />
        </header>
      )}

      <div
        css={css`
          flex-grow: 1;
          min-height: 1px;
        `}
      >
        <KnowledgeBaseList
          actions={knowledgeBaseListActions}
          projectId={project.id}
          ownership={tab}
          searchTerm={searchTerm}
          viewType={isPersonalTab || searchTerm ? 'list' : 'tree'}
          onSelectedIdsChange={onSelectedKnowledgeBaseIdsChange}
        />
      </div>
      {isPersonalTab && (
        <footer
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 52px;
            padding: 8px;
            background-color: ${theme.palette.unstable_background.default};
            border-top: 1px solid ${theme.palette.border.divider.default};
          `}
        >
          <Button
            variant="outlined"
            startIcon={<IcUpload />}
            onClick={handleUpload}
          >
            <FormattedMessage
              id="documents-picker.upload-button.label"
              defaultMessage="Upload"
              description="documents picker upload button label"
            />
          </Button>
        </footer>
      )}

      <ErrorBoundary fallback={null}>
        <Suspense fallback={null}>
          <UploadFile />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
