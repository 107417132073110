import {
  DocumentViewer,
  documentViewerClasses,
} from '@allganize/alli-document';
import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import { IcOpenInNew } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { Text } from '@allganize/ui-text';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { FunctionComponent, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { SidePanelContext } from './side-panel-context';
import { SidePanelErrorFallback } from './side-panel-error-fallback';
import { isDocumentPreviewTab } from './use-tab-navigator';

export const DocumentPreviewPanel: FunctionComponent = () => {
  const theme = useTheme();
  const { activeTab } = useContext(SidePanelContext);

  if (!isDocumentPreviewTab(activeTab) || !activeTab.metadata) {
    return <SidePanelErrorFallback />;
  }

  const { initialPage, pages, thumbnails, title, originalWebUri } =
    activeTab.metadata;

  return (
    <DocumentViewer
      css={css`
        width: 100%;
        height: 100%;

        &,
        .${documentViewerClasses.header},
          .${documentViewerClasses.sidebar}
          > div {
          background-color: ${theme.palette.unstable_background.default};
        }
      `}
      initialPage={initialPage}
      pages={pages}
      thumbnails={thumbnails}
      header={
        <>
          <Text variant="title12">
            <Truncate clamp={1}>{title}</Truncate>
          </Text>

          {originalWebUri && (
            <Tooltip
              title={
                <FormattedMessage
                  id="document-preview.original-web-uri"
                  defaultMessage="Open page in a new tab"
                  description="Document preview original web uri tooltip"
                />
              }
            >
              <IconButton
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={originalWebUri}
              >
                <IcOpenInNew />
              </IconButton>
            </Tooltip>
          )}
        </>
      }
    />
  );
};
