import { ObjectAccessType } from '@allganize/data-access-alli-enums';
import { useEventCallback } from '@allganize/hooks';
import { IconButton } from '@allganize/ui-button';
import { IcClose } from '@allganize/ui-icons';
import { Select } from '@allganize/ui-select';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ActionMeta, SingleValue } from 'react-select';
import { AccessControlAvatar } from '../access-control-avatar/access-control-avatar';
import { AccessControlAvatarProps } from '../access-control-avatar/access-control-avatar';
import { gql, Scalars } from '../gql';

interface Option {
  label: string;
  value: Scalars<'ObjectAccessType'>;
}

export interface AccessControlListItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  avatar?: Partial<AccessControlAvatarProps>;
  name?: React.ReactNode;
  description?: React.ReactNode;
  accessType?: Scalars<'ObjectAccessType'>;
  readOnly?: boolean;
  onDelete?: () => void;
  onPermissionChange?: (accessType: Scalars<'ObjectAccessType'>) => void;
}

const accessTypeLabels = defineMessages({
  [gql.scalar('ObjectAccessType', 'EDITOR')]: {
    id: 'access-control.object-access-type.editor',
    defaultMessage: 'Editor',
    description: 'access control object access type label - EDITOR',
  },
  [gql.scalar('ObjectAccessType', 'READER')]: {
    id: 'access-control.object-access-type.READER',
    defaultMessage: 'Viewer',
    description: 'access control object access type label - READER',
  },
});

const options = Object.values(ObjectAccessType).map(opt => ({
  label: opt,
  value: opt,
}));

export const AccessControlListItem = forwardRef<
  HTMLDivElement,
  AccessControlListItemProps
>(
  (
    {
      avatar,
      name,
      description,
      accessType: accessTypeProp,
      readOnly,
      onDelete,
      onPermissionChange,
      ...others
    },
    ref,
  ) => {
    const intl = useIntl();
    const theme = useTheme();
    const accessType: Option | null = accessTypeProp
      ? {
          label: accessTypeProp,
          value: accessTypeProp,
        }
      : null;
    const deletable = !readOnly && !!onDelete;
    const editable = !readOnly && !!onPermissionChange;

    const getAccessTypeLabel = useCallback(
      (_accessType?: Option) => {
        if (_accessType && _accessType.value in accessTypeLabels) {
          return intl.formatMessage(accessTypeLabels[_accessType.value]);
        }
        return '';
      },
      [intl],
    );

    const handlePermissionChange = useEventCallback(
      (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
        if (!newValue || actionMeta.action !== 'select-option') return;
        onPermissionChange?.(newValue.value);
      },
    );

    return (
      <div
        css={css`
          display: flex;
          gap: 8px;
          align-items: center;
          height: 40px;
        `}
        {...others}
        ref={ref}
      >
        {avatar && <AccessControlAvatar {...avatar} />}
        <div
          css={css`
            flex: 1;
            overflow: hidden;
          `}
        >
          <Text
            variant="body14"
            css={css`
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {name}
          </Text>
          <Text
            css={css`
              color: ${theme.palette.foreground.secondary};
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
            variant="body12"
          >
            {description}
          </Text>
        </div>
        {accessType && (
          <div
            css={css`
              min-width: 48px;
            `}
          >
            {editable ? (
              <Select<Option>
                css={css`
                  min-width: 105px;
                `}
                menuShouldBlockScroll
                menuPortalTarget={document.body}
                isSearchable={false}
                options={options}
                formatOptionLabel={getAccessTypeLabel}
                value={accessType}
                onChange={handlePermissionChange}
              />
            ) : (
              <Text
                variant="body14"
                css={css`
                  color: ${theme.palette.foreground.secondary};
                `}
              >
                {getAccessTypeLabel(accessType || undefined)}
              </Text>
            )}
          </div>
        )}
        {deletable && (
          <IconButton size="large" onClick={onDelete} shape="rounded">
            <IcClose />
          </IconButton>
        )}
      </div>
    );
  },
);
