import { useEventCallback } from '@allganize/hooks';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { useSlotProps } from '@mui/base/utils';
import { useControlled } from '@mui/material/utils';
import clsx from 'clsx';
import { FunctionComponent, createRef, useMemo, useState } from 'react';
import { useIsDateDisabled } from '../date-calendar/use-is-date-disabled';
import { DayCalendarSkeleton } from '../day-calendar-skeleton';
import { DAY_MARGIN, DAY_SIZE } from '../internals/constants';
import { useLocaleText, useNow, useUtils } from '../internals/hooks/use-utils';
import {
  findClosestEnabledDate,
  getWeekdays,
} from '../internals/utils/date-utils';
import { PickersDay, PickersDayProps } from '../pickers-day';
import { PickersSlideTransition } from '../pickers-slide-transition';
import { dayCalendarClasses } from './day-calendar-classes';
import { DayCalendarProps } from './day-calendar-type-map';

const weeksContainerHeight = (DAY_SIZE + DAY_MARGIN * 2) * 6 + 20;

const WrappedDay = ({
  parentProps,
  day,
  focusableDay,
  selectedDays,
  isDateDisabled,
  currentMonthNumber,
  isViewFocused,
  ...other
}: Pick<PickersDayProps, 'onFocus' | 'onBlur' | 'onKeyDown' | 'onDaySelect'> & {
  parentProps: DayCalendarProps;
  day: Date;
  focusableDay: Date | null;
  selectedDays: Date[];
  isDateDisabled: (date: Date | null) => boolean;
  currentMonthNumber: number;
  isViewFocused: boolean;
}) => {
  const {
    disabled,
    disableHighlightToday,
    isMonthSwitchingAnimating,
    showDaysOutsideCurrentMonth,
    slots,
    slotProps,
    timezone,
  } = parentProps;

  const utils = useUtils<Date>();
  const now = useNow<Date>(timezone);

  const isFocusableDay =
    focusableDay !== null && utils.isSameDay(day, focusableDay);
  const isSelected = selectedDays.some(selectedDay =>
    utils.isSameDay(selectedDay, day),
  );
  const isToday = utils.isSameDay(day, now);

  const Day = slots?.day ?? PickersDay;
  // We don't want to pass to ownerState down, to avoid re-rendering all the day whenever a prop changes.
  const { ownerState: dayOwnerState, ...dayProps } = useSlotProps({
    elementType: Day,
    externalSlotProps: slotProps?.day,
    additionalProps: {
      disableHighlightToday,
      showDaysOutsideCurrentMonth,
      role: 'gridcell',
      isAnimating: isMonthSwitchingAnimating,
      // it is used in date range dragging logic by accessing `dataset.timestamp`
      'data-timestamp': utils.toJsDate(day).valueOf(),
      ...other,
    },
    ownerState: { ...parentProps, day, selected: isSelected },
  });

  const isDisabled = useMemo(
    () => disabled || isDateDisabled(day),
    [disabled, isDateDisabled, day],
  );

  const outsideCurrentMonth = useMemo(
    () => utils.getMonth(day) !== currentMonthNumber,
    [utils, day, currentMonthNumber],
  );

  const isFirstVisibleCell = useMemo(() => {
    const startOfMonth = utils.startOfMonth(
      utils.setMonth(day, currentMonthNumber),
    );
    if (!showDaysOutsideCurrentMonth) {
      return utils.isSameDay(day, startOfMonth);
    }
    return utils.isSameDay(day, utils.startOfWeek(startOfMonth));
  }, [currentMonthNumber, day, showDaysOutsideCurrentMonth, utils]);

  const isLastVisibleCell = useMemo(() => {
    const endOfMonth = utils.endOfMonth(
      utils.setMonth(day, currentMonthNumber),
    );
    if (!showDaysOutsideCurrentMonth) {
      return utils.isSameDay(day, endOfMonth);
    }
    return utils.isSameDay(day, utils.endOfWeek(endOfMonth));
  }, [currentMonthNumber, day, showDaysOutsideCurrentMonth, utils]);

  return (
    <Day
      {...dayProps}
      day={day}
      disabled={isDisabled}
      autoFocus={isViewFocused && isFocusableDay}
      today={isToday}
      outsideCurrentMonth={outsideCurrentMonth}
      isFirstVisibleCell={isFirstVisibleCell}
      isLastVisibleCell={isLastVisibleCell}
      selected={isSelected}
      tabIndex={isFocusableDay ? 0 : -1}
      aria-selected={isSelected}
      aria-current={isToday ? 'date' : undefined}
    />
  );
};

export const DayCalendar: FunctionComponent<DayCalendarProps> = props => {
  const {
    onFocusedDayChange,
    classes,
    className,
    currentMonth,
    selectedDays,
    focusedDay,
    loading,
    onSelectedDaysChange,
    onMonthSwitchingAnimationEnd,
    readOnly,
    reduceAnimations,
    renderLoading = () => <DayCalendarSkeleton />,
    slideDirection,
    TransitionProps,
    disablePast,
    disableFuture,
    minDate,
    maxDate,
    shouldDisableDate,
    shouldDisableMonth,
    shouldDisableYear,
    dayOfWeekFormatter: dayOfWeekFormatterFromProps,
    hasFocus,
    onFocusedViewChange,
    gridLabelId,
    displayWeekNumber,
    fixedWeekNumber,
    autoFocus,
    timezone,
  } = props;

  const now = useNow<Date>(timezone);
  const utils = useUtils<Date>();
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  // before we could define this outside of the component scope, but now we need utils, which is only defined here
  const dayOfWeekFormatter =
    dayOfWeekFormatterFromProps ||
    ((_day: string, date: Date) =>
      utils.format(date, 'weekdayShort').charAt(0).toUpperCase());

  const isDateDisabled = useIsDateDisabled({
    shouldDisableDate,
    shouldDisableMonth,
    shouldDisableYear,
    minDate,
    maxDate,
    disablePast,
    disableFuture,
    timezone,
  });

  const localeText = useLocaleText<Date>();

  const [internalHasFocus, setInternalHasFocus] = useControlled({
    name: 'DayCalendar',
    state: 'hasFocus',
    controlled: hasFocus,
    default: autoFocus ?? false,
  });

  const [internalFocusedDay, setInternalFocusedDay] = useState<Date>(
    () => focusedDay || now,
  );

  const handleDaySelect = useEventCallback((day: Date) => {
    if (readOnly) {
      return;
    }

    onSelectedDaysChange(day);
  });

  const focusDay = useEventCallback((day: Date) => {
    if (!isDateDisabled(day)) {
      onFocusedDayChange(day);
      setInternalFocusedDay(day);

      onFocusedViewChange?.(true);
      setInternalHasFocus(true);
    }
  });

  const handleKeyDown = useEventCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>, day: Date) => {
      switch (event.key) {
        case 'ArrowUp':
          focusDay(utils.addDays(day, -7));
          event.preventDefault();
          break;
        case 'ArrowDown':
          focusDay(utils.addDays(day, 7));
          event.preventDefault();
          break;
        case 'ArrowLeft': {
          const newFocusedDayDefault = utils.addDays(day, isRTL ? 1 : -1);
          const nextAvailableMonth = utils.addMonths(day, isRTL ? 1 : -1);

          const closestDayToFocus = findClosestEnabledDate({
            utils,
            date: newFocusedDayDefault,
            minDate: isRTL
              ? newFocusedDayDefault
              : utils.startOfMonth(nextAvailableMonth),
            maxDate: isRTL
              ? utils.endOfMonth(nextAvailableMonth)
              : newFocusedDayDefault,
            isDateDisabled,
            timezone,
          });
          focusDay(closestDayToFocus || newFocusedDayDefault);
          event.preventDefault();
          break;
        }
        case 'ArrowRight': {
          const newFocusedDayDefault = utils.addDays(day, isRTL ? -1 : 1);
          const nextAvailableMonth = utils.addMonths(day, isRTL ? -1 : 1);

          const closestDayToFocus = findClosestEnabledDate({
            utils,
            date: newFocusedDayDefault,
            minDate: isRTL
              ? utils.startOfMonth(nextAvailableMonth)
              : newFocusedDayDefault,
            maxDate: isRTL
              ? newFocusedDayDefault
              : utils.endOfMonth(nextAvailableMonth),
            isDateDisabled,
            timezone,
          });
          focusDay(closestDayToFocus || newFocusedDayDefault);
          event.preventDefault();
          break;
        }
        case 'Home':
          focusDay(utils.startOfWeek(day));
          event.preventDefault();
          break;
        case 'End':
          focusDay(utils.endOfWeek(day));
          event.preventDefault();
          break;
        case 'PageUp':
          focusDay(utils.addMonths(day, 1));
          event.preventDefault();
          break;
        case 'PageDown':
          focusDay(utils.addMonths(day, -1));
          event.preventDefault();
          break;
        default:
          break;
      }
    },
  );

  const handleFocus = useEventCallback(
    (event: React.FocusEvent<HTMLButtonElement>, day: Date) => focusDay(day),
  );
  const handleBlur = useEventCallback(
    (event: React.FocusEvent<HTMLButtonElement>, day: Date) => {
      if (internalHasFocus && utils.isSameDay(internalFocusedDay, day)) {
        onFocusedViewChange?.(false);
      }
    },
  );

  const currentMonthNumber = utils.getMonth(currentMonth);
  const validSelectedDays = useMemo(
    () =>
      selectedDays
        .filter((day): day is Date => !!day)
        .map(day => utils.startOfDay(day)),
    [utils, selectedDays],
  );

  // need a new ref whenever the `key` of the transition changes: http://reactcommunity.org/react-transition-group/transition/#Transition-prop-nodeRef.
  const transitionKey = currentMonthNumber;
  const slideNodeRef = useMemo(
    () => createRef<HTMLDivElement>(),
    [transitionKey],
  );
  const startOfCurrentWeek = utils.startOfWeek(now);

  const focusableDay = useMemo<Date | null>(() => {
    const startOfMonth = utils.startOfMonth(currentMonth);
    const endOfMonth = utils.endOfMonth(currentMonth);
    if (
      isDateDisabled(internalFocusedDay) ||
      utils.isAfterDay(internalFocusedDay, endOfMonth) ||
      utils.isBeforeDay(internalFocusedDay, startOfMonth)
    ) {
      return findClosestEnabledDate({
        utils,
        date: internalFocusedDay,
        minDate: startOfMonth,
        maxDate: endOfMonth,
        disablePast,
        disableFuture,
        isDateDisabled,
        timezone,
      });
    }
    return internalFocusedDay;
  }, [
    currentMonth,
    disableFuture,
    disablePast,
    internalFocusedDay,
    isDateDisabled,
    utils,
    timezone,
  ]);

  const weeksToDisplay = useMemo(() => {
    const currentMonthWithTimezone = utils.setTimezone(currentMonth, timezone);
    const toDisplay = utils.getWeekArray(currentMonthWithTimezone);
    let nextMonth = utils.addMonths(currentMonthWithTimezone, 1);
    while (fixedWeekNumber && toDisplay.length < fixedWeekNumber) {
      const additionalWeeks = utils.getWeekArray(nextMonth);
      const hasCommonWeek = utils.isSameDay(
        toDisplay[toDisplay.length - 1][0],
        additionalWeeks[0][0],
      );

      additionalWeeks.slice(hasCommonWeek ? 1 : 0).forEach(week => {
        if (toDisplay.length < fixedWeekNumber) {
          toDisplay.push(week);
        }
      });

      nextMonth = utils.addMonths(nextMonth, 1);
    }
    return toDisplay;
  }, [currentMonth, fixedWeekNumber, utils, timezone]);

  return (
    <div
      data-testid="day-calendar"
      css={css`
        padding-top: 12px;
      `}
      role="grid"
      aria-labelledby={gridLabelId}
      className={clsx(dayCalendarClasses.root, classes?.root)}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
        role="row"
        className={clsx(dayCalendarClasses.header, classes?.header)}
      >
        {displayWeekNumber && (
          <Text
            css={css`
              width: ${DAY_SIZE}px;
              height: ${DAY_SIZE + 2 * DAY_MARGIN}px;
              margin: 0 ${DAY_MARGIN}px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              color: ${theme.palette.text.disabled};
            `}
            variant="body12"
            role="columnheader"
            aria-label={localeText.calendarWeekNumberHeaderLabel}
            className={clsx(
              dayCalendarClasses.weekNumberLabel,
              classes?.weekNumberLabel,
            )}
          >
            {localeText.calendarWeekNumberHeaderText}
          </Text>
        )}

        {getWeekdays(utils, now).map((weekday, i) => {
          const day = utils.format(weekday, 'weekdayShort');

          return (
            <Text
              css={css`
                width: ${DAY_SIZE}px;
                height: ${DAY_SIZE + 2 * DAY_MARGIN}px;
                margin: 0 ${DAY_MARGIN}px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: ${theme.palette.text.secondary};
              `}
              key={day + i.toString()}
              variant="body14"
              role="columnheader"
              aria-label={utils.format(
                utils.addDays(startOfCurrentWeek, i),
                'weekday',
              )}
              className={clsx(
                dayCalendarClasses.weekDayLabel,
                classes?.weekDayLabel,
              )}
            >
              {dayOfWeekFormatter?.(day, weekday) ?? day}
            </Text>
          );
        })}
      </div>

      {loading ? (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: ${weeksContainerHeight}px;
          `}
          className={clsx(
            dayCalendarClasses.loadingContainer,
            classes?.loadingContainer,
          )}
        >
          {renderLoading()}
        </div>
      ) : (
        <PickersSlideTransition
          css={css`
            min-height: ${weeksContainerHeight}px;
          `}
          transKey={transitionKey}
          onExited={onMonthSwitchingAnimationEnd}
          reduceAnimations={reduceAnimations}
          slideDirection={slideDirection}
          className={clsx(
            dayCalendarClasses.slideTransition,
            classes?.slideTransition,
            className,
          )}
          {...TransitionProps}
          nodeRef={slideNodeRef}
        >
          <div
            css={css`
              overflow: hidden;
            `}
            ref={slideNodeRef}
            role="rowgroup"
            className={clsx(
              dayCalendarClasses.monthContainer,
              classes?.monthContainer,
            )}
          >
            {weeksToDisplay.map((week, index) => (
              <div
                css={css`
                  padding: ${DAY_MARGIN}px 0;
                  display: flex;
                  justify-content: center;
                `}
                role="row"
                key={`week-${week[0]}`}
                className={clsx(
                  dayCalendarClasses.weekContainer,
                  classes?.weekContainer,
                )}
                // fix issue of announcing row 1 as row 2
                // caused by week day labels row
                aria-rowindex={index + 1}
              >
                {displayWeekNumber && (
                  <Text
                    css={css`
                      width: ${DAY_SIZE}px;
                      height: ${DAY_SIZE}px;
                      padding: 0;
                      margin: 0 ${DAY_MARGIN}px;
                      color: ${theme.palette.text.disabled};
                      align-items: center;
                      justify-content: center;
                      display: inline-flex;
                    `}
                    variant="body14"
                    className={clsx(
                      dayCalendarClasses.weekNumber,
                      classes?.weekNumber,
                    )}
                    role="rowheader"
                    aria-label={localeText.calendarWeekNumberAriaLabelText(
                      utils.getWeekNumber(week[0]),
                    )}
                  >
                    {localeText.calendarWeekNumberText(
                      utils.getWeekNumber(week[0]),
                    )}
                  </Text>
                )}
                {week.map((day, dayIndex) => (
                  <WrappedDay
                    key={(day as any).toString()}
                    parentProps={props}
                    day={day}
                    selectedDays={validSelectedDays}
                    focusableDay={focusableDay}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onDaySelect={handleDaySelect}
                    isDateDisabled={isDateDisabled}
                    currentMonthNumber={currentMonthNumber}
                    isViewFocused={internalHasFocus}
                    // fix issue of announcing column 1 as column 2 when `displayWeekNumber` is enabled
                    aria-colindex={dayIndex + 1}
                  />
                ))}
              </div>
            ))}
          </div>
        </PickersSlideTransition>
      )}
    </div>
  );
};
