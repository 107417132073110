import {
  AlliChatRow,
  AlliChatRowItem,
  AlliChatRowItemProps,
  ChatStackItem,
  TimestampTooltip,
} from '@allganize/alli-chat-base';
import { RichText, useRichText } from '@allganize/alli-chat-content';
import {
  DraftDocumentList,
  useDocumentEntities,
} from '@allganize/alli-chat-document';
import { isDraftInputEmpty } from '@allganize/draft-input';
import { Markdown } from '@allganize/markdown';
import { FunctionComponent } from 'react';
import { FragmentOf, gql, readFragment } from '../gql';

export const SendFormChatMessage_SendFormChatFragment = gql(`
  fragment SendFormChatMessage_SendFormChatFragment on SendFormChat {
    createdAt
    messageContentState @client
    markdownMessage

    media {
      id
    }
  }
`);

export interface SendFormChatMessageProps
  extends Pick<AlliChatRowItemProps, 'avatar'> {
  chat: FragmentOf<typeof SendFormChatMessage_SendFormChatFragment>;
}

export const SendFormChatMessage: FunctionComponent<
  SendFormChatMessageProps
> = props => {
  const { avatar, chat: chatProp } = props;
  const chat = readFragment(SendFormChatMessage_SendFormChatFragment, chatProp);
  const { value: message, onChange: onMessageChange } = useRichText({
    value: chat.messageContentState,
  });
  const { documentEntities } = useDocumentEntities({ value: message });
  const hasMedia = !!chat.media;
  const hasMarkdownMessage = !!chat.markdownMessage;
  const hasDraftMessage = !hasMarkdownMessage && !isDraftInputEmpty(message);
  const hasMessage = !hasMedia && (hasDraftMessage || hasMarkdownMessage);
  const hasDocumentEntities =
    !hasMarkdownMessage && documentEntities.length > 0;

  if (!hasMessage) {
    return null;
  }

  return (
    <ChatStackItem disablePadding>
      <AlliChatRow>
        <TimestampTooltip align="left" timestamp={chat.createdAt}>
          <AlliChatRowItem avatar={avatar}>
            {chat.markdownMessage && (
              <Markdown>{chat.markdownMessage}</Markdown>
            )}

            {!chat.markdownMessage && (
              <RichText value={message} onChange={onMessageChange} />
            )}

            {hasDocumentEntities && (
              <DraftDocumentList data={documentEntities} />
            )}
          </AlliChatRowItem>
        </TimestampTooltip>
      </AlliChatRow>
    </ChatStackItem>
  );
};
