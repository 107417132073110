import { DraftFilePlugin } from '@allganize/draft-file-plugin';
import { useMemo } from 'react';
import { defaultPlugins } from '../rich-text/use-rich-text.const';
import { getChatFileValue } from './use-chat-file.utils';

const plugins = defaultPlugins.filter(
  plugin => plugin instanceof DraftFilePlugin,
);

export interface UseChatFileOptions<T extends { url: string }> {
  file: T | null | (T | null)[];
}

export const useChatFile = <T extends { url: string }>({
  file,
}: UseChatFileOptions<T>) => {
  const value = useMemo(() => getChatFileValue<T>({ file }), [file]);
  return { value, plugins };
};
