import { useSuspenseQuery } from '@apollo/client/react';
import { css } from '@emotion/react';
import { compact } from 'lodash-es';
import { FunctionComponent, HTMLAttributes, useMemo } from 'react';
import {
  LLMAppCard,
  LLMAppCardProps,
  LLMAppCard__LLMAppFragment,
} from '../llm-app-card/llm-app-card';
import { LLMAppListEmptyState } from './llm-app-list-empty-state';
import { gql, Scalars } from '../gql';

export const LLMAppList__LLMAppsQuery = gql(
  `
  query LLMAppList__LLMAppsQuery(
      $filter: LLMAppFilter
      $order: LLMAppOrder
      $before: String
      $after: String
      $first: Int
      $last: Int
    ) {
      llmApps(
        filter: $filter
        order: $order
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }

        edges {
          cursor
          node {
            id

            campaign {
              id
              popupUrl
            }

            singleActionApp {
              id
              publicToken
            }

            ...LLMAppCard__LLMAppFragment
          }
        }
      }
  }
`,
  [LLMAppCard__LLMAppFragment],
);

export interface LLMAppListProps extends HTMLAttributes<HTMLDivElement> {
  columns?: number;
  filter?: Scalars<'LLMAppFilter'>;
  onClickLLMApp?: LLMAppCardProps['onClickLLMApp'];
}

export const LLMAppList: FunctionComponent<LLMAppListProps> = ({
  columns = 1,
  filter,
  onClickLLMApp,
  ...others
}) => {
  const { data } = useSuspenseQuery(LLMAppList__LLMAppsQuery, {
    variables: {
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const nodes = useMemo(
    () => compact(data?.llmApps?.edges.map(edge => edge?.node) ?? []),
    [data?.llmApps?.edges],
  );

  if (nodes.length === 0) {
    return <LLMAppListEmptyState isSearch={!!filter?.searchTerm} />;
  }

  return (
    <div
      css={css`
        display: grid;
        gap: 8px;
        grid-template-columns: repeat(${columns}, 1fr);
      `}
      {...others}
    >
      {nodes.map(node => {
        return (
          <div key={node.id}>
            <LLMAppCard data={node} onClickLLMApp={onClickLLMApp} />
          </div>
        );
      })}
    </div>
  );
};
