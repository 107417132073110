import { useEventCallback } from '@allganize/hooks';
import { InputField, InputFieldProps, inputClasses } from '@allganize/ui-input';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';

export const ChatFormInputField = forwardRef<
  HTMLDivElement,
  InputFieldProps & { ownerState?: any }
>((props, ref) => {
  const { ownerState, inputProps, multiline, ...other } = props;
  const theme = useTheme();
  const handleInputKeyDown = useEventCallback(
    (ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!multiline || !ev.isTrusted) {
        inputProps?.onKeyDown?.(ev);
        return;
      }

      // simulate input element's keydown event
      // instead of directly using native event of textarea
      ev.stopPropagation();
      const simulatedEvent = new KeyboardEvent('keydown', ev.nativeEvent);
      if (ev.target.dispatchEvent(simulatedEvent)) {
        if (
          simulatedEvent.key === 'Enter' &&
          !simulatedEvent.shiftKey &&
          !simulatedEvent.isComposing
        ) {
          ev.preventDefault();
          const submitEvent = new Event('submit', {
            cancelable: true,
            bubbles: true,
          });
          (ev.target as HTMLTextAreaElement).form?.dispatchEvent(submitEvent);
        }
      } else {
        ev.preventDefault();
      }
    },
  );

  return (
    <InputField
      css={css`
        .${inputClasses.root} {
          border-radius: 8px;

          &.${inputClasses.disabled} {
            background-color: ${theme.palette.unstable_background.grayDisabled
              .alpha};
          }

          &.${inputClasses.adornedEnd} .${inputClasses.notchedOutline} {
            background-color: ${theme.palette.unstable_background.white};
            z-index: -1;
          }
        }
      `}
      maxRows={7}
      fullWidth
      autoComplete="off"
      multiline={multiline}
      inputProps={{
        ...inputProps,
        onKeyDown: handleInputKeyDown,
      }}
      {...other}
      ref={ref}
      label={other.label ?? null}
    />
  );
});
